import { AxiosRequestConfig } from 'axios';
import {
  IResponse,
  IResponseAllChecks,
  IResponsePayrollUsers,
  TPayNow,
  TUserPayrollRangeResponse,
  TUserPayrollSingleDayResponse
} from 'pages/payrolls/types/types.payroll';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import axiosServices from 'utils/axios';

class PayrollServices {
  getPayrollUsers = async () => {
    try {
      const response: IResponsePayrollUsers = await axiosServices.get('api/payroll/users');
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  getUserPayrollRangeData = async (userId: string, filter: string, paginationData?: { page: number; rowsPerPage: number }) => {
    const page = paginationData && paginationData?.page + 1;
    const limit = paginationData && paginationData?.rowsPerPage;
    try {
      const response: TUserPayrollRangeResponse = await axiosServices.get(`api/payroll/user/${userId}${filter}`, {
        params: {
          ...(page && { page }),
          ...(limit && { limit })
        },
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  getUserPayrollSingleDayData = async (userId: string, selectedDate: string, paginationData?: { page: number; rowsPerPage: number }) => {
    const page = paginationData && paginationData?.page + 1;
    const limit = paginationData && paginationData?.rowsPerPage;
    try {
      const response: TUserPayrollSingleDayResponse = await axiosServices.get(`api/payroll/user/${userId}/${selectedDate}`, {
        params: {
          ...(page && { page }),
          ...(limit && { limit })
        },
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  payrollPayment = async (values: TPayNow) => {
    try {
      const response: IResponse = await axiosServices.post('api/payroll/pay', { ...values } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Payment Processed Successfully',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: false
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  createPayrollData = async (values: any) => {
    try {
      const response: IResponse = await axiosServices.post('api/payroll', { ...values } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: 'PayRoll created  Successfully',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: false
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  getAllUserCollectedCheck = async (userId: string) => {
    try {
      const response: IResponseAllChecks = await axiosServices.get(`api/job/checks/${userId}`);
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  deleteCheckCollected = async (id: string) => {
    try {
      const response: IResponse = await axiosServices.delete(`api/job/checks/${id}`); //! TBD
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Check Collected Deleted Successfully',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: false
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
}

const payrollServicesInstance = new PayrollServices();
export default payrollServicesInstance;
