import { CarryOutOutlined, CloseOutlined } from '@ant-design/icons';
import { AppBar, Dialog, DialogContent, IconButton, Slide, Toolbar, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import AddFollowupForm from 'components/forms/AddFollowupForm';
import { IAddFollowupPopupWActions } from 'pages/Settings/types/types.followupPopup';
import { forwardRef } from 'react';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddFollowupPopup = (props: IAddFollowupPopupWActions) => {
  const handleFollowUpAddSuccess = () => {
    props?.onSave?.();
  };

  return (
    <Dialog fullWidth maxWidth={'xs'} {...props.action} open={props.action?.open} TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative', padding: '0px !important' }}>
        <Toolbar className="w-full flex items-center justify-between !px-2 !pl-6">
          <Typography sx={{ flex: 1 }} variant="h4" component="div">
            <CarryOutOutlined className="mr-2" />

            <span>{props?.action?.isEditMode ? 'Edit Followup' : 'Add Followup'}</span>
          </Typography>
          <IconButton edge="start" color="inherit" onClick={props?.onClose} aria-label="close">
            <CloseOutlined />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <AddFollowupForm
          customerData={props.data?.otherData?.customerData}
          selectedJobId={props.selectedJobId}
          existingData={props?.data?.selectedData}
          isEditMode={props?.action?.isEditMode}
          onSuccess={handleFollowUpAddSuccess}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddFollowupPopup;
