import logoIcon from 'assets/images/logo-icon.png';

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = ({ reverse, width = 100, ...others }: { fillColor?: string; reverse?: boolean; width?: string | number }) => {
  //   const theme = useTheme();

  return <img src={logoIcon} alt="ZIPPfleet" width={width} />;
};

export default LogoIcon;
