import { CheckCircleFilled, ClearOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, FormControlLabel, Grid, IconButton, InputLabel, Radio, RadioGroup, Stack, Typography, useTheme } from '@mui/material';
import CustomTooltip from 'components/CustomTooltip';
import { useFormik } from 'formik';
import { useRef } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';

const EstimatorProposalAcceptanceWithoutSending = ({
  onSubmit,
  isSubmitting
}: {
  onSubmit: (value?: string) => void;
  isSubmitting: boolean;
}) => {
  const theme = useTheme();
  //------------------formik-------------
  const formik = useFormik({
    initialValues: {
      customer_sign_on_estimation: 'required',
      proposal_approve_signature: ''
    },
    onSubmit: async (values: { customer_sign_on_estimation: string; proposal_approve_signature: string }) => {
      if (values.customer_sign_on_estimation === 'required' && !!values.proposal_approve_signature)
        onSubmit(values.proposal_approve_signature);
      else onSubmit();
    }
  });
  const signatureRef = useRef<any>();

  //------------------Handlers-------------------
  const clearSignature = () => {
    signatureRef?.current?.clear();
    formik.setFieldValue('proposal_approve_signature', '');
  };

  return (
    <Grid container component={'form'} onSubmit={formik.handleSubmit} spacing={2}>
      <Grid item xs={12} sm={6}>
        <InputLabel>Does customer need to sign-off on this job?*</InputLabel>
        <RadioGroup row value={formik.values.customer_sign_on_estimation} onChange={formik.handleChange} name="customer_sign_on_estimation">
          <FormControlLabel control={<Radio />} value={'required'} label="Yes" />
          <FormControlLabel control={<Radio />} value={'no'} label="No" />
        </RadioGroup>
      </Grid>
      {formik.values.customer_sign_on_estimation === 'required' && (
        <Grid item xs={12}>
          <Stack>
            <div className="flex items-center justify-between">
              <Typography>
                Customer Sign*&nbsp;
                {!!formik.values?.proposal_approve_signature && <CheckCircleFilled style={{ color: theme.palette.success.main }} />}
              </Typography>
              <CustomTooltip message="Clear">
                <IconButton onClick={clearSignature} color="error">
                  <ClearOutlined />
                </IconButton>
              </CustomTooltip>
            </div>
            <ReactSignatureCanvas
              penColor="black"
              canvasProps={{
                className: 'signateureCanvas',
                style: {
                  width: '100%',
                  border: '1px solid black',
                  minHeight: 200
                }
              }}
              ref={signatureRef}
              onEnd={() => formik.setFieldValue('proposal_approve_signature', signatureRef?.current?.toDataURL())}
            />
          </Stack>
        </Grid>
      )}
      <Grid item xs={12} className="flex justify-end">
        <Button
          type="submit"
          variant="contained"
          startIcon={isSubmitting && <LoadingOutlined />}
          disabled={
            isSubmitting ||
            (formik.values.customer_sign_on_estimation === 'required' ? formik.values?.proposal_approve_signature?.length === 0 : false)
          }
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default EstimatorProposalAcceptanceWithoutSending;
