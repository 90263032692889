import {
  DeleteOutlined,
  DownloadOutlined,
  ExclamationCircleFilled,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined
} from '@ant-design/icons';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardMedia,
  FormControlLabel,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  InputLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import CustomTooltip from 'components/CustomTooltip';
import PaymentInput from 'components/inputs/PaymentInput';
import UniversalDialog from 'components/popups/UniversalDialog';
import { TJobData, TPayment } from 'components/tables/interfaces/jobTableInterface';
import { RefObject, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import FileUploadServiceInstance from 'services/services.fileUpload';
import JobServicesInstance from 'services/services.jobs';
import { useSelector } from 'store';

const defaultPaymentTypes = ['cash', 'check', 'zelle', 'other', 'print'];

const InvoicePayment = (props: {
  refetchJob: () => void;
  approvedOptionIndex: number;
  amountPaid: number | null;
  jobData?: TJobData;
  componentRef: RefObject<HTMLDivElement>;
}) => {
  //------------------------Constants-----------------------
  const { jobData, componentRef, approvedOptionIndex, amountPaid } = props;
  const navigate = useNavigate();
  const selectedOption = jobData?.job?.estimations?.options?.[approvedOptionIndex];
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paymentData, setPaymentData] = useState<TPayment[]>([{}]);
  const [totalAmountRemaining, setTotalAmountRemaining] = useState<number>(0);
  const [paymentTypes, setPaymentTypes] = useState<Array<string>>([]);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [isErrorInJobImagesDisplay, setIsErrorInJobImagesDisplay] = useState('');
  const [openTaxExemptPopup, setOpenTaxExemptPopup] = useState(false);
  const { generalSettings: settingsData } = useSelector((state) => state.settings);
  const isStringValid = (value: string) => value.length > 0;
  //------------------------------Handlers---------------------
  const getTotalAmount = () => {
    let totalAmount = 0;
    paymentData.forEach((singlePaymentData) => {
      totalAmount += singlePaymentData?.amount || 0;
    });
    return totalAmount;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    const payload: TPayment[] = [
      ...paymentData.map((singlePaymentData) => {
        singlePaymentData.date = new Date().toISOString();
        return singlePaymentData;
      })
    ];

    if (jobData?.job?._id) {
      await JobServicesInstance.invoicePayment(jobData?.job?._id, { payments: payload }).then((res) => {
        if (res) {
          navigate(`/jobs`);
        }
      });
    }
    setIsSubmitting(false);
  };

  const fieldErrors = () => {
    return paymentData.some((singlePaymentData) => {
      return (
        (singlePaymentData?.payment_type === undefined ? false : !isStringValid(singlePaymentData?.payment_type)) ||
        (singlePaymentData?.amount === undefined ? false : !singlePaymentData?.amount ? true : false) ||
        (singlePaymentData?.cheque_number === undefined ? false : singlePaymentData?.cheque_number === null ? true : false) ||
        (singlePaymentData?.cheque_date === undefined ? false : singlePaymentData?.cheque_date ? false : true) ||
        (singlePaymentData?.how_did_customer_pay === undefined ? false : !isStringValid(singlePaymentData?.how_did_customer_pay)) ||
        (singlePaymentData?.card_number === undefined
          ? false
          : !singlePaymentData.card_number || singlePaymentData.card_number?.length < 13 || singlePaymentData.card_number?.length > 16
          ? true
          : false) ||
        (singlePaymentData?.expiry_month === undefined
          ? false
          : singlePaymentData.expiry_month && Number(singlePaymentData.expiry_month) <= 12 && Number(singlePaymentData.expiry_month) >= 1
          ? false
          : true) ||
        (singlePaymentData?.expiry_year === undefined
          ? false
          : !singlePaymentData.expiry_year || singlePaymentData.expiry_year?.length !== 2
          ? true
          : false) ||
        (singlePaymentData?.cvv === undefined
          ? false
          : !singlePaymentData.cvv || singlePaymentData.cvv?.length < 3 || singlePaymentData.cvv?.length > 4
          ? true
          : false) ||
        (singlePaymentData?.card_type === undefined ? false : !isStringValid(singlePaymentData?.card_type))
      );
    });
  };
  const handleRemoveSelectedfile = (index: number) => {
    const dummyData = [...(jobData?.job?.tax_exempt_photos ?? [])];
    dummyData.splice(index, 1);
    return handleTaxExemptSubmit(true, false, undefined, true, dummyData);
    // dispatch(setJobCompletionPhotos([...dummyData]));
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setIsImageUploading(true);
      const files = event.target.files;
      const FilesData = Object.values(files);
      let newImagesData: string[] = jobData?.job?.tax_exempt_photos ?? [];
      let isError = false;
      await Promise.all(
        FilesData.map(async (eachFile) => {
          const isImage = eachFile.type.startsWith('image/');

          if (!isImage) {
            isError = true;
            return;
          }

          const response = await FileUploadServiceInstance.uploadFile(eachFile);
          if (response && response.data) newImagesData = [...newImagesData, response.data];
        })
      );
      setIsImageUploading(false);
      if (isError) {
        setIsErrorInJobImagesDisplay('Please upload an image');
        return null;
      }
      setIsErrorInJobImagesDisplay('');
      return newImagesData;
    }
  };
  const handleTaxExemptSubmit = async (
    isTaxExempt: boolean,
    isTaxExemptImagesUploaded?: boolean,
    event?: React.ChangeEvent<HTMLInputElement>,
    isTaxExemptImagesDeleted?: boolean,
    updatedTaxExemptImages?: string[]
  ) => {
    let taxExemptPhotos = [] as string[];
    if (isTaxExemptImagesUploaded) {
      taxExemptPhotos = (await handleFileUpload(event as React.ChangeEvent<HTMLInputElement>)) ?? ([] as string[]);
    }
    const response = await JobServicesInstance.markTaxExempt(jobData?.job._id as string, {
      is_this_job_tax_exempt: isTaxExempt,
      tax_exempt_photos: isTaxExemptImagesUploaded ? taxExemptPhotos : isTaxExemptImagesDeleted ? updatedTaxExemptImages ?? [] : []
    });

    if (response) {
      props.refetchJob();
      setOpenTaxExemptPopup(false);
    }
  };
  //--------------------------useEffects------------------------------
  useEffect(() => {
    if (selectedOption && amountPaid?.toString()) {
      setTotalAmountRemaining(selectedOption.sub_total + (jobData.job.is_this_job_tax_exempt ? 0 : selectedOption.tax) - amountPaid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobData?.job.is_this_job_tax_exempt]);

  useEffect(() => {
    if (!settingsData) return;
    if (
      settingsData.payment_methods?.authorize_net_details &&
      Object.keys(settingsData.payment_methods?.authorize_net_details).length > 0
    ) {
      if (!defaultPaymentTypes.includes('credit_card')) {
        defaultPaymentTypes.push('credit_card');
      }
    }
    if (settingsData.payment_methods?.venmo_details && !Object.keys(settingsData.payment_methods.venmo_details).length) {
      if (!defaultPaymentTypes.includes('venmo')) {
        defaultPaymentTypes.push('venmo');
      }
    }
    if (!settingsData.payment_methods?.paypal_details?.client_id || !settingsData.payment_methods?.paypal_details?.client_secret) {
      if (!defaultPaymentTypes.includes('paypal')) {
        defaultPaymentTypes.push('paypal');
      }
    }
    setPaymentTypes(defaultPaymentTypes);
  }, [settingsData]);

  return (
    <Grid container component={'form'} spacing={2}>
      {!!jobData?.job.estimations?.options[approvedOptionIndex].tax &&
        jobData?.job?.payments?.reduce((acc, singlePaymentData) => acc + (singlePaymentData?.amount ?? 0), 0) === 0 && (
          <Grid item xs={12} className="flex flex-row space-x-5 align-middle">
            <InputLabel className="flex items-center">Is This Job Tax Exempt?</InputLabel>
            <RadioGroup
              row
              value={jobData.job.is_this_job_tax_exempt}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.value === 'false') handleTaxExemptSubmit(false);
                setOpenTaxExemptPopup(event.target.value === 'true');
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
        )}
      {!!jobData?.job.tax_exempt_photos && (
        <Grid item xs={12}>
          <ImageList cols={5} rowHeight={'auto'} className="space-x-1 space-y-1">
            {jobData?.job?.tax_exempt_photos?.map((singleFileUrl: string, index: number) => (
              <ImageListItem key={index} component={Card} className="border border-1-black">
                <Box className="flex flex-col items-end">
                  <ButtonGroup>
                    <IconButton color="error" size={'small'} onClick={() => handleRemoveSelectedfile(index)}>
                      <DeleteOutlined />
                    </IconButton>
                    <IconButton
                      color="success"
                      size={'small'}
                      onClick={
                        () => {}
                        // downloadFile(singleFileUrl, `tax_exempt_file_${index}`)
                      }
                    >
                      <DownloadOutlined />
                    </IconButton>
                  </ButtonGroup>
                </Box>
                <div className="flex flex-col justify-center h-full">
                  <CardMedia component={'img'} src={singleFileUrl} alt={`Image ${index + 1}`} loading="lazy" />
                </div>
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      )}
      <PaymentInput
        componentRef={componentRef}
        paymentData={paymentData}
        setPaymentData={setPaymentData}
        totalAmountRemaining={totalAmountRemaining}
        paymentTypes={paymentTypes}
      />
      <Grid item className="flex flex-row justify-center space-x-6  bottom-0 right-2 w-full" xs={12}>
        {amountPaid != null && selectedOption && (
          <Button
            startIcon={<PlusOutlined />}
            disabled={
              isSubmitting ||
              fieldErrors() ||
              getTotalAmount() >= selectedOption?.sub_total + (jobData.job.is_this_job_tax_exempt ? 0 : selectedOption?.tax) - amountPaid ||
              paymentData[paymentData.length - 1]?.amount === undefined
            }
            onClick={() => {
              const totalAmount = getTotalAmount();
              if (
                selectedOption &&
                !(totalAmount >= selectedOption?.sub_total + (jobData.job.is_this_job_tax_exempt ? 0 : selectedOption?.tax) - amountPaid)
              ) {
                setTotalAmountRemaining(
                  selectedOption?.sub_total + (jobData.job.is_this_job_tax_exempt ? 0 : selectedOption?.tax) - amountPaid - totalAmount
                );
                setPaymentData((prevPaymenData) => {
                  const dummyData = [...prevPaymenData];
                  dummyData.push({});
                  return dummyData;
                });
              }
            }}
            variant="outlined"
          >
            Add More
          </Button>
        )}
        {amountPaid != null && selectedOption && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={
              isSubmitting ||
              fieldErrors() ||
              getTotalAmount() > selectedOption.sub_total + (jobData.job.is_this_job_tax_exempt ? 0 : selectedOption.tax) - amountPaid ||
              paymentData[paymentData.length - 1].amount === undefined
            }
          >
            Save
          </Button>
        )}
      </Grid>
      {openTaxExemptPopup && (
        <UniversalDialog
          action={{ open: openTaxExemptPopup, fullWidth: true, maxWidth: 'sm' }}
          onClose={() => setOpenTaxExemptPopup(false)}
          hasPrimaryButton={false}
        >
          <>
            <Typography>Would you like to take a picture of the resale certificate or the tax exempt certificate?</Typography>
            <div className="flex justify-end">
              <Button variant="text" onClick={() => handleTaxExemptSubmit(true, false)}>
                No
              </Button>
              <input
                style={{ display: 'none' }}
                id="upload-file"
                type="file"
                accept="image/*"
                multiple
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTaxExemptSubmit(true, true, event)}
              />
              <label htmlFor="upload-file">
                <Button
                  component="span"
                  startIcon={isImageUploading ? <LoadingOutlined /> : <UploadOutlined />}
                  disabled={isImageUploading}
                  variant="contained"
                >
                  Upload
                </Button>
              </label>
              {!!isErrorInJobImagesDisplay.length && (
                <CustomTooltip message={isErrorInJobImagesDisplay}>
                  <ExclamationCircleFilled className="text-xl text-red-500 px-2" />
                </CustomTooltip>
              )}
            </div>
          </>
        </UniversalDialog>
      )}
    </Grid>
  );
};

export default InvoicePayment;
