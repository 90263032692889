import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TSettingData } from 'pages/Settings/types/types.generalSettings';
import generalSettingsInstance from 'services/services.generalSettings';

// types
type TSettingState = {
  mobileSideBarVisible: boolean;
  isTodaysJob: boolean;
  generalSettings: TSettingData | undefined;
};

export const getGeneralSettings = createAsyncThunk('general-settings', async () => {
  const generalSettings = await generalSettingsInstance.getSettingsData();
  return generalSettings;
});

// initial state
const initialState: TSettingState = {
  mobileSideBarVisible: false,
  isTodaysJob: false,
  generalSettings: undefined
};

// ==============================|| SLICE ||============================== //

const settingSlice = createSlice({
  name: 'followup_actions',
  initialState,
  reducers: {
    setMobileSettingsSideBarVisible(state, action) {
      state.mobileSideBarVisible = action.payload;
    },
    setIsTodaysJob(state, action) {
      state.isTodaysJob = action.payload;
    },
    setGeneralSettings(state, action) {
      state.generalSettings = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(getGeneralSettings.fulfilled, (state, action) => {
      state.generalSettings = action.payload;
    });
  }
});

export default settingSlice.reducer;

export const { setMobileSettingsSideBarVisible, setGeneralSettings, setIsTodaysJob } = settingSlice.actions;
