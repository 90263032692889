import { Typography, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import ProductPlaceholder from 'components/cards/skeleton/ProductPlaceholder';
import RenderStatusPreviewIcons from 'components/RenderStatusPreviewIcons';
import CustomDataTable from 'components/tables/CustomDataTable';
import useAuth from 'hooks/useAuth';
import useTimezone from 'hooks/useTimezone';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import JobServicesInstance from 'services/services.jobs';
import { TUserInvoice } from 'services/types/types.services';
import { useSelector } from 'store';
import { formateData } from 'utils/common';

type TCustomerJobInvoices = {
  customerId: string;
};

const CustomerJobInvoices = (props: TCustomerJobInvoices) => {
  const { user } = useAuth();
  const theme = useTheme();
  const formatDateTime = useTimezone();
  const settingsState = useSelector((state) => state.settings.generalSettings);
  const primaryColor = `${theme.palette.primary.main}`;

  const { data: allInvoices, isLoading: isInvoicesFetching } = useQuery({
    queryKey: ['jobs', props.customerId],
    queryFn: () => JobServicesInstance.getCustomerInvoice(props.customerId),
    enabled: !!props.customerId && user?.permissions && user?.permissions.includes('getJobs'),
    networkMode: 'always'
  });

  const [userInvoices, setUserInvoices] = useState<TUserInvoice[]>([]);
  useEffect(() => {
    if (!allInvoices) return;
    setUserInvoices(allInvoices.invoices);
  }, [allInvoices]);

  const columns = useMemo<ColumnDef<TUserInvoice>[]>(
    () => [
      {
        id: 'job_name',
        cell: ({ row }) => {
          return (
            <Typography
              sx={{
                '&:hover': {
                  color: primaryColor
                }
              }}
              className={`cursor-pointer`}
              component={Link}
              to={`/jobs/view/${row.original.job.id}/job_status`}
            >
              {row.original.job.name}
            </Typography>
          );
        },
        header: () => <span>Job Name</span>
      },
      {
        accessorFn: (row) => <span style={{ color: '#017BFE' }}>{formateData(row.total_amount ?? 0, '$')}</span>,
        id: 'invoice_total',
        cell: (info) => info.getValue(),
        header: () => <span>Invoice Total</span>
      },
      {
        accessorFn: (row) => <span style={{ color: '#28A745' }}>{formateData(row.amount_paid ?? 0, '$')}</span>,
        id: 'amount_paid',
        cell: (info) => info.getValue(),
        header: () => <span>Amount Paid</span>
      },
      {
        accessorFn: (row) => <span style={{ color: '#DE404D' }}>{formateData(row.balance_due ?? 0, '$')}</span>,
        id: 'balance_due',
        cell: (info) => info.getValue(),
        header: () => <span>Balance Due</span>
      },

      {
        id: 'invoice_date',
        cell: ({ row }) => (
          <div className="text-xs text-gray-500">
            {formatDateTime(row.original.created_at, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: settingsState?.time_format === '12hrs'
            })}
          </div>
        ),
        header: () => <span>Invoice Date</span>
      },
      {
        id: 'status_preview',
        cell: ({ row }) => {
          return (
            <div className="flex flex-col items-center p-0">
              <div className="w-full flex justify-start space-x-3">
                <RenderStatusPreviewIcons
                  jobAction={row.original?.jobData?.job_action ?? []}
                  jobState={row.original?.jobData?.state as string}
                  status={row.original?.jobData?.status!}
                  previousStates={row.original?.jobData?.previous_states}
                  jobId={row.original._id}
                  proposalSentDate={row?.original?.jobData?.last_proposal_sent}
                  invoiceSentDate={row.original?.jobData?.last_invoice_sent?.toString()}
                />
              </div>
            </div>
          );
        },
        header: () => (
          <div className="flex w-40">
            <div className="ml-3 w-full flex justify-start space-x-9">
              <span>E</span>
              <span>P</span>
              <span>W</span>
              <span>I</span>
            </div>
          </div>
        )
      }
    ],

    []
  );
  return (
    <>
      <div>{isInvoicesFetching ? <ProductPlaceholder /> : <CustomDataTable columns={columns} data={userInvoices || []} />}</div>
    </>
  );
};

export default CustomerJobInvoices;
