import { DeleteFilled } from '@ant-design/icons';
import { Button, Checkbox, IconButton, Paper, Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import MainCard from 'components/MainCard';
import DeleteConfirmationPoup from 'components/popups/DeleteConfirmationPopup';
import CustomDataTable from 'components/tables/CustomDataTable';
import { useCallback, useMemo, useState } from 'react';
import { IDeletePopup } from 'types/globalTypes/type.deleteDialog';
import { useQuery } from '@tanstack/react-query';
import payrollServicesInstance from 'services/services.payroll';
import useAuth from 'hooks/useAuth';
import { useParams } from 'react-router';
import Loader from 'components/Loader';
import moment from 'moment';
import UserServicesInstance from 'services/services.users';

const ChecksCollected = () => {
  const [selectedChecks, setSelectedChecks] = useState<string[]>([]);
  const [deleteDialog, setDeleteDialog] = useState<IDeletePopup>({
    action: { open: false, reasonRequired: false }
  });
  const { user } = useAuth();
  const { id: userId } = useParams();

  const { data: userData, isFetched: isUserDataFetched } = useQuery({
    queryKey: ['user_view', userId],
    queryFn: () => UserServicesInstance.getUser(userId as string)
    // enabled: user?.permissions && user.permissions.includes('getPayrolls')
  });

  const {
    data: allChecksData,
    isFetched: isAllChecksFetched,
    refetch: refetchAllChecks
  } = useQuery({
    queryKey: ['checks_view', userId],
    queryFn: () => payrollServicesInstance.getAllUserCollectedCheck(`${userId}`),
    enabled: user?.permissions && user.permissions.includes('getPayrolls')
  });

  const handleActions = (action: string, data: any) => {
    if (action === 'delete') {
      // Handle delete action
      setDeleteDialog({ action: { open: true, reasonRequired: false }, data: { selectedData: data } });
    }
  };

  const handleCheckSelection = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const id = event.target.value;
    if (checked) {
      setSelectedChecks((prev) => [...prev, id]);
    } else {
      setSelectedChecks((prev) => prev.filter((check) => check !== id));
    }
  }, []);

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        cell: ({ row: { original: originalData } }) => <Typography>Check ({originalData.check_number})</Typography>,
        id: 'payment-type',
        header: () => <span>Payment Type</span>
      },
      {
        cell: ({ row: { original: originalData } }) => <Typography>${originalData.payment_amount}</Typography>,
        id: 'payment-amount',
        header: () => <span>Payment Amount</span>
      },
      {
        cell: ({ row: { original: originalData } }) => <Typography>{moment(originalData.date).format('MM-DD-YYYY')}</Typography>,
        id: 'date',
        header: () => <span>Date</span>
      },
      {
        id: 'actions',
        header: () => <span>Actions</span>,
        cell: ({ row }) => {
          return (
            <span className="flex items-center space-x-2">
              <IconButton color="error" onClick={() => handleActions('delete', row.original)}>
                <DeleteFilled />
              </IconButton>
              <Checkbox
                color="error"
                onChange={handleCheckSelection}
                value={row.original.id}
                checked={selectedChecks.some((eachCheck) => eachCheck === row.original.id)}
              />
            </span>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleCheckSelection, selectedChecks]
  );

  async function handleDeleteSelectedCustomer(checkIds?: string[]) {
    const idsToDelete = checkIds || selectedChecks;
    if (idsToDelete.length === 0) {
      setDeleteDialog({ action: { open: false, reasonRequired: false } });
      return;
    }

    try {
      await Promise.all(idsToDelete.map((id) => payrollServicesInstance.deleteCheckCollected(id)));
      setSelectedChecks((prev) => prev.filter((id) => !idsToDelete.includes(id)));
      refetchAllChecks();
    } catch (error) {
      console.error('Error deleting checks:', error);
    } finally {
      setDeleteDialog({ action: { open: false, reasonRequired: false } });
    }
  }

  return !isAllChecksFetched || !isUserDataFetched ? (
    <Loader />
  ) : (
    <Paper className="p-4">
      <div className="w-full flex items-center justify-between">
        <Typography gutterBottom className="text-xl mb-6">
          <span>Checks Collected by </span>
          <strong>
            {userData?.first_name} {userData?.last_name}
          </strong>
        </Typography>
        {selectedChecks.length > 0 && (
          <Button
            startIcon={<DeleteFilled />}
            variant="contained"
            color="error"
            onClick={() => setDeleteDialog({ action: { open: true, reasonRequired: false } })}
          >
            Delete Selected
          </Button>
        )}
      </div>
      <div className="flex flex-col gap-4">
        {allChecksData ? (
          allChecksData.map((eachUser) => (
            <MainCard title={eachUser.customer_name} content>
              <CustomDataTable columns={columns} data={eachUser.checks_collected} hasPagination={false} />
            </MainCard>
          ))
        ) : (
          <></>
        )}
      </div>
      {deleteDialog && (
        <DeleteConfirmationPoup
          {...deleteDialog}
          // onDelete={handleDeleteSelectedCustomer}
          onDelete={() =>
            deleteDialog?.data?.selectedData?.id
              ? handleDeleteSelectedCustomer([deleteDialog.data.selectedData.id])
              : handleDeleteSelectedCustomer()
          }
          onClose={() => setDeleteDialog({ action: { open: false } })}
        />
      )}
    </Paper>
  );
};

export default ChecksCollected;
