import { Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import UniversalDialog from 'components/popups/UniversalDialog';
import { TJobCompleteData, TJobData } from 'components/tables/interfaces/jobTableInterface';
import moment from 'moment';
import NotesTimelineView from 'pages/customers/CustomerViewTabs/NotesTimelineView';
import { useEffect, useRef, useState } from 'react';
import JobServicesInstance from 'services/services.jobs';

const NotesContainer = ({
  jobData,
  refetchJob
}: {
  jobData?: TJobCompleteData;
  refetchJob: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<TJobData | undefined, Error>>;
}) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [viewHistory, setViewHistory] = useState<{ state: boolean; data: any }>({
    state: false,
    data: null
  });

  const [otwNote, setOTWNote] = useState(jobData?.office_to_worker_notes?.[jobData?.office_to_worker_notes.length - 1]?.text ?? '');
  const [wtoNote, setWTONote] = useState(jobData?.worker_to_office_notes?.[jobData?.worker_to_office_notes.length - 1]?.text ?? '');

  const otwNoteRef = useRef(jobData?.office_to_worker_notes?.[jobData?.office_to_worker_notes.length - 1]?.text ?? '');
  const wtoNoteRef = useRef(jobData?.worker_to_office_notes?.[jobData?.worker_to_office_notes.length - 1]?.text ?? '');

  useEffect(() => {
    otwNoteRef.current = otwNote;
  }, [otwNote]);

  useEffect(() => {
    wtoNoteRef.current = wtoNote;
  }, [wtoNote]);

  useEffect(() => {
    if (jobData) {
      setOTWNote(jobData?.office_to_worker_notes?.[jobData?.office_to_worker_notes.length - 1]?.text || '');
      setWTONote(jobData?.worker_to_office_notes?.[jobData?.worker_to_office_notes.length - 1]?.text || '');
    }
  }, [jobData]);

  async function updateNewNotesData() {
    //  ------ Only update the notes if user has typed someting

    if (!(jobData?.worker_to_office_notes && !jobData?.worker_to_office_notes?.length && !wtoNoteRef.current?.length)) {
      if (wtoNoteRef.current !== jobData?.worker_to_office_notes?.[jobData?.worker_to_office_notes.length - 1]?.text) {
        await JobServicesInstance.addNote(jobData?._id as string, { type: 'worker_to_office_notes', text: wtoNoteRef.current });
      }
    }

    if (!(jobData?.office_to_worker_notes && !jobData?.office_to_worker_notes?.length && !otwNoteRef.current?.length)) {
      if (otwNoteRef.current !== jobData?.office_to_worker_notes?.[jobData?.office_to_worker_notes.length - 1]?.text) {
        await JobServicesInstance.addNote(jobData?._id as string, { type: 'office_to_worker_notes', text: otwNoteRef.current });
      }
    }

    refetchJob();
  }

  useEffect(() => {
    return () => {
      refetchJob();

      updateNewNotesData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleNotesHistoryClick(data: any) {
    const formattedData = data.map((note: any) => ({
      note: note.text,
      updated_by: note.updated_by,
      updated_at: note.updated_at
    }));
    setViewHistory({ state: true, data: formattedData });
  }

  return (
    <Grid container className="p-4 w-full" spacing={2}>
      <Grid item xs={12} lg={6}>
        <Typography className="font-bold">Office To Worker Notes</Typography>
        <TextField
          value={otwNote}
          onChange={(e) => setOTWNote(e.target.value)}
          placeholder="Write a note here..."
          minRows={4}
          multiline
          className="w-full bg-transparent"
        />
        {jobData?.office_to_worker_notes && jobData?.office_to_worker_notes?.length > 0 && (
          <Typography
            className="flex items-center justify-end sm:text-xs text-[10px] text-gray-400 cursor-pointer underline mt-1"
            onClick={() => handleNotesHistoryClick(jobData?.office_to_worker_notes)}
          >
            Updated by&nbsp;
            <strong>{jobData?.office_to_worker_notes?.[jobData?.office_to_worker_notes.length - 1]?.updated_by.name}</strong>
            &nbsp;on&nbsp;
            <strong>
              {jobData?.office_to_worker_notes?.[jobData?.office_to_worker_notes.length - 1]?.updated_at &&
                moment(jobData?.office_to_worker_notes?.[jobData?.office_to_worker_notes.length - 1]?.updated_at).format('lll')}
            </strong>
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} lg={6}>
        <Typography className="font-bold"> Worker To Office Notes</Typography>
        <TextField
          value={wtoNote}
          onChange={(e) => setWTONote(e.target.value)}
          placeholder="Write a note here..."
          minRows={4}
          multiline
          className="w-full bg-transparent"
        />
        {jobData?.worker_to_office_notes && jobData?.worker_to_office_notes?.length > 0 && (
          <Typography
            className="flex items-center justify-end sm:text-xs text-[10px] text-gray-400 cursor-pointer underline mt-1"
            onClick={() => handleNotesHistoryClick(jobData?.worker_to_office_notes)}
          >
            Updated by&nbsp;
            <strong>{jobData?.worker_to_office_notes?.[jobData?.worker_to_office_notes.length - 1]?.updated_by.name}</strong>
            &nbsp;on&nbsp;
            <strong>
              {jobData?.worker_to_office_notes?.[jobData?.worker_to_office_notes.length - 1]?.updated_at &&
                moment(jobData?.worker_to_office_notes?.[jobData?.worker_to_office_notes.length - 1]?.updated_at).format('lll')}
            </strong>
          </Typography>
        )}
      </Grid>
      {viewHistory.state && (
        <UniversalDialog
          onClose={() => setViewHistory({ state: false, data: null })}
          hasPrimaryButton={false}
          action={{ open: viewHistory.state, ...(isMobile ? { fullScreen: true } : { fullWidth: true }), maxWidth: 'sm' }}
          title={`${jobData?.job_name}'s notes history`}
        >
          <NotesTimelineView data={viewHistory.data} />
        </UniversalDialog>
      )}
    </Grid>
  );
};

export default NotesContainer;
