import { HistoryOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, FormControlLabel, IconButton, InputLabel, Paper, Stack, TextField, Typography, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { ISearch } from 'components/filters/SearchFilter';
import UniversalDialog from 'components/popups/UniversalDialog';
import CustomDataTable, { rowsPerPageOptions } from 'components/tables/CustomDataTable';
import useTimezone from 'hooks/useTimezone';
import { IAddFollowupPopup } from 'pages/Settings/types/types.followupPopup';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import LogsServicesInstance from 'services/services.logs';
import { useSelector } from 'store';
import { snakeCaseToTitleCase } from 'utils/common';
import { TCustomerLog } from '../types/types.customerLogs';

type TLogsHistory = { customer: { id: string; name: string } };

const CustomerLogs = (props: TLogsHistory) => {
  const {
    customer: { id: customerId, name: customerName }
  } = props;

  // ------- states -------
  const settingsState = useSelector((state) => state.settings.generalSettings);
  const theme = useTheme();
  const navigate = useNavigate();
  const primaryColor = `${theme.palette.primary.main}`;
  const [paginationData, setPaginationData] = useState({ page: 0, rowsPerPage: rowsPerPageOptions[0] });
  const [isLogAddLoading, setIsLogAddLoading] = useState(false);
  const formatDateTime = useTimezone();
  const [searchData, setSearchData] = useState<ISearch | null>(null);
  const [displayViewedLogs, setDisplayViewedLogs] = useState<boolean>(false);
  const [addLogPopup, setAddLogPopup] = useState<IAddFollowupPopup>({ action: { open: false }, data: null });
  const [customerLogs, setCustomerLogs] = useState<TCustomerLog[]>([]);

  // ------- useQueries --------
  const {
    data: allCustomerLogs,
    isFetching: isLogsFetching,
    refetch: refetchLogs
  } = useQuery({
    queryKey: [paginationData, searchData, displayViewedLogs],
    queryFn: () => LogsServicesInstance.getAllLogs(displayViewedLogs, paginationData, searchData),
    enabled: !!searchData && searchData?.search.length > 0
  });

  useEffect(() => {
    if (!allCustomerLogs) return;
    setCustomerLogs(allCustomerLogs.logs);
  }, [allCustomerLogs]);

  useEffect(() => {
    if (customerId) {
      setSearchData({
        search: [
          [
            {
              field_name: 'customer.id',
              field_value: customerId,
              operator: 'exactmatch'
            }
          ],
          [
            {
              field_name: 'is_customer_log_also',
              field_value: true,
              operator: 'exactmatch'
            }
          ]
        ]
      });
    }
  }, [customerId]);

  // ------ handlers ------

  const handleChangePagination = (page: number, rowsPerPage: number) => {
    setPaginationData({ page, rowsPerPage });
  };

  // ------ columns -------

  const columns = useMemo<ColumnDef<TCustomerLog>[]>(
    () => [
      {
        id: 'date',
        header: () => <span>Date</span>,
        cell: ({ row }) => {
          return (
            <Stack>
              <Typography variant="body1">
                {formatDateTime(row.original.created_at, {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                })}
              </Typography>
              <Typography variant="caption">
                {formatDateTime(row.original.created_at, {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: settingsState?.time_format === '12hrs'
                })}
              </Typography>
            </Stack>
          );
        }
      },
      {
        id: 'user',
        header: () => <span>User</span>,
        cell: ({ row }) => {
          return <div>{row.original?.user?.name || 'System'}</div>;
        }
      },

      {
        id: 'job_name',
        cell: ({
          row: {
            original: { job }
          }
        }) =>
          job?.id ? (
            <Typography
              sx={{
                '&:hover': {
                  color: primaryColor
                }
              }}
              className={`cursor-pointer`}
              onClick={() => navigate(`/jobs/view/${job?.id}/job_status`)}
            >
              {job?.name}
            </Typography>
          ) : (
            '-'
          ),
        header: () => <span>Job Name</span>
      },
      {
        id: 'action',
        cell: ({ row }) => snakeCaseToTitleCase(row.original.action),
        header: () => <span>Action</span>
      },
      {
        id: 'description',
        accessorFn: (row) => row.description,
        header: () => <span>Description</span>
      },
      {
        id: 'restore_deleted',
        header: () => <span>Restore Deleted</span>,
        cell: ({ row }) => {
          return row.original.is_restorable ? (
            <IconButton onClick={() => handleRestoreDeleted(row.original._id)} color="error">
              <HistoryOutlined />
            </IconButton>
          ) : null;
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleRestoreDeleted = async (logId: string) => {
    await LogsServicesInstance.restoreDeleted(logId);
    refetchLogs();
  };

  const handleLogAdd = (openState: boolean) => {
    setAddLogPopup({ action: { open: openState } });
  };

  const [customerLogDescription, setCustomerLogDescription] = useState<string | null>(null);
  const handleCustomerLogDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerLogDescription(event.target.value);
  };

  const handleAddCustomerLog = async () => {
    setIsLogAddLoading(true);
    if (customerLogDescription) {
      const data = {
        description: customerLogDescription,
        module: 'customer',
        action: 'log_added',
        customer: { id: customerId, name: customerName },
        is_customer_log_also: true
      };
      await LogsServicesInstance.createLog(data);
      setIsLogAddLoading(false);
    }
  };

  // ------ Components ------

  const FolllowupHeader = () => {
    return (
      <div className="flex items-center justify-end w-full px-4 my-6">
        <div className="space-x-5">
          <FormControlLabel
            value={true}
            color="primary"
            checked={displayViewedLogs}
            control={
              <Checkbox onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setDisplayViewedLogs(checked)} />
            }
            label={'Viewed logs'}
          />
          <Button variant="contained" size="large" startIcon={<PlusOutlined />} color="primary" onClick={() => handleLogAdd(true)}>
            Add Log
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Paper elevation={3} className="flex flex-col items-start space-y-4">
        <FolllowupHeader />
        <CustomDataTable
          data={customerLogs || []}
          columns={columns}
          count={allCustomerLogs?.count}
          onPaginationChange={handleChangePagination}
          isDataLoading={isLogsFetching}
        />
      </Paper>
      {addLogPopup.action.open && (
        <UniversalDialog
          onClose={() => handleLogAdd(false)}
          title={'Add new log'}
          action={{ fullWidth: true, ...addLogPopup.action }}
          primaryButonTitle="Add"
          disablePrimaryButton={isLogAddLoading}
          isPrimaryButtonLoading={isLogAddLoading}
          onSave={() => handleAddCustomerLog()}
        >
          <Stack gap={1}>
            <InputLabel>Description*</InputLabel>
            <TextField placeholder="Add a description" multiline minRows={3} maxRows={4} onChange={handleCustomerLogDescriptionChange} />
          </Stack>
        </UniversalDialog>
      )}
    </div>
  );
};

export default CustomerLogs;
