// third-party
import { combineReducers } from 'redux';

// project import
import customerActions from './customReducers/customerActions';
import customer from './customReducers/slice.customer';
import followUpActions from './customReducers/slice.followUps';
import menu from './menu';
import snackbar from './snackbar';
import settings from './customReducers/slice.settings';
import estimateOptions from './customReducers/slice.estimateOptions';
import punching from './punching';
import job from './customReducers/slice.jobs';
import universalActionOption from './customReducers/UniversalAction';
import calendarOption from './customReducers/slice.calendar';
import register from './customReducers/slice.register';
import logsAction from './customReducers/slice.logs';
import tabSummaryOption from './customReducers/slice.tabSummary';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  customerActions,
  followUpActions,
  customer,
  settings,
  estimateOptions,
  punching,
  job,
  universalActionOption,
  calendarOption,
  register,
  logsAction,
  tabSummaryOption
});

export default reducers;
