import { CloseCircleFilled, UserAddOutlined } from '@ant-design/icons';
import { AppBar, Dialog, DialogContent, IconButton, Slide, Toolbar, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import AddMaterialForm from 'components/forms/AddMaterialForm';
import { IAddMaterialPopupWActions } from 'pages/Settings/types/types.materialPopup';
import { forwardRef } from 'react';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddMaterialPopup = (props: IAddMaterialPopupWActions) => {
  const handleSuccess = () => {
    props?.onClose();
    props?.onSave();
  };
  return (
    <Dialog fullWidth maxWidth={'md'} {...props.action} open={props.action?.open} TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <UserAddOutlined />
          <Typography sx={{ ml: 2, flex: 1 }} variant="h4" component="div">
            {props?.action?.isEditMode ? 'Edit Material' : 'Add Material'}
          </Typography>
          <IconButton edge="start" color="inherit" onClick={props?.onClose} aria-label="close">
            <CloseCircleFilled />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <AddMaterialForm existingData={props?.data?.selectedData} isEditMode={props?.action?.isEditMode} onSuccess={handleSuccess} />
      </DialogContent>
    </Dialog>
  );
};

export default AddMaterialPopup;
