import { CloseOutlined, DeleteOutlined, LoadingOutlined, PlusCircleOutlined, SendOutlined } from '@ant-design/icons';
import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  Box,
  Button,
  Checkbox,
  Chip,
  createFilterOptions,
  FilterOptionsState,
  FormHelperText,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  InputLabel,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { useQuery } from '@tanstack/react-query';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import { TCustomerPhones, TJobCompleteData } from 'components/tables/interfaces/jobTableInterface';
import { universalRenerMedia } from 'components/UniversalRenderMedia';
import dayjs from 'dayjs';
import { getIn, useFormik } from 'formik';
import { TCustomerAddEmailSMSPopup, TCustomerAddSMSPopupData } from 'pages/customers/types/type.customersView';
import { useEffect, useState } from 'react';
import { CountryData } from 'react-phone-input-2';
import { useLocation } from 'react-router';
import EmailSmsServicesInstance from 'services/services.emailSms';
import FileUploadServiceInstance from 'services/services.fileUpload';
import JobServicesInstance from 'services/services.jobs';
import * as Yup from 'yup';

const filterEmails = createFilterOptions<string>();
const AddEmailorSms = (props: TCustomerAddEmailSMSPopup) => {
  //----------------------constants-----------------
  const theme = useTheme();
  const { action } = props;
  const location = useLocation();

  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [newPhoneData, setNewPhoneData] = useState<TCustomerPhones[0]>({ phone: '', phone_country_code: '' });

  //----------------------useFormik-----------------
  const formik = useFormik<TCustomerAddSMSPopupData>({
    initialValues: props.action.isEmail
      ? {
          job: { id: '', name: '' },
          customer: { id: '', name: '' },
          to: [] as string[],
          message: '',
          subject: '',
          attachments: []
        }
      : {
          customer: { id: '', name: '' },
          to: [] as string[],
          message: '',
          attachments: [],
          schedule_sms: false
        },
    validationSchema: Yup.object().shape({
      to: props.action.isEmail
        ? Yup.array().of(Yup.string().email('Enter valid email')).required('This field is required')
        : Yup.array().of(Yup.string()).required('This field is required'),
      subject: props.action.isEmail ? Yup.string().required('This field is required') : Yup.string(),
      message: Yup.string().required('This field is required')
    }),
    onSubmit: async (values: TCustomerAddSMSPopupData, { setSubmitting }) => {
      setSubmitting(true);
      const isCustomerModule = location.pathname.includes('customers');
      if (isCustomerModule && !values.job?.id.length && !values.job?.name.length) delete values.job;
      const response = props.action.isEmail
        ? await EmailSmsServicesInstance.sendEmail(values)
        : await EmailSmsServicesInstance.sendSms(values);
      if (response) props?.onClose?.();
      setSubmitting(false);
    }
  });
  //----------------------useQuery-----------------

  const { data: allJobsData } = useQuery({
    queryKey: ['job_data'],
    queryFn: () =>
      JobServicesInstance.getAllJobs(undefined, {
        search: [
          [
            {
              field_name: 'status',
              field_value: 1,
              operator: 'exactmatch'
            },
            {
              field_name: 'customer.id',
              field_value: props?.data?.id || '',
              operator: 'exactmatch'
            }
          ]
        ]
      })
  });
  //----------------------handlers-----------------
  const handlePhoneInputChange = (phone: string, country: CountryData, e: React.ChangeEvent<HTMLInputElement>, formattedValue: string) => {
    setNewPhoneData({ phone_country_code: country.dialCode, phone: phone.slice(country.dialCode.length) });
  };
  const handleAddNumber = () => {
    formik.setFieldValue('to', [...formik.values.to, `${newPhoneData.phone_country_code}${newPhoneData.phone}`]);
    setNewPhoneData({ phone_country_code: '', phone: '' });
  };
  const handleRemovePhoneNumber = (removeNumberIndex: number) => {
    const dummyData = (formik.values.to as string[]).filter((phoneDetails, index) => index !== removeNumberIndex);
    formik.setFieldValue('to', dummyData);
  };
  //----------------------handlers: attachments-----------------

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setIsFileUploading(true);

      const FilesData = Object.values(event.target.files);
      const newImagesData = !!formik.values.attachments?.length ? [...formik.values.attachments] : [];
      const uploadPromises = FilesData.map((eachFile, index) => {
        return FileUploadServiceInstance.uploadFile(eachFile);
      });

      Promise.all(uploadPromises)
        .then((response) => {
          setIsFileUploading(false);
          response.forEach((eachImage) => {
            newImagesData.push(eachImage?.data);
          });
        })
        .catch((err) => {});

      formik.setFieldValue('attachments', newImagesData);
    }
  };

  const handleRemoveSelectedImage = (index: number) => {
    const newImagesData = !!formik.values?.attachments?.length ? [...formik.values?.attachments] : [];
    newImagesData.splice(index, 1);
    formik.setFieldValue('attachments', newImagesData);
  };
  //---------------------handler:To--------------------
  const handleToChange = (event: React.SyntheticEvent<Element, Event>, newValue: string[]) => {
    const emailExist = (formik.values.to as string[]).includes(newValue[newValue.length - 1]);
    if (!emailExist) formik.setFieldValue('to', newValue);
    else formik.setFieldValue('to', newValue);
  };
  const handleToFilterOption = (options: string[], params: FilterOptionsState<string>): string[] => {
    const filtered = filterEmails(options, params);
    const { inputValue } = params;
    const isExisting = options.some((option) => inputValue === option);
    if (inputValue !== '' && !isExisting) {
      filtered.push(inputValue);
    }

    return filtered;
  };
  const handleToRenderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: string) => {
    return (
      <Box component="li" {...props}>
        {!(formik.values.to as string[]).some((v) => option.includes(v)) ? `Add "${option}"` : option}
      </Box>
    );
  };
  const handleToRenderTags = (value: string[], getTagProps: AutocompleteRenderGetTagProps) =>
    value.map((option, index) => {
      let error = false;
      if (formik.touched.to && formik.errors.to && typeof formik.errors.to !== 'string') {
        if (typeof formik.errors.to[index] === 'object') error = true;
      }

      return (
        <Chip
          {...getTagProps({ index })}
          variant="combined"
          color={error ? 'error' : 'secondary'}
          label={
            <Typography variant="caption" color="secondary.dark">
              {option}
            </Typography>
          }
          deleteIcon={<CloseOutlined style={{ fontSize: '0.875rem' }} />}
          size="small"
        />
      );
    });
  //----------------------useEffect-----------------
  useEffect(() => {
    if (!!props.data) {
      if (props.action.isEmail && !!props.data.emails) {
        formik.setValues({
          job: { id: props?.job?.id ?? '', name: props?.job?.name ?? '' },
          customer: { id: props?.data?.id as string, name: props?.data?.name as string },
          to: props.data.emails as string[],
          message: '',
          attachments: [],
          subject: ''
        });
        return;
      } else if (!props.action.isEmail && !!props.data.phones) {
        formik.setValues({
          customer: { id: props?.data?.id as string, name: props?.data?.name as string },
          to: props?.data?.phones.map((phoneDetails) => `${phoneDetails.phone_country_code}${phoneDetails.phone}`) as string[],
          message: '',
          attachments: [],
          schedule_sms: false
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  useEffect(() => {
    console.log('formik.errors', formik.errors);
  }, [formik.errors]);
  return (
    <Grid container spacing={2} component={'form'} onSubmit={formik.handleSubmit}>
      {/*--------------------Job Selection---------------*/}
      {props?.action?.showJobName && (
        <Grid item xs={12}>
          <InputLabel htmlFor="emails">Job Name</InputLabel>
          <Autocomplete
            value={allJobsData?.jobs.find((singleJob) => singleJob._id === formik.values.job?.id) ?? { job_name: '' }}
            disableClearable
            getOptionLabel={(option: any) => option?.job_name}
            options={allJobsData?.jobs || []}
            onChange={(event: React.SyntheticEvent<Element, Event>, newValue: TJobCompleteData) =>
              formik.setFieldValue('job', { id: newValue._id, name: newValue.job_name })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Job Name"
                name="job"
                sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
              />
            )}
          />
        </Grid>
      )}
      <Grid xs={12} item container spacing={2}>
        {/*--------------------Emails and phone---------------*/}
        {props?.action?.isEmail ? (
          <Grid item xs={12}>
            <InputLabel htmlFor="emails">Email*</InputLabel>
            <Autocomplete
              value={formik.values.to ?? []}
              id="to"
              sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
              multiple
              fullWidth
              autoHighlight
              disableCloseOnSelect
              options={formik.values.to ?? []}
              onBlur={formik.handleBlur}
              getOptionLabel={(option: string) => option}
              onChange={handleToChange}
              filterOptions={handleToFilterOption}
              renderOption={handleToRenderOption}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="to"
                  error={Boolean(formik.touched.to && formik.errors.to)}
                  placeholder="Enter emails separated by commas"
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' || event.key === ',') {
                      event.preventDefault();

                      // Cast event.target to HTMLInputElement to access its value
                      const inputElement = event.target as HTMLInputElement;
                      const inputValue = inputElement.value.trim();

                      if (inputValue) {
                        // Split by comma and trim each email
                        const newEmails: string[] = inputValue
                          .split(',')
                          .map((email: string) => email.trim())
                          .filter((email: string) => email);
                        // If valid emails are present, update formik value
                        if (newEmails.length > 0) {
                          const updatedEmails: string[] = [...formik.values.to, ...newEmails];
                          formik.setFieldValue('to', updatedEmails);
                          formik.setTouched({ ...formik.touched, to: true });
                          // Clear the input field after emails are added
                          inputElement.value = '';
                        }
                      }
                    }
                  }}
                />
              )}
              renderTags={handleToRenderTags}
            />
            {getIn(formik.touched, 'to') && getIn(formik.errors, 'to') && (
              <FormHelperText error id="helper-text-email">
                {getIn(formik.errors, 'to')}
              </FormHelperText>
            )}
          </Grid>
        ) : (
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12}>
              <InputLabel htmlFor="phones">To</InputLabel>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
                <div className="col-span-1 sm:col-span-2 w-full">
                  <PhoneNumberInput
                    className="w-full"
                    value={`${newPhoneData.phone_country_code}${newPhoneData.phone}`}
                    onChange={handlePhoneInputChange}
                  />
                </div>
                <Button
                  variant="contained"
                  startIcon={<PlusCircleOutlined />}
                  size="small"
                  className="col-span-1 sm:col-span-1"
                  disabled={!newPhoneData.phone.length}
                  onClick={handleAddNumber}
                >
                  Add Number
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} className="flex flex-wrap gap-2">
              {(formik.values.to as string[]).map((phoneDetails, index) => (
                <Button
                  variant="outlined"
                  endIcon={<PlusCircleOutlined rotate={45} />}
                  color="info"
                  size="small"
                  onClick={() => handleRemovePhoneNumber(index)}
                >
                  {phoneDetails}
                </Button>
              ))}
            </Grid>
          </Grid>
        )}
        {/*--------------------Subject---------------*/}
        {props?.action?.isEmail && (
          <Grid item xs={12}>
            <InputLabel htmlFor="subject">Subject*</InputLabel>

            <TextField
              className="w-full"
              value={formik.values.subject}
              name="subject"
              onChange={formik.handleChange}
              sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
            />
            {getIn(formik.touched, 'subject') && getIn(formik.errors, 'subject') && (
              <FormHelperText error id="helper-text-password">
                {getIn(formik.errors, 'subject')}
              </FormHelperText>
            )}
          </Grid>
        )}
      </Grid>
      {/*--------------------Message---------------*/}

      <Grid item xs={12}>
        <InputLabel htmlFor="message">Message*</InputLabel>

        <TextField
          fullWidth
          multiline
          rows={3}
          name="message"
          value={formik.values.message}
          onChange={formik.handleChange}
          sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
        />
        {getIn(formik.touched, 'message') && getIn(formik.errors, 'message') && (
          <FormHelperText error id="helper-text-password">
            {getIn(formik.errors, 'message')}
          </FormHelperText>
        )}
      </Grid>
      {/*--------------------Attachments---------------*/}

      <Grid item xs={12}>
        <input
          style={{ display: 'none' }}
          id="upload-file"
          type="file"
          multiple
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileUpload(event)}
        />
        <label htmlFor="upload-file">
          <Button
            variant="dashed"
            color="primary"
            component="span"
            className="lg:w-1/3 w-full"
            startIcon={isFileUploading && <LoadingOutlined />}
            disabled={isFileUploading}
          >
            Add Attachments
          </Button>
        </label>
      </Grid>
      {formik?.values.attachments && !!formik?.values.attachments.length && (
        <Grid item xs={12}>
          <ImageList cols={3} rowHeight={164}>
            {formik.values.attachments.map((singleImage: string, index: number) => (
              <ImageListItem key={index} className="flex items-end">
                <IconButton color="error" onClick={() => handleRemoveSelectedImage(index)}>
                  <DeleteOutlined />
                </IconButton>
                {universalRenerMedia(singleImage, index)}
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      )}
      {!props?.action?.isEmail && (
        <Grid item xs={12}>
          <Checkbox
            id="schedule-check"
            value={formik.values.schedule_sms}
            onChange={() => formik.setFieldValue('schedule_sms', !formik.values.schedule_sms)}
          />
          <label htmlFor="schedule-check">Do you want to schedule it?</label>
        </Grid>
      )}
      {formik.values.schedule_sms && (
        <Grid container item xs={6} md={6}>
          <Stack spacing={1}>
            <InputLabel htmlFor="schedule_date" required>
              Schedule Date
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock
                }}
                value={dayjs(formik.values.scheduled_date_and_time)}
                minDate={dayjs(new Date())}
                onChange={(newValue) => {
                  formik.setFieldValue('scheduled_date_and_time', !!newValue && newValue?.toISOString());
                }}
              />
            </LocalizationProvider>
          </Stack>
        </Grid>
      )}
      <Grid item xs={12} className="flex justify-end">
        <Button
          type="submit"
          variant="contained"
          endIcon={formik.isSubmitting ? <LoadingOutlined /> : <SendOutlined />}
          disabled={formik.isSubmitting}
        >
          Send
        </Button>
      </Grid>
    </Grid>
  );
};
export default AddEmailorSms;
