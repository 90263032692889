import { LoadingOutlined, RedoOutlined } from '@ant-design/icons';
import { TimelineDot } from '@mui/lab';
import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { GoogleMap, InfoWindow, Marker, MarkerClusterer, useLoadScript } from '@react-google-maps/api';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import car_marker_true from 'assets/images/icons/truck-green.png';
import car_marker_cluster from 'assets/images/icons/truck-primary.png';
import car_marker_false from 'assets/images/icons/truck-red.png';
import car_marker_idol from 'assets/images/icons/truck-yellow.png';
import useConfig from 'hooks/useConfig';
import useTimezone from 'hooks/useTimezone';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { dispatch, useSelector } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { googleMapsApiKey } from 'utils/constants';
import { TGpsCoordinates } from '../types/types.report';

const containerStyle = {
  width: '100%',
  height: '70dvh'
};

const userActivityStatusToCarIcon = {
  0: car_marker_false,
  1: car_marker_true,
  2: car_marker_idol
};

const clusterStyles = [
  {
    url: car_marker_cluster, // Your custom cluster icon path
    height: 50,
    width: 50,
    textColor: '#ffffff',
    textSize: 12
  },
  {
    url: car_marker_cluster,
    height: 60,
    width: 60,
    textColor: '#ffffff',
    textSize: 14
  },
  {
    url: car_marker_cluster,
    height: 70,
    width: 70,
    textColor: '#ffffff',
    textSize: 16
  }
];

const defaultLocation = { center: { lat: Number(40.7128), lng: Number(-74.006) }, all: [] };

const minActiveMinutes = 30;
const maxIdolMinutes = 180;

type TWorkerCardProps = {
  workerData: any;
};

function WorkerMarkerCard({ workerData }: TWorkerCardProps) {
  const themeConfig = useConfig();
  const formatetimezone = useTimezone();

  const timeAgoString = (ts: string) => {
    const d = moment.duration(moment().diff(moment(ts)));
    return `${d.days()} days ${d.hours()} hours ${d.minutes()} minutes ago`;
  };

  const lastOnlineTime = useMemo(() => workerData.last_time ?? workerData.last_online, [workerData]);

  return (
    <Card className="bg-transparent relative flex flex-col items-center w-full !p-0" variant="elevation" elevation={0}>
      <CardContent
        className={`flex flex-col items-start gap-2 !p-0 !z-[2] ${themeConfig.mode === 'dark' ? 'dark:bg-gray-800' : 'bg-white'}`}
      >
        <Typography className="font-bold text-lg">{workerData?.user?.name ?? workerData?.title ?? ''}</Typography>
        {lastOnlineTime && (
          <Typography className="text-base">
            Reported on {formatetimezone(lastOnlineTime ?? '')} ({timeAgoString(lastOnlineTime)})
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

const GpsCoordinatesReport = ({
  reportData,
  isReportDataFetching,
  refetchReportdata
}: {
  reportData: TGpsCoordinates[];
  isReportDataFetching: boolean;
  refetchReportdata: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<unknown, Error>>;
}) => {
  //-----------------------constants----------------
  const mapRef = useRef<google.maps.Map | null>(null);
  const [selectedFilter, setSelectedFilter] = useState<string>('show_all');
  const [gpsData, setGpsData] = useState<TGpsCoordinates[]>([]);
  const formatDateTime = useTimezone();
  const settingsState = useSelector((state) => state.settings.generalSettings);

  const [markerMenuAnchorEl, setMarkerMenuAnchorEl] = useState<{
    position: { lat: number; lng: number } | null;
    workerData?: any;
  }>({ position: null });

  const location = useLocation();
  const userId = new URLSearchParams(location.search).get('uid');

  useEffect(() => {
    if (!userId) return;
    setSelectedFilter(userId);
  }, [userId]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey ?? ''
  });

  const center = useMemo(() => {
    const centerCoordinateData = reportData.find(
      (singleData) => singleData.last_time !== null && singleData.latitude.length > 0 && singleData.longitude.length > 0
    );
    return { lat: Number(centerCoordinateData?.latitude ?? 26.8371622), lng: Number(centerCoordinateData?.longitude ?? 75.8340063) };
  }, [reportData]);

  const handleMapLoad = (map: google.maps.Map) => {
    mapRef.current = map;
  };

  //--------------------------useEffect-----------------------------
  useEffect(() => {
    if (!reportData) {
      return;
    }

    const updatedReportData = reportData.map((singleUserData) => {
      const lastLocationRecorded = moment().diff(moment(singleUserData.last_time), 'minutes');

      let activity_status = singleUserData.activity_status;
      if (lastLocationRecorded <= minActiveMinutes) {
        activity_status = 1;
      } else if (lastLocationRecorded > minActiveMinutes && lastLocationRecorded <= maxIdolMinutes) {
        activity_status = 2;
      } else {
        activity_status = 0;
      }

      return {
        ...singleUserData,
        activity_status: activity_status
      };
    });

    setGpsData(() => [
      {
        user: { id: 'show_all', name: 'Show All' },
        activity_status: 0,
        last_time: '',
        latitude: '',
        longitude: ''
      },
      ...updatedReportData
    ]);
  }, [reportData]);

  const userLocationMarkers = useMemo(() => {
    const allKnownTypeLocations: TGpsCoordinates[] = Array.isArray(reportData) ? reportData : [];

    const filtered = allKnownTypeLocations.filter((location) => {
      const hasValidTime = location.last_time !== null;
      const hasValidLatitude = location.latitude && !isNaN(Number(location.latitude));
      const hasValidLongitude = location.longitude && !isNaN(Number(location.longitude));
      return hasValidTime && hasValidLatitude && hasValidLongitude;
    });

    if (filtered.length === 0) return defaultLocation;

    return {
      center: {
        lat: Number(filtered[0].latitude),
        lng: Number(filtered[0].longitude)
      },
      all: filtered.map((location) => {
        const { latitude, longitude, user, last_time } = location;
        let activity_status = location.activity_status;
        let icon = userActivityStatusToCarIcon[activity_status] || car_marker_false;
        const lastLocationRecorded = moment().diff(moment(last_time), 'minutes');

        if (lastLocationRecorded <= minActiveMinutes) {
          icon = userActivityStatusToCarIcon[1];
          activity_status = 1;
        } else if (lastLocationRecorded > minActiveMinutes && lastLocationRecorded <= maxIdolMinutes) {
          icon = userActivityStatusToCarIcon[2];
          activity_status = 2;
        } else {
          icon = userActivityStatusToCarIcon[0];
          activity_status = 0;
        }

        return {
          key: user.id,
          position: { lat: Number(latitude), lng: Number(longitude) },
          title: user.name,
          icon,
          activity_status
        };
      })
    };
  }, [reportData]);

  const getActivityDotColor = (activityStatus: number) => {
    switch (activityStatus) {
      case 1:
        return 'success';
      case 2:
        return 'warning';
      case 0:
        return 'error';
    }
  };

  return (
    <div className="flex flex-col md:flex-row gap-4">
      <Card className="w-full lg:w-7/12 3xl:w-5/12">
        {!!gpsData.length && (
          <>
            <CardContent>
              <div className="flex space-x-2">
                <Autocomplete
                  fullWidth
                  disableClearable
                  options={gpsData.filter((singleUserData) => singleUserData.last_time !== null)}
                  getOptionLabel={(singleOption) => singleOption.user.name}
                  value={
                    gpsData.find((singleUserData) => singleUserData.user.id === selectedFilter) || {
                      user: { id: '', name: '' },
                      activity_status: 0,
                      last_time: '',
                      latitude: '',
                      longitude: ''
                    }
                  }
                  onChange={(event, newValue: TGpsCoordinates) => {
                    setSelectedFilter(newValue.user.id);
                    setMarkerMenuAnchorEl({ position: null });
                  }}
                  isOptionEqualToValue={(option: TGpsCoordinates, value: TGpsCoordinates) => option.user.id === value.user.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps
                      }}
                    />
                  )}
                />
                <IconButton
                  onClick={() => {
                    refetchReportdata();
                  }}
                  disabled={isReportDataFetching}
                >
                  {isReportDataFetching ? <LoadingOutlined /> : <RedoOutlined />}
                </IconButton>
              </div>
              <CardHeader
                title={
                  <div className="flex flex-row justify-between font-semibold">
                    <Typography className="text-lg font-bold">Name</Typography>
                    <Typography className="text-lg font-bold">Last Online</Typography>
                  </div>
                }
              />
              <List>
                {gpsData.map(
                  (singleItem) =>
                    singleItem.user.id !== 'show_all' && (
                      <ListItemButton
                        onClick={() => {
                          if (!singleItem.latitude) {
                            dispatch(
                              openSnackbar({
                                open: true,
                                message: `No location found for ${singleItem?.user?.name ?? ''}.`,
                                variant: 'alert',
                                alert: {
                                  color: 'error'
                                },
                                close: true
                              })
                            );
                            return;
                          }
                          setSelectedFilter(singleItem.user.id);
                          const position = { lat: Number(singleItem.latitude), lng: Number(singleItem.longitude) };
                          setMarkerMenuAnchorEl((prev) => {
                            if (prev.position?.lat === position.lat && prev.position?.lng === position.lng) {
                              return { position: null };
                            }
                            return { position: position, workerData: singleItem };
                          });
                          if (mapRef.current) {
                            mapRef.current.panTo(position);
                            mapRef.current.setZoom(100); // If you want to zoom in on click
                          }
                        }}
                        key={singleItem.user.id}
                      >
                        <ListItemText
                          primary={
                            <Stack
                              sx={{ paddingLeft: '0.5rem' }}
                              className="flex flex-col sm:flex-row sm:justify-between items-start sm:items-center"
                            >
                              <div className="flex space-x-2 items-center sm:w-1/2 text-start">
                                <TimelineDot color={getActivityDotColor(singleItem.activity_status)} />
                                <span className="text-xs sm:text-sm">{singleItem.user.name}</span>
                              </div>
                              <Typography className="text-xs sm:text-sm sm:w-1/2 text-end">
                                {singleItem.last_time !== null
                                  ? formatDateTime(singleItem.last_time, {
                                      day: '2-digit',
                                      month: '2-digit',
                                      year: 'numeric',
                                      hour: '2-digit',
                                      minute: '2-digit',
                                      hour12: settingsState?.time_format === '12hrs'
                                    })
                                  : 'N/A'}
                              </Typography>
                            </Stack>
                          }
                        />
                      </ListItemButton>
                    )
                )}
              </List>
            </CardContent>
          </>
        )}
      </Card>

      {isLoaded && (
        <>
          {selectedFilter === 'show_all' ? (
            <GoogleMap
              onLoad={handleMapLoad}
              mapContainerClassName="map-container"
              center={userLocationMarkers.center}
              zoom={10}
              key={googleMapsApiKey}
              mapContainerStyle={containerStyle}
            >
              <MarkerClusterer
                averageCenter
                enableRetinaIcons
                gridSize={10}
                options={{
                  styles: clusterStyles,
                  calculator: (markers, numStyles) => {
                    // Extract titles from each marker
                    const markerTitles = markers
                      .map((marker) => marker.getTitle() || '')
                      .filter((t) => t)
                      .join(', ');

                    // Return the cluster properties
                    return {
                      text: String(markers.length), // The number displayed on the cluster
                      index: Math.min(numStyles, markers.length),
                      title: markerTitles // The tooltip shown when hovering over the cluster
                    };
                  }
                }}
              >
                {(clusterer) => {
                  return (
                    <>
                      {userLocationMarkers.all.map((markerProps) => (
                        <Marker
                          key={markerProps.key}
                          position={markerProps.position}
                          title={markerProps.title}
                          icon={markerProps.icon}
                          clusterer={clusterer}
                          onClick={() => {
                            // Center and open InfoWindow
                            if (mapRef.current) {
                              mapRef.current.panTo(markerProps.position);
                              mapRef.current.setZoom(100); // If you want to zoom in on click
                            }
                            setMarkerMenuAnchorEl((prev) => {
                              if (prev.position?.lat === markerProps.position.lat && prev.position?.lng === markerProps.position.lng) {
                                // Hide if same marker is clicked again
                                return { position: null };
                              }
                              return { position: markerProps.position, workerData: markerProps };
                            });
                          }}
                        />
                      ))}
                    </>
                  );
                }}
              </MarkerClusterer>
              {markerMenuAnchorEl.position && markerMenuAnchorEl.workerData && (
                <InfoWindow
                  position={markerMenuAnchorEl.position}
                  options={{
                    pixelOffset: new window.google.maps.Size(0, -55)
                  }}
                  onCloseClick={() => setMarkerMenuAnchorEl({ position: null })}
                >
                  <WorkerMarkerCard workerData={markerMenuAnchorEl.workerData} />
                </InfoWindow>
              )}
            </GoogleMap>
          ) : (
            <GoogleMap
              onLoad={handleMapLoad}
              mapContainerClassName="map-container"
              center={center}
              zoom={10}
              key={googleMapsApiKey}
              mapContainerStyle={containerStyle}
            >
              {gpsData.map((singleUserData) => {
                if (singleUserData.user.id === selectedFilter) {
                  const position = { lat: Number(singleUserData.latitude), lng: Number(singleUserData.longitude) };

                  return (
                    <Marker
                      title={singleUserData.user.name}
                      position={position}
                      icon={`${
                        singleUserData.activity_status === 1
                          ? car_marker_true
                          : singleUserData.activity_status === 2
                          ? car_marker_idol
                          : singleUserData.activity_status === 0 && car_marker_false
                      }`}
                      onClick={() => {
                        // Center and open InfoWindow
                        if (mapRef.current) {
                          mapRef.current.panTo(position);
                          mapRef.current.setZoom(100); // If you want to zoom in on click
                        }
                        setMarkerMenuAnchorEl((prev) => {
                          if (prev.position?.lat === position.lat && prev.position?.lng === position.lng) {
                            // Hide if same marker is clicked again
                            return { position: null };
                          }
                          const data = {
                            title: singleUserData.user.name,
                            last_online: singleUserData.last_time
                          };
                          return { position: position, workerData: data };
                        });
                      }}
                    />
                  );
                }
                return null;
              })}
              {markerMenuAnchorEl.position && markerMenuAnchorEl.workerData && (
                <InfoWindow
                  position={markerMenuAnchorEl.position}
                  options={{
                    pixelOffset: new window.google.maps.Size(0, -55)
                  }}
                  onCloseClick={() => setMarkerMenuAnchorEl({ position: null })}
                >
                  <WorkerMarkerCard workerData={markerMenuAnchorEl.workerData} />
                </InfoWindow>
              )}
            </GoogleMap>
          )}
        </>
      )}
    </div>
  );
};

export default GpsCoordinatesReport;
