import { TEvent, TGetResponseWEvent } from 'pages/calendar/types/types.calendar';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import axiosServices from 'utils/axios';

class CalendarServices {
  getCalendarData = async (selectedTab: string, month: number, year: number, areaName?: string) => {
    try {
      const response = await axiosServices.get(
        `api/calendar/reports?type=${selectedTab}&month=${month}&year=${year}${
          selectedTab === 'area_availabilities' ? `&area_name=${areaName}` : ''
        }`
      );
      if (response.data.success && response.data.data) {
        return response.data.data;
      }
    } catch (error: unknown) {
      // const knownError = error as { message: string };
      // dispatch(
      //   openSnackbar({
      //     open: true,
      //     message: knownError.message,
      //     variant: 'alert',
      //     alert: {
      //       color: 'error'
      //     },
      //     severity: 'error',
      //     close: true
      //   })
      // );
    }
  };
  getEventData = async (selectedDate: string) => {
    try {
      const response: TGetResponseWEvent = await axiosServices.get(`api/calendar?date=${selectedDate}`);
      if (response.data.success && response.data.data) {
        return response.data.data;
      }
    } catch (error: unknown) {
      // const knownError = error as { message: string };
      // dispatch(
      //   openSnackbar({
      //     open: true,
      //     message: knownError.message,
      //     variant: 'alert',
      //     alert: {
      //       color: 'error'
      //     },
      //     severity: 'error',
      //     close: true
      //   })
      // );
    }
  };
  deleteEventData = async (eventId: string) => {
    try {
      const response: { data: { success: boolean } } = await axiosServices.delete(`api/calendar/${eventId}`);
      if (response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Event deleted successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  updateEventData = async (eventData: TEvent) => {
    try {
      const response = await axiosServices.post('api/calendar/upsert-calendar', eventData);
      if (response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Event updated successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
}
const calendarServicesInstance = new CalendarServices();
export default calendarServicesInstance;
