import { DeleteOutlined, PlusOutlined, UndoOutlined } from '@ant-design/icons';
import { LoadingButton } from '@mui/lab';
import { Button, CardMedia, IconButton, ImageListItem, ImageListItemBar, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import companyServicesInstance from 'services/services.company';
import FileUploadServiceInstance from 'services/services.fileUpload';
import MediaServicesInstance from 'services/services.media';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { dataURLtoBlob, generateRandomKeyword } from 'utils/common';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const UploadPhotosWidget = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const companyId = new URLSearchParams(location.search).get('cid');
  const customerId = new URLSearchParams(location.search).get('csid');
  const jobId = new URLSearchParams(location.search).get('jid');

  // const [isFileUploading, setIsFileUploading] = useState(false);
  const [captcha, setCaptcha] = useState<string>('');
  const [rawFileData, setRawFileData] = useState<{ file: string | null | undefined; name: string }[]>([]);
  const [enteredCaptcha, setEnteredCaptcha] = useState<string>('');
  const [comment, setComment] = useState('');
  const [companyMetaData, setCompanyMetaData] = useState({ _id: '', company_name: '', company_logo: '' });

  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    createNewCaptchaPattern(4);

    const getCompanyMetaData = async () => {
      if (!companyId) return;
      const resp = await companyServicesInstance.getCompanyMetaData(companyId);
      if (resp) setCompanyMetaData(resp);
    };

    getCompanyMetaData();
  }, []);

  const createNewCaptchaPattern = (length: number) => {
    setCaptcha(generateRandomKeyword(length));
  };

  const handleCaptchaInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEnteredCaptcha(event.target.value.toLowerCase());
  };

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    let selectedFile;
    if (event.target.files) {
      selectedFile = event?.target.files[0];
    }

    if (selectedFile) {
      if (!selectedFile.type.startsWith('image/')) {
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target) {
          setRawFileData((prev) => [...prev, { file: e.target?.result ? String(e.target.result) : null, name: selectedFile.name }]);
        }
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  const handleFileUploadSubmit = async () => {
    const promisArray: Promise<{ data?: any; success: boolean } | undefined>[] = [];
    rawFileData.forEach((selectedFile) => {
      if (selectedFile.file) {
        const blob = dataURLtoBlob(selectedFile.file);
        const uploadPromise = FileUploadServiceInstance.uploadFile(blob, selectedFile.name, false);
        promisArray.push(uploadPromise);
      }
    });
    return Promise.all(promisArray);
  };

  const handleSuccess = async () => {
    if (!rawFileData.length) {
      dispatch(
        openSnackbar({
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          open: true,
          message: 'Please upload at least one file.',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
      return;
    }

    if (captcha !== enteredCaptcha) {
      dispatch(
        openSnackbar({
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          open: true,
          message: 'Invalid Captcha.',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
      return;
    }

    setIsSubmitting(true);
    let fileUrls: any[] = [];

    const uploadedFiles = await handleFileUploadSubmit();
    if (uploadedFiles) {
      uploadedFiles.forEach((uploadedFile) => {
        if (uploadedFile?.success) {
          fileUrls.push(uploadedFile?.data);
        }
      });
    }

    const payload = {
      company_id: companyId,
      customer_id: customerId,
      comment,
      files: fileUrls,
      ...(jobId && { job_id: jobId })
    };

    await MediaServicesInstance.addCustomerMedia(payload);
    setIsSubmitting(false);
    navigate(`/thankyou/${companyId}?fm=photos-link`);
  };

  const handleDeleteFile = (index: number) => {
    setRawFileData((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
  };

  return (
    <div className="w-full h-[100dvh] flex flex-col items-center justify-start p-6">
      <div className="mt-1 mb-12 flex flex-col items-center w-full">
        {!companyMetaData?.company_logo && <img className="w-[5rem]" src={companyMetaData?.company_logo} alt={''} />}
        <Typography color={'primary'} variant="h2" className="font-black">
          {companyMetaData?.company_name}
        </Typography>
      </div>
      <div
        style={{
          overflow: 'scroll',
          scrollbarWidth: 'none', // For Firefox
          msOverflowStyle: 'none' // For IE/Edge
        }}
        className="flex flex-col items-start lg:w-1/3 sm:w-1/2 w-full gap-6 max-h-[90dvh] overflow-y-auto"
      >
        {/* --------Step 1-------- */}
        <div className="flex flex-col items-start gap-y-2 w-full">
          <Typography color={'primary'} variant="h2" className="font-black">
            STEP 1
          </Typography>
          <Typography variant="h6" className="font-medium">
            Click the button below to add as many photos as necessary.
          </Typography>
          <Button
            disabled={isSubmitting}
            fullWidth
            variant="dashed"
            startIcon={<PlusOutlined />}
            component="label"
            className="font-bold text-lg"
          >
            Add Photos
            <VisuallyHiddenInput type="file" accept="*/*" onChange={handleImageChange} />
          </Button>
          {rawFileData.length > 0 && (
            <Typography variant="h6" className="font-medium">
              Selected Files
            </Typography>
          )}
          {rawFileData.length > 0 && (
            <div className="flex flex-col items-start gap-y-2 max-w-full overflow-auto">
              <div className="flex flex-row gap-x-2 snap-x">
                {rawFileData
                  .map((file, index) => (
                    <ImageListItem
                      key={file.name}
                      className="border border-gray rounded-sm snap-center"
                      sx={{ minHeight: '150px', maxHeight: '150px', width: '185px', minWidth: '150px', maxWidth: '200px' }}
                    >
                      <CardMedia component={'img'} src={file.file as string} alt={`Media ${index + 1}`} loading="lazy" className="h-full" />
                      <ImageListItemBar
                        position={'bottom'}
                        className="bg-black/50"
                        title={<Typography sx={{ color: 'white', fontWeight: 'bold' }}>{file.name}</Typography>}
                        subtitle={'file'}
                        actionIcon={
                          <IconButton
                            size="medium"
                            color="error"
                            className="bg-red-600 !text-white mr-1"
                            onClick={() => handleDeleteFile(index)}
                          >
                            <DeleteOutlined className="text-white hover:text-red-500" />
                          </IconButton>
                        }
                        actionPosition="right"
                      />
                    </ImageListItem>
                  ))
                  .reverse()}
              </div>
            </div>
          )}
        </div>

        {/* --------Step 2-------- */}
        <div className="flex flex-col items-start gap-y-2 w-full">
          <Typography color={'blue'} variant="h2" className="font-black">
            STEP 2
          </Typography>
          <Typography variant="h6" className="font-medium">
            Add a comment
          </Typography>
          <TextField multiline minRows={4} maxRows={10} fullWidth autoFocus value={comment} onChange={(e) => setComment(e.target.value)} />
        </div>

        {/* --------Step 3-------- */}
        <div className="flex flex-col items-start gap-y-2 w-full">
          <Typography color={'firebrick'} variant="h2" className="font-black">
            STEP 3
          </Typography>
          <Typography variant="h6" className="font-medium">
            Enter the Captcha code and hit submit
          </Typography>
          <div className="flex flex-row space-x-4 my-2 justify-between">
            <Typography
              className="text-4xl font-medium normal-case border-2 border-dashed p-4 select-none text-gray-500"
              sx={{ fontFamily: 'Ribeye Marrow' }}
            >
              {captcha}
            </Typography>
            <Button variant="text" onClick={() => createNewCaptchaPattern(4)} startIcon={<UndoOutlined className="rotate-45" />}>
              Generate New
            </Button>
          </div>
          <div className="flex items-center justify-between w-full gap-6">
            <TextField
              autoComplete="off"
              style={{ textTransform: 'lowercase' }}
              placeholder="Enter Captcha"
              inputProps={{ maxLength: 6 }}
              className="w-full"
              value={enteredCaptcha?.toLowerCase()}
              onChange={handleCaptchaInputChange}
            />
            <LoadingButton
              loading={isSubmitting}
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              className="font-semibold text-xl"
              onClick={handleSuccess}
            >
              Submit
            </LoadingButton>
          </div>
        </div>

        {/* --------Submit-------- */}
      </div>
    </div>
  );
};

export default UploadPhotosWidget;
