import { ExclamationCircleOutlined } from '@ant-design/icons';
import { LoadingButton } from '@mui/lab';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  InputLabel,
  Autocomplete,
  TextField,
  useTheme,
  Grid
} from '@mui/material';
import { Paper } from '@mui/material';
import { Stack } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import NumberInput from 'components/inputs/NumberInput';
import UniversalDialog from 'components/popups/UniversalDialog';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import PublicSerivcesInstance from 'services/service.noAuth';
import { dispatch, useSelector } from 'store';
import { setGeneralSettings } from 'store/reducers/customReducers/slice.settings';
import { getStateAbbrevation, snakeCaseToTitleCase } from 'utils/common';

const defaultPaymentTypes = ['check'];
const creditCardType = ['Visa', 'MasterCard', 'Discover', 'American Express'];

const Checkout = () => {
  const errorMessage = 'This field is required.';
  const { jobId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [jobDetails, setJobDetails] = useState<{ label: string; value: string | number }[]>([]);
  const [paymentTypes, setPaymentTypes] = useState<string[]>(defaultPaymentTypes);
  const [selectedPayment, setSelectedPayment] = useState<string>();
  const [cardDetails, setCardDetails] = useState({ card_number: '', expiry_month: '', expiry_year: '', cvv: '', card_type: '' });
  const [loading, setLoading] = useState(false);
  const [isPaymentCancelled, setIsPaymentCancelled] = useState({ action: false, message: '' });
  const settings = useSelector((state) => state.settings);
  const location = useLocation();

  const { data: jobData } = useQuery({
    queryKey: ['jobDetails'],
    queryFn: () => PublicSerivcesInstance.getJobInfo(jobId as string, 'checkout'),
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (!location) return;
    const searchQuery = new URLSearchParams(location.search);
    const errorObj = searchQuery.get('error');
    const successObj = searchQuery.get('success');
    if (errorObj) {
      setIsPaymentCancelled({ action: true, message: errorObj });
    }
    if (successObj) {
      navigate('/payment/thankyou');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!jobData) return;
    dispatch(setGeneralSettings({ ...settings, type_case: jobData?.type_case }));

    let total = jobData.job.job_price,
      balanceDue = jobData.job.balance_due;
    const approved = jobData?.job.estimations?.options.findIndex((singleOption) => singleOption.approved);
    if (approved !== undefined && approved !== -1 && jobData.job.is_this_job_tax_exempt) {
      total = total - jobData?.job.estimations?.options[approved].tax;
      balanceDue = balanceDue - jobData?.job.estimations?.options[approved].tax;
    }

    setJobDetails([
      { label: 'Invoice #:', value: jobData.job.invoice_number },
      { label: 'Customer Name:', value: jobData.job.customer.name },
      { label: 'Job Name:', value: jobData.job.job_name },
      { label: 'Order Total:', value: `$${total}` },
      { label: 'Balance Due:', value: `$${balanceDue}` }
    ]);
    if (jobData.credit_card && !defaultPaymentTypes.includes('credit_card')) defaultPaymentTypes.push('credit_card');
    if (jobData.venmo && !defaultPaymentTypes.includes('venmo')) defaultPaymentTypes.push('venmo');
    if (jobData.zelle && !defaultPaymentTypes.includes('zelle')) defaultPaymentTypes.push('zelle');
    if (jobData.paypal && !defaultPaymentTypes.includes('paypal')) defaultPaymentTypes.push('paypal');
    setPaymentTypes(defaultPaymentTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobData]);

  const handlePayment = async () => {
    setLoading(true);
    try {
      if (!jobData || !selectedPayment) return;
      let payload = { company_id: jobData?.company._id, payment_type: selectedPayment, amount: jobData?.job.balance_due };

      if (selectedPayment === 'credit_card') {
        payload = { ...payload, ...cardDetails };
      }

      const response = await PublicSerivcesInstance.payNow(jobId as string, payload);
      if (response.success) {
        if (selectedPayment === 'paypal') {
          window.open(response.data);
          return;
        }
        if (selectedPayment === 'credit_card') {
          navigate('/payment/thankyou');
          return;
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return jobData ? (
    <>
      <UniversalDialog
        action={{ open: isPaymentCancelled.action, maxWidth: 'sm', fullWidth: true }}
        hasPrimaryButton={false}
        onClose={() => {
          setIsPaymentCancelled({ action: false, message: '' });
          navigate(`/payment/checkout/${jobId}`);
        }}
      >
        <div className="flex flex-col gap-6">
          <div className="ml-auto mr-auto">
            <Typography sx={{ opacity: 0.23, fontSize: 56, lineHeight: 0 }}>{<ExclamationCircleOutlined />}</Typography>
          </div>
          <Typography fontSize={18} align="center">
            {isPaymentCancelled.message}
          </Typography>
        </div>
      </UniversalDialog>
      <div className="flex mt-12 justify-center">
        <Paper className="pt-5 ml-5 mr-5  lg:w-3/5 w-full">
          <Typography className="ml-5 mb-5" variant="h3">
            Checkout
          </Typography>
          <Divider />
          <div className="p-5">
            <Table>
              <TableBody>
                {jobDetails.map((singleData) => (
                  <TableRow key={singleData.label} className="border-hidden">
                    <TableCell sx={{ paddingX: '0 !important' }} className="w-2/3">
                      <Typography variant="subtitle1" className="font-bold">
                        {singleData.label}
                      </Typography>
                    </TableCell>
                    <TableCell className="p-2 w-1/3">
                      <Typography variant="body1">{singleData.value}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Stack gap={1}>
              <InputLabel className="font-bold">Payment Method*</InputLabel>
              <Autocomplete
                value={selectedPayment}
                disableClearable
                getOptionLabel={(option) => snakeCaseToTitleCase(option)}
                onChange={(event: React.SyntheticEvent<Element, Event>, newValue: string) => {
                  setSelectedPayment(newValue);
                }}
                className="sm:w-1/2 w-full"
                options={paymentTypes}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Payment Method"
                    sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                  />
                )}
              />
            </Stack>

            {selectedPayment === 'paypal' && (
              <div className="flex justify-end mt-2">
                <LoadingButton loading={loading} onClick={handlePayment} className="" variant="contained">
                  Continue
                </LoadingButton>
              </div>
            )}

            {selectedPayment === 'credit_card' && (
              <div>
                <Grid className="mt-5" container gap={1} xs={12}>
                  <Grid className="space-y-1" item xs={6} sm={4}>
                    <InputLabel>Card Type*</InputLabel>
                    <Autocomplete
                      value={cardDetails.card_type}
                      disableClearable
                      onChange={(event, newValue: string) => {
                        setCardDetails((prev) => ({ ...prev, card_type: newValue }));
                      }}
                      getOptionLabel={(eachOption) => eachOption}
                      options={creditCardType}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          helperText={!cardDetails.card_type && errorMessage}
                          error={cardDetails.card_type ? false : true}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid className="mt-5" container gap={1} xs={12}>
                  <Grid className="space-y-1" item xs={6} sm={4}>
                    <InputLabel>Card Number*</InputLabel>
                    <NumberInput
                      fullWidth
                      type="number"
                      value={cardDetails.card_number}
                      args={[]}
                      onChangeHandler={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const inputValue = event.target.value;
                        if (inputValue.charAt(0) !== '-') {
                          setCardDetails((prev) => ({ ...prev, card_number: inputValue }));
                        }
                      }}
                      error={
                        !cardDetails.card_number || cardDetails.card_number?.length < 13 || cardDetails.card_number?.length > 16
                          ? true
                          : false
                      }
                      helperText={
                        !cardDetails.card_number
                          ? errorMessage
                          : cardDetails.card_number?.length < 13 || cardDetails.card_number?.length > 16
                          ? 'Card number must be between 13 and 16 digits'
                          : null
                      }
                    />
                  </Grid>
                  <Grid className="space-y-1" item xs={3}>
                    <InputLabel>Expiry Month*</InputLabel>
                    <NumberInput
                      fullWidth
                      args={[]}
                      type="number"
                      onChangeHandler={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const inputValue = event.target.value;
                        if (Number(inputValue) >= 0 && Number(inputValue) <= 12 && inputValue.charAt(0) !== '-') {
                          setCardDetails((prev) => ({ ...prev, expiry_month: inputValue }));
                        }
                      }}
                      error={
                        cardDetails.expiry_month && Number(cardDetails.expiry_month) <= 12 && Number(cardDetails.expiry_month) >= 1
                          ? false
                          : true
                      }
                      value={cardDetails.expiry_month}
                    />
                  </Grid>
                  <Grid className="space-y-1" item xs={3}>
                    <InputLabel>Expiry Year*</InputLabel>
                    <NumberInput
                      fullWidth
                      type="number"
                      onChangeHandler={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const inputValue = event.target.value;
                        if (inputValue.length > 2) return;
                        if (inputValue.charAt(0) !== '-') {
                          setCardDetails((prev) => ({ ...prev, expiry_year: inputValue }));
                        }
                      }}
                      args={[]}
                      error={!cardDetails.expiry_year || cardDetails.expiry_year?.length !== 2 ? true : false}
                      value={cardDetails.expiry_year}
                    />
                  </Grid>
                  <Grid className="space-y-1" item xs={2}>
                    <InputLabel>CVV*</InputLabel>
                    <NumberInput
                      fullWidth
                      type="number"
                      args={[]}
                      onChangeHandler={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const inputValue = event.target.value;
                        if (inputValue.charAt(0) !== '-') {
                          if (inputValue.length > 4) return;
                          setCardDetails((prev) => ({ ...prev, cvv: inputValue }));
                        }
                      }}
                      error={!cardDetails.cvv || cardDetails.cvv?.length < 3 || cardDetails.cvv?.length > 4 ? true : false}
                      value={cardDetails.cvv}
                    />
                  </Grid>
                </Grid>
                <div className="flex justify-end mt-2">
                  <LoadingButton loading={loading} onClick={handlePayment} className="" variant="contained">
                    Continue
                  </LoadingButton>
                </div>
              </div>
            )}

            {selectedPayment === 'check' && (
              <>
                <div className="mt-5">
                  <Typography className="font-bold">Please make check payable to:</Typography>
                  <Typography className="mb-5">{jobData?.company.company_name}</Typography>
                  <Typography className="font-bold">Send payment to:</Typography>
                  <Typography className="">{`${jobData?.company.company_address.address1}, ${jobData?.company.company_address.address2}`}</Typography>
                  <Typography className="">
                    {`
                    ${jobData?.company.company_address.city},
                    ${getStateAbbrevation(jobData?.company.company_address.state)},
                    ${jobData?.company.company_address.zip}`}
                  </Typography>
                </div>
              </>
            )}
          </div>
        </Paper>
      </div>
    </>
  ) : null;
};

export default Checkout;
