import { EyeFilled, PictureFilled } from '@ant-design/icons';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  ButtonGroup,
  Card,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import RenderMeasurementsUnits from 'components/ShowMeasurementUnits';
import { IEstimationOption, TJobData, TJobItem, TOfficeToWorkerNote } from 'components/tables/interfaces/jobTableInterface';
import { universalRenerMedia } from 'components/UniversalRenderMedia';
import useConfig from 'hooks/useConfig';
import { formatCurrency, formateData, toTitleCase } from 'utils/common';
import { TStateView } from '../JobsViewPage';

const estimateFields = ['estimate title', 'notes to be displayed on the proposal', 'current status', 'file'];

const SingleEstimateOptionView = ({
  activeTab,
  optionData,
  currentStatus,
  estimator,
  dataByCategory,
  categoryTotal,
  office_to_worker_notes,
  jobData,
  ...props
}: {
  activeTab: number;
  optionData: IEstimationOption;
  currentStatus: string;
  estimator: string;
  dataByCategory: { [key: string]: TJobItem[] }[];
  categoryTotal: { [key: string]: number }[];
  office_to_worker_notes: TOfficeToWorkerNote[];
  jobData?: TJobData;
  stateView?: TStateView;
}) => {
  //-----------------------constants---------------------

  const themeConfig = useConfig();

  //--------------------Components-------------------------

  const renderEstimateHeaderContent = () => {
    const renderValue = (field_name: string) => {
      switch (field_name) {
        case 'estimate title':
          return optionData.option_name;
        case 'notes to be displayed on the proposal':
          return optionData.proposal_note?.length ? optionData.proposal_note : 'N/A';
        case 'current status':
          return currentStatus;

        case 'file':
          return !!optionData.files && optionData.files.length ? (
            <Accordion className="w-full">
              <AccordionSummary>View Files ({optionData.files.length})</AccordionSummary>
              <AccordionDetails>
                <div className="flex flex-wrap items-start justify-end w-full">
                  {optionData.files?.map((singleFile, index: number) => (
                    <ImageListItem
                      key={index}
                      className="flex items-center justify-start my-2 !bg-gray-100 rounded-md border max-w-max pt-8"
                      sx={{ height: '100px', minWidth: '200px' }}
                    >
                      {universalRenerMedia(singleFile.url, index)}
                      <ImageListItemBar
                        position="top"
                        className="bg-transparent"
                        actionIcon={
                          <ButtonGroup>
                            <IconButton
                              LinkComponent={'a'}
                              size="medium"
                              color="info"
                              href={singleFile.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <EyeFilled />
                            </IconButton>
                          </ButtonGroup>
                        }
                        actionPosition="right"
                      />
                    </ImageListItem>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          ) : (
            'N/A'
          );
      }
    };

    return (
      <List className="sm:w-8/12 w-full">
        {estimateFields.map((singleItem, index) => {
          return (
            <ListItemButton className="flex items-start gap-x-4">
              <ListItemText
                classes={{ primary: `sm:w-max w-[200px] ${singleItem === 'file' && 'mt-2'}` }}
                primary={toTitleCase(singleItem)}
              />
              {singleItem === 'file' ? (
                renderValue(singleItem)
              ) : (
                <Typography className="text-base text-end">{renderValue(singleItem)}</Typography>
              )}
            </ListItemButton>
          );
        })}
        {props.stateView === 'estimate_info' && (
          <>
            <ListItemButton>
              <ListItemText primary="Estimator" />
              <Typography className="text-base">{jobData?.job.estimator?.name}</Typography>
            </ListItemButton>
            <ListItemButton>
              <ListItemText primary="Estimator Instruction" />
              <Typography color={'#228732'} className="font-semibold text-base">
                {jobData?.job.estimator_instructions}
              </Typography>
            </ListItemButton>
          </>
        )}

        {props.stateView === 'worker_info' &&
          jobData?.job?.job_action?.map((singleJobAction, jobActionIndex) => (
            <ListItemButton className="flex items-start">
              <ListItemText primary={`Field Worker - ${jobActionIndex + 1}`} />
              <div className="flex flex-col items-end gap-2">
                <Typography className="text-base">
                  {singleJobAction.field_worker?.name}&nbsp;&nbsp; ( {formateData(singleJobAction.worker_commission, '$')} )
                </Typography>
                <Typography color={'#228732'} variant="body1" className="font-semibold">
                  {singleJobAction.job_instructions}
                </Typography>
              </div>
            </ListItemButton>
          ))}
      </List>
    );
  };

  const SummarySection = ({ label, value, bold = false }: { label: string; value: number; bold?: boolean }) => (
    <div className="w-full flex items-end justify-between">
      <Typography color={'white'} align="right" className={bold ? 'font-bold' : 'font-normal'}>
        {label}
      </Typography>
      <Typography color={'white'} align="right" className="font-mono">
        ${formatCurrency(value ?? 0)}
      </Typography>
    </div>
  );

  const renderSingleOption = () => {
    return (
      <div className="space-y-8">
        {dataByCategory[activeTab] &&
          Object.keys(dataByCategory[activeTab]).map((singleJobCategory) => (
            <div
              style={{
                backgroundColor: themeConfig.mode === 'dark' ? 'ThreeDLightShadow' : 'ghostwhite'
              }}
              className="flex flex-col items-start justify-around gap-y-1 p-1 overflow-x-auto rounded-sm"
            >
              <Typography color="primary" variant="h3" className="font-normal p-1">
                {singleJobCategory}
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="font-bold ">Image</TableCell>
                    <TableCell className="font-bold ">Description</TableCell>
                    <TableCell className="font-bold ">Units</TableCell>
                    <TableCell className="font-bold ">Taxable</TableCell>
                    <TableCell className="font-bold ">Item Price($)</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody style={{ overflowX: 'auto' }}>
                  {dataByCategory[activeTab][singleJobCategory].map((singleItem, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: themeConfig.mode === 'dark' ? 'ThreeDLightShadow' : 'white'
                      }}
                    >
                      <TableCell>
                        {singleItem.image ? (
                          <a href={singleItem.image} target="_blank" rel="noreferrer">
                            <Avatar variant="rounded" sx={{ maxHeight: '8rem', border: '1px solid lightgrey' }} src={singleItem.image} />
                          </a>
                        ) : (
                          <Avatar variant="rounded" sx={{ maxHeight: '8rem', border: '1px solid lightgrey' }}>
                            <PictureFilled />
                          </Avatar>
                        )}
                      </TableCell>

                      <TableCell className="font-medium">{singleItem.description}</TableCell>
                      <TableCell className="font-medium min-w-max">
                        <RenderMeasurementsUnits
                          measurementData={singleItem.measurements}
                          measure={singleItem.measure_type}
                          showUnitsOnProposal={singleItem.show_units_on_proposal}
                        />
                      </TableCell>
                      <TableCell className="min-w-max">
                        <Typography className="sm:text-base text-xs min-w-max font-mono">
                          <strong>&#x2713; ( ${formatCurrency(singleItem.taxable)} )</strong>
                        </Typography>
                      </TableCell>
                      <TableCell className="font-medium font-mono">${formatCurrency(singleItem.price)}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow
                    sx={{
                      backgroundColor: themeConfig.mode === 'dark' ? 'MenuText' : 'white'
                    }}
                  >
                    <TableCell align="right" colSpan={4} className="font-bold">
                      Total
                    </TableCell>
                    <TableCell align="left" className="font-mono">
                      ${formatCurrency(categoryTotal[activeTab][singleJobCategory] ?? 0)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          ))}

        {/*-----------------------------------Over All Summary------------------------*/}
        <div className="flex flex-row justify-end">
          <div className="rounded-md flex flex-col p-5 md:w-1/2 w-full align-middle justify-center" style={{ backgroundColor: '#34495f' }}>
            <SummarySection label="Sub Total" value={optionData.sub_total} bold />
            <SummarySection label="Tax" value={optionData.tax} bold />
            <SummarySection label="Grand Total" value={optionData.grand_total} bold />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="flex flex-col space-y-10">
      {/*------------------Estimate Header-------------------- */}
      <Stack divider={<Divider flexItem />} spacing={2}>
        {jobData?.job?.job_images?.length && (
          <Grid item xs={12} className="max-h-min overflow-y-auto custom-scrollbar">
            <ImageList className="space-x-1 space-y-1" cols={5}>
              {jobData?.job.job_images?.map((singleFileUrl: string, index: number) => (
                <ImageListItem
                  key={index}
                  component={Card}
                  className="flex justify-center border"
                  sx={{ maxHeight: '250px', minHeight: '100px', minWidth: '100px' }}
                >
                  {universalRenerMedia(singleFileUrl, index)}
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>
        )}

        {renderEstimateHeaderContent()}
      </Stack>

      {renderSingleOption()}
    </div>
  );
};

export default SingleEstimateOptionView;
