import { DeleteOutlined, QuestionCircleFilled } from '@ant-design/icons';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker, DateTimePicker, LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useQuery } from '@tanstack/react-query';
import UniversalDialog from 'components/popups/UniversalDialog';
import {
  IReminder,
  TCustomerPhones,
  TJobCompleteData,
  TOfficeToWorkerNote,
  TScheduleSMSData
} from 'components/tables/interfaces/jobTableInterface';
import TUserData, { SingleEstimator } from 'components/tables/interfaces/userTableInterface';
import dayjs, { Dayjs } from 'dayjs';
import { getIn, useFormik } from 'formik';
// import useAuth from 'hooks/useAuth';
import { LoadingButton } from '@mui/lab';
import CustomTooltip from 'components/CustomTooltip';
import FormSubmissionAlertMessage from 'components/FormSubmissionAlertMessage';
import { universalRenerMedia } from 'components/UniversalRenderMedia';
import useAuth from 'hooks/useAuth';
import moment from 'moment';
import { TCustomerData } from 'pages/customers/types/type.customersPage';
import { ChangeEvent, ForwardedRef, forwardRef, MouseEvent, useEffect, useImperativeHandle, useRef, useState } from 'react';
import AutoAppointmentServiceInstance from 'services/services.autoAppointment';
import EstimateServicesInstance from 'services/services.estimates';
import JobServicesInstance from 'services/services.jobs';
import MediaServicesInstance from 'services/services.media';
import UserServicesInstance from 'services/services.users';
import { dispatch, useSelector } from 'store';
import { getGeneralSettings } from 'store/reducers/customReducers/slice.settings';
import { openSnackbar } from 'store/reducers/snackbar';
import { TMedia } from 'types/globalTypes/type.media';
import { formatCostInDecimal, formatePhonesData, formatJobName, handleEstimateReminder } from 'utils/common';
import { canCustomerAutoScheduleInfo, notificationSendingChannels, reminderTypes, scheduleSmsInfoForEstimator } from 'utils/constants';
import trimFc, { ltrim, rtrim } from 'utils/trimFc';
import * as v4 from 'uuidv4';
import * as Yup from 'yup';
import AddScheduleSMSForm from './AddScheduleSMSForm';
import { TEstimateFormRef } from './JobFormWizard';
import SelectJobImages from './SelectJobImages';
import GoogleMaps from './google-map-autocomplete/GoogleMap';

const AddEstimateForm = forwardRef(
  (
    {
      existingData,
      isEditMode,
      customerData,
      onSubmitSuccess,
      onError
    }: {
      existingData?: TJobCompleteData;
      isEditMode?: boolean;
      customerData?: TCustomerData;
      onSubmitSuccess?: (jobid: string, assignedEstimator?: string) => void;
      onError?: (flag: boolean) => void;
    },
    ref: ForwardedRef<TEstimateFormRef>
  ) => {
    // -------------------------Constants------------------
    const theme = useTheme();
    const { user } = useAuth();

    const { generalSettings: generalSettingsData } = useSelector((state) => state.settings);
    const [officeToWorkerNotes, setOfficeToWorkerNotes] = useState<string>('');
    const [openSheduleSMS, setOpenSheduleSMS] = useState(false);
    const [sheduleSMSValues, setSheduleSMSValues] = useState<TScheduleSMSData>();
    const [reminder, setReminder] = useState<string>('1daybefore');
    const [allEstimator, setAllEstimator] = useState<SingleEstimator[]>([]);
    const [openJobImagesPopup, setOpenJobImagesPopup] = useState<boolean>(false);
    const [files, setFiles] = useState<string[]>([]);

    const inputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});

    //----------------------useQuery--------------------------

    const { data: allAppointmentArea, isFetched: isAllAppointmentAreaFetched } = useQuery({
      queryKey: ['all_area_appointment'],
      queryFn: () => AutoAppointmentServiceInstance.getAllArea()
    });

    const { data: allFetchedEstimators } = useQuery({
      queryKey: ['all_estimator_data'],
      queryFn: () =>
        UserServicesInstance.getAllUsers(undefined, {
          search: [
            [
              {
                field_name: 'status',
                field_value: 1,
                operator: 'exactmatch'
              }
            ],
            [
              { field_name: 'role', field_value: ['estimator', 'company_admin'], operator: 'in' },
              { field_name: 'is_fw_estimator', field_value: true, operator: 'exactmatch' }
            ]
          ]
        })
    });
    const { data: mediaData } = useQuery({
      queryKey: ['media_data'],
      queryFn: () => MediaServicesInstance.getMedia('customers', false, customerData?._id, 'media'),
      enabled: !!customerData?._id
    });

    const [isLoading, setIsLoading] = useState<boolean>(false);
    // --------------------------Formik-------------------
    const formik = useFormik<TJobCompleteData>({
      initialValues: !isEditMode
        ? {
            job_name: formatJobName('custom_name', customerData?.first_name ?? '', customerData?.last_name ?? ''),
            customer: {
              id: '',
              name: '',
              phones: [] as TCustomerPhones,
              emails: []
            },
            job_images: [],
            estimator_instructions: '',
            state: 'estimation',
            estimator: { id: '', name: '', profile_picture: '' },
            estimator_commission: null as unknown as number,
            is_customer_address: true,
            is_estimate_require_date: false,
            customer_want_call_first_for_estimator: false,
            display_job_images: false,
            can_customer_auto_schedule: true,
            address: {
              address1: customerData?.address?.address1 as string,
              address2: customerData?.address?.address2 as string,
              zip: customerData?.address?.zip as string,
              state: customerData?.address?.state as string,
              city: customerData?.address?.city as string,
              country: customerData?.address?.country as string
            },
            estimate_reminder: {
              exclude_time: false,
              scheduled_date_and_time: dayjs().toISOString(),
              reminder_date: '',
              reminder_method: [] as unknown as number[]
            },
            office_to_worker_notes: [] as TOfficeToWorkerNote[],
            log_note: 'Setting up an Estimate/Assigning an Estimator.'
          }
        : {
            job_name: formatJobName('custom_name', customerData?.first_name ?? '', customerData?.last_name ?? ''),
            job_images: [],
            estimator_instructions: '',
            estimator: { id: '', name: '', profile_picture: '' },
            estimator_commission: null as unknown as number,
            is_customer_address: true,
            is_estimate_require_date: false,
            customer_want_call_first_for_estimator: false,
            display_job_images: false,
            address: {
              address1: customerData?.address.address1 as string,
              address2: customerData?.address.address2 as string,
              zip: customerData?.address.zip as string,
              state: customerData?.address.state as string,
              city: customerData?.address.city as string,
              country: customerData?.address.country as string
            },
            estimate_reminder: {
              exclude_time: false,
              scheduled_date_and_time: dayjs().toISOString(),
              reminder_date: '',
              reminder_method: [] as unknown as number[]
            },

            office_to_worker_notes: (existingData?.office_to_worker_notes ?? []) as TOfficeToWorkerNote[],
            log_note: 'Setting up an Estimate/Assigning an Estimator.'
          },

      validationSchema: Yup.object().shape({
        customer: Yup.object().shape({
          phones: Yup.array().of(
            Yup.object().shape({
              phone: Yup.string().required('This field is required'),
              phone_country_code: Yup.string().required('This field is required')
            })
          )
        }),
        job_name: Yup.string().required('This field is required'),
        estimator_instructions: Yup.string().required('This field is required'),
        estimator: Yup.object().shape({
          id: Yup.string().required('This field is required')
        }),
        estimator_commission: Yup.number().when('estimator', ([estimator]) => {
          const selectedEstimator = allFetchedEstimators?.users.find((eachEstimator) => eachEstimator._id === estimator.id);
          if (!selectedEstimator) {
            return Yup.number().nullable().optional();
          }
          if (selectedEstimator?.rate_info?.pay_type === 'commission') {
            return Yup.number().required('This field is required');
          }
          return Yup.number().nullable().optional();
        }),
        log_note: Yup.string().required('Log Note is required'),
        estimate_reminder: Yup.object().when('is_estimate_require_date', {
          is: (value: boolean) => value,
          then: (schema) =>
            schema.shape({
              reminder_date: Yup.string().test({
                name: 'reminder_date',
                exclusive: true,
                message: 'Reminder date is required',
                test: function (value) {
                  return reminder === 'choosecustomedate' ? !!value : true;
                }
              }),
              scheduled_date_and_time: Yup.string().required('This field is required')
            }),
          otherwise: (schema) => schema.nullable()
        }),
        address: Yup.object().when('is_customer_address', {
          is: (value: boolean) => !value,
          then: (schema) =>
            schema.shape({
              address1: Yup.string().required('This field is required'),
              city: Yup.string().required('This field is required'),
              state: Yup.string().required('This field is required'),
              zip: Yup.string().required('This field is required'),
              country: Yup.string().required('This field is required')
            }),
          otherwise: (schema) => schema.nullable()
        })
      }),

      onSubmit: async (values) => {
        setIsLoading(true);

        values.estimator_commission = Number(values.estimator_commission);
        handleJobFormSubmit(values).finally(() => {
          setIsLoading(false);
          dispatch(getGeneralSettings());
        });
      }
    });

    // --------------------------handlers-------------------

    const setFieldRef = (fieldName: string, element: HTMLInputElement | null) => {
      inputRefs.current[fieldName] = element;
    };

    const handleJobFormSubmit = async (values: TJobCompleteData) => {
      if (!values.display_job_images) delete values.job_images;
      if (!!allAppointmentArea && !allAppointmentArea.length) delete values.can_customer_auto_schedule;
      if (!values.is_estimate_require_date)
        values.estimate_reminder = { exclude_time: false, scheduled_date_and_time: '', reminder_date: '', reminder_method: [] };
      else {
        values.estimate_reminder = {
          ...values.estimate_reminder,
          reminder_date: handleEstimateReminder(formik, reminder)
        } as IReminder;
      }

      if (isEditMode && existingData) {
        if (existingData._id) {
          const response = await EstimateServicesInstance.editEstimate(existingData._id, values);
          if (response) onSubmitSuccess?.(existingData._id as string);
        }
      } else {
        values.state = 'estimation';
        const estimateCreated = await EstimateServicesInstance.createEstimate(values);
        if (estimateCreated?.success) {
          const job_id = estimateCreated?.data?.job.id as string;
          const assignedEstimator = estimateCreated?.data?.estimator?.id;

          if (sheduleSMSValues) {
            const scheduleObj: TScheduleSMSData = {
              ...sheduleSMSValues,
              job: {
                id: job_id as string,
                name: estimateCreated.data?.job.name as string
              },
              customer: {
                id: estimateCreated.data?.customer?.id as string,
                name: estimateCreated.data?.customer?.name as string
              }
            };
            await JobServicesInstance.addScheduleSMS(scheduleObj)
              .then((response) => {
                if (response) {
                  onSubmitSuccess?.(job_id, assignedEstimator);
                  dispatch(
                    openSnackbar({
                      open: true,
                      message: 'Job Created Successfully',
                      variant: 'alert',
                      alert: {
                        color: 'success'
                      },
                      close: false
                    })
                  );
                }
              })
              .catch(console.error);
            return;
          }

          onSubmitSuccess?.(job_id, assignedEstimator);
          dispatch(
            openSnackbar({
              open: true,
              message: 'Job Created Successfully',
              variant: 'alert',
              alert: {
                color: 'success'
              },
              close: false
            })
          );
        }
      }
    };

    //-----------DisplayJobImages---------------
    useImperativeHandle(
      ref,
      () => ({
        next: () => formik.handleSubmit()
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    const handleOfficeToWorkerNotes = () => {
      if (!!officeToWorkerNotes?.length && !!ltrim(rtrim(officeToWorkerNotes))?.length) {
        const currentDateTime = new Date();
        const newOfficeToWorkerNotes = [...(formik.values.office_to_worker_notes ?? ([] as TOfficeToWorkerNote[]))];
        newOfficeToWorkerNotes.push({
          text: officeToWorkerNotes,
          id: v4.uuid(),
          created_at: currentDateTime.toISOString(),
          created_by: { id: user?._id as string, name: `${user?.first_name} ${user?.last_name}` },
          updated_at: currentDateTime.toISOString(),
          updated_by: { id: user?._id as string, name: `${user?.first_name} ${user?.last_name}` }
        });
        formik.setFieldValue('office_to_worker_notes', newOfficeToWorkerNotes);
      }
    };

    const handleSheduleSMS = (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setOpenSheduleSMS(true);
    };

    const onAddScheduleSMSFromSubmit = (values: TScheduleSMSData) => {
      setSheduleSMSValues(values);
      setOpenSheduleSMS(false);
      return;
    };

    const getDefaultEstimator = () => {
      const matchedEstimator = allFetchedEstimators?.users.findIndex(
        (eachEstimator: TUserData) => eachEstimator._id === generalSettingsData?.default_estimator.id
      );
      return matchedEstimator && matchedEstimator !== -1 && allFetchedEstimators?.users[matchedEstimator];
    };
    const handleEstimateRequireDate = (event: ChangeEvent<HTMLInputElement>, value: string) => {
      if (value === 'true') {
        setReminder('1daybefore');
        formik.setFieldValue('estimate_reminder', {
          scheduled_date_and_time: new Date().toISOString(),
          reminder_date: new Date().toISOString(),
          reminder_method: [1, 2]
        });
      }
      formik.setFieldValue('is_estimate_require_date', value === 'true');
    };
    const handleDisplayJobImages = (event: ChangeEvent<HTMLInputElement>, value: string) => {
      if (value === 'true') {
        setOpenJobImagesPopup(true);
        return;
      }
      formik.setFieldValue('display_job_images', false);
      formik.setFieldValue('job_images', []);
    };
    const handleSubmitJobImages = (jobImages: string[]) => {
      if (!!jobImages.length) {
        formik.setFieldValue('job_images', jobImages);
        formik.setFieldValue('display_job_images', true);
        setOpenJobImagesPopup(false);
      }
    };
    const handleRemoveSelectedfile = (index: number) => {
      if (!!formik.values?.job_images?.length) {
        const dummyData = [...formik.values.job_images];
        dummyData.splice(index, 1);
        formik.setFieldValue('job_images', dummyData);
        if (dummyData.length === 0) formik.setFieldValue('display_job_images', false);
      }
    };
    //------------------useEffects-------------------

    useEffect(() => {
      if (!isEditMode && generalSettingsData) {
        formik.setFieldValue(
          'job_name',
          formatJobName(
            generalSettingsData?.job_page?.job_name ?? 'custom_name',
            customerData?.first_name ?? '',
            customerData?.last_name ?? '',
            generalSettingsData?.job_page?.job_number
          )
        );
        formik.setFieldValue('is_customer_address', generalSettingsData?.job_page?.is_customer_address);
        formik.setFieldValue('estimate_reminder', {
          ...formik.values.estimate_reminder,
          exclude_time: generalSettingsData?.job_page?.exclude_time
        });

        formik.setFieldValue('is_estimate_require_date', generalSettingsData?.job_page?.is_job_require_date ?? false);
        formik.setFieldValue('customer_want_call_first_for_estimator', generalSettingsData?.job_page?.customer_want_call_first);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generalSettingsData]);

    useEffect(() => {
      !isEditMode &&
        customerData &&
        formik.setFieldValue('customer', {
          id: customerData?._id ?? '',
          name: customerData?.first_name ?? '',
          phones: formatePhonesData(customerData),
          emails: customerData.emails
        });
      if (!customerData?.address) {
        formik.setFieldValue('is_customer_address', false);
      } else {
        formik.setFieldValue('is_customer_address', generalSettingsData?.job_page?.is_customer_address);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerData, isEditMode]);

    useEffect(() => {
      const estimator = allFetchedEstimators?.users.find((estimator: TUserData) => formik.values?.estimator?.id === estimator._id);
      if (!estimator) {
        return;
      }
      if (estimator?.rate_info?.pay_type === 'hourly' || estimator?.rate_info?.pay_type === 'salary') {
        formik.setFieldValue('estimator_commission', '');
      } else {
        if (isEditMode) {
          formik.setFieldValue(
            'estimator_commission',
            existingData?.customer?.property_type === 'residential'
              ? estimator.rate_info?.residential_commission
              : estimator.rate_info?.commercial_commission
          );
        } else {
          formik.setFieldValue(
            'estimator_commission',
            customerData?.property_type === 'residential'
              ? estimator.rate_info?.residential_commission
              : estimator.rate_info?.commercial_commission
          );
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.estimator]);

    useEffect(() => {
      if (isEditMode && !!existingData && existingData.is_estimate_require_date) {
        switch (
          moment(existingData.estimate_reminder?.scheduled_date_and_time).diff(
            moment(existingData.estimate_reminder?.reminder_date),
            'days'
          )
        ) {
          case 1:
            setReminder('1daybefore');
            break;
          case 2:
            setReminder('2daybefore');
            break;
          default:
            setReminder('choosecustomedate');
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [existingData]);

    useEffect(() => {
      if (!!allFetchedEstimators?.users) {
        const estimators = allFetchedEstimators?.users?.map((singleEstimator) => ({
          value: singleEstimator?._id ?? '',
          label: `${singleEstimator.first_name} ${singleEstimator.last_name}`
        }));
        setAllEstimator(() => estimators);
        if (!isEditMode) {
          if (!!generalSettingsData?.default_estimator?.id?.length) {
            const defaultEstimator = getDefaultEstimator();
            if (defaultEstimator) {
              formik.setFieldValue('estimator', {
                id: defaultEstimator._id,
                name: `${defaultEstimator?.first_name} ${defaultEstimator?.last_name}`,
                profile_picture: defaultEstimator?.profile_picture
              });
            }
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allFetchedEstimators?.users]);

    useEffect(() => {
      console.log('Errors in Estimate Form :\n', formik.errors);
    }, [formik.errors]);

    useEffect(() => {
      if (!existingData && !isEditMode) return;
      formik.setValues({
        job_name: existingData?.job_name,
        can_customer_auto_schedule: existingData?.can_customer_auto_schedule,
        job_images: existingData?.job_images,
        estimator_instructions: existingData?.estimator_instructions,
        estimator: existingData?.estimator,
        estimator_commission: existingData?.estimator_commission,
        is_customer_address: existingData?.is_customer_address,
        is_estimate_require_date: existingData?.is_estimate_require_date,
        customer_want_call_first_for_estimator: existingData?.customer_want_call_first_for_estimator,
        display_job_images: existingData?.display_job_images,
        address: existingData?.address,
        estimate_reminder: existingData?.estimate_reminder
          ? { ...existingData?.estimate_reminder, exclude_time: existingData?.estimate_reminder?.exclude_time ?? false }
          : {
              scheduled_date_and_time: new Date().toISOString(),
              reminder_date: '',
              reminder_method: [] as unknown as number[],
              exclude_time: false
            },
        office_to_worker_notes: existingData?.office_to_worker_notes ?? [],
        log_note: isEditMode ? '' : existingData?.log_note
      });
      if (existingData)
        setOfficeToWorkerNotes(
          existingData?.office_to_worker_notes
            ? existingData?.office_to_worker_notes[existingData?.office_to_worker_notes?.length - 1]?.text
            : ''
        );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [existingData, isEditMode]);

    useEffect(() => {
      !!mediaData &&
        setFiles(() => {
          const urls = (mediaData as TMedia[]).map((singleMedia: TMedia) => singleMedia.url);
          return urls;
        });
    }, [mediaData]);

    const getComissionInput = () => {
      const selectedEstimator = allFetchedEstimators?.users.find((estimator: TUserData) => formik.values?.estimator?.id === estimator._id);
      if (selectedEstimator) {
        if (selectedEstimator.rate_info?.pay_type === 'hourly') {
          return <TextField fullWidth disabled aria-disabled value={'Punch Emp.'} InputProps={{ startAdornment: '$' }} />;
        }

        return (
          <TextField
            inputProps={{ min: 0.0 }}
            fullWidth
            inputMode="decimal"
            id="estimator_commission"
            name="estimator_commission"
            value={formik.values.estimator_commission ?? ''}
            onBlur={(event) => {
              const numericValue = parseFloat(event.target.value);
              if (!isNaN(numericValue)) {
                formik.setFieldValue('estimator_commission', numericValue.toFixed(2));
              }
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              formik.setFieldValue('estimator_commission', formatCostInDecimal(event.target.value));
            }}
            placeholder={selectedEstimator.rate_info?.pay_type === 'salary' ? 'Salaried Emp.' : ''}
            InputProps={{ startAdornment: '$' }}
            error={!!formik.touched.estimator_commission && !!formik.errors.estimator_commission}
            helperText={formik.touched.estimator_commission && formik.errors.estimator_commission}
          />
        );
      }
    };

    function scrollToErrorField() {
      const allErrors = Object.keys(formik.errors);

      if (allErrors.length > 0) {
        const firstError = allErrors[0];

        const firstErrorElement = document.getElementById(firstError);
        if (firstErrorElement) {
          firstErrorElement.focus();
          firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }

    return (
      <>
        <Grid
          container
          spacing={2.5}
          component={'form'}
          onSubmit={(event) => {
            scrollToErrorField();
            handleOfficeToWorkerNotes();
            formik.handleSubmit(event);
          }}
        >
          {/* -----------------------------------------Job Name--------------------------------- */}
          <Grid item xs={12} md={!!formik.values.estimator?.id?.length ? 3 : 4} className="space-y-2">
            <InputLabel htmlFor="job_name">Job Name *</InputLabel>
            <OutlinedInput
              id="job_name"
              value={formik.values.job_name}
              name="job_name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              fullWidth
              error={!!formik.touched.job_name && !!formik.errors.job_name}
              ref={(element) => setFieldRef('job_name', element as HTMLInputElement | null)}
            />
            {formik.touched.job_name && formik.errors.job_name && (
              <FormHelperText error id="helper-text-job_name-signup">
                {formik.errors?.job_name}
              </FormHelperText>
            )}
          </Grid>

          {/* -----------------------------Instructions/Details for Estimator *------------------------------ */}
          <Grid item xs={12} md={!!formik.values.estimator?.id?.length ? 3 : 4} className="space-y-2">
            <InputLabel htmlFor="estimator_instructions">Instructions/Details for Estimator *</InputLabel>
            <TextField
              ref={(element) => setFieldRef('estimator_instructions', element as HTMLInputElement | null)}
              id="estimator_instructions"
              fullWidth
              name="estimator_instructions"
              multiline
              rows={2}
              onChange={trimFc(formik)}
              value={formik.values?.estimator_instructions}
              error={!!formik.touched.estimator_instructions && !!formik.errors.estimator_instructions}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && !event.shiftKey) {
                  event.preventDefault();
                  const form = document.getElementsByTagName('form')[0];
                  if (form) {
                    form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
                  }
                }
              }}
            />
            {formik.touched.estimator_instructions && formik.errors.estimator_instructions && (
              <FormHelperText error id="helper-text-estimator_instructions-signup">
                {formik.errors?.estimator_instructions}
              </FormHelperText>
            )}
          </Grid>

          {/* -----------------------------Estimate Assigned to *------------------------------ */}
          <Grid item xs={12} md={!!formik.values.estimator?.id?.length ? 3 : 4} className="space-y-2">
            <InputLabel htmlFor="estimator">Estimate Assigned to*</InputLabel>
            <Autocomplete
              loadingText="Fetching Estimators..."
              ref={(element) => setFieldRef('estimator.id', element as HTMLInputElement | null)}
              id="estimator"
              value={
                allEstimator.find((singleData: SingleEstimator) => singleData.value === formik.values?.estimator?.id) || {
                  value: '',
                  label: ''
                }
              }
              disableClearable
              getOptionLabel={(option) => option.label}
              onChange={(event: React.SyntheticEvent<Element, Event>, newValue: SingleEstimator) =>
                formik.setFieldValue('estimator', {
                  id: newValue.value,
                  name: newValue.label
                })
              }
              options={allEstimator}
              renderInput={(params) => (
                <TextField
                  error={getIn(formik.touched, 'estimator.id') && getIn(formik.errors, 'estimator.id')}
                  {...params}
                  placeholder=""
                  sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                />
              )}
            />
            {getIn(formik.touched, 'estimator.id') && getIn(formik.errors, 'estimator.id') && (
              <FormHelperText error id="helper-text-first_name">
                {getIn(formik.errors, 'estimator.id')}
              </FormHelperText>
            )}
          </Grid>

          {/* -----------------------------------------Worker Commission--------------------------------- */}
          {!!formik.values.estimator?.id?.length && (
            <Grid item xs={12} md={3} className="space-y-2">
              <InputLabel htmlFor="estimator_commission">Estimator Commission</InputLabel>
              {getComissionInput()}
            </Grid>
          )}

          {/* -----------------------------------------Is this estimate at customer's address ?---------------------------------- */}
          <Grid item xs={12} md={formik.values.is_customer_address ? 3 : 12} className="space-y-2">
            <InputLabel htmlFor="is_customer_address">Is this estimate at customer's address ?</InputLabel>

            <RadioGroup
              row
              aria-label="is_customer_address"
              onChange={(event: ChangeEvent<HTMLInputElement>, value: string) => {
                if (value === 'true') {
                  formik.unregisterField('address');
                }
                formik.setFieldValue('is_customer_address', value === 'true');
              }}
              name="is_customer_address"
              id="is_customer_address"
              value={formik.values.is_customer_address ? 'true' : 'false'}
            >
              <FormControlLabel value={true} control={<Radio />} label={'Yes'} />
              <FormControlLabel value={false} control={<Radio />} label={'No'} />
            </RadioGroup>
          </Grid>

          {/* -----------------------------------------ADDRESS---------------------------------- */}
          {!formik.values.is_customer_address && (
            <Grid xs={12} item>
              <Box border="1px solid lightgrey" borderRadius={2} padding={2} position="relative">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} className="space-y-2">
                    <InputLabel className="text-xl font-bold">Address</InputLabel>

                    <GoogleMaps formik={formik} root_field_name={'address'} />
                  </Grid>
                  {/* -----------------------------------------Address 1------------------------------ */}
                  <Grid item xs={12} sm={3} className="space-y-2">
                    <InputLabel htmlFor="address1">Address1*</InputLabel>
                    <TextField
                      id="address1"
                      name="address.address1"
                      value={formik.values?.address?.address1}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      error={getIn(formik.touched, 'address.address1') && getIn(formik.errors, 'address.address1')}
                    />
                    {getIn(formik.touched, 'address.address1') && getIn(formik.errors, 'address.address1') && (
                      <FormHelperText error id="helper-text-address1">
                        {getIn(formik.errors, 'address.address1')}
                      </FormHelperText>
                    )}
                  </Grid>

                  {/* -----------------------------------------Address 2------------------------------ */}
                  <Grid item xs={12} sm={3} className="space-y-2">
                    <InputLabel htmlFor="address.address2">Address2</InputLabel>
                    <TextField
                      id="address.address2"
                      name="address.address2"
                      placeholder=""
                      value={formik.values?.address?.address2}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                    />
                  </Grid>
                  {/* -----------------------------------------City------------------------------ */}
                  <Grid item xs={12} sm={2} className="space-y-2">
                    <InputLabel htmlFor="city">City*</InputLabel>
                    <TextField
                      id="address.city"
                      name="address.city"
                      placeholder=""
                      value={formik.values?.address?.city}
                      onChange={trimFc(formik)}
                      onBlur={formik.handleBlur}
                      fullWidth
                      error={getIn(formik.touched, 'address.city') && getIn(formik.errors, 'address.city')}
                    />
                    {getIn(formik.touched, 'address.city') && getIn(formik.errors, 'address.city') && (
                      <FormHelperText error id="helper-text-city">
                        {getIn(formik.errors, 'address.city')}
                      </FormHelperText>
                    )}
                  </Grid>
                  {/* -----------------------------------------State------------------------------ */}
                  <Grid item xs={12} sm={2} className="space-y-2">
                    <InputLabel htmlFor="state">State*</InputLabel>
                    <TextField
                      id="address.state"
                      name="address.state"
                      placeholder=""
                      value={formik.values?.address?.state}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      error={getIn(formik.touched, 'address.state') && getIn(formik.errors, 'address.state')}
                    />
                    {getIn(formik.touched, 'address.state') && getIn(formik.errors, 'address.state') && (
                      <FormHelperText error id="helper-text-state">
                        {getIn(formik.errors, 'address.state')}
                      </FormHelperText>
                    )}
                  </Grid>

                  {/* -----------------------------------------Zip------------------------------ */}
                  <Grid item xs={12} sm={2} className="space-y-2">
                    <InputLabel htmlFor="zip">Zip*</InputLabel>
                    <TextField
                      id="address.zip"
                      name="address.zip"
                      placeholder=""
                      value={formik.values?.address?.zip}
                      onChange={trimFc(formik)}
                      onBlur={formik.handleBlur}
                      fullWidth
                      error={getIn(formik.touched, 'address.zip') && getIn(formik.errors, 'address.zip')}
                    />
                    {getIn(formik.touched, 'address.zip') && getIn(formik.errors, 'address.zip') && (
                      <FormHelperText error id="helper-text-zip">
                        {getIn(formik.errors, 'address.zip')}
                      </FormHelperText>
                    )}
                  </Grid>
                  {/* -----------------------------------------country------------------------------ */}
                  <Grid item xs={12} sm={2} className="space-y-2">
                    <InputLabel htmlFor="country">Country*</InputLabel>
                    <TextField
                      id="address.country"
                      name="address.country"
                      placeholder=""
                      value={formik.values?.address?.country}
                      onChange={trimFc(formik)}
                      onBlur={formik.handleBlur}
                      fullWidth
                      error={getIn(formik.touched, 'address.country') && getIn(formik.errors, 'address.country')}
                    />
                    {getIn(formik.touched, 'address.country') && getIn(formik.errors, 'address.country') && (
                      <FormHelperText error id="helper-text-country">
                        {getIn(formik.errors, 'address.country')}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
          {/* -----------------------------------------Does this estimate require a date?---------------------------------- */}
          <Grid item xs={12} md={formik.values.is_estimate_require_date ? 9 : 3} className="space-y-2">
            <InputLabel htmlFor="is_estimate_require_date">Does this estimate require a date?</InputLabel>
            <RadioGroup
              row
              aria-label="is_estimate_require_date"
              onChange={handleEstimateRequireDate}
              name="is_estimate_require_date"
              id="is_estimate_require_date"
              value={formik.values.is_estimate_require_date ? 'true' : 'false'}
            >
              <FormControlLabel value={true} control={<Radio />} label={'Yes'} />
              <FormControlLabel value={false} control={<Radio />} label={'No'} />
            </RadioGroup>
          </Grid>
          {formik.values.is_estimate_require_date && (
            <Grid xs={12} item>
              <Box border="1px solid lightgrey" borderRadius={2} padding={2} position="relative">
                <Grid container spacing={4}>
                  {/* -----------------------------------------Scheduled Date ------------------------------ */}

                  <Grid item className="space-y-2" xs={12} sm={6} md={reminder.length > 0 ? 3 : 4}>
                    <InputLabel htmlFor="scheduled_time">
                      {formik.values.estimate_reminder?.exclude_time ? 'Schedule Date' : 'Schedule Date & Time'}
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      {formik.values.estimate_reminder?.exclude_time ? (
                        <DatePicker
                          ref={(element) => setFieldRef('scheduled_time', element as HTMLInputElement | null)}
                          className="w-full"
                          value={dayjs(formik.values.estimate_reminder?.scheduled_date_and_time)}
                          minDate={dayjs()}
                          onChange={(newValue: Dayjs | null) => {
                            if (newValue?.isValid()) {
                              const isoUTCString = newValue.toISOString();
                              formik.setFieldValue('estimate_reminder.scheduled_date_and_time', isoUTCString);
                            }
                          }}
                        />
                      ) : (
                        <DateTimePicker
                          className="w-full"
                          value={dayjs(formik.values.estimate_reminder?.scheduled_date_and_time)}
                          minDate={dayjs()}
                          onChange={(newValue: Dayjs | null) => {
                            if (newValue?.isValid()) {
                              const isoUTCString = newValue.toISOString();
                              formik.setFieldValue('estimate_reminder.scheduled_date_and_time', isoUTCString);
                            }
                          }}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock
                          }}
                        />
                      )}
                      {getIn(formik.touched, 'estimate_reminder.scheduled_date_and_time') &&
                        getIn(formik.errors, 'estimate_reminder.scheduled_date_and_time') && (
                          <FormHelperText error id="helper-text-first_name">
                            {getIn(formik.errors, 'estimate_reminder.scheduled_date_and_time')}
                          </FormHelperText>
                        )}
                    </LocalizationProvider>
                    <FormControlLabel
                      value={formik.values.estimate_reminder?.exclude_time}
                      name="estimate_reminder.exclude_time"
                      id="estimate_reminder.exclude_time"
                      color="primary"
                      checked={formik.values.estimate_reminder?.exclude_time}
                      control={
                        <Checkbox
                          onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                            formik.setFieldValue('estimate_reminder.exclude_time', checked)
                          }
                        />
                      }
                      label={'Exclude Time'}
                    />
                  </Grid>

                  {/* -----------------------------------------Reminder------------------------------ */}
                  <Grid item className="space-y-2" xs={12} sm={6} md={reminder.length > 0 ? 3 : 4}>
                    <InputLabel htmlFor="estimate_reminder">Reminder</InputLabel>
                    <Autocomplete
                      id="estimate_reminder"
                      value={reminderTypes.find((singleData) => singleData.value === reminder)}
                      disableClearable
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newValue: { label: string; value: string }) => setReminder(newValue.value)}
                      options={reminderTypes}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder=""
                          sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                        />
                      )}
                    />
                  </Grid>
                  {/* -----------------------------------------Reminder Date------------------------------ */}
                  {reminder === 'choosecustomedate' && (
                    <Grid item className="space-y-2" xs={12} sm={6} md={3}>
                      <InputLabel htmlFor="estimate_reminder.reminder_date">Reminder Date</InputLabel>
                      <DatePicker
                        className="w-full"
                        maxDate={dayjs(formik.values.estimate_reminder?.scheduled_date_and_time)}
                        minDate={dayjs()}
                        value={dayjs(formik.values.estimate_reminder?.reminder_date)}
                        onChange={(newValue: Dayjs | null) => {
                          formik.setFieldValue('estimate_reminder.reminder_date', newValue?.toISOString());
                        }}
                      />
                      {getIn(formik.touched, 'estimate_reminder.reminder_date') &&
                        getIn(formik.errors, 'estimate_reminder.reminder_date') && (
                          <FormHelperText error id="helper-text-first_name">
                            {getIn(formik.errors, 'estimate_reminder.reminder_date')}
                          </FormHelperText>
                        )}
                    </Grid>
                  )}
                  {/* -----------------------------------------Reminder Method------------------------------ */}
                  <Grid item className="space-y-2 justify-start" xs={12} sm={6} md={reminder.length > 0 ? 3 : 4}>
                    <InputLabel htmlFor="estimate_reminder.reminder_method">Reminder Method</InputLabel>
                    <Stack direction={'row'} spacing={2}>
                      {notificationSendingChannels.map((items: { value: number; label: string }, index) => {
                        return (
                          <FormControlLabel
                            value={items.value}
                            name="estimate_reminder.reminder_method"
                            id="estimate_reminder.reminder_method"
                            color="primary"
                            checked={formik.values.estimate_reminder?.reminder_method.includes(items.value)}
                            control={
                              <Checkbox
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const newCheckObject: Array<number> = [...(formik.values.estimate_reminder?.reminder_method ?? [])];
                                  if (event.target.checked) {
                                    newCheckObject.push(Number(event.target.value));
                                    formik.setFieldValue('estimate_reminder.reminder_method', [...new Set(newCheckObject)]);
                                  } else {
                                    formik.setFieldValue(
                                      'estimate_reminder.reminder_method',
                                      newCheckObject.filter((obj) => obj !== Number(event.target.value))
                                    );
                                  }
                                }}
                              />
                            }
                            label={items.label}
                          />
                        );
                      })}
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
          {/* -----------------------------------------give customer option to auto schedule---------------------------------- */}
          {isAllAppointmentAreaFetched && !!allAppointmentArea && !!allAppointmentArea.length && (
            <Grid item xs={12} md={3} className="space-y-2">
              <InputLabel htmlFor="can_customer_auto_schedule" className="flex items-center space-x-2">
                <Typography>Give customer option to auto schedule</Typography>
                <CustomTooltip message={canCustomerAutoScheduleInfo}>
                  <QuestionCircleFilled />
                </CustomTooltip>
              </InputLabel>
              <RadioGroup
                row
                aria-label="can_customer_auto_schedule"
                onChange={(event: ChangeEvent<HTMLInputElement>, value: string) =>
                  formik.setFieldValue('can_customer_auto_schedule', value === 'true')
                }
                name="can_customer_auto_schedule"
                id="can_customer_auto_schedule"
                value={formik.values.can_customer_auto_schedule ? 'true' : 'false'}
              >
                <FormControlLabel value={true} control={<Radio />} label={'Yes'} />
                <FormControlLabel value={false} control={<Radio />} label={'No'} />
              </RadioGroup>
            </Grid>
          )}
          {/* -----------------------------------------Does customer want a call first?---------------------------------- */}
          <Grid item xs={12} md={3} className="space-y-2">
            <InputLabel htmlFor="customer_want_call_first_for_estimator">Call customer before arrival ?</InputLabel>

            <RadioGroup
              row
              aria-label="customer_want_call_first_for_estimator"
              onChange={(event: ChangeEvent<HTMLInputElement>, value: string) =>
                formik.setFieldValue('customer_want_call_first_for_estimator', value === 'true')
              }
              name="customer_want_call_first_for_estimator"
              id="customer_want_call_first_for_estimator"
              value={formik.values.customer_want_call_first_for_estimator ? 'true' : 'false'}
            >
              <FormControlLabel value={true} control={<Radio />} label={'Yes'} />
              <FormControlLabel value={false} control={<Radio />} label={'No'} />
            </RadioGroup>
          </Grid>

          {/* -----------------------------------------Would you like to display any job images?---------------------------------- */}
          <Grid item xs={12} md={3}>
            <Stack>
              <InputLabel htmlFor="display_job_images">Would you like to display any job images?</InputLabel>
              <RadioGroup
                row
                aria-label="display_job_images"
                onChange={handleDisplayJobImages}
                name="display_job_images"
                id="display_job_images"
                value={formik.values.display_job_images ? 'true' : 'false'}
              >
                <FormControlLabel value={true} control={<Radio />} label={'Yes'} />
                <FormControlLabel value={false} control={<Radio />} label={'No'} />
              </RadioGroup>
            </Stack>
          </Grid>
          {/*-----------------------------------------------------Display Job Images----------------------------------------------------------- */}
          {formik.values.display_job_images && !!formik.values?.job_images?.length && (
            <Grid item xs={12}>
              <ImageList className="space-x-1 space-y-1" cols={5}>
                {formik.values.job_images?.map((singleFileUrl: string, index: number) => (
                  <ImageListItem
                    key={index}
                    component={Card}
                    className="flex justify-center"
                    sx={{ maxHeight: '250px', minHeight: '100px', minWidth: '100px' }}
                  >
                    {universalRenerMedia(singleFileUrl, index)}
                    <ImageListItemBar
                      className="p-1 bg-transparent"
                      position="top"
                      actionIcon={<DeleteOutlined className="font-lg text-red-500" onClick={() => handleRemoveSelectedfile(index)} />}
                      actionPosition="right"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Grid>
          )}
          {/* -----------------------------Add a Schdule Sms------------------------------ */}
          {!existingData?._id && (
            <Grid item xs={12} className="flex items-center space-x-2">
              <Button className="space-y-4" variant="outlined" color="primary" onClick={handleSheduleSMS}>
                Add a Schedule SMS
              </Button>
              <CustomTooltip message={scheduleSmsInfoForEstimator}>
                <QuestionCircleFilled />
              </CustomTooltip>
            </Grid>
          )}
          {/* -----------------------------Office To Worker Notes------------------------------ */}

          <Grid item xs={12} className="space-y-2">
            <div>
              <InputLabel className="mb-2" htmlFor="office_to_worker_notes">
                Office To Worker Notes
              </InputLabel>

              <TextField
                className="md:w-1/2 w-full"
                id={`office_to_worker_notes`}
                fullWidth
                name={`office_to_worker_notes`}
                multiline
                rows={3}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  setOfficeToWorkerNotes(event.target.value ?? '');
                }}
                value={officeToWorkerNotes}
              />
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>

          {/* -----------------------------Log Notes------------------------------ */}
          <Grid item xs={12} md={12} className="space-y-2">
            <InputLabel htmlFor="log_note">Log Notes*</InputLabel>
            <TextField
              className="md:w-1/2 w-full"
              id="log_note"
              fullWidth
              placeholder="Log Notes"
              name="log_note"
              multiline
              rows={3}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && !event.shiftKey) {
                  event.preventDefault();
                  const form = document.getElementsByTagName('form')[0];
                  if (form) {
                    form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
                  }
                }
              }}
              onChange={trimFc(formik)}
              value={formik.values?.log_note}
              error={Boolean(formik.touched.log_note && formik.errors.log_note)}
            />
            {formik.touched.log_note && formik.errors.log_note && (
              <FormHelperText error id="helper-text-job_instructions -signup">
                {formik.errors?.log_note}
              </FormHelperText>
            )}
          </Grid>

          {/* -----------------------------Next------------------------------ */}
          <Grid item xs={12}>
            <div className="flex flex-row items-center justify-end mt-5 space-x-3">
              {FormSubmissionAlertMessage(formik)}

              <LoadingButton variant="contained" loading={isLoading} loadingPosition="start" sx={{ px: 4 }} type="submit">
                {isLoading ? 'Loading...' : <>{existingData ? 'Save' : 'Next'}</>}
              </LoadingButton>
            </div>
          </Grid>
        </Grid>
        {openJobImagesPopup && (
          <UniversalDialog
            onClose={() => setOpenJobImagesPopup(false)}
            hasPrimaryButton={false}
            action={{ open: openJobImagesPopup, fullWidth: true, maxWidth: 'sm' }}
            title={'Job Media'}
          >
            <SelectJobImages files={files} onSubmit={handleSubmitJobImages} jobImages={existingData?.job_images} />
          </UniversalDialog>
        )}
        {openSheduleSMS && !!customerData && (
          <AddScheduleSMSForm
            data={{
              sheduleSMSValues: sheduleSMSValues ?? ({} as TScheduleSMSData),
              customer: customerData,
              selectedUserId: formik.values.estimator?.id,
              scheduleDateAndTime: formik.values.estimate_reminder?.scheduled_date_and_time,
              message: `Just a reminder: you have an estimate scheduled for ${moment(
                formik.values.estimate_reminder?.scheduled_date_and_time
              ).format('YYYY/MM/DD hh:mm:s a')} \nat ${formik.values.job_name}, \nAddress:${[
                formik.values.address?.address1 && `\n${formik.values.address?.address1}`,
                formik.values.address?.city && `, ${formik.values.address?.city}`
              ]
                .filter(Boolean)
                .join('')}`
            }}
            openSheduleSMS={openSheduleSMS}
            setOpenSheduleSMS={setOpenSheduleSMS}
            onAddScheduleSMSFromSubmit={onAddScheduleSMSFromSubmit}
            onClose={() => setOpenSheduleSMS(false)}
          />
        )}
      </>
    );
  }
);

export default AddEstimateForm;
