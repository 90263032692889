import { useFormik } from 'formik';
// import { useTheme } from '@mui/material/styles';
import { CloseOutlined, LoadingOutlined, QuestionCircleFilled } from '@ant-design/icons';
import {
  Alert,
  Autocomplete,
  Button,
  Chip,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { Stack } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import CustomTooltip from 'components/CustomTooltip';
import { ISearch } from 'components/filters/SearchFilter';
import FormSubmissionAlertMessage from 'components/FormSubmissionAlertMessage';
import NumberInput from 'components/inputs/NumberInput';
import TMaterialData, { TSingleMaterial } from 'components/tables/interfaces/materialTableInterface';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import MaterialServicesInstance from 'services/services.materials';
import UomServicesInstance from 'services/services.uoms';
import UserServicesInstance from 'services/services.users';
import * as Yup from 'yup';

// const filterSkills = createFilterOptions<string>();

// const notify_users = ['Java', 'HTML', 'Bootstrap', 'JavaScript', 'NodeJS', 'React', 'Angular', 'CI'];

const AddMaterialForm = (props: { onSuccess: () => void; existingData: TMaterialData | undefined; isEditMode?: boolean }) => {
  //----------------constants------------------------
  const theme = useTheme();
  const navigate = useNavigate();
  const { existingData, isEditMode = false, onSuccess = () => {} } = props;

  const availableSchedule = [
    { label: 'Once', value: 'once' },
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' }
  ];

  const [userData, setUserData] = useState<TSingleMaterial[]>([]);
  const [materialUomData, setMaterialUomData] = useState<string[]>([]);
  const [isMaterialsLoading, setIsMaterialsLoading] = useState(false);

  const [searchData] = useState<ISearch>({
    search: [
      [
        {
          field_name: 'status',
          field_value: 1,
          operator: 'exactmatch'
        }
      ]
    ]
  });
  //-----------------------useQuery--------------------
  const { data: allUomData } = useQuery({
    queryKey: ['materila_uom'],
    queryFn: () => UomServicesInstance.getAllUom(undefined, searchData)
  });

  const { data: allUsersData } = useQuery({
    queryKey: ['material_forms'],
    queryFn: () => UserServicesInstance.getAllUsers(undefined, searchData)
  });
  //----------------useEffects-----------------
  useEffect(() => {
    if (allUsersData?.users) {
      const formattedUserData: TSingleMaterial[] =
        allUsersData && allUsersData.users?.map((eachUser) => ({ id: eachUser._id, name: eachUser.first_name }));
      setUserData(formattedUserData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUsersData?.users]);

  useEffect(() => {
    if (allUomData) {
      const dummyData = allUomData.material_uoms.map((singleData) => singleData.unit_of_measure);
      setMaterialUomData(dummyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUomData]);

  // --------FORMIK--------
  const formik = useFormik<TMaterialData>({
    initialValues: {
      description: '',
      unit_of_measure: '',
      available_units: 0,
      price_per_unit: 0,
      low_alert_notification: 0,
      low_alert_notification_schedule: { type: 'once' },
      notify_users: []
    },
    validationSchema: Yup.object().shape({
      description: Yup.string().required('This field is required'),
      unit_of_measure: Yup.string().required('This field is required'),
      price_per_unit: Yup.string().required('This field is required'),
      low_alert_notification: Yup.string().required('This field is required'),
      notify_users: Yup.array().of(Yup.object()).min(1).required('This field is required')
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      handleMaterialFormSubmit(values);
      setStatus({ success: true });
      setSubmitting(false);
    }
  });

  // ---- form submit ----
  const handleMaterialFormSubmit = async (values: any) => {
    delete values.submit;
    delete values?._id;
    const formattedValue: TMaterialData = values;
    setIsMaterialsLoading(true);
    if (isEditMode && existingData && existingData._id) {
      const response = await MaterialServicesInstance.editMaterial(existingData?._id, formattedValue);
      response && onSuccess();
      setIsMaterialsLoading(false);
    } else {
      const response = await MaterialServicesInstance.createMaterial(formattedValue);
      response && onSuccess();
      setIsMaterialsLoading(false);
    }
  };

  useEffect(() => {
    if (!isEditMode || !existingData) return;
    formik.setFieldValue('description', existingData.description);
    formik.setFieldValue('unit_of_measure', existingData.unit_of_measure);
    formik.setFieldValue('available_units', existingData.available_units);
    formik.setFieldValue('price_per_unit', existingData.price_per_unit);
    formik.setFieldValue('low_alert_notification', existingData.low_alert_notification);
    formik.setFieldValue('low_alert_notification_schedule', { type: existingData.low_alert_notification_schedule.type });
    formik.setFieldValue('notify_users', existingData.notify_users);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, existingData]);

  useEffect(() => {
    console.log('formik.errors :', formik.errors);
  }, [formik.errors]);

  const redirectToMaterialUom = () => {
    navigate('/settings/materials-supplies/material-uom');
    window.location.reload();
  };

  return (
    <Grid component={'form'} noValidate onSubmit={formik.handleSubmit} container spacing={4}>
      <Grid item xs={12} md={12}>
        <Grid container spacing={3}>
          {/* -----------------------------------------Description--------------------------------- */}
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="description">Description*</InputLabel>
              <OutlinedInput
                required
                id="description"
                type="text"
                value={formik.values.description}
                name="description"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder=""
                fullWidth
                error={Boolean(formik.touched.description && formik.errors.description)}
              />
              {formik.touched.description && formik.errors.description && (
                <FormHelperText error id="helper-text-description-signup">
                  {formik.errors?.description}
                </FormHelperText>
              )}
            </Stack>
          </Grid>

          {/* -----------------------------------------Unit Of Measure---------------------------------- */}
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="unit_of_measure">Unit Of Measure *</InputLabel>

              {!materialUomData?.length ? (
                <Alert
                  severity="warning"
                  action={
                    <Button color="inherit" size="small" onClick={redirectToMaterialUom}>
                      Add
                    </Button>
                  }
                >
                  No unit of measure found
                </Alert>
              ) : (
                <Autocomplete
                  id="unit_of_measure"
                  value={formik.values.unit_of_measure}
                  disableClearable
                  getOptionLabel={(option) => option}
                  onChange={(event: React.SyntheticEvent<Element, Event>, newValue: string) => {
                    formik.setFieldValue('unit_of_measure', newValue);
                  }}
                  options={materialUomData ?? []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(formik.touched.unit_of_measure && formik.errors.unit_of_measure)}
                      placeholder="Unit Of Measure"
                      sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                    />
                  )}
                />
              )}

              {formik.touched.unit_of_measure && formik.errors.unit_of_measure && (
                <FormHelperText error id="helper-text-unit_of_measure-signup">
                  {formik.errors.unit_of_measure}
                </FormHelperText>
              )}
            </Stack>
          </Grid>

          {/* -----------------------------------------Low Alert Notification------------------------------ */}
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <div className="flex items-center gap-x-2">
                <InputLabel htmlFor="low_alert_notification">Low Alert Notification *&nbsp;</InputLabel>
                <CustomTooltip props={{ placement: 'top' }} message="Set stock alert by inputing the number of units below">
                  <QuestionCircleFilled />
                </CustomTooltip>
              </div>
              <NumberInput
                required
                id="low_alert_notification"
                type="number"
                value={formik.values.low_alert_notification}
                name="low_alert_notification"
                onBlur={formik.handleBlur}
                args={[]}
                onChangeHandler={formik.handleChange}
                placeholder=""
                fullWidth
                error={Boolean(formik.touched.low_alert_notification && formik.errors.low_alert_notification)}
              />

              {formik.touched.low_alert_notification && formik.errors.low_alert_notification && (
                <FormHelperText error id="helper-text-low_alert_notification-signup">
                  {formik.errors.low_alert_notification}
                </FormHelperText>
              )}
            </Stack>
          </Grid>
          {/* -----------------------------------------Low Alert Notification------------------------------ */}
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="low_alert_notification_schedule.type">Low Alert Notification Schedule*</InputLabel>
              <Select
                fullWidth
                value={formik.values.low_alert_notification_schedule?.type}
                displayEmpty
                name="low_alert_notification_schedule.type"
                id="low_alert_notification_schedule.type"
                renderValue={(selected) => availableSchedule.find((status) => status.value === selected)?.label}
                onChange={(event: SelectChangeEvent<string>) =>
                  formik.setFieldValue('low_alert_notification_schedule.type', event.target.value)
                }
              >
                {availableSchedule.map((status) => (
                  <MenuItem value={status.value}>{status.label}</MenuItem>
                ))}
              </Select>
              {formik.touched.low_alert_notification && formik.errors.low_alert_notification && (
                <FormHelperText error id="helper-text-low_alert_notification-signup">
                  {formik.errors.low_alert_notification}
                </FormHelperText>
              )}
            </Stack>
          </Grid>
          {/* -----------------------------------------Price Per Unit------------------------------ */}
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="price_per_unit">Price Per Unit*</InputLabel>
              <NumberInput
                InputProps={{ startAdornment: '$' }}
                required
                id="price_per_unit"
                type="number"
                value={formik.values.price_per_unit}
                name="price_per_unit"
                onBlur={formik.handleBlur}
                args={[]}
                onChangeHandler={formik.handleChange}
                placeholder=""
                fullWidth
                error={Boolean(formik.touched.price_per_unit && formik.errors.price_per_unit)}
              />

              {formik.touched.price_per_unit && formik.errors.price_per_unit && (
                <FormHelperText error id="helper-text-price_per_unit-signup">
                  {formik.errors.price_per_unit}
                </FormHelperText>
              )}
            </Stack>
          </Grid>

          {/* -----------------------------------------Notify Users------------------------------------------ */}

          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="notify_users">Notify Users *</InputLabel>
              <Autocomplete
                id="notify_users"
                multiple
                fullWidth
                autoHighlight
                freeSolo
                disableCloseOnSelect
                options={userData}
                value={formik.values.notify_users}
                onBlur={formik.handleBlur}
                getOptionLabel={(option: string | TSingleMaterial) => {
                  return typeof option === 'string' ? option : option.name;
                }}
                onChange={(event, newValue) => {
                  const objectToCheck: TSingleMaterial = newValue[newValue.length - 1] as TSingleMaterial;
                  const containsObject = userData.some((obj) => obj.id === objectToCheck?.id && obj.name === objectToCheck?.name);
                  if (!containsObject) {
                    formik.setFieldValue('notify_users', newValue);
                  } else {
                    formik.setFieldValue('notify_users', newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="notify_users"
                    placeholder="Notify Users"
                    error={formik.touched.notify_users && Boolean(formik.errors.notify_users)}
                    //   helperText={TagsError}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    let error = false;
                    if (formik.touched.notify_users && formik.errors.notify_users && typeof formik.errors.notify_users !== 'string') {
                      if (typeof formik.errors.notify_users[index] === 'object') error = true;
                    }

                    return (
                      <Chip
                        {...getTagProps({ index })}
                        variant="combined"
                        color={error ? 'error' : 'secondary'}
                        label={
                          <Typography variant="caption" color="secondary.dark">
                            {typeof option === 'string' ? option : option.name}
                          </Typography>
                        }
                        deleteIcon={<CloseOutlined style={{ fontSize: '0.875rem' }} />}
                        size="small"
                      />
                    );
                  })
                }
              />
            </Stack>
          </Grid>

          {/* -----------------------------------------Available Units------------------------------ */}
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="available_units">Units currently in stock</InputLabel>
              <NumberInput
                required
                id="available_units"
                type="number"
                value={formik.values.available_units}
                name="available_units"
                onBlur={formik.handleBlur}
                args={[]}
                onChangeHandler={formik.handleChange}
                placeholder=""
                fullWidth
                error={Boolean(formik.touched.available_units && formik.errors.available_units)}
              />

              {formik.touched.available_units && formik.errors.available_units && (
                <FormHelperText error id="helper-text-available-units-signup">
                  {formik.errors.available_units}
                </FormHelperText>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className="flex items-center justify-between mt-5 space-x-2">
          {isEditMode ? (
            <Typography
              variant="h5"
              color={(existingData?.available_units ?? 0) <= (existingData?.low_alert_notification ?? 0) ? 'red' : 'green'}
            >
              Currently in stock : <span className="font-bold text-lg">{existingData?.available_units ?? 0}</span>
            </Typography>
          ) : (
            <span />
          )}
          {FormSubmissionAlertMessage(formik)}

          <Button
            type="submit"
            startIcon={isMaterialsLoading && <LoadingOutlined />}
            disabled={isMaterialsLoading}
            variant="shadow"
            size="large"
            className="py-3 px-8 text-base"
          >
            Add
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default AddMaterialForm;
