import { AxiosRequestConfig } from 'axios';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { TMediaResponseWGet } from 'types/globalTypes/type.media';
import axiosServices from 'utils/axios';

class MediaServices {
  getAllMedia = async () => {
    try {
      const response = await axiosServices.get(`api/media`, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        return response.data.data as {
          _id: string;
          company_id: string;
          url: string;
        }[];
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  getMedia = async (module: string, isFormateData: boolean, id?: string, type?: string) => {
    try {
      const response: TMediaResponseWGet = await axiosServices.get(`api/media/${module}`, {
        params: {
          ...(id && { id }),
          ...(type && { type }),
          ...(isFormateData !== undefined && { is_formate_data: isFormateData })
          // ...(page && { page }),
          // ...(limit && { limit }),
          // ...(searchData && { filter: JSON.stringify(searchData) }),
          // ...(!!filter_type && !!filter_field && { type: filter_type, field: filter_field })
        },
        locationNeeded: false
      } as AxiosRequestConfig);

      if (response && response.data.success) {
        if (response.data.data.length === 0) return isFormateData ? ({} as unknown) : ([] as unknown);
        return isFormateData ? (response.data.data[0] as unknown) : (response.data.data as unknown);
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  addMedia = async (url: string[], module: string, customer_id?: string, job_id?: string, type?: string) => {
    try {
      const response = await axiosServices.post(`api/media/${module}`, { urls: url }, {
        params: {
          ...(customer_id && { customer_id }),
          ...(job_id && { job_id }),

          ...(type && { type })
        },
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Media Added',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  deleteMedia = async (id: string) => {
    try {
      const response = await axiosServices.delete(`api/media/${id}`, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Media Deleted',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  addCustomerMedia = async (payload: any) => {
    try {
      const response = await axiosServices.post(`api/widget/customer-photos`, payload, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: response.data.message,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
}
const MediaServicesInstance = new MediaServices();
export default MediaServicesInstance;
