import { Alert, AlertTitle } from '@mui/material';
import { snakeCaseToTitleCase } from 'utils/common';

const FormSubmissionAlertMessage = (formik: any) => {
  return (
    formik.submitCount > 0 &&
    Object.keys(formik.errors).length > 0 && (
      <Alert severity="error" className="text-sm break-all w-full">
        <AlertTitle color="tomato">
          You've missed something in these fields: <br /> [ {Object.keys(formik.errors).map((key) => `${snakeCaseToTitleCase(key)}, `)} ]
        </AlertTitle>
      </Alert>
    )
  );
};

export default FormSubmissionAlertMessage;
