// third-party
import { FormattedMessage } from 'react-intl';
import { defaultCalendarTab, defaultReport } from 'utils/constants';
// assets
import {
  BarChartOutlined,
  CalendarOutlined,
  CarOutlined,
  ChromeOutlined,
  ClockCircleOutlined,
  ContactsOutlined,
  CreditCardOutlined,
  DeploymentUnitOutlined,
  DollarOutlined,
  HomeOutlined,
  MoneyCollectOutlined,
  QuestionOutlined,
  SafetyOutlined,
  SettingOutlined,
  UserOutlined
} from '@ant-design/icons';
// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  HomeOutlined,
  UserOutlined,
  SettingOutlined,
  ContactsOutlined,
  DollarOutlined,
  SafetyOutlined,
  BarChartOutlined,
  CreditCardOutlined,
  MoneyCollectOutlined
};

export const NavigationItems = [
  {
    id: 'dashboard',
    title: <FormattedMessage id="Dashboard" />,
    type: 'item',
    url: '/dashboard',
    icon: icons.HomeOutlined
  },
  {
    id: 'customers',
    title: <FormattedMessage id="Customers" />,
    type: 'item',
    url: '/customers',
    icon: icons.ContactsOutlined
  },
  {
    id: 'jobs',
    title: <FormattedMessage id="Jobs" />,
    type: 'item',
    url: '/jobs',
    icon: CarOutlined
  },
  {
    id: 'punch',
    title: <FormattedMessage id="Clock In / Clock Out" />,
    type: 'item',
    url: '/punch',
    icon: ClockCircleOutlined
  },
  {
    id: 'calendar',
    title: <FormattedMessage id="Calendar" />,
    type: 'item',
    url: `/calendar/${defaultCalendarTab}`,
    icon: CalendarOutlined
  },
  {
    id: 'company_management',
    title: <FormattedMessage id="Company Management" />,
    type: 'item',
    url: '/company',
    icon: SafetyOutlined
  },

  {
    id: 'payroll',
    title: <FormattedMessage id="Payroll" />,
    type: 'item',
    url: '/payroll',
    icon: icons.CreditCardOutlined
  },
  {
    id: 'expenses',
    title: <FormattedMessage id="Expenses" />,
    type: 'item',
    url: '/expenses',
    icon: DollarOutlined
  },

  {
    id: 'reports',
    title: <FormattedMessage id="Reports" />,
    type: 'item',
    url: `/reports/${defaultReport}`,
    icon: icons.BarChartOutlined
  },
  {
    id: 'invoices',
    title: <FormattedMessage id="Invoices" />,
    type: 'item',
    url: `/invoices`,
    icon: icons.MoneyCollectOutlined
  },
  {
    id: 'settings',
    title: <FormattedMessage id="System Settings" />,
    type: 'item',
    url: `/settings`,
    icon: icons.SettingOutlined
    //   external: true,
    //   target: true
    //   chip: {
    //     label: 'gitbook',
    //     color: 'secondary',
    //     size: 'small'
    //   }
  }
];
