import { LoadingButton } from '@mui/lab';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, List, ListItem, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import useAuth from 'hooks/useAuth';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import EmailSmsServicesInstance, { TTextPhotosLinkPayload, TUserObject } from 'services/services.emailSms';

type TTextPhotosLink = {
  open: boolean;
  onClose: () => void;
  customerData: any;
  onSubmit?: (arg0: any) => void;
  jobData?: any;
  fromModule: 'job' | 'customer';
};

const listVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 }
};

const TextPhotosLink = (props: TTextPhotosLink) => {
  const loggedInUser = useAuth();

  const [usersRelatedToThisJob, setUsersRelatedToThisJob] = useState<TUserObject[]>([]);
  const [notifyUser, setNotifyUser] = useState<{ state: boolean; users: TUserObject[] }>({ state: false, users: [] });
  const [isSending, setIsSending] = useState(false);

  const isAssignedToMe = useMemo(() => {
    if (props?.fromModule === 'job') {
      const loggedInUserId = loggedInUser.user?._id;

      const hasJobActionAssigned = props?.jobData?.job_action?.some(
        (jobAction: { field_worker: { id: string | undefined } }) => jobAction?.field_worker?.id === loggedInUserId
      );

      const isEstimatorAssigned = props?.jobData?.estimator?.id === loggedInUserId;

      if (hasJobActionAssigned || isEstimatorAssigned) {
        setNotifyUser((prev) => ({
          ...prev,
          users: [
            ...prev.users,
            { id: loggedInUser?.user?._id as string, name: `${loggedInUser?.user?.first_name} ${loggedInUser?.user?.last_name}` }
          ]
        }));
      }

      return hasJobActionAssigned || isEstimatorAssigned;
    } else {
      return true;
    }
  }, [props?.jobData, loggedInUser]);

  useEffect(() => {
    if (props?.fromModule === 'job') {
      let usersRelatedToJob = [];

      if (loggedInUser?.user?._id)
        usersRelatedToJob.push({
          id: loggedInUser?.user?._id as string,
          name: `${loggedInUser?.user?.first_name} ${loggedInUser?.user?.last_name}`
        });

      if (props?.jobData) {
        usersRelatedToJob = [
          ...usersRelatedToJob,
          ...props?.jobData?.job_action?.map((jobAction: { field_worker: { id: string | undefined } }) => jobAction?.field_worker)
        ];
        if (props?.jobData?.estimator && props?.jobData?.estimator?.id) usersRelatedToJob.push(props?.jobData.estimator);
      }
      setUsersRelatedToThisJob(usersRelatedToJob);
    } else {
      setNotifyUser((prev) => ({
        ...prev,
        users: [
          ...prev.users,
          { id: loggedInUser?.user?._id as string, name: `${loggedInUser?.user?.first_name} ${loggedInUser?.user?.last_name}` }
        ]
      }));
    }
  }, [props?.jobData, loggedInUser?.user, props?.fromModule]);

  const sendPhotosLink = async () => {
    let usersToNotify = notifyUser.users;

    const payload: TTextPhotosLinkPayload = {
      notify_when_customer_replies_with_photos: notifyUser.state,
      customer: { id: props.customerData._id, name: `${props.customerData.first_name} ${props.customerData.last_name}` },
      users_to_notify: notifyUser.state ? usersToNotify : []
    };

    if (props.jobData) {
      payload.job = { id: props.jobData._id, name: props.jobData.job_name };
    }
    setIsSending(true);
    const res = await EmailSmsServicesInstance.textPhotosLink(payload);
    if (res) {
      props?.onSubmit?.(res);
      props.onClose();
    }
    setIsSending(false);
  };

  const handleUsersSelection = (event: React.ChangeEvent<HTMLInputElement>, selectedUsers: TUserObject) => {
    if (event.target.checked) {
      setNotifyUser((prev) => ({ ...prev, users: [...prev.users, selectedUsers] }));
    } else {
      if (notifyUser.users.length > 1) {
        setNotifyUser((prev) => ({ ...prev, users: prev.users.filter((user) => user.id !== selectedUsers.id) }));
      }
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth={'sm'}>
      <DialogContent className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          {props.jobData?.last_photos_link_sent ? (
            <Typography>
              Photos link was sent on <strong>{moment(props.jobData?.last_photos_link_sent).format('MM-DD-YYYY')}.</strong>
            </Typography>
          ) : (
            <Typography>Photos link was not sent yet.</Typography>
          )}
          <Typography variant="h5">Do you want to send now ?</Typography>
        </div>
        <div>
          <FormControlLabel
            name="customer_notification_preference"
            id="customer_notification_preference"
            color="primary"
            checked={notifyUser.state}
            control={
              <Checkbox
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setNotifyUser((prev) => ({ ...prev, state: event.target.checked }))
                }
              />
            }
            label={
              <span>
                Please notify <strong>{isAssignedToMe ? 'me' : ''}</strong> as soon as customer replies back with photos
              </span>
            }
          />
          {!isAssignedToMe && notifyUser.state && usersRelatedToThisJob?.length > 0 && (
            <List
              component={motion.div}
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={listVariants}
              className="flex flex-col gap-2"
            >
              <AnimatePresence>
                {usersRelatedToThisJob?.map((user) => (
                  <ListItem className="!py-0" key={user?.id} component={motion.div} variants={itemVariants} transition={{ duration: 0.3 }}>
                    <FormControlLabel
                      name="customer_notification_preference"
                      id="customer_notification_preference"
                      color="primary"
                      checked={notifyUser.users.some((eachUser) => {
                        return eachUser.id === user?.id;
                      })}
                      control={<Checkbox onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleUsersSelection(event, user)} />}
                      label={user?.name}
                    />
                  </ListItem>
                ))}
              </AnimatePresence>
            </List>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose} disabled={isSending}>
          No
        </Button>
        <LoadingButton
          variant="contained"
          loading={isSending}
          onClick={sendPhotosLink}
          disabled={notifyUser.state && notifyUser.users.length === 0}
        >
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default TextPhotosLink;
