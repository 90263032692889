import { Skeleton, Stack, TableBody, TableCell } from '@mui/material';

const TableSkeleton = () => {
  return (
    <TableBody className="w-full">
      <TableCell colSpan={12}>
        <Stack>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={90} />
        </Stack>
      </TableCell>
    </TableBody>
  );
};

export default TableSkeleton;
