import { EyeFilled, PictureFilled } from '@ant-design/icons';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Hidden,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import RenderMeasurementsUnits from 'components/ShowMeasurementUnits';
import { IEstimationOption, TJobItem } from 'components/tables/interfaces/jobTableInterface';
import { universalRenerMedia } from 'components/UniversalRenderMedia';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { formatCurrency } from 'utils/common';
import { proposalMessage } from 'utils/constants';
import { TJobInfo } from '../types/jobInfoType';

const WidgetEstimateOptions = ({
  dataByCategory,
  categoryTotal,
  approvedOptionIndex,
  jobData
}: {
  dataByCategory: { [key: string]: TJobItem[] }[];
  categoryTotal: { [key: string]: number }[];
  approvedOptionIndex: number;
  jobData: TJobInfo['job'];
}) => {
  //--------------constants------------------
  const navigate = useNavigate();
  const locaiton = useLocation();
  const pathParameter = locaiton.pathname.split('/')[1];
  const [amountPaid, setAmountPaid] = useState<number | null>(0);
  const [totalAmountRemaining, setTotalAmountRemaining] = useState<number>(0);
  const [displayImages, setDisplayImages] = useState<string[]>([]);

  //------------------------useEffect--------------------
  useEffect(() => {
    if (!!jobData) {
      const approvedOptionIndex = jobData?.estimations?.options.findIndex((singleOption) => singleOption.approved) || 0;

      let totalPaidAmount: number = 0;
      jobData?.payments?.forEach((paymentData) => {
        totalPaidAmount += paymentData?.amount || 0;
      });
      setAmountPaid(totalPaidAmount);
      setTotalAmountRemaining(
        jobData.estimations.options[approvedOptionIndex]?.sub_total +
          (jobData.is_this_job_tax_exempt ? 0 : jobData.estimations.options[approvedOptionIndex]?.tax) -
          totalPaidAmount
      );

      const addedToProposal: string[] = [];

      jobData?.estimations?.options.forEach((eachOption) => {
        eachOption.files?.forEach((eachFile) => {
          if (!!eachFile.addToProposal) addedToProposal.push(eachFile.url);
        });
      });

      setDisplayImages(addedToProposal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobData]);
  //-------------------------Handlers---------------------

  const renderContent = (optionIndex: number) => {
    switch (pathParameter) {
      case 'proposal':
        return (
          <div>
            {approvedOptionIndex !== -1 && !jobData.previous_states?.includes('proposal_declined') && (
              <div className="flex flex-row">
                {!!jobData.proposal_approved_date && (
                  <Typography className="w-1/2">{`Accepted ${jobData.proposal_approved_date}`}</Typography>
                )}
                {!!jobData.proposal_approve_signature && !!jobData.proposal_approve_signature.length && (
                  <CardMedia component={'img'} className="w-1/2" src={jobData.proposal_approve_signature} />
                )}
              </div>
            )}
            {approvedOptionIndex === -1 && jobData.state === 'proposal_sent' && (
              <div className="space-y-2">
                <Typography className="text-center font-normal">{proposalMessage}</Typography>
                <div className="flex flex-row justify-center space-x-2">
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      navigate(`/proposal?action=decline&job_id=${jobData._id}`);
                    }}
                  >
                    Decline Proposal
                  </Button>
                  <Button
                    color="success"
                    variant="contained"
                    onClick={() => {
                      navigate(`/proposal?action=accept&job_id=${jobData._id}&option_id=${jobData.estimations?.options[optionIndex].id}`);
                    }}
                  >
                    Accept Proposal
                  </Button>
                </div>
              </div>
            )}
          </div>
        );
    }
  };

  //-------------------------Components---------------------
  const SummarySection = ({
    label,
    value,
    bold = false,
    strikeThrough = false
  }: {
    label: string;
    value: number;
    bold?: boolean;
    strikeThrough?: boolean;
  }) => (
    <Stack direction={'row'} className="justify-between" spacing={5} color="white">
      <Typography align="right" className={bold ? 'font-bold' : ''}>
        {label}
      </Typography>
      <Typography className={`${strikeThrough ? 'line-through' : 'none'} font-mono text-base`} align="right">
        &#36;{formatCurrency(value)}
      </Typography>
    </Stack>
  );

  const optionData = (singleOption: IEstimationOption, optionIndex: number) => {
    return (
      <div className="space-y-4">
        <div className={`${pathParameter !== 'jobs' && 'border border-gray-400 p-0'} space-y-4`}>
          {pathParameter !== 'jobs' && (
            <Typography variant="h5" className="text-lg font-bold text-gray-600 px-6 mt-4">
              {singleOption.option_name}
            </Typography>
          )}
          {dataByCategory[optionIndex] &&
            Object.keys(dataByCategory[optionIndex]).map((singleJobCategory) => (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        className="font-normal text-base"
                        sx={{
                          backgroundColor: '#34495f',
                          color: 'white'
                        }}
                      >
                        {singleJobCategory}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody component={Hidden} smDown={true}>
                    {dataByCategory[optionIndex][singleJobCategory].map((eachItem: TJobItem) => {
                      return (
                        <TableRow>
                          <TableCell align="left">
                            {eachItem.image ? (
                              <a href={eachItem.image} target="_blank" rel="noreferrer">
                                <Avatar src={eachItem.image} variant="rounded" />
                              </a>
                            ) : (
                              <Avatar variant="rounded">
                                <PictureFilled />
                              </Avatar>
                            )}
                          </TableCell>

                          <TableCell align="left" sx={{ textOverflow: 'ellipsis' }}>
                            {eachItem.description}
                          </TableCell>
                          <TableCell align="left">
                            {eachItem.show_units_on_proposal ? (
                              <RenderMeasurementsUnits
                                measurementData={eachItem.measurements}
                                measure={eachItem.measure_type}
                                showUnitsOnProposal={false}
                              />
                            ) : (
                              '-'
                            )}
                          </TableCell>
                          <TableCell align="left" className="capitalize font-mono">
                            {eachItem.taxable === 0 ? '-' : `Sales Tax ( $${formatCurrency(eachItem.taxable ?? 0)} )`}
                          </TableCell>
                          <TableCell align="right" className="font-mono">
                            ${formatCurrency(eachItem.price ?? 0)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow>
                      <TableCell colSpan={4} align="right" className="font-bold">
                        Total
                      </TableCell>
                      <TableCell align="right" className="font-bold font-mono">
                        ${formatCurrency(categoryTotal[optionIndex][singleJobCategory] ?? 0)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <Hidden smUp={true}>
                    <Box className="p-1 space-y-2">
                      {dataByCategory[optionIndex][singleJobCategory].map((eachItem: TJobItem) => (
                        <div className="border border-1 p-1 space-y-2">
                          <div className="flex justify-start">
                            <a href={eachItem.image} target="_blank" rel="noreferrer">
                              <Avatar src={eachItem.image} variant="rounded" />
                            </a>
                          </div>

                          <div className="flex justify-between">
                            <Typography variant="body2" className="font-bold">
                              Description
                            </Typography>
                            <Typography textAlign={'right'} sx={{ textOverflow: 'ellipsis' }}>
                              {eachItem.description}
                            </Typography>
                          </div>
                          <div className="flex justify-between">
                            <Typography variant="body2" className="font-bold">
                              Unit
                            </Typography>
                            <Typography textAlign={'right'} className="text-wrap">
                              {eachItem.show_units_on_proposal ? (
                                <RenderMeasurementsUnits
                                  measurementData={eachItem.measurements}
                                  measure={eachItem.measure_type}
                                  showUnitsOnProposal={false}
                                />
                              ) : (
                                '-'
                              )}
                            </Typography>
                          </div>
                          <div className="flex justify-between">
                            <Typography variant="body2" className="font-bold">
                              Tax
                            </Typography>
                            <Typography className="capitalize font-mono" textAlign={'right'}>
                              {eachItem.taxable === 0 ? '-' : `Sales Tax ( $${formatCurrency(eachItem.taxable ?? 0)} )`}
                            </Typography>
                          </div>
                          <div className="flex justify-between">
                            <Typography variant="body2" className="font-bold">
                              Price
                            </Typography>
                            <Typography textAlign={'right'} className="font-mono">
                              ${formatCurrency(eachItem.price ?? 0)}
                            </Typography>
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-between ">
                        <Typography variant="body2" className="font-bold">
                          Total
                        </Typography>
                        <Typography className="font-bold font-mono" textAlign={'right'}>
                          ${formatCurrency(categoryTotal[optionIndex][singleJobCategory] ?? 0)}
                        </Typography>
                      </div>
                    </Box>
                  </Hidden>
                </Table>
              </TableContainer>
            ))}

          {/*-----------------------------------Over All Summary------------------------*/}
          <div className="flex sm:flex-row flex-col-reverse justify-end">
            {['proposal', 'invoice'].includes(pathParameter) && !!singleOption.proposal_note && (
              <CardHeader title={'Notes'} subheader={singleOption.proposal_note} className="border w-full sm:w-1/2" />
            )}
            <div className="flex flex-col p-5 w-full sm:w-1/2" style={{ backgroundColor: '#34495f' }}>
              <SummarySection label="Sub Total" value={singleOption.sub_total} bold />
              <SummarySection label="Tax" value={singleOption.tax} bold strikeThrough={jobData.is_this_job_tax_exempt} />

              {singleOption?.sub_total !== undefined && (
                <SummarySection
                  label="Grand Total"
                  value={singleOption?.sub_total + (jobData.is_this_job_tax_exempt ? 0 : singleOption?.tax || 0)}
                  bold
                />
              )}
              {pathParameter === 'invoice' && (
                <>
                  <SummarySection label="Amount Paid" value={amountPaid ?? 0} bold />
                  <SummarySection label="Balance Due" value={totalAmountRemaining ?? 0} bold />
                </>
              )}
            </div>
          </div>

          {['proposal', 'invoice'].includes(pathParameter) && pathParameter === 'proposal' && !!displayImages && !!displayImages.length && (
            <CardContent>
              <ImageList className="space-x-1 space-y-1" cols={5}>
                {singleOption.files?.map((singleFile, index: number) => {
                  if (!singleFile.addToProposal) return null;
                  return (
                    <ImageListItem
                      key={index}
                      component={Card}
                      className="flex justify-center border"
                      sx={{ maxHeight: '250px', minHeight: '100px', minWidth: '100px' }}
                    >
                      {universalRenerMedia(singleFile.url, index)}

                      <ImageListItemBar
                        position="top"
                        className="bg-transparent"
                        actionIcon={
                          <ButtonGroup>
                            <IconButton size="small" color="info">
                              <a href={singleFile.url} target="_blank" rel="noreferrer">
                                <EyeFilled />
                              </a>
                            </IconButton>
                          </ButtonGroup>
                        }
                        actionPosition="right"
                      />
                    </ImageListItem>
                  );
                })}
              </ImageList>
            </CardContent>
          )}
        </div>
        <>{renderContent(optionIndex)}</>

        <Divider />
      </div>
    );
  };

  const renderEstimateOption = () => {
    if (approvedOptionIndex === -1) {
      if (pathParameter === 'proposal')
        return jobData.estimations?.options.map((singleOption, optionIndex) => optionData(singleOption, optionIndex));
    }
    switch (pathParameter) {
      case 'proposal':
      case 'invoice':
        return !!jobData.estimations?.options && optionData(jobData.estimations?.options[approvedOptionIndex], approvedOptionIndex);
      case 'signature':
        return !!jobData?.draft_estimations?.options
          ? optionData(jobData?.draft_estimations?.options[approvedOptionIndex], approvedOptionIndex)
          : !!jobData?.estimations?.options && optionData(jobData?.estimations?.options[approvedOptionIndex], approvedOptionIndex);
    }
  };

  return <div className="space-y-10">{renderEstimateOption()}</div>;
};

export default WidgetEstimateOptions;
