import { createSlice } from '@reduxjs/toolkit';
import { CalendarProps } from 'pages/calendar/types/types.calendar';

const initialState: CalendarProps = {
  calendarView: 'dayGridMonth',
  date: new Date().toISOString(),
  previous_year: null as unknown as number,
  areaName: '',
  events: [],
  isModalOpen: false,
  selectedEventDate: null
};

// ==============================|| CALENDAR - SLICE ||============================== //

const calendar = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    //modify date
    setDate(state, action) {
      state.date = action.payload;
    },
    //year change
    setPreviousYear(state, action) {
      state.previous_year = action.payload;
    },
    //modify area name
    setAreaName(state, action) {
      state.areaName = action.payload;
    },
    // event list
    setEvents(state, action) {
      state.events = action.payload;
    },

    // update calendar view
    updateCalendarView(state, action) {
      state.calendarView = action.payload;
    },

    // select event
    selectEvent(state, action) {
      const eventDate = action.payload;
      state.isModalOpen = true;
      state.selectedEventDate = eventDate;
    },

    // modal toggle
    toggleModal(state) {
      if (state.isModalOpen === false) {
        state.selectedEventDate = null;
      }
      state.isModalOpen = !state.isModalOpen;
    }
  }
});

export default calendar.reducer;

export const { setDate, setAreaName, selectEvent, toggleModal, updateCalendarView, setEvents, setPreviousYear } = calendar.actions;
