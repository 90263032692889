import { AxiosRequestConfig } from 'axios';
import TPageConfigurationData from 'components/tables/interfaces/pageConfigurationInterface';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import axiosServices from 'utils/axios';
import { IResponseWithPageConfigurations } from './types/types.services';

class PageConfigurationServices {
  getPageConfig = async () => {
    try {
      const response: IResponseWithPageConfigurations = await axiosServices.get('api/setting', {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  createPageConfiguration = async (PageConfigurationData: TPageConfigurationData) => {
    try {
      const response: IResponseWithPageConfigurations = await axiosServices.post(`api/setting`, PageConfigurationData, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Page Configuration Updated Successfully!`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  editPageConfiguration = async (PageConfigurationData: TPageConfigurationData) => {
    try {
      const response: IResponseWithPageConfigurations = await axiosServices.put(`api/setting`, PageConfigurationData, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Page Configuration was Updated Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
}

const PageConfigurationServicesInstance = new PageConfigurationServices();
export default PageConfigurationServicesInstance;
