import { Card, CardContent, Typography, useTheme } from '@mui/material';
import LinearWithLabel from 'components/@extended/progress/LinearWithLabel';
import { isFinite } from 'lodash';
import { formateData } from 'utils/common';
import { TOpenInvoicesReport } from '../types/types.report';
import TotalOpenInvoicesReportCard from './OpenInvoicesReport/TotalOpenInvoicesReportCard';

const OpenInvoicesReport = ({ reportData }: { reportData: TOpenInvoicesReport }) => {
  //---------------------constants----------------
  const theme = useTheme();
  const invoiceData = [
    { title: reportData['0'].title, data: reportData['0'].data, color: theme.palette.error.main },
    { title: reportData['1'].title, data: reportData['1'].data, color: theme.palette.warning.main },
    { title: reportData['2'].title, data: reportData['2'].data, color: theme.palette.success.main }
  ];

  return (
    <div className="grid sm:grid-cols-1 lg:grid-cols-3 gap-4">
      <TotalOpenInvoicesReportCard reportData={reportData} invoiceData={invoiceData} />
      <div className="col-span-1 lg:col-span-2">
        <Card className="grid divide-y">
          {reportData &&
            invoiceData.map((singleData: { title: string; data: number; color: string }, index: number) => (
              <CardContent key={index} className="grid grid-cols-1 gap-1 sm:grid-cols-4 align-middle items-center">
                <div className="col-span-1 justify-center">
                  <Typography variant="h5" className="font-semibold">
                    {singleData.title}
                  </Typography>
                  <Typography className="text-sm text-gray-600">{formateData(singleData.data ?? 0, '$')}</Typography>
                </div>
                <div className="col-span-3">
                  <LinearWithLabel
                    className="sm:h-4"
                    sx={{ color: singleData.color }}
                    color="inherit"
                    value={
                      isFinite((singleData.data * 100) / reportData.total_open_invoices)
                        ? (singleData.data * 100) / reportData.total_open_invoices
                        : 0
                    }
                  />
                </div>
              </CardContent>
            ))}
        </Card>
      </div>
    </div>
  );
};
export default OpenInvoicesReport;
