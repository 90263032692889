import { useQuery } from '@tanstack/react-query';
import ProductPlaceholder from 'components/cards/skeleton/ProductPlaceholder';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PublicSerivcesInstance from 'services/service.noAuth';
import { AcceptForm } from './Components/AcceptProposalForm';
import { DeclineForm } from './Components/DeclineProposalForm';
import { jobStates } from 'utils/constants';
import { dispatch, useSelector } from 'store';
import { setGeneralSettings } from 'store/reducers/customReducers/slice.settings';

const AcceptAndDeclineProposalForm = () => {
  //--------------------Constants---------------------
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const action = searchParams.get('action');
  const jobId = searchParams.get('job_id');
  const optionId = searchParams.get('option_id');
  const navigate = useNavigate();
  const settings = useSelector((state) => state.settings);
  //-------------------useQuery---------------
  const { data: jobData } = useQuery({
    queryKey: ['single_job_data', jobId],
    queryFn: () => PublicSerivcesInstance.getJobInfo(jobId as string, 'approval')
  });
  //----------------useEffects---------------
  useEffect(() => {
    dispatch(setGeneralSettings({ ...settings, type_case: jobData?.type_case }));

    if (jobData && jobData?.job?.previous_states) {
      const previousState = jobData?.job?.previous_states ?? [];
      if (
        jobData?.job.state !== jobStates.PROPOSAL_SENT &&
        (previousState.includes('proposal_approved') || previousState.includes('proposal_declined'))
      ) {
        navigate(`/proposal/${jobData.job._id}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobData]);

  return !!jobData ? (
    <>
      {!!optionId?.length && action === 'accept' && <AcceptForm jobData={jobData} optionId={optionId} />}
      {action === 'decline' && <DeclineForm jobData={jobData} />}
    </>
  ) : (
    <ProductPlaceholder />
  );
};
export default AcceptAndDeclineProposalForm;
