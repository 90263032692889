import { EnvironmentFilled, InfoCircleFilled, MailFilled, MessageFilled, PhoneFilled } from '@ant-design/icons';
import { Button, ButtonGroup, IconButton, Menu, useMediaQuery, useTheme } from '@mui/material';
import CustomTooltip from 'components/CustomTooltip';
import { useState } from 'react';

type TCustomerQuickActions = {
  onSmsClick: () => void;
  onCallClick: () => void;
  onEmailClick: () => void;
  onGetDirectionClick: () => void;
  onInfoClick: () => void;
  onGoogleEarthClick: () => void;
  onMapViewClick: () => void;
  onStreetViewClick: () => void;
  containerClassName?: string;
};

const CustomerQuickActions = (props: TCustomerQuickActions) => {
  const theme = useTheme();
  const {
    containerClassName = '',
    onCallClick,
    onEmailClick,
    onSmsClick,
    onInfoClick,
    onGetDirectionClick,
    onGoogleEarthClick,
    onMapViewClick,
    onStreetViewClick
  } = props;

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [locationAnchorEl, setLocationAnchorEl] = useState<null | HTMLElement>(null);

  const closeLocationMenu = () => {
    setLocationAnchorEl(null);
  };

  const openLocationMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (!!locationAnchorEl) {
      closeLocationMenu();
    } else setLocationAnchorEl(event.currentTarget);
  };

  return (
    <ButtonGroup color="primary" variant="contained" className={`shadow-none bg-slate-50/10 ${containerClassName}`}>
      <CustomTooltip message="View Customer Details" props={{ color: 'primary' }}>
        <IconButton sx={{ color: theme.palette.primary.light }} className="rounded-none rounded-l-md" size={'large'} onClick={onInfoClick}>
          <InfoCircleFilled />
        </IconButton>
      </CustomTooltip>
      <IconButton sx={{ color: theme.palette.success.main }} className="rounded-none" size={'large'} onClick={onCallClick}>
        <PhoneFilled />
      </IconButton>
      <IconButton sx={{ color: '#3c7bff' }} className="rounded-none" size={'large'} onClick={onEmailClick}>
        <MailFilled />
      </IconButton>
      <IconButton sx={{ color: '#eeb509' }} className="rounded-none" size={'large'} onClick={onSmsClick}>
        <MessageFilled />
      </IconButton>
      <IconButton
        sx={{ color: theme.palette.error.main }}
        className="rounded-none rounded-r-md z-[999]"
        size={'large'}
        onClick={openLocationMenu}
      >
        <EnvironmentFilled />
      </IconButton>
      <Menu
        classes={{
          root: '!z-[90]',
          paper: `!shadow-lg p-2 sm:pl-12 pl-2 sm:pt-2 ${isMobile ? 'rounded-lg' : 'rounded-l-full'}`,
          list: 'flex sm:flex-row flex-col items-center gap-2'
        }}
        anchorEl={locationAnchorEl}
        open={!!locationAnchorEl}
        onClose={closeLocationMenu}
        anchorOrigin={
          isMobile
            ? {
                vertical: 'top',
                horizontal: 'right'
              }
            : {
                vertical: 'center',
                horizontal: 'left'
              }
        }
        transformOrigin={
          isMobile
            ? {
                vertical: 'top',
                horizontal: 'left'
              }
            : {
                vertical: 'center',
                horizontal: 'left'
              }
        }
      >
        <Button fullWidth className="min-w-max" onClick={onStreetViewClick}>
          Street view
        </Button>
        <Button fullWidth className="min-w-max" onClick={onMapViewClick}>
          Map view
        </Button>
        <Button fullWidth className="min-w-max" onClick={onGetDirectionClick}>
          Get direction
        </Button>
        <Button fullWidth className="min-w-max" onClick={onGoogleEarthClick}>
          Google Earth
        </Button>
      </Menu>
    </ButtonGroup>
  );
};

export default CustomerQuickActions;
