import { useTheme } from '@mui/system';
import PhoneInput, { CountryData } from 'react-phone-input-2';

import 'react-phone-input-2/lib/semantic-ui.css';
import { ThemeMode } from 'types/config';

type TPhoneNumberInput = {
  value: any;
  onChange: (value: string, data: CountryData, event: React.ChangeEvent<HTMLInputElement>, formattedValue: string) => void;
  className?: string;
  inputProps?: { autoFocus: boolean };
  disabled?: boolean;
  country?: string | number;
  id?: string;
  error?: boolean;
  autoFocus?: boolean;
  focus?: boolean;
};

const PhoneNumberInput = ({
  inputProps,
  value,
  onChange,
  country = 'us',
  disabled = false,
  error = false,
  ...props
}: TPhoneNumberInput) => {
  const theme = useTheme();

  return (
    <PhoneInput
      inputProps={{
        autoFocus: props.autoFocus ?? true,
        focus: props.focus ?? false
      }}
      buttonStyle={{ background: 'transparent' }}
      key={props?.id}
      searchStyle={{ background: 'black' }}
      dropdownStyle={{ background: theme.palette.mode === ThemeMode.DARK ? '#1E1E1E' : '' }}
      placeholder="0000000000"
      isValid={!error}
      inputClass={props?.className}
      disabled={disabled}
      defaultErrorMessage="Phone should be valid"
      country={country}
      inputStyle={{ width: '100%', height: '2.5rem', background: 'transparent', MozUserFocus: 'initial' }}
      value={value}
      onChange={onChange}
    />
  );
};

export default PhoneNumberInput;
