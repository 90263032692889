import { Autocomplete, FormHelperText, InputLabel, TextField, Typography } from '@mui/material';
import ProductPlaceholder from 'components/cards/skeleton/ProductPlaceholder';
import TEstimateData from 'components/tables/interfaces/estimateTableInterface';
import { FormikContextType, getIn, useFormikContext } from 'formik';
import { TGetAppointmentWidgetData } from 'pages/Settings/types/types.autoAppointment';
import { useEffect } from 'react';
import { TAppointmentWidget } from '../types/types.appointmentWidget';

const AreaSelectionStep = ({
  activeStep,
  isAppointmentWidgetDataFetched,
  appointmentConfigData
}: {
  activeStep: number;
  isAppointmentWidgetDataFetched: boolean;
  appointmentConfigData: TGetAppointmentWidgetData['data']['data'];
}) => {
  //-----------------------constants------------------
  const { values, setFieldValue, errors, touched }: FormikContextType<TEstimateData & TAppointmentWidget> = useFormikContext();

  //----------------------------useEffect--------------
  useEffect(() => {
    if (!!appointmentConfigData)
      setFieldValue('service', {
        id: appointmentConfigData.auto_appointment_service?._id,
        name: appointmentConfigData.auto_appointment_service?.service?.name
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentConfigData]);

  return isAppointmentWidgetDataFetched && !!appointmentConfigData && !!appointmentConfigData.auto_appointment_areas?.length ? (
    <div className="space-y-2">
      <div className="flex justify-between">
        <InputLabel>Choose Your&nbsp;{appointmentConfigData.auto_appointment_schedule.area}</InputLabel>
        <Typography>
          <span className="font-semibold">Service:</span>&nbsp;&nbsp;
          {appointmentConfigData.auto_appointment_service?.service?.name}
        </Typography>
      </div>
      <Autocomplete
        disabled={activeStep !== 0}
        options={appointmentConfigData.auto_appointment_areas}
        getOptionLabel={(option: { _id: string; area_name: string }) => option.area_name}
        onChange={(event, newValue) => setFieldValue('area', { id: newValue?._id, name: newValue?.area_name })}
        value={appointmentConfigData.auto_appointment_areas.find((singleArea) => singleArea._id === values.area.id)}
        renderInput={(params) => <TextField {...params} />}
      />
      {getIn(touched, 'area.id') && getIn(errors, 'area.id') && (
        <FormHelperText error id="area.id">
          {getIn(errors, 'area.id')}
        </FormHelperText>
      )}
    </div>
  ) : (
    <ProductPlaceholder />
  );
};

export default AreaSelectionStep;
