// ==============================|| SLICE ||============================== //

import { createSlice } from '@reduxjs/toolkit';

export type TCustomerDetailsView = {
  view?: boolean;
  selectedCustomer?: {
    id: string;
    name: string;
  } | null;
};

type TCustomerState = {
  customerDetailsView: TCustomerDetailsView;
};

// initial state
const initialState: TCustomerState = {
  customerDetailsView: { view: false, selectedCustomer: null }
};

const customerSlice = createSlice({
  name: 'followup_actions',
  initialState,
  reducers: {
    setCustomerDetils(state, action: { payload: TCustomerDetailsView }) {
      state.customerDetailsView.view = action.payload.view;
      state.customerDetailsView.selectedCustomer = action.payload.selectedCustomer;
    }
  }
});

export default customerSlice.reducer;

export const { setCustomerDetils } = customerSlice.actions;
