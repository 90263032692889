import { ArrowLeftOutlined, FileOutlined } from '@ant-design/icons';
import { Avatar, CardHeader, CardMedia, Divider, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import CustomDataTable from 'components/tables/CustomDataTable';
import dayjs from 'dayjsConfig';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import payrollServicesInstance from 'services/services.payroll';
import { useSelector } from 'store';
import { formateData, fromUrlSafeBase64, snakeCaseToTitleCase } from 'utils/common';
import { TUserPayrollSingleDay } from './types/types.payroll';

const UserPayrollListSingleDayView = () => {
  //---------------------Constants----------------
  const { id: userId, date: selectedDate } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const primaryColor = `${theme.palette.primary.main}`;
  const settings = useSelector((state) => state.settings.generalSettings);
  const timezone = useMemo(() => settings?.timezone ?? 'America/New_York', [settings]);

  const columns = useMemo<ColumnDef<TUserPayrollSingleDay>[]>(
    () => [
      {
        accessorFn: (row) => row?.job?.name ?? '-',
        id: 'job_name',
        cell: ({
          row: {
            original: { job }
          }
        }) =>
          job?.id ? (
            <Typography
              sx={{
                '&:hover': {
                  color: primaryColor
                }
              }}
              className={`cursor-pointer`}
              onClick={() => navigate(`/jobs/view/${job?.id}/job_status`)}
            >
              {job?.name}
            </Typography>
          ) : (
            '-'
          ),
        header: () => <span>Job Name</span>
      },
      {
        accessorFn: (row) => row.created_at,
        id: 'time',
        header: () => <span>Pay Time</span>,
        cell: ({ row }) => {
          return <Typography>{dayjs(row.original.created_at).tz(timezone).format('hh:mm A')}</Typography>;
        }
      },
      {
        accessorFn: (row) => formateData(row?.payment ?? 0, '$'),
        id: 'pay',
        header: () => <span>Pay</span>
      },
      {
        accessorFn: (row) => formateData(row?.paid_amount ?? 0, '$'),
        id: 'paid_amount',
        header: () => <span>Paid Amount</span>
      },
      {
        accessorFn: (row) => row.payment_type ?? '-',
        id: 'payment_type',
        header: () => <span>Payment/Action (Type)</span>
      },
      {
        accessorFn: (row) => formateData(row?.customer_paid ?? 0, '$'),
        id: 'customer_paid',
        header: () => <span>Customer Paid</span>
      },
      {
        accessorFn: (row) => formateData(row?.total_amount ?? 0, '$'),
        id: 'total_paid',
        header: () => <span>Total Amount</span>
      },
      {
        cell: ({ row }) => {
          return row.original?.proposal_link?.length ? (
            <div className="flex flex-wrap w-full max-w-[30rem]">
              <a href={row.original?.proposal_link} target="blank" className="mr-2 mt-2">
                <Avatar variant="rounded" sx={{ maxHeight: '8rem' }}>
                  <FileOutlined />
                </Avatar>{' '}
              </a>
            </div>
          ) : (
            'N/A'
          );
        },
        id: 'proposal',
        header: () => <span>Proposal</span>
      },
      {
        accessorFn: (row) => row.note ?? '-',
        id: 'notes',
        header: () => <span>Instructions/Notes</span>
      },
      {
        cell: ({ row: { original: originalData } }) => {
          return <CardMedia src={originalData.payment_signature} component={'img'} />;
        },
        id: 'payment_signature',
        header: () => <span>Payment Signature</span>
      },
      {
        accessorFn: (row) => row?.pay_by?.name ?? '-',
        id: 'user',
        header: () => <span>Pay By</span>
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  //-----------------------useQuery-------------------
  const {
    data: payrollData,
    isFetched: isPayrollDataFetched,
    isLoading
  } = useQuery({
    queryKey: ['single_user_payroll_data', userId, selectedDate],
    queryFn: () => {
      if (!!userId?.length && !!selectedDate?.length)
        return payrollServicesInstance.getUserPayrollSingleDayData(userId, fromUrlSafeBase64(selectedDate));
    },
    enabled: !!userId?.length && !!selectedDate?.length
  });

  return (
    <div>
      {isPayrollDataFetched && !!payrollData && (
        <>
          <CardHeader
            title={
              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'flex-start', sm: 'center' }} className="gap-2 w-full">
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowLeftOutlined />
                </IconButton>
                <div>
                  {payrollData.user?.first_name} {payrollData.user?.last_name} ({snakeCaseToTitleCase(payrollData.user?.role)})
                </div>
                <Divider flexItem orientation="vertical" sx={{ border: 0.5 }} />
                <div>{dayjs(fromUrlSafeBase64(selectedDate)).tz(timezone).format('ddd, MMMM DD, YYYY')}</div>
              </Stack>
            }
          />
          <CustomDataTable data={payrollData.payrolls} columns={columns} isDataLoading={isLoading} />
        </>
      )}
    </div>
  );
};

export default UserPayrollListSingleDayView;
