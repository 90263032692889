import { Divider, Hidden, useMediaQuery, useTheme } from '@mui/material';
import { Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { formatPhoneNumber, handleRedirectToGoogleMap, snakeCaseToTitleCase } from 'utils/common';
import { TCustomerBilling } from '../types/type.customersPage';
import UniversalDialog from 'components/popups/UniversalDialog';
import AddEmailorSms from 'components/popups/AddEmailorSms';
import { TCustomerAddEmailSMSPopup } from '../types/type.customersView';

const CustomerBilling = ({ data }: { data?: TCustomerBilling }) => {
  //------------------------Constants---------------------------
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const [customerDetails, setCustomerDetils] = useState<{ label: string; value: string | JSX.Element }[] | null>(null);
  const [addEmailOrSmsPopup, setAddEmailOrSmsPopup] = useState<TCustomerAddEmailSMSPopup>({
    action: { open: false, isEmail: true, showJobName: true },
    data: {}
  });
  //----------------------useEffects-------------------
  useEffect(() => {
    const customerDetailsData: { label: string; value: string | JSX.Element }[] = [
      { label: 'Billing Contact Name:', value: `${data?.first_name} ${data?.last_name}` },
      {
        label: 'Billing Address1:',
        value: (
          <Typography
            onClick={() => {
              if (data) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { address2, ...address } = data.address;
                handleRedirectToGoogleMap(address);
              }
            }}
            sx={{ '&:hover': { color: theme.palette.primary.light }, cursor: 'pointer' }}
          >
            {data?.address.address1}
          </Typography>
        )
      },
      {
        label: 'Billing Address2:',
        value: (
          <Typography
            onClick={() => {
              if (data) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { address1, ...address } = data.address;
                handleRedirectToGoogleMap(address);
              }
            }}
            sx={{ '&:hover': { color: theme.palette.primary.light }, cursor: 'pointer' }}
          >
            {data?.address.address2}
          </Typography>
        )
      },
      { label: 'Billing City', value: data?.address.city ?? '' },
      { label: 'Billing State', value: data?.address.state ?? '' },
      {
        label: 'Billing Zip',
        value: data?.address.zip ?? ''
      },
      {
        label: 'Billing Country',
        value: data?.address.country ?? ''
      },
      {
        label: 'Phone1',
        value: data?.phone1 ? (
          <a href={`tel:+${data?.phone1_country_code}${data?.phone1}${data?.phone1_extension ? `,${data?.phone1_extension}` : ''}`}>
            <span>{snakeCaseToTitleCase(data?.phone1_type as string)}</span> &nbsp;{' '}
            {/* {`+${data.phone1_country_code}${data.phone1_extension ? ` (${data.phone1_extension})` : ''} ${data?.phone1}${
              data.phone1_extension ? ` x${data.phone1_extension}` : ''
            }`} */}
            {formatPhoneNumber(data?.phone1)}
          </a>
        ) : (
          '-'
        )
      },
      {
        label: 'Phone2',
        value: data?.phone2 ? (
          <span>
            <span>{snakeCaseToTitleCase(data?.phone2_type as string)}</span> &nbsp;{' '}
            {/* {`+${data.phone2_country_code}${data.phone2_extension ? ` (${data.phone2_extension})` : ''} ${data?.phone2}${
              data.phone2_extension ? ` x${data.phone2_extension}` : ''
            }`} */}
            {formatPhoneNumber(data?.phone2)}
          </span>
        ) : (
          '-'
        )
      },
      {
        label: 'Email',
        value: (
          <Typography
            onClick={() => {
              setAddEmailOrSmsPopup({
                action: { open: true, isEmail: true, showJobName: true },
                data: {
                  id: data?._id,
                  name: `${data?.first_name} ${data?.last_name}`,
                  emails: data?.emails
                }
              });
            }}
            sx={{ cursor: 'pointer', '&:hover': { color: theme.palette.primary.main } }}
          >
            {data?.emails.join(',\n')}
          </Typography>
        )
      },
      { label: 'Why Are We Billing Them Instead Of You?:', value: data?.reason ? data?.reason : '' }
    ];

    setCustomerDetils(customerDetailsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const handleAddEmailorSmsPopup = () => {
    setAddEmailOrSmsPopup({
      action: { open: false, isEmail: true, showJobName: true },
      data: {}
    });
  };
  return (
    <>
      {data && customerDetails && (
        <Stack className="h-full" direction={{ xs: 'column', md: 'row' }} padding={2}>
          <Table>
            <TableBody>
              {customerDetails
                .slice(0, Math.ceil(mdDown ? customerDetails.length : customerDetails.length / 2))
                .map((singleData, index) => (
                  <TableRow key={index} className="border-hidden">
                    <TableCell>
                      <Typography variant="subtitle1" className="font-bold text-gray-400">
                        {singleData.label}
                      </Typography>
                    </TableCell>
                    <TableCell className="p-2">
                      <Typography variant="body1">{singleData.value}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Divider orientation="vertical" flexItem />
          <Hidden mdDown>
            <Table>
              <TableBody>
                {customerDetails.slice(Math.ceil(customerDetails.length / 2)).map((singleData, index) => (
                  <TableRow key={index} className="border-hidden">
                    <TableCell>
                      <Typography variant="subtitle1" className="font-bold text-gray-400">
                        {singleData.label}
                      </Typography>
                    </TableCell>
                    <TableCell className="p-2">
                      <Typography variant="body1">{singleData.value}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Hidden>
        </Stack>
      )}
      {addEmailOrSmsPopup.action.open && (
        <UniversalDialog
          action={{ open: addEmailOrSmsPopup.action.open, fullWidth: true }}
          onClose={() => handleAddEmailorSmsPopup()}
          hasPrimaryButton={false}
        >
          <AddEmailorSms {...addEmailOrSmsPopup} onClose={() => handleAddEmailorSmsPopup()} />
        </UniversalDialog>
      )}
    </>
  );
};

export default CustomerBilling;
