import { Alert, AlertTitle, Button, Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import UniversalDialog from 'components/popups/UniversalDialog';
import { IEstimationOption, TUpdateEstimate } from 'components/tables/interfaces/jobTableInterface';
import EstimateOptions from 'pages/jobs/EstimateOptions';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import EstimateServicesInstance from 'services/services.estimates';
import JobServicesInstance from 'services/services.jobs';
import { dispatch, useSelector } from 'store';
import { addLogs, clearLogs } from 'store/reducers/customReducers/slice.logs';
import { jobStates } from 'utils/constants';
import * as v4 from 'uuidv4';
import AddTermAndConditionFrom from './AddTermAndConditionFrom';
import EstimationUpdateForm from './EstimationUpdateForm';
import { createMultipleLogs } from 'utils/common';
import { LoadingOutlined } from '@ant-design/icons';

type TAddEstimationFormProps = {
  jobId: string;
  isAppointmentJob?: boolean;
  jobName?: string;
  isEditMode?: boolean;
  existingData?: {
    options: IEstimationOption[];
    terms_and_conditions: string;
  };
  defaultTaxRate: number;
  currentState?: string;
  previousStates?: string[];
  onSuccess?: () => void;
  customerData: any;
};

export const AddEstimationForm = (props: TAddEstimationFormProps) => {
  const { jobId, existingData, isEditMode, onSuccess, previousStates, currentState, defaultTaxRate, customerData } = props;

  const navigate = useNavigate();
  const estimateOptionData = useSelector((state) => state.estimateOptions.optionsData);
  const activeTabTitle = useSelector((state) => state.estimateOptions.activeTabTitle);
  const [isSubmitting, setIsSubmitting] = useState(false);
  //----------------------useQuery--------------------------

  const { data: proposalTemplateData } = useQuery({
    queryKey: ['proposal_template'],
    queryFn: () => EstimateServicesInstance.getProposalTemplate(),
    networkMode: 'always'
  });

  const [addOptionAlert, setAddOptionAlert] = useState<boolean>(false);

  const [termAndConditionData, setTermAndConditionData] = useState('');

  const [tncError, setTncError] = useState<boolean>(false);

  const [openUpdateEstimationDialog, setOpenUpdateEstimationDialog] = useState<boolean>(false);

  const handleCreateEstimation = async (values?: TUpdateEstimate) => {
    setIsSubmitting(true);

    if (!termAndConditionData.length || estimateOptionData.some((option) => option.items && !option.items.length)) {
      setTncError(!termAndConditionData.length);
      setAddOptionAlert(!estimateOptionData.some((option) => option.items && option.items.length));
      return;
    }
    const newEstimateOptionData = estimateOptionData.map((eachOption) => {
      return openUpdateEstimationDialog ? { ...eachOption, update_reason: values?.update_reason } : { ...eachOption };
    });
    await EstimateServicesInstance.upsertEstimateOptions(
      jobId,
      newEstimateOptionData,
      termAndConditionData,
      !!values?.customer_sign_on_estimation.length ? currentState : jobStates.NEED_REVIEW,
      openUpdateEstimationDialog ? values : ({} as TUpdateEstimate)
    )
      .then(async (response) => {
        if (response?.success) {
          createMultipleLogs({
            module: 'job',
            is_customer_log_also: true,
            logs:
              !isEditMode && logsOption.length === 1
                ? logsOption
                : [
                    ...logsOption,
                    {
                      description: `${activeTabTitle} estimate updated`,
                      action: 'estimate_updated'
                      // attachments: []
                    }
                  ],
            job: { id: jobId, name: props?.jobName ?? '' }
          });
          if (values?.customer_sign_on_estimation === 'required' && !values.is_customer_present)
            await JobServicesInstance.resendSignature(jobId);
          onSuccess?.();
          navigate(`/jobs/view/${jobId}/job_status`);
        }
      })
      .catch((err) => console.log('Error while updating estimations'))
      .finally(() => setIsSubmitting(false));
    return;
  };

  const handleSubmitButtonClick = () => {
    if (isEditMode) {
      if (previousStates && previousStates.includes('proposal_approved')) {
        setOpenUpdateEstimationDialog(true);
        return;
      }
      handleCreateEstimation();
    } else {
      handleCreateEstimation();
    }
  };

  const handleTermAndConditionsFormData = (value: string) => {
    setTncError(false);
    setTermAndConditionData(value);
  };

  //-----------------useEffects-----------------
  const logsOption = useSelector((state) => state.logsAction);

  useEffect(() => {
    if (isEditMode && existingData) {
      setTermAndConditionData(existingData?.terms_and_conditions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingData]);

  useEffect(() => {
    if (proposalTemplateData) {
      setTermAndConditionData(proposalTemplateData.proposal_terms_and_conditions);
    }
  }, [proposalTemplateData]);
  useEffect(() => {
    if (!isEditMode) {
      dispatch(
        addLogs({
          description: 'Option 1',
          action: 'estimate_created'
          // ,  attachments: []
        })
      );
    }
    return () => {
      dispatch(clearLogs());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formattedOptionsData = (options?: IEstimationOption[]) => {
    if (options) {
      return options.map((eachOption, eachOptionIndex) => ({
        title: eachOption.option_name,
        id: eachOption.id ?? v4.uuid(),
        value: `option-${eachOptionIndex + 1}`,
        optionId: eachOptionIndex,
        data: eachOption
      }));
    }
  };

  const existingOptionsData = formattedOptionsData(existingData?.options);

  return (
    <div className="w-full">
      <EstimateOptions
        isAppointmentJob={props.isAppointmentJob}
        defaultTaxRate={defaultTaxRate}
        optionData={existingOptionsData}
        isEditMode={isEditMode}
        previousStates={previousStates}
        customerData={customerData}
        jobId={jobId}
      />

      <Grid item xs={12} sm={6} md={12} className="space-y-4 p-4">
        <Typography variant="h4">Terms & Conditions*</Typography>
        {tncError && <Alert severity="error">Terms and Conditions are required</Alert>}
        {navigator.onLine ? (
          <AddTermAndConditionFrom
            handleTermAndConditionsFormData={handleTermAndConditionsFormData}
            termAndConditionData={termAndConditionData}
          />
        ) : (
          <p dangerouslySetInnerHTML={{ __html: termAndConditionData }} />
        )}
      </Grid>

      <div className="w-full flex justify-end my-6 pr-8">
        <div className="flex flex-row space-x-3">
          {addOptionAlert && (
            <Alert severity="error">
              <AlertTitle>Please choose an item for each option.</AlertTitle>
            </Alert>
          )}
          <Button
            variant="contained"
            sx={{ px: 4, py: 1 }}
            type="submit"
            onClick={handleSubmitButtonClick}
            startIcon={isSubmitting && <LoadingOutlined />}
            disabled={isSubmitting || estimateOptionData.some((option) => option.items && !option.items.length)}
          >
            {props.isEditMode ? 'Update Estimate' : 'Create Estimate'}
          </Button>
        </div>
      </div>
      {openUpdateEstimationDialog && (
        <UniversalDialog
          title="Estimation Update Reason"
          action={{ open: openUpdateEstimationDialog, maxWidth: 'sm', fullWidth: true }}
          onClose={() => setOpenUpdateEstimationDialog(false)}
          hasPrimaryButton={false}
        >
          <EstimationUpdateForm onSave={handleCreateEstimation} />
        </UniversalDialog>
      )}
    </div>
  );
};
