// material-ui
import { BarsOutlined } from '@ant-design/icons';
import { Checkbox, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { TPermissionsData } from 'services/types/types.services';

// project imports
import { RadioGroup } from '@mui/material';
import { permissionsTypes } from 'utils/constants';
import ExpensesPermissions from './ExpensesPermissions';
import ReportsPermissions from './ReportsPermissions';

// =========================|| DATA WIDGET - LATEST ORDER ||========================= //

const orderOfRoutes = ['create', 'get', 'update', 'delete'];

const ModulePermissionTable = ({
  modulePermissionsData,
  onChange,
  permissionData
}: {
  modulePermissionsData: Array<string>;
  onChange: (arg0: any) => void;
  permissionData?: TPermissionsData[];
}) => {
  const [reportsDialog, setReportsDialog] = useState<{
    action: {
      open: boolean;
      fullWidth: boolean;
    };
    title: string;
    data: string[];
  }>({
    action: {
      open: false,
      fullWidth: true
    },
    title: '',
    data: []
  });

  const [expenseDialog, setExpenseDialog] = useState<{
    action: {
      open: boolean;
      fullWidth: boolean;
    };
    title: string;
    data: string[];
  }>({
    action: {
      open: false,
      fullWidth: true
    },
    title: '',
    data: []
  });

  const [adminPermissionData, setAdminPermissionData] = useState<Array<TPermissionsData & { admin: boolean }>>([]);

  useEffect(() => {
    if (!permissionData?.length) return;

    const permissionsWithAdmin = permissionData?.map((permission) => {
      const routes = orderOfRoutes.map((order) => permission.routes.find((route) => route.toLowerCase().startsWith(order)) || '');
      if (permission.name === 'Reports') {
        return { ...permission, admin: false };
      }
      if (permission.name === 'Expenses') {
        return { ...permission, admin: permission.routes.every((route) => modulePermissionsData.includes(route)) };
      }
      return {
        ...permission,
        admin: permission.routes.every((route) => modulePermissionsData.includes(route)),
        routes
      };
    });

    setAdminPermissionData(permissionsWithAdmin);
  }, [permissionData, modulePermissionsData]);

  const handleReoprtsOptionClick = (routes: string[]) => {
    const newRoutes = routes.filter((route) => route !== '');
    setReportsDialog({
      action: {
        open: true,
        fullWidth: true
      },
      title: 'Reports Permissions',
      data: newRoutes
    });
  };

  const handleReportPermissionClose = () => {
    setReportsDialog({
      action: {
        open: false,
        fullWidth: true
      },
      title: 'Reports Permissions',
      data: []
    });
  };

  const handleAdminPermissionClick = (event: React.ChangeEvent<HTMLInputElement>, routes: string[], index: number) => {
    const newRoutes = routes.filter((route) => route !== '');
    if (event.target.checked) {
      const newCheckArray = [...new Set([...(modulePermissionsData ?? []), ...newRoutes])];
      onChange(newCheckArray);
    } else {
      const setRoutes = new Set(newRoutes);
      const newCheckArray = [...(modulePermissionsData ?? [])].filter((ele) => !setRoutes.has(ele));
      onChange(newCheckArray);
    }
    const newAdminPermissionData = [...adminPermissionData];
    newAdminPermissionData[index].admin = event.target.checked;
    setAdminPermissionData(newAdminPermissionData);
  };

  const handleSingleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>, routes: string[], index: number) => {
    const newCheckArray = [...(modulePermissionsData ?? [])];
    if (event.target.checked) {
      newCheckArray.push(event.target.value);
      onChange(newCheckArray);
    } else {
      newCheckArray.splice(newCheckArray.indexOf(event.target.value), 1);
      onChange(newCheckArray);
    }
    const newAdminPermissionData = [...adminPermissionData];
    newAdminPermissionData[index].admin = routes.filter((route) => route !== '').every((route) => newCheckArray.includes(route));

    setAdminPermissionData(newAdminPermissionData);
  };

  //  ---------------- Expense Permissions ----------------
  const renderExpensePermissions = (
    index: number,
    singlePermission: TPermissionsData & {
      admin: boolean;
    },
    singleRoute: string
  ) => {
    if ('createExpense' === singleRoute)
      return (
        <IconButton color="primary" size="large" onClick={() => handleExpenseOptionClick(singlePermission.routes)}>
          <BarsOutlined />
        </IconButton>
      );

    if (singleRoute.includes('delete') || singleRoute.includes('update') || singleRoute.includes('admin')) {
      return <>-</>;
    }
    return (
      <Checkbox
        value={singleRoute}
        color="primary"
        checked={modulePermissionsData.includes(singleRoute) ?? false}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSingleCheckboxClick(event, singlePermission.routes, index)}
        disabled={singleRoute === '' ? true : false}
      />
    );
  };

  const handleExpensePermissionClose = () => {
    setExpenseDialog((prevExpenseDialog) => {
      return {
        ...prevExpenseDialog,
        action: {
          open: false,
          fullWidth: true
        },
        title: 'Expense Permissions',
        data: []
      };
    });
  };

  const handleExpenseOptionClick = (routes: string[]) => {
    const newRoutes = routes.filter((route) => route !== '');
    setExpenseDialog({
      action: {
        open: true,
        fullWidth: true
      },
      title: 'Expense Permissions',
      data: newRoutes
    });
  };

  //  ---------------- Invoice Permissions ----------------
  const renderInvoicePermissions = (
    index: number,
    singlePermission: TPermissionsData & {
      admin: boolean;
    },
    singleRoute: string
  ) => {
    if (singleRoute.includes('delete') || singleRoute.includes('admin') || singleRoute.includes('create')) {
      return <>-</>;
    }
    return (
      <Checkbox
        value={singleRoute}
        color="primary"
        checked={modulePermissionsData.includes(singleRoute) ?? false}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSingleCheckboxClick(event, singlePermission.routes, index)}
        disabled={singleRoute === '' ? true : false}
      />
    );
  };

  return (
    <>
      <TableContainer component={Paper} elevation={2}>
        <Table sx={{ minWidth: 350 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ pl: 3 }}>Permission</TableCell>
              {permissionsTypes.map((permission) => (
                <TableCell align="center">{permission.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {adminPermissionData?.map((singlePermission, index) => {
              if (singlePermission.key === 'reports') {
                return (
                  <TableRow key={singlePermission.key}>
                    <TableCell component="th" scope="row">
                      {singlePermission.name}
                    </TableCell>

                    {Array.from({ length: 4 }, (_, index) => (
                      <TableCell key={index} align="center">
                        {index === 1 ? (
                          <IconButton color="primary" size="large" onClick={() => handleReoprtsOptionClick(singlePermission.routes)}>
                            <BarsOutlined />
                          </IconButton>
                        ) : (
                          <>-</>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              }
              if (singlePermission.key === 'expenses') {
                return (
                  <TableRow
                    key={singlePermission.key}
                    component={RadioGroup}
                    onChange={(event) => handleExpenseOptionClick(singlePermission.routes)}
                    value={
                      modulePermissionsData.includes('createExpense') && modulePermissionsData.includes('getExpenses')
                        ? 'createExpense'
                        : modulePermissionsData.includes('getExpenses') && 'getExpenses'
                    }
                  >
                    <TableCell component="th" scope="row">
                      {singlePermission.name}
                    </TableCell>

                    {['createExpense', 'getExpenses', 'updateExpense', 'deleteExpense', 'admin'].map((singleRoute, routeIndex) => {
                      return (
                        <TableCell key={routeIndex} align="center">
                          {renderExpensePermissions(index, singlePermission, singleRoute)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }
              if (singlePermission.key === 'invoices') {
                return (
                  <TableRow
                    key={singlePermission.key}
                    component={RadioGroup}
                    onChange={(event) => handleExpenseOptionClick(singlePermission.routes)}
                    value={
                      modulePermissionsData.includes('createInvoice') && modulePermissionsData.includes('getInvoices')
                        ? 'createInvoice'
                        : modulePermissionsData.includes('getInvoices') && 'getInvoices'
                    }
                  >
                    <TableCell component="th" scope="row">
                      {singlePermission.name}
                    </TableCell>

                    {['createInvoice', 'getInvoices', 'updateInvoice', 'deleteInvoice', 'admin'].map((singleRoute, routeIndex) => {
                      return (
                        <TableCell key={routeIndex} align="center">
                          {renderInvoicePermissions(index, singlePermission, singleRoute)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }
              return (
                <TableRow key={singlePermission.key}>
                  <TableCell component="th" scope="row">
                    {singlePermission.name}
                  </TableCell>

                  {singlePermission?.routes.map((singleRoute, routeIndex) => {
                    return (
                      <TableCell key={routeIndex} align="center">
                        <Checkbox
                          value={singleRoute}
                          color="primary"
                          checked={modulePermissionsData.includes(singleRoute) ?? false}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            handleSingleCheckboxClick(event, singlePermission.routes, index)
                          }
                          disabled={singleRoute === '' ? true : false}
                        />
                      </TableCell>
                    );
                  })}
                  <TableCell align="center">
                    <Checkbox
                      onChange={(e) => handleAdminPermissionClick(e, singlePermission.routes, index)}
                      value={singlePermission.admin}
                      color="primary"
                      checked={singlePermission.admin}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {reportsDialog.action.open && (
        <ReportsPermissions
          open={reportsDialog.action.open}
          title={reportsDialog.title}
          reportsPermissionData={reportsDialog.data}
          existingPermissions={modulePermissionsData}
          onClose={handleReportPermissionClose}
          onSave={onChange}
        />
      )}
      {expenseDialog.action.open && (
        <ExpensesPermissions
          open={expenseDialog.action.open}
          title={expenseDialog.title}
          expensePermissionData={expenseDialog.data}
          existingPermissions={modulePermissionsData}
          onClose={handleExpensePermissionClose}
          onSave={onChange}
        />
      )}
    </>
  );
};

export default ModulePermissionTable;
