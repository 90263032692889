import { AxiosRequestConfig } from 'axios';
import {
  TDateFilter,
  TResponseWithGrossIncome,
  TResponseWithJobWon,
  TResponseWithOpenInvoice,
  TResponsewithDashboardWidgetCard
} from 'pages/Dashboard/types/types.dashboard';
import { TGpsCoordinates, TResponseWGetReport } from 'pages/Reports/types/types.report';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import axiosServices from 'utils/axios';

class DashboardServices {
  getDashboardData = async () => {
    try {
      const response: TResponsewithDashboardWidgetCard = await axiosServices.get('api/dashboard', {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: `Unable to fetch dashboard.`,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
    }
  };
  getGrossIncomeData = async (startDate: string, endDate: string) => {
    try {
      const response: TResponseWithGrossIncome = await axiosServices.get(
        `/api/dashboard/gross-income?filter={"start_date":"${startDate}","end_date":"${endDate}"}`
      );
      if (response.data.success && response.data.data) {
        return response.data.data;
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: `Failed to fetch gross income data.`,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
    }
  };
  getJobWonData = async (jobWonData: TDateFilter) => {
    try {
      const response: TResponseWithJobWon = await axiosServices.get(
        `/api/dashboard/jobs-won?filter={"range": "${jobWonData.filter_value}"${
          jobWonData.filter_value === 'date_range' ? `,"start_date":"${jobWonData.start_date}","end_date":"${jobWonData.end_date}"` : ''
        }}`
      );
      if (response.data.success && response.data.data) {
        return response.data.data;
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: `Failed to fetch job won data.`,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
    }
  };
  getOpenInvoiceData = async () => {
    try {
      const response: TResponseWithOpenInvoice = await axiosServices.get(`/api/dashboard/invoices`);
      if (response.data.success && response.data.data) {
        return response.data.data;
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: `Failed to fetch invoice data.`,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
    }
  };
  //-------------Reports--------------------
  reportData = async (reportFilter: string, dateFilter: TDateFilter): Promise<TGpsCoordinates[] | unknown> => {
    try {
      const response: TResponseWGetReport = await axiosServices.get(
        `/api/dashboard/reports?filter={"report_type": "${reportFilter}", "range": "${dateFilter.filter_value}"${
          dateFilter.filter_value === 'date_range' ? `,"start_date":"${dateFilter.start_date}","end_date":"${dateFilter.end_date}"` : ''
        }${reportFilter === 'getFieldWorkerJobs' ? `,"is_completed":${dateFilter.is_completed}` : ''}}`
      );
      if (response.data && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
}

const DashboardServicesInstance = new DashboardServices();
export default DashboardServicesInstance;
