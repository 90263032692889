import { ArrowLeftOutlined, CarOutlined, CoffeeOutlined, LoginOutlined, LogoutOutlined } from '@ant-design/icons';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineSeparator } from '@mui/lab';
import { Box, Card, CardContent, CardHeader, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useQuery } from '@tanstack/react-query';
import car_marker_true from 'assets/images/icons/truck-green.png';
import dayjs from 'dayjs';
import useConfig from 'hooks/useConfig';
import { TPunchDetails } from 'pages/payrolls/types/types.payroll';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import PunchingServicesInstance from 'services/services.punching';
import { useSelector } from 'store';
import { handleRedirectToGoogleMap } from 'utils/common';
import { googleMapsApiKey } from 'utils/constants';

const containerStyle = {
  width: '100%',
  height: '30dvh'
};

const PunchViewPage = () => {
  //--------------------constants-------------------
  const { id } = useParams();
  const theme = useTheme();
  const themeConfig = useConfig();
  const navigate = useNavigate();

  const settings = useSelector((state) => state.settings.generalSettings);
  const timezone = useMemo(() => settings?.timezone ?? 'America/New_York', [settings]);

  //-------------------useQuery---------------
  const { data: punchData, isFetched: isPunchDataFetched } = useQuery({
    queryKey: ['get_punch_list', id],
    queryFn: () => {
      if (id) return PunchingServicesInstance.getPunchList(id);
    },
    enabled: !!id
  });

  const center = useMemo(() => {
    return {
      lat: Number(punchData?.punching_details[punchData?.punching_details.length - 1].clock_in.location.lat),
      lng: Number(punchData?.punching_details[punchData?.punching_details.length - 1].clock_in.location.long)
    };
  }, [punchData]);

  //------------------components-----------------
  const renderTimelineData = (singlePunchData: TPunchDetails, fieldName: string, punchIndex: number) => {
    switch (fieldName) {
      case 'clock_in': {
        const isSpecifiedEarly =
          !!singlePunchData.clock_in.specified_time &&
          dayjs(singlePunchData.clock_in.time).isBefore(singlePunchData.clock_in.specified_time);
        return (
          <>
            <Stack direction="row">
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineConnector />
                <TimelineDot className="bg-white !my-1">
                  <LoginOutlined className="text-primary" />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent className="mt-20 max-w-full overflow-hidden">
                <Card>
                  <CardContent>
                    <CardHeader
                      className="p-0"
                      title={
                        <div className="flex items-center">
                          <Typography color={isSpecifiedEarly ? theme.palette.error.main : theme.palette.primary.main} variant="h5">
                            {dayjs(singlePunchData.clock_in.time).tz(timezone).format('LT')}
                          </Typography>
                          {singlePunchData.clock_in.specified_time && (
                            <Typography sx={{ ml: 0.5 }}>{` (${dayjs(singlePunchData.clock_in.specified_time)
                              .tz(timezone)
                              .format('LT')})`}</Typography>
                          )}
                          <span>&nbsp;-&nbsp;Clock In</span>
                        </div>
                      }
                      subheader={
                        <Typography
                          sx={{ cursor: 'pointer', color: 'GrayText', '&:hover': { color: theme.palette.primary.light } }}
                          onClick={() => handleRedirectToGoogleMap(singlePunchData.clock_in.address)}
                        >
                          {singlePunchData.clock_in.address}
                        </Typography>
                      }
                    />

                    {singlePunchData?.notes && singlePunchData.notes?.length > 0 && punchIndex === 0 && (
                      <div className="max-w-full flex flex-col items-start mt-6 overflow-hidden">
                        <Typography variant="subtitle1">Punch Notes</Typography>
                        <div className="flex items-center gap-2 flex-wrap px-2">
                          {singlePunchData.notes.map((eachNote) => (
                            <div
                              style={{ background: themeConfig.mode === 'dark' ? 'black' : 'lightgray' }}
                              className="shadow-sm p-2 px-3 min-w-max hover:scale-105 hover:shadow-md transition-all ease-in-out duration-150"
                            >
                              <Typography variant="caption" color={'GrayText'} className="min-w-max">
                                {dayjs(eachNote.time).tz(timezone).format('LT')}
                              </Typography>
                              <Typography variant="body1">{eachNote.note}</Typography>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </CardContent>
                </Card>
              </TimelineContent>
            </Stack>
            {singlePunchData.job?.name && (
              <Stack direction="row">
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot className="bg-white !my-1">
                    <CarOutlined className="text-primary" />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Card>
                    <CardContent
                      className="md:grid md:grid-cols-2"
                      component={Stack}
                      direction={{ xs: 'column', md: 'row' }}
                      justifyContent={{ md: 'space-between' }}
                      spacing={{ xs: '10px', md: '0px' }}
                    >
                      <CardHeader
                        className="p-0"
                        title={
                          <div className="flex items-center">
                            <Typography color={isSpecifiedEarly ? theme.palette.error.main : theme.palette.primary.main} variant="h5">
                              {dayjs(singlePunchData.clock_in.time).tz(timezone).format('LT')}
                            </Typography>
                            {singlePunchData.clock_in.specified_time && (
                              <Typography sx={{ ml: 0.5 }}>{` (${dayjs(singlePunchData.clock_in.specified_time)
                                .tz(timezone)
                                .format('LT')})`}</Typography>
                            )}
                            <span>&nbsp;- &nbsp;Start Job</span>
                          </div>
                        }
                        subheader={<div>{singlePunchData.job.name}</div>}
                      />
                    </CardContent>
                  </Card>
                </TimelineContent>
              </Stack>
            )}
          </>
        );
      }
      case 'clock_out': {
        const isSpecifiedLate = !!singlePunchData.clock_out.specified_time
          ? dayjs(singlePunchData.clock_out.time).isAfter(singlePunchData.clock_out.specified_time)
          : false;
        return (
          <>
            {singlePunchData.job?.name && (
              <Stack direction="row">
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot className="bg-white !my-1">
                    <CarOutlined className="text-slate-400" />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Card>
                    <CardContent>
                      <CardHeader
                        className="p-0"
                        title={
                          <div className="flex items-center">
                            <Typography color={theme.palette.primary.main} variant="h5">
                              {dayjs(singlePunchData.clock_out.time).tz(timezone).format('LT')}
                            </Typography>
                            {singlePunchData.clock_out.specified_time && (
                              <Typography sx={{ ml: 0.5 }}>{` (${dayjs(singlePunchData.clock_out.specified_time)
                                .tz(timezone)
                                .format('LT')})`}</Typography>
                            )}
                            <span>&nbsp;- &nbsp;End Job</span>
                          </div>
                        }
                        subheader={<div>{singlePunchData.job.name}</div>}
                      />
                    </CardContent>
                  </Card>
                </TimelineContent>
              </Stack>
            )}

            <Stack direction="row">
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot className="bg-white !my-1">
                  <LogoutOutlined className={(singlePunchData as any)?.is_auto_logout ? 'text-orange-500' : 'text-slate-400'} />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Card>
                  <CardContent
                    className="md:grid md:grid-cols-2"
                    component={Stack}
                    direction={{ xs: 'column', md: 'row' }}
                    justifyContent={{ md: 'space-between' }}
                    spacing={{ xs: '10px', md: '0px' }}
                  >
                    <CardHeader
                      className="p-0"
                      title={
                        <div className="flex items-center">
                          <Typography color={isSpecifiedLate ? theme.palette.error.main : theme.palette.primary.main} variant="h5">
                            {dayjs(singlePunchData.clock_out.time).tz(timezone).format('LT')}
                          </Typography>
                          {singlePunchData.clock_out.specified_time && (
                            <Typography sx={{ ml: 0.5 }}>
                              {` (${dayjs(singlePunchData.clock_out.specified_time).tz(timezone).format('LT')})`}
                            </Typography>
                          )}
                          <span>&nbsp;- &nbsp;{(singlePunchData as any)?.is_auto_logout ? 'Auto' : ''}Clock Out</span>
                        </div>
                      }
                      subheader={
                        <Typography
                          sx={{ cursor: 'pointer', color: 'GrayText', '&:hover': { color: theme.palette.primary.light } }}
                          onClick={() => handleRedirectToGoogleMap(singlePunchData.clock_out.address)}
                        >
                          {singlePunchData.clock_out.address}
                        </Typography>
                      }
                    />
                  </CardContent>
                </Card>
              </TimelineContent>
            </Stack>
          </>
        );
      }
      case 'breaks':
        return singlePunchData.breaks.map((eachBreakData) => (
          <Stack direction="row">
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineConnector />
              <TimelineDot className="bg-white !my-1">{<CoffeeOutlined className="text-primary" />}</TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Card>
                <CardContent
                  className="md:grid md:grid-cols-2"
                  component={Stack}
                  direction={{ xs: 'column', md: 'row' }}
                  justifyContent={{ md: 'space-between' }}
                  spacing={{ xs: '10px' }}
                >
                  <CardHeader
                    className="p-0"
                    title={
                      <Stack>
                        <Typography color={theme.palette.primary.main} variant="subtitle1" className="items-center">
                          {dayjs(eachBreakData.start_time).tz(timezone).format('LT')}
                          &nbsp;-&nbsp;
                          {eachBreakData.end_time ? dayjs(eachBreakData.end_time).tz(timezone).format('LT') : 'N/A'}
                        </Typography>
                        <Typography color={theme.palette.primary.main}>Start Break &nbsp;-&nbsp; End Break</Typography>
                      </Stack>
                    }
                    subheader={
                      <Typography
                        className="cursor-pointer"
                        sx={{ color: 'GrayText', '&:hover': { color: theme.palette.primary.light } }}
                        onClick={() => handleRedirectToGoogleMap(eachBreakData.address)}
                      >
                        {eachBreakData.address}
                      </Typography>
                    }
                  />
                  <Box>
                    {!!eachBreakData?.note?.length && (
                      <>
                        <Typography variant="subtitle1">Break Note</Typography>
                        <Typography
                          sx={{ backgroundColor: themeConfig.mode === 'dark' ? 'black' : 'lightgray' }}
                          className="shadow-sm p-2 px-3 min-w-max hover:shadow-md transition-all ease-in-out duration-150"
                        >
                          {eachBreakData?.note}
                        </Typography>
                      </>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </TimelineContent>
          </Stack>
        ));

      default:
        return <></>;
    }
  };

  return (
    <div>
      {!!isPunchDataFetched && !!punchData?.punching_details.length && (
        <Stack spacing={2}>
          <Stack alignItems="center" justifyContent={'center'} direction="row">
            <IconButton onClick={() => navigate(-1)}>
              <ArrowLeftOutlined />
            </IconButton>
            <div className="m-auto">
              <Typography alignSelf="center" className="text-center" variant="h4">
                {punchData.user.name}
              </Typography>
            </div>
          </Stack>
          <GoogleMap
            mapContainerClassName="map-container"
            center={center}
            zoom={10}
            key={googleMapsApiKey}
            mapContainerStyle={containerStyle}
          >
            <Marker
              position={{
                lat: Number(punchData?.punching_details[punchData?.punching_details.length - 1].clock_in.location.lat),
                lng: Number(punchData?.punching_details[punchData?.punching_details.length - 1].clock_in.location.long)
              }}
              icon={car_marker_true}
            />
          </GoogleMap>
          <Stack sx={{ width: '100%' }}>
            {punchData?.punching_details.map((singlePunchData, index) => {
              return (
                <Timeline position="right" className="p-0 flex flex-row items-start justify-between">
                  <div className="lg:max-w-[60%] max-w-full">
                    {Object.keys(singlePunchData).map((fieldName: string) => renderTimelineData(singlePunchData, fieldName, index))}
                  </div>
                </Timeline>
              );
            })}
          </Stack>
        </Stack>
      )}
    </div>
  );
};

export default PunchViewPage;
