import { CloseOutlined, UserAddOutlined } from '@ant-design/icons';
import { AppBar, Dialog, DialogContent, IconButton, Slide, Toolbar, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import AddCustomerForm from 'components/forms/AddCustomerForm';
import { TAddCustomerPopupWActions } from 'pages/customers/types/type.customersPage';
import { forwardRef, useEffect } from 'react';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddCutsomerPopup = (props: TAddCustomerPopupWActions) => {
  const handleSuccess = () => {
    props?.onSave();
    props?.onClose();
  };

  useEffect(() => {
    // Push a state to history to detect back navigation

    window.history.pushState(null, '', window.location.href);

    const handlePopState = () => {
      // Close the dialog instead of navigating back
      props?.onClose?.();
    };

    // Add popstate event listener
    window.addEventListener('popstate', handlePopState);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <Dialog fullScreen {...props.action} open={props.action?.open} TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <UserAddOutlined />
          <Typography sx={{ ml: 2, flex: 1 }} variant="h4" component="div">
            {props?.action?.isEditMode ? 'Edit Customer' : 'Add Customer'}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={props?.onClose} aria-label="close">
            <CloseOutlined className="text-xl" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent className="pb-32">
        <AddCustomerForm
          existingData={props?.data?.selectedData}
          isEditMode={props?.action?.isEditMode}
          onSuccess={handleSuccess}
          setCustomerData={props?.setCustomerData}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddCutsomerPopup;
