import { AlertFilled, EnvironmentFilled, OrderedListOutlined, PhoneFilled, PlusSquareOutlined, UserAddOutlined } from '@ant-design/icons';
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
  TableBody,
  TableCell,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import CircularWithPath from 'components/@extended/progress/CircularWithPath';
import ActionButtonsGroup from 'components/buttons/ActionButtonsGroup/ActionButtonsGroup';
import { TAvailableActionButtons } from 'components/buttons/ActionButtonsGroup/types.actionButtonGroup';
import JobDateColumn from 'components/columns/JobDateColumn';
import CustomTooltip from 'components/CustomTooltip';
import SearchFilter, { ISearch } from 'components/filters/SearchFilter';
import AddActionPopup from 'components/popups/AddActionPopup';
import AddCutsomerPopup from 'components/popups/AddCutsomerPopup';
import DeleteConfirmationPoup from 'components/popups/DeleteConfirmationPopup';
import UniversalDialog from 'components/popups/UniversalDialog';
import RenderStatusPreviewIcons from 'components/RenderStatusPreviewIcons';
import { rowsPerPageOptions } from 'components/tables/CustomDataTable';
import { TJobCompleteData } from 'components/tables/interfaces/jobTableInterface';
import useAuth from 'hooks/useAuth';
import useTimezone from 'hooks/useTimezone';
import { TAddCustomerPopup, TCustomerData } from 'pages/customers/types/type.customersPage';
import { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import CategoriesProductAndServicesInstance from 'services/services.categoriesProductAndServices';
import EmailSmsServicesInstance from 'services/services.emailSms';
import EstimateServicesInstance from 'services/services.estimates';
import JobServicesInstance from 'services/services.jobs';
import PunchingServicesInstance, { TClockInData } from 'services/services.punching';
import { dispatch, useSelector } from 'store';
import { setFollowUpCustomer, setFollowUpJobId } from 'store/reducers/customReducers/slice.followUps';
import { setIsTodaysJob } from 'store/reducers/customReducers/slice.settings';
import { IDeletePopup } from 'types/globalTypes/type.deleteDialog';
import {
  getAddressFromCoordinates,
  getCurrentLocation,
  getGoogleMapsDirectionsURL,
  snakeCaseToTitleCase,
  storedDataInIndexedDB
} from 'utils/common';
import { indexedDbStoreNames, jobStates } from 'utils/constants';

const CustomDataTable = lazy(() => import('components/tables/CustomDataTable'));
const JobMapView = lazy(() => import('./JobMapView'));

const JobsPage = () => {
  //--------------constants-----------------
  const theme = useTheme();
  const punching = useSelector((state) => state.punching);
  const { isTodaysJob, ...settingsState } = useSelector((state) => state.settings);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const primaryColor = `${theme.palette.primary.main}`;
  const primaryDarkColor = `${theme.palette.primary.dark}`;
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const formatDatetime = useTimezone();
  const { user } = useAuth();
  const isWorker = useMemo(() => user?.role === 'field_worker' || user?.role === 'estimator' || !!user?.is_fw_estimator, [user]);
  const navigate = useNavigate();
  const [addJobPopup, setAddJobPopup] = useState<TAddCustomerPopup>({ action: { open: false }, data: null });
  const [addActionPopup, setAddActionPopup] = useState<boolean>(false);
  const [toggleFilter, setToggleFilter] = useState<boolean | null>(null);
  const [customerData, setCustomerData] = useState<TCustomerData>();
  const [deleteDialog, setDeleteDialog] = useState<IDeletePopup>({ action: { open: false }, data: { subjectToBeDeleted: '' } });
  const [suspendJobReason, setSuspendJobReason] = useState<{ state: boolean; reason: string; jobData: any }>({
    state: false,
    reason: '',
    jobData: null
  });
  const [paginationData, setPaginationData] = useState({ page: 0, rowsPerPage: rowsPerPageOptions[0] });
  const [showMap, setShowMap] = useState(false);
  const [alertCustomerPopup, setAlertCustomerPopup] = useState<{ open: boolean; jobData?: any }>({
    open: false
  });
  const [userCoordinates, setUserCoordinates] = useState<{ lat: string | number; lng: string | number }>({ lat: '', lng: '' });
  const [loadingDirection, setLoadingDirection] = useState(false);

  const getReminderDate = (row: TJobCompleteData) => {
    if (!(row && row.state && row.created_at && row.previous_states)) {
      return 'N/A';
    }

    const isStateIncluded = [jobStates.WORK_ORDER].includes(row.state);

    const lastPreviousState = row.previous_states[row.previous_states.length - 1];
    if (isStateIncluded) {
      if (row.is_estimate_require_date && row.estimate_reminder?.scheduled_date_and_time) {
        return formatDatetime(row.estimate_reminder.scheduled_date_and_time, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',

          ...(!row.estimate_reminder.exclude_time && {
            hour: '2-digit',
            minute: '2-digit',
            hour12: settingsState.generalSettings?.time_format === '12hrs'
          })
        });
      }
      return 'N/A';
    }
    if (lastPreviousState === jobStates.ESTIMATION) {
      return <JobDateColumn customerData={row} state={jobStates.ESTIMATION} />;
    }
    if (lastPreviousState === jobStates.NEED_REVIEW) {
      return <JobDateColumn customerData={row} state={jobStates.NEED_REVIEW} />;
    }

    if (lastPreviousState === jobStates.REASSIGNED_ESTIMATOR) {
      return <JobDateColumn customerData={row} state={jobStates.REASSIGNED_ESTIMATOR} />;
    }
    if (lastPreviousState === jobStates.REASSIGNED_WORKER) {
      return <JobDateColumn jobAction={row?.job_action ?? []} state={jobStates.REASSIGNED_WORKER} customerData={row} />;
    }

    if (row.state === jobStates.WORKER_ASSIGNED) {
      return <JobDateColumn jobAction={row?.job_action ?? []} customerData={row} />;
    }

    if (
      row.previous_states.includes(jobStates.JOB_COMPLETED) &&
      !row.previous_states.some((each) => each === jobStates.REASSIGNED_ESTIMATOR || each === jobStates.REASSIGNED_WORKER)
    ) {
      const data = row.job_action?.[row.job_action.length - 1]?.job_reminder;

      if (data?.scheduled_date_and_time) {
        return (
          <Typography color={'GrayText'}>
            Job Completed. Scheduled for{' '}
            {formatDatetime(data?.scheduled_date_and_time as string, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',

              ...(!data.exclude_time && {
                hour: '2-digit',
                minute: '2-digit',
                hour12: settingsState.generalSettings?.time_format === '12hrs'
              })
            })}
          </Typography>
        );
      }
    }
    return 'N/A';
  };

  const columns = useMemo<ColumnDef<TJobCompleteData & { job_followups: number }>[]>(
    () => [
      {
        id: 'alert',
        cell: ({ row: { original: row } }) => {
          const currentJobIndex =
            row?.job_action?.findIndex((jobAction) => jobAction?.field_worker?.id === user?._id && jobAction.is_job_complete !== true) ??
            -1;

          return !!row?.job_action?.[currentJobIndex]?.customer_want_call_first_for_worker ||
            !!row.customer_want_call_first_for_estimator ? (
            <a
              href={`tel:+${row.customer?.phones[0].phone_country_code}${row.customer?.phones[0].phone}${
                row.customer?.phones[0].phone_extension ? `,${row.customer?.phones[0].phone_extension}` : ''
              }`}
            >
              <PhoneFilled />{' '}
            </a>
          ) : (
            <>-</>
          );
        },
        header: () => <span>Alert</span>
      },
      {
        accessorFn: (row) => row?.job_name?.toLowerCase(),
        id: 'job_name',
        cell: ({
          row: {
            original: { _id, job_name, job_followups }
          }
        }) => (
          <div className="flex w-full items-center gap-x-2">
            {job_followups > 0 && (
              <CustomTooltip message={'Total Follow Ups'}>
                <IconButton
                  size="small"
                  sx={{
                    backgroundColor: `${theme.palette.error.main} !important`,
                    fontSize: '12px',
                    padding: '1px'
                  }}
                  className=" text-white rounded-2xl w-5 h-5"
                  onClick={(event) => {
                    event.stopPropagation();
                    navigate(`/jobs/view/${_id}/follow_ups`);
                  }}
                >
                  {job_followups}
                </IconButton>
              </CustomTooltip>
            )}
            <Typography
              sx={{
                color: primaryColor,
                '&:hover': {
                  color: primaryDarkColor
                }
              }}
              className={`cursor-pointer text-[0.95rem]`}
              onClick={() => navigate(`view/${_id}/job_status`)}
            >
              {job_name}
            </Typography>
          </div>
        ),
        header: () => <span>Job Name</span>
      },
      {
        accessorFn: (row) => (row?.customer?.name ? row?.customer?.name.toLowerCase() : ''),
        id: 'customer_name',
        cell: ({ row }) => (
          <Typography
            sx={{
              '&:hover': {
                ...(user?.permissions?.includes('getCustomers') && { color: primaryColor })
              }
            }}
            className={user?.permissions?.includes('getCustomers') ? `cursor-pointer` : ''}
            onClick={() => {
              if (user?.permissions?.includes('getCustomers')) navigate(`/customers/view/${row?.original?.customer?.id ?? ''}/contact`);
            }}
          >
            {row?.original?.customer?.name ?? ''}
          </Typography>
        ),
        header: () => <span>Customer Name</span>
      },
      {
        accessorFn: (row) => row.address?.address1,
        id: 'address',
        cell: ({ row }) => (
          <CustomTooltip props={{ placement: 'right' }} message="Get Directions">
            <Typography
              onClick={() => {
                toggleConfirmationAlert(true, row.original);
              }}
              className="min-w-min w-[200px] max-w-[200px] text-ellipsis"
              sx={{ '&:hover': { color: theme.palette.primary.light }, cursor: 'pointer' }}
            >
              {row.original?.address?.address1}
            </Typography>
          </CustomTooltip>
        ),
        header: () => <span>Address</span>
      },
      {
        accessorFn: (row) => row.address?.city,
        id: 'city',
        cell: ({ row }) => <Typography>{row.original.address?.city}</Typography>,
        header: () => <span>City</span>
      },
      {
        accessorFn: (row) => row.estimate_reminder?.scheduled_date_and_time,
        id: 'schedule_date',
        cell: ({ row }) => getReminderDate(row.original),
        header: () => <span>Job Date</span>
      },
      {
        id: 'status',
        cell: ({ row }) => {
          // const excludeTime = row.original.job_action?.[0].job_reminder?.exclude_time;
          // const formatDateOnly = (date: Date) => new Date(date).toLocaleDateString();
          // const getFormattedDate = (date: Date) => (excludeTime ? formatDateOnly(date) : formatDatetime(date));

          const previousStateLength = row.original.previous_states?.length;
          if (previousStateLength) {
            if (
              row.original.previous_states?.[previousStateLength - 1] === jobStates.REASSIGNED_ESTIMATOR ||
              row.original.previous_states?.[previousStateLength - 1] === jobStates.REASSIGNED_WORKER ||
              row.original.previous_states?.[previousStateLength - 1] === jobStates.ESTIMATION_COMPLETED
            ) {
              return `${snakeCaseToTitleCase(row.original.previous_states?.[previousStateLength - 1])}-${
                row.original.updated_at
                  ? formatDatetime(row.original.updated_at, { hour12: settingsState.generalSettings?.time_format === '12hrs' })
                  : row.original.created_at
                  ? formatDatetime(row.original.created_at, { hour12: settingsState.generalSettings?.time_format === '12hrs' })
                  : ''
              }`;
            }
          }
          return `${snakeCaseToTitleCase(row.original.state as string)}-${
            row.original.updated_at
              ? formatDatetime(row.original.updated_at, { hour12: settingsState.generalSettings?.time_format === '12hrs' })
              : row.original.created_at
              ? formatDatetime(row.original.created_at, { hour12: settingsState.generalSettings?.time_format === '12hrs' })
              : ''
          }`;
        },
        header: () => <span>Status</span>
      },
      {
        id: 'status_preview',
        header: () => (
          <div className="flex w-40">
            <div className="ml-3 w-full flex justify-start space-x-9">
              <span>E</span>
              <span>P</span>
              <span>W</span>
              <span>I</span>
            </div>
          </div>
        ),
        cell: ({ row }) => {
          return (
            <div className="flex flex-col items-center p-0">
              <div className="w-full flex justify-start space-x-3">
                <RenderStatusPreviewIcons
                  jobAction={row.original.job_action ?? []}
                  jobState={row.original?.state as string}
                  status={row.original.status!}
                  previousStates={row.original.previous_states}
                  jobId={row.original._id}
                  proposalSentDate={row.original.last_proposal_sent}
                  invoiceSentDate={row.original.last_invoice_sent?.toString()}
                />
              </div>
            </div>
          );
        }
      },
      {
        id: 'actions',
        header: () => <span>Actions</span>,
        cell: ({ row }) => {
          if (getStatusValue() === 0) {
            return <></>;
          }
          const actionButtons: TAvailableActionButtons[] = [];

          if (user?.permissions?.includes('createJob')) {
            row.original.status === 1 && actionButtons.push('suspend');
            row.original.status === 3 && actionButtons.push('unsuspend');
          }

          if (row.original.status !== 0) {
            if (row.original.state === jobStates.ESTIMATION || row.original.state === jobStates.NEED_REVIEW) {
              user?.permissions?.includes('deleteEstimate') && actionButtons.push('delete');
            } else if (user?.permissions?.includes('deleteJob')) {
              actionButtons.push('delete');
            }
          }

          return (
            <span className="flex items-center space-x-2">
              <CustomTooltip message="Add FollowUp">
                <IconButton size="large" color="primary" onClick={() => handleActions('followup', row.original)}>
                  <PlusSquareOutlined />
                </IconButton>
              </CustomTooltip>

              {!!actionButtons.length && (
                <ActionButtonsGroup handleActions={(action) => handleActions(action, row.original)} buttons={actionButtons} />
              )}
            </span>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [settingsState.generalSettings?.time_format]
  );

  const defaultSearch = {
    search: [
      [
        {
          field_name: 'status',
          field_value: 1,
          operator: 'exactmatch'
        }
      ]
    ]
  };

  const [searchData, setSearchData] = useState<ISearch>(defaultSearch);

  //--------------useQuery-----------------

  const {
    data: allJobsData,
    isLoading: isJobFetching,
    isFetching,
    refetch: refetchJobs
  } = useQuery({
    queryKey: [isTodaysJob, paginationData, searchData, showMap, location.search],
    queryFn: () => {
      const baseUrl = location.search?.split('?')[1]?.split('&') ?? '';
      const filterType = urlParams.get('type') ?? 'job_calendar';
      const date = urlParams.get('date') ?? '';
      const userId = urlParams.get('user_id') ?? '';

      let queryPayload;

      if (!!filterType) {
        switch (filterType) {
          case 'job_calendar':
          case 'estimate_calendar':
          case 'archive_calendar':
            queryPayload = { calendar_type: filterType, date, user_id: userId };
            break;
          case 'town_calendar':
          case 'area_calendar':
            {
              const date = baseUrl[1]?.split('=')[1] ?? undefined,
                workCity = baseUrl[2]?.split('=')[1] ?? undefined;

              queryPayload = { calendar_type: filterType, date, work_city: workCity };
            }
            break;

          default:
            const filter_field = baseUrl[1]?.split('=')[1] ?? undefined;

            queryPayload = { type: filterType, field: filter_field };
            break;
        }
      }

      return JobServicesInstance.getAllJobs(paginationData, searchData, queryPayload, isTodaysJob);
    },
    enabled: user?.permissions && user?.permissions.includes('getJobs'),
    networkMode: 'always',
    refetchOnWindowFocus: true
  });
  //--------------Handlers-----------------

  const handleAddJob = (openState: boolean) => {
    setAddJobPopup({ action: { open: openState } });
  };

  const handleChangePagination = (page: number, rowsPerPage: number) => {
    setPaginationData({ page, rowsPerPage });
  };

  const handleJobStatusFilterChange = (event: any) => {
    setToggleFilter((prev) => (prev !== null ? !prev : false));
    dispatch(setIsTodaysJob(false));
    setSearchData((prevSearchData: ISearch) => {
      return {
        search: prevSearchData.search.map((singleArrayData) => {
          return singleArrayData.map((singleFilter) => {
            if (singleFilter.field_name === 'status') {
              return { ...singleFilter, field_value: Number(event.target.value) };
            }
            return singleFilter;
          });
        })
      };
    });
  };

  const handleSearchChange = (values: { search_text: string; status?: number }) => {
    if (!values.search_text.length) {
      setSearchData(defaultSearch);
      return;
    }

    const dummySearchData = [...searchData.search];
    const nameFilterIndex = dummySearchData.findIndex((singleArrayData) =>
      singleArrayData.some((singleFilter) => singleFilter.field_name === 'job_name')
    );
    const searchTextFilter = [
      {
        field_name: 'job_name',
        field_value: values['search_text'] as string,
        operator: 'contains'
      },
      {
        field_name: 'customer.name',
        field_value: values['search_text'] as string,
        operator: 'contains'
      },
      {
        field_name: 'customer.phones[0].phone_country_code',
        field_value: values['search_text'] as string,
        operator: 'in'
      },
      {
        field_name: 'customer.phones[0].phone',
        field_value: values['search_text'] as string,
        operator: 'in'
      },
      {
        field_name: 'customer.emails',
        field_value: values['search_text'] as string,
        operator: 'in'
      },
      {
        field_name: 'address.address1',
        field_value: values['search_text'] as string,
        operator: 'contains'
      },
      {
        field_name: 'address.address2',
        field_value: values['search_text'] as string,
        operator: 'contains'
      },
      {
        field_name: 'address.city',
        field_value: values['search_text'] as string,
        operator: 'contains'
      },
      {
        field_name: 'address.zip',
        field_value: values['search_text'] as string,
        operator: 'contains'
      },
      {
        field_name: 'address.country',
        field_value: values['search_text'] as string,
        operator: 'contains'
      }
    ];

    if (nameFilterIndex !== -1) {
      dummySearchData[nameFilterIndex] = searchTextFilter;
    } else {
      dummySearchData.push(searchTextFilter);
    }
    setSearchData({ search: dummySearchData });
  };

  const getStatusValue = () => {
    const statusFilter = searchData.search.flat().find((filter) => filter.field_name === 'status' && filter.field_value);

    if (!statusFilter) {
      return 1;
    }
    return statusFilter.field_value;
  };

  const deleteJob = async (jobId: string, reason: string) => {
    const deleteResponse = await JobServicesInstance.deleteJob(jobId, reason);
    if (deleteResponse) {
      handleDeleteJobAction('close');
      refetchJobs();
    }
  };

  const handleActions = (actionType: string, rowOriginal: TJobCompleteData) => {
    switch (actionType) {
      case 'delete':
        handleDeleteJobAction('open', rowOriginal);
        break;
      case 'followup':
        handleJobsFollowup(rowOriginal);
        break;
      case 'suspend':
        handleSuspendJobReasonDialog(rowOriginal, true);
        break;
      case 'unsuspend':
        handleUnsuspendJob(rowOriginal._id as string);
        break;
      default:
        break;
    }
  };

  const handleDeleteJobAction = (action: string, rowOriginal?: TJobCompleteData) => {
    switch (action) {
      case 'open':
        setDeleteDialog({
          action: { open: true, maxWidth: 'xs', reasonRequired: true },
          data: { subjectToBeDeleted: rowOriginal?.job_name, selectedData: rowOriginal }
        });
        break;
      case 'close':
        setDeleteDialog({ action: { open: false, maxWidth: 'xs' } });
        break;
      default:
        break;
    }
  };

  const handleJobsFollowup = (rowOriginal: TJobCompleteData) => {
    dispatch(setFollowUpJobId(rowOriginal._id));
    dispatch(setFollowUpCustomer({ id: rowOriginal?.customer?.id ?? '', name: rowOriginal?.customer?.name ?? '' }));
    navigate(`/jobs/view/${rowOriginal._id}/follow_ups?action=add_followup`);
  };

  const handleSuspendJob = async () => {
    await JobServicesInstance.suspendJob(suspendJobReason.jobData._id, suspendJobReason.reason);
    handleSuspendJobReasonDialog(suspendJobReason.jobData._id, false);
    refetchJobs();
  };

  const handleSuspendJobReasonDialog = (jobData: any, state: boolean) => {
    setSuspendJobReason({ jobData, reason: '', state });
  };

  const handleUnsuspendJob = async (jobId: string) => {
    await JobServicesInstance.unsuspendJob(jobId);
    refetchJobs();
  };

  const handleDeleteSelectedJob = (
    selectedJobToBeDeleted: string,
    selectedJobToBeDeletedFirstName: string | null,
    deleteReason?: string
  ) => {
    deleteReason && deleteJob(selectedJobToBeDeleted, deleteReason);
  };

  const handleSuspendJobReasonChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSuspendJobReason((prev) => ({ ...prev, reason: event.target.value }));
  };

  //------------------useEffects------------------
  useEffect(() => {
    if (user?.role === 'company_admin' || user?.role === 'office_worker' || !navigator.onLine) return;
    const storedSingleJobData = async () => {
      if (!allJobsData?.jobs?.length) return;

      caches.open('version-2').then((cache) => {
        const jobdIDs = allJobsData.jobs.map((job) => `jobs/view/${job._id}`);
        return cache.addAll(jobdIDs).then(() => {});
      });

      const promises = allJobsData.jobs.map((job) => JobServicesInstance.getSingleJobs(job._id));
      const response = await Promise.all(promises);
      await storedDataInIndexedDB(indexedDbStoreNames.singleJobs, response);
    };
    const storeTemplate = async () => {
      const response = await EstimateServicesInstance.getProposalTemplate();
      await storedDataInIndexedDB(indexedDbStoreNames.proposalTemplate, response);
    };
    const storeJobCategories = async () => {
      const jobCategories = await CategoriesProductAndServicesInstance.getJobCategories();
      await storedDataInIndexedDB(indexedDbStoreNames.jobCategories, jobCategories);

      if (!jobCategories?.length) return;
      const promises = jobCategories.map((cat) =>
        CategoriesProductAndServicesInstance.getServiceAndProductByJobCategory(cat._id as string)
      );
      const response = await Promise.all(promises);
      const detailJobCategories = response.map((res, i) => ({ id: jobCategories[i]._id, data: res }));
      await storedDataInIndexedDB(indexedDbStoreNames.singleJobCategores, detailJobCategories);
    };
    storeTemplate();
    storedSingleJobData();
    if (user?.permissions?.some((eachPermission) => ['getJobCategories', 'getJobs'].includes(eachPermission))) storeJobCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allJobsData]);

  useEffect(() => {
    return () => {
      dispatch(setIsTodaysJob(false));
    };
  }, []);

  useEffect(() => {
    const navTimeout = setTimeout(function () {
      if (navigator.geolocation) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function (position) {
            setUserCoordinates({ lat: position.coords.latitude, lng: position.coords.longitude });
          });
        }
      } else {
        console.log('No Geolocation Support.');
      }
    }, 1000);

    return () => {
      clearTimeout(navTimeout);
    };
  }, []);

  function toggleConfirmationAlert(state: boolean, data?: any) {
    setAlertCustomerPopup({ open: state, jobData: data });
  }

  async function handleGetDirection(jobData: any) {
    const address = `${jobData?.address?.address1} ${jobData?.address?.address2}, ${jobData?.address?.city}, ${jobData?.address?.state} ${jobData?.address?.zip}, ${jobData?.address?.country}`;
    if (!!userCoordinates.lat && !!userCoordinates.lng) {
      const directionUrl = getGoogleMapsDirectionsURL(userCoordinates.lat, userCoordinates.lng, address);
      window.open(directionUrl, '_blank')?.focus();
    }
  }

  async function handleAlertConfirmation(alertUser: boolean, jobData?: any) {
    setLoadingDirection(true);

    if (alertUser) {
      const customerPhone =
        jobData.customer.phones?.length === 0
          ? []
          : jobData.customer.phones.map(
              (phone: { phone_country_code: string; phone: string }) => `${phone?.phone_country_code}${phone?.phone}`
            );
      const userProfile = user?.profile_picture ?? '';
      const smsData = {
        customer: {
          id: jobData.customer?.id,
          name: jobData.customer?.name ?? ''
        },
        to: customerPhone,
        message: `Your ${user?.company_name} Tech, ${user?.first_name}, is on the way and should arrive shortly.`,
        attachments: [userProfile as string],
        schedule_sms: false
      };
      await EmailSmsServicesInstance.sendSms(smsData);
    }

    if (user?.rate_info?.pay_type === 'hourly') {
      let location, address;
      if (navigator.onLine) {
        location = await getCurrentLocation();
        address = await getAddressFromCoordinates(Number(location.lat), Number(location.long));
      } else {
        location = { lat: '', long: '' };
        address = '';
      }

      const jobPunchData = {
        id: jobData?._id as string,
        name: jobData?.job_name as string,
        time: new Date().toISOString(),
        location,
        address
      };

      const todaysPunch = punching.punchingDetails?.todaysPunch;

      if (!todaysPunch) {
        const clockInData: TClockInData = {
          date: new Date().toISOString(),
          clock_in: {
            time: new Date().toISOString(),
            location: location,
            address: address
          },
          is_specified_time: false
        };
        const response = await PunchingServicesInstance.clockIn(clockInData);
        if (response && response.success) {
          if (response.punch_id) {
            await PunchingServicesInstance.punchJobAction(response.punch_id, 'start', jobPunchData);
            // ----- get direction
            handleGetDirection(jobData);
            window.location.reload();
          }
        }
      } else {
        await PunchingServicesInstance.punchJobAction(todaysPunch._id, 'start', jobPunchData);
        handleGetDirection(jobData);
      }
    }

    setLoadingDirection(false);
    toggleConfirmationAlert(false);
  }

  const isPendingPayment: boolean = useMemo(() => {
    if (!suspendJobReason.jobData?.payments || suspendJobReason.jobData?.payments?.length < 0) {
      return false;
    }
    const total = suspendJobReason.jobData?.payments.reduce((total: number, payment: any) => total + payment.amount, 0);
    return total > 0;
  }, [suspendJobReason.jobData]);

  return (
    <>
      <Paper elevation={1} className="flex flex-col items-start space-y-4 min-h-[75dvh] pt-4">
        <div className="flex flex-col items-end w-full gap-3">
          {user?.permissions && user?.permissions.includes('createCustomer') && (
            <div className="absolute top-6 sm:right-6 right-4 flex justify-end items-end sm:w-max w-full gap-2">
              <Button
                className="py-2 sm:min-w-max min-w-max font-medium sm:text-lg text-sm xs:text-xs"
                variant="contained"
                size={isMobile ? 'small' : 'large'}
                startIcon={showMap ? <OrderedListOutlined /> : <EnvironmentFilled />}
                color="info"
                onClick={() => setShowMap((prev) => !prev)}
              >
                <span>{showMap ? 'List' : 'Map'} View</span>
              </Button>
              <Button
                className="py-2 sm:min-w-max min-w-max font-medium sm:text-lg text-sm xs:text-xs"
                variant="contained"
                size={isMobile ? 'small' : 'large'}
                startIcon={<UserAddOutlined />}
                color="primary"
                onClick={() => handleAddJob(true)}
              >
                <span>Add Customer</span>
              </Button>
            </div>
          )}
          <div className="w-full flex lg:flex-row flex-col-reverse items-end gap-x-8 gap-y-4 px-4">
            <SearchFilter placeholder="Search for Job name / Customer name / Address/  Phone / Email" onChange={handleSearchChange} />
            <div className="flex items-center sm:w-max w-full justify-between">
              {(isWorker || user?.role === 'company_admin') && (
                <FormControlLabel
                  className="min-w-max"
                  value={isTodaysJob}
                  name="is_today_job"
                  id="is_today_job"
                  color="primary"
                  checked={isTodaysJob}
                  control={
                    <Checkbox
                      color="primary"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch(setIsTodaysJob(event.target.checked))}
                    />
                  }
                  label="Today's Job"
                />
              )}
              {user?.role === 'company_admin' && (
                <Select className="min-w-max sm:w-[180px] w-full" value={getStatusValue()} onChange={handleJobStatusFilterChange}>
                  <MenuItem value={1}>Open Jobs</MenuItem>
                  <MenuItem value={2}>Completed Jobs</MenuItem>
                  <MenuItem value={3}>Suspended Jobs</MenuItem>
                  <MenuItem value={0}>Deleted Jobs</MenuItem>
                </Select>
              )}
            </div>
          </div>
        </div>

        {!showMap ? (
          <CustomDataTable
            hasPagination
            onPaginationChange={handleChangePagination}
            data={allJobsData?.jobs || []}
            columns={columns}
            isDataLoading={isJobFetching || isFetching}
            count={allJobsData?.count}
            toggleFilter={toggleFilter}
          />
        ) : (
          <Suspense
            fallback={
              <TableBody className="w-full min-h-max">
                <TableCell colSpan={12}>
                  <Stack>
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                    <Skeleton height={90} />
                  </Stack>
                </TableCell>
              </TableBody>
            }
          >
            (
            <JobMapView isJobFetching={isJobFetching} jobs={allJobsData?.jobs || []} />
          </Suspense>
        )}
      </Paper>

      {/* ------------------ Add Customer --------------- */}
      {addJobPopup.action.open && (
        <AddCutsomerPopup
          {...addJobPopup}
          onClose={() => {
            handleAddJob(false);
          }}
          onSave={() => setAddActionPopup(true)}
          setCustomerData={setCustomerData}
        />
      )}

      {/* ------------------ Add Action --------------- */}
      {addActionPopup && customerData && (
        <AddActionPopup customerData={customerData} addActionPopup={addActionPopup} onClose={() => setAddActionPopup(false)} />
      )}

      {/* ------------------ Delete Job --------------- */}
      {deleteDialog.action.open && (
        <DeleteConfirmationPoup {...deleteDialog} onClose={() => handleDeleteJobAction('close')} onDelete={handleDeleteSelectedJob} />
      )}

      {/* ------------------ Suspend Job --------------- */}
      {suspendJobReason.state && (
        <UniversalDialog
          title="Job Suspend Reason"
          action={{ open: suspendJobReason.state, maxWidth: 'xs', fullWidth: true }}
          onClose={() => setSuspendJobReason((prev) => ({ ...prev, state: false, reason: '' }))}
          onSave={() => handleSuspendJob()}
          primaryButonTitle="Submit"
          disablePrimaryButton={!suspendJobReason.reason?.length}
        >
          <div className="flex flex-col items-start justify-center space-y-2">
            {suspendJobReason?.jobData?.state !== jobStates.INVOICE_UNPAID && isPendingPayment && (
              <Alert title="Warning" severity="warning" className="mb-3">
                <AlertTitle variant="subtitle1" color={'warning.dark'}>
                  This job has a pending payment. Consider if reimbursement is needed.
                </AlertTitle>
              </Alert>
            )}
            {suspendJobReason?.jobData?.state === jobStates.INVOICE_UNPAID && (
              <Alert title="Warning" severity="warning" className="mb-3">
                <AlertTitle variant="subtitle1" color={'warning.dark'}>
                  You are about to delete a job with an outstanding balance.
                </AlertTitle>
              </Alert>
            )}
            <Typography variant="body1" className="ml-2">
              Reason For Suspend The Job*
            </Typography>
            <TextField fullWidth multiline minRows={3} onChange={handleSuspendJobReasonChange} />
          </div>
        </UniversalDialog>
      )}

      {alertCustomerPopup.open && (
        <UniversalDialog
          action={{ open: alertCustomerPopup.open, fullWidth: true, maxWidth: 'xs' }}
          onClose={() => toggleConfirmationAlert(false)}
          title={
            <Typography variant="h4">
              <AlertFilled /> Alert Customer
            </Typography>
          }
          hasSecondaryButton
          primaryButonTitle="Yes"
          secondaryButonTitle="No"
          onSave={() => handleAlertConfirmation(true, alertCustomerPopup.jobData)}
          handleSecondaryClick={() => handleAlertConfirmation(false, alertCustomerPopup.jobData)}
          disablePrimaryButton={loadingDirection}
          isPrimaryButtonLoading={loadingDirection}
          disableSecondaryButton={loadingDirection}
        >
          {loadingDirection ? (
            <Typography className="text-center">
              <CircularWithPath size={'20px'} /> Please wait...
            </Typography>
          ) : (
            <Typography>Would you like to alert customer, you are on the way ?</Typography>
          )}
        </UniversalDialog>
      )}
    </>
  );
};

export default JobsPage;
