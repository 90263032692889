import { InboxOutlined } from '@ant-design/icons';
import { Card, CardContent, Skeleton, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import useConfig from 'hooks/useConfig';
import ReactApexChart from 'react-apexcharts';
import { Link } from 'react-router-dom';
import DashboardServicesInstance from 'services/services.dashboard';
import { formateData } from 'utils/common';

const OpenInvoice = () => {
  const themeConfig = useConfig();
  //--------------------------useQuery---------------
  const { data: openInvoiceData } = useQuery({ queryKey: ['invoice'], queryFn: () => DashboardServicesInstance.getOpenInvoiceData() });
  return (
    <Card className="w-full shadow-md max-h-min h-full">
      {!!openInvoiceData ? (
        <CardContent className="flex flex-col items-center max-h-min w-full gap-4">
          <div className="flex flex-col items-center gap-4 w-full">
            <Typography textAlign={'center'} className="font-semibold text-2xl text-gray-500 hover:underline">
              <Link to="/invoices">Total Open Invoices</Link>
            </Typography>
            <Typography color={'red'} className="font-semibold text-3xl hover:underline">
              <Link to="/reports/gross_income_report">
                {formateData(openInvoiceData[0].data + openInvoiceData[1].data + openInvoiceData[2].data, '$')}
              </Link>
            </Typography>
          </div>
          <Typography className="text-xs text-center">
            THE ABOVE # INCLUDES <strong>{formateData(openInvoiceData.sales_tax ?? 0, '$')}</strong> OF UNCOLLECTED SALES TAX
          </Typography>
          {!(openInvoiceData?.[0].data === 0 && openInvoiceData?.[1].data === 0 && openInvoiceData?.[2].data === 0) ? (
            <div className="flex flex-row justify-center">
              {/* //todo: when api is updated apply check for data. */}

              <div className="w-fit ">
                <ReactApexChart
                  type="donut"
                  series={[
                    Number(openInvoiceData['0'].data.toFixed(2)),
                    Number(openInvoiceData['1'].data.toFixed(2)),
                    Number(openInvoiceData['2'].data.toFixed(2))
                  ]}
                  options={{
                    theme: {
                      mode: themeConfig.mode,
                      palette: 'palette1'
                    },
                    labels: [openInvoiceData['0'].title, openInvoiceData['1'].title, openInvoiceData['2'].title],
                    legend: { position: 'bottom' },
                    colors: ['#dc3545', '#007bff', '#28a745']
                  }}
                />
              </div>
            </div>
          ) : (
            <CardContent className="w-full">
              <Stack className="mt-4 flex flex-col items-center gap-2">
                <InboxOutlined style={{ height: 20, transform: 'scale(3)', color: 'GrayText' }} />
                <Typography color={'GrayText'}>No Data</Typography>
              </Stack>
            </CardContent>
          )}
        </CardContent>
      ) : (
        <CardContent className="w-full h-full" sx={{ minHeight: { sm: '300px', lg: '350px' } }}>
          <Skeleton variant="text" width={150} height={30} />
          <Skeleton variant="text" height={30} className="w-full" />
          <Skeleton variant="rectangular" className="w-full h-full" />
        </CardContent>
      )}
    </Card>
  );
};

export default OpenInvoice;
