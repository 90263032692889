import { TCompanyData, TResponseCompany, TResponseSettings, TSettingData } from 'pages/Settings/types/types.generalSettings';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import axiosServices from 'utils/axios';
import { indexedDbStoreNames } from 'utils/constants';
import IndexedDBServices from './services.localDb';
import { TIndexedDBResponse } from './types/types.services';

class GeneralSettings {
  getCompanyData = async (company_id?: string) => {
    try {
      const response: TResponseCompany = await axiosServices.get(`api/company/${company_id}`);
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (err) {
      console.log('getting error');
    }
  };
  updateCompanyData = async (values: TCompanyData, company_id?: string) => {
    try {
      const response: TResponseCompany = await axiosServices.put(`api/company/${company_id}`, values);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Company Data was successfully updated`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
      }
    } catch (error: unknown) {
      console.log('error', error);
      const knownError = error as { success: boolean; message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: `${knownError.message}`,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
    }
  };

  getSettingsData = async () => {
    try {
      if (!navigator.onLine) {
        const indexedDBInstance = new IndexedDBServices(indexedDbStoreNames.settings);
        const data = (await indexedDBInstance.getAllLocalData()) as TIndexedDBResponse;
        return data.data as TSettingData;
      }
      const response: TResponseSettings = await axiosServices.get(`api/setting`);
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (err) {
      console.log('failed to get settings');
    }
  };
  updateSettingsData = async (values: TSettingData) => {
    try {
      const response: TResponseSettings = await axiosServices.put(`api/setting`, values);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Settings was successfully updated`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      console.log('error', error);
      const knownError = error as { success: boolean; message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: `${knownError.message}`,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
    }
  };
  getTwilioNumber = async (companyId?: string) => {
    try {
      const response: { data: { success: boolean; data: string } } = await axiosServices.post(`api/twilio/get-phone-number`, {
        friendly_name: companyId
      });
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (err) {
      console.log('getting error');
    }
  };
  removeTwilioNumber = async () => {
    try {
      const response: { data: { success: boolean } } = await axiosServices.post(`api/twilio/remove-sub-account`);
      if (response && response.data.success) {
        return response.data.success;
      }
    } catch (err) {
      dispatch(
        openSnackbar({
          open: true,
          message: `Problem while deleting phone number`,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
    }
  };
}
const generalSettingsInstance = new GeneralSettings();
export default generalSettingsInstance;
