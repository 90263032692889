import { CoffeeOutlined, EyeOutlined, FieldTimeOutlined, FormOutlined } from '@ant-design/icons';
import {
  Autocomplete,
  Box,
  Button,
  DialogProps,
  Divider,
  Grid,
  Hidden,
  Icon,
  InputLabel,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import Loader from 'components/Loader';
import ClockSvg from 'components/logo/ClockSvg';
import AddBreakPopup from 'components/popups/AddBreakPopup';
import AddNotePopup from 'components/popups/AddNotePopup';
import SpecifyPunchTimePopup from 'components/popups/SpecifyPunchTimePopup';
import useAuth from 'hooks/useAuth';
import useConfig from 'hooks/useConfig';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import JobServicesInstance from 'services/services.jobs';
import PunchingServicesInstance, { TClockInData } from 'services/services.punching';
import { dispatch, useSelector } from 'store';
import { setIsTodaysJob } from 'store/reducers/customReducers/slice.settings';
import { clockIn, clockOut, getPunchDetails } from 'store/reducers/punching';
import { getAddressFromCoordinates, getCurrentLocation } from 'utils/common';
import { jobStates } from 'utils/constants';

type TPopupActionProps = { open: boolean; fullWidth: boolean; maxWidth: DialogProps['maxWidth'] };

const ClockInOutPage = () => {
  const theme = useTheme();
  const punching = useSelector((state) => state.punching);
  const navigate = useNavigate();
  const loggedInUser = useAuth();
  const themeConfig = useConfig();

  const { data: assignedJobs, isLoading: isJobDataFetching } = useQuery({
    queryKey: ['fetchAvaliableJobs', loggedInUser.user?._id],
    queryFn: () =>
      JobServicesInstance.getAllJobs(undefined, {
        search: [
          [
            {
              field_name: 'status',
              field_value: 1,
              operator: 'exactmatch'
            }
          ],
          [
            {
              field_name: 'previous_states',
              field_value: [jobStates.JOB_COMPLETED, jobStates.INVOICE_PAID, jobStates.INVOICE_SENT],
              operator: 'not_in'
            },
            {
              field_name: 'previous_states',
              field_value: [jobStates.REASSIGNED_WORKER],
              operator: 'in'
            }
          ]
        ]
      }),
    enabled: !!loggedInUser.user?._id
  });

  const [breaks, setBreaks] = useState<
    {
      start_time: string;
      location: number;
      end_time: string;
    }[]
  >([]);

  const [selectedJob, setSelectedJob] = useState<{ id: string; name: string }>({ id: '', name: '' });
  const [addNotePopup, setAddNotePopup] = useState<TPopupActionProps>({
    open: false,
    fullWidth: true,
    maxWidth: 'xs'
  });
  const [specifyTimePopup, setSpecifyTimePopup] = useState<TPopupActionProps>({
    open: false,
    fullWidth: true,
    maxWidth: 'sm'
  });
  const [addBreakPopup, setAddBreakPopup] = useState<TPopupActionProps>({
    open: false,
    fullWidth: true,
    maxWidth: 'sm'
  });
  const [loading, setLoading] = useState(false);
  const [currentPuchedInJob, setCurrentPuchedInJob] = useState<{ id: string; name: string; start?: any; end?: any }>({
    id: '',
    name: ''
  });

  useEffect(() => {
    if (!punching) return;

    const currentJob = punching.punchingDetails?.todaysPunch;

    if (currentJob?.jobs[currentJob?.jobs.length - 1] && !currentJob?.jobs[currentJob?.jobs.length - 1].end) {
      setCurrentPuchedInJob(currentJob?.jobs[currentJob?.jobs.length - 1]);
      setSelectedJob(currentJob?.jobs[currentJob?.jobs.length - 1]);
    } else {
      setSelectedJob({ id: '', name: '' });
      setCurrentPuchedInJob({ id: '', name: '' });
    }

    const breaksList = punching.punchingDetails?.todaysPunch?.punching_details.find((punch) => !punch.clock_out)?.breaks;
    if (!breaksList) return;
    setBreaks(breaksList);
  }, [punching]);

  // --------handlers---------
  const handleAddNoteClick = (state: boolean, data?: string) => {
    setAddNotePopup((prev) => ({ ...prev, open: state }));
  };
  const handleSpecifyTime = (state: boolean, data?: string) => {
    setSpecifyTimePopup((prev) => ({ ...prev, open: state }));
  };
  const handleAddBreak = (state: boolean, data?: string) => {
    setAddBreakPopup((prev) => ({ ...prev, open: state }));
  };

  const handlePunchClick = async (date: string, time: string, is_specified_time: boolean) => {
    setLoading(true);
    let location, address;
    if (navigator.onLine) {
      location = await getCurrentLocation();
      address = await getAddressFromCoordinates(Number(location.lat), Number(location.long));
    } else {
      location = { lat: '', long: '' };
      address = '';
    }
    const punchData: TClockInData = {
      date,
      clock_in: {
        time,
        location: location,
        address: address
      },
      is_specified_time
    };

    if (!punching.isClockedIn) {
      const response = await PunchingServicesInstance.clockIn(punchData);
      if (response && response.success) {
        if (selectedJob?.id && response.punch_id) {
          const jobPunchData = {
            id: selectedJob.id,
            name: selectedJob.name,
            time,
            location,
            address
          };
          await PunchingServicesInstance.punchJobAction(response.punch_id, 'start', jobPunchData);
        }
      }
      dispatch(clockIn(new Date().toISOString()));
      navigate('/jobs');
      if (loggedInUser.user?.role === 'field_worker' || loggedInUser.user?.role === 'estimator' || !!loggedInUser.user?.is_fw_estimator)
        dispatch(setIsTodaysJob(true));
    } else {
      if (punching.punchingDetails?.todaysPunch) {
        const punchingDate = new Date(punching.punchingDetails?.todaysPunch?.date);
        const updatedTime = new Date(time).setFullYear(punchingDate.getFullYear(), punchingDate.getMonth(), punchingDate.getDate());
        const clockOutData = {
          clock_out: { ...punchData.clock_in, time: new Date(updatedTime).toISOString() },
          is_specified_time,
          is_auto_logout: false
        };

        const response = await PunchingServicesInstance.clockOut(punching.punchingDetails?.todaysPunch._id, clockOutData);
        if (response && response.success) {
          dispatch(clockOut(punchData.clock_in.time));
        }
      }
    }
    dispatch(getPunchDetails());
    setLoading(false);
  };

  const formattedJobData: { id: string; name: string }[] = (assignedJobs?.jobs as { _id: string; job_name: string }[])
    ? (assignedJobs?.jobs as { _id: string; job_name: string }[]).map((eachJob) => {
        return { id: eachJob._id, name: eachJob.job_name };
      })
    : [];

  return loading || punching.isPunchLoading ? (
    <Loader />
  ) : (
    <Paper className="relative w-full h-fit  lg:h-[70dvh]  overflow-y-scroll flex justify-center items-start p-8">
      <Grid container className="w-full h-full" spacing={3}>
        {/* -----Clockin/Clockout--------- */}
        <Grid item lg={4} md={5} sm={12} xs={12} justifyContent={'center'} alignItems={'center'}>
          <Box display="flex" height="100%" alignItems="center" justifyContent="center">
            {punching.isPunchLoading ? (
              <Stack className="w-full" alignItems={'center'} gap={1}>
                <Skeleton width={'100%'} height={50} variant="rectangular" />
                <Skeleton width={'50%'} height={20} variant="rectangular" />
              </Stack>
            ) : (
              <Stack className="w-full" alignItems={'center'} gap={1}>
                <Button
                  fullWidth
                  className="shadow-lg "
                  color={punching.isClockedIn ? 'error' : 'primary'}
                  variant="contained"
                  onClick={() => handlePunchClick(new Date().toISOString(), new Date().toISOString(), false)}
                  size="large"
                >
                  <Icon color="inherit" component={ClockSvg} width={50} height={50} fill={'currentColor'} />
                  <Typography variant="h2" className="ml-2">
                    {!punching.isClockedIn ? 'Clock In' : 'Clock Out'}
                  </Typography>
                </Button>
                {punching.isClockedIn && (
                  <Typography color={'GrayText'} variant="caption" className="flex items-end space-x-1">
                    <strong>{moment(punching.clockedInAt).format('MM-DD-YYYY')}</strong>
                    <Typography variant="h5">{moment(punching.clockedInAt).format('hh:mm A')}</Typography>
                  </Typography>
                )}
              </Stack>
            )}
          </Box>
        </Grid>

        <Hidden mdDown>
          <Grid item lg={1} md={1} sm={0} xs={12} justifyContent={'center'}>
            <Box display="flex" height="100%" alignItems="center" justifyContent="center">
              <Divider orientation="vertical" flexItem />
            </Box>
          </Grid>
        </Hidden>

        {/* ---------Actions--------- */}

        <Grid item lg={7} md={6} sm={12} xs={12} className="max-h-full overflow-y-auto">
          <Stack className="w-full" spacing={3}>
            {/* ---------Job-------- */}
            {!isJobDataFetching ? (
              <>
                {!!formattedJobData.length && (
                  <Stack width={'100%'}>
                    <InputLabel>Job Name</InputLabel>
                    <Autocomplete
                      fullWidth
                      value={selectedJob}
                      disabled={punching.isClockedIn || currentPuchedInJob.id !== ''}
                      getOptionLabel={(option: { id: string; name: string }) => option.name}
                      isOptionEqualToValue={(option, optionValue) => option.id === optionValue.id}
                      options={formattedJobData}
                      onChange={(event: React.SyntheticEvent<Element, Event>, newValue: any) => {
                        setSelectedJob(newValue ?? { id: '', name: '' });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="No job selected"
                          sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                          helperText={
                            currentPuchedInJob &&
                            currentPuchedInJob.start?.time &&
                            `Started at ${moment(currentPuchedInJob.start?.time).format('hh:mm A MM/DD')}`
                          }
                        />
                      )}
                    />
                  </Stack>
                )}
              </>
            ) : (
              <Typography component="div" variant={'h2'}>
                <Skeleton height={70} />
              </Typography>
            )}
            {/* ---------Action Buttons--------- */}
            <Stack className="w-full" spacing={3}>
              {punching.isClockedIn && (
                <div className="w-full flex sm:flex-row flex-col sm:space-x-4 space-x-0 sm:space-y-0 space-y-4 ">
                  <Button
                    size="large"
                    fullWidth
                    variant="text"
                    color={localStorage.getItem('breakInfo') ? 'warning' : 'primary'}
                    className="shadow-md border-dashed border-[0.09rem]"
                    startIcon={<CoffeeOutlined />}
                    onClick={() => handleAddBreak(true)}
                  >
                    {localStorage.getItem('breakInfo') ? 'End Break' : 'Take Break'}
                  </Button>
                  <Button
                    size="large"
                    fullWidth
                    className="sm:shadow-lg shadow-md"
                    startIcon={<FormOutlined />}
                    onClick={() => handleAddNoteClick(true)}
                  >
                    Add Note
                  </Button>
                </div>
              )}
              <div className="w-full flex sm:flex-row flex-col sm:space-x-4 space-x-0 sm:space-y-0 space-y-4 ">
                <Button
                  size="large"
                  fullWidth
                  className="sm:shadow-lg shadow-md"
                  startIcon={<FieldTimeOutlined />}
                  onClick={() => handleSpecifyTime(true)}
                >
                  Specify time
                </Button>
                <Button
                  onClick={() => navigate('/punch/list')}
                  size="large"
                  fullWidth
                  className="sm:shadow-lg shadow-md"
                  startIcon={<EyeOutlined />}
                >
                  Punching details
                </Button>
              </div>
            </Stack>
          </Stack>

          {/* Breaks */}
          {punching.isClockedIn && breaks?.length ? (
            <>
              <div className="flex flex-row justify-center items-center space-x-3 my-8">
                <Divider className="w-24" />
                <Typography variant="h4" className="text-center">
                  Breaks
                </Typography>
                <Divider className="w-24" />
              </div>
              <div className="flex flex-col gap-2 w-full">
                {breaks.map((eachBreak) => (
                  <div className="flex sm:flex-row flex-col w-full items-center justify-center border-2 border-dashed border-gray-200">
                    <div className="p-3 text-gray-500">
                      {moment(eachBreak.start_time).format('MM-DD-YYYY')}&nbsp;
                      <strong className={`text-base ${themeConfig.mode === 'light' ? 'text-gray-800' : 'text-white'}`}>
                        {moment(eachBreak.start_time).format('hh:mm A')}
                      </strong>
                    </div>
                    <div className="p-3 text-gray-400">
                      <CoffeeOutlined className="text-xl" />
                    </div>
                    <div className="p-3 text-gray-500">
                      {moment(eachBreak.end_time).format('MM-DD-YYYY')}&nbsp;
                      <strong className={`text-base ${themeConfig.mode === 'light' ? 'text-gray-800' : 'text-white'}`}>
                        {moment(eachBreak.end_time).format('hh:mm A')}
                      </strong>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : null}
        </Grid>
      </Grid>
      {addNotePopup.open && (
        <AddNotePopup
          action={{ ...addNotePopup }}
          title={'Add Notes'}
          onClose={() => handleAddNoteClick(false)}
          selectedJob={selectedJob}
        />
      )}
      {specifyTimePopup && (
        <SpecifyPunchTimePopup
          action={{ ...specifyTimePopup }}
          title={`Specify ${punching.isClockedIn ? 'Clock Out' : 'Clock In'} Time`}
          onClose={() => handleSpecifyTime(false)}
          isClockedIn={punching.isClockedIn}
          handlePunchClick={handlePunchClick}
          specifyTimePopup={specifyTimePopup}
        />
      )}
      {addBreakPopup && (
        <AddBreakPopup
          action={{ ...addBreakPopup }}
          title={'Add Break info'}
          onClose={() => handleAddBreak(false)}
          isClockedIn={punching.isClockedIn}
          addBreakPopup={addBreakPopup}
        />
      )}
    </Paper>
  );
};

export default ClockInOutPage;
