// material-ui
import { Typography } from '@mui/material';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <Typography className="mt-auto pt-4" variant="caption">
      {year} &copy; <strong>ZippFleet</strong> - Save Your Time, Choose The Best
    </Typography>
  );
};

export default Footer;
