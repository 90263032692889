import { CloseCircleFilled, EnvironmentFilled, PhoneFilled } from '@ant-design/icons';
import { LoadingButton } from '@mui/lab';
import { ListItemButton } from '@mui/material';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  Slide,
  Toolbar,
  Typography
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { TCustomerData } from 'pages/customers/types/type.customersPage';
import { forwardRef, useState } from 'react';
import CustomerServicesInstance from 'services/services.customers';
import { formatPhoneNumber, getStateAbbrevation } from 'utils/common';

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => {
    return <Slide direction="down" ref={ref} {...props} />;
  }
);

type TCustomerConfDialogProps = {
  action: { open: boolean; maxWidth: DialogProps['maxWidth']; title: string; header: string };
  data: TCustomerData[] | null;
  customerFormValues: any;
  handleClose: () => void;
  handleSuccess: (arg0?: TCustomerData) => void;
  type?: 'add' | 'edit' | 'update';
  extraData?: any;
};

const CustomerCreateUpdateDialog = (props: TCustomerConfDialogProps) => {
  const { type: customerActionTime = 'add' } = props;
  const [loading, setLoading] = useState(false);

  const handleSave = async (action: 'new' | 'update' | 'edit') => {
    setLoading(true);
    const formattedValue = props.customerFormValues;
    if (formattedValue.phone1_type !== 'office') delete formattedValue.phone1_extension;
    if (formattedValue?.phone2 && formattedValue.phone2.length === 0) {
      delete formattedValue.phone2;
      delete formattedValue.phone2_type;
      delete formattedValue.phone2_extension;
    } else if (formattedValue.phone2_type !== 'office') delete formattedValue.phone2_extension;

    if (formattedValue?.phone3 && formattedValue.phone3.length === 0) {
      delete formattedValue.phone3;
      delete formattedValue.phone3_type;
      delete formattedValue.phone3_extension;
    } else if (formattedValue.phone3_type !== 'office') delete formattedValue.phone3_extension;

    if (action === 'update') {
      const response = await CustomerServicesInstance.editCustomer(selectedCustomerId as string, formattedValue);
      if (response && response.data.success) {
        props.handleSuccess(response.data.data as TCustomerData);
        setLoading(false);
      }
    } else if (action === 'new') {
      const response = await CustomerServicesInstance.createCustomer(formattedValue);
      if (response && response.data.success) {
        props.handleSuccess(response.data.data as TCustomerData);
        setLoading(false);
      }
    } else if (action === 'edit') {
      const response = await CustomerServicesInstance.editCustomer(props.extraData.customerId as string, formattedValue);
      if (response && response.data.success) {
        props.handleSuccess(response.data.data as TCustomerData);
        setLoading(false);
      }
    } else {
      return;
    }
  };

  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(null);
  const handleSelectedCustomer = (selectedCustomerId: string) => {
    setSelectedCustomerId(selectedCustomerId);
  };

  const renderPrimaryButton = () => {
    switch (customerActionTime) {
      case 'add':
        return (
          <LoadingButton loading={loading} onClick={() => handleSave('new')} variant="contained">
            {!!props.data ? 'Create new record' : 'Yes'}
          </LoadingButton>
        );
      case 'update':
        return (
          <>
            <LoadingButton loading={loading} onClick={() => handleSave('new')} variant="contained">
              {!!props.data ? 'Create new record' : 'Yes'}
            </LoadingButton>
            <LoadingButton loading={loading} disabled={!selectedCustomerId} onClick={() => handleSave('update')} variant="contained">
              Update existing record
            </LoadingButton>
          </>
        );
      case 'edit':
        return (
          <LoadingButton loading={loading} onClick={() => handleSave('edit')} variant="contained">
            Update record
          </LoadingButton>
        );
    }
  };

  return (
    <Dialog {...props.action} fullWidth TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <div className="w-full flex items-center">
            {props?.action.header && (
              <>
                {typeof props?.action.header === 'string' ? (
                  <Typography sx={{ flex: 1 }} variant="h4" component="div">
                    {props?.action.header}
                  </Typography>
                ) : (
                  props?.action.header
                )}
              </>
            )}
          </div>

          <IconButton edge="start" color="inherit" onClick={props?.handleClose} aria-label="close">
            <CloseCircleFilled />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <DialogContentText>{props?.action.title}</DialogContentText>
        {props.data && customerActionTime === 'update' && (
          <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {props.data.map((eachCustomer, index) => (
              <ListItem key={index} disablePadding className="gap-x-2">
                <ListItemButton role={undefined} onClick={() => handleSelectedCustomer(eachCustomer._id as string)} dense>
                  <ListItemIcon>
                    <Radio checked={eachCustomer._id === selectedCustomerId} />
                  </ListItemIcon>
                  <ListItemText
                    id={index.toString()}
                    primary={
                      <div className="flex items-center space-x-2">
                        <PhoneFilled />
                        <Typography>{`${formatPhoneNumber(eachCustomer.phone1 as string)}${
                          eachCustomer?.first_name && ` (${eachCustomer?.first_name} ${eachCustomer?.last_name})`
                        }`}</Typography>
                      </div>
                    }
                    secondary={
                      <div className="flex items-center space-x-2">
                        <EnvironmentFilled />
                        <span>{`${eachCustomer?.address?.address1} ${eachCustomer?.address?.address2} ${
                          eachCustomer?.address?.cross_street
                        } 
                        ${eachCustomer?.address?.city}
                           ${getStateAbbrevation(eachCustomer?.address?.state)} 
                          `}</span>
                      </div>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions className="w-full">
        <Button onClick={props.handleClose} variant="text">
          {!!props.data ? 'Cancel' : 'No'}
        </Button>
        {renderPrimaryButton()}
      </DialogActions>
    </Dialog>
  );
};

export default CustomerCreateUpdateDialog;
