import { LoadingOutlined } from '@ant-design/icons';
import { Button, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { TJobInfo } from 'components/widgets/types/jobInfoType';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import JobServicesInstance from 'services/services.jobs';
import { constants } from 'utils/constants';

export const DeclineForm = ({
  jobData,
  onSuccess
}: {
  jobData: Omit<TJobInfo, 'cash' | 'credit_card' | 'paypal' | 'venmo' | 'zelle'>;
  onSuccess?: () => void;
}) => {
  //-------------------constants--------------------
  const navigate = useNavigate();
  const reasonOptions = ['Timing Issues', 'Not A Competitive Offer', 'Better Offers Are On The Table', 'Other'];
  const [selectedReason, setSelectedReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  //--------------------Submit Funtion-----------------
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    if (jobData?.job?._id && jobData?.company._id) {
      const response = await JobServicesInstance.declineProposal(
        {
          company_id: jobData?.company._id,
          proposal_decline_reason: selectedReason,
          proposal_decline_reason_note: otherReason,
          follow_up_link: `${constants.REACT_APP_BASE_URL}/followups/view/${jobData.job._id}`
        },
        jobData?.job?._id
      );
      setIsSubmitting(false);
      if (!onSuccess) {
        response && navigate(`/proposal/${jobData.job._id}`);
      } else {
        onSuccess();
      }
    }
  };

  return (
    <Grid container className="p-4" spacing={6} component={'form'} onSubmit={(event) => handleSubmit(event)}>
      <Grid item xs={12}>
        <InputLabel>Reason*</InputLabel>
        <Select
          id="reason"
          className="w-full"
          value={selectedReason}
          renderValue={(selected) => reasonOptions.find((reason) => reason === selected)}
          onChange={(event) => {
            setSelectedReason(event.target.value);
          }}
        >
          {reasonOptions?.map((reason) => {
            return <MenuItem value={reason}>{reason}</MenuItem>;
          })}
        </Select>
      </Grid>
      {selectedReason === 'Other' && (
        <Grid item xs={12}>
          <InputLabel>Reason Note</InputLabel>
          <TextField
            id="other-reason"
            className="w-full"
            multiline
            value={otherReason}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setOtherReason(event.target.value)}
          />
        </Grid>
      )}
      <Grid item xs={12} className="flex flex-row justify-center">
        <Button
          type="submit"
          disabled={isSubmitting || selectedReason === '' || (selectedReason === 'other' && otherReason === '')}
          variant="contained"
          startIcon={isSubmitting && <LoadingOutlined />}
        >
          submit
        </Button>
      </Grid>
    </Grid>
  );
};
