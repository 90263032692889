import { Divider, Typography } from '@mui/material';
import { IEstimationOption } from 'components/tables/interfaces/jobTableInterface';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { checkApprovedOption, getListOfItemDetails } from 'utils/common';

const WidgetEstimateTermsAndConditions = ({
  termsAndConditions,
  approvedOptionIndex,
  optionData
}: {
  termsAndConditions: string;
  approvedOptionIndex?: number;
  optionData?: IEstimationOption[];
}) => {
  const locaiton = useLocation();
  const pathParameter = locaiton.pathname.split('/')[1];
  const [listOfItemDetails, setListOfItemDetails] = useState<string[]>();

  //-------------useEffect------------

  useEffect(() => {
    if (optionData) {
      const approvedOptionIndex = checkApprovedOption(optionData);
      setListOfItemDetails(() => {
        return getListOfItemDetails(approvedOptionIndex === -1 ? optionData : [optionData[approvedOptionIndex]]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="space-y-2 p-4 ">
      {pathParameter === 'proposal' && (
        <>
          <Typography variant="h5" sx={{ color: '#2cc4cb' }}>
            Item Or Task Details
          </Typography>
          <Divider />

          {listOfItemDetails?.map((singleItemDetails) => (
            <Typography>{singleItemDetails}</Typography>
          ))}
        </>
      )}
      <Typography variant="h5" sx={{ color: '#2cc4cb' }}>
        Terms & Conditions
      </Typography>
      <Divider />
      <Typography sx={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
    </div>
  );
};

export default WidgetEstimateTermsAndConditions;
