import { ClockCircleOutlined } from '@ant-design/icons';
import { LoadingButton } from '@mui/lab';
import { IconButton, Typography, useMediaQuery } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const formatTime = (seconds: number) => {
  const d = moment.duration(seconds, 'seconds');
  return moment.utc(d.asMilliseconds()).format('HH:mm:ss');
};

const UserClockTime = () => {
  const theme = useTheme();
  const matchUpSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const navigate = useNavigate();
  const punching = useSelector((state: any) => state.punching);

  const [isOnBreak, setIsOnBreak] = useState<boolean>(() => {
    return !!localStorage.getItem('breakInfo');
  });
  const [time, setTime] = useState<number>(() => {
    const savedTime = localStorage.getItem('activeTimeSoFar');
    return savedTime ? parseInt(savedTime, 10) : 0;
  });

  useEffect(() => {
    const checkBreakStatus = () => {
      const breakInfoPresent = !!localStorage.getItem('breakInfo');
      setIsOnBreak(breakInfoPresent);
    };

    const intervalId = setInterval(checkBreakStatus, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!punching.isClockedIn) {
      setTime(0);
      localStorage.setItem('activeTimeSoFar', '0');
      return;
    }

    const tick = () => {
      setTime((prev) => {
        if (!isOnBreak) {
          const newTime = prev + 1;

          localStorage.setItem('activeTimeSoFar', newTime.toString());
          return newTime;
        } else {
          return prev;
        }
      });
    };

    const timerId = setInterval(tick, 1000);
    return () => clearInterval(timerId);
  }, [punching.isClockedIn, isOnBreak]);

  return (
    <div className="min-w-max">
      {matchUpSm ? (
        <LoadingButton
          loading={punching.isPunchLoading}
          startIcon={<ClockCircleOutlined />}
          variant="contained"
          fullWidth
          onClick={() => navigate('/punch')}
          color={isOnBreak ? 'warning' : 'primary'}
        >
          {punching.isClockedIn ? formatTime(time) : 'Clock In'}
        </LoadingButton>
      ) : punching.isClockedIn ? (
        <Typography
          onClick={() => navigate('/punch')}
          sx={{
            backgroundColor: `${theme.palette.primary.main} !important`
          }}
          className="rounded-sm text-white p-1 cursor-pointer"
        >
          {formatTime(time)}
        </Typography>
      ) : (
        <IconButton onClick={() => navigate('/punch')} className="rounded-sm">
          <ClockCircleOutlined />
        </IconButton>
      )}
    </div>
  );
};

export default UserClockTime;
