import { DeleteOutlined, InboxOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  CardContent,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import MainCard from 'components/MainCard';
import { getIn, useFormik } from 'formik';
import { TCalendarConfig } from 'pages/Settings/types/types.generalSettings';
import { useEffect } from 'react';
import generalSettingsInstance from 'services/services.generalSettings';
import { dispatch, useSelector } from 'store';
import { getGeneralSettings } from 'store/reducers/customReducers/slice.settings';
import { calendarConfigInstruction } from 'utils/constants';
import * as Yup from 'yup';

const CalendarSetingsPage = ({ onClose }: { onClose: () => void }) => {
  const { generalSettings: settingsData } = useSelector((state) => state.settings);

  const newCalendarCongif = {
    count: 0,
    bg_color: '#FFFFFF'
  };
  const calendarSettingsFormik = useFormik<{ booking_limit_type: number; calendar_config: TCalendarConfig[] }>({
    initialValues: {
      booking_limit_type: 0,
      calendar_config: [] as TCalendarConfig[]
    },
    validationSchema: Yup.object().shape({
      calendar_config: Yup.array().of(
        Yup.object().shape({
          count: Yup.number().min(0, 'should have at least 0')
        })
      )
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      setSubmitting(true);
      await handleSettingFormSubmit(values);
      setStatus({ success: true });
      setSubmitting(false);
    }
  });
  const handleSettingFormSubmit = async (configData: { booking_limit_type: number; calendar_config: TCalendarConfig[] }) => {
    if (settingsData) {
      const values = { ...settingsData };
      delete values?._id;
      delete values?.company_id;
      delete values?.created_at;
      delete values?.updated_at;
      delete values?.__v;
      delete values?.created_by;
      delete values?.updated_by;
      delete values?.status;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { address2, ...company_address } = values.invoice_company_details.company_address;
      const company_details = { company_address: company_address, company_name: values.invoice_company_details.company_name };
      values.invoice_company_details = company_details;
      if (!values?.charge_for_estimates) {
        delete values?.estimate_price;
        delete values.waive_estimate_charges;
      }
      values.booking_limit_type = configData.booking_limit_type;
      values.calendar_config = configData.calendar_config;
      const response = await generalSettingsInstance.updateSettingsData(values);

      if (response) {
        dispatch(getGeneralSettings());
        onClose();
      }
    }
  };
  const handleDeleteCalendarConfigData = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    const dummyCalendarConfigData = [...calendarSettingsFormik.values.calendar_config];
    dummyCalendarConfigData.splice(index, 1);
    calendarSettingsFormik.setFieldValue('calendar_config', dummyCalendarConfigData);
  };
  const handleAddNewCalendarConfigData = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const dummyCalendarConfigData = [...calendarSettingsFormik.values.calendar_config, newCalendarCongif];
    calendarSettingsFormik.setFieldValue('calendar_config', dummyCalendarConfigData);
  };
  useEffect(() => {
    if (settingsData) {
      calendarSettingsFormik.setValues({
        booking_limit_type: settingsData.booking_limit_type,
        calendar_config: settingsData.calendar_config
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsData]);
  return (
    <div>
      <Typography
        variant="body1"
        component="div"
        style={{ lineHeight: 1.5 }}
        dangerouslySetInnerHTML={{ __html: calendarConfigInstruction }}
      />
      <RadioGroup
        row
        className="p-2"
        aria-label="is_customer_address"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          calendarSettingsFormik.setFieldValue('calendar_config', []);
          calendarSettingsFormik.setFieldValue('booking_limit_type', Number(event.target.value));
        }}
        name="booking_limit_type"
        value={calendarSettingsFormik.values.booking_limit_type}
      >
        <FormControlLabel value={0} control={<Radio />} label={'Job Count'} />
        <FormControlLabel value={1} control={<Radio />} label={'Job Price'} />
      </RadioGroup>
      <Grid item xs={12} component={'form'} onSubmit={calendarSettingsFormik.handleSubmit}>
        <MainCard
          title={
            <div className="flex justify-between">
              <div>Config Data</div>
              <div className="flex justify-end">
                <Button variant="outlined" size="small" startIcon={<PlusOutlined />} onClick={handleAddNewCalendarConfigData}>
                  {calendarSettingsFormik.values?.calendar_config?.length > 0 ? 'Add More' : 'Add'}
                </Button>
              </div>
            </div>
          }
        >
          <Grid item xs={12}>
            {!!calendarSettingsFormik.values?.calendar_config?.length ? (
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {<TableCell align="left">Job {calendarSettingsFormik.values.booking_limit_type === 0 ? 'Count' : 'Price'}</TableCell>}
                      <TableCell align="left">Bg Color</TableCell>
                      <TableCell align="left">Action</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {calendarSettingsFormik.values.calendar_config.map(
                      (singleCalendarData: { count: number; bg_color: string }, index: number) => (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            <TextField
                              type="number"
                              placeholder={`Job ${calendarSettingsFormik.values.booking_limit_type === 0 ? 'Count' : 'Price'}`}
                              name={`calendar_config[${index}].count`}
                              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                const inputValue = event.target.value;
                                if (inputValue.charAt(0) !== '-') {
                                  calendarSettingsFormik.handleChange(event);
                                }
                              }}
                              value={singleCalendarData.count}
                              inputProps={{ min: 0 }}
                              error={Boolean(
                                getIn(calendarSettingsFormik.touched, `calendar_config[${index}].count`) &&
                                  getIn(calendarSettingsFormik.errors, `calendar_config[${index}].count`)
                              )}
                            />
                            {getIn(calendarSettingsFormik.touched, `calendar_config[${index}].count`) &&
                              getIn(calendarSettingsFormik.errors, `calendar_config[${index}].count`) && (
                                <FormHelperText error id="helper-text-first_name">
                                  {getIn(calendarSettingsFormik.errors, `calendar_config[${index}].count`)}
                                </FormHelperText>
                              )}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <TextField
                              fullWidth
                              type="color"
                              placeholder="Bg Color"
                              name={`calendar_config[${index}].bg_color`}
                              onChange={calendarSettingsFormik.handleChange}
                              value={singleCalendarData.bg_color}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <IconButton>
                              <DeleteOutlined
                                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                  handleDeleteCalendarConfigData(event, index);
                                }}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <CardContent className="w-full">
                <Stack className="mt-4 flex flex-col items-center gap-2">
                  <InboxOutlined style={{ height: 20, transform: 'scale(3)', color: 'GrayText' }} />
                  <Typography color={'GrayText'}>No Data</Typography>
                </Stack>
              </CardContent>
            )}
          </Grid>
        </MainCard>
        <div className="flex justify-end mt-4">
          <Button variant="outlined" type="submit" disabled={calendarSettingsFormik.isSubmitting}>
            Save
          </Button>
        </div>
      </Grid>
    </div>
  );
};

export default CalendarSetingsPage;
