import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import ProductPlaceholder from 'components/cards/skeleton/ProductPlaceholder';
import MainCard from 'components/MainCard';
import { TJobItem } from 'components/tables/interfaces/jobTableInterface';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import PublicSerivcesInstance from 'services/service.noAuth';
import { categorizeEstimationOptions, checkApprovedOption } from 'utils/common';
import WidgetEstimateHeader from '../components/WidgetEstimateHeader';
import WidgetEstimateOptions from '../components/WidgetEstimateOptions';
import WidgetEstimateTermsAndConditions from '../components/WidgetEstimateTermsAndConditions';
import { dispatch, useSelector } from 'store';
import { setGeneralSettings } from 'store/reducers/customReducers/slice.settings';

const ProposalPage = () => {
  //---------------constants-----------------
  const location = useLocation();
  const navigate = useNavigate();
  const jobId = new URLSearchParams(location.search).get('job_id');
  const componentRef = useRef<HTMLDivElement | null>(null);
  const [dataByCategory, setDataByCategory] = useState<{ [key: string]: TJobItem[] }[]>([]); //It will be an array of objects and each keys in object will represent a separate job category, a single key will contain items related to it.
  const [categoryTotal, setCategoryTotal] = useState<{ [key: string]: number }[]>([]);
  const [backButton, setBackButton] = useState<{ state: boolean; route: string | null }>({ state: false, route: null });
  const settings = useSelector((state) => state.settings);
  //-----------------useQuery------------------------------
  const { data: jobData, isFetched: isJobFetched } = useQuery({
    queryKey: ['single_job_data', jobId],
    queryFn: () => PublicSerivcesInstance.getJobInfo(jobId as string, 'proposal'),
    enabled: !!jobId,
    networkMode: 'always'
  });
  //-------------------------useEffects--------------------

  useEffect(() => {
    const prevPage = localStorage.getItem('prevPage');
    if (prevPage && prevPage.length) {
      setBackButton({ state: true, route: prevPage });
    }
  }, []);

  useEffect(() => {
    dispatch(setGeneralSettings({ ...settings, type_case: jobData?.type_case }));

    if (!!jobData?.job.estimations && !!jobData?.job.estimations?.options.length) {
      const approvedOptionIndex = checkApprovedOption(jobData.job.estimations?.options);
      if (approvedOptionIndex === -1) {
        const formatedData = categorizeEstimationOptions(jobData.job.estimations.options);
        setCategoryTotal(formatedData.category_total);
        setDataByCategory(formatedData.data_by_category);

        return;
      }
      const formatedData = categorizeEstimationOptions([jobData.job.estimations.options[approvedOptionIndex]]);
      setCategoryTotal(formatedData.category_total);
      setDataByCategory(formatedData.data_by_category);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobData]);

  const handleBackButtonClick = () => {
    if (backButton.route) {
      localStorage.setItem('prevPage', '');
      navigate(backButton.route);
    }
  };

  return (
    <>
      {backButton.state && (
        <Button onClick={handleBackButtonClick} color="secondary" startIcon={<ArrowLeftOutlined />}>
          Back
        </Button>
      )}
      {isJobFetched && !!jobData && !!jobData?.job?.estimations ? (
        <MainCard content={false} className="uppercase" ref={componentRef}>
          <WidgetEstimateHeader
            jobData={jobData}
            dataByCategory={dataByCategory}
            categoryTotal={categoryTotal}
            componentRef={componentRef}
          />
          <div className="p-5">
            <WidgetEstimateOptions
              dataByCategory={dataByCategory}
              categoryTotal={categoryTotal}
              approvedOptionIndex={checkApprovedOption(jobData.job.estimations.options) ?? -1}
              jobData={jobData.job}
            />
          </div>
          <WidgetEstimateTermsAndConditions
            termsAndConditions={jobData?.job.estimations?.terms_and_conditions as string}
            approvedOptionIndex={checkApprovedOption(jobData.job.estimations?.options)}
            optionData={jobData.job.estimations.options}
          />
        </MainCard>
      ) : (
        <ProductPlaceholder />
      )}
    </>
  );
};

export default ProposalPage;
