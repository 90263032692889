import { FileOutlined, MailFilled, MessageFilled, PictureFilled, SendOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Autocomplete, Button, Grid, TextField, Typography, useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import CustomTooltip from 'components/CustomTooltip';
import { ISearch } from 'components/filters/SearchFilter';
import AddEmailorSms from 'components/popups/AddEmailorSms';
import TextPhotosLink from 'components/popups/TextPhotosLink';
import UniversalDialog from 'components/popups/UniversalDialog';
import CustomDataTable, { rowsPerPageOptions } from 'components/tables/CustomDataTable';
import { TCustomerPhones, TJobCompleteData } from 'components/tables/interfaces/jobTableInterface';
import useTimezone from 'hooks/useTimezone';
import mime from 'mime';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import CustomerServicesInstance from 'services/services.customers';
import EmailSmsServicesInstance from 'services/services.emailSms';
import JobServicesInstance from 'services/services.jobs';
import UserServicesInstance from 'services/services.users';
import { useSelector } from 'store';
import { toTitleCase } from 'utils/common';
import { TCustomerAddEmailSMSPopup, TCustomerEmailSMSData } from '../types/type.customersView';

const EmailSMS = ({
  customer,
  showFilter,
  showJobName,
  jobData,
  fromModule
}: {
  customer: {
    _id?: string;
    first_name?: string;
    last_name?: string;
    emails?: string[];
    phones?: TCustomerPhones;
  };
  showFilter?: boolean;
  showJobName?: boolean;
  jobData?: TJobCompleteData;
  fromModule: 'job' | 'customer';
}) => {
  //-----------------constants-----------------
  const theme = useTheme();
  const navigate = useNavigate();
  const primaryColor = `${theme.palette.primary.main}`;
  const formatDateTime = useTimezone();

  const { generalSettings } = useSelector((state) => state.settings);
  const [selectedUser, setSelectedUser] = useState({ _id: '', first_name: '', last_name: '' });
  const [selectedJobName, setSelectedJobName] = useState({ _id: '', job_name: '' });
  const [paginationData, setPaginationData] = useState({ page: 0, rowsPerPage: rowsPerPageOptions[0] });
  const [jobSearchData, setJobSearchData] = useState<ISearch>({
    search: [
      [
        {
          field_name: 'status',
          field_value: 1,
          operator: 'exactmatch'
        },
        {
          field_name: 'customer.id',
          field_value: customer?._id || '',
          operator: 'exactmatch'
        }
      ]
    ]
  });
  const [emailsSmsSearchData, setEmailsSmsSearchData] = useState<ISearch>({
    search: [
      [
        {
          field_name: 'customer.id',
          field_value: customer?._id ?? '',
          operator: 'exactmatch'
        }
      ]
    ]
  });
  const [userSearchData] = useState<ISearch>({
    search: [
      [
        {
          field_name: 'status',
          field_value: 1,
          operator: 'exactmatch'
        }
      ]
    ]
  });
  const [addEmailOrSmsPopup, setAddEmailOrSmsPopup] = useState<TCustomerAddEmailSMSPopup>({
    action: { open: false, isEmail: true, showJobName: true },
    data: {
      id: customer?._id,
      name: `${customer?.first_name} ${customer?.last_name}`,
      emails: customer.emails,
      phones: customer.phones
    },
    job: jobData ? { id: jobData._id || '', name: jobData.job_name || '' } : undefined
  });

  const [textPhotosLink, setTextPhotosLink] = useState(false);

  //-----------------useQuery-----------------
  const { data: allEmailsSmsData, refetch: refetchEmailandSms } = useQuery({
    queryKey: ['EmailandSms_data', paginationData, emailsSmsSearchData],
    queryFn: () => EmailSmsServicesInstance.getAllEmailsAndSms(customer?._id, paginationData, emailsSmsSearchData),
    enabled: !!customer?._id && customer?._id?.length > 0
  });
  const { data: allUsersData } = useQuery({
    queryKey: ['user_data', userSearchData],
    queryFn: () => UserServicesInstance.getAllUsers(undefined, userSearchData)
  });

  const { data: allJobsData } = useQuery({
    queryKey: ['job_data', jobSearchData],
    queryFn: () => JobServicesInstance.getAllJobs(undefined, jobSearchData)
  });
  //-----------------handlers-----------------
  const renderMedia = (fileUrl: string) => {
    const mimeType = mime.getType(fileUrl)?.split('/')[0];
    switch (mimeType) {
      case 'image':
        return <Avatar variant="rounded" sx={{ maxHeight: '8rem', border: '1px solid lightgrey' }} src={fileUrl} />;
      case 'video':
        return (
          <Avatar variant="rounded" sx={{ maxHeight: '8rem' }}>
            <VideoCameraOutlined />
          </Avatar>
        );
      default:
        return (
          <Avatar variant="rounded" sx={{ maxHeight: '8rem' }}>
            <FileOutlined />
          </Avatar>
        );
    }
  };

  const handleUserChange = (newValue: any) => {
    setSelectedUser(newValue);
    if (newValue) {
      setEmailsSmsSearchData(
        selectedJobName && selectedJobName?._id !== ''
          ? {
              search: [
                [
                  {
                    field_name: 'created_by.user_id',
                    field_value: newValue._id,
                    operator: 'exactmatch'
                  }
                ],
                [
                  {
                    field_name: 'job.id',
                    field_value: selectedJobName._id,
                    operator: 'exactmatch'
                  }
                ]
              ]
            }
          : {
              search: [
                [
                  {
                    field_name: 'created_by.user_id',
                    field_value: newValue._id,
                    operator: 'exactmatch'
                  }
                ]
              ]
            }
      );
    } else {
      selectedJobName && selectedJobName?._id !== ''
        ? setEmailsSmsSearchData({
            search: [
              [
                {
                  field_name: 'job.id',
                  field_value: selectedJobName._id,
                  operator: 'exactmatch'
                }
              ]
            ]
          })
        : setEmailsSmsSearchData({ search: [] });
    }
  };
  const handleJobChange = (newValue: any) => {
    setSelectedJobName(newValue);
    if (newValue) {
      setEmailsSmsSearchData(
        selectedUser && selectedUser?._id !== ''
          ? {
              search: [
                [
                  {
                    field_name: 'job.id',
                    field_value: newValue._id,
                    operator: 'exactmatch'
                  }
                ],
                [
                  {
                    field_name: 'created_by.user_id',
                    field_value: selectedUser._id,
                    operator: 'exactmatch'
                  }
                ]
              ]
            }
          : {
              search: [
                [
                  {
                    field_name: 'job.id',
                    field_value: newValue._id,
                    operator: 'exactmatch'
                  }
                ]
              ]
            }
      );
    } else {
      selectedUser && selectedUser?._id !== ''
        ? setEmailsSmsSearchData({
            search: [
              [
                {
                  field_name: 'created_by.user_id',
                  field_value: selectedUser._id,
                  operator: 'exactmatch'
                }
              ]
            ]
          })
        : setEmailsSmsSearchData({ search: [] });
    }
  };
  const handleAddEmailorSmsPopup = () => {
    refetchEmailandSms();
    setAddEmailOrSmsPopup({
      action: { open: false, isEmail: true, showJobName: showJobName },
      data: {
        id: customer?._id,
        name: `${customer?.first_name} ${customer?.last_name}`,
        emails: customer.emails,
        phones: customer.phones
      },
      job: jobData ? { id: jobData._id || '', name: jobData.job_name || '' } : undefined
    });
  };

  const handleSms = async () => {
    setAddEmailOrSmsPopup({
      action: { open: true, isEmail: false, showJobName: showJobName },
      data: {
        id: customer?._id,
        name: `${customer?.first_name} ${customer?.last_name}`,
        phones: customer.phones
      },
      job: jobData ? { id: jobData._id || '', name: jobData.job_name || '' } : undefined
    });
  };
  const handleEmail = async () => {
    setAddEmailOrSmsPopup({
      action: { open: true, isEmail: true, showJobName: showJobName },
      data: {
        id: customer?._id,
        name: `${customer?.first_name} ${customer?.last_name}`,
        emails: customer.emails
      },
      ...(!!jobData && { job: { id: jobData?._id as string, name: jobData?.job_name as string } })
    });
  };

  const handleTextPhotosLink = (state: boolean) => {
    setTextPhotosLink(state);
  };

  const handleEmailRatingLink = async () => {
    await CustomerServicesInstance.emailRatingLink({ customer_id: customer._id as string });
  };

  const handleChangePagination = (page: number, rowsPerPage: number) => {
    setPaginationData({ page, rowsPerPage });
  };

  //--------------------useEffect----------------
  useEffect(() => {
    if (!jobData) return;
    setEmailsSmsSearchData((prevState) => ({
      ...prevState,
      search: [
        ...prevState.search,
        [
          {
            field_name: 'job.id',
            field_value: jobData._id ?? '',
            operator: 'exactmatch'
          }
        ]
      ]
    }));
  }, [jobData]);

  useEffect(() => {
    if (!!customer?._id?.length) {
      setJobSearchData((prevSearchData: ISearch) => {
        return {
          search: prevSearchData.search.map((singleArrayData) => {
            return singleArrayData.map((singleFilter) => {
              if (singleFilter.field_name === 'customer.id') {
                return { ...singleFilter, field_value: customer?._id ?? '' };
              }
              return singleFilter;
            });
          })
        };
      });
      setEmailsSmsSearchData((prevSearchData: ISearch) => {
        return {
          search: prevSearchData.search.map((singleArrayData) => {
            return singleArrayData.map((singleFilter) => {
              if (singleFilter.field_name === 'customer.id') {
                return { ...singleFilter, field_value: customer?._id ?? '' };
              }
              return singleFilter;
            });
          })
        };
      });
    }
  }, [customer]);

  // ---------------------------------------Columns----------------------------------------
  const columns = useMemo<ColumnDef<TCustomerEmailSMSData>[]>(
    () => [
      {
        accessorFn: (row) => {
          if (row.created_at) return formatDateTime(row.created_at, { hour12: generalSettings?.time_format === '12hrs' });
          return '-';
        },
        id: 'Date',
        header: () => <span>Date</span>
      },
      {
        accessorFn: (row) => row.job?.id,
        id: 'job_name',
        cell: ({
          row: {
            original: { job }
          }
        }) =>
          job?.id ? (
            <Typography
              sx={{
                minWidth: 'max-content',
                '&:hover': {
                  color: primaryColor
                }
              }}
              className={`cursor-pointer`}
              onClick={() => navigate(`/jobs/view/${job?.id}/job_status`)}
            >
              {job?.name}
            </Typography>
          ) : (
            'N/A'
          ),
        header: () => <span>Job Name</span>
      },
      {
        accessorFn: (row) => row.type,
        id: 'type',
        header: () => <span>Type</span>,
        cell: ({ row: { original: row } }) => {
          return (
            <Typography
              color={row.type === 'email' ? 'success.main' : 'primary.dark'}
              className="min-w-max whitespace-break-spaces font-medium"
            >
              {toTitleCase(row.type)}
            </Typography>
          );
        }
      },
      {
        accessorFn: (row) => (row.subject ? row.subject : 'N/A'),
        id: 'subject',
        cell: ({ row: { original: rowOriginal } }) => {
          return <Typography className="w-[150px] whitespace-break-spaces">{rowOriginal.subject}</Typography>;
        },
        header: () => <span>Subject</span>
      },
      {
        accessorFn: (row) => row.message,
        id: 'body',
        cell: ({ row: { original: rowOriginal } }) => {
          return (
            <Typography
              className="w-[400px] max-w-[450px] whitespace-break-spaces overflow-auto"
              component={'p'}
              dangerouslySetInnerHTML={{ __html: rowOriginal.message }}
            />
          );
        },
        header: () => <span>Body</span>
      },
      {
        accessorFn: (row) => row.attachments,
        id: 'attachment',
        cell: ({ row }) => {
          return row.original?.attachments?.length ? (
            <div className="flex flex-wrap w-full max-w-[30rem]">
              {row.original.attachments.map((preview: string, index: number) => (
                <a key={index} href={preview} target="blank" className="mr-2 mt-2">
                  {renderMedia(preview)}
                </a>
              ))}
            </div>
          ) : (
            'N/A'
          );
        },
        header: () => <span>Attachments</span>
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      <Grid container xs={12} className="space-y-8">
        <Grid container item xs={12} className="flex justify-between px-6 my-3" spacing={3}>
          <Grid container item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }} spacing={3}>
            {showFilter && (
              <Grid item xs={12} md={6} lg={6} sm={6}>
                <Autocomplete
                  value={selectedUser}
                  getOptionLabel={(option: any) =>
                    option?.first_name && option?.last_name ? `${option?.first_name} ${option?.last_name}` : ''
                  }
                  options={allUsersData?.users || []}
                  onChange={(event: React.SyntheticEvent<Element, Event>, newValue: any) => {
                    handleUserChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      placeholder="User"
                      sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                    />
                  )}
                />
              </Grid>
            )}
            {showJobName && (
              <Grid item xs={12} md={6} lg={6} sm={6}>
                <Autocomplete
                  value={selectedJobName}
                  getOptionLabel={(option: any) => option?.job_name}
                  options={allJobsData?.jobs || []}
                  onChange={(event: React.SyntheticEvent<Element, Event>, newValue: any) => {
                    handleJobChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      placeholder="Job Name"
                      sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }} className="flex space-x-3" justifyContent={'end'}>
            <CustomTooltip
              message={'A link will be sent to the customer via EMAIL and SMS, allowing them to upload photos.'}
              props={{ placement: 'left' }}
            >
              <Button variant="contained" endIcon={<PictureFilled />} color="success" onClick={() => handleTextPhotosLink(true)}>
                Send Photos Link
              </Button>
            </CustomTooltip>
            <Button variant="contained" endIcon={<MailFilled />} color="primary" onClick={() => handleEmail()}>
              Send Email
            </Button>
            <Button variant="contained" endIcon={<MessageFilled />} color="primary" onClick={() => handleSms()}>
              Send SMS
            </Button>
            {generalSettings?.rating.link && showFilter && (
              <Button variant="contained" endIcon={<SendOutlined />} color="primary" onClick={() => handleEmailRatingLink()}>
                Email Ratings Link
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CustomDataTable
            data={allEmailsSmsData?.job_email_and_sms || []}
            columns={columns}
            hasPagination
            onPaginationChange={handleChangePagination}
            count={allEmailsSmsData?.count}
          />
        </Grid>
      </Grid>
      {addEmailOrSmsPopup.action.open && (
        <UniversalDialog
          action={{ open: addEmailOrSmsPopup.action.open, fullWidth: true }}
          onClose={() => handleAddEmailorSmsPopup()}
          hasPrimaryButton={false}
        >
          <AddEmailorSms {...addEmailOrSmsPopup} onClose={() => handleAddEmailorSmsPopup()} />
        </UniversalDialog>
      )}
      {textPhotosLink && (
        <TextPhotosLink
          open={textPhotosLink}
          onClose={() => handleTextPhotosLink(false)}
          customerData={customer}
          jobData={jobData}
          fromModule={fromModule}
        />
      )}
    </div>
  );
};
export default EmailSMS;
