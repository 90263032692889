import { TAppointmentWidget } from 'components/widgets/types/types.appointmentWidget';
import { EstimateFormData } from 'components/widgets/types/types.estimateFormWidget';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import axiosServices from 'utils/axios';

class WidgetServices {
  createCustomerEstimate = async (values: any) => {
    try {
      const response: { data: { success: boolean; jobId: string } } = await axiosServices.post('api/widget/request-estimate', values);
      if (response && response.data.success) {
        return response.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  scheduleAppointment = async (values: EstimateFormData & TAppointmentWidget) => {
    try {
      const response: { data: { success: boolean; jobId: string } } = await axiosServices.post('api/widget/schedule-appointment', values);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Appointment Scheduled Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data;
      }
    } catch (error: unknown) {
      const knownError = error as { success: boolean; message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: `${knownError.message}`,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
    }
  };
}
const widgetServicesInstance = new WidgetServices();
export default widgetServicesInstance;
