import { ClearOutlined, LoadingOutlined, PlusCircleFilled } from '@ant-design/icons';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CustomTooltip from 'components/CustomTooltip';
import { useFormik } from 'formik';
import { TPayNow, TUserPayrollInformation } from 'pages/payrolls/types/types.payroll';
import { useEffect, useRef } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';
import payrollServicesInstance from 'services/services.payroll';
import { formateData } from 'utils/common';
import * as Yup from 'yup';

const PayNowPopup = ({ payrollData, open, onClose }: { payrollData: TUserPayrollInformation; open: boolean; onClose: () => void }) => {
  //--------------------------------------constants-------------
  const signatureRef = useRef<any>();
  const theme = useTheme();
  const formik = useFormik<TPayNow>({
    initialValues: {
      user: {
        id: payrollData._id,
        name: `${payrollData.first_name} ${payrollData.last_name}`
      },
      note: '',
      paid_amount: payrollData.payroll.payment,
      payment_signature: null as unknown as number,
      cheque_or_receipt_number: null as unknown as number,
      clear_cash_and_cheque: false
    },
    validationSchema: Yup.object().shape({
      note: Yup.string().required('This Field is required'),
      paid_amount: Yup.number().required('This Field is required'),
      payment_signature: Yup.string().required('This Field is required'),
      cheque_or_receipt_number: Yup.string().required('This Field is required')
    }),
    onSubmit: async (values: TPayNow, { setSubmitting }) => {
      setSubmitting(true);
      if (payrollData.payroll.cash_collected === 0 && payrollData.payroll.cheques_collected === 0) values.clear_cash_and_cheque = false;
      await payrollServicesInstance.payrollPayment(values);
      setSubmitting(false);
      onClose();
    }
  });

  useEffect(() => {
    console.log('error', formik.errors);
  }, [formik.errors]);
  //--------------------handlers-----------------
  const clearSignature = () => {
    signatureRef?.current?.clear();
    formik.setFieldValue('payment_signature', '');
  };
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle className="w-full flex justify-between">
        <span>Pay To {formik.values.user.name}</span>
        <IconButton onClick={onClose} className="absolute top-1 right-1">
          <PlusCircleFilled rotate={45} />
        </IconButton>
      </DialogTitle>
      <Box component={'form'} onSubmit={formik.handleSubmit}>
        <DialogContent className="space-y-4">
          <Stack direction={{ xs: 'column', sm: 'row' }} className="sm:justify-between">
            <div className="flex justify-between">
              <Typography color={theme.palette.info.main}>Total Pay Owed:&nbsp;</Typography>
              <Typography color={theme.palette.info.main} className="inline-block text-end">
                {formateData(payrollData.payroll.payment ?? 0, '$')}
              </Typography>
            </div>
            <div className="flex justify-between">
              <Typography color={theme.palette.success.main}>Cash Collected:&nbsp;</Typography>
              <Typography color={theme.palette.success.main} className="inline-block text-end">
                {payrollData.payroll.cash_collected}
              </Typography>
            </div>
            <div className="flex justify-between">
              <Typography color={theme.palette.warning.main}>Cheques Collected:&nbsp;</Typography>
              <Typography color={theme.palette.warning.main} className="inline-block text-end">
                {payrollData.payroll.cheques_collected}
              </Typography>
            </div>
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="paid_amount">Payment*</InputLabel>
              <OutlinedInput
                type="number"
                id="paid_amount"
                value={formik.values.paid_amount}
                name="paid_amount"
                onBlur={formik.handleBlur}
                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  if (Number(event.target.value) < 0) {
                    event.target.value = '';
                    return null;
                  } else if (Number(event.target.value) > payrollData.payroll.payment) {
                    return null;
                  }
                  formik.handleChange(event);
                }}
                placeholder="Payment"
                fullWidth
                error={Boolean(formik.touched.paid_amount && formik.errors.paid_amount && formik.values.paid_amount)}
              />
              {formik.touched.paid_amount && formik.errors.paid_amount && (
                <FormHelperText error id="helper-text-paid_amount">
                  {formik.errors?.paid_amount}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="paid_amount">Check / Receipt#*</InputLabel>
              <OutlinedInput
                id="cheque_or_receipt_number"
                value={formik.values.cheque_or_receipt_number}
                name="cheque_or_receipt_number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="Check / Receipt"
                fullWidth
                error={Boolean(
                  formik.touched.cheque_or_receipt_number &&
                    formik.errors.cheque_or_receipt_number &&
                    formik.values.cheque_or_receipt_number
                )}
              />
              {formik.touched.cheque_or_receipt_number && formik.errors.cheque_or_receipt_number && (
                <FormHelperText error id="helper-text-cheque_or_receipt_number">
                  {formik.errors?.cheque_or_receipt_number}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="paid_amount">Notes*</InputLabel>
              <OutlinedInput
                type="number"
                id="notes"
                multiline
                minRows={3}
                value={formik.values.note}
                name="note"
                onBlur={formik.handleBlur}
                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  if (Number(event.target.value) < 0) {
                    event.target.value = '';
                    return null;
                  }
                  formik.handleChange(event);
                }}
                placeholder="Notes"
                fullWidth
                error={Boolean(formik.touched.note && formik.errors.note && formik.values.note)}
              />
              {formik.touched.note && formik.errors.note && (
                <FormHelperText error id="helper-text-note">
                  {formik.errors?.note}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="payment_signature" className="flex justify-between">
                <span>Please Enter Your Signature To Approve Propsal.*</span>
                <CustomTooltip message="Clear">
                  <IconButton onClick={clearSignature} color="error">
                    <ClearOutlined />
                  </IconButton>
                </CustomTooltip>
              </InputLabel>
              <div className="flex flex-row justify-center">
                <ReactSignatureCanvas
                  penColor="black"
                  canvasProps={{
                    className: 'signateureCanvas',
                    style: { width: '100%', maxWidth: '600px', border: '1px solid black' }
                  }}
                  onEnd={() => formik.setFieldValue('payment_signature', signatureRef?.current?.toDataURL())}
                  ref={signatureRef}
                />
              </div>
              {formik.touched.payment_signature && formik.errors.payment_signature && (
                <FormHelperText error id="helper-text-payment_signature">
                  {formik.errors?.payment_signature}
                </FormHelperText>
              )}
            </Grid>
            {/**------------------Should We Clear Out The Cash And The cheques?
--------------------------- */}
            <Grid item xs={12}>
              <div className="flex flex-col md:flex-row md:space-x-2 space-y-2 md:space-y-0 md:items-center">
                <div className="break-all">Should we clear out the cash and the cheques?</div>
              </div>
              <RadioGroup
                row
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('clear_cash_and_cheque', event.target.value === 'true')
                }
                aria-label="clear_cash_and_cheque"
                name="clear_cash_and_cheque"
                id="clear_cash_and_cheque"
                value={`${formik.values.clear_cash_and_cheque}`}
              >
                <FormControlLabel
                  disabled={!(payrollData.payroll.cash_collected > 0 || payrollData.payroll.cheques_collected > 0)}
                  value={false}
                  control={<Radio />}
                  label={'No'}
                />
                <FormControlLabel
                  disabled={!(payrollData.payroll.cash_collected > 0 || payrollData.payroll.cheques_collected > 0)}
                  value={true}
                  control={<Radio />}
                  label={'Yes'}
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" disabled={formik.isSubmitting} startIcon={formik.isSubmitting && <LoadingOutlined />}>
            Submit
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default PayNowPopup;
