import { Card, CardContent, CardHeader, List, ListItemButton, ListItemText, MenuItem, Select, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { TDateFilter, TSalesTax } from 'pages/Dashboard/types/types.dashboard';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { formateData, randomHexColorCode, snakeCaseToTitleCase } from 'utils/common';

const SalesTaxReport = ({
  selectedFilter,
  setSelectedFilter,
  salesTaxDateFilter,
  setSalesTaxDateFilter,
  reportData
}: {
  selectedFilter: TDateFilter;
  setSelectedFilter: React.Dispatch<React.SetStateAction<TDateFilter>>;
  salesTaxDateFilter: { year: number; quater: string };
  setSalesTaxDateFilter: React.Dispatch<React.SetStateAction<{ year: number; quater: string }>>;

  reportData: TSalesTax;
}) => {
  //--------------------------constants--------------------
  const currentYear = new Date().getFullYear();
  const yearFilter = [];
  for (let i = 0; i < 7; i++) yearFilter.push(currentYear - i);
  const qualterFilter = [
    { label: `Entire Year`, value: 'year' },
    { label: 'Dec - Feb', value: '1' },
    { label: 'Mar - May', value: '2' },
    { label: 'Jun - Aug', value: '3' },
    { label: 'Sep - Nov', value: '4' }
  ];
  const [salesTaxReport, setSalesTaxReport] = useState<{ label: string; value: number; color: string }[]>([]);

  //-----------------------------useEffects-------------------------
  useEffect(() => {
    const dummyData = Object.keys(reportData).map((singleItem) => {
      return { label: snakeCaseToTitleCase(singleItem), value: reportData[singleItem as keyof TSalesTax], color: randomHexColorCode() };
    });
    setSalesTaxReport(dummyData);
  }, [reportData]);

  useEffect(() => {
    const dateFilter: { start_date: string; end_date: string } = { start_date: '', end_date: '' };
    const t = () => {
      switch (salesTaxDateFilter.quater) {
        case 'year':
          dateFilter.start_date = moment().year(salesTaxDateFilter.year).startOf('year').toISOString();
          dateFilter.end_date = moment().year(salesTaxDateFilter.year).endOf('year').toISOString();

          return dateFilter;
        case '1':
          dateFilter.start_date = moment().year(salesTaxDateFilter.year).month('Dec').startOf('month').toISOString();
          dateFilter.end_date = moment().year(salesTaxDateFilter.year).month('Feb').endOf('month').toISOString();

          return dateFilter;
        case '2':
          dateFilter.start_date = moment().year(salesTaxDateFilter.year).month('Mar').startOf('month').toISOString();
          dateFilter.end_date = moment().year(salesTaxDateFilter.year).month('May').endOf('month').toISOString();

          return dateFilter;
        case '3':
          dateFilter.start_date = moment().year(salesTaxDateFilter.year).month('Jun').startOf('month').toISOString();
          dateFilter.end_date = moment().year(salesTaxDateFilter.year).month('Aug').endOf('month').toISOString();

          return dateFilter;
        case '4':
          dateFilter.start_date = moment().year(salesTaxDateFilter.year).month('Sep').startOf('month').toISOString();
          dateFilter.end_date = moment().year(salesTaxDateFilter.year).month('Oct').endOf('month').toISOString();

          return dateFilter;
        default:
        // Default case
      }
    };
    setSelectedFilter((prev) => ({
      ...prev,
      ...t()
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesTaxDateFilter]);

  return (
    <div className="grid sm:grid-cols-1 lg:grid-cols-3 gap-2">
      <Card>
        <CardContent className="space-y-4">
          <div className="sm:flex justify-between space-y-2 sm:space-x-2 sm:space-y-0">
            <Select
              name="year_filter"
              defaultValue={moment().year()}
              className="w-full"
              value={salesTaxDateFilter.year}
              onChange={(event) => setSalesTaxDateFilter({ ...salesTaxDateFilter, year: Number(event.target.value) })}
            >
              {yearFilter &&
                yearFilter.map((singleData: number, index: number) => (
                  <MenuItem key={index} value={singleData}>
                    {singleData}
                  </MenuItem>
                ))}
            </Select>{' '}
            <Select
              defaultValue={'year'}
              name="quater_filter"
              className="w-full"
              value={salesTaxDateFilter.quater}
              onChange={(event) => setSalesTaxDateFilter({ ...salesTaxDateFilter, quater: event.target.value })}
            >
              {qualterFilter?.map((singleData: { label: string; value: string }, index: number) => (
                <MenuItem key={index} value={singleData.value}>
                  {singleData.label}
                </MenuItem>
              ))}
            </Select>
          </div>
          <CardHeader
            title={
              <div className="flex flex-row justify-between font-semibold">
                <Typography className="text-lg font-bold">Payment Type</Typography>
                <Typography className="text-lg font-bold">Amount</Typography>
              </div>
            }
          />
          {salesTaxReport.map((singleItem) => (
            <List>
              <ListItemButton>
                <ListItemText
                  primary={
                    <Stack
                      sx={{ paddingLeft: '0.5rem', borderLeft: `5px solid ${singleItem.color}` }}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <span>{singleItem.label}</span>
                      <Typography>{formateData(singleItem.value ?? 0, '$')}</Typography>
                    </Stack>
                  }
                />
              </ListItemButton>
            </List>
          ))}
        </CardContent>
      </Card>
      <Card className="lg:col-span-2 justify-center hidden sm:block">
        <ReactApexChart
          type="bar"
          options={{
            chart: { stacked: true, toolbar: { show: false }, offsetX: -16 },

            title: { text: 'Sales Tax Report', align: 'center' },
            dataLabels: { enabled: false }
          }}
          series={[
            {
              name: '',
              data: salesTaxReport.map((singleItem) => ({
                x: singleItem.label,
                y: singleItem.value.toFixed(2),
                fillColor: singleItem.color
              }))
            }
          ]}
        />
      </Card>
    </div>
  );
};

export default SalesTaxReport;
