import { LoadingButton } from '@mui/lab';
import { Alert, Autocomplete, Checkbox, FormControlLabel, FormHelperText, Grid, InputLabel, Stack, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker, DateTimePicker, LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useQuery } from '@tanstack/react-query';
import { ISearch } from 'components/filters/SearchFilter';
import FormSubmissionAlertMessage from 'components/FormSubmissionAlertMessage';
import TFollowupData, { TfollowupFormatedData } from 'components/tables/interfaces/followupTableInterface';
import { SingleJob, TCustomerPhones } from 'components/tables/interfaces/jobTableInterface';
import dayjs, { Dayjs } from 'dayjsConfig';
import { getIn, useFormik } from 'formik';
import moment from 'moment';
import { SingleCustomer } from 'pages/customers/types/type.customersPage';
import { SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import FollowupServicesInstance from 'services/services.followups';
import JobServicesInstance from 'services/services.jobs';
import UserServicesInstance from 'services/services.users';
import { useSelector } from 'store';
import trimFc from 'utils/trimFc';
import * as Yup from 'yup';

type TFollowuoFormProps = {
  existingData?: TFollowupData;
  isEditMode?: boolean;
  customerData?: { id: string; name: string; phones: TCustomerPhones; emails?: string[] };
  selectedJobId?: string;
  onSuccess?: () => void;
};

type TFollowupFormValues = {
  job: { id: string; name: string };
  customer: { id: string; name: string; emails: string[]; phones: string[] };
  call_assigned_to: { id: string; name: string };
  callback_date: string | null;
  exclude_callback_time: boolean;
  note: string;
  alert_assigned_user: string;
  reminder_method: number[];
  time: string | null;
  text_alert: boolean;
};

const AddFollowupForm = (props: TFollowuoFormProps) => {
  const { existingData, isEditMode, customerData, selectedJobId } = props;

  const alertUserCheck = useRef(false);
  const theme = useTheme();
  const followUpStoreData = useSelector((state) => state.followUpActions);

  // ----------------------------------------Constants----------------------------------------
  const [allUser, setAllUser] = useState<SingleCustomer[]>([]);
  const [showCustomerNotification, setShowCustomerNotification] = useState<number[]>([]);
  const [formattedCustomerJobData, setFormattedCustomerJobData] = useState<SingleJob[] | undefined>();

  const [searchData, setSearchData] = useState<ISearch>({
    search: [
      [
        {
          field_name: 'customer.id',
          field_value: followUpStoreData.customer?.id?.length ? followUpStoreData.customer?.id : customerData?.id ?? '',
          operator: 'exactmatch'
        }
      ],
      [
        {
          field_name: 'status',
          field_value: 1,
          operator: 'exactmatch'
        },
        {
          field_name: 'status',
          field_value: 2,
          operator: 'exactmatch'
        }
      ]
    ]
  });

  const notificationSendingChannels: { value: number; label: string }[] = [
    { value: 1, label: 'Email' },
    { value: 2, label: 'SMS' }
  ];

  const alertAssignedUser: { value: boolean; label: string }[] = [{ value: true, label: 'Alert the user assigned to this followup ?' }];
  // ----------------------------------------useQuery----------------------------------------
  const { data: customerJobsData } = useQuery({
    queryKey: ['customerJobsData', searchData],
    queryFn: () => JobServicesInstance.getAllJobs(undefined, searchData)
  });

  const { data: allFetchedUsers } = useQuery({
    queryKey: ['followup_users'],
    queryFn: () => UserServicesInstance.getAllUsers()
  });
  // --------------------------Formik-------------------
  const formik = useFormik<TFollowupFormValues>({
    initialValues: {
      job: { id: '', name: '' },
      customer: { id: '', name: '', emails: [], phones: [] },
      call_assigned_to: { id: '', name: '' },
      callback_date: new Date().toISOString(),
      exclude_callback_time: false,
      note: 'Setting Up A Followup',
      alert_assigned_user: '',
      reminder_method: [],
      time: null,
      text_alert: false
    },

    validationSchema: Yup.object().shape({
      text_alert: Yup.boolean(),
      time: Yup.string().when('text_alert', {
        is: (value: boolean) => {
          return !!value;
        },
        then: (schema) => schema.required('time is required.'),
        otherwise: (schema) => schema.nullable()
      }),
      callback_date: Yup.string().when('call_assigned_to', {
        is: (value: { id: string; name: string }) => !!value?.id,
        then: (schema) => schema.required('Callback date is required.'),
        otherwise: (schema) => schema.nullable()
      }),
      note: Yup.string().required('This field is required'),
      customer: Yup.object().shape({
        phones: Yup.array()
          .of(
            Yup.object().shape({
              phone: Yup.string().required('This field is required'),
              phone_country_code: Yup.string().required('This field is required')
            })
          )
          .required('This field is required')
      })
    }),

    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await handleJobFormSubmit(values, setSubmitting);
      setSubmitting(false);
    }
  });
  // ----------------------------------------Handlers----------------------------------------

  const formatJobData = (flatObject: TfollowupFormatedData) => {
    const formatedObj: TfollowupFormatedData = {
      callback_date: flatObject.callback_date,
      exclude_callback_time: flatObject.exclude_callback_time,
      note: flatObject.note,
      text_alert: flatObject.text_alert,
      reminder_method: showCustomerNotification,
      time: flatObject.time
        ? moment(flatObject.callback_date)
            .set('hour', moment(flatObject.time).hour())
            .set('minute', moment(flatObject.time).minute())
            .toISOString()
        : ''
    };

    if (!existingData?._id) {
      formatedObj.customer = flatObject.customer;
    }

    if (flatObject?.job && flatObject?.job.id) {
      formatedObj.job = flatObject.job;
    }

    if (flatObject?.call_assigned_to && flatObject?.call_assigned_to.id) {
      formatedObj.call_assigned_to = flatObject.call_assigned_to;
    }

    return formatedObj;
  };

  const handleJobFormSubmit = async (values: any, setSubmitting: (arg0: boolean) => void) => {
    const fomattedValues: any = formatJobData(values);

    if (isEditMode)
      !!existingData && !!existingData._id && (await FollowupServicesInstance.editFollowup(existingData?._id, fomattedValues));
    else await FollowupServicesInstance.createFollowup(fomattedValues);
    props?.onSuccess?.();
  };

  // ----------------------------------------useEffect----------------------------------------

  useEffect(() => {
    alertUserCheck.current = formik.values?.call_assigned_to?.id.length > 0;
  }, [formik.values]);

  useEffect(() => {
    const filterQuery: ISearch = { ...searchData };
    if (followUpStoreData.job_id) {
      filterQuery.search[0].push({
        field_name: '_id',
        field_value: followUpStoreData.job_id,
        operator: 'exactmatch'
      });
    } else if (selectedJobId) {
      filterQuery.search[0].push({
        field_name: '_id',
        field_value: selectedJobId,
        operator: 'exactmatch'
      });
    }
    setSearchData(filterQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [followUpStoreData.job_id, selectedJobId]);

  useEffect(() => {
    if (!allFetchedUsers) return;
    const users = allFetchedUsers.users.map((singleEstimator) => ({
      value: singleEstimator._id as string,
      label: `${singleEstimator.first_name} ${singleEstimator.last_name}`
    }));
    setAllUser(users);
  }, [allFetchedUsers]);

  useEffect(() => {
    if (customerJobsData) {
      const selectedJob = customerJobsData.jobs?.map((eachJob) => ({ value: eachJob._id, label: eachJob.job_name }));
      setFormattedCustomerJobData(selectedJob);

      formik.setFieldValue('job', {
        id: selectedJob?.find((eachJob) => eachJob.value === selectedJobId)?.value,
        name: selectedJob?.find((eachJob) => eachJob.value === selectedJobId)?.label
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerJobsData]);

  useEffect(() => {
    if (existingData) {
      formik.setValues({
        ...formik.values,
        call_assigned_to: existingData.call_assigned_to ?? { id: '', name: '' },
        note: existingData.note,
        callback_date: existingData.callback_date,
        exclude_callback_time: existingData.exclude_callback_time ?? false,
        text_alert: existingData?.text_alert as boolean,
        time: existingData?.time as string
      });
      existingData.reminder_method && setShowCustomerNotification(existingData.reminder_method);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingData]);

  useEffect(() => {
    if (customerData) {
      formik.setFieldValue('customer', {
        id: customerData?.id,
        name: customerData?.name,
        emails: customerData?.emails,
        phones: customerData.phones
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerData]);

  function scrollToErrorField() {
    const allErrors = Object.entries(formik.errors);

    if (allErrors.length > 0) {
      const firstError = allErrors[0];

      let elemId = firstError[0];
      if (typeof firstError[1] === 'object') {
        elemId = Object.keys(firstError[1])[0];
      }

      const firstErrorElement = document.getElementById(elemId);
      if (firstErrorElement) {
        firstErrorElement.focus();
        firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }

  const areDatesInPast = useMemo(() => {
    const { time, callback_date } = formik.values;
    return dayjs(time).isBefore(dayjs()) || dayjs(callback_date).isBefore(dayjs());
  }, [formik.values]);

  return (
    <form
      onSubmit={(event) => {
        scrollToErrorField();
        formik.handleSubmit(event);
      }}
      className="w-full relative pb-6"
    >
      <Grid container spacing={2.5}>
        {/* -----------------------------------------Job Name--------------------------------- */}
        {!!getIn(formik.errors, 'customer.phones') && formik.isSubmitting && (
          <Grid>
            <Alert severity="error">{getIn(formik.errors, 'customer.phones')}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack spacing={1}>
            <InputLabel htmlFor="job">Job Name</InputLabel>
            <Autocomplete
              disabled={!!selectedJobId?.length}
              id="job"
              value={
                formattedCustomerJobData?.find((singleData: SingleJob) => {
                  return singleData.value === formik.values.job?.id;
                }) || {
                  value: '',
                  label: ''
                }
              }
              disableClearable={false}
              clearOnBlur={true}
              getOptionLabel={(option) => option?.label ?? ''}
              onChange={(event: SyntheticEvent<Element, Event>, newValue: SingleJob | null) => {
                if (newValue) formik.setFieldValue('job', { id: newValue.value, name: newValue.label });
                else {
                  formik.setFieldValue('job', { id: '', name: '' });
                }
              }}
              options={formattedCustomerJobData ? formattedCustomerJobData : []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                />
              )}
            />
          </Stack>
        </Grid>

        {/* -----------------------------------------Call Assigned To--------------------------------- */}
        <Grid item xs={12}>
          <Stack spacing={1}>
            <InputLabel htmlFor="call_assigned_to">Assigned To</InputLabel>
            <Autocomplete
              disableClearable={false}
              clearOnBlur={true}
              id="call_assigned_to"
              value={allUser.find((singleData) => singleData.value === formik.values.call_assigned_to?.id) || null}
              getOptionLabel={(option) => (option ? option.label : '')}
              onChange={(event: SyntheticEvent<Element, Event>, newValue: { label: string; value: string } | null) => {
                if (newValue) {
                  formik.setFieldValue('call_assigned_to', { id: newValue.value, name: newValue.label });
                } else {
                  formik.setFieldValue('call_assigned_to', { id: '', name: '' }); // Clear the value
                  formik.setFieldValue('time', '');
                  formik.setFieldValue('text_alert', false);
                  setShowCustomerNotification([]);
                }
              }}
              options={allUser}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                />
              )}
            />
          </Stack>
        </Grid>

        {/* -----------------------------------------Callback Date------------------------------ */}
        <Grid item xs={12}>
          <Stack spacing={1}>
            <div className="w-full flex items-center justify-between">
              <InputLabel htmlFor="callback_date" required={!!formik.values.call_assigned_to.id}>
                Followup Date
              </InputLabel>
              <FormControlLabel
                value={formik.values.exclude_callback_time}
                name="exclude_callback_time"
                id="exclude_callback_time"
                color="primary"
                checked={formik.values.exclude_callback_time}
                control={
                  <Checkbox
                    classes={{ root: 'p-0 mr-1' }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                      formik.setFieldValue('exclude_callback_time', checked)
                    }
                  />
                }
                label={'Exclude Time'}
              />
            </div>
            {formik.values.exclude_callback_time ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="w-full"
                  value={formik.values.callback_date ? dayjs(formik.values.callback_date) : null}
                  minDate={dayjs()}
                  onChange={(newValue: Dayjs | null) => {
                    if (newValue?.isValid()) {
                      const utcMidnight = newValue.startOf('day').toISOString();
                      formik.setFieldValue('callback_date', utcMidnight);
                    }
                  }}
                />
              </LocalizationProvider>
            ) : (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  className="w-full"
                  value={formik.values.callback_date ? dayjs(formik.values.callback_date) : null}
                  minDateTime={dayjs()}
                  onChange={(newValue) => {
                    if (newValue?.isValid()) {
                      const isoUTCString = newValue.toISOString();
                      formik.setFieldValue('callback_date', isoUTCString);
                    }
                  }}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock
                  }}
                />
              </LocalizationProvider>
            )}
            {formik.touched.callback_date && formik.errors.callback_date && (
              <FormHelperText error id="helper-text-callback_date">
                {formik.errors.callback_date}
              </FormHelperText>
            )}
          </Stack>
        </Grid>

        {/* -----------------------------Notes------------------------------ */}
        <Grid item xs={12}>
          <Stack spacing={1} className="w-full">
            <InputLabel htmlFor="note">Notes*</InputLabel>
            <TextField
              id="note"
              fullWidth
              placeholder="Note"
              name="note"
              multiline
              rows={2}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && !event.shiftKey) {
                  event.preventDefault();
                  const form = document.getElementsByTagName('form')[0];
                  if (form) {
                    form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
                  }
                }
              }}
              onChange={trimFc(formik)}
              value={formik.values?.note}
              error={formik.touched.note && !!formik.errors.note}
              helperText={formik.touched.note && formik.errors.note}
            />
          </Stack>
        </Grid>

        {/* -----------------------------------------alert_assigned_user----------------------------- */}
        {alertUserCheck.current === true && !!formik.values.call_assigned_to.id && (
          <Grid item xs={12}>
            {alertAssignedUser.map((items: { value: boolean; label: string }, index) => {
              return (
                <FormControlLabel
                  value={items.value}
                  name="alert_assigned_user"
                  id="alert_assigned_user"
                  color="primary"
                  checked={formik.values.text_alert}
                  control={
                    <Checkbox
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (event.target.checked) {
                          formik.setFieldValue('text_alert', true);
                          if (dayjs(formik.values.callback_date).isBefore(dayjs(new Date()))) {
                            formik.setFieldValue('time', null);
                          } else {
                            formik.setFieldValue('time', formik.values.callback_date);
                          }
                          setShowCustomerNotification([1, 2]);
                        } else {
                          setShowCustomerNotification([]);
                          formik.setFieldValue('time', null);
                          formik.setFieldValue('text_alert', false);
                        }
                      }}
                    />
                  }
                  label={items.label}
                />
              );
            })}
          </Grid>
        )}
        <Grid item xs={12}>
          {alertUserCheck.current === true && !!formik.values.call_assigned_to.id && formik.values.text_alert === true && (
            <Grid container spacing={2}>
              {/* -----------------------------------------Time------------------------------ */}
              <Grid item className="space-y-2" xs={12}>
                <InputLabel htmlFor="scheduled_time">Reminder Date & Time*</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    className="w-full"
                    value={dayjs(formik.values.time)}
                    minDateTime={dayjs()}
                    maxDateTime={dayjs(formik.values.callback_date)}
                    onChange={(newValue) => {
                      if (newValue?.isValid()) {
                        const isoUTCString = newValue.toISOString();
                        formik.setFieldValue('time', isoUTCString);
                      }
                    }}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock
                    }}
                  />
                  {formik.touched.time && formik.errors.time && (
                    <FormHelperText error id="helper-text-time">
                      {formik.errors.time}
                    </FormHelperText>
                  )}
                </LocalizationProvider>
              </Grid>
              {/* -----------------------------------------Reminder Method------------------------------ */}
              <Grid item xs={12}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  <InputLabel htmlFor="reminder_method">Remind via</InputLabel>
                  <Stack direction={'row'} spacing={1}>
                    {notificationSendingChannels.map((items: { value: number; label: string }, index) => {
                      return (
                        <FormControlLabel
                          value={items.value}
                          name="reminder_method"
                          id="reminder_method"
                          color="primary"
                          checked={showCustomerNotification.includes(items.value)}
                          control={
                            <Checkbox
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const newCheckObject: Array<number> = [...showCustomerNotification];
                                if (event.target.checked) {
                                  newCheckObject.push(Number(event.target.value));
                                  setShowCustomerNotification([...new Set(newCheckObject)]);
                                } else {
                                  setShowCustomerNotification(newCheckObject.filter((obj) => obj !== Number(event.target.value)));
                                }
                              }}
                            />
                          }
                          label={items.label}
                        />
                      );
                    })}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          {FormSubmissionAlertMessage(formik)}
        </Grid>
      </Grid>
      {/* -----------------------------------------Submit Button------------------------------ */}
      <div className="absolute bottom-0 flex flex-col items-end w-full">
        <LoadingButton
          variant="contained"
          disabled={areDatesInPast || formik.isSubmitting}
          type="submit"
          loading={formik.isSubmitting}
          size="large"
          className="max-w-max"
        >
          {formik.isSubmitting ? 'Saving...' : isEditMode ? 'Save' : 'Add'}
        </LoadingButton>
      </div>
    </form>
  );
};

export default AddFollowupForm;
