import { ArrowLeftOutlined, CarOutlined, EditOutlined, ScheduleOutlined } from '@ant-design/icons';
import { Button, Card, Icon, Typography, useMediaQuery, useTheme } from '@mui/material';
import AddFollowupForm from 'components/forms/AddFollowupForm';
import AddEstimateForm from 'components/forms/JobFormWizard';
import useAuth from 'hooks/useAuth';
import { TCustomerData } from 'pages/customers/types/type.customersPage';
import { useEffect, useState } from 'react';
import { dispatch } from 'store';
import { setActionTitle } from 'store/reducers/customReducers/customerActions';
import { setFollowUpCustomer } from 'store/reducers/customReducers/slice.followUps';
import { formatePhonesData } from 'utils/common';
import { actionTypes } from 'utils/constants';
import { TActionCardProps, TCardData } from './types.actionPage';

type TAdActionProps = {
  customerData: TCustomerData;
  onSuccess: () => void;
};

const AddActionPage = (props: TAdActionProps) => {
  const { customerData } = props;
  const { user } = useAuth();
  const [activeForm, setActiveForm] = useState<string>('');

  const handleFormChange = (currentForm: string) => {
    dispatch(setActionTitle(actionTypes.find((actionType) => actionType.value === currentForm)?.label));
    setActiveForm(currentForm);
  };

  const handleBack = () => {
    setActiveForm('');
    dispatch(setActionTitle(''));
  };

  const renderForms = () => {
    switch (activeForm) {
      case 'follow_up':
        return (
          customerData && (
            <AddFollowupForm
              customerData={{
                id: customerData._id as string,
                name: `${customerData.first_name} ${customerData.last_name}`,
                emails: customerData.emails,
                phones: formatePhonesData(customerData)
              }}
              onSuccess={props?.onSuccess}
            />
          )
        );
      case 'job':
      case 'estimate':
        return <AddEstimateForm customerData={customerData} onSuccess={props?.onSuccess} />;
      default:
        break;
    }
  };

  const [actionCardsData, setActionCardsData] = useState<TCardData[]>([]);

  useEffect(() => {
    const newActionCardsData: TCardData[] = [...actionCardsData];
    if (user?.role && user?.role === 'company_admin') {
      newActionCardsData.push({
        type: 'follow_up',
        title: 'Would you like to add a follow up?',
        background: '#59981a',
        icon: <ScheduleOutlined />
      });
    }
    if (user?.permissions && user?.permissions.includes('createJob')) {
      newActionCardsData.push(
        {
          type: 'estimate',
          title: 'Would you like to add an estimate?',
          background: '#f6a21e',
          icon: <EditOutlined />
        },
        {
          type: 'job',
          title: 'Would you like to add a work order?',
          background: '#3062d6',
          icon: <CarOutlined />
        }
      );
    }

    setActionCardsData(newActionCardsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.permissions]);
  useEffect(() => {
    dispatch(setFollowUpCustomer({ id: customerData?._id, name: customerData?.first_name }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {!!activeForm.length && (
        <Button className="drop-shadow-2xl" color="inherit" startIcon={<ArrowLeftOutlined />} onClick={handleBack}>
          back
        </Button>
      )}
      {!activeForm.length && (
        <div className="w-full flex sm:flex-row flex-col items-center justify-between gap-4">
          {actionCardsData.map((cardData) => (
            <ActionCards
              title={cardData.title}
              type={cardData.type}
              onClick={(type: string) => handleFormChange(type)}
              background={cardData.background}
              icon={cardData.icon}
            />
          ))}
        </div>
      )}
      <div className="mt-6">{renderForms()}</div>
    </div>
  );
};

const ActionCards = (props: TActionCardProps) => {
  const theme = useTheme();
  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { title, type, onClick = (arg0: string) => {}, icon } = props;
  return (
    <Card
      fullWidth
      variant="outlined"
      component={Button}
      className="flex justify-center items-center cursor-pointer p-6 shadow-inner hover:shadow-lg transition-all ease-in duration-300"
      sx={{
        height: 200,
        '&:hover svg': {
          transform: 'scale(1.5)',
          transition: 'transform 0.3s ease-in-out'
        },
        color: '#fff',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          color: '#fff',
          background: props.background
        },
        background: props.background
      }}
      onClick={() => onClick(type)}
    >
      <div className="w-full">
        <div className="w-full flex justify-center">
          <Icon color="inherit" sx={{ width: 50, height: 50 }}>
            {icon}
          </Icon>
        </div>
        <Typography className="text-center" variant={matchDownSm ? 'h5' : 'h3'} color="inherit">
          {title}
        </Typography>
      </div>
    </Card>
  );
};

export default AddActionPage;
