import { Button, Checkbox, FormControlLabel, FormHelperText, InputLabel, TextField } from '@mui/material';
import { DatePicker, DateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { getIn, useFormik } from 'formik';
import FollowupServicesInstance from 'services/services.followups';
import * as Yup from 'yup';

type TMarkFollowUpNotCompleteProps = {
  data: any;
  onSubmit: () => void;
};

const MarkFollowUpNotComplete = (props: TMarkFollowUpNotCompleteProps) => {
  const { data, onSubmit } = props;

  const handleMarkFollowupNotComplete = async (value: any) => {
    const response = await FollowupServicesInstance.markFollowupNotComplete(data?._id as string, value);
    if (response) {
      onSubmit();
    }
  };

  //-----------------------------------------Formik-----------------------------
  const formik = useFormik({
    initialValues: {
      description: '',
      callback_date: '',
      exclude_callback_time: false,
      note: ''
    },
    validationSchema: Yup.object().shape({
      description: Yup.string().required('This field is required'),
      callback_date: Yup.string().required('This field is required'),
      exclude_callback_time: Yup.boolean().required('This field is required'),
      note: Yup.string().required('This field is required')
    }),

    onSubmit: async (values) => {
      handleMarkFollowupNotComplete(values);
    }
  });

  return (
    <form className="flex flex-col items-start justify-center gap-4 w-full" onSubmit={formik.handleSubmit}>
      <div className="flex flex-col items-start justify-center space-y-2 w-full">
        <InputLabel htmlFor="description" required>
          Description
        </InputLabel>
        <TextField
          name="description"
          id="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          fullWidth
          autoFocus={true}
          multiline
          minRows={3}
          error={Boolean(formik.touched.description && formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
        />
      </div>
      <div className="flex flex-col items-start justify-center space-y-2 w-full">
        <div className="w-full flex items-center justify-between">
          <InputLabel htmlFor="callback_date" required>
            Followup Date
          </InputLabel>
          <FormControlLabel
            value={formik.values.exclude_callback_time}
            name="exclude_callback_time"
            id="exclude_callback_time"
            color="primary"
            checked={formik.values.exclude_callback_time}
            control={
              <Checkbox
                classes={{ root: 'p-0 mr-1' }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                  formik.setFieldValue('exclude_callback_time', checked)
                }
              />
            }
            label={'Exclude Time'}
          />
        </div>

        {formik.values.exclude_callback_time ? (
          <DatePicker
            className="w-full"
            value={formik.values.callback_date ? dayjs(formik.values.callback_date) : null}
            minDate={dayjs(new Date())}
            onChange={(newValue: Dayjs | null) => {
              formik.setFieldValue(
                'callback_date',
                newValue !== null && newValue?.toString() !== 'Invalid Date' ? newValue.toISOString() : ''
              );
            }}
          />
        ) : (
          <DateTimePicker
            className="w-full"
            value={formik.values.callback_date ? dayjs(formik.values.callback_date) : null}
            minDate={dayjs(new Date())}
            onChange={(newValue) => {
              if (newValue?.isValid()) {
                formik.setFieldValue('callback_date', newValue.format('MM/DD/YYYY hh:mm a'));
              }
            }}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock
            }}
          />
        )}
        {!!formik.touched.callback_date && formik.errors.callback_date && (
          <FormHelperText error id="helper-text-callback_date">
            {getIn(formik.errors, 'callback_date')}
          </FormHelperText>
        )}
      </div>
      <div className="flex flex-col items-start justify-center space-y-2 w-full">
        <InputLabel htmlFor="note" required>
          Note
        </InputLabel>
        <TextField
          name="note"
          id="note"
          value={formik.values.note}
          onChange={formik.handleChange}
          fullWidth
          multiline
          minRows={3}
          error={Boolean(formik.touched.note && formik.errors.note)}
          helperText={formik.touched.note && formik.errors.note}
        />
      </div>

      <div className="flex items-center justify-end w-full">
        <Button color="primary" variant="contained" type="submit" size="large">
          Submit
        </Button>
      </div>
    </form>
  );
};

export default MarkFollowUpNotComplete;
