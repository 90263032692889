import { EditOutlined, InfoCircleFilled, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { TimelineDot } from '@mui/lab';
import {
  Box,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import CustomTooltip from 'components/CustomTooltip';
import AddMaterialPopup from 'components/popups/AddMaterialPopup';
import TMaterialData from 'components/tables/interfaces/materialTableInterface';
import moment from 'moment';
import { TDateFilter } from 'pages/Dashboard/types/types.dashboard';
import { IAddMaterialPopup } from 'pages/Settings/types/types.materialPopup';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import MaterialServicesInstance from 'services/services.materials';
import { formateData, randomHexColorCode } from 'utils/common';
import ReportDateFilter from '../components/ReportDateFilter';
import { TMaterialAndSupplies } from '../types/types.report';

const MaterialAndSupplies = ({
  isReportDataFetching,
  selectedFilter,
  setSelectedFilter,
  reportData,
  refetchReportdata
}: {
  isReportDataFetching: boolean;
  selectedFilter: TDateFilter;
  setSelectedFilter: (value: TDateFilter) => void;
  reportData: TMaterialAndSupplies;
  refetchReportdata: (options?: RefetchOptions) => Promise<QueryObserverResult<unknown, Error>>;
}) => {
  //-------------------------constants------------------------
  const theme = useTheme();
  const dateFilter = [
    { label: `This Year ${new Date().getFullYear()}`, value: 'this_year' },
    { label: `Last 10 Days`, value: 'last_ten_days' },
    { label: 'Last 30 Days', value: 'last_thirty_days' },
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: `Last Year ${new Date().getFullYear() - 1}`, value: 'last_year' },
    { label: 'Date Range', value: 'date_range' }
  ];
  const isXs = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const [materialAndSuppliesData, setMaterialAndSuppliesData] = useState<TMaterialAndSupplies>({} as TMaterialAndSupplies);
  const [availableUnits, setAvailableUnits] = useState<{ available_units: number; edit: ConstrainBoolean }[]>([]);

  //---------------------useEffects-------------------
  useEffect(() => {
    if (!isReportDataFetching && Object.keys(reportData).length) {
      setAvailableUnits(() => {
        return reportData.statistics.map((eachData) => {
          return { available_units: eachData.available_units, edit: false };
        });
      });
      setMaterialAndSuppliesData({
        ...reportData,
        statistics: reportData.statistics.map((singleItem) => ({ ...singleItem, color: randomHexColorCode() }))
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData]);
  //--------------------------Handlers-------------------------
  const handleUpdateInventoryData = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, selectedMaterialIndex: number) => {
    const response = await MaterialServicesInstance.updateMaterialUnits(
      reportData.statistics[selectedMaterialIndex].material.id,
      availableUnits[selectedMaterialIndex].available_units
    );
    if (!response) {
      setAvailableUnits((prevData) => {
        const dummyPrevData = { ...prevData };
        dummyPrevData[selectedMaterialIndex].available_units = materialAndSuppliesData.statistics[selectedMaterialIndex].available_units;
        dummyPrevData[selectedMaterialIndex].edit = false;
        return dummyPrevData;
      });
      return null;
    }
    setAvailableUnits((prevData) => {
      const dummyPrevData = { ...prevData };
      dummyPrevData[selectedMaterialIndex].edit = false;
      return dummyPrevData;
    });
    setMaterialAndSuppliesData((prevData) => {
      const dummyPrevData = { ...prevData };
      materialAndSuppliesData.statistics[selectedMaterialIndex].available_units = availableUnits[selectedMaterialIndex].available_units;
      return dummyPrevData;
    });
  };
  const handleInventoryDataChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    materialAndSuppliesIndex: number
  ) => {
    const inputValue = event.target.value as string;
    if (inputValue.charAt(0) === '-') {
      event.target.value = '';
      return null;
    }
    setAvailableUnits((prevData) => {
      const dummyPrevData = { ...prevData };
      dummyPrevData[materialAndSuppliesIndex].available_units = Number(event.target.value);
      return dummyPrevData;
    });
  };
  const [materialDialog, setMaterialDialog] = useState<IAddMaterialPopup>({ action: { open: false, isEditMode: true }, data: null });

  // ---------- Open Close Material Popup-----------
  const handleMaterialPopup = async (id?: string) => {
    let materialData: TMaterialData;
    if (!materialDialog.action.open && !!id) {
      materialData = (await MaterialServicesInstance.getMaterial(id)) as TMaterialData;
    }
    setMaterialDialog((prevState) => ({
      action: {
        open: !prevState.action.open,
        isEditMode: true
      },
      data: !!materialData ? { selectedData: materialData } : null
    }));
  };

  return (
    <div className="grid md:grid-cols-2 gap-3">
      {!!Object.keys(materialAndSuppliesData).length && (
        <>
          {/*----------------------------Materials Table------------------------ */}
          <Card>
            <CardHeader
              title={
                <div className="text-center  font-semibold">
                  {selectedFilter.filter_value === 'this_year' && moment().year()}
                  {selectedFilter.filter_value === 'last_year' && moment().year() - 1}&nbsp; Materials
                </div>
              }
              subheader={
                <Typography className="text-center text-gray-700">{formateData(materialAndSuppliesData.total_amount ?? 0, '$')}</Typography>
              }
            />
            <CardContent>
              <ReportDateFilter selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} dateFilters={dateFilter} />
            </CardContent>

            {!!materialAndSuppliesData.statistics?.length && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {['Name', 'Inventory', 'Price'].map((item) => (
                        <TableCell>{item}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {materialAndSuppliesData.statistics.map((singleItem, materialAndSuppliesIndex) => (
                      <TableRow>
                        <TableCell>
                          {
                            <div
                              className="flex items-center gap-2 cursor-pointer hover:underline"
                              onClick={() => handleMaterialPopup(singleItem.material.id)}
                            >
                              <TimelineDot sx={{ bgcolor: singleItem.color }} />
                              {singleItem.material.name}
                            </div>
                          }
                        </TableCell>
                        <TableCell>
                          <div className="flex space-x-2 items-center">
                            {availableUnits[materialAndSuppliesIndex].edit ? (
                              <Stack direction={'row'} spacing={2}>
                                <TextField
                                  autoFocus
                                  type="number"
                                  variant="standard"
                                  className="w-10"
                                  value={availableUnits[materialAndSuppliesIndex].available_units}
                                  disabled={!availableUnits[materialAndSuppliesIndex].edit}
                                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                    handleInventoryDataChange(event, materialAndSuppliesIndex)
                                  }
                                  onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                                    if (event.key === 'Enter') {
                                      handleUpdateInventoryData(
                                        event as unknown as React.MouseEvent<HTMLButtonElement, MouseEvent>,
                                        materialAndSuppliesIndex
                                      );
                                    }
                                  }}
                                />
                                <ButtonGroup>
                                  <IconButton
                                    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                                      handleUpdateInventoryData(event, materialAndSuppliesIndex)
                                    }
                                  >
                                    <SaveOutlined />
                                  </IconButton>
                                  <IconButton
                                    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                      setAvailableUnits((prevData) => {
                                        const dummyPrevData = { ...prevData };
                                        dummyPrevData[materialAndSuppliesIndex].available_units = singleItem.available_units;
                                        dummyPrevData[materialAndSuppliesIndex].edit = false;
                                        return dummyPrevData;
                                      });
                                    }}
                                  >
                                    <PlusOutlined rotate={45} />
                                  </IconButton>
                                </ButtonGroup>
                              </Stack>
                            ) : (
                              <div className="flex space-x-2 items-center">
                                <Typography>{singleItem.available_units}</Typography>
                                <IconButton
                                  onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                    setAvailableUnits((prevData) => {
                                      const dummyPrevData = { ...prevData };
                                      dummyPrevData[materialAndSuppliesIndex].edit = true;
                                      return dummyPrevData;
                                    });
                                  }}
                                  edge="end"
                                >
                                  <EditOutlined />
                                </IconButton>
                              </div>
                            )}
                            {singleItem.available_units < singleItem.total_units / 2 &&
                              (isXs ? (
                                <CustomTooltip message="Reorder">
                                  <InfoCircleFilled style={{ color: 'red' }} />
                                </CustomTooltip>
                              ) : (
                                <Chip variant="light" color="error" label={'Reorder'} size="small" />
                              ))}
                          </div>
                        </TableCell>

                        <TableCell>{formateData(singleItem.amount ?? 0, '$')}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Card>
          {/*----------------------------Inventory Report------------------------ */}

          {!!materialAndSuppliesData.statistics?.length && (
            <Card className="grid divide-y">
              {materialAndSuppliesData.statistics.map((singleMaterialData, singleMaterialIndex) => {
                return (
                  <CardContent key={singleMaterialIndex} className="grid grid-cols-1 gap-1 sm:grid-cols-4 align-middle items-center">
                    <div className="col-span-1 justify-center">
                      <div className="flex items-start sm:flex-col flex-row sm:space-x-0 space-x-2">
                        <Typography variant="h5" className="font-semibold">
                          {singleMaterialData.material.name}
                        </Typography>
                        {singleMaterialData.available_units < singleMaterialData.total_units / 2 && (
                          <Chip variant="light" color="error" label={'Reorder'} size="small" />
                        )}
                      </div>
                    </div>
                    <div className="col-span-3">
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                          <LinearProgress
                            variant="determinate"
                            className="sm:h-4"
                            value={
                              singleMaterialData.available_units > singleMaterialData.total_units
                                ? 100
                                : (singleMaterialData.available_units * 100) / singleMaterialData.total_units
                            }
                            sx={{
                              color: singleMaterialData.color
                            }}
                            color="inherit"
                          />
                        </Box>
                        <Box sx={{ minWidth: 35 }}>
                          <Typography variant="body2" color="text.secondary">{`${Math.round(
                            singleMaterialData.available_units
                          )}`}</Typography>
                        </Box>
                      </Box>
                    </div>
                  </CardContent>
                );
              })}
            </Card>
          )}
          {/*----------------------------Inventory Pie Chart------------------------ */}

          {!!materialAndSuppliesData.statistics?.length &&
            !materialAndSuppliesData.statistics.every((singleItemData) => singleItemData.available_units === 0) && (
              <Card className="h-fit md:col-span-2">
                <ReactApexChart
                  type="pie"
                  height={isXs ? 200 : isSm ? 300 : isMd ? 400 : isLg ? 500 : 600}
                  options={{
                    title: { text: 'Inventory', align: 'center' },
                    legend: { show: false },
                    xaxis: { offsetX: 10 },
                    labels: materialAndSuppliesData.statistics.map((singleItemData) => singleItemData.material.name),
                    colors: materialAndSuppliesData.statistics.map((singleItemData) => singleItemData.color)
                  }}
                  series={materialAndSuppliesData.statistics.map((singleItemData) => singleItemData.available_units)}
                />
              </Card>
            )}
          {/*----------------------------Supplies And Material Used Pie Chart------------------------ */}

          {/* {!!materialAndSuppliesData.statistics?.length &&
            !materialAndSuppliesData.statistics.every((singleItemData) => singleItemData.total_units === 0) && (
              <Card className="h-fit">
                <ReactApexChart
                  type="pie"
                  height={isXs ? 200 : isSm ? 300 : isMd ? 400 : isLg ? 500 : 600}
                  options={{
                    title: { text: 'Supplies & Material Used', align: 'center' },
                    legend: { show: false },
                    xaxis: { offsetX: 10 },

                    colors: materialAndSuppliesData.statistics.map((singleItemData) => singleItemData.color)
                  }}
                  series={materialAndSuppliesData.statistics.map((singleItemData) => singleItemData.total_units)}
                />
              </Card>
            )} */}
        </>
      )}
      {/* ----- Dialogs ----- */}
      {materialDialog.action.open && <AddMaterialPopup {...materialDialog} onClose={handleMaterialPopup} onSave={refetchReportdata} />}
    </div>
  );
};

export default MaterialAndSupplies;
