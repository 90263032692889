import { Card, CardContent, Typography } from '@mui/material';
import LinearWithLabel from 'components/@extended/progress/LinearWithLabel';
import { TServiceReport } from 'pages/Reports/types/types.report';
import ReactApexChart from 'react-apexcharts';

const ServiceReportGraph = ({ reportData }: { reportData: TServiceReport }) => {
  return (
    <div className="grid grid-cols-1 gap-2">
      <Card className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 px-2 divide-x">
        {reportData.statistics.map((singleJobCategory) => (
          <div className="flex flex-col items-center">
            <ReactApexChart
              height={240}
              options={{
                chart: { id: 'basic-bar', type: 'radialBar' },
                labels: [singleJobCategory.job_category],
                colors: [singleJobCategory.color],
                plotOptions: {
                  radialBar: {
                    track: { margin: 0, strokeWidth: '100' },
                    dataLabels: {
                      name: { show: false },
                      value: { fontSize: '12px', offsetY: 0 }
                    }
                  }
                }
              }}
              type="radialBar"
              series={[
                isFinite((singleJobCategory.amount * 100) / reportData.gross_income)
                  ? Number(((singleJobCategory.amount * 100) / reportData.gross_income).toFixed(2))
                  : 0
              ]}
            />
            <Typography className="drop-shadow-sm break-all pb-5" color={singleJobCategory.color}>
              {singleJobCategory.job_category}
            </Typography>
          </div>
        ))}
      </Card>
      <Card className="grid divide-y">
        <CardContent className="grid divide-y">
          {reportData &&
            reportData.statistics.map((singleJobCategory) => (
              <div className="space-y-2 ">
                {singleJobCategory.services_and_products.map((singleServiceAndProduct) => {
                  return (
                    <CardContent className="grid grid-cols-1 gap-1 sm:grid-cols-4 align-middle items-center">
                      <Typography variant="h5" className="font-semibold">
                        {singleServiceAndProduct.name}
                      </Typography>
                      <div className="col-span-3 ">
                        <LinearWithLabel
                          color="inherit"
                          sx={{ color: singleJobCategory.color }}
                          className="sm:h-4"
                          value={
                            isFinite((singleServiceAndProduct.amount * 100) / singleJobCategory.amount)
                              ? (singleServiceAndProduct.amount * 100) / singleJobCategory.amount
                              : 0
                          }
                        />
                      </div>
                    </CardContent>
                  );
                })}
              </div>
            ))}
        </CardContent>
      </Card>
    </div>
  );
};

export default ServiceReportGraph;
