import moment from 'moment';
import { useCallback } from 'react';
import { useSelector } from 'store';

const useTimezone = () => {
  const settings = useSelector((state) => state.settings);

  const formatDateTime = useCallback(
    (timestamp: string | Date, options?: Intl.DateTimeFormatOptions | undefined) => {
      const date = moment(timestamp);
      const fomatterOptions = options ?? {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      };
      const formatter = new Intl.DateTimeFormat('en-US', { timeZone: settings.generalSettings?.timezone, ...fomatterOptions });
      return formatter.format(date.toDate()).replace(',', ' ');
    },
    [settings]
  );

  return formatDateTime;
};

export default useTimezone;
