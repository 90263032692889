import axiosServices from 'utils/axios';

class TabSummaries {
  getTabSummary = async (module: string, id: string) => {
    try {
      const response = await axiosServices.get(`api/tab-summaries?module=${module}&id=${id}`);

      return response.data.data.tab_summary;
    } catch (error) {}
  };
}

const tabSummariesInstance = new TabSummaries();
export default tabSummariesInstance;
