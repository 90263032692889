// ==============================|| SLICE ||============================== //

import { createSlice } from '@reduxjs/toolkit';

type TCustomerState = {
  companyData: { company_id: string | null };
};

// initial state
const initialState: TCustomerState = {
  companyData: { company_id: null }
};

const registerSlice = createSlice({
  name: 'res',
  initialState,
  reducers: {
    setCompanyData(state, action: { payload: any }) {
      state.companyData = action.payload;
    }
  }
});

export default registerSlice.reducer;

export const { setCompanyData } = registerSlice.actions;
