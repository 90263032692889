import {
  Card,
  CardContent,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow
} from '@mui/material';
import { TDateFilter } from 'pages/Dashboard/types/types.dashboard';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { formateData } from 'utils/common';
import JobReportDateFilter from '../components/JobReportDateFilter';
import { TFieldWorkerJobReport } from '../types/types.report';

const JobsCompletedReport = ({
  selectedFilter,
  setSelectedFilter,
  reportData
}: {
  selectedFilter: TDateFilter;
  setSelectedFilter: React.Dispatch<React.SetStateAction<TDateFilter>>;
  reportData: TFieldWorkerJobReport;
}) => {
  //--------------------------constants--------------------
  const [selectedJobFilter, setSelectedJobFilter] = useState('job_price');
  // const [scheduleJob, setScheduleJob] = useState(true);

  const pastDateFilters = [
    { label: 'Today', value: 'today' },
    { label: `Yesterday`, value: 'yesterday' },
    { label: `Last 10 Days`, value: 'last_ten_days' },
    { label: 'Last 30 Days', value: 'last_thirty_days' },
    { label: 'Last Year', value: 'last_year' },
    { label: 'Date Range', value: 'date_range' }
  ];

  const jobFilter = [
    { label: 'Show the total job price', value: 'job_price' },
    { label: 'Show the total number of jobs', value: 'job_numbers' }
  ];

  useEffect(() => {
    setSelectedFilter((prevData) => {
      return { ...prevData, is_completed: true };
    });
  }, []);

  return (
    <div className="grid sm:grid-cols-1 lg:grid-cols-4 gap-4">
      <Card className="lg:col-span-2">
        <CardContent className="space-y-4">
          <div className="sm:flex">
            <JobReportDateFilter
              dateFilters={pastDateFilters}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              isJobCompleted={true}
            />
          </div>
        </CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {['Name', 'Job Completed', 'Price'].map((item) => (
                  <TableCell>{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {reportData.map((singleItem) => (
                <TableRow>
                  <TableCell className="flex flex-row items-center">{singleItem.name}</TableCell>
                  <TableCell>{singleItem.jobs_completed}</TableCell>
                  <TableCell>{formateData(singleItem.job_price ?? 0, '$')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={12}>&nbsp;</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Card>
      {/*--------------------------------Graph---------------------- */}
      <Card className="lg:col-span-2 justify-center hidden sm:block">
        <CardContent>
          <Select
            className="w-full"
            value={selectedJobFilter}
            onChange={(event: SelectChangeEvent<string>) => setSelectedJobFilter(event.target.value)}
          >
            {jobFilter.map((singleItem) => (
              <MenuItem value={singleItem.value}>{singleItem.label}</MenuItem>
            ))}
          </Select>
        </CardContent>
        <ReactApexChart
          type="bar"
          options={{
            chart: { stacked: false, toolbar: { show: false }, width: '100%' },
            dataLabels: { enabled: false },
            xaxis: {
              categories: [...reportData.map((singleItem) => singleItem.name)]
            },
            stroke: {
              show: true,
              width: 4,
              colors: ['transparent']
            },

            title: { text: 'Field Worker Job ', align: 'center' }
          }}
          series={
            selectedJobFilter === 'job_price'
              ? [
                  {
                    data: [...reportData.map((singleItem) => Number(singleItem.job_price.toFixed(2)))],
                    name: 'Job Price'
                  }
                ]
              : [
                  {
                    data: [...reportData.map((singleItem) => singleItem.jobs_completed)],
                    name: 'Job Completed'
                  }
                ]
          }
        />
      </Card>
    </div>
  );
};

export default JobsCompletedReport;
