import { CloudUploadOutlined, InboxOutlined } from '@ant-design/icons';
import { LoadingButton } from '@mui/lab';
import { Card, CardContent, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import FileUploadServiceInstance from 'services/services.fileUpload';
import MediaServicesInstance from 'services/services.media';
import { TMedia } from 'types/globalTypes/type.media';
import ProductPlaceholder from './cards/skeleton/ProductPlaceholder';
import MediaItemList from './MediaItemList';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const Media = ({ module, customer_id }: { module: string; customer_id?: string }) => {
  //-------------------constants-----------
  const [activeTab, setActiveTab] = useState('media');
  const [mediaData, setMediaData] = useState<{ [date: string]: TMedia[] }>({});

  const location = useLocation();
  const path = location.pathname.split('/');
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);

  const mediaTabs = [
    {
      label: 'Media',
      value: 'media'
    },
    {
      label: 'Proposal',
      value: 'proposal'
    },
    {
      label: 'Invoice',
      value: 'invoice'
    }
  ];
  //-----------------useQuery------------
  const {
    data: allMedia,
    isFetching: isMediaFetching,
    refetch: refetchMedia
  } = useQuery({
    initialData: {},
    queryKey: ['media_data', activeTab],
    queryFn: () => MediaServicesInstance.getMedia(path[1], true, path[3], activeTab),
    enabled: activeTab.length > 0
  });

  //------------------Handlers-----------------
  const addMedia = async (url: string[]) => {
    let jobId, customerId;
    if (module === 'jobs') {
      jobId = path[3];
      customerId = customer_id;
    } else if (module === 'customers') {
      customerId = path[3];
    }
    const response = await MediaServicesInstance.addMedia(url, module, customerId, jobId, activeTab);
    if (response) {
      refetchMedia();
    }
  };
  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setIsImageUploading(true);

      const FilesData = Object.values(event.target.files);
      let urls: string[] = [];
      await Promise.all(
        FilesData.map(async (eachFile) => {
          const response = await FileUploadServiceInstance.uploadFile(eachFile);
          if (response && response.data) {
            urls = [...urls, response.data];
          }
        })
      );
      await addMedia(urls);

      setIsImageUploading(false);
    }
  };
  const handleDelete = async (mediaId: string) => {
    const response = await MediaServicesInstance.deleteMedia(mediaId);
    if (response) {
      refetchMedia();
    }
  };
  //--------------useEffect---------------
  useEffect(() => {
    !!allMedia && setMediaData(allMedia as { [date: string]: TMedia[] });
  }, [allMedia]);

  return (
    <Card className="relative w-full">
      <CardContent className="gap-y-4 w-full">
        <Tabs
          onChange={(event: React.SyntheticEvent, value: any) => {
            setActiveTab(value);
          }}
          value={activeTab}
          variant="fullWidth"
        >
          {mediaTabs.map((singleTab) => (
            <Tab label={singleTab.label} value={singleTab.value} />
          ))}
        </Tabs>
        <div className="w-full flex justify-end mt-2">
          {(activeTab === 'media' || module === 'frequently_used_media') && (
            <LoadingButton
              size="large"
              component="label"
              loading={isImageUploading}
              variant="dashed"
              color="primary"
              startIcon={<CloudUploadOutlined />}
            >
              Upload Media <VisuallyHiddenInput multiple type="file" accept="*/*" onChange={handleFileUpload} />
            </LoadingButton>
          )}
        </div>

        {isMediaFetching ? (
          <ProductPlaceholder />
        ) : !!mediaData && Object.keys(mediaData).length > 0 ? (
          <MediaItemList mediaData={mediaData} module={module} activeTab={activeTab} handleDelete={handleDelete} />
        ) : (
          <div className="w-full h-96 flex flex-col items-center justify-center gap-3">
            <InboxOutlined style={{ height: 20, transform: 'scale(3)', color: 'GrayText' }} />
            <Typography color={'GrayText'}>No Data</Typography>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default Media;
