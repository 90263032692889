import { AxiosRequestConfig } from 'axios';
import { TEstimateOptions } from 'components/tables/interfaces/estimateTableInterface';
import { TJobCompleteData, TUpdateEstimate } from 'components/tables/interfaces/jobTableInterface';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import axiosServices from 'utils/axios';
import {
  IResponseWitEdithEstimates,
  IResponseWithEstimates,
  IResponseWithProposalTemplate,
  TIndexedDBResponse,
  TSendProposalData
} from './types/types.services';
import IndexedDBServices from './services.localDb';
import { storedAPIInIndexedDB } from 'utils/common';
import { indexedDbStoreNames } from 'utils/constants';

class EstimateServices {
  createEstimate = async (estimateData: TJobCompleteData) => {
    try {
      const response: IResponseWithEstimates = await axiosServices.post(`api/job/`, estimateData);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Estimate Created Successfully!`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  editEstimate = async (estimateId: string, EstimateData: TJobCompleteData) => {
    try {
      const response: IResponseWitEdithEstimates = await axiosServices.put(`api/job/update-estimate/${estimateId}`, EstimateData);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Estimate was Updated Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  reAssignEstimator = async (jobId: string, estimateData: Omit<TJobCompleteData, 'state'>) => {
    try {
      const response = await axiosServices.put(`api/job/reassign-estimator/${jobId}`, estimateData);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Estimate was Updated Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  upsertEstimateOptions = async (
    jobId: string,
    estimateOptions: TEstimateOptions[],
    termAndCondition?: string,
    state?: string,
    updateEstimate?: TUpdateEstimate,
    proposalApproveSignature?: string
  ) => {
    try {
      if (!navigator.onLine) {
        await storedAPIInIndexedDB({
          apiUrl: `api/Job/upsert-estimate-options/${jobId}`,
          requestType: 'post',
          data: {
            options: estimateOptions,
            ...(termAndCondition && { terms_and_conditions: termAndCondition }),
            ...(state && { state }),
            ...(updateEstimate && {
              customer_sign_for_estimation_updated: updateEstimate.customer_sign_for_estimation_updated,
              customer_sign_on_estimation: updateEstimate.customer_sign_on_estimation,
              is_customer_present: updateEstimate.is_customer_present
            }),
            ...(!!proposalApproveSignature && !!proposalApproveSignature.length && { proposal_approve_signature: proposalApproveSignature })
          }
        });
        return;
      }

      const response: IResponseWithEstimates = await axiosServices.post(`api/job/upsert-estimate-options/${jobId}`, {
        options: estimateOptions,
        ...(termAndCondition && { terms_and_conditions: termAndCondition }),
        ...(state && { state }),
        ...(updateEstimate && {
          customer_sign_for_estimation_updated: updateEstimate.customer_sign_for_estimation_updated,
          customer_sign_on_estimation: updateEstimate.customer_sign_on_estimation,
          is_customer_present: updateEstimate.is_customer_present
        }),
        ...(!!proposalApproveSignature && !!proposalApproveSignature.length && { proposal_approve_signature: proposalApproveSignature })
      });
      if (response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message:
              !!proposalApproveSignature && !!proposalApproveSignature.length
                ? 'Proposal Accepted Successfully'
                : `Estimate was updated successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  sendProposal = async (sendProposalData: TSendProposalData, jobId: string) => {
    try {
      const response: IResponseWithEstimates = await axiosServices.post(`api/job/send-proposal/${jobId}`, sendProposalData);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Proposal Sent to ${sendProposalData.to}`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  getProposalTemplate = async () => {
    try {
      if (!navigator.onLine) {
        const indexedDBInstance = new IndexedDBServices(indexedDbStoreNames.proposalTemplate);
        const data = (await indexedDBInstance.getAllLocalData()) as TIndexedDBResponse;
        return data.data;
      }

      const response: IResponseWithProposalTemplate = await axiosServices.get('api/template/proposal-template', {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: `Unable to fetch terms and conditions.`,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
    }
  };
  authDeclineEstimateOption = async (companyId: string, jobId: string) => {
    try {
      const response: IResponseWithEstimates = await axiosServices.put(`api/job/estimation-updated/decline/user/${jobId}`, {
        company_id: companyId
      });
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Estimate Declined`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  authAcceptEstimateOption = async (jobId: string) => {
    try {
      const response: IResponseWithEstimates = await axiosServices.put(`api/job/estimation-updated/approve/user/${jobId}`);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Estimate Accepted`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  noAuthDeclineEstimateOption = async (companyId: string, jobId: string) => {
    try {
      const response: IResponseWithEstimates = await axiosServices.post(`api/job/estimation-updated/decline/${jobId}`, {
        company_id: companyId
      });
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Estimate Declined`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  noAuthAcceptEstimateOption = async (companyId: string, jobId: string, customer_sign_for_estimation_updated: string) => {
    try {
      const response: IResponseWithEstimates = await axiosServices.post(`api/job/estimation-updated/approve/${jobId}`, {
        company_id: companyId,
        customer_sign_for_estimation_updated: customer_sign_for_estimation_updated
      });
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Estimate Accepted`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
}

const EstimateServicesInstance = new EstimateServices();
export default EstimateServicesInstance;
