import { CheckCircleFilled, EditFilled, MailFilled, MessageFilled, PhoneFilled, PlusCircleFilled } from '@ant-design/icons'; // Ant Design Icons
import {
  Avatar,
  Badge,
  ButtonGroup,
  Card,
  CircularProgress,
  ClickAwayListener,
  Collapse,
  IconButton,
  Popper,
  Typography
} from '@mui/material';
import CustomTooltip from 'components/CustomTooltip';
import AddEmailorSms from 'components/popups/AddEmailorSms';
import AddUserPopup from 'components/popups/AddUserPopup';
import UniversalDialog from 'components/popups/UniversalDialog';
import { TJobAction } from 'components/tables/interfaces/jobTableInterface';
import useTimezone from 'hooks/useTimezone';
import { useState } from 'react';
import UserServicesInstance from 'services/services.users';
import { jobStates } from 'utils/constants';
import { TCustomerAddEmailSMSPopup } from '../../pages/customers/types/type.customersView';
import { IAddUserPopup } from '../../pages/Settings/types/types.userPopup';
import { useSelector } from 'store';

const JobDateColumn = ({ jobAction, state, customerData }: { jobAction?: TJobAction[]; state?: string; customerData?: any }) => {
  const settingsState = useSelector((state) => state.settings.generalSettings);
  const formatDateTime = useTimezone();

  const [workerProfilePopup, setWorkerProfilePopup] = useState<{ anchor: null | HTMLElement; data?: any }>({ anchor: null });

  const [loadingUser, setLoadingUser] = useState(false);

  const [addEmailOrSmsPopup, setAddEmailOrSmsPopup] = useState<TCustomerAddEmailSMSPopup>({
    action: { open: false, isEmail: true, showJobName: true },
    data: {}
  });

  const closeAssignedUserActions = () => {
    setWorkerProfilePopup(() => ({ anchor: null }));
  };

  const [addUserPopup, setAddUserPopup] = useState<IAddUserPopup>({
    action: { open: false, size: 'fullScreen', isEditMode: false },
    data: null
  });

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>, workerData: any) => {
    setWorkerProfilePopup((prev) => {
      if (prev.anchor === event.currentTarget) return { anchor: null };
      return { anchor: event.currentTarget, data: workerData };
    });
  };

  const handleEditUser = async (openState: boolean, workerData?: any) => {
    try {
      let result = null;
      if (openState && workerData) result = await singleUserData(workerData?.id);

      setAddUserPopup({ action: { open: openState, isEditMode: true }, data: { selectedData: result } });
    } catch (error) {
      console.log('error on handle add user function ..', error);
    }
  };

  //  Get User Data based on id
  async function singleUserData(userId: string) {
    setLoadingUser(() => true);
    try {
      const response = await UserServicesInstance.getUser(userId);
      setLoadingUser(() => false);
      return response;
    } catch (error) {
      setLoadingUser(() => false);
      console.error('Error getting user data:', error);
    }
  }

  // ---------------------- Open Email Popup ----------------------
  const handleAddEmailorSmsPopup = () => {
    setAddEmailOrSmsPopup({
      action: { open: false, isEmail: true, showJobName: true },
      data: {}
    });
  };

  // ---------------------- Send Email Functionality ----------------------

  async function handleEmail() {
    const fetchedWorkerData = await singleUserData(workerProfilePopup.data?.id);

    setAddEmailOrSmsPopup({
      action: { open: true, isEmail: true, showJobName: true },
      job: { id: customerData?._id, name: customerData?.job_name },
      data: {
        id: fetchedWorkerData?._id,
        name: `${fetchedWorkerData?.first_name} ${fetchedWorkerData?.last_name}`,
        emails: [fetchedWorkerData.email]
      }
    });
  }

  // ---------------------- Send SMS Functionality -------------------------
  async function handleSms() {
    const fetchedWorkerData = await singleUserData(workerProfilePopup.data?.id);
    const workerPhone = { phone: fetchedWorkerData.phone, phone_country_code: fetchedWorkerData.phone_country_code };

    setAddEmailOrSmsPopup({
      action: { open: true, isEmail: false, showJobName: false },
      job: { id: customerData?._id, name: customerData?.job_name },
      data: {
        id: fetchedWorkerData?._id,
        name: `${fetchedWorkerData?.first_name} ${fetchedWorkerData?.last_name}`,
        phones: [workerPhone]
      }
    });
  }

  // ---------------------- Call User Functionality ----------------------
  async function handleCallUser() {
    const fetchedWorkerData = await singleUserData(workerProfilePopup.data?.id);
    const workerPhone = { phone: fetchedWorkerData.phone, phone_country_code: fetchedWorkerData.phone_country_code };

    window.location.href = `tel:${workerPhone}`;
  }

  const renderDateColumnContent = () => {
    switch (state) {
      case jobStates.REASSIGNED_WORKER:
        return (
          <div className="space-y-1">
            {jobAction?.map(
              (singleJobAction) =>
                singleJobAction?.is_job_complete !== true && (
                  <div className="w-full flex items-center justify-between gap-2" key={singleJobAction.id}>
                    <Typography className="col-span-1">
                      {!!singleJobAction?.job_reminder?.scheduled_date_and_time
                        ? formatDateTime(singleJobAction?.job_reminder?.scheduled_date_and_time, {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            ...(!singleJobAction?.job_reminder.exclude_time && {
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: settingsState?.time_format === '12hrs'
                            })
                          })
                        : 'N/A'}
                    </Typography>

                    <CustomTooltip message={singleJobAction?.field_worker?.name}>
                      <IconButton
                        classes={{ root: 'z-[2] rounded-full' }}
                        onClick={(e) => handleAvatarClick(e, singleJobAction?.field_worker)}
                      >
                        {singleJobAction?.field_worker?.profile_picture?.length ? (
                          <Avatar
                            variant="circular"
                            alt="profile user"
                            src={singleJobAction?.field_worker?.profile_picture}
                            sx={{ width: 32, height: 32 }}
                          />
                        ) : (
                          <Avatar variant="circular" alt="profile user" sx={{ width: 32, height: 32 }}>
                            {singleJobAction?.field_worker?.name[0]}
                          </Avatar>
                        )}
                      </IconButton>
                    </CustomTooltip>
                  </div>
                )
            ) ?? <>N/A</>}
          </div>
        );
      case jobStates.ESTIMATION:
        return (
          <div className="w-full flex items-center justify-between">
            <Typography className="">
              {customerData.is_estimate_require_date && customerData.estimate_reminder?.scheduled_date_and_time
                ? formatDateTime(customerData.estimate_reminder.scheduled_date_and_time, {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    ...(!customerData.estimate_reminder.exclude_time && {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: settingsState?.time_format === '12hrs'
                    })
                  })
                : 'N/A'}
            </Typography>

            {!!customerData?.estimator && (
              <CustomTooltip message={customerData?.estimator?.name ?? ''}>
                <Badge classes={{ badge: 'z-[10]' }} overlap="circular" anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                  <IconButton classes={{ root: 'z-[2] rounded-full' }} onClick={(e) => handleAvatarClick(e, customerData?.estimator)}>
                    {customerData?.estimator?.profile_picture?.length ? (
                      <Avatar
                        variant="circular"
                        alt="profile user"
                        src={customerData?.estimator?.profile_picture}
                        sx={{ width: 32, height: 32 }}
                      />
                    ) : (
                      <Avatar variant="circular" alt="profile user" sx={{ width: 32, height: 32 }}>
                        {customerData?.estimator?.name?.[0]}
                      </Avatar>
                    )}
                  </IconButton>
                </Badge>
              </CustomTooltip>
            )}
          </div>
        );
      case jobStates.NEED_REVIEW:
        return (
          <div className="w-full flex items-center justify-between">
            <Typography className="">
              {customerData.is_estimate_require_date && customerData.estimate_reminder?.scheduled_date_and_time
                ? formatDateTime(customerData.estimate_reminder.scheduled_date_and_time, {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    ...(!customerData.estimate_reminder.exclude_time && {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: settingsState?.time_format === '12hrs'
                    })
                  })
                : 'N/A'}
            </Typography>

            {!!customerData?.estimator && (
              <CustomTooltip message={customerData?.estimator?.name ?? ''}>
                <Badge classes={{ badge: 'z-[10]' }} overlap="circular" anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                  <IconButton classes={{ root: 'z-[2] rounded-full' }} onClick={(e) => handleAvatarClick(e, customerData?.estimator)}>
                    {customerData?.estimator?.profile_picture?.length ? (
                      <Avatar
                        variant="circular"
                        alt="profile user"
                        src={customerData?.estimator?.profile_picture}
                        sx={{ width: 32, height: 32 }}
                      />
                    ) : (
                      <Avatar variant="circular" alt="profile user" sx={{ width: 32, height: 32 }}>
                        {customerData?.estimator?.name?.[0]}
                      </Avatar>
                    )}
                  </IconButton>
                </Badge>
              </CustomTooltip>
            )}
          </div>
        );
      case jobStates.REASSIGNED_ESTIMATOR:
        return (
          <div className="w-full flex items-center justify-between">
            <Typography className="col-span-1">
              {customerData.is_estimate_require_date && customerData.estimate_reminder?.scheduled_date_and_time
                ? formatDateTime(customerData.estimate_reminder.scheduled_date_and_time, {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    ...(!customerData.estimate_reminder.exclude_time && {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: settingsState?.time_format === '12hrs'
                    })
                  })
                : 'N/A'}
            </Typography>

            <CustomTooltip message={customerData.estimator?.name}>
              <Badge classes={{ badge: 'z-[10]' }} overlap="circular" anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <IconButton
                  classes={{ root: 'z-[2] rounded-full rounded-full' }}
                  onClick={(e) => handleAvatarClick(e, customerData?.estimator)}
                >
                  {customerData?.estimator?.profile_picture?.length ? (
                    <Avatar
                      variant="circular"
                      alt="profile user"
                      src={customerData?.estimator?.profile_picture}
                      sx={{ width: 32, height: 32 }}
                    />
                  ) : (
                    <Avatar variant="circular" alt="profile user" sx={{ width: 32, height: 32 }}>
                      {customerData?.estimator?.name[0]}
                    </Avatar>
                  )}
                </IconButton>
              </Badge>
            </CustomTooltip>
          </div>
        );
      default:
        return (
          <div style={{ zIndex: 110 }} className="space-y-1">
            {jobAction?.map((singleJobAction) => (
              <div className="w-full flex items-center justify-between" key={singleJobAction.id}>
                <Typography className="sm:col-span-2">
                  {!!singleJobAction?.job_reminder?.scheduled_date_and_time
                    ? singleJobAction.job_reminder.exclude_time
                      ? new Date(singleJobAction.job_reminder.scheduled_date_and_time).toLocaleDateString()
                      : formatDateTime(singleJobAction.job_reminder.scheduled_date_and_time)
                    : 'N/A'}
                </Typography>

                <CustomTooltip message={singleJobAction?.field_worker?.name}>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    classes={{ root: '!p-0', badge: 'z-[10]' }}
                    badgeContent={
                      <IconButton
                        classes={{ root: 'max-w-max z-[10]' }}
                        color={singleJobAction.is_job_complete === true ? 'success' : 'error'}
                        className={`!p-0 ${singleJobAction.is_job_complete === true ? 'text-green-500' : 'text-red-500'}`}
                        size="small"
                        hidden={singleJobAction.is_job_complete === null}
                      >
                        {singleJobAction.is_job_complete === true ? (
                          <CheckCircleFilled className="!p-0" />
                        ) : (
                          <PlusCircleFilled rotate={45} />
                        )}
                      </IconButton>
                    }
                  >
                    <IconButton
                      classes={{ root: 'z-[2] rounded-full' }}
                      onClick={(e) => handleAvatarClick(e, singleJobAction?.field_worker)}
                    >
                      {singleJobAction?.field_worker?.profile_picture?.length ? (
                        <Avatar
                          variant="circular"
                          alt="profile user"
                          src={singleJobAction?.field_worker?.profile_picture}
                          sx={{ width: 32, height: 32 }}
                        />
                      ) : (
                        <Avatar variant="circular" alt="profile user" sx={{ width: 32, height: 32 }}>
                          {singleJobAction?.field_worker?.name[0]}
                        </Avatar>
                      )}
                    </IconButton>
                  </Badge>
                </CustomTooltip>
              </div>
            ))}
          </div>
        );
    }
  };

  return (
    <>
      {renderDateColumnContent()}

      {!!workerProfilePopup.anchor && (
        <Popper
          className="min-w-max max-w-full"
          sx={{
            zIndex: 1,
            '& .MuiPaper-root': {
              borderRadius: 0,
              borderTopLeftRadius: 100,
              borderBottomLeftRadius: 100,
              borderEndEndRadius: 25,
              borderStartEndRadius: 25,
              boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.25)'
            }
          }}
          key={customerData._id}
          open={workerProfilePopup.anchor !== null}
          anchorEl={workerProfilePopup.anchor}
          placement={'right-start'}
          transition
          modifiers={[
            {
              name: 'offset',
              options: {
                // [horizontal offset, vertical offset]
                offset: [-8, -42]
              }
            }
          ]}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={closeAssignedUserActions}>
              <Collapse unmountOnExit appear {...TransitionProps} timeout={100} orientation="horizontal">
                <Card className="pl-12 pr-3 py-2 rounded-l-full bg-white">
                  {loadingUser ? (
                    <div className="flex gap-2 items-center h-[34px] pl-2">
                      <CircularProgress size={20} color="secondary" />
                      <Typography color={'GrayText'}>Getting user info...</Typography>
                    </div>
                  ) : (
                    <ButtonGroup>
                      <IconButton onClick={handleCallUser} color="success">
                        <PhoneFilled />
                      </IconButton>
                      {/* Email Icon Item */}
                      <IconButton color="error" onClick={handleEmail}>
                        <MailFilled />
                      </IconButton>
                      {/* Sms Icon Item */}
                      <IconButton color="warning" onClick={() => handleSms()}>
                        <MessageFilled />
                      </IconButton>
                      {/* Edit User Icon Item */}
                      <IconButton color="primary" onClick={() => handleEditUser(true, workerProfilePopup.data)}>
                        <EditFilled />
                      </IconButton>
                    </ButtonGroup>
                  )}
                </Card>
              </Collapse>
            </ClickAwayListener>
          )}
        </Popper>
      )}
      {/* Edit User Pop up */}
      {addUserPopup.action.open && (
        <AddUserPopup {...addUserPopup} onClose={() => handleEditUser(false)} onSave={() => handleEditUser(false)} />
      )}
      {/* Edit EMAIL OR SMS */}
      {addEmailOrSmsPopup.action.open && (
        <UniversalDialog
          action={{ open: addEmailOrSmsPopup.action.open, fullWidth: true }}
          onClose={() => handleAddEmailorSmsPopup()}
          hasPrimaryButton={false}
        >
          <AddEmailorSms {...addEmailOrSmsPopup} onClose={() => handleAddEmailorSmsPopup()} />
        </UniversalDialog>
      )}
    </>
  );
};

export default JobDateColumn;
