import { Card, CardContent, CardHeader, List, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import { TDateFilter } from 'pages/Dashboard/types/types.dashboard';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { formateData, randomHexColorCode, snakeCaseToTitleCase } from 'utils/common';
import ReportDateFilter from '../components/ReportDateFilter';

const AccountsReceivableReport = ({
  selectedFilter,
  setSelectedFilter,
  reportData
}: {
  selectedFilter: TDateFilter;
  setSelectedFilter: (value: TDateFilter) => void;
  reportData: { [key: string]: number };
}) => {
  //--------------------------constants--------------------

  const dateFilter = [
    { label: `Today`, value: 'today' },
    { label: `Yesterday`, value: 'yesterday' },
    { label: `Last 10 Days`, value: 'last_ten_days' },
    { label: 'Last 30 Days', value: 'last_thirty_days' },
    { label: `Last Year`, value: 'last_year' },
    { label: 'Date Range', value: 'date_range' }
  ];
  const [accountReceivableData, setAccountReceivableData] = useState<{ label: string; value: number; color: string }[]>([]);

  useEffect(() => {
    const dummyData = Object.keys(reportData).map((singleItem) => {
      return { label: singleItem, value: reportData[singleItem], color: randomHexColorCode() };
    });
    setAccountReceivableData(dummyData);
  }, [reportData]);

  return (
    <div className="grid sm:grid-cols-1 lg:grid-cols-3 gap-4">
      <Card>
        <CardContent className="space-y-4">
          <ReportDateFilter dateFilters={dateFilter} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />

          <CardHeader
            title={
              <div className="flex flex-row justify-between font-semibold">
                <Typography className="text-lg font-bold">Payment Type</Typography>
                <Typography className="text-lg font-bold">Amount</Typography>
              </div>
            }
          />
          {accountReceivableData.map((singleItem) => (
            <List>
              <ListItemButton>
                <ListItemText
                  primary={
                    <Stack
                      sx={{ paddingLeft: '0.5rem', borderLeft: `5px solid ${singleItem.color}` }}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <span>{snakeCaseToTitleCase(singleItem.label)}</span>
                      <Typography>{formateData(singleItem.value ?? 0, '$')}</Typography>
                    </Stack>
                  }
                />
              </ListItemButton>
            </List>
          ))}
        </CardContent>
      </Card>
      <Card className="lg:col-span-2 justify-center hidden sm:block">
        <ReactApexChart
          type="bar"
          options={{
            chart: { stacked: true, toolbar: { show: false } },
            dataLabels: { enabled: false },
            title: { text: 'Accounts Receivable', align: 'center' }
          }}
          series={[
            {
              name: '',
              data: accountReceivableData.map((singleItem) => ({
                x: snakeCaseToTitleCase(singleItem.label),
                y: singleItem.value.toFixed(2),
                fillColor: singleItem.color
              }))
            }
          ]}
        />
      </Card>
    </div>
  );
};

export default AccountsReceivableReport;
