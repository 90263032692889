import { DeleteOutlined, EditOutlined, PictureFilled } from '@ant-design/icons';
import {
  ButtonGroup,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Avatar from 'components/@extended/Avatar';
import MainCard from 'components/MainCard';
import RenderMeasurementsUnits from 'components/ShowMeasurementUnits';
import { EditJobItem } from 'components/forms/JobOptionItem/EditJobItemForm';
import DeleteConfirmationPoup from 'components/popups/DeleteConfirmationPopup';
import UniversalDialog from 'components/popups/UniversalDialog';
import _ from 'lodash';
import { TOptionTabs } from 'pages/jobs/types/types.estimateOptions';
import { useEffect, useState } from 'react';
import { IDeletePopup } from 'types/globalTypes/type.deleteDialog';
import { formateData } from 'utils/common';
import { TJobItem } from './interfaces/jobTableInterface';

type TCategoryItemDisplayTableProps = {
  data: TOptionTabs;
  taxRate?: number;
  handleDeleteCategory: (u: string) => void;
  handleDeleteItem: (itemId: string) => void;
  handleEditItem: (values: TJobItem) => void;
  isAppointmentJob?: boolean;
};

type ItemsWithCategory = {
  job_category: { name: string; id: string };
};

type GroupedItems = {
  [key: string]: ItemsWithCategory[];
};

const CategoryItemDisplayTable = (props: TCategoryItemDisplayTableProps) => {
  const categoryItemData = props.data;
  const defaultTaxRate = props.taxRate;

  type TItemsGroupData = { category_name: string; category_id: string; items: ItemsWithCategory[] };

  const [itemsGroupData, setItemsGroupData] = useState<TItemsGroupData[]>();

  const [itemData, setItemData] = useState<TJobItem | null>(null);

  const [deleteDialog, setDeleteDialog] = useState<IDeletePopup>({ action: { open: false }, data: { subjectToBeDeleted: '' } });

  const formatItems = (allItems: TJobItem[]) => {
    const groupedItems = allItems.reduce((currentItems: GroupedItems, eachItem: ItemsWithCategory) => {
      const categoryName = `${eachItem.job_category.name}:${eachItem.job_category.id}`;
      if (!currentItems[categoryName]) {
        currentItems[categoryName] = [];
      }
      currentItems[categoryName].push(eachItem);
      return currentItems;
    }, {});

    return Object.keys(groupedItems).map((key) => ({
      category_name: key.split(':')[0],
      category_id: key.split(':')[1],
      items: groupedItems[key]
    }));
  };

  useEffect(() => {
    const allItems = categoryItemData.data.items;

    if (allItems) {
      setItemsGroupData(formatItems(allItems));
      return;
    }
    setItemsGroupData([]);
  }, [categoryItemData.data]);

  const columns = [
    { title: 'Image' },
    { title: 'Description' },
    { title: 'Units' },
    { title: 'Taxable' },
    { title: 'Item Price ($)' },
    { title: 'Action' }
  ];

  const calculateCategoryItemsTotalPrice = (eachItemsGroup: any[]) => {
    return eachItemsGroup.reduce((total, currentItem) => {
      return total + currentItem.price;
    }, 0);
  };

  const handleEditJobItem = (eachItem: TJobItem) => {
    setItemData(() => _.cloneDeep(eachItem));
  };

  const closeEditTeamsDialog = (updatedItem: TJobItem) => {
    setItemData(null);
    props.handleEditItem(updatedItem);
  };

  function handleDisplayOnProposal(
    oldData: TJobItem & {
      isTaxable: boolean;
    }
  ) {
    const newData = _.cloneDeep(oldData);
    newData.show_units_on_proposal = !newData.show_units_on_proposal;
    props.handleEditItem(newData);
  }

  function handleDeleteCategoryClick(data?: any) {
    setDeleteDialog(() => ({
      action: { open: true, maxWidth: 'xs' },
      data: { subjectToBeDeleted: data?.category_name, selectedData: { _id: data.category_id, first_name: 'category' } }
    }));
  }

  function handleDeleteItemClick(data?: any) {
    setDeleteDialog(() => ({
      action: { open: true, maxWidth: 'xs' },
      data: { subjectToBeDeleted: data?.description, selectedData: { _id: data.id, first_name: 'item' } }
    }));
  }

  function closeDeleteDialog() {
    setDeleteDialog(() => ({ action: { open: false, maxWidth: 'xs' } }));
  }

  function handleDelete(id: string, type: string) {
    if (type === 'item') {
      props.handleDeleteItem(id);
    } else {
      props.handleDeleteCategory(id);
    }
    closeDeleteDialog();
  }

  return (
    <div className="w-full">
      {itemsGroupData &&
        itemsGroupData.map(
          (eachItemsGroup, eachItemsGroupIndex) =>
            eachItemsGroup.category_name !== '' && (
              <MainCard
                sx={{ mb: 2 }}
                contentClassName="!p-0 !w-full"
                title={eachItemsGroup.category_name}
                secondary={
                  <IconButton
                    color="error"
                    key={eachItemsGroupIndex}
                    onClick={(e) => {
                      handleDeleteCategoryClick(eachItemsGroup);
                    }}
                  >
                    <DeleteOutlined />
                  </IconButton>
                }
                key={eachItemsGroup.category_id}
              >
                <TableContainer className="w-full">
                  <Table className="!w-full">
                    <TableHead>
                      <TableRow>
                        {columns.map((eachColumn) => (
                          <TableCell key={eachColumn.title}>{eachColumn.title}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!!eachItemsGroup.items.length &&
                        eachItemsGroup.items.map((eachItemFromArray: unknown, eachItemIndex: number) => {
                          const eachItem = eachItemFromArray as TJobItem & { isTaxable: boolean };
                          return (
                            <TableRow key={eachItemIndex} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell className="max-w-min">
                                {eachItem?.image ? (
                                  <Avatar variant="rounded" alt={eachItem.service_and_product.name} size="xl" src={eachItem?.image} />
                                ) : (
                                  <Avatar variant="rounded" size="xl" sx={{ border: '1px solid lightgrey', backgroundColor: 'lightgrey' }}>
                                    <PictureFilled className="!text-gray-50 text-4xl" />
                                  </Avatar>
                                )}
                              </TableCell>
                              <TableCell>{eachItem.description}</TableCell>
                              <TableCell className="flex flex-col items-start">
                                <RenderMeasurementsUnits
                                  measurementData={eachItem.measurements}
                                  measure={eachItem.measure_type}
                                  showUnitsOnProposal={eachItem.show_units_on_proposal}
                                />
                                <FormControlLabel
                                  control={
                                    <Switch
                                      size="small"
                                      checked={eachItem.show_units_on_proposal}
                                      onChange={() => handleDisplayOnProposal(eachItem)}
                                    />
                                  }
                                  label={<Typography className="text-xs min-w-max">Display Units On Proposal</Typography>}
                                />
                              </TableCell>
                              <TableCell className="min-w-max">
                                <Typography className="sm:text-base text-xs min-w-max">
                                  {eachItem?.taxable && defaultTaxRate ? (
                                    <strong>&#x2713; ( {formateData(eachItem?.taxable ?? 0, '$')})</strong>
                                  ) : (
                                    <span>-</span>
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell className="min-w-max">
                                <Typography className="sm:text-base text-xs min-w-max">{formateData(eachItem.price ?? 0, '$')}</Typography>
                              </TableCell>

                              <TableCell>
                                <ButtonGroup variant="text">
                                  <IconButton
                                    onClick={() => {
                                      handleEditJobItem(eachItem);
                                    }}
                                    color="primary"
                                  >
                                    <EditOutlined />
                                  </IconButton>
                                  <IconButton key={eachItemIndex} onClick={() => handleDeleteItemClick(eachItem)} color="error">
                                    <DeleteOutlined />
                                  </IconButton>
                                </ButtonGroup>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={5} align="right">
                          Total Items Price
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="h6">
                            <strong>{formateData(calculateCategoryItemsTotalPrice(eachItemsGroup.items) ?? 0, '$')}</strong>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </MainCard>
            )
        )}

      {itemData !== null && (
        <UniversalDialog
          title={itemData.job_category.name}
          action={{ open: itemData !== null, maxWidth: 'sm', fullScreen: false, fullWidth: true }}
          primaryButonTitle="Edit Job Item"
          hasSecondaryButton={false}
          hasPrimaryButton={false}
          onClose={() => setItemData(null)}
        >
          <EditJobItem
            jobItemData={itemData}
            onEditJobItem={closeEditTeamsDialog}
            taxRate={props.taxRate}
            isAppointmentJob={props.isAppointmentJob}
          />
        </UniversalDialog>
      )}

      {deleteDialog.action.open && <DeleteConfirmationPoup {...deleteDialog} onClose={closeDeleteDialog} onDelete={handleDelete} />}
    </div>
  );
};

export default CategoryItemDisplayTable;
