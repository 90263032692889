import { EditOutlined, StopOutlined } from '@ant-design/icons';
import { DateSelectArg, DayCellContentArg, DayHeaderContentArg, EventContentArg, EventSourceInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import { Box, Dialog, Grid, Typography, useMediaQuery } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { PopupTransition } from 'components/@extended/Transitions';
import CustomTooltip from 'components/CustomTooltip';
import UniversalDialog from 'components/popups/UniversalDialog';
import CalendarSkeleton from 'components/skeleton/CalendarSkeleton';
import dayjs from 'dayjs';
import moment from 'moment';
import { TAppointmentConfig } from 'pages/Settings/types/types.autoAppointment';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import AutoAppointmentServiceInstance from 'services/services.autoAppointment';
import { dispatch, useSelector } from 'store';
import { selectEvent, setDate, toggleModal, updateCalendarView } from 'store/reducers/customReducers/slice.calendar';
import { openSnackbar } from 'store/reducers/snackbar';
import { formateData, getAllWeekendDates } from 'utils/common';
import { TCalendar } from '../types/types.calendar';
import AddEventFrom from './AddEventForm';
import CalendarStyled from './CalendarStyled';
import Toolbar from './Toolbar';

const Calendar = ({
  titleData,
  appointmentConfigData,
  calendarAllData,
  isCalendarDataFetched,
  refetchCalendarData
}: {
  titleData: { [key: string]: { total_job_count: number; total_job_price: number } };
  appointmentConfigData?: TAppointmentConfig;
  calendarAllData: unknown[];
  isCalendarDataFetched: boolean;
  refetchCalendarData: () => void;
}) => {
  //------------------constants--------------------
  const theme = useTheme();
  const navigate = useNavigate();
  const matchDownSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [blackoutDays, setBlackoutDays] = useState<string[]>([]);
  const [weekendPopup, setWeekendPopup] = useState<{ open: boolean; type: string; date: string; weekend_type?: number }>({
    open: false,
    type: '',
    date: ''
  });
  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [dateHovered, setDateHovered] = useState('');
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const { calendar_tab: selectedTab } = useParams();
  const { calendarView, date, events, selectedEventDate, isModalOpen } = useSelector((state) => state.calendarOption);
  const calendarRef = useRef<FullCalendar>(null);
  //----------------Handlers-------------------------
  const handleDateToday = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      dispatch(setDate(calendarApi.getDate()));
    }
  };

  const handleViewChange = (newView: string) => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.changeView(newView);
      dispatch(updateCalendarView(newView));
    }
  };

  const handleDatePrev = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      dispatch(setDate(calendarApi.getDate()));
    }
  };

  const handleDateNext = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.next();
      dispatch(setDate(calendarApi.getDate()));
    }
  };

  const handleModal = () => {
    dispatch(toggleModal());
  };

  const renderEventContent = (eventInfo: EventContentArg) => {
    switch (eventInfo.event.extendedProps?.contentType) {
      case 'title':
        return (
          <CustomTooltip message={eventInfo.event.title}>
            <div className="bg-transparent justify-start flex align-middle items-center text-ellipsis overflow-hidden">
              {eventInfo.event.extendedProps.icon}
              &nbsp;&nbsp;
              {eventInfo.event.title}
            </div>
          </CustomTooltip>
        );
      case 'body':
        switch (selectedTab) {
          case 'estimate_calendar':
          case 'job_calendar':
          case 'archive_calendar': {
            console.log('estimate_calendar Date :', dayjs(eventInfo.event.start).format('YYYY-MM-DD'));

            return (
              <CustomTooltip
                message={`${eventInfo.event.extendedProps.user?.name ?? ''} - ${eventInfo.event.extendedProps.count} - ${
                  eventInfo.event.extendedProps.price
                }`}
              >
                <div className="text-ellipsis overflow-hidden flex align-middle items-center">
                  {eventInfo.event.extendedProps.icon}
                  &nbsp;&nbsp;
                  {!!eventInfo.event.extendedProps.user?.name?.length && (
                    <Typography
                      sx={{ ':hover': { color: theme.palette.primary.main }, cursor: 'pointer' }}
                      className="font-extrabold"
                      onClick={() =>
                        navigate(
                          `/jobs?type=${selectedTab}&date=${dayjs(eventInfo.event.start).format('YYYY-MM-DD')}&user_id=${
                            eventInfo.event.extendedProps.user.id
                          }`
                        )
                      }
                    >
                      {eventInfo.event.extendedProps.user?.name} &nbsp;-&nbsp;
                    </Typography>
                  )}
                  {eventInfo.event.extendedProps.count}&nbsp;-&nbsp;{eventInfo.event.extendedProps.price}
                </div>
              </CustomTooltip>
            );
          }
          case 'town_calendar':
            return (
              <CustomTooltip message={`${eventInfo.event.extendedProps.city} - ${eventInfo.event.extendedProps.count} - Jobs`}>
                <div className="flex align-middle items-center text-ellipsis overflow-hidden">
                  {eventInfo.event.extendedProps.icon}
                  &nbsp;&nbsp;
                  {!!eventInfo.event.extendedProps.city?.length && (
                    <Typography
                      sx={{ ':hover': { color: theme.palette.primary.main }, cursor: 'pointer' }}
                      className="font-extrabold"
                      onClick={() =>
                        navigate(
                          `/jobs?type=${selectedTab}&date=${moment(eventInfo.event.start).format('YYYY-MM-DD')}&work_city=${
                            eventInfo.event.extendedProps.city
                          }`
                        )
                      }
                    >
                      {eventInfo.event.extendedProps.city}
                      &nbsp;-&nbsp;
                    </Typography>
                  )}
                  {eventInfo.event.extendedProps.count}&nbsp;-&nbsp;Jobs
                </div>
              </CustomTooltip>
            );
          case 'area_calendar': {
            if (!!eventInfo.event.extendedProps.area_name && eventInfo.event.extendedProps.area_name?.length > 0) {
              return (
                <CustomTooltip message={`${eventInfo.event.extendedProps.area_name} - ${eventInfo.event.extendedProps.count} - Jobs`}>
                  <div className="flex align-middle items-center text-ellipsis overflow-hidden">
                    {eventInfo.event.extendedProps.icon}
                    &nbsp;&nbsp;
                    <Typography
                      sx={{ ':hover': { color: theme.palette.primary.main }, cursor: 'pointer' }}
                      className="font-extrabold"
                      onClick={() =>
                        navigate(
                          `/jobs?type=${selectedTab}&date=${moment(eventInfo.event.start).format('YYYY-MM-DD')}&work_city=${
                            eventInfo.event.extendedProps.area_name
                          }`
                        )
                      }
                    >
                      {eventInfo.event.extendedProps.area_name}
                      &nbsp;-&nbsp;
                    </Typography>
                    {eventInfo.event.extendedProps.count}&nbsp;-&nbsp;Jobs
                  </div>
                </CustomTooltip>
              );
            }
            break;
          }
          case 'area_availabilities':
            return (
              <CustomTooltip message={eventInfo.event.extendedProps.data}>
                <div className="flex align-middle items-center text-ellipsis overflow-hidden">
                  {eventInfo.event.extendedProps.icon}
                  &nbsp;&nbsp;
                  {!!eventInfo.event.extendedProps.data?.length && (
                    <Typography className="font-extrabold">{eventInfo.event.extendedProps.data}</Typography>
                  )}
                </div>
              </CustomTooltip>
            );
        }
        break;
      case 'note':
        return (
          <CustomTooltip message={eventInfo.event.title}>
            <div className="flex align-middle items-center ">
              {eventInfo.event.extendedProps.icon}
              &nbsp;&nbsp;
              <Typography className="text-ellipsis overflow-hidden">{eventInfo.event.title}</Typography>
            </div>
          </CustomTooltip>
        );
    }
  };
  const dayCellClassNames = (arg: DayCellContentArg) => {
    const date = moment(arg.date).format('YYYY-MM-DD');
    if ((calendarAllData as unknown as TCalendar[])?.some((value) => moment(value?.date).format('YYYY-MM-DD') === date))
      return `day-${date}`;

    return '';
  };
  const handlerDayCellHeaderContent = (arg: DayCellContentArg) => {
    const renderDayContent = () => {
      switch (selectedTab) {
        case 'area_availabilities':
          return (
            appointmentConfigData?.blackout_days?.includes(moment(arg.date).format('YYYY-MM-DD')) ||
            !availableDates.includes(moment(arg.date).format('YYYY-MM-DD'))
          );
        case 'blackout_days': {
          return blackoutDays.includes(moment(arg.date).format('YYYY-MM-DD'));
        }
        default:
          return (calendarAllData as unknown as TCalendar[])?.find(
            (singleDate) => moment(arg.date).isSame(singleDate.date) && singleDate.blackout_day === true
          );
      }
    };
    const currentDate = moment(arg.date).format('YYYY-MM-DD');

    return (
      <div
        onMouseOver={() => {
          setDateHovered(currentDate);
        }}
        className={`${renderDayContent() ? 'text-gray-500' : 'text-black'}`}
      >
        {/* <div className="flex justify-between align-top items-start"> */}
        <Grid container>
          <Grid item xs={7} className=" flex justify-end">
            <Typography className={`text-xl font-semibold ${renderDayContent() ? 'line-through' : ''}`}>{arg.date.getDate()}</Typography>
          </Grid>
          <Grid item xs={4} className="flex justify-end items-start align-top">
            <EditOutlined
              onClick={() => dispatch(selectEvent(moment(arg.date).format('YYYY-MM-DD')))}
              className="cursor-pointer text-blue-600"
              hidden={isSmDown ? false : currentDate !== dateHovered}
            />
          </Grid>
        </Grid>

        {Object.keys(titleData).includes(currentDate) && (
          <Typography className="text-xs text-center">
            {titleData[currentDate].total_job_count} - {formateData(titleData[currentDate].total_job_price ?? 0, '$')}
          </Typography>
        )}
      </div>
    );
  };
  const handlerDayHeaderContent = (arg: DayHeaderContentArg) => {
    const renderDayContent = () => {
      switch (selectedTab) {
        case 'job_calendar':
        case 'estimate_calendar':
          return (
            <EditOutlined
              onClick={() => {
                dispatch(selectEvent(moment(arg.date).format('YYYY-MM-DD')));
              }}
              className="cursor-pointer p-1"
            />
          );
        case 'blackout_days': {
          const formatedDate = moment(arg.date).format('YYYY-MM-DD');
          return blackoutDays.includes(formatedDate) && <StopOutlined />;
        }
        default:
          return <></>;
      }
    };
    return (
      <div>
        {calendarView === 'listWeek' ? (
          <div className="flex justify-between">
            <span className="text-md font-semibold">{moment(arg.date).format('dddd')}</span>
            {renderDayContent()}
          </div>
        ) : (
          <div>{moment(arg.date).format('dddd').substring(0, 3)}</div>
        )}
      </div>
    );
  };
  const handleValidRange = (nowDate: Date) => {
    return { start: moment().format('YYYY-01-01'), end: moment(nowDate).add(2, 'year').format('YYYY-01-01') };
  };
  const disableDates = (arg: DateSelectArg) => {
    return blackoutDays.includes(moment(arg.start).format('YYYY-MM-DD'));
  };
  const handleBlackoutDateClick = (arg: DateClickArg) => {
    const date = moment(arg.date).format('YYYY-MM-DD');
    if (selectedTab === 'blackout_days') {
      if (moment(date) < moment()) {
        dispatch(
          openSnackbar({
            open: true,
            message: 'You can only blackout date in future',
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: true
          })
        );
        return;
      }

      if ([0, 6].includes(moment(date).weekday())) {
        setWeekendPopup({
          open: true,
          type: blackoutDays.includes(date) ? 'remove' : 'add',
          weekend_type: moment(date).weekday(),
          date: date
        });
        return;
      }

      blackoutDays.includes(date)
        ? setBlackoutDays((prevData) => prevData.filter((singleBlackout) => singleBlackout !== date))
        : setBlackoutDays([...blackoutDays, date]);
    }
  };
  const handleSaveBlackoutDay = async () => {
    if (!!appointmentConfigData && !!Object.keys(appointmentConfigData).length && appointmentConfigData?._id) {
      const { area, schedule, scheduling_limit } = appointmentConfigData;

      const response = await AutoAppointmentServiceInstance.editAppointmentConfig(appointmentConfigData?._id, {
        area,
        schedule,
        scheduling_limit,
        blackout_days: blackoutDays
      });
      if (response) {
        refetchCalendarData();
        window.location.reload();
      }
    }
  };
  const handleCloseWeekendBlackoutPopup = () => setWeekendPopup({ open: false, type: '', date: '' });
  const handleWeekendBlackoutDays = (markAllWeekdayBlackout: boolean) => {
    if (markAllWeekdayBlackout) {
      const allWeekend = weekendPopup.weekend_type !== undefined ? getAllWeekendDates(weekendPopup.weekend_type) : ([] as string[]);
      weekendPopup.type === 'remove'
        ? setBlackoutDays((prevData) => prevData.filter((singleBlackout) => !allWeekend.includes(singleBlackout)))
        : setBlackoutDays([...blackoutDays, ...allWeekend]);
      handleCloseWeekendBlackoutPopup();
      return;
    }
    weekendPopup.type === 'remove'
      ? setBlackoutDays((prevData) => prevData.filter((singleBlackout) => singleBlackout !== weekendPopup.date))
      : setBlackoutDays([...blackoutDays, weekendPopup.date]);
    handleCloseWeekendBlackoutPopup();
  };
  //-----------------------useEffects----------------
  useEffect(() => {
    const newView = matchDownSM ? 'listWeek' : 'dayGridMonth';
    dispatch(updateCalendarView(newView));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      const newView = matchDownSM ? 'listWeek' : 'dayGridMonth';
      calendarApi.changeView(newView);
      dispatch(updateCalendarView(newView));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownSM]);
  useEffect(() => {
    if (isCalendarDataFetched && !!calendarAllData) {
      switch (selectedTab) {
        case 'blackout_days':
          setBlackoutDays(calendarAllData as unknown as string[]);
          break;
        case 'area_availabilities':
          setAvailableDates(
            (calendarAllData as TCalendar[])
              .filter((eachAreaAvailabilityData) => !eachAreaAvailabilityData.blackout_day)
              .map((areaAvailabilityData) => moment(areaAvailabilityData.date).format('YYYY-MM-DD'))
          );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCalendarDataFetched, calendarAllData]);

  return (
    <Box sx={{ position: 'relative' }}>
      {isCalendarDataFetched ? (
        <CalendarStyled calendarAllData={calendarAllData}>
          <Toolbar
            handleSaveBlackoutDay={handleSaveBlackoutDay}
            view={calendarView}
            onClickNext={handleDateNext}
            onClickPrev={handleDatePrev}
            onClickToday={handleDateToday}
            onChangeView={handleViewChange}
          />

          <FullCalendar
            weekends
            firstDay={1}
            validRange={handleValidRange}
            editable={false}
            droppable={false}
            selectable={false}
            eventOrder="data"
            events={events as EventSourceInput}
            ref={calendarRef}
            rerenderDelay={10}
            initialDate={date}
            initialView={calendarView}
            dayMaxEventRows={2}
            eventDisplay="block"
            headerToolbar={false}
            eventContent={renderEventContent}
            height={'auto'}
            dateClick={handleBlackoutDateClick}
            dayHeaderContent={handlerDayHeaderContent}
            dayCellClassNames={dayCellClassNames}
            dayCellContent={handlerDayCellHeaderContent}
            selectConstraint={disableDates}
            plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
          />
        </CalendarStyled>
      ) : (
        <CalendarSkeleton />
      )}
      {isModalOpen && !!selectedEventDate && !!selectedEventDate?.length && (
        <Dialog
          maxWidth="sm"
          TransitionComponent={PopupTransition}
          fullWidth
          onClose={handleModal}
          open={isModalOpen}
          sx={{ '& .MuiDialog-paper': { p: 0 } }}
        >
          <AddEventFrom onCancel={handleModal} refetchCalendarData={refetchCalendarData} />
        </Dialog>
      )}
      {weekendPopup.open && (
        <UniversalDialog
          action={{ open: weekendPopup.open, fullWidth: true }}
          onClose={handleCloseWeekendBlackoutPopup}
          primaryButonTitle="Yes"
          hasSecondaryButton
          secondaryButonTitle="No"
          handleSecondaryClick={() => handleWeekendBlackoutDays(false)}
          onSave={() => handleWeekendBlackoutDays(true)}
        >
          <Typography>
            {weekendPopup.type === 'remove'
              ? `Do you want remove all ${weekendPopup.weekend_type === 6 ? 'saturday' : 'sunday'}(s) from blackout`
              : `Do you want blackout all ${weekendPopup.weekend_type === 6 ? 'saturday' : 'sunday'}(s)`}
          </Typography>
        </UniversalDialog>
      )}
    </Box>
  );
};

export default Calendar;
