import { IconButton, Typography, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import CustomTooltip from 'components/CustomTooltip';
import { ISearch } from 'components/filters/SearchFilter';
import RenderStatusPreviewIcons from 'components/RenderStatusPreviewIcons';
import CustomDataTable, { rowsPerPageOptions } from 'components/tables/CustomDataTable';
import useAuth from 'hooks/useAuth';
import useTimezone from 'hooks/useTimezone';
import { useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import JobServicesInstance from 'services/services.jobs';
import { TUserInvoice } from 'services/types/types.services';
import { useSelector } from 'store';
import { formateData, formatPhoneNumber } from 'utils/common';

const InvoicePage = () => {
  //--------------------constants------------
  const location = useLocation();
  const navigate = useNavigate();

  const defaultSearch = {
    search: [
      [
        {
          field_name: 'status',
          field_value: 1,
          operator: 'exactmatch'
        }
      ]
    ]
  };
  const { user } = useAuth();
  const theme = useTheme();
  const formatDateTime = useTimezone();
  const settingsState = useSelector((state) => state.settings.generalSettings);
  const primaryColor = `${theme.palette.primary.main}`;
  const [searchData] = useState<ISearch>(defaultSearch);
  const [paginationData, setPaginationData] = useState({ page: 0, rowsPerPage: rowsPerPageOptions[0] });
  //------------------------useQuery--------------------

  const { data: allInvoices, isLoading: isInvoicesFetching } = useQuery({
    queryKey: ['invoices', paginationData, searchData, location.search],
    queryFn: () => {
      const filter_type = location.search?.split('?')[1]?.split('&')[0]?.split('=')[1] ?? undefined;
      const filter_field = location.search?.split('?')[1]?.split('&')[1]?.split('=')[1] ?? undefined;
      const searchFilter =
        searchData.search[0][0].field_value === 3
          ? {
              search: [
                [
                  { field_name: 'status', field_value: 2, operator: 'exactmatch' },
                  { field_name: 'status', field_value: 1, operator: 'exactmatch' }
                ]
              ]
            }
          : searchData;
      return JobServicesInstance.getInvoices(paginationData, searchFilter, filter_type, filter_field);
    },
    enabled: user?.permissions && user?.permissions.includes('getInvoices'),
    refetchOnWindowFocus: true,
    networkMode: 'always'
  });

  //------------------------columns--------------------

  const columns = useMemo<ColumnDef<TUserInvoice>[]>(
    () => [
      {
        id: 'customer_name',
        cell: ({ row }) => {
          return (
            <div className="flex w-full justify-between">
              <Typography
                sx={{
                  '&:hover': {
                    color: primaryColor
                  }
                }}
                className={`cursor-pointer break-words`}
                component={Link}
                to={`/customers/view/${row.original?.customer?.id}/contact`}
              >
                {row.original?.customer?.name}
              </Typography>
              {!!row.original.followup_count && (
                <CustomTooltip message={row.original.followup_count}>
                  <IconButton
                    size="small"
                    sx={{
                      backgroundColor: `${theme.palette.error.main} !important`,
                      fontSize: '12px',
                      padding: '1px'
                    }}
                    className=" text-white rounded-2xl w-5 h-5"
                    onClick={(event) => {
                      event.stopPropagation();
                      navigate(`/jobs/view/${row.original?.job?.id}/job_status`);
                    }}
                  >
                    {row.original.followup_count}
                  </IconButton>
                </CustomTooltip>
              )}
            </div>
          );
        },
        header: () => <span>Customer Name</span>
      },
      {
        id: 'job_name',
        cell: ({ row }) => {
          return (
            <Typography
              sx={{
                '&:hover': {
                  color: primaryColor
                }
              }}
              className={`cursor-pointer`}
              component={Link}
              to={`/jobs/view/${row.original?.job?.id}/job_status`}
            >
              {row.original?.job?.name}
            </Typography>
          );
        },
        header: () => <span>Job Name</span>
      },
      {
        id: 'phone_number',
        cell: ({ row }) => (
          <a
            href={`tel:+${row.original.customer?.phones[0].phone_country_code}${row.original.customer?.phones[0].phone}${
              row.original.customer?.phones[0].phone_extension ? `,${row.original.customer?.phones[0].phone_extension}` : ''
            }`}
          >
            <div>{row.original.customer?.phones[0].phone ? formatPhoneNumber(row.original.customer?.phones[0].phone) : '-'}</div>
          </a>
        ),
        header: () => <span>Phone Number</span>
      },
      {
        accessorFn: (row) => <span style={{ color: '#017BFE' }}>{formateData(row?.total_amount ?? 0, '$')}</span>,
        id: 'invoice_total',
        cell: (info) => info.getValue(),
        header: () => <span>Invoice Total</span>
      },
      {
        accessorFn: (row) => <span style={{ color: '#DE404D' }}>{formateData(row?.balance_due ?? 0, '$')}</span>,
        id: 'balance_due',
        cell: (info) => info.getValue(),
        header: () => <span>Balance Due</span>
      },
      {
        id: 'invoice_date',
        cell: ({ row }) => (
          <div className="text-sm text-gray-500">
            {formatDateTime(row.original?.created_at, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: settingsState?.time_format === '12hrs'
            })}
          </div>
        ),
        header: () => <span>Invoice Date</span>
      },
      {
        id: 'status_preview',
        header: () => (
          <div className="flex w-40">
            <div className="ml-3 w-full flex justify-start space-x-9">
              <span>E</span>
              <span>P</span>
              <span>W</span>
              <span>I</span>
            </div>
          </div>
        ),
        cell: ({ row }) => {
          return (
            <div className="flex flex-col items-center p-0">
              <div className="w-full flex justify-start space-x-3">
                <RenderStatusPreviewIcons
                  jobAction={row.original.job_action ?? []}
                  jobState={row.original?.state as string}
                  status={row.original.status!}
                  previousStates={row.original.previous_states}
                  jobId={row.original.job.id}
                  proposalSentDate={row.original?.last_proposal_sent}
                  invoiceSentDate={row.original?.last_invoice_sent}
                />
              </div>
            </div>
          );
        }
      }
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  //---------handlers-------------
  const handleChangePagination = (page: number, rowsPerPage: number) => {
    setPaginationData({ rowsPerPage, page });
  };

  return (
    <>
      <div className="gap-2 ">
        <CustomDataTable
          columns={columns}
          data={allInvoices?.invoices || []}
          onPaginationChange={handleChangePagination}
          count={allInvoices?.count}
          isDataLoading={isInvoicesFetching}
        />
      </div>
    </>
  );
};

export default InvoicePage;
