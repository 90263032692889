import { LoadingButton } from '@mui/lab';
import { AppBar, Dialog, DialogActions, DialogContent, Slide, Stack, TextField, Toolbar, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import useAuth from 'hooks/useAuth';
import React, { forwardRef, useEffect, useState } from 'react';
import PunchingServicesInstance, { TInactivityReasonData } from 'services/services.punching';
import { useSelector } from 'store';
import { getAddressFromCoordinates, getCurrentLocation } from 'utils/common';

const Transition = forwardRef(function Transition(props: TransitionProps & { children: React.ReactElement }, ref: React.Ref<unknown>) {
  return <Slide direction="down" ref={ref} {...props} />;
});

type TInactivityReasonPopupProps = { open: boolean; onSuccess?: () => void };

const InactivityReasonPopup = (props: TInactivityReasonPopupProps) => {
  const loggedInUser = useAuth();
  const punching = useSelector((state) => state.punching.punchingDetails);
  const [reasonText, setReasonText] = useState<string>('');
  const [secondsRemaining, setSecondsRemaining] = useState<number>(60);
  const [isLoggedOut, setIsLoggedOut] = useState<boolean>(false);
  const [isApiLoading, setIsApiLoading] = useState(false);

  useEffect(() => {
    if (!props.open) return;

    const interval = setInterval(() => {
      setSecondsRemaining((seconds) => {
        if (seconds <= 1) {
          clearInterval(interval);
          handleLogout();
          return 0;
        }
        return seconds - 1;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [props.open]);

  const handleLogout = () => {
    setIsLoggedOut(true);

    localStorage.removeItem('seconds_remaining');
    loggedInUser.logout(true, loggedInUser.user?.rate_info?.punch_type);
  };

  const handlePrimaryClick = async () => {
    setIsApiLoading(true);
    if (reasonText.trim().length) {
      if (punching?.todaysPunch?._id) {
        const location = await getCurrentLocation();
        const address = await getAddressFromCoordinates(Number(location.lat), Number(location.long));
        const punchData: TInactivityReasonData = {
          time: new Date().toISOString(),
          location: location,
          address: address,
          reason: reasonText
        };

        await PunchingServicesInstance.addInactivityReason(punching.todaysPunch._id, punchData);
      }
      setIsApiLoading(false);
      props.onSuccess?.();
    }
  };

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setReasonText(event.target.value);
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <Dialog open={props.open} TransitionComponent={Transition} fullWidth maxWidth="xs">
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography variant="h4">User Inactivity Reason</Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Stack gap={2}>
          <Typography variant="h6">
            Please enter a reason within <strong>{formatTime(secondsRemaining)}</strong>. If no reason is provided, you will be
            automatically logged out.
          </Typography>
          <TextField
            autoFocus
            label="Reason"
            fullWidth
            multiline
            minRows={3}
            maxRows={3}
            value={reasonText}
            onChange={handleReasonChange}
            disabled={isLoggedOut}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={isApiLoading}
          onClick={handlePrimaryClick}
          variant="contained"
          disabled={!reasonText.trim().length || isLoggedOut}
        >
          Continue
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default InactivityReasonPopup;
