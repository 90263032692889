import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { findLastIndex } from 'lodash';
// import moment from 'moment';
import PunchingServicesInstance from 'services/services.punching';

type TPunchingDetailsClockIn = {
  clock_in?: {
    time: string;
    location: string;
  };
  clock_out?: {
    start_time: string;
    location: string;
    end_time: string;
  };
  breaks: {
    start_time: string;
    location: number;
    end_time: string;
  }[];
};

export type TJobsPunchDetails = {
  id: string;
  name: string;
  start: {
    time: string;
    specified_time?: string;
    location?: { lat: string; long: string };
    address?: string;
  };
  end?: {
    time: string;
    specified_time?: string;
    location?: { lat: string; long: string };
    address?: string;
  };
};

export type TPunchData = {
  _id: string;
  company_id: string;
  status: number;
  user: {
    id: string;
    name: string;
  };
  __v?: number;
  created_at: string;
  date: string;
  notes: string[];
  punching_details: TPunchingDetailsClockIn[];
  updated_at: string;
  jobs: TJobsPunchDetails[];
};

export type TPunchProps = {
  isClockedIn: boolean;
  clockedInAt: string | null;
  clockedOutAt: string | null;
  isPunchLoading: boolean;
  punchingDetails: { allPunches: TPunchData[]; todaysPunch?: TPunchData } | null;
};

// initial state
const initialState: TPunchProps = {
  punchingDetails: null,
  isClockedIn: false,
  clockedInAt: null,
  clockedOutAt: null,
  isPunchLoading: true
};

// ==============================|| SLICE - PUNCH ||============================== //

export const getPunchDetails = createAsyncThunk('punch', async () => {
  const punchResponse = await PunchingServicesInstance.allPunches();
  const allPunches: TPunchData[] = punchResponse?.data.data;

  const todaysPunch = allPunches.find((eachData) => new Date(eachData.date).getDate() === new Date().getDate());
  const punchData = {
    allPunches,
    todaysPunch
  };

  return punchData;
});

const checkForCompleteDayPunch = (punchingDetails: TPunchingDetailsClockIn[]): boolean => {
  const lastIndex = findLastIndex(punchingDetails);
  return !!punchingDetails[lastIndex].clock_in && !punchingDetails[lastIndex].clock_out;
};

const punch = createSlice({
  name: 'punch',
  initialState,
  reducers: {
    clockIn(state, action) {
      state.isClockedIn = true;
      state.clockedInAt = action.payload;
      localStorage.setItem('clock_in_at', action.payload);
    },
    clockOut(state, action) {
      state.isClockedIn = false;
      state.clockedOutAt = action.payload;
      localStorage.removeItem('clock_in_at');
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPunchDetails.fulfilled, (state, action) => {
      state.punchingDetails = action.payload;
      state.isPunchLoading = false;
      const todaysPunchingDetails = action.payload.todaysPunch?.punching_details;
      if (todaysPunchingDetails) {
        state.isClockedIn = checkForCompleteDayPunch(todaysPunchingDetails);

        const clockedInTime = action.payload.todaysPunch?.punching_details.find((eachDeatil) => eachDeatil.clock_in)?.clock_in;
        if (clockedInTime) state.clockedInAt = new Date(clockedInTime?.time).toISOString();
      }
    });
    builder.addCase(getPunchDetails.pending, (state, action) => {
      state.isPunchLoading = true;
    });

    builder.addCase(getPunchDetails.rejected, (state, action) => {
      state.isPunchLoading = false;
    });
  }
});

export default punch.reducer;

export const { clockIn, clockOut } = punch.actions;
