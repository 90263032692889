import { EditOutlined, PictureFilled } from '@ant-design/icons';
import {
  Avatar,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { Stack } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import ProductPlaceholder from 'components/cards/skeleton/ProductPlaceholder';
import { TJobItem } from 'components/tables/interfaces/jobTableInterface';
import { useFormik } from 'formik';
import { TAddServicesAndProduct } from 'pages/Settings/types/types.categoriesProductAndServicesTab';
import { ChangeEvent, useEffect, useState } from 'react';
import CategoriesProductAndServicesInstance from 'services/services.categoriesProductAndServices';
import { formatCostInDecimal } from 'utils/common';
import * as Yup from 'yup';
import ItemsMeasureUnitFields from './ItemsMeasureUnitFields';
import { TItemsMeasurementFieldsOnChangeArg } from './types.jobItems';

export const EditJobItem = (props: {
  taxRate?: number;
  onEditJobItem: (values: TJobItem) => void;
  jobItemData: TJobItem;
  isAppointmentJob?: boolean;
}) => {
  //--------------------Constants---------------------
  const { onEditJobItem, jobItemData } = props;

  const [showPriceInput, setShowPriceInput] = useState(false);
  const [serviceAndProduct, setServiceAndProduct] = useState<TAddServicesAndProduct>({} as TAddServicesAndProduct);
  const [isItemChanged, setIsItemChanged] = useState(false);
  //------------------------------useQuery----------------------------------

  const {
    data: allServiceAndProduct,
    isFetched: isServiceAndPRoductDataFetched,
    isFetching: isServiceAndProductDataFetching
  } = useQuery({
    queryKey: ['job_products'],
    queryFn: () => CategoriesProductAndServicesInstance.getServiceAndProductByJobCategory(jobItemData.job_category.id),
    networkMode: 'always'
  });
  //--------------------------formik------------------------------
  const formik = useFormik({
    initialValues: {} as TJobItem,
    validationSchema: Yup.object().shape({}),
    onSubmit: async (values: TJobItem) => {
      if (values.price === null) {
        values.price = 0;
      }
      onEditJobItem(values);
    }
  });

  //--------------------------Handlers------------------------------

  const handlePriceEdit = () => {
    setShowPriceInput(true);
  };

  const handlePriceBlur = () => {
    setShowPriceInput(false);
  };

  const handlePriceChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (/^\d*\.?\d{0,2}$/.test(event.target.value)) {
      formik.setFieldValue('price', event.target.value ? Number(formatCostInDecimal(event.target.value)) : (null as unknown as number));
      formik.setFieldValue(
        'taxable',
        serviceAndProduct?.tax ? Number(formatCostInDecimal(`${calculateTax(Number(event.target.value))}`)) : 0
      );
    }
  };

  const handleDisplayOnProposal = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('show_units_on_proposal', event.target.checked);
  };
  const calculateTax = (price: number) => {
    if (props?.taxRate !== undefined) {
      const taxableAmount = (price * props?.taxRate) / 100;
      return Number(taxableAmount.toFixed(2));
    }
    return 0;
  };

  const handleCalculatedPrice = (data: TItemsMeasurementFieldsOnChangeArg) => {
    let { price: totalItemsPrice, units: totalUnits, measurement_data } = data;

    totalItemsPrice = ![null, undefined].includes(jobItemData?.price as any) ? jobItemData.price : totalItemsPrice;

    formik.setFieldValue('price', totalItemsPrice);
    formik.setFieldValue('units', `${totalUnits}`);
    formik.setFieldValue('measurements', measurement_data);
    formik.setFieldValue('taxable', serviceAndProduct?.tax ? calculateTax(totalItemsPrice) : 0);

    const selectedItem = allServiceAndProduct?.find((singleItem) => singleItem._id === formik.values.service_and_product.id);
    if (selectedItem)
      formik.setFieldValue(
        'worker_commission',
        selectedItem?.worker_commission_per_unit * (totalItemsPrice / selectedItem?.customer_cost_per_unit)
      );
  };
  const handleItemChange = (event: SelectChangeEvent<string>) => {
    const selectedServiceAndProduct = allServiceAndProduct?.find(
      (singleServiceAndProduct) => singleServiceAndProduct._id === event.target.value
    );
    if (selectedServiceAndProduct) {
      setServiceAndProduct(selectedServiceAndProduct);
      setIsItemChanged((prev) => !prev);
      formik.setFieldValue('service_and_product', { id: selectedServiceAndProduct._id, name: selectedServiceAndProduct.item_name });
      formik.setFieldValue('job_category', {
        id: selectedServiceAndProduct.job_category.id,
        name: selectedServiceAndProduct.job_category.name
      });
      formik.setFieldValue('image', selectedServiceAndProduct.job_image);
      formik.setFieldValue('description', selectedServiceAndProduct.proposal_description);
      formik.setFieldValue('item_details', selectedServiceAndProduct.item_details);
      formik.setFieldValue('measure_type', selectedServiceAndProduct.measure);
    }
  };
  //-------------------------useEffects------------------------------

  useEffect(() => {
    if (!!jobItemData) {
      formik.setValues(jobItemData);
      formik.setFieldValue('price', jobItemData.price);
      if (isServiceAndPRoductDataFetched && !!allServiceAndProduct && !!allServiceAndProduct.length) {
        const selectedServiceAndProduct = allServiceAndProduct.find(
          (singleServiceAndProduct: TAddServicesAndProduct) => singleServiceAndProduct._id === jobItemData.service_and_product.id
        );

        setServiceAndProduct(selectedServiceAndProduct ?? ({} as TAddServicesAndProduct));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobItemData, isServiceAndPRoductDataFetched]);

  return !!Object.keys(formik.values).length && Object.keys(serviceAndProduct).length > 0 ? (
    <Grid container spacing={4} component={'form'} onSubmit={formik.handleSubmit}>
      {/*--------------------------------- Profile Picture*--------------------------------*/}
      <Grid item xs={12} className="w-full flex justify-center my-4">
        <IconButton disableRipple>
          {formik.values.image ? (
            <Avatar sx={{ border: formik.values.image && 1, width: 75, height: 75 }} variant="rounded" src={formik.values.image} />
          ) : (
            <Avatar sx={{ border: formik.values.image && 1, width: 75, height: 75 }} variant="rounded">
              <PictureFilled />
            </Avatar>
          )}
        </IconButton>
      </Grid>
      {/*--------------------------------- Job Item*--------------------------------*/}
      {!!allServiceAndProduct && (
        <Grid item xs={12} className="space-y-1">
          <InputLabel htmlFor={formik.values.service_and_product.name}>Job Item*</InputLabel>

          <Select value={serviceAndProduct._id} onChange={handleItemChange} fullWidth>
            {isServiceAndProductDataFetching ? (
              <MenuItem disabled aria-disabled>
                <CircularProgress size={20} /> Loading
              </MenuItem>
            ) : (
              allServiceAndProduct.map((singleServiceAndProduct) => (
                <MenuItem value={singleServiceAndProduct._id}>{singleServiceAndProduct.item_name}</MenuItem>
              ))
            )}
          </Select>
        </Grid>
      )}
      {/*--------------------------------- Description*--------------------------------*/}
      <Grid item xs={12} className="space-y-1">
        <InputLabel htmlFor={formik.values.description}>Description</InputLabel>
        <TextField multiline rows={2} value={formik.values.description} onChange={formik.handleChange} name="description" fullWidth />
      </Grid>
      {/*--------------------------------- Measurment Type*--------------------------------*/}
      <Grid item xs={12} className="space-y-1">
        <InputLabel htmlFor={`measurment_type`}>
          <div>
            {formik.values.measure_type ? formik.values.measure_type.replace(/_/g, ' ') : 'Units'}*
            <FormControlLabel
              control={<Switch size="small" checked={formik.values.show_units_on_proposal} onChange={handleDisplayOnProposal} />}
              className="px-6"
              label={<Typography className="text-xs">Display Units On Proposal</Typography>}
            />
          </div>
        </InputLabel>

        <ItemsMeasureUnitFields
          key={formik.values.measure_type}
          measuringType={formik.values.measure_type}
          measurementData={formik.values.measurements}
          itemPrice={serviceAndProduct?.customer_cost_per_unit}
          onChange={handleCalculatedPrice}
          isMultipleItemsAllowed={props.isAppointmentJob ? true : false}
          isItemChanged={isItemChanged}
        />
      </Grid>
      {/*--------------------------------- Taxable*--------------------------------*/}
      <Grid item xs={12} sm={6} className=" flex flex-col w-full justify-center">
        <InputLabel htmlFor={'taxable'}>
          <Typography className="py-2">Taxable</Typography>
        </InputLabel>

        <Typography className="break-all text-md">{serviceAndProduct?.tax ? '$ ' + formik.values.taxable : '-'}</Typography>
      </Grid>
      {/*--------------------------------- Price*--------------------------------*/}
      <Grid item xs={12} sm={6}>
        <>
          <Stack className="flex flex-row justify-start w-full items-center">
            <InputLabel htmlFor={'price'}>Price</InputLabel>
            <IconButton color="primary" onClick={handlePriceEdit}>
              <EditOutlined />
            </IconButton>
          </Stack>

          {!showPriceInput ? (
            <Typography className="break-all text-md">$ {formik.values.price}</Typography>
          ) : (
            <TextField
              InputProps={{ startAdornment: <Typography>$</Typography> }}
              inputMode="decimal"
              type="number"
              autoFocus={true}
              variant="outlined"
              sx={{ padding: 0 }}
              onBlur={handlePriceBlur}
              onChange={handlePriceChange}
              value={formik.values.price}
            />
          )}
        </>
      </Grid>

      <Grid item xs={12} className="flex flex-row w-full items-end justify-end">
        <Button
          type="submit"
          variant="contained"
          disabled={
            formik.values.service_and_product.id.length === 0 ||
            (formik.values.measurements === null || formik.values.measurements?.length ? !formik.values.measurements[0].length : true)
          }
        >
          Save
        </Button>
      </Grid>
    </Grid>
  ) : (
    <ProductPlaceholder />
  );
};
