import { SelectOutlined } from '@ant-design/icons';
import { Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material';
import LinearWithLabel from 'components/@extended/progress/LinearWithLabel';
import CustomTooltip from 'components/CustomTooltip';
import moment from 'moment';
import { TDateFilter } from 'pages/Dashboard/types/types.dashboard';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useNavigate } from 'react-router';
import { formateData, randomHexColorCode, snakeCaseToTitleCase } from 'utils/common';
import ReportDateFilter from '../components/ReportDateFilter';
import { TExpenseReport } from '../types/types.report';

const ExpenseReport = ({
  selectedFilter,
  setSelectedFilter,
  reportData
}: {
  selectedFilter: TDateFilter;
  setSelectedFilter: (value: TDateFilter) => void;
  reportData: TExpenseReport;
}) => {
  //--------------------constants--------------------
  const navigate = useNavigate();
  const dateFilter = [
    { label: `This Year ${new Date().getFullYear()}`, value: 'this_year' },
    { label: 'Last 10 days', value: 'last_ten_days' },
    { label: 'Last 30 days', value: 'last_thirty_days' },
    { label: `Last Year ${new Date().getFullYear() - 1}`, value: 'last_year' },
    { label: 'Date Range', value: 'date_range' }
  ];
  const [seriesData, setSeriesData] = useState<{ name: string; data: number[] }[]>([
    { name: 'Gross Income', data: [] },
    { name: 'Expenses', data: [] }
  ]);
  const [expenseReportData, setExpenseReportData] = useState<TExpenseReport['expenses']>();
  //-------------------------------useEffect-----------------

  useEffect(() => {
    if (reportData) {
      if (reportData.graph_statistics) {
        const tempData: { name: string; data: number[] }[] = JSON.parse(JSON.stringify(seriesData));
        reportData.graph_statistics.forEach((singleData) => {
          singleData.counts.map((singleData, countIndex) => tempData[countIndex].data.push(singleData));
        });
        setSeriesData(tempData);
      }
      if (reportData.expenses) {
        const tempData: TExpenseReport['expenses'] = [];
        reportData.expenses.map((singleData, countIndex) => tempData.push({ ...singleData, color: randomHexColorCode() }));
        setExpenseReportData(tempData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData]);
  return (
    <div className="grid lg:grid-cols-3 gap-2">
      <Card>
        <CardHeader
          title={
            <div className="text-2xl text-center ">
              {selectedFilter.filter_value === 'last_year' && moment().year() - 1}
              {selectedFilter.filter_value === 'this_year' && moment().year()} Expenses
            </div>
          }
          action={
            <CustomTooltip message="View And Add Expense">
              <IconButton
                color="primary"
                onClick={() => {
                  navigate('/expenses');
                }}
              >
                <SelectOutlined />
              </IconButton>
            </CustomTooltip>
          }
          subheader={<div className="text-center text-lg text-gray-500">{formateData(reportData.statistics.expenses ?? 0, '$')}</div>}
        />
        <CardContent>
          <ReportDateFilter selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} dateFilters={dateFilter} />
          <div className="gap-2 divide-y">
            {expenseReportData &&
              expenseReportData.map((singleExpense) => (
                <CardContent className="grid grid-cols-1 gap-1 ">
                  <div className="flex justify-between">
                    <Typography variant="h5" className="font-semibold">
                      {snakeCaseToTitleCase(singleExpense.expense_type)}
                    </Typography>
                    <Typography variant="subtitle2" className=" text-gray-500 text-sm">
                      {formateData(singleExpense.amount ?? 0, '$')}
                    </Typography>
                  </div>
                  <div className="col-span-3">
                    <LinearWithLabel
                      color="inherit"
                      sx={{ color: singleExpense.color }}
                      className="sm:h-4"
                      value={singleExpense.percentage}
                    />
                  </div>
                </CardContent>
              ))}
          </div>
        </CardContent>
      </Card>
      <Card className="hidden sm:block lg:col-span-2">
        <ReactApexChart
          type="bar"
          series={seriesData}
          options={{
            chart: {
              toolbar: { show: false },
              offsetY: 10,
              offsetX: -15
            },
            title: { text: 'Expenses', align: 'center' },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            dataLabels: {
              enabled: false
            },
            xaxis: {
              type: 'category',
              categories: [...reportData.graph_statistics.map((singleData) => singleData.date)]
            }
          }}
        />
      </Card>
    </div>
  );
};

export default ExpenseReport;
