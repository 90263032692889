// project import
import Locales from 'components/Locales';
import Routes from 'routes';
import ThemeCustomization from 'themes';
// import RTLLayout from 'components/RTLLayout';
import Snackbar from 'components/@extended/Snackbar';
import ScrollTop from 'components/ScrollTop';
import Notistack from 'components/third-party/Notistack';

// auth-provider
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import GoogleMaps from 'components/forms/google-map-autocomplete/GoogleMap';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import IndexedDBServices from 'services/services.localDb';
import { TIndexedDBResponse, TStoredAPICalls } from 'services/types/types.services';
import axiosServices from 'utils/axios';
import { constants, indexedDbStoreNames } from 'utils/constants';

const queryClient = new QueryClient();

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const App = () => {
  const loaded = useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${constants.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  useEffect(() => {
    const executeStoredApis = async () => {
      try {
        const indexedDBInstance = new IndexedDBServices(indexedDbStoreNames.api);
        const apiData = (await indexedDBInstance.getAllLocalData()) as TIndexedDBResponse;
        if (!apiData) return;
        const apis: TStoredAPICalls[] = apiData.data;
        for (let i = 0; i < apis.length; i++) {
          const api = apis[i];
          switch (api.requestType) {
            case 'post': {
              await axiosServices.post(api.apiUrl, { ...api.data });
              break;
            }
            case 'put': {
              await axiosServices.put(api.apiUrl, { ...api.data });
              break;
            }
            default:
              break;
          }
        }
        await indexedDBInstance.clearObjectStore();
        apis.length > 0 && window.location.reload();
      } catch (error) {
        console.log(error);
      }
    };
    window.addEventListener('online', executeStoredApis);

    return () => {
      window.removeEventListener('online', executeStoredApis);
    };
  }, []);

  useEffect(() => {
    const removeOldData = async () => {
      const currentDate = moment().startOf('day');
      const storeNames = Object.values(indexedDbStoreNames);
      for (let i = 0; i < storeNames.length; i++) {
        const indexedDBInstance = new IndexedDBServices(storeNames[i]);
        const keys = (await indexedDBInstance.getAllKeysOfStore()) as string[];
        if (!keys) return;
        const promises = keys
          .filter((key) => moment(key, 'MM-DD-YYYY').isBefore(currentDate))
          .map((key) => indexedDBInstance.deleteKey(key));
        await Promise.all(promises);
      }
    };
    removeOldData();
  }, []);

  return (
    <ThemeCustomization>
      {/* <RTLLayout> */}
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Locales>
            <ScrollTop>
              <AuthProvider>
                <>
                  <Notistack>
                    <Routes />
                    <Snackbar />
                    <div className="hidden">
                      <GoogleMaps formik={null} root_field_name={'company_address'} />
                    </div>
                  </Notistack>
                </>
              </AuthProvider>
            </ScrollTop>
          </Locales>
        </LocalizationProvider>
      </QueryClientProvider>
      {/* </RTLLayout> */}
    </ThemeCustomization>
  );
};

export default App;
