import { CameraFilled, CloseOutlined, EyeFilled, LoadingOutlined } from '@ant-design/icons';
import { Autocomplete, Avatar, Grid, IconButton, InputLabel, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomTooltip from 'components/CustomTooltip';
import { TPayment } from 'components/tables/interfaces/jobTableInterface';
import dayjs, { Dayjs } from 'dayjs';
import useAuth from 'hooks/useAuth';
import React, { ReactInstance, ReactNode, RefObject, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import FileUploadServiceInstance from 'services/services.fileUpload';
import { formatCostInDecimal, snakeCaseToTitleCase } from 'utils/common';
import NumberInput from './NumberInput';

const paymentTypes2 = ['check', 'venmo', 'zelle', 'other'];
const creditCardType = ['Visa', 'MasterCard', 'Discover', 'American Express'];

type TPaymentInputProps = {
  paymentData: Array<TPayment>;
  setPaymentData: (value: React.SetStateAction<TPayment[]>) => void;
  totalAmountRemaining?: number;
  componentRef: RefObject<HTMLDivElement>;
  paymentTypes: string[];
};

const PaymentInput = ({ paymentData, setPaymentData, totalAmountRemaining, componentRef, paymentTypes }: TPaymentInputProps) => {
  const { user } = useAuth();
  const errorMessage = 'This field is required.';
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [paymentTypeError, setPaymentTypeError] = useState<{ error: boolean; index: number }[]>([]);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const handleAmountChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
    const value = Number(event.target.value) === 0 || isNaN(Number(event.target.value)) ? null : Number(event.target.value);
    if (value && value < 0) return;
    if (
      totalAmountRemaining === undefined ||
      (totalAmountRemaining !== undefined && !(totalAmountRemaining - Number(event.target.value) < 0))
    ) {
      setPaymentData((prevPayments) => {
        const updatedPayments = [...prevPayments];
        updatedPayments[index].amount = Number(formatCostInDecimal(`${value}`, 2));
        return updatedPayments;
      });
    }
  };

  const handlePaymentDataChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number, field_name: string) => {
    setPaymentData((prevPaymentData) => {
      const dummyData = [...prevPaymentData];
      switch (field_name) {
        case 'how_did_customer_pay':
          dummyData[index]['how_did_customer_pay'] = event.target.value;
          break;
        case 'cheque_number':
          const value = Number(event.target.value) === 0 || isNaN(Number(event.target.value)) ? null : Number(event.target.value);
          dummyData[index]['cheque_number'] = value as number;
          break;
      }
      return dummyData;
    });
  };

  const handleOpenFileUpload = (index: number) => {
    setSelectedIndex(index);
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsFileUploading(true);

    const selectedImage = event.target.files?.[0];
    if (selectedImage) {
      const response: { success: boolean; data?: any } | undefined = await FileUploadServiceInstance.uploadFile(selectedImage);
      if (response && response.success) {
        const dummyData = [...paymentData];
        dummyData[selectedIndex].cheque_image = response.data;
        setPaymentData(() => dummyData);
      }
    }
    setIsFileUploading(false);
  };

  const handleDeleteChequeImage = (index: number) => {
    const dummyData = [...paymentData];
    dummyData[index].cheque_image = '';
    setPaymentData(dummyData);
  };

  const handlePaymentTypeBlur = (paymentType: TPayment['payment_type'], index: number) => {
    const newPaymentTypeError = [...paymentTypeError];
    const isExistingIndex = newPaymentTypeError.findIndex((each) => each.index === index);
    if (!(isExistingIndex > 0)) {
      newPaymentTypeError.push({ index, error: !paymentType });
    } else {
      newPaymentTypeError[isExistingIndex] = { index, error: !paymentType };
    }
    setPaymentTypeError(newPaymentTypeError);
  };

  const handlePrint = useReactToPrint({
    documentTitle: 'Print This Document',
    onBeforePrint: () => console.log('before printing...'),
    onAfterPrint: () => console.log('after printing...'),
    removeAfterPrint: true,
    content: function (): ReactInstance | null {
      throw new Error('Function not implemented.');
    }
  });

  return (
    <>
      {paymentData.map((singlePaymentData, paymentIndex) => (
        <Grid item xs={12} className="my-3">
          <Grid container spacing={2}>
            {/*-----------------------------Payment Type---------------------- */}

            <Grid item xs={6} sm={4} className="space-y-1">
              <InputLabel required>Payment Type</InputLabel>
              <Autocomplete
                value={singlePaymentData.payment_type}
                disableClearable
                onChange={(event, newValue: string) => {
                  setPaymentData((prevPayments) => {
                    const updatedPayments = [...prevPayments];
                    updatedPayments[paymentIndex] = (() => {
                      switch (newValue) {
                        case 'cash':
                        case 'venmo':
                        case 'zelle':
                        case 'other':
                        case 'paypal':
                          return {
                            payment_type: newValue,
                            amount: Number(formatCostInDecimal(`${totalAmountRemaining}`, 2)) ?? 0,
                            user: { id: user?._id ?? '', name: `${user?.first_name} ${user?.last_name}`, role: user?.role ?? '' }
                          };
                        case 'check':
                          return {
                            payment_type: 'cheque',
                            amount: Number(formatCostInDecimal(`${totalAmountRemaining}`, 2)) ?? 0,
                            cheque_date: new Date(),
                            cheque_number: null as unknown as number,
                            cheque_image: '',
                            user: { id: user?._id ?? '', name: `${user?.first_name} ${user?.last_name}`, role: user?.role ?? '' }
                          };
                        case 'credit_card':
                          return {
                            payment_type: 'credit_card',
                            amount: Number(formatCostInDecimal(`${totalAmountRemaining}`, 2)) ?? 0,
                            expiry_month: '',
                            expiry_year: '',
                            cvv: '',
                            card_type: '',
                            card_number: '',
                            user: { id: user?._id ?? '', name: `${user?.first_name} ${user?.last_name}`, role: user?.role ?? '' }
                          };
                        case 'print':
                          return { payment_type: 'print' };
                        default:
                          return {};
                      }
                    })();
                    setPaymentTypeError((prev) => prev.filter((each) => each.index !== paymentIndex));
                    return updatedPayments;
                  });
                }}
                onBlur={() => handlePaymentTypeBlur(singlePaymentData.payment_type, paymentIndex)}
                getOptionLabel={(eachOption) => snakeCaseToTitleCase(eachOption)}
                options={paymentIndex === 0 ? paymentTypes : paymentTypes2}
                renderInput={(params) => (
                  <TextField required {...params} error={paymentTypeError.find((each) => each.index === paymentIndex)?.error} />
                )}
              />
              {paymentTypeError.find((each) => each.index === paymentIndex)?.error && (
                <Typography color={'red'}>This field is required</Typography>
              )}
            </Grid>
            {singlePaymentData.payment_type?.includes('credit_card') && (
              <Grid className="space-y-1" item xs={6} sm={4}>
                <InputLabel>Card Type*</InputLabel>
                <Autocomplete
                  value={singlePaymentData.card_type}
                  disableClearable
                  onChange={(event, newValue: string) => {
                    setPaymentData((prevPaymentData) => {
                      const dummyData = [...prevPaymentData];
                      dummyData[paymentIndex].card_type = newValue;
                      return dummyData;
                    });
                  }}
                  getOptionLabel={(eachOption) => eachOption}
                  options={creditCardType}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      helperText={!singlePaymentData.card_type && errorMessage}
                      error={singlePaymentData.card_type ? false : true}
                    />
                  )}
                />
              </Grid>
            )}

            {/*-----------------------------Amount Collected---------------------- */}

            {['cash', 'venmo', 'zelle', 'other', 'paypal'].some((value) => singlePaymentData.payment_type!?.includes(value)) && (
              <Grid item xs={6} sm={4} className="space-y-1">
                <InputLabel>Amount Collected</InputLabel>
                <NumberInput
                  fullWidth
                  onChangeHandler={handleAmountChange}
                  args={[paymentIndex]}
                  placeholder="Amount"
                  error={!singlePaymentData.amount ? true : false}
                  helperText={!singlePaymentData.amount && errorMessage}
                  value={singlePaymentData.amount}
                />
              </Grid>
            )}
            {/*-----------------------------How Did Customer Pay---------------------- */}

            {singlePaymentData.payment_type?.includes('other') && (
              <Grid item xs={6} sm={4} className="space-y-1">
                <InputLabel>How Did Customer Pay</InputLabel>
                <TextField
                  fullWidth
                  autoFocus
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    handlePaymentDataChange(event, paymentIndex, 'how_did_customer_pay');
                  }}
                  error={!singlePaymentData.how_did_customer_pay}
                  helperText={!singlePaymentData.how_did_customer_pay && errorMessage}
                  value={singlePaymentData.how_did_customer_pay}
                />
              </Grid>
            )}
            <Grid item>
              {paymentData[paymentIndex].payment_type === 'print' && (handlePrint(null, () => componentRef?.current) as ReactNode)}
            </Grid>
            {/*-------------------------------------------Cheque--------------------------------- */}

            <input
              key={paymentIndex}
              style={{ display: 'none' }}
              id="cheque_image_input"
              type="file"
              accept="image/*"
              capture="environment"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileUpload(event)}
            />
            {singlePaymentData.payment_type?.includes('cheque') && (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {/*-----------------------------Cheque Image---------------------- */}

                  <Grid item xs={6} sm={2} marginTop={3}>
                    <label htmlFor="cheque_image_input">
                      {singlePaymentData?.cheque_image ? (
                        <div className="flex items-center justify-between border-[1px] rounded-md">
                          <IconButton size="small" LinkComponent={'a'} href={singlePaymentData?.cheque_image} target="_blank">
                            <EyeFilled className="text-base" />
                          </IconButton>
                          <CustomTooltip message="Re-upload">
                            <Avatar
                              onClick={(event) => {
                                handleOpenFileUpload(paymentIndex);
                              }}
                              component={'span'}
                              sx={{ width: 120, height: 40 }}
                              className={`hover:border-4 border-dashed cursor-pointer`}
                              variant="square"
                              src={singlePaymentData?.cheque_image}
                            />
                          </CustomTooltip>
                          <IconButton
                            color="error"
                            size="small"
                            onClick={(e) => {
                              e.preventDefault();
                              handleDeleteChequeImage(paymentIndex);
                            }}
                          >
                            <CloseOutlined className="text-xs" />
                          </IconButton>
                        </div>
                      ) : (
                        <Avatar
                          className="hover:border-2 border-dashed text-xs cursor-pointer bg-gray-300"
                          sx={{ width: 170, height: 40 }}
                          variant="rounded"
                          component={'span'}
                          onClick={(event) => {
                            handleOpenFileUpload(paymentIndex);
                          }}
                          aria-disabled={isFileUploading}
                        >
                          {isFileUploading ? <LoadingOutlined /> : <CameraFilled className="text-lg" />}
                        </Avatar>
                      )}
                    </label>
                  </Grid>
                  {/*-----------------------------Cheque Number---------------------- */}

                  <Grid className="space-y-1" item xs={6} sm={3}>
                    <InputLabel>Check Number*</InputLabel>
                    <NumberInput
                      fullWidth
                      type="number"
                      inputProps={{ min: 0 }}
                      onChangeHandler={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const inputValue = event.target.value;
                        if (inputValue.charAt(0) !== '-') {
                          handlePaymentDataChange(event, paymentIndex, 'cheque_number');
                        }
                      }}
                      args={[]}
                      autoFocus
                      error={singlePaymentData.cheque_number ? false : true}
                      helperText={!singlePaymentData.cheque_number && errorMessage}
                      value={singlePaymentData.cheque_number}
                    />
                  </Grid>
                  {/*-----------------------------Cheque Date---------------------- */}

                  <Grid className="space-y-1" item xs={6} sm={3}>
                    <InputLabel>Check Date</InputLabel>
                    <DatePicker
                      sx={{
                        border: function () {
                          const isError = !singlePaymentData.cheque_date;

                          return isError ? '1px solid red' : 'none';
                        },
                        borderRadius: 2 // Add border radius of 2 if needed
                      }}
                      value={singlePaymentData.cheque_date ? dayjs(singlePaymentData.cheque_date) : null}
                      onChange={(newValue: Dayjs | null) => {
                        setPaymentData((prevPaymentData) => {
                          const dummyData = [...prevPaymentData];
                          dummyData[paymentIndex].cheque_date = newValue?.toDate();
                          return dummyData;
                        });
                      }}
                      minDate={dayjs(Date.now())}
                    />
                    {!singlePaymentData.cheque_date && <Typography color="red">{errorMessage}</Typography>}
                  </Grid>
                  {/*-----------------------------Cheque Amount---------------------- */}
                  <Grid className="space-y-1" item xs={6} sm={3}>
                    <InputLabel>Check Amount</InputLabel>
                    <NumberInput
                      error={singlePaymentData.amount ? false : true}
                      helperText={!singlePaymentData.amount && errorMessage}
                      fullWidth
                      type="number"
                      inputProps={{ min: 0 }}
                      onChangeHandler={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const inputValue = event.target.value;
                        if (inputValue.charAt(0) !== '-') {
                          handleAmountChange(event, paymentIndex);
                        }
                      }}
                      args={[]}
                      value={singlePaymentData.amount}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/*-------------------------------------------credit_card--------------------------------- */}
            {singlePaymentData.payment_type?.includes('credit_card') && (
              <Grid container item gap={1} xs={12}>
                <Grid container spacing={2}>
                  <Grid className="space-y-1" item xs={6} sm={4}>
                    <InputLabel>Card Number*</InputLabel>
                    <NumberInput
                      fullWidth
                      type="number"
                      args={[]}
                      onChangeHandler={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const inputValue = event.target.value;
                        if (inputValue.charAt(0) !== '-') {
                          setPaymentData((prevPaymentData) => {
                            const dummyData = [...prevPaymentData];
                            dummyData[paymentIndex].card_number = inputValue;
                            return dummyData;
                          });
                        }
                      }}
                      error={
                        !singlePaymentData.card_number ||
                        singlePaymentData.card_number?.length < 13 ||
                        singlePaymentData.card_number?.length > 16
                          ? true
                          : false
                      }
                      helperText={
                        !singlePaymentData.card_number
                          ? errorMessage
                          : singlePaymentData.card_number?.length < 13 || singlePaymentData.card_number?.length > 16
                          ? 'Card number must be between 13 and 16 digits'
                          : null
                      }
                      value={singlePaymentData.card_number}
                    />
                  </Grid>
                  <Grid className="space-y-1" item xs={3}>
                    <InputLabel>Expiry Month*</InputLabel>
                    <NumberInput
                      fullWidth
                      args={[]}
                      type="number"
                      onChangeHandler={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const inputValue = event.target.value;
                        if (Number(inputValue) >= 0 && Number(inputValue) <= 12 && inputValue.charAt(0) !== '-') {
                          setPaymentData((prevPaymentData) => {
                            const dummyData = [...prevPaymentData];
                            dummyData[paymentIndex].expiry_month = inputValue;
                            return dummyData;
                          });
                        }
                      }}
                      error={
                        singlePaymentData.expiry_month &&
                        Number(singlePaymentData.expiry_month) <= 12 &&
                        Number(singlePaymentData.expiry_month) >= 1
                          ? false
                          : true
                      }
                      // helperText={!singlePaymentData.expiry_month && errorMessage}
                      value={singlePaymentData.expiry_month}
                    />
                  </Grid>
                  <Grid className="space-y-1" item xs={3}>
                    <InputLabel>Expiry Year*</InputLabel>
                    <NumberInput
                      fullWidth
                      type="number"
                      onChangeHandler={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const inputValue = event.target.value;
                        if (inputValue.length > 2) return;
                        if (inputValue.charAt(0) !== '-') {
                          setPaymentData((prevPaymentData) => {
                            const dummyData = [...prevPaymentData];
                            dummyData[paymentIndex].expiry_year = inputValue;
                            return dummyData;
                          });
                        }
                      }}
                      args={[]}
                      error={!singlePaymentData.expiry_year || singlePaymentData.expiry_year?.length !== 2 ? true : false}
                      // helperText={!singlePaymentData.expiry_year && errorMessage}
                      value={singlePaymentData.expiry_year}
                    />
                  </Grid>
                  <Grid className="space-y-1" item xs={2}>
                    <InputLabel>CVV*</InputLabel>
                    <NumberInput
                      fullWidth
                      type="number"
                      args={[]}
                      onChangeHandler={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const inputValue = event.target.value;
                        if (inputValue.charAt(0) !== '-') {
                          if (inputValue.length > 4) return;
                          setPaymentData((prevPaymentData) => {
                            const dummyData = [...prevPaymentData];
                            dummyData[paymentIndex].cvv = inputValue;
                            return dummyData;
                          });
                        }
                      }}
                      error={
                        !singlePaymentData.cvv || singlePaymentData.cvv?.length < 3 || singlePaymentData.cvv?.length > 4 ? true : false
                      }
                      // helperText={!singlePaymentData.cvv && errorMessage}
                      value={singlePaymentData.cvv}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid className="space-y-1" item xs={4}>
                    <InputLabel>Amount*</InputLabel>
                    <NumberInput
                      args={[]}
                      fullWidth
                      type="number"
                      onChangeHandler={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const inputValue = event.target.value;

                        if (!inputValue.includes('-')) {
                          handleAmountChange(event, paymentIndex);
                        }
                      }}
                      error={singlePaymentData.amount ? false : true}
                      helperText={!singlePaymentData.amount && errorMessage}
                      value={singlePaymentData.amount}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default PaymentInput;
