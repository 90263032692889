import { TIndexedDBResponse, TResponseWithPermissions } from './types/types.services';
import IndexedDBServices from './services.localDb';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import axiosServices from 'utils/axios';
import { IResponse } from './types/types.services';
import { storedDataInIndexedDB } from 'utils/common';
import { indexedDbStoreNames } from 'utils/constants';

class AuthService {
  getMe = async () => {
    try {
      if (!navigator.onLine) {
        const indexedDBInstance = new IndexedDBServices(indexedDbStoreNames.me);
        const meData = (await indexedDBInstance.getAllLocalData()) as TIndexedDBResponse;

        return meData.data;
      }
      const response = await axiosServices.get('/api/auth/me');

      const data = {
        success: response.data.success,
        data: response.data.data.user,
        company: response.data.data.company,
        setting: response.data.data.setting
      };
      const meData = await storedDataInIndexedDB(indexedDbStoreNames.me, data);

      return meData.data;
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  logout = async () => {
    try {
      const response = await axiosServices.post('/api/auth/logout');
      return response?.data ?? false;
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  getPermissions = async () => {
    try {
      if (!navigator.onLine) {
        const indexedDBInstance = new IndexedDBServices(indexedDbStoreNames.permissions);
        const permissions = (await indexedDBInstance.getAllLocalData()) as TIndexedDBResponse;
        return permissions.data as TResponseWithPermissions;
      }
      const permissionsResponse = await axiosServices.get('/api/user/permissions');

      const permissions = await storedDataInIndexedDB(indexedDbStoreNames.permissions, permissionsResponse.data);
      return permissions.data as TResponseWithPermissions;
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  companyRegister = async (data: any) => {
    try {
      const response: IResponse = await axiosServices.post('api/company/sign-up', data);
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  forgotPassword = async (email: string) => {
    try {
      await axiosServices.post('/api/auth/forget-password-request', { email });
      return true;
    } catch (error) {
      const knownError = error as { success: boolean; message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: `${knownError.message}`,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      return false;
    }
  };
}
const AuthServicesInstance = new AuthService();
export default AuthServicesInstance;
