import { CheckCircleFilled, PictureOutlined } from '@ant-design/icons';
import { Button, CardActionArea, ImageList, ImageListItem, ImageListItemBar, Typography, useTheme } from '@mui/material';
import { universalRenerMedia } from 'components/UniversalRenderMedia';
import { useState } from 'react';
import { getFileNameFromURL, handleDownload } from 'utils/common';

const SelectJobImages = ({
  files,
  onSubmit,
  jobImages
}: {
  files: string[];
  onSubmit: (values: string[]) => void;
  jobImages?: string[];
}) => {
  //----------------constants-------------
  const theme = useTheme();
  const [selectedImage, setSelectedImage] = useState<string[]>(jobImages ?? []);

  //------------Handlers-------------
  const handleImageSelect = (imageUrl: string) => {
    if (selectedImage.includes(imageUrl)) {
      const dummyData = [...selectedImage];
      const selectedImageIndex = dummyData.findIndex((singleSelectedImageUrl) => singleSelectedImageUrl === imageUrl);
      if (selectedImageIndex !== -1) {
        dummyData.splice(selectedImageIndex, 1);
        setSelectedImage(dummyData);
      }
      return;
    }
    const dummyData = [...selectedImage, imageUrl];
    setSelectedImage(dummyData);
  };

  return (
    <>
      {!!files?.length ? (
        <ImageList cols={3} rowHeight={'auto'} className="space-x-1 space-y-1">
          {files?.map((singleFileUrl: string, index: number) => (
            <ImageListItem
              key={index}
              component={CardActionArea}
              sx={{
                border: selectedImage.some((singleSelectedImage) => singleSelectedImage === singleFileUrl)
                  ? `2px solid ${theme.palette.primary.light}`
                  : '1px solid gray'
              }}
              className="h-full rounded"
              style={{ height: '200px' }}
              onClick={() => handleImageSelect(singleFileUrl)}
            >
              <>{universalRenerMedia(singleFileUrl, index)}</>
              <ImageListItemBar
                sx={{
                  background: 'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
                }}
                className="p-1"
                position="top"
                actionIcon={
                  selectedImage.includes(singleFileUrl) ? (
                    <CheckCircleFilled className="font-lg" style={{ color: theme.palette.primary.main }} />
                  ) : (
                    <div className="rounded-full w-3 h-3 border border-black" />
                  )
                }
                actionPosition="left"
              />
              <ImageListItemBar
                title={
                  <Typography
                    className="text-xs font-bold cursor-pointer"
                    sx={{ ':hover': { color: theme.palette.primary.main } }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDownload(singleFileUrl);
                    }}
                  >
                    {getFileNameFromURL(singleFileUrl)}
                  </Typography>
                }
                className="p-1"
                position="bottom"
                actionPosition="left"
              />
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <div className="flex flex-col items-center gap-3 p-8">
          <PictureOutlined style={{ height: 20, transform: 'scale(3)', color: 'GrayText' }} />
          <Typography color={'GrayText'}>No Image</Typography>
        </div>
      )}
      <div className="flex justify-end">
        <Button variant="contained" disabled={selectedImage.length === 0} onClick={() => onSubmit(selectedImage)}>
          Submit
        </Button>
      </div>
    </>
  );
};

export default SelectJobImages;
