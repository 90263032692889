import { ProductOutlined } from '@ant-design/icons';
import { Card, CardMedia, Typography, useTheme } from '@mui/material';

const JobCategoryCards = (props: {
  data: { _id: string; title: string; type: string; background: string; icon?: string };
  onClick: (arg0: string, arg1: string) => void;
}) => {
  const theme = useTheme();
  const { onClick = () => {}, data } = props;
  return (
    <Card
      className="flex justify-start items-center shadow-none border-[1px] cursor-pointer hover:shadow-lg transition-all ease-in duration-150"
      sx={{ height: '5rem', color: theme.palette.primary.lighter, background: theme.palette.primary.main }}
      onClick={() => onClick(data._id, data.title)}
    >
      {!!data.icon?.length ? (
        <CardMedia component="img" image={data.icon} alt={''} classes={{ root: 'h-[5rem] w-[5rem]' }} />
      ) : (
        <div className="h-[5rem] min-w-max px-4 flex justify-center items-center">
          <ProductOutlined style={{ color: theme.palette.primary.lighter }} className="text-3xl" />
        </div>
      )}

      <Typography className="text-start break-all max-w-full px-6 text-2xl font-medium">{data.title}</Typography>
    </Card>
  );
};

export default JobCategoryCards;
