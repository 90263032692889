import {
  TAppointmentConfig,
  TGetAllArea,
  TGetAllService,
  TGetAndUpdateSingleArea,
  TGetAppointmentConfig,
  TGetAppointmentWidgetData,
  TGetServiceAndAreaAvailableDates,
  TMailAppointmentWidgetData,
  TResponseWSuccess,
  TScheduleWorker,
  TSingleService
} from 'pages/Settings/types/types.autoAppointment';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import axiosServices from 'utils/axios';

class AutoAppointmentService {
  //------------------AppointmentConfig------------
  createAppointmentConfig = async (appointmentConfig: TAppointmentConfig) => {
    try {
      const response: TResponseWSuccess = await axiosServices.post(`api/auto-appointment/schedule`, appointmentConfig);
      if (response.data && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `${appointmentConfig.area} created successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  editAppointmentConfig = async (appointmentConfigId: string, appointmentConfig: TAppointmentConfig, calendarType?: string) => {
    try {
      const response: TResponseWSuccess = await axiosServices.put(
        `api/auto-appointment/schedule/${appointmentConfigId}`,
        appointmentConfig
      );
      if (response.data && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `${calendarType === 'blackout_days' ? 'Blackout days ' : appointmentConfig.area} updated successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  getAppointmentWidgetData = async (companyId: string, serviceId: string) => {
    try {
      const response: TGetAppointmentWidgetData = await axiosServices.get(`/api/auto-appointment/service/${companyId}/${serviceId}`);

      if (response.data && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  getServiceAndAreaAvailableDates = async (
    companyId: string,
    areaId: string,
    serviceId: string,
    month: number,
    year: number,
    isServiceRequired: boolean
  ) => {
    try {
      const response: TGetServiceAndAreaAvailableDates = await axiosServices.get(
        `/api/auto-appointment/available-dates/${companyId}/${areaId}${
          isServiceRequired ? `/${serviceId}` : '/service_id'
        }?month=${month}&year=${year}&isServiceRequired=${isServiceRequired}`
      );

      if (response.data && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  getAppointmentConfig = async () => {
    try {
      const response: TGetAppointmentConfig = await axiosServices.get('api/auto-appointment/schedule');
      if (response.data && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  getAppointmentDetailsFromMail = async (companyId: string) => {
    try {
      const response: TMailAppointmentWidgetData = await axiosServices.get(`api/auto-appointment/mail/${companyId}`);

      if (response.data && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  deleteAppointmentConfig = async (appointmentId: string) => {
    try {
      const response: TResponseWSuccess = await axiosServices.delete(`api/auto-appointment/schedule/${appointmentId}`);
      if (response.data && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Deleted SuccessFully',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  //----------------AreaAvailability----------------
  getAllArea = async () => {
    try {
      const response: TGetAllArea = await axiosServices.get('api/auto-appointment/area');
      if (response.data && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  getSingleArea = async (areaId: string) => {
    try {
      const response: TGetAndUpdateSingleArea = await axiosServices.get(`api/auto-appointment/area/${areaId}`);
      if (response.data && response.data.success && response.data.data) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  editSingleArea = async (areaId: string, areaData: TGetAndUpdateSingleArea['data']['data']) => {
    try {
      const response: TResponseWSuccess = await axiosServices.put(`api/auto-appointment/area/${areaId}`, areaData);
      if (response.data && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `${areaData.area_name} updated successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  createSingleArea = async (areaData: TGetAndUpdateSingleArea['data']['data']) => {
    try {
      const response: TResponseWSuccess = await axiosServices.post(`api/auto-appointment/area`, areaData);
      if (response.data && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `${areaData.area_name} created successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  deleteArea = async (areaId: string) => {
    try {
      const response: TResponseWSuccess = await axiosServices.delete(`api/auto-appointment/area/${areaId}`);
      if (response.data && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Deleted SuccessFully',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  //----------------Service----------------
  getAllService = async () => {
    try {
      const response: TGetAllService = await axiosServices.get('api/auto-appointment/service');
      if (response.data && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  getSingleService = async (serviceId: string) => {
    try {
      const response: TSingleService = await axiosServices.get(`api/auto-appointment/service/${serviceId}`);
      if (response.data && response.data.success && response.data.data) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  createSingleService = async (serviceData: TSingleService['data']['data']) => {
    try {
      const response: TResponseWSuccess = await axiosServices.post(`api/auto-appointment/service`, serviceData);
      if (response.data && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `${serviceData.service.name} created successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  editSingleService = async (serviceId: string, serviceData: TSingleService['data']['data']) => {
    try {
      const response: TResponseWSuccess = await axiosServices.put(`api/auto-appointment/service/${serviceId}`, serviceData);
      if (response.data && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `${serviceData.service.name} updated successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  deleteService = async (serviceId: string) => {
    try {
      const response: TResponseWSuccess = await axiosServices.delete(`api/auto-appointment/service/${serviceId}`);
      if (response.data && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Deleted SuccessFully',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  scheduleWorker = async (jobId: string, values: TScheduleWorker) => {
    try {
      const response: { data: { success: boolean } } = await axiosServices.post(`/api/widget/schedule-worker/${jobId}`, values);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Appointment Scheduled Successfully',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
}

const AutoAppointmentServiceInstance = new AutoAppointmentService();
export default AutoAppointmentServiceInstance;
