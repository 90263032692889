import { CheckOutlined, DeleteOutlined, UserAddOutlined } from '@ant-design/icons';
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import ActionButtonsGroup from 'components/buttons/ActionButtonsGroup/ActionButtonsGroup';
import { TAvailableActionButtons } from 'components/buttons/ActionButtonsGroup/types.actionButtonGroup';
import { ISearch } from 'components/filters/SearchFilter';
import AssignWorkerForm from 'components/forms/AssignWorkerForm';
import AddFollowupPopup from 'components/popups/AddFollowupPopup';
import DeleteConfirmationPoup from 'components/popups/DeleteConfirmationPopup';
import UniversalDialog from 'components/popups/UniversalDialog';
import RenderStatusPreviewIcons from 'components/RenderStatusPreviewIcons';
import CustomDataTable, { rowsPerPageOptions } from 'components/tables/CustomDataTable';
import TFollowupData from 'components/tables/interfaces/followupTableInterface';
import { TCustomerPhones, TJobCompleteData } from 'components/tables/interfaces/jobTableInterface';
import useAuth from 'hooks/useAuth';
import useTimezone from 'hooks/useTimezone';
import { IAddFollowupPopup } from 'pages/Settings/types/types.followupPopup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import FollowupServicesInstance from 'services/services.followups';
import JobServicesInstance from 'services/services.jobs';
import { dispatch, useSelector } from 'store';
import { getTabSummary } from 'store/reducers/customReducers/slice.tabSummary';
import { IDeletePopup } from 'types/globalTypes/type.deleteDialog';
import { TUniversalDialogProps } from 'types/globalTypes/type.universalDialog';
import { recurringJobFollowup, sequentialJobActionFollowup } from 'utils/constants';
import dayjs from '../../dayjsConfig';
import MarkFollowUpNotComplete from './MarkFollowUpNotComplete';

type TFollowupPage = {
  customerData?: { name: string; id: string; phones: TCustomerPhones; emails?: string[] };
  jobData?: TJobCompleteData;
};

const defaultSearch = {
  search: [
    [
      {
        field_name: 'status',
        field_value: 1,
        operator: 'exactmatch'
      }
    ]
  ]
};

const FollowupPage = (props: TFollowupPage) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const primaryColor = `${theme.palette.primary.main}`;
  const { user } = useAuth();
  const location = useLocation();
  const action = new URLSearchParams(location.search).get('action');
  const formatDateTime = useTimezone();
  const settingsState = useSelector((state) => state.settings.generalSettings);
  const [addFollowupPopup, setAddFollowupPopup] = useState<IAddFollowupPopup>({ action: { open: false }, data: null });
  const [deleteDialog, setDeleteDialog] = useState<IDeletePopup>({ action: { open: false }, data: { subjectToBeDeleted: '' } });
  const [bulkDeleteDialog, setBulkDeleteDialog] = useState<IDeletePopup>({ action: { open: false }, data: { subjectToBeDeleted: '' } });
  const [markCompletePopup, setMarkCompletePopup] = useState<IAddFollowupPopup>({ action: { open: false }, data: null });
  const [markNotCompletePopup, setMarkNotCompletePopup] = useState<IAddFollowupPopup>({ action: { open: false }, data: null });
  const [description, setDescription] = useState('');
  const [paginationData, setPaginationData] = useState({ page: 0, rowsPerPage: rowsPerPageOptions[0] });
  const [searchData, setSearchData] = useState<ISearch>(defaultSearch);
  const [toggleFilter, setToggleFilter] = useState<boolean | null>(null);
  const [selectedFilter, setSelectedFilter] = useState<number>(1);
  const [selectedFollowUps, setSelectedFollowUps] = useState<string[]>([]);
  const [assignWorkerFormDialogControl, setAssignWorkerFormDialogControl] = useState<TUniversalDialogProps>({
    action: {
      open: false,
      fullScreen: true
    },
    data: { job_id: '', isRecurringJob: false, followup_id: '' },
    title: ''
  });
  const [bulkMarkCompletePopup, setBulkMarkCompletePopup] = useState<boolean>(false);

  // -----------------useQuery-----------------
  const {
    data: allFollowupData,
    isLoading: isFollowUpFetching,
    refetch: refetchFollowUps
  } = useQuery({
    queryKey: [paginationData, searchData],
    queryFn: () => FollowupServicesInstance.getAllFollowup(paginationData, searchData),
    enabled: !!props.customerData && props.customerData?.id?.length > 0 && !!searchData && searchData.search.length > 1
  });

  const { data: jobData, isFetched: isJobFetched } = useQuery({
    queryKey: [assignWorkerFormDialogControl.data.job_id, 'job_data'],
    queryFn: () => JobServicesInstance.getSingleJobs(assignWorkerFormDialogControl.data.job_id),
    enabled: assignWorkerFormDialogControl.data.job_id?.length > 0
  });

  const handleNotes = (originalData: TFollowupData) => {
    return [recurringJobFollowup, sequentialJobActionFollowup].includes(originalData.note) ? (
      <div className="flex flex-col gap-2 min-w-[150px]">
        <Typography className="">{originalData.note}</Typography>

        <Button
          className="max-w-max max-h-min rounded-none"
          size="extraSmall"
          variant="contained"
          startIcon={<UserAddOutlined />}
          onClick={() => handleAssignWorker(true, originalData.note === recurringJobFollowup, originalData.job.id, originalData._id ?? '')}
        >
          Click Here
        </Button>
        {originalData.note === sequentialJobActionFollowup ? (
          <Typography className="text-base text-[#228732] font-semibold">{originalData.sequential_action_instruction}</Typography>
        ) : (
          ''
        )}
      </div>
    ) : (
      <div dangerouslySetInnerHTML={{ __html: originalData.note }} className="max-w-[300px] break-normal overflow-x-auto" />
    );
  };

  const handleFollowUpSelect = (id: string) => {
    setSelectedFollowUps((prev) => {
      if (prev.includes(id)) {
        return prev.filter((prevId) => prevId !== id);
      }
      return [...prev, id];
    });
  };

  const selectAllFollowUps = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!allFollowupData) {
      return;
    }

    if (event.target.checked) {
      setSelectedFollowUps(() => allFollowupData?.follow_ups.map((followUp) => followUp._id as string));
      return;
    }
    setSelectedFollowUps((prev) => {
      return [];
    });
  };

  const columns = useMemo<ColumnDef<TFollowupData>[]>(
    () => [
      {
        id: 'select',
        header: () =>
          !!allFollowupData?.follow_ups &&
          !!allFollowupData?.follow_ups?.length && (
            <Checkbox checked={selectedFollowUps.length === allFollowupData?.follow_ups.length} onChange={selectAllFollowUps} />
          ),
        cell: ({ row }) => {
          return (
            <Checkbox
              checked={selectedFollowUps.includes(row.original._id as string)}
              onClick={() => handleFollowUpSelect(row.original._id as string)}
            />
          );
        }
      },
      {
        accessorFn: (row) => row.created_at,
        id: 'date',
        header: () => <span>Added on</span>,
        cell: ({ row }) => {
          return (
            <Typography>
              {formatDateTime(row.original.created_at, {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: settingsState?.time_format === '12hrs'
              })}
            </Typography>
          );
        }
      },
      {
        accessorFn: (row) => row.user?.name ?? 'System',
        id: 'user',
        header: () => <span>User</span>,
        cell: ({ row }) =>
          row.original?.user?.name ? (
            <Typography textAlign="start" className="min-w-max">
              {row.original?.user?.name?.split(' ')?.[0]} {row.original?.user?.name?.split(' ')?.[1]?.[0]}
            </Typography>
          ) : (
            'System'
          )
      },
      {
        accessorFn: (row) => row.job?.name,
        id: 'job_name',
        cell: ({
          row: {
            original: { job }
          }
        }) =>
          job?.id ? (
            <Typography
              sx={{
                '&:hover': {
                  color: primaryColor
                }
              }}
              className="cursor-pointer"
              onClick={() => navigate(`/jobs/view/${job?.id}/job_status`)}
            >
              {job?.name}
            </Typography>
          ) : (
            'N/A'
          ),
        header: () => <span>Job Name</span>
      },
      {
        accessorFn: (row) => row.call_assigned_to?.name,
        id: 'call_assigned_to',
        header: () => <span className="min-w-max">Assigned To</span>,
        cell: ({ row }) => {
          const isBeforeNow = dayjs.utc(row.original?.time).isBefore(dayjs.utc());
          return (
            <div className="min-w-min max-w-[100px]">
              {row.original?.call_assigned_to?.name ? (
                <Typography textAlign="start" className="min-w-max">
                  {row.original?.call_assigned_to?.name?.split(' ')?.[0]} {row.original?.call_assigned_to?.name?.split(' ')?.[1]?.[0]}
                </Typography>
              ) : (
                <Typography color={'GrayText'} textAlign="start" className="min-w-max">
                  N/A
                </Typography>
              )}
              {row.original?.time && isBeforeNow && (
                <Typography className="text-[0.8rem] text-cyan-500">
                  {dayjs(row.original?.time).utc().tz('America/New_York').format('MM/DD/YYYY hh:mm A')}
                </Typography>
              )}
            </div>
          );
        }
      },
      {
        accessorFn: (row) => row.callback_date,
        id: 'callback_date',
        cell: ({ row }) => {
          const { callback_date, exclude_callback_time } = row.original;

          if (!callback_date) {
            return (
              <Typography display="flex" color="GrayText" textAlign="center">
                N/A
              </Typography>
            );
          }

          const isOverdue = exclude_callback_time
            ? dayjs.utc(callback_date).isBefore(dayjs.utc().tz('America/New_York'), 'day')
            : dayjs.utc(callback_date).isBefore(dayjs.utc().tz('America/New_York'));

          const formattedDate = exclude_callback_time
            ? dayjs(callback_date).utc().tz('America/New_York').format('MM/DD/YYYY')
            : dayjs(callback_date).utc().tz('America/New_York').format('MM/DD/YYYY hh:mm A');

          return (
            <Typography className="text-base" color={isOverdue ? 'error' : 'inherit'}>
              {formattedDate}
            </Typography>
          );
        },
        header: () => <span>Callback Date</span>
      },
      {
        cell: ({ row }) => {
          return handleNotes(row.original);
        },
        id: 'note',
        header: () => <span>Notes</span>
      },
      {
        id: 'status_preview',
        cell: ({ row }) => {
          return (
            <div className="w-full flex justify-start space-x-3">
              <RenderStatusPreviewIcons
                jobAction={row?.original?.jobData?.job_action ?? []}
                jobState={row?.original?.jobData?.state as string}
                status={row?.original?.jobData?.status!}
                previousStates={row?.original?.jobData?.previous_states}
                jobId={row?.original?.jobData?._id}
                proposalSentDate={row?.original?.jobData?.last_proposal_sent}
                invoiceSentDate={row?.original?.jobData?.last_invoice_sent?.toString()}
              />
            </div>
          );
        },
        header: () => (
          <div className="flex w-40">
            <div className="ml-3 w-full flex justify-start space-x-9">
              <span>E</span>
              <span>P</span>
              <span>W</span>
              <span>I</span>
            </div>
          </div>
        )
      },
      {
        ...(selectedFilter === 0
          ? {
              accessorFn: (row) => row.delete_reason ?? '-',
              id: 'reason',
              header: () => <span>Reason</span>
            }
          : { id: 'reason' })
      },
      {
        id: 'actions',
        header: () => <span>Actions</span>,
        cell: ({ row }) => {
          let buttons: TAvailableActionButtons[] = ['edit', 'delete', 'mark_followup', 'not_mark_followup'];
          if (row.original.status === 2) {
            buttons = ['delete'];
          } else if (row.original.status === 0) {
            buttons = [];
          }
          return <ActionButtonsGroup handleActions={(action) => handleActions(action, row.original)} buttons={buttons} />;
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedFilter, selectedFollowUps, allFollowupData]
  );

  // ----------------useEffect--------------------

  useEffect(() => {
    let path;

    if (props.jobData !== undefined && !!props.jobData?._id) {
      path = location.pathname.split('/')[3];

      setSearchData({
        search: [
          [
            {
              field_name: 'customer.id',
              field_value: props.customerData?.id ?? '',
              operator: 'exactmatch'
            }
          ],
          [
            {
              field_name: 'status',
              field_value: 1,
              operator: 'exactmatch'
            }
          ],
          [
            {
              field_name: 'job.id',
              field_value: path,
              operator: 'exactmatch'
            }
          ]
        ]
      });
      return;
    }
    setSearchData({
      search: [
        [
          {
            field_name: 'customer.id',
            field_value: props.customerData?.id ?? '',
            operator: 'exactmatch'
          }
        ],
        [
          {
            field_name: 'status',
            field_value: 1,
            operator: 'exactmatch'
          }
        ]
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!props.customerData && !!props.customerData?.id) {
      if (!!props.jobData?._id) {
        dispatch(getTabSummary({ module: 'job', id: props.jobData._id }));
        return;
      }

      dispatch(getTabSummary({ module: 'customer', id: props.customerData.id }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFollowupData]);

  //------------------ Handlers-------------------

  const handleAddFollowup = useCallback(
    (openState: boolean) => {
      setAddFollowupPopup({ action: { open: openState }, data: { otherData: { customerData: props.customerData } } });
    },
    [props.customerData]
  );

  useEffect(() => {
    if (action === 'add_followup') {
      handleAddFollowup(true);
    }
  }, [action, handleAddFollowup]);

  const getStatusValue = () => {
    const statusFilter = searchData.search.flat().find((filter) => filter.field_name === 'status' && filter.field_value);

    if (!statusFilter) {
      return 1;
    }
    return statusFilter.field_value;
  };

  const handleJobStatusFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFilter(Number(event.target.value));
    setToggleFilter((prev) => (prev !== null ? !prev : false));

    if (searchData)
      setSearchData((prevSearchData) => {
        return {
          search: prevSearchData.search.map((singleArrayData) => {
            return singleArrayData.map((singleFilter) => {
              if (singleFilter.field_name === 'status') {
                return { ...singleFilter, field_value: Number(event.target.value) };
              }
              return singleFilter;
            });
          })
        };
      });
  };
  const handleAssignWorker = (state: boolean, isRecurringJob: boolean, jobId: string, followupId?: string, parent_action_id?: string) => {
    refetchFollowUps();
    setAssignWorkerFormDialogControl({
      action: {
        open: state,
        fullScreen: true
      },
      data: { job_id: jobId, isRecurringJob, followup_id: followupId, parent_action_id },
      title: 'Assign Worker'
    });
  };

  const handleBulkComplete = (state: boolean) => {
    setBulkMarkCompletePopup(state);
  };

  const handleBulkDeleteAction = async (state: boolean) => {
    setBulkDeleteDialog({
      action: { open: state, maxWidth: 'xs', reasonRequired: true },
      data: { selectedData: { id: '' } }
    });
  };
  const handleBulkDelete = async (id: string, name: string, reason?: string) => {
    if (selectedFollowUps.length === 0 || reason === undefined) return;
    const response = await FollowupServicesInstance.bulkDeleteFollowup(selectedFollowUps, reason);
    if (response) {
      setSelectedFollowUps([]);
      refetchFollowUps();
      handleBulkDeleteAction(false);
    }
  };

  const handleBulkMarkFollowupComplete = async () => {
    if (!selectedFollowUps.length || !description.length) return;

    await FollowupServicesInstance.bulkMarkFollowupComplete(selectedFollowUps, description);
    setBulkMarkCompletePopup(false);
    setDescription('');
    setSelectedFollowUps([]);
    refetchFollowUps();
  };

  const renderFolllowupHeader = () => {
    return (
      <div className="flex sm:flex-row flex-col-reverse gap-4 items-center justify-end w-full px-4 my-6">
        {!!selectedFollowUps.length && (
          <ButtonGroup className="min-w-max">
            <Button
              className="min-w-max"
              color="success"
              variant="contained"
              startIcon={<CheckOutlined />}
              onClick={() => handleBulkComplete(true)}
            >
              Bulk Complete
            </Button>
            <Button
              className="min-w-max"
              color="error"
              variant="contained"
              startIcon={<DeleteOutlined />}
              onClick={() => handleBulkDeleteAction(true)}
            >
              Bulk Delete
            </Button>
          </ButtonGroup>
        )}
        <div className="flex items-center justify-end w-full">
          {user?.role === 'company_admin' && (
            <RadioGroup row onChange={handleJobStatusFilterChange} defaultValue={getStatusValue}>
              <FormControlLabel value={1} control={<Radio />} label={'Open Followups'} />
              <FormControlLabel value={2} control={<Radio />} label={'Completed Followups'} />
              <FormControlLabel value={0} control={<Radio />} label={'Deleted Followups'} />
            </RadioGroup>
          )}
          <Button
            className="min-w-max"
            variant="contained"
            size="large"
            startIcon={<UserAddOutlined />}
            color="primary"
            onClick={() => handleAddFollowup(true)}
          >
            Add Followup
          </Button>
        </div>
      </div>
    );
  };

  const deleteFollowup = async (followupId: string, reason: string) => {
    const deleteResponse = await FollowupServicesInstance.deleteFollowup(followupId, reason);
    if (deleteResponse) {
      handleDeleteFollowupAction('close');
    }
  };

  const handleActions = (actionType: string, rowOriginal: TFollowupData) => {
    actionType === 'delete' && handleDeleteFollowupAction('open', rowOriginal);
    actionType === 'edit' && handleEditFollowup(rowOriginal);
    actionType === 'mark_followup' && handleMarkFollowupAction(true, rowOriginal);
    actionType === 'not_mark_followup' && handleNotMarkFollowupAction(true, rowOriginal);
  };

  const handleChangePagination = (page: number, rowsPerPage: number) => {
    setPaginationData({ page, rowsPerPage });
  };

  const handleDeleteFollowupAction = (action: string, rowOriginal?: TFollowupData) => {
    switch (action) {
      case 'open':
        setDeleteDialog({
          action: { open: true, maxWidth: 'xs', reasonRequired: true },
          data: { selectedData: rowOriginal }
        });
        break;
      case 'close':
        setDeleteDialog({ action: { open: false, maxWidth: 'xs', reasonRequired: true } });
        break;
    }
    refetchFollowUps();
  };

  const handleMarkFollowupAction = (open: boolean, rowOriginal: TFollowupData) => {
    setMarkCompletePopup({ action: { open, size: 'small' }, data: { selectedData: rowOriginal } });
  };

  const handleNotMarkFollowupAction = (open: boolean, rowOriginal: TFollowupData) => {
    setMarkNotCompletePopup({ action: { open, size: 'small' }, data: { selectedData: rowOriginal } });
  };

  const handleMarkFollowupComplete = async () => {
    const data = markCompletePopup.data?.selectedData;
    await FollowupServicesInstance.markFollowupComplete(data?._id as string, description);
    setMarkCompletePopup((prev) => ({ ...prev, action: { open: false } }));
    setDescription('');
    refetchFollowUps();
  };

  const handleMarkFollowupNotComplete = () => {
    setMarkNotCompletePopup((prev) => ({ ...prev, action: { open: false } }));
    refetchFollowUps();
  };

  const handleEditFollowup = (rowOriginal: TFollowupData) => {
    setAddFollowupPopup({
      action: { open: true, isEditMode: true },
      data: { selectedData: rowOriginal, otherData: { customerData: props.customerData } }
    });
  };

  const handleDeleteSelectedFollowup = (followupId: string, selectedCustomerToBeDeletedFirstName: string, reason?: string) => {
    reason && deleteFollowup(followupId, reason);
  };

  return (
    <div>
      <Paper elevation={3} className="flex flex-col items-start">
        {renderFolllowupHeader()}

        <CustomDataTable
          data={allFollowupData?.follow_ups ?? []}
          columns={columns}
          count={allFollowupData?.count}
          onPaginationChange={handleChangePagination}
          isDataLoading={isFollowUpFetching}
          toggleFilter={toggleFilter}
        />
      </Paper>
      {addFollowupPopup.action.open && (
        <AddFollowupPopup
          selectedJobId={props.jobData?._id ? props.jobData?._id : addFollowupPopup.data?.selectedData?.job?.id}
          {...addFollowupPopup}
          onClose={() => handleAddFollowup(false)}
          onSave={() => {
            refetchFollowUps();
            handleAddFollowup(false);
          }}
        />
      )}
      {deleteDialog.action.open && (
        <DeleteConfirmationPoup
          {...deleteDialog}
          onClose={() => handleDeleteFollowupAction('close')}
          onDelete={handleDeleteSelectedFollowup}
        />
      )}

      {/* ----------------- BULK DELETE ---------------- */}
      {bulkDeleteDialog.action.open && (
        <DeleteConfirmationPoup {...bulkDeleteDialog} onClose={() => handleBulkDeleteAction(false)} onDelete={handleBulkDelete} />
      )}

      {markCompletePopup.action.open && (
        <UniversalDialog
          onClose={() => setMarkCompletePopup((prev) => ({ ...prev, action: { open: false } }))}
          title={'Mark Followup Complete'}
          primaryButonTitle="Submit"
          onSave={handleMarkFollowupComplete}
          action={{ open: markCompletePopup.action.open, maxWidth: 'xs', fullWidth: true }}
          disablePrimaryButton={!description.length}
        >
          <div className="flex flex-col items-start justify-center space-y-2">
            <Typography variant="body1" className="ml-2">
              Description*
            </Typography>
            <TextField
              value={description}
              autoFocus={true}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              multiline
              minRows={3}
            />
          </div>
        </UniversalDialog>
      )}

      {/* ----------------- BULK MARK FOLLOWUP COMPLETE ---------------- */}
      {bulkMarkCompletePopup && (
        <UniversalDialog
          onClose={() => setBulkMarkCompletePopup(false)}
          title={'Mark followups as complete in bulk'}
          primaryButonTitle="Submit"
          onSave={handleBulkMarkFollowupComplete}
          action={{ open: bulkMarkCompletePopup, maxWidth: 'xs', fullWidth: true }}
          disablePrimaryButton={!description.length}
        >
          <div className="flex flex-col items-start justify-center space-y-2">
            <InputLabel required>Description</InputLabel>
            <TextField
              value={description}
              autoFocus={true}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              multiline
              minRows={3}
            />
          </div>
        </UniversalDialog>
      )}

      {markNotCompletePopup.action.open && (
        <UniversalDialog
          onClose={() => setMarkNotCompletePopup((prev) => ({ ...prev, action: { open: false } }))}
          title={'Mark Followup Not Complete'}
          action={{ open: markNotCompletePopup.action.open, maxWidth: 'xs', fullWidth: true }}
          hasPrimaryButton={false}
        >
          <MarkFollowUpNotComplete data={markNotCompletePopup.data?.selectedData} onSubmit={handleMarkFollowupNotComplete} />
        </UniversalDialog>
      )}
      {assignWorkerFormDialogControl.action.open && isJobFetched && !!jobData?.job && Object.keys(jobData?.job).length > 0 && (
        <UniversalDialog
          hasPrimaryButton={false}
          {...assignWorkerFormDialogControl}
          onClose={() => handleAssignWorker(false, false, '', '')}
        >
          <AssignWorkerForm
            jobData={jobData?.job}
            followupId={assignWorkerFormDialogControl.data.followup_id}
            onSuccess={() => handleAssignWorker(false, false, '', '')}
            isEditMode={true}
            isAddNewAction={!assignWorkerFormDialogControl.data.isRecurringJob}
            isReccuringJob={assignWorkerFormDialogControl.data.isRecurringJob}
            jobActionIndex={0}
          />
        </UniversalDialog>
      )}
    </div>
  );
};

export default FollowupPage;
