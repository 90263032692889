// types
import { PalettesProps } from '@ant-design/colors';
import { PaletteColorOptions } from '@mui/material/styles';
import { PaletteThemeProps } from 'types/theme';

// ==============================|| PRESET THEME - DEFAULT ||============================== //

const Default = (colors: PalettesProps): PaletteThemeProps => {
  const { red, gold, cyan, green, grey } = colors;
  const greyColors: PaletteColorOptions = {
    0: grey[0],
    50: grey[1],
    100: grey[2],
    200: grey[3],
    300: grey[4],
    400: grey[5],
    500: grey[6],
    600: grey[7],
    700: grey[8],
    800: grey[9],
    900: grey[10],
    A50: grey[15],
    A100: grey[11],
    A200: grey[12],
    A400: grey[13],
    A700: grey[14],
    A800: grey[16]
  };
  const contrastText = '#fff';

  return {
    // primary: {
    //   lighter: '#e8fffe',
    //   100: '#27C5BF',
    //   200: '#27C5BF',
    //   light: '#27C5BF',
    //   400: '#27C5BF',
    //   main: '#2CC4CB',
    //   dark: '#31a39f',
    //   700: '#27C5BF',
    //   darker: '#1d7572',
    //   900: '#27C5BF',
    //   contrastText
    // },
    primary: {
      lighter: '#d9f1fa',
      light: '#4dafe3',
      400: '#2CC4CB',
      main: '#429bd6',
      dark: '#3272b8',
      darker: '#214496',
      contrastText: '#FFFFFF'
    },
    secondary: {
      lighter: greyColors[100],
      100: greyColors[100],
      200: greyColors[200],
      light: greyColors[300],
      400: greyColors[400],
      main: greyColors[500]!,
      600: greyColors[600],
      dark: greyColors[700],
      800: greyColors[800],
      darker: greyColors[900],
      A100: greyColors[0],
      A200: greyColors.A400,
      A300: greyColors.A700,
      contrastText: greyColors[0]
    },
    error: {
      lighter: red[0],
      light: red[2],
      main: red[4],
      dark: red[7],
      darker: red[9],
      contrastText
    },
    warning: {
      lighter: gold[0],
      light: gold[3],
      main: gold[5],
      dark: gold[7],
      darker: gold[9],
      contrastText: greyColors[100]
    },
    info: {
      lighter: cyan[0],
      light: cyan[3],
      main: cyan[5],
      dark: cyan[7],
      darker: cyan[9],
      contrastText
    },
    success: {
      lighter: green[0],
      light: green[3],
      main: green[5],
      dark: green[7],
      darker: green[9],
      contrastText
    },
    grey: greyColors
  };
};

export default Default;
