import { MoonOutlined, QuestionCircleOutlined, SunOutlined } from '@ant-design/icons';
import { IconButton, Popover, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import useAuth from 'hooks/useAuth';
import useConfig from 'hooks/useConfig';
import DrawerHeader from 'layout/MainLayout/Drawer/DrawerHeader';
import { useEffect, useState } from 'react';
import UserServicesInstance from 'services/services.users';
import { MenuOrientation, ThemeMode } from 'types/config';
import Profile from './Profile';
import UserClockTime from './UserClockTime';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { menuOrientation, onChangeMode, mode } = useConfig();
  const loggedInUser = useAuth();

  const [activeTheme, setActiveTheme] = useState(ThemeMode.LIGHT);

  useEffect(() => {
    if (loggedInUser) setActiveTheme(loggedInUser.user?.theme_mode as ThemeMode);
  }, []);

  const checkPermission = (): boolean => {
    if (loggedInUser.user?.rate_info?.pay_type !== 'hourly') return false;
    return (
      !!loggedInUser.user?.permissions?.some((eachPermission) => ['upsertPunch', 'getPunch'].includes(eachPermission)) &&
      loggedInUser.user.rate_info?.punch_type !== 'auto/system_calculator'
    );
  };

  // const punching: TPunchProps = useSelector((state) => state.punching);
  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const matchUpSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  // const megaMenu = useMemo(() => <MegaMenuSection />, []);
  //  Open Pop up Over
  // Popover state for tooltip replacement
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleThemeChange = async () => {
    setActiveTheme(() => (activeTheme === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT));
    onChangeMode(activeTheme === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT);
    await UserServicesInstance.switchThemeMode(
      loggedInUser.user?._id as string,
      activeTheme === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <div className="flex justify-between w-full">
      <div className="flex items-center w-full">
        {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && <DrawerHeader open={true} />}
        {loggedInUser.user?.company_name && (
          <Typography
            sx={{
              fontWeight: 'bold'
            }}
            className={`break-words px-1 text-left ${matchUpSm ? 'text-base' : ''}`}
          >
            {loggedInUser.user?.company_name}
          </Typography>
        )}
        {/* Popover triggered by icon */}
        <QuestionCircleOutlined onClick={handleIconClick} className="cursor-pointer text-black-600 ml-2" />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left'
          }}
          classes={{
            paper: 'ml-2 shadow-lg'
          }}
        >
          {/* Content inside popover */}
          <p className="p-4">
            Click&nbsp;
            <a
              href="https://drive.google.com/file/d/16kqnyW2sHCdWdWQmmvXGBSITGQszNJJi/preview"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              here
            </a>
            &nbsp;to know how it works.
          </p>
        </Popover>
      </div>
      {/* {<Box sx={{ width: '100%', ml: 1 }} />} */}
      <div className="flex items-center justify-end w-fit gap-x-3">
        <IconButton onClick={handleThemeChange}>{mode === ThemeMode.DARK ? <SunOutlined /> : <MoonOutlined />}</IconButton>

        {checkPermission() && <UserClockTime />}
        <Profile />
      </div>
    </div>
  );
};

export default HeaderContent;
