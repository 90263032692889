import { Card, CardContent, CardHeader, Typography, useTheme } from '@mui/material';
import { TOpenInvoicesReport } from 'pages/Reports/types/types.report';
import ReactApexChart from 'react-apexcharts';
import { formateData } from 'utils/common';

const TotalOpenInvoicesReportCard = ({
  reportData,
  invoiceData
}: {
  reportData: TOpenInvoicesReport;
  invoiceData: { title: string; data: number; color: string }[];
}) => {
  //---------------------------constants-----------------------
  const theme = useTheme();

  return (
    <Card className="max-w-md p-4 space-y-3">
      {/*----------------------------Header--------------------------- */}
      <CardHeader
        title={<div className="text-center text-xl text-gray-600">Total Open Invoices</div>}
        subheader={
          <Typography variant="subtitle1" className="text-center text-lg">
            {formateData(reportData.total_open_invoices ?? 0, '$')}
          </Typography>
        }
      />
      <Typography sx={{ color: theme.palette.error.main }} className="text-center">
        The Above # Includes {formateData(reportData.sales_tax ?? 0, '$')} of uncollected sales tax
      </Typography>
      <CardContent>
        {!(
          Number(reportData[0].data.toFixed(2)) === 0 &&
          Number(reportData[1].data.toFixed(2)) === 0 &&
          Number(reportData[2].data.toFixed(2)) === 0
        ) && (
          <ReactApexChart
            type="donut"
            options={{
              labels: [reportData[0].title, reportData[1].title, reportData[2].title],
              legend: { show: false },
              colors: [theme.palette.error.main, theme.palette.warning.main, theme.palette.success.main]
            }}
            series={[Number(reportData[0].data.toFixed(2)), Number(reportData[1].data.toFixed(2)), Number(reportData[2].data.toFixed(2))]}
          />
        )}

        <div className={`text-center w-full sm:divide-x space-y-4`}>
          {invoiceData.map((singleInfo: { title: string; data: number; color: string }) => {
            return (
              <div
                className={`flex-1 flex-col-reverse justify-between sm:flex-col cursor-pointer items-center shadow-lg shadow-slate-200 rounded-lg min-w-max w-full p-4`}
              >
                <Typography className="text-xs text-gray-500">{singleInfo.title}</Typography>
                <Typography className="text-2xl font-semibold" color={singleInfo.color}>
                  {formateData(singleInfo.data ?? 0, '$')}
                </Typography>
              </div>
            );
          })}
        </div>
      </CardContent>
    </Card>
  );
};

export default TotalOpenInvoicesReportCard;
