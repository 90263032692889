import axiosServices from 'utils/axios';
import { IResponseWithUoms } from './types/types.services';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import TUomData from 'components/tables/interfaces/uomTableInterface';
import { AxiosRequestConfig } from 'axios';
import { ISearch } from 'components/filters/SearchFilter';

class UomServices {
  getAllUom = async (paginationData?: { page: number; rowsPerPage: number }, searchData?: ISearch | null) => {
    const page = paginationData?.page && paginationData?.page + 1;
    const limit = paginationData?.rowsPerPage && paginationData?.rowsPerPage;
    try {
      const response: IResponseWithUoms = await axiosServices.get('api/material/material-uom', {
        params: {
          ...(page && { page }),
          ...(limit && { limit }),
          ...(searchData && { filter: JSON.stringify(searchData) })
        },
        locationNeeded: false
      } as AxiosRequestConfig);

      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  deleteUom = async (uomId: string, uomName: string) => {
    try {
      const response: IResponseWithUoms = await axiosServices.delete(`api/material/delete-material-uom/${uomId}`, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Uom was successfully deleted`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  createUom = async (uomData: TUomData) => {
    try {
      const response: IResponseWithUoms = await axiosServices.post(`api/material/create-material-uom`, uomData, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Uom Created Successfully!`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { success: boolean; message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
    }
  };

  editUom = async (uomId: string, uomData: TUomData) => {
    try {
      const response: IResponseWithUoms = await axiosServices.put(`api/material/update-material-uom/${uomId}`, uomData, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Uom was Updated Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
}

const UomServicesInstance = new UomServices();
export default UomServicesInstance;
