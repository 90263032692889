import { AxiosRequestConfig } from 'axios';
import { ISearch } from 'components/filters/SearchFilter';
import {
  TCompanyEditPayloadData,
  TCompanyPayloadData,
  TGetCompaniesResponse,
  TGetSingleCompanyResponse
} from 'pages/SuperAdmin/types/types.company';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import axiosServices from 'utils/axios';

class CompanyServices {
  getAllCompanyData = async (paginationData?: { page: number; rowsPerPage: number } | null, searchData?: ISearch | null) => {
    try {
      const page = paginationData && paginationData?.page + 1;
      const limit = paginationData && paginationData?.rowsPerPage;

      const response: TGetCompaniesResponse = await axiosServices.get('api/company', {
        params: {
          ...(page && { page }),
          ...(limit && { limit }),
          ...(searchData && { filter: JSON.stringify(searchData) })
        },
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error) {
      console.log('Error in fetching companies data');
    }
  };
  getSingleCompanyData = async (companyId: string) => {
    try {
      const response: TGetSingleCompanyResponse = await axiosServices.get(`api/company/${companyId}`);
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error) {
      console.log('Error in fetching companies data');
    }
  };

  createCompany = async (values: TCompanyPayloadData) => {
    try {
      const response: { data: { success: boolean; data: any } } = await axiosServices.post('api/company', values);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Company Created Successfully',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: false
          })
        );

        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  deleteCompany = async (companyId: string, text?: string) => {
    try {
      const response: { data: { success: boolean } } = await axiosServices.delete(`api/company/${companyId}`, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Company was successfully deleted`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  suspendCompany = async (companyId: string) => {
    try {
      const response: { data: { success: boolean } } = await axiosServices.put(`api/company/suspend/${companyId}`, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Company suspended`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  activateCompany = async (companyId: string) => {
    try {
      const response: { data: { success: boolean } } = await axiosServices.put(`api/company/activate/${companyId}`, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Company Reactivated`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  editCompany = async (companyId: string, values: TCompanyEditPayloadData) => {
    try {
      const response: { data: { success: boolean } } = await axiosServices.put(`api/company/${companyId}`, values, {
        locationNeeded: false
      } as AxiosRequestConfig);

      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Company record updated`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  getCompanyMetaData = async (companyId: string) => {
    try {
      const response: TGetSingleCompanyResponse = await axiosServices.get(`api/company/meta-data/${companyId}`);
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error) {
      console.log('Error in fetching companies data');
    }
  };
}

const companyServicesInstance = new CompanyServices();
export default companyServicesInstance;
