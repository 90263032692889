import moment from 'moment';
import { indexedDbStoreNames } from 'utils/constants';

const storeNames = Object.values(indexedDbStoreNames);

class IndexedDBServices {
  public dbPromise: Promise<IDBDatabase>;
  public storeName: string;

  constructor(objectStoreName: string) {
    this.storeName = objectStoreName;

    this.dbPromise = new Promise((resolve, reject) => {
      const request = indexedDB.open('zippfleet-local-db', 2);

      request.onupgradeneeded = (event) => {
        console.log('Upgrading or creating the database');
        const db = request.result;

        storeNames.forEach((name) => {
          if (!db.objectStoreNames.contains(name)) {
            db.createObjectStore(name, { keyPath: 'date' });
          }
        });
      };

      request.onsuccess = () => {
        resolve(request.result);
      };

      request.onerror = () => {
        console.error('Cannot connect to zippfleet-local-db');
        reject(request.error);
      };
    });
  }

  async insertDataInIndexedDB(data: any) {
    const db = await this.dbPromise;
    const transaction = db.transaction(this.storeName, 'readwrite');
    const store = transaction.objectStore(this.storeName);

    store.put(data);
  }

  async getAllLocalData() {
    const db = await this.dbPromise;
    const transaction = db.transaction(this.storeName, 'readonly');
    const store = transaction.objectStore(this.storeName);

    const dataRequest = store.getAll(IDBKeyRange.only(moment().format('MM-DD-YYYY')));
    return new Promise((resolve) => {
      dataRequest.onsuccess = (query: any) => {
        resolve(query.target?.result?.[0]);
      };
    });
  }

  async clearObjectStore() {
    const db = await this.dbPromise;
    const transaction = db.transaction(this.storeName, 'readwrite');
    const store = transaction.objectStore(this.storeName);
    const clearRequest = store.clear();
    return new Promise((resolve) => {
      clearRequest.onsuccess = (query: any) => {
        console.log('object cleared successfully');
        resolve(true);
      };
    });
  }

  async getAllKeysOfStore() {
    const db = await this.dbPromise;
    const transaction = db.transaction(this.storeName, 'readwrite');
    const store = transaction.objectStore(this.storeName);
    const dataRequest = store.getAllKeys();
    return new Promise((resolve) => {
      dataRequest.onsuccess = (query: any) => {
        resolve(query.target?.result);
      };
    });
  }

  async deleteKey(key: string) {
    const db = await this.dbPromise;
    const transaction = db.transaction(this.storeName, 'readwrite');
    const store = transaction.objectStore(this.storeName);
    const dataRequest = store.delete(key);
    return new Promise((resolve) => {
      dataRequest.onsuccess = (query: any) => {
        console.log('deleted successfully');
        resolve(true);
      };
    });
  }
}

export default IndexedDBServices;
