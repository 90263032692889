import { AppstoreOutlined, CloseCircleFilled, UserOutlined } from '@ant-design/icons';
import { AppBar, Dialog, DialogContent, IconButton, Slide, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { motion } from 'framer-motion';
import AddActionPage from 'pages/addAction/AddActionPage';
import { TCustomerData } from 'pages/customers/types/type.customersPage';
import { forwardRef, useEffect } from 'react';
import { dispatch, useSelector } from 'store';
import { setActionTitle } from 'store/reducers/customReducers/customerActions';
// import AddJobPopup from './AddJobPopup';

type TAddActionProp = {
  onClose: () => void;
  addActionPopup: boolean;
  customerData: TCustomerData;
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const motionContainer = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
};

const motionItem = {
  hidden: { x: -20, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1
  }
};

const AddActionPopup = (props: TAddActionProp) => {
  const theme = useTheme();
  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const customerAction = useSelector((state) => state.customerActions);

  const handleClose = () => {
    dispatch(setActionTitle(''));

    props?.onClose();
  };

  useEffect(() => {
    // Push a state to history to detect back navigation

    window.history.pushState(null, '', window.location.href);

    const handlePopState = () => {
      // Close the dialog instead of navigating back
      handleClose();
    };

    // Add popstate event listener
    window.addEventListener('popstate', handlePopState);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <Dialog
      {...(customerAction.action_title ? { fullScreen: true } : { fullWidth: true, maxWidth: 'xl' })}
      open={props.addActionPopup}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }} color="primary">
        <Toolbar>
          <motion.div variants={motionContainer} initial="hidden" animate="visible" className="w-full flex items-center justify-between">
            <motion.div variants={motionItem} className="flex items-center max-w-max gap-x-2">
              <UserOutlined />
              <Typography className="max-w-max" variant={matchDownSm ? 'h5' : 'h4'}>
                {props.customerData.first_name}
              </Typography>
            </motion.div>

            {customerAction.action_title && (
              <motion.div variants={motionItem} className="max-w-max flex items-center gap-x-2">
                <AppstoreOutlined />
                <Typography className="max-w-max" variant={matchDownSm ? 'h5' : 'h4'} component="div">
                  Add {customerAction.action_title}
                </Typography>
              </motion.div>
            )}
            <motion.div variants={motionItem} className="min-w-[40px] flex items-center gap-x-2">
              <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                <CloseCircleFilled />
              </IconButton>
            </motion.div>
          </motion.div>
        </Toolbar>
      </AppBar>
      <DialogContent className="sm:p-12 p-4">
        <AddActionPage customerData={props.customerData} onSuccess={handleClose} />
      </DialogContent>
    </Dialog>
  );
};

export default AddActionPopup;
