import { FormControlLabel, FormHelperText, Grid, InputLabel, Radio, RadioGroup } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useQuery } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import { FormikContextType, getIn, useFormikContext } from 'formik';
import moment from 'moment';
import { TGetAppointmentWidgetData } from 'pages/Settings/types/types.autoAppointment';
import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import AutoAppointmentServiceInstance from 'services/services.autoAppointment';
import { TAppointmentWidget } from '../types/types.appointmentWidget';
import { EstimateFormData } from '../types/types.estimateFormWidget';

const AppointmentDateAndTimeSelection = ({
  appointmentConfigData,
  activeStep
}: {
  appointmentConfigData: TGetAppointmentWidgetData['data']['data'];
  activeStep: number;
}) => {
  //-----------------constants---------------
  const { values, setFieldValue, touched, errors }: FormikContextType<TAppointmentWidget & EstimateFormData> = useFormikContext();
  const [date, setDate] = useState(moment(values.schedule_date).isValid() ? moment(values.schedule_date) : new Date().toISOString());
  const [availableDates, setAvailableDates] = useState<string[]>([]);

  const location = useLocation();
  const serviceId = new URLSearchParams(location.search).get('service_id');
  const companyId = new URLSearchParams(location.search).get('company_id');
  //-----------------useQuery---------------
  const { data: serviceAndAeraAvailableDates, isFetching: isAvailableDatesFetching } = useQuery({
    queryKey: ['service_and_area_available_date', date],
    queryFn: () => {
      if (!!serviceId && !!companyId)
        return AutoAppointmentServiceInstance.getServiceAndAreaAvailableDates(
          companyId,
          values.area.id,
          serviceId,
          moment(date).month() + 1,
          moment(date).year(),
          true
        );
    },
    enabled: moment(date).isValid()
  });
  //-----------------Handlers---------------
  const handleScheduleTimeChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    const dates = value.split('&');
    setFieldValue('appointment_timeblock', { start_time: dates[0], end_time: dates[1] });
  };
  const disableNotAvailableDates = (day: Dayjs) => {
    const formatedDate = day.format('YYYY-MM-DD');
    if ((!!availableDates && !availableDates.includes(formatedDate)) || day < dayjs()) {
      return true;
    }
    const currentDateData = serviceAndAeraAvailableDates?.find(
      (singleDate) => moment(singleDate.date).format('YYYY-MM-DD') === formatedDate
    );
    if (!!currentDateData) {
      if (appointmentConfigData.auto_appointment_schedule.schedule.type !== 'hour')
        return appointmentConfigData.auto_appointment_schedule.scheduling_limit.by_job_count
          ? currentDateData.count >= appointmentConfigData.auto_appointment_schedule.scheduling_limit.limit
          : currentDateData.price >= appointmentConfigData.auto_appointment_schedule.scheduling_limit.limit;
      return appointmentConfigData.auto_appointment_schedule.scheduling_limit.by_job_count
        ? !currentDateData.time_blocks.find(
            (singleTimeBlock) => singleTimeBlock.count < appointmentConfigData.auto_appointment_schedule.scheduling_limit.limit
          )
        : !currentDateData.time_blocks.find(
            (singleTimeBlock) => singleTimeBlock.price < appointmentConfigData.auto_appointment_schedule.scheduling_limit.limit
          );
    }
    return false;
  };
  //----------------useEffect--------------
  useEffect(() => {
    if (!!serviceAndAeraAvailableDates) {
      const dummyAvailableDates = serviceAndAeraAvailableDates.map((singleAvavilableDate) => singleAvavilableDate.date);

      setAvailableDates(dummyAvailableDates);
    }
  }, [serviceAndAeraAvailableDates]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <InputLabel>Schedule Date*</InputLabel>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            loading={isAvailableDatesFetching}
            className="w-full"
            disabled={activeStep !== 1}
            shouldDisableDate={disableNotAvailableDates}
            onMonthChange={(month: dayjs.Dayjs) => setDate(month.format('YYYY-MM'))}
            value={values.schedule_date.length ? dayjs(values.schedule_date) : null}
            onChange={(newValue: Dayjs | null) => {
              if (newValue?.isValid()) setFieldValue('schedule_date', newValue.toISOString());
            }}
          />
          {getIn(touched, 'schedule_date') && getIn(errors, 'schedule_date') && (
            <FormHelperText error id="schedule_date">
              {getIn(errors, 'schedule_date')}
            </FormHelperText>
          )}
        </LocalizationProvider>
      </Grid>

      {appointmentConfigData.auto_appointment_schedule.schedule.type === 'hour' && !!values.schedule_date.length && (
        <Grid item xs={12} sm={activeStep === 2 ? 6 : 12} className="flex justify-start">
          <div>
            <InputLabel>Schedule Time*</InputLabel>
            <RadioGroup
              value={`${values?.appointment_timeblock?.start_time}&${values?.appointment_timeblock?.end_time}`}
              name="appointment_timeblock"
              onChange={handleScheduleTimeChange}
            >
              {appointmentConfigData.auto_appointment_schedule.schedule.type === 'hour' &&
                appointmentConfigData?.auto_appointment_schedule?.schedule?.time_blocks?.map((singleTimeBlock) => (
                  <FormControlLabel
                    value={`${singleTimeBlock.start_time}&${singleTimeBlock.end_time}`}
                    control={
                      <Radio
                        disabled={
                          activeStep !== 1 ||
                          serviceAndAeraAvailableDates?.some(
                            (singleAvailableDate) =>
                              singleAvailableDate.date === moment(values.schedule_date).format('YYYY-MM-DD') &&
                              singleAvailableDate.time_blocks.some((timeBlock) => {
                                const isMatchingTimeBlock =
                                  timeBlock.start_time === singleTimeBlock.start_time && timeBlock.end_time === singleTimeBlock.end_time;

                                if (isMatchingTimeBlock) {
                                  const schedulingLimit = appointmentConfigData.auto_appointment_schedule.scheduling_limit.limit;

                                  if (appointmentConfigData.auto_appointment_schedule.scheduling_limit.by_job_count) {
                                    return timeBlock.count >= schedulingLimit;
                                  } else {
                                    return timeBlock.price >= schedulingLimit;
                                  }
                                }

                                return false;
                              })
                          )
                        }
                      />
                    }
                    label={`${moment(singleTimeBlock.start_time).format('hh:mm A')} - ${moment(singleTimeBlock.end_time).format(
                      'hh:mm A'
                    )}`}
                  />
                ))}
              {getIn(touched, 'appointment_timeblock.start_time') && getIn(errors, 'appointment_timeblock.start_time') && (
                <FormHelperText error id="appointment_timeblock.start_time">
                  {getIn(errors, 'appointment_timeblock.start_time')}
                </FormHelperText>
              )}
            </RadioGroup>
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default AppointmentDateAndTimeSelection;
