import { CloseCircleFilled } from '@ant-design/icons';
import { Typography } from '@mui/material';
import {
  AppBar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  IconButton,
  Slide,
  Toolbar
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, useEffect, useState } from 'react';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type TReportsPermissionsProps = {
  title: string;
  open: boolean;
  reportsPermissionData: string[];
  existingPermissions: string[];
  onSave: (arg0: string[]) => void;
  onClose: () => void;
};

type ReportItem = {
  value: string;
  label: string;
};

const ReportsPermissions = (props: TReportsPermissionsProps) => {
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);

  useEffect(() => {
    if (props.existingPermissions) {
      setSelectedPermissions(props.existingPermissions);
    }
  }, [props.existingPermissions]);

  const convertArray = (): ReportItem[] => {
    return props.reportsPermissionData.map((item) => {
      // Remove the "get" prefix and then split the camelCase string into words
      const words = item
        .replace('get', '')
        .replace(/([A-Z])/g, ' $1')
        .trim();

      // Capitalize the first letter of each word and join them with a space
      const label = words.charAt(0).toUpperCase() + words.slice(1);
      if (label === 'Reports') {
        return { value: item, label: 'Reports Page' };
      }

      return { value: item, label: label };
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, permission: string) => {
    setSelectedPermissions((prevSelected) => {
      const newPermission = [...prevSelected];
      if (checked) {
        return [...newPermission, permission];
      } else {
        return newPermission.filter((singleMaterial) => singleMaterial !== permission);
      }
    });
  };

  const handleSave = () => {
    props.onSave(selectedPermissions);
    props.onClose();
  };
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setSelectedPermissions((prevSelectedPermission) => {
        return [
          ...prevSelectedPermission,
          ...props.reportsPermissionData.filter((singleReportPermission) => !prevSelectedPermission.includes(singleReportPermission))
        ];
      });
    } else {
      setSelectedPermissions((prevSelectedPermission) => {
        return prevSelectedPermission.filter((singleSelectedPermission) => !props.reportsPermissionData.includes(singleSelectedPermission));
      });
    }
  };
  return (
    <Dialog maxWidth="xs" fullWidth open TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <div className="w-full flex items-center text-lg">{props.title}</div>
          <IconButton edge="start" color="inherit" onClick={() => props.onClose()} aria-label="close">
            <CloseCircleFilled />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <FormGroup aria-label="reports" row={true} className="flex flex-col w-full ">
          <FormControlLabel
            key="select_all"
            control={
              <Checkbox
                checked={!props.reportsPermissionData.some((eachReportPermission) => !selectedPermissions.includes(eachReportPermission))}
                onChange={(event) => handleSelectAll(event, event.target.checked)}
              />
            }
            label={<Typography className="font-semibold">All Reports</Typography>}
          />
          {convertArray()?.map((eachReportPermission) => (
            <FormControlLabel
              key={eachReportPermission.label}
              control={
                <Checkbox
                  checked={selectedPermissions.some((singleMaterialData) => singleMaterialData === eachReportPermission.value)}
                  onChange={(event) => handleCheckboxChange(event, event.target.checked, eachReportPermission.value)}
                />
              }
              label={eachReportPermission.label}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportsPermissions;
