import { lazy, Suspense } from 'react';

// project import
import Loadable from 'components/Loadable';
import Loader from 'components/Loader';
import CommonLayout from 'layout/CommonLayout';
import MainLayout from 'layout/MainLayout';
import DashboardPage from 'pages/Dashboard/DashboardPage';
import InvoicePage from 'pages/Invoice/InvoicePage';
import ClockInOutPage from 'pages/PunchingPages/ClockInOutPage';
import PunchList from 'pages/PunchingPages/PunchList';
import PunchViewPage from 'pages/PunchingPages/PunchViewPage';
import ReportsPage from 'pages/Reports/ReportsPage';
import ViewServiceAndProduct from 'pages/Settings/SettingTabs/CategoriesProductAndServicesTabs/ViewServiceAndProduct';
import AdminLogsHistory from 'pages/SuperAdmin/AdminLogsHistory';
import LocationPermissionGuard from 'pages/auth/LocationPermissionGuard';
import CalendarPage from 'pages/calendar/CalendarPage';
import CustomerView from 'pages/customers/CustomerView';
import FollowupPage from 'pages/followup/FollowupPage';
import JobsPage from 'pages/jobs/JobsPage';
import JobsViewPage from 'pages/jobs/JobsViewPage';
import ChecksCollected from 'pages/payrolls/ChecksCollected';
import PayrollUserPunchList from 'pages/payrolls/PayrollUserPunchList';
import UserPayrollListSingleDayView from 'pages/payrolls/UserPayrollListSingleDayView';
import UserPayrollRangeView from 'pages/payrolls/UserPayrollRangeView';
import ActivityTracker from 'sections/auth/ActivityTracker';
import ErrorBoundary from 'utils/ErrorHandlers/ErrorBoundary';
const AuthGuard = Loadable(lazy(() => import('utils/route-guard/AuthGuard')));

// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

// render - sample page
const SettingsPage = Loadable(lazy(() => import('pages/Settings/SettingsPage')));
const CustomerPage = Loadable(lazy(() => import('pages/customers/CustomersPage')));
const ExpensePage = Loadable(lazy(() => import('pages/expenses/ExpensePage')));
const PayRollPage = Loadable(lazy(() => import('pages/payrolls/PayrollPage')));
const SuperAdminPage = Loadable(lazy(() => import('pages/SuperAdmin/SuperAdminPage')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <Suspense fallback={<Loader />}>
          <AuthGuard>
            <ErrorBoundary>
              <ActivityTracker>
                <MainLayout />
              </ActivityTracker>
            </ErrorBoundary>
          </AuthGuard>
        </Suspense>
      ),
      children: [
        {
          path: 'dashboard',
          element: <DashboardPage />
        },
        {
          path: 'reports/:report_filter',
          element: <ReportsPage />
        },
        {
          path: 'customers/view/:id/:tab',
          element: <CustomerView />
        },
        {
          path: 'customers',
          element: <CustomerPage />
        },
        {
          path: 'jobs',
          element: <JobsPage />
        },
        {
          path: 'jobs/view/:id/:tab',
          element: <JobsViewPage />
        },

        {
          path: 'followup',
          element: <FollowupPage />
        },
        {
          path: 'expenses',
          element: <ExpensePage />
        },
        {
          path: 'payroll',
          element: <PayRollPage />
        },
        {
          path: 'payroll/timesheet/:id',
          element: <PayrollUserPunchList />
        },
        {
          path: 'payroll/view/:id',
          element: <UserPayrollRangeView />
        },
        {
          path: 'payroll/view/:id/:date',
          element: <UserPayrollListSingleDayView />
        },
        {
          path: 'payroll/checks-collected/:id',
          element: <ChecksCollected />
        },
        {
          path: 'company',
          element: <SuperAdminPage />
        },
        {
          path: 'company/logs/:id',
          element: <AdminLogsHistory />
        },
        {
          path: 'settings/:tab',
          element: <SettingsPage />
        },
        {
          path: 'settings',
          element: <SettingsPage />
        },
        {
          path: 'settings/categories-product-services/service-products/view/:service_product_id',
          element: <ViewServiceAndProduct />
        },
        {
          path: 'settings/:tab/:sub_tab',
          element: <SettingsPage />
        },
        {
          path: 'punch',
          element: <ClockInOutPage />
        },
        {
          path: 'punch/list',
          element: <PunchList />
        },
        {
          path: 'punch/view/:id',
          element: <PunchViewPage />
        },
        {
          path: 'calendar',
          element: <CalendarPage />
        },
        {
          path: 'calendar/:calendar_tab',
          element: <CalendarPage />
        },
        {
          path: 'invoices',
          element: <InvoicePage />
        }
      ]
    },
    {
      path: '/error',
      element: <CommonLayout />,
      children: [
        {
          path: 'grant_location_access',
          element: <LocationPermissionGuard />
        }
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: 'grant_location_access',
          element: <LocationPermissionGuard />
        },
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    },
    {
      path: '*',
      element: <MaintenanceError />
    }
  ]
};

export default MainRoutes;
