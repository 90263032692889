import { AxiosRequestConfig } from 'axios';
import { ISearch } from 'components/filters/SearchFilter';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import axiosServices from 'utils/axios';
import { IResponseGetLogs, TLogs } from './types/types.services';

type TLog = {
  description: string;
  module: string;
  action: string;
  is_customer_log_also: boolean;
  customer?: { id: string; name: string };
  job?: { id: string; name: string };
};
class LogsServices {
  getAllLogs = async (displayViewedLogs: boolean, paginationData?: any | null, searchData?: ISearch | null) => {
    const page = paginationData?.page + 1;
    const limit = paginationData?.rowsPerPage;
    try {
      const response = await axiosServices.get(`api/log`, {
        locationNeeded: false,
        params: {
          ...(page && { page }),
          ...(limit && { limit }),
          ...(searchData && { filter: JSON.stringify(searchData) }),
          ...(displayViewedLogs !== undefined && { display_viewed_logs: displayViewedLogs })
        }
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        return response.data.data as IResponseGetLogs;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  createLog = async (data: TLog) => {
    try {
      const response: any = await axiosServices.post(`api/log`, data, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  createBulkLog = async (data: TLogs) => {
    try {
      const response: any = await axiosServices.post(`api/log/bulk`, data, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  restoreDeleted = async (id: string) => {
    try {
      const response = await axiosServices.put(`api/log/restore-delete/${id}`);
      if (response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Restored Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
}

const LogsServicesInstance = new LogsServices();
export default LogsServicesInstance;
