import { DeleteOutlined, DownloadOutlined, EyeFilled, MessageOutlined } from '@ant-design/icons';
import { ButtonGroup, IconButton, ImageListItem, ImageListItemBar, ListSubheader, Typography, useTheme } from '@mui/material';
import mime from 'mime';
import { useNavigate } from 'react-router';
import { TMedia } from 'types/globalTypes/type.media';
import { getFileNameFromURL, handleDownload } from 'utils/common';
import CustomTooltip from './CustomTooltip';
import { universalRenerMedia } from './UniversalRenderMedia';

const MediaItemList = ({
  mediaData,
  module,
  activeTab,
  handleDelete
}: {
  mediaData: {
    [date: string]: TMedia[];
  };
  module: string;
  activeTab?: string;
  handleDelete: (mediaId: string) => void;
}) => {
  //-----------------constants---------------

  const navigate = useNavigate();
  const theme = useTheme();
  const isRelevantMedia = module === 'jobs' && activeTab !== 'media';

  return (
    <div className="space-y-5 ">
      {Object.keys(mediaData)?.map((eachDate) => (
        <div key={eachDate}>
          <ListSubheader className="font-bold">{eachDate}</ListSubheader>
          <div className="flex items-end sm:flex-wrap flex-nowrap sm:flex-row flex-col w-full">
            {mediaData?.[eachDate]
              ?.map((item, index) => {
                const fileName = getFileNameFromURL(item.url);
                const mimeType = mime.getType(item.url)?.split('/');
                return (
                  <div className="p-2 xl:w-1/3 md:w-1/2 w-full">
                    <ImageListItem
                      key={fileName}
                      className="flex items-center justify-start !bg-gray-100 rounded-md border"
                      sx={{ height: '100px', paddingBottom: mimeType?.[0] === 'audio' ? '35px' : '0px' }}
                    >
                      {universalRenerMedia(item.url, index)}
                      <ImageListItemBar
                        position={isRelevantMedia ? 'top' : 'bottom'}
                        className="bg-black/50 flex items-end"
                        title={
                          module === 'customers' ? (
                            <CustomTooltip message={item?.job?.name ?? ''} props={{ placement: 'left' }}>
                              <Typography
                                className="font-semibold text-md cursor-pointer text-white"
                                sx={{ '&:hover': { color: theme.palette.primary.main }, color: 'black' }}
                                onClick={() => navigate(`/jobs/view/${item.job?.id}/job_status`)}
                              >
                                {item?.job?.name}
                              </Typography>
                            </CustomTooltip>
                          ) : (
                            <></>
                          )
                        }
                        subtitle={
                          activeTab === 'media' ? (
                            <Typography className="text-white text-xs">
                              {!!item?.created_by?.name ? 'By ' + item.created_by.name : ''}
                            </Typography>
                          ) : (
                            <></>
                          )
                        }
                        actionIcon={
                          <ButtonGroup className="mb-[0.2rem]" variant="outlined">
                            <IconButton
                              size="medium"
                              color="info"
                              className="hover:bg-white"
                              LinkComponent={'a'}
                              href={item.url}
                              target="_blank"
                            >
                              <EyeFilled />
                            </IconButton>
                            <IconButton size="medium" color="success" className="hover:bg-white" onClick={() => handleDownload(item.url)}>
                              <DownloadOutlined />
                            </IconButton>
                            {!!item?.comment && (
                              <CustomTooltip message={item?.comment ?? ''} props={{ placement: 'bottom', arrow: true }}>
                                <IconButton size="medium" color="success" className="hover:bg-white">
                                  <MessageOutlined />
                                </IconButton>
                              </CustomTooltip>
                            )}
                            {(activeTab === 'media' || module === 'frequently_used_media') && (
                              <IconButton size="medium" color="error" className="hover:bg-white" onClick={() => handleDelete(item._id)}>
                                <DeleteOutlined />
                              </IconButton>
                            )}
                          </ButtonGroup>
                        }
                        actionPosition="right"
                      />
                    </ImageListItem>
                  </div>
                );
              })
              .reverse()}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MediaItemList;
