import { Editor } from '@tinymce/tinymce-react';

const AddTermAndConditionFrom = (props: { handleTermAndConditionsFormData: (values: string) => void; termAndConditionData: string }) => {
  const { handleTermAndConditionsFormData, termAndConditionData } = props;

  return (
    <Editor
      apiKey={process.env.REACT_APP_TINY_MCE_KEY}
      id={`term_and_condition`}
      value={termAndConditionData}
      onEditorChange={(value: string) => {
        handleTermAndConditionsFormData(value);
      }}
      init={{
        height: 400,
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'preview',
          'anchor',
          'searchreplace',
          'visualblocks',
          'fullscreen',
          'insertdatetime',
          'media',
          'table',
          'help',
          'wordcount'
        ],
        toolbar: `undo redo | casechange blocks | bold italic backcolor | \
alignleft aligncenter alignright alignjustify | \
bullist numlist checklist outdent indent | removeformat | a11ycheck code table help`
      }}
    />
  );
};

export default AddTermAndConditionFrom;
