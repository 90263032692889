import { EyeInvisibleOutlined, EyeOutlined, LoadingOutlined, MailOutlined, QuestionCircleFilled, SaveOutlined } from '@ant-design/icons';
import {
  Alert,
  Autocomplete,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography
} from '@mui/material';

import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';
import Avatar from 'components/@extended/Avatar';
import CustomTooltip from 'components/CustomTooltip';
import FormSubmissionAlertMessage from 'components/FormSubmissionAlertMessage';
import ImageCrop from 'components/image-crop/ImageCrop';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import ModulePermissionTable from 'components/tables/ModulePermissionTable';
import TUserData from 'components/tables/interfaces/userTableInterface';
import dayjs, { Dayjs } from 'dayjs';
import { FormikValues, getIn, useFormik } from 'formik';
import useAuth from 'hooks/useAuth';
import useConfig from 'hooks/useConfig';
import { SyntheticEvent, useEffect, useState } from 'react';
import { CountryData } from 'react-phone-input-2';
import UserServicesInstance from 'services/services.users';
import { handlePhoneNumberInputChange } from 'utils/common';
import {
  availableRoles,
  availableStatus,
  companyEmailTooltipMessage,
  minutesScale,
  notificationSendingChannels,
  notifyProposalApprovedItems,
  payType,
  personalEmailTooltipMessage,
  phoneTypes,
  punchTypes
} from 'utils/constants';
import { trimString } from 'utils/trimFc';
import * as Yup from 'yup';
import GoogleMapAutocomplete from './google-map-autocomplete';

type TNotificationOptionsCheck = {
  proposal_approval_notification?: number;
  proposal_approval_notification_sms_email: number[];
  estimate_assigned_notification: number[];
  job_assigned_notification: number[];
  auto_schedules_notification: number[];
  estimate_submitted_through_website_notification?: number[];
  employee_punch_card_notification?: number[];
};

const AddUserForm = (props: { onSuccess: () => void; existingData: TUserData | undefined; isEditMode?: boolean }) => {
  const theme = useTheme();
  const { permissions } = useAuth();
  const themeConfig = useConfig();
  // ----------------------------------------Constants----------------------------------------
  const { existingData, isEditMode = false, onSuccess = () => {} } = props;
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [fileUploadDialog, setFileUploadDialog] = useState<boolean>(false);
  const [profilePicture, setProfilePicture] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [modulePermissionsData, setModulePermissionsData] = useState<string[]>([]);
  const [isEstimator, setIsEstimator] = useState(false);
  const [notificationOptionsCheck, setNotificationOptionsCheck] = useState<TNotificationOptionsCheck>({
    proposal_approval_notification_sms_email: [],
    estimate_assigned_notification: [],
    job_assigned_notification: [],
    auto_schedules_notification: [],
    proposal_approval_notification: 0
  });

  const formatUserData = (exisitingData: TUserData) => {
    return {
      profile_picture: exisitingData?.profile_picture,
      ...(!isEditMode && { password: '' }),
      ...(existingData?.role === 'company_admin' && { personal_email: existingData.personal_email }),
      first_name: exisitingData.first_name,
      last_name: exisitingData.last_name,
      email: exisitingData.email,
      role: exisitingData.role,
      status: exisitingData.status,
      phone: exisitingData.phone,
      phone_country_code: exisitingData.phone_country_code,
      phone_type: exisitingData.phone_type,
      phone_extension: exisitingData.phone_extension,
      date_hired: exisitingData.date_hired === null ? '' : exisitingData.date_hired,
      inactivity: exisitingData.inactivity,
      birthdate: exisitingData.birthdate === null ? '' : exisitingData.birthdate,
      is_fw_estimator: Boolean(exisitingData.is_fw_estimator),
      address: {
        address1: exisitingData.address.address1,
        address2: exisitingData.address.address2,
        city: exisitingData.address.city,
        state: exisitingData.address.state,
        zip: exisitingData.address.zip,
        country: exisitingData.address.country
      },
      rate_info: {
        pay_type: exisitingData?.rate_info?.pay_type,
        weekly_salary: exisitingData.rate_info?.weekly_salary,
        hourly_rate: exisitingData.rate_info?.hourly_rate,
        punch_type: exisitingData.rate_info?.punch_type,
        residential_commission: exisitingData.rate_info?.residential_commission,
        commercial_commission: exisitingData.rate_info?.commercial_commission
      }
    };
  };

  /* --------FORMIK--------*/
  const formik = useFormik({
    initialValues: !isEditMode
      ? {
          first_name: '',
          ...(existingData?.role === 'company_admin' && { personal_email: '' }),
          last_name: '',
          email: '',
          password: '',
          role: 'office_worker',
          status: 1,
          phone_country_code: '',
          phone: '',
          phone_type: 'mobile',
          phone_extension: null as unknown as number,
          date_hired: '',
          inactivity: '15',
          birthdate: '',
          is_fw_estimator: false,
          address: {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            country: ''
          },
          rate_info: {
            pay_type: 'salary',
            weekly_salary: null as number | null,
            hourly_rate: null as number | null,
            punch_type: 'clock_in/clock_out',
            residential_commission: null as number | null,
            commercial_commission: null as number | null
          }
        }
      : { ...formatUserData(existingData as TUserData) },

    validationSchema: Yup.object().shape({
      first_name: Yup.string().max(255).required('First Name is required'),
      last_name: Yup.string().max(255).required('Last Name is required'),
      email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
      personal_email: existingData?.role === 'company_admin' ? Yup.string().email('Must be a valid email') : Yup.string().nullable(),

      password: !isEditMode
        ? Yup.string()
            .max(255)
            .min(8, 'Must be 8 characters or more')
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/,
              'Password should contain at least 1 Uppercase, 1 Lowercase letter, 1 number, any 1 specical character and it should not be less than 8 characters'
            )
            .required('Password is required')
        : Yup.string().nullable(),
      role: Yup.string().required('Atleast one role is required'),
      status: Yup.number().required('Status is required'),
      inactivity: Yup.string(),
      is_fw_estimator: Yup.boolean(),
      date_hired: Yup.string().when('rate_info.pay_type', ([pay_type], schema) => {
        if (pay_type === 'salary') {
          return schema.required('Date Hired is required.');
        }
        return schema.nullable();
      }),
      phone: Yup.string().min(5, 'Numbers should be at least 5 digits long').required('Phone is required'),
      phoneType: Yup.string(),
      address: Yup.object().shape({
        address1: Yup.string().required('Address is required'),
        address2: Yup.string(),
        city: Yup.string().required('Address City is required'),
        state: Yup.string().required('Address Sate is required'),
        zip: Yup.string().required('Address Zip is required'),
        country: Yup.string().required('Address Country is required')
      }),
      rate_info: Yup.object().shape({
        pay_type: Yup.string().required('Pay type is required.'),
        weekly_salary: Yup.number().when('pay_type', {
          is: (pay_type: string) => pay_type === 'salary',
          then: (schema) => schema.moreThan(0).required('Salary is required.'),
          otherwise: (schema) => schema.nullable()
        }),
        hourly_rate: Yup.number().when('pay_type', {
          is: (pay_type: string) => pay_type === 'hourly',
          then: (schema) => schema.moreThan(0).required('Hourly rate is required.'),
          otherwise: (schema) => schema.nullable()
        }),
        punch_type: Yup.string().when('pay_type', {
          is: (pay_type: string) => pay_type === 'hourly',
          then: (schema) => schema.required('Punch type is required.'),
          otherwise: (schema) => schema.nullable()
        }),
        residential_commission: Yup.number().when('pay_type', {
          is: (pay_type: string) => {
            return isEstimator && pay_type === 'commission';
          },
          then: (schema) => schema.required('Residential Commission is required.'),
          otherwise: (schema) => schema.nullable()
        }),
        commercial_commission: Yup.number()
          .moreThan(0)
          .when('pay_type', {
            is: (pay_type: string) => pay_type === 'commission' && isEstimator,
            then: (schema) => schema.required('Commercial Commission is required.'),
            otherwise: (schema) => schema.nullable()
          })
      })
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      // -----------submit function-----------
      if (
        notificationOptionsCheck.proposal_approval_notification !== undefined &&
        notificationOptionsCheck.proposal_approval_notification !== null &&
        notificationOptionsCheck.proposal_approval_notification !== 0 &&
        !notificationOptionsCheck['proposal_approval_notification_sms_email'].length
      ) {
        return;
      }
      setSubmitting(true);
      await handleUserFormSubmit(values);
      setStatus({ success: true });
      setSubmitting(false);
    }
  });

  useEffect(() => {
    console.log('Errors while creating user:', formik.errors);
  }, [formik.errors]);

  useEffect(() => {
    if (!isEditMode) {
      setModulePermissionsData(
        permissions?.users_permissions.find((userPermission) => userPermission.name === formik.values.role)?.permissions ?? []
      );
      return;
    }
    if (isEditMode && existingData) {
      if (existingData.profile_picture) {
        setProfilePicture(existingData.profile_picture);
      }
      if (existingData.permissions && existingData.permissions.length) {
        setModulePermissionsData(existingData.permissions);
      }

      if (existingData.role !== 'company_admin') {
        setNotificationOptionsCheck({
          proposal_approval_notification: existingData?.proposal_approval_notification,
          proposal_approval_notification_sms_email: existingData?.proposal_approval_notification_sms_email,
          estimate_assigned_notification: existingData?.estimate_assigned_notification,
          job_assigned_notification: existingData?.job_assigned_notification,
          estimate_submitted_through_website_notification: existingData.estimate_submitted_through_website_notification ?? [],
          auto_schedules_notification: existingData.auto_schedules_notification ?? []
        });
      } else {
        setNotificationOptionsCheck({
          proposal_approval_notification: existingData?.proposal_approval_notification,
          proposal_approval_notification_sms_email: existingData?.proposal_approval_notification_sms_email,
          estimate_assigned_notification: existingData?.estimate_assigned_notification,
          job_assigned_notification: existingData?.job_assigned_notification,
          estimate_submitted_through_website_notification: existingData.estimate_submitted_through_website_notification ?? [],
          employee_punch_card_notification: existingData.employee_punch_card_notification ?? [],
          auto_schedules_notification: existingData.auto_schedules_notification ?? []
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, existingData]);

  useEffect(() => {
    if (alertMessage) {
      const timeoutId = setTimeout(() => {
        setAlertMessage('');
      }, 4000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [alertMessage]);

  const getPunchTypes = (selectedRole: string | undefined) => {
    if (selectedRole !== 'office_worker') {
      return punchTypes.filter((type) => type.value !== 'auto/system_calculator');
    }
    return punchTypes;
  };

  // -------------------------Handlers--------------------------
  const handleClickShowPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowPassword((prev) => !prev);
  };

  const handlePermissionChange = (newPermissions: string[]) => {
    setModulePermissionsData(newPermissions);
  };

  const handleNotificationOptionsCheck = (event: SyntheticEvent<Element, Event>) => {
    event.preventDefault();
    const { name, value, checked } = event.target as HTMLInputElement;

    if (checked) {
      if (name === 'proposal_approval_notification') {
        setNotificationOptionsCheck((prev: TNotificationOptionsCheck) => ({ ...prev, [name]: Number(value) }));
      } else {
        if (Number(value) === 1 && !formik.values.phone?.length) {
          setAlertMessage("You can't click sms, you don't have a cell phone. First enter the cell phone.");

          return;
        }
        if (Number(value) === 2 && !formik.values.email?.length) {
          setAlertMessage("You can't click email, you don't have an email. First enter the email.");

          return;
        }
        const newCheckObject: any = { ...notificationOptionsCheck };
        Array.isArray(newCheckObject[name]) && (newCheckObject[name] = [...new Set([...newCheckObject[name], Number(value)])]);

        setNotificationOptionsCheck(newCheckObject);
      }
    } else {
      if (name === 'proposal_approval_notification') {
        setNotificationOptionsCheck((prev: TNotificationOptionsCheck) => ({ ...prev, [name]: null as unknown as number }));
      } else {
        const newCheckObject: any = { ...notificationOptionsCheck };
        Array.isArray(newCheckObject[name]) && (newCheckObject[name] = newCheckObject[name].filter((obj: any) => obj !== Number(value)));

        setNotificationOptionsCheck(newCheckObject);
      }
    }
  };

  const handleOpenFileUpload = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setFileUploadDialog(true);
  };

  const handleUploadedFile = (fileUrl: string) => {
    setProfilePicture(fileUrl);
    setFileUploadDialog(false);
  };
  const handleRoleChange = (event: SyntheticEvent<Element, Event>, newValue: { label: string; value: string }) => {
    if (formik.values.role === 'field_worker' && newValue.value !== 'field_worker') {
      setNotificationOptionsCheck((prevData) => {
        return { ...prevData, proposal_approval_notification: 0 };
      });
    }
    formik.setFieldValue('role', newValue.value);
    setIsEstimator(newValue.value === 'estimator' || (newValue.value === 'field_worker' && formik.values.is_fw_estimator));
    setModulePermissionsData(
      permissions?.users_permissions.find((userPermission) => userPermission.name === newValue.value)?.permissions ?? []
    );
    if (newValue.value === 'office_worker') {
      formik.setFieldValue('rate_info.pay_type', 'salary');
    }
  };

  const handlePayTypeChange = (event: SelectChangeEvent<string>) => {
    formik.handleChange(event);
    if (event.target.value === 'hourly') {
      const newPermissions = [...modulePermissionsData];
      newPermissions.push('upsertPunch', 'getPunch');
      const uniquePermissions = [...new Set(newPermissions)];
      setModulePermissionsData(uniquePermissions);
    } else {
      const newPermissions = [...modulePermissionsData.filter((eachPermission) => !['upsertPunch', 'getPunch'].includes(eachPermission))];
      setModulePermissionsData(newPermissions);
    }
  };
  const handleIsFwEstimator = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('is_fw_estimator', event.target.value === 'true' ? true : false);
    setIsEstimator(formik.values.role === 'estimator' || (formik.values.role === 'field_worker' && event.target.value === 'true'));
    let fwNEstPermissins: string[];
    if (formik.values.is_fw_estimator && formik.values.role === 'field_worker') {
      fwNEstPermissins = [
        ...(permissions?.users_permissions.find((userPermission) => userPermission.name === formik.values.role)?.permissions ?? []),
        ...(permissions?.users_permissions.find((userPermission) => userPermission.name === 'estimator')?.permissions ?? [])
      ];
    } else {
      fwNEstPermissins =
        permissions?.users_permissions.find((userPermission) => userPermission.name === formik.values.role)?.permissions ?? [];
    }
    setModulePermissionsData([...new Set(fwNEstPermissins)]);
  };
  // ---- form submit ----
  const handleUserFormSubmit = async (values: FormikValues) => {
    if (formik.values.role !== 'office_worker') {
      delete values.inactivity;
    }
    if (formik.values.phone_type !== 'office') delete values.phone_extension;

    const formattedData: any = {
      ...values,
      permissions: modulePermissionsData,
      ...notificationOptionsCheck,
      ...(profilePicture.length && { profile_picture: profilePicture })
    };
    if (formik.values.role === 'field_worker' && !formik.values.is_fw_estimator) {
      delete formattedData.proposal_approval_notification;
      if (formik.values.rate_info.pay_type === 'commission') {
        delete formattedData.rate_info.residential_commission;
        delete formattedData.rate_info.commercial_commission;
      }
    }

    if (formik.values.role !== 'field_worker') {
      delete formattedData.is_fw_estimator;
    }

    if (isEditMode && existingData && existingData._id) {
      const response = await UserServicesInstance.editUser(existingData?._id, formattedData);
      if (response) {
        onSuccess();
      }
    } else {
      const response = await UserServicesInstance.createUser(formattedData);
      if (response) {
        onSuccess();
      }
    }
  };

  // -------------------------Renders----------------------------------------
  const renderNotificationRadios = (jobRole: string, formik: any) => {
    switch (jobRole) {
      case 'estimator':
        return (
          <Grid item xs={12} sm={6} md={3}>
            <InputLabel htmlFor="estimate_assigned_notification">
              <Typography className="break-words">Notify Me When I Am Assigned To An Estimate</Typography>
            </InputLabel>
            <Stack direction={'row'} spacing={2}>
              {notificationSendingChannels.map((items) => {
                const checked: boolean =
                  Array.isArray(notificationOptionsCheck['estimate_assigned_notification']) &&
                  notificationOptionsCheck['estimate_assigned_notification'].includes(items.value);

                return (
                  <FormControlLabel
                    value={items.value}
                    name="estimate_assigned_notification"
                    id="estimate_assigned_notification"
                    color="primary"
                    onChange={(event: SyntheticEvent<Element, Event>) => {
                      handleNotificationOptionsCheck(event);
                    }}
                    checked={checked}
                    control={<Checkbox />}
                    label={items.label}
                  />
                );
              })}
            </Stack>
          </Grid>
        );
      case 'field_worker':
        return (
          <Grid item xs={12} sm={6} md={3}>
            <InputLabel htmlFor="job_assigned_notification">
              <Typography className="break-words">Notify Me When I Am Assigned To A Job</Typography>
            </InputLabel>
            <Stack direction={'row'} spacing={2}>
              {notificationSendingChannels.map((items) => {
                const checked: boolean =
                  Array.isArray(notificationOptionsCheck['job_assigned_notification']) &&
                  notificationOptionsCheck['job_assigned_notification'].includes(items.value);

                return (
                  <FormControlLabel
                    value={items.value}
                    name="job_assigned_notification"
                    id="job_assigned_notification"
                    color="primary"
                    onChange={(event: SyntheticEvent<Element, Event>) => {
                      handleNotificationOptionsCheck(event);
                    }}
                    checked={checked}
                    control={<Checkbox />}
                    label={items.label}
                  />
                );
              })}
            </Stack>
          </Grid>
        );
      case 'office_worker':
        return null;

      default:
        return (
          <>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel htmlFor="estimate_assigned_notification">
                <Typography className="break-words">Notify Me When I Am Assigned To An Estimate</Typography>
              </InputLabel>
              <Stack direction={'row'} spacing={2}>
                {notificationSendingChannels.map((items) => {
                  const checked: boolean =
                    Array.isArray(notificationOptionsCheck['estimate_assigned_notification']) &&
                    notificationOptionsCheck['estimate_assigned_notification'].includes(items.value);

                  return (
                    <FormControlLabel
                      value={items.value}
                      name="estimate_assigned_notification"
                      id="estimate_assigned_notification"
                      color="primary"
                      onChange={(event: SyntheticEvent<Element, Event>) => {
                        handleNotificationOptionsCheck(event);
                      }}
                      checked={checked}
                      control={<Checkbox />}
                      label={items.label}
                    />
                  );
                })}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel htmlFor="job_assigned_notification">
                <Typography className="break-words">Notify Me When I Am Assigned To A Job</Typography>
              </InputLabel>
              <Stack direction={'row'} spacing={2}>
                {notificationSendingChannels.map((items) => {
                  const checked: boolean =
                    Array.isArray(notificationOptionsCheck['job_assigned_notification']) &&
                    notificationOptionsCheck['job_assigned_notification'].includes(items.value);

                  return (
                    <FormControlLabel
                      value={items.value}
                      name="job_assigned_notification"
                      id="job_assigned_notification"
                      color="primary"
                      onChange={(event: SyntheticEvent<Element, Event>) => {
                        handleNotificationOptionsCheck(event);
                      }}
                      checked={checked}
                      control={<Checkbox />}
                      label={items.label}
                    />
                  );
                })}
              </Stack>
            </Grid>
          </>
        );
    }
  };

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit} className="md:mx-12 sm:mx-4 mx-0 my-4">
        <Grid container spacing={4}>
          {/* ----------------------------------------Profile Picture--------------------------------- */}
          <Grid item xs={12} md={12}>
            <Stack spacing={5} alignItems={'center'} className="mt-5">
              <CustomTooltip message={profilePicture ? 'Change Picture' : 'Upload Profile Picture'}>
                <IconButton disableRipple onClick={handleOpenFileUpload}>
                  <Avatar
                    sx={{ border: profilePicture && 2, width: 100, height: 100 }}
                    className="hover:border-4 border-dashed"
                    variant="circular"
                    src={profilePicture}
                  />
                </IconButton>
              </CustomTooltip>
              <Typography>Profile Picture</Typography>
            </Stack>
          </Grid>

          {/* -----------------------------------------First Name--------------------------------- */}
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="first_name">First Name*</InputLabel>
              <OutlinedInput
                required
                id="first_name"
                value={formik.values.first_name}
                name="first_name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="John"
                fullWidth
                error={Boolean(formik.touched.first_name && formik.errors.first_name)}
              />
              {formik.touched.first_name && formik.errors.first_name && (
                <FormHelperText error id="helper-text-first_name-signup">
                  {formik.errors?.first_name}
                </FormHelperText>
              )}
            </Stack>
          </Grid>
          {/* -----------------------------------------Last Name---------------------------------- */}
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="last_name">Last Name*</InputLabel>
              <OutlinedInput
                required
                id="last_name"
                type="last_name"
                value={formik.values.last_name}
                name="last_name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="Doe"
                fullWidth
                error={Boolean(formik.touched.last_name && formik.errors.last_name)}
              />
              {formik.touched.last_name && formik.errors.last_name && (
                <FormHelperText error id="helper-text-last_name-signup">
                  {formik.errors.last_name}
                </FormHelperText>
              )}
            </Stack>
          </Grid>
          {/* -----------------------------------------Email------------------------------ */}
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="email-user">
                {formik.values.role === 'company_admin' ? (
                  <div className="flex gap-1 items-center">
                    Admin Login Email *
                    <CustomTooltip message={companyEmailTooltipMessage} props={{ placement: 'top' }}>
                      <QuestionCircleFilled />
                    </CustomTooltip>
                  </div>
                ) : (
                  <>Email *</>
                )}
              </InputLabel>
              <OutlinedInput
                readOnly={isEditMode && existingData?.role === 'company_admin'}
                startAdornment={<MailOutlined />}
                required
                id="email-user"
                type="email"
                value={formik.values.email}
                name="email"
                onBlur={formik.handleBlur}
                onChange={(event) => formik.setFieldValue('email', trimString(event.target.value.toLowerCase()))}
                placeholder="john.doe@xyz.com"
                fullWidth
                error={Boolean(formik.touched.email && formik.errors.email)}
              />

              {formik.touched.email && formik.errors.email && (
                <FormHelperText error id="helper-text-email-signup">
                  {formik.errors.email}
                </FormHelperText>
              )}
            </Stack>
          </Grid>
          {/* -----------------------------------------personal Email------------------------------ */}
          {formik.values.role === 'company_admin' && (
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-email">
                  <div className="flex gap-1 items-center">
                    Personal Email
                    <CustomTooltip message={personalEmailTooltipMessage} props={{ placement: 'top' }}>
                      <QuestionCircleFilled />
                    </CustomTooltip>
                  </div>
                </InputLabel>
                <OutlinedInput
                  startAdornment={<MailOutlined />}
                  required
                  id="personal-email"
                  type="email"
                  value={formik.values.personal_email}
                  name="personal_email"
                  onBlur={formik.handleBlur}
                  onChange={(event) => {
                    formik.setFieldValue('personal_email', trimString(event.target.value.toLowerCase()));
                  }}
                  placeholder="john.doe@xyz.com"
                  fullWidth
                  error={Boolean(formik.touched.personal_email && formik.errors.personal_email)}
                />

                {formik.touched.personal_email && formik.errors.personal_email && (
                  <FormHelperText error id="helper-text-personal_email-signup">
                    {formik.errors.personal_email}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
          )}
          {/* -----------------------------------------Password------------------------------------ */}
          {!isEditMode && (
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="password">Password*</InputLabel>
                <TextField
                  id="password"
                  name="password"
                  inputProps={{ style: { textTransform: 'none' } }}
                  type={showPassword ? 'text' : 'password'}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  error={Boolean(formik.touched.password && formik.errors.password)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" variant="filled">
                        <IconButton
                          disabled={!formik.values.password?.length}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                          color="primary"
                        >
                          {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                {Boolean(formik.touched.password && formik.errors.password) && (
                  <FormHelperText error id="helper-text-password-signup">
                    {formik.errors.password}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
          )}
          {/* -----------------------------------------Phone------------------------------------- */}
          <Grid item xs={12} md={6}>
            <Stack direction={'row'} justifyContent={'start'} spacing={3}>
              <Stack spacing={1} className="w-1/2">
                <InputLabel htmlFor="phone_type">Phone Type</InputLabel>
                <Select
                  id="phone_type"
                  sx={{ padding: 0.2 }}
                  size="medium"
                  fullWidth
                  value={formik.values.phone_type}
                  name="phone_type"
                  renderValue={(selected) => phoneTypes.find((status) => status.value === selected)?.label}
                  onChange={formik.handleChange}
                >
                  {phoneTypes.map((phoneType) => (
                    <MenuItem value={phoneType.value}>{phoneType.label}</MenuItem>
                  ))}
                </Select>
              </Stack>
              {formik.values.phone_type === 'office' && (
                <Stack spacing={1} className="w-full">
                  <InputLabel htmlFor="phone1_extension">Extension Number</InputLabel>
                  <OutlinedInput
                    type="number"
                    id="phone_extension"
                    value={formik.values.phone_extension}
                    name="phone_extension"
                    onBlur={formik.handleBlur}
                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                      if (Number(event.target.value) < 0) {
                        event.target.value = '';
                        return null;
                      }
                      formik.handleChange(event);
                    }}
                    placeholder=""
                    fullWidth
                  />
                </Stack>
              )}
              <Stack spacing={1} className="w-full">
                <InputLabel htmlFor="phone">Phone*</InputLabel>
                <PhoneNumberInput
                  id="phone"
                  value={formik.values.phone && `${formik.values.phone_country_code}${formik.values.phone}`}
                  onChange={(phone: string, country: CountryData, e: React.ChangeEvent<HTMLInputElement>) =>
                    handlePhoneNumberInputChange(phone, country, e, formik, 'phone')
                  }
                />
                {getIn(formik.touched, 'phone') && getIn(formik.errors, 'phone') && (
                  <FormHelperText error id="helper-text-first_name-signup">
                    {getIn(formik.errors, 'phone')}
                  </FormHelperText>
                )}
              </Stack>
            </Stack>
          </Grid>

          {/* </Stack> */}
          {/* -----------------------------------------Address----------------------------------------- */}
          <Grid item xs={12} gap={1} marginY={3}>
            <Typography className="mb-2" variant="h4">
              Address*
            </Typography>

            <GoogleMapAutocomplete formik={formik} />
          </Grid>

          {/* -----------------------------------------Date Hired------------------------------------------ */}
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputLabel required={formik.values.rate_info.pay_type === 'salary'} htmlFor="date_hired-user">
                Date Hired
              </InputLabel>
              <DatePicker
                disableFuture
                sx={{
                  border: () => {
                    return !!formik.errors.date_hired && formik.touched.date_hired ? '1px solid red' : 'none';
                  },
                  borderRadius: 2 // Add border radius of 2 if needed
                }}
                value={formik.values.date_hired ? dayjs(formik.values.date_hired) : null}
                onChange={(newValue: Dayjs | null) => {
                  formik.setFieldValue('date_hired', newValue !== null ? newValue.toDate() : '');
                }}
              />
              {formik.touched.date_hired && formik.errors.date_hired && (
                <FormHelperText error id="helper-text-role">
                  {formik.errors.date_hired}
                </FormHelperText>
              )}
            </Stack>
          </Grid>
          {/* -----------------------------------------Date Of Birth------------------------------------------ */}

          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="birthdate-user">Date of Birth</InputLabel>
              <DatePicker
                value={formik.values.birthdate ? dayjs(formik.values.birthdate) : null}
                maxDate={dayjs()}
                onChange={(newValue: Dayjs | null) => {
                  formik.setFieldValue('birthdate', newValue !== null ? newValue.toDate() : '');
                }}
              />
            </Stack>
          </Grid>
          {/* -----------------------------------------Role------------------------------------------ */}
          {existingData?.role !== 'company_admin' && (
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel>Role*</InputLabel>
                <Autocomplete
                  id="role"
                  value={availableRoles.find((role) => role.value === formik.values.role)}
                  disableClearable
                  getOptionLabel={(option) => option.label}
                  onChange={handleRoleChange}
                  options={availableRoles}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      placeholder="Role"
                      sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                    />
                  )}
                />
                {formik.touched.role && formik.errors.role && (
                  <FormHelperText error id="helper-text-role">
                    {formik.errors.role}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
          )}

          {/* -----------------------------------------UserInactivity------------------------------------- */}

          {['office_worker'].includes(formik.values.role as string) && (
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel>User Inactivity</InputLabel>
                <Autocomplete
                  id="inactivity"
                  value={minutesScale.find((time) => {
                    return Number(time.value as string) === Number(formik.values.inactivity);
                  })}
                  disableClearable
                  onChange={(event: SyntheticEvent<Element, Event>, newValue: { label: string; value: string }) => {
                    formik.setFieldValue('inactivity', newValue.value);
                  }}
                  getOptionLabel={(option) => option.label}
                  options={minutesScale}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="User Inactivity"
                      sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                    />
                  )}
                />
                {formik.touched.status && formik.errors.status && (
                  <FormHelperText error id="helper-text-status">
                    {formik.errors.status}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
          )}
          {/* -----------------------------------------Status------------------------------------------ */}
          <Grid item xs={12} md={4}>
            <Stack spacing={1}>
              <InputLabel>Status</InputLabel>
              <Autocomplete
                id="status"
                value={availableStatus.find((status) => status.value === formik.values.status)}
                disableClearable
                onChange={(event: SyntheticEvent<Element, Event>, newValue: { label: string; value: number }) => {
                  formik.setFieldValue('status', newValue.value);
                }}
                options={availableStatus.filter((status) => status.value !== 0)}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    placeholder="Status"
                    sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                  />
                )}
              />
              {formik.touched.status && formik.errors.status && (
                <FormHelperText error id="helper-text-status">
                  {formik.errors.status}
                </FormHelperText>
              )}
            </Stack>
          </Grid>

          {/* -----------------------------------------FW is also estimator------------------------------------------ */}
          {formik.values.role === 'field_worker' && (
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="is_fw_estimator">Is This Field Worker Also An Estimator?</InputLabel>
                <FormControl>
                  <RadioGroup
                    row
                    aria-label="is_fw_estimator"
                    value={Boolean(formik.values.is_fw_estimator)}
                    onChange={handleIsFwEstimator}
                    name="is_fw_estimator"
                    id="is_fw_estimator"
                  >
                    <FormControlLabel value={true} control={<Radio />} label={'Yes'} />
                    <FormControlLabel value={false} control={<Radio />} label={'No'} />
                  </RadioGroup>
                </FormControl>
              </Stack>
            </Grid>
          )}
          {/* ----------------------------------------Notification---------------------------------------- */}
          <Grid item alignItems={'stretch'} xs={12} md={12}>
            <Typography variant="h4" className="pb-4">
              Notification
            </Typography>
            {alertMessage && (
              <Alert severity="warning" className="my-2 xs:text-2xl">
                {alertMessage}
              </Alert>
            )}

            {/* <div className="flex flex-wrap sm:flex-row flex-col sm:space-y-0 space-y-4 sm:gap-x-4 gap-x-0  sm:justify-between mt-4 xl:w-2/3 md:w-full"> */}
            <Grid container spacing={2}>
              <Grid item xs={12} container>
                {/*---------------------Notify Me When A Proposal Is Approved--------------------------*/}
                {(!['field_worker'].includes(formik.values.role) || formik.values.is_fw_estimator) && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    bgcolor={
                      notificationOptionsCheck.proposal_approval_notification !== undefined &&
                      notificationOptionsCheck.proposal_approval_notification !== null &&
                      notificationOptionsCheck.proposal_approval_notification !== 0
                        ? themeConfig.mode === 'dark'
                          ? 'GrayText'
                          : '#f5f5f5'
                        : 'transparent'
                    }
                    className="py-2 pl-2"
                  >
                    <InputLabel htmlFor="proposal_approval_notification">
                      <Typography className="break-words">Notify Me When A Proposal Is Approved</Typography>
                    </InputLabel>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-label="proposal_approval_notification"
                        value={`${notificationOptionsCheck.proposal_approval_notification}`}
                        onChange={(event: SyntheticEvent<Element, Event>) => handleNotificationOptionsCheck(event)}
                        name="proposal_approval_notification"
                        id="proposal_approval_notification"
                      >
                        {notifyProposalApprovedItems.map((items) => (
                          <FormControlLabel value={items.value} control={<Radio />} label={items.label} />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                )}
                {/*--------------Select Notification Channels----------------------- */}

                {(!['field_worker'].includes(formik.values.role) || formik.values.is_fw_estimator) &&
                  notificationOptionsCheck.proposal_approval_notification !== undefined &&
                  notificationOptionsCheck.proposal_approval_notification !== null &&
                  notificationOptionsCheck.proposal_approval_notification !== 0 && (
                    <Grid item xs={12} sm={6} md={4} bgcolor={themeConfig.mode === 'dark' ? 'GrayText' : '#f5f5f5'} className="py-2 pl-2">
                      <InputLabel htmlFor="proposal_approval_notification_sms_email">
                        Select Notification Channels
                        {notificationOptionsCheck.proposal_approval_notification !== undefined &&
                        notificationOptionsCheck.proposal_approval_notification !== null &&
                        notificationOptionsCheck.proposal_approval_notification !== 0
                          ? '*'
                          : ''}
                        {notificationOptionsCheck.proposal_approval_notification !== undefined &&
                          notificationOptionsCheck.proposal_approval_notification !== null &&
                          notificationOptionsCheck.proposal_approval_notification !== 0 &&
                          !notificationOptionsCheck['proposal_approval_notification_sms_email'].length && (
                            <Chip color="error" label="Required" size="small" className=" text-xs ml-2 rounded-2xl" />
                          )}
                      </InputLabel>
                      <Stack direction={'row'} spacing={2}>
                        {notificationSendingChannels.map((items) => {
                          return (
                            <FormControlLabel
                              value={items.value}
                              name="proposal_approval_notification_sms_email"
                              id="proposal_approval_notification_sms_email"
                              color="primary"
                              onChange={(event: SyntheticEvent<Element, Event>) => {
                                handleNotificationOptionsCheck(event);
                              }}
                              checked={
                                Array.isArray(notificationOptionsCheck['proposal_approval_notification_sms_email']) &&
                                notificationOptionsCheck['proposal_approval_notification_sms_email'].includes(items.value)
                              }
                              control={
                                <Checkbox
                                  disabled={
                                    (formik.values.role !== 'field_worker' || formik.values.is_fw_estimator) &&
                                    notificationOptionsCheck.proposal_approval_notification === 0
                                  }
                                />
                              }
                              label={items.label}
                            />
                          );
                        })}
                      </Stack>
                    </Grid>
                  )}
              </Grid>
              <Grid item container xs={12} spacing={3}>
                {/*--------------Notify Me When I Am Assigned To An Estimate----------------------- */}
                {formik.values.is_fw_estimator && formik.values.role === 'field_worker' && (
                  <Grid item xs={12} sm={6} md={3}>
                    <InputLabel htmlFor="estimate_assigned_notification-user">
                      <Typography className="break-words">Notify Me When I Am Assigned To An Estimate</Typography>
                    </InputLabel>
                    <Stack direction={'row'} spacing={2}>
                      {notificationSendingChannels.map((items) => {
                        const checked: boolean =
                          Array.isArray(notificationOptionsCheck['estimate_assigned_notification']) &&
                          notificationOptionsCheck['estimate_assigned_notification'].includes(items.value);
                        return (
                          <FormControlLabel
                            value={items.value}
                            name="estimate_assigned_notification"
                            id="estimate_assigned_notification"
                            color="primary"
                            onChange={(event: SyntheticEvent<Element, Event>) => {
                              handleNotificationOptionsCheck(event);
                            }}
                            checked={checked}
                            control={<Checkbox />}
                            label={items.label}
                          />
                        );
                      })}
                    </Stack>
                  </Grid>
                )}
                {/*--------------renderNotificationRadios----------------------- */}

                {renderNotificationRadios(formik.values.role, formik)}

                {existingData?.role === 'company_admin' && (
                  <Grid item xs={12} sm={6} md={3}>
                    <InputLabel htmlFor="employee_punch_card_notification">
                      <Typography className="break-words">Employee Punch Card Notifications</Typography>
                    </InputLabel>
                    <Stack direction={'row'} spacing={2}>
                      {notificationSendingChannels.map((items) => {
                        return (
                          <FormControlLabel
                            value={items.value}
                            name="employee_punch_card_notification"
                            id="employee_punch_card_notification"
                            color="primary"
                            onChange={(event: SyntheticEvent<Element, Event>) => {
                              handleNotificationOptionsCheck(event);
                            }}
                            checked={
                              Array.isArray(notificationOptionsCheck['employee_punch_card_notification']) &&
                              notificationOptionsCheck['employee_punch_card_notification'].includes(items.value)
                            }
                            control={<Checkbox />}
                            label={items.label}
                          />
                        );
                      })}
                    </Stack>
                  </Grid>
                )}

                <Grid item xs={12} sm={6} md={3}>
                  <InputLabel htmlFor="auto_schedules_notification">
                    <Typography className="break-words">Notify me when a customer auto schedules</Typography>
                  </InputLabel>
                  <Stack direction={'row'} spacing={2}>
                    {notificationSendingChannels.map((items) => {
                      return (
                        <FormControlLabel
                          value={items.value}
                          name="auto_schedules_notification"
                          id="auto_schedules_notification"
                          color="primary"
                          onChange={(event: SyntheticEvent<Element, Event>) => {
                            handleNotificationOptionsCheck(event);
                          }}
                          checked={
                            Array.isArray(notificationOptionsCheck['auto_schedules_notification']) &&
                            notificationOptionsCheck['auto_schedules_notification'].includes(items.value)
                          }
                          control={<Checkbox />}
                          label={items.label}
                        />
                      );
                    })}
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CustomTooltip message={'Notify Me When An Estimate Is Submitted Through The Website'}>
                    <InputLabel htmlFor="estimate_submitted_through_website_notification">
                      <Typography className="break-words">Notify me when a customer submits a free quote</Typography>
                    </InputLabel>
                  </CustomTooltip>
                  <Stack direction={'row'} spacing={2}>
                    {notificationSendingChannels.map((items) => {
                      return (
                        <FormControlLabel
                          value={items.value}
                          name="estimate_submitted_through_website_notification"
                          id="estimate_submitted_through_website_notification"
                          color="primary"
                          onChange={(event: SyntheticEvent<Element, Event>) => {
                            handleNotificationOptionsCheck(event);
                          }}
                          checked={
                            Array.isArray(notificationOptionsCheck['estimate_submitted_through_website_notification']) &&
                            notificationOptionsCheck['estimate_submitted_through_website_notification'].includes(items.value)
                          }
                          control={<Checkbox />}
                          label={items.label}
                        />
                      );
                    })}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            {/* </div> */}
          </Grid>

          {/* ----------------------------------------Rate Info------------------------------------- */}

          <Grid item xs={12} md={12} spacing={3}>
            <Grid item xs={12} md={12} className="mb-2">
              <Typography variant="h4">Rate Info*</Typography>
            </Grid>

            <Grid container xs={12} md={12} direction="row" spacing={3} className="w-full">
              {/* ------------------------------------Pay Type----------------------------------------- */}
              <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                  <InputLabel error={Boolean(getIn(formik.touched, 'rate_info.pay_type') && getIn(formik.errors, 'rate_info.pay_type'))}>
                    Pay Type*
                  </InputLabel>
                  <Select
                    fullWidth
                    value={formik.values?.rate_info?.pay_type}
                    name="rate_info.pay_type"
                    renderValue={(selected) => payType.find((status) => status.value === selected)?.label}
                    onChange={handlePayTypeChange}
                  >
                    {payType
                      .filter((type) => {
                        if (formik.values.role !== 'office_worker') {
                          return type;
                        }
                        return type.value !== 'commission';
                      })
                      .map((payType) => (
                        <MenuItem value={payType.value}>{payType.label}</MenuItem>
                      ))}
                  </Select>
                  {Boolean(getIn(formik.touched, 'rate_info.pay_type') && getIn(formik.errors, 'rate_info.pay_type')) && (
                    <FormHelperText error id="helper-text-pay_type">
                      {getIn(formik.errors, 'rate_info.pay_type')}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              {/* ------------------------------------Houly Rate----------------------------------------- */}
              {formik.values.rate_info?.pay_type === 'hourly' && (
                <Grid item xs={12} md={4}>
                  <Stack spacing={1} className="w-full">
                    <InputLabel
                      error={Boolean(getIn(formik.touched, 'rate_info.hourly_rate') && getIn(formik.errors, 'rate_info.hourly_rate'))}
                    >
                      Hourly Rate ($)*
                    </InputLabel>
                    <TextField
                      type="number"
                      autoComplete="off"
                      required
                      id="hourly_rate"
                      value={formik.values.rate_info?.hourly_rate}
                      name="rate_info.hourly_rate"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="100.00"
                      fullWidth
                      error={Boolean(getIn(formik.touched, 'rate_info.hourly_rate') && getIn(formik.errors, 'rate_info.hourly_rate'))}
                      helperText={
                        Boolean(getIn(formik.touched, 'rate_info.hourly_rate') && getIn(formik.errors, 'rate_info.hourly_rate')) &&
                        getIn(formik.errors, 'rate_info.hourly_rate')
                      }
                    />
                  </Stack>
                </Grid>
              )}

              {/* ------------------------------------Weekly Rate----------------------------------------- */}
              {formik.values?.rate_info?.pay_type === 'salary' && (
                <Grid item xs={12} md={4}>
                  <Stack spacing={1}>
                    <InputLabel
                      error={Boolean(getIn(formik.touched, 'rate_info.weekly_salary') && getIn(formik.errors, 'rate_info.weekly_salary'))}
                    >
                      Weekly Rate ($)*
                    </InputLabel>
                    <TextField
                      type="number"
                      autoComplete="off"
                      required
                      id="weekly_salary"
                      value={formik.values?.rate_info.weekly_salary}
                      name="rate_info.weekly_salary"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="Ex: 100.00"
                      fullWidth
                      error={Boolean(getIn(formik.touched, 'rate_info.weekly_salary') && getIn(formik.errors, 'rate_info.weekly_salary'))}
                      helperText={
                        Boolean(getIn(formik.touched, 'rate_info.weekly_salary') && getIn(formik.errors, 'rate_info.weekly_salary')) &&
                        getIn(formik.errors, 'rate_info.weekly_salary')
                      }
                    />
                  </Stack>
                </Grid>
              )}

              {/* ------------------------------------Punch Type----------------------------------------- */}
              {formik.values.rate_info?.pay_type === 'hourly' && (
                <Grid item xs={12} md={4}>
                  <Stack>
                    <InputLabel htmlFor="rate_info.punch_type">Punch</InputLabel>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-label="punch_type"
                        value={formik.values.rate_info?.punch_type}
                        onChange={formik.handleChange}
                        name="rate_info.punch_type"
                        id="rate_info.punch_type"
                      >
                        {getPunchTypes(formik.values.role).map((items) => (
                          <FormControlLabel value={items.value} control={<Radio />} label={items.label} />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Stack>
                </Grid>
              )}
              {/* ------------------------------------Commission($)------------------------------------------ */}
              {formik.values.rate_info?.pay_type === 'commission' &&
                ((formik.values.is_fw_estimator && formik.values.role === 'field_worker') ||
                  formik.values.role === 'estimator' ||
                  formik.values.role === 'company_admin') && (
                  <Grid container item xs={12} md={8} spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1} className="w-full">
                        <InputLabel htmlFor="residential_commission">
                          <div className="whitespace-normal">Residential Estimate Commission ($)*</div>
                        </InputLabel>
                        <OutlinedInput
                          required
                          id="rate_info.residential_commission"
                          type="number"
                          value={formik.values.rate_info?.residential_commission}
                          name="rate_info.residential_commission"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          placeholder="Ex: 100.00"
                          fullWidth
                          error={Boolean(
                            !!getIn(formik.touched, 'rate_info.residential_commission') &&
                              !!getIn(formik.errors, 'rate_info.residential_commission')
                          )}
                        />
                        {Boolean(
                          !!getIn(formik.touched, 'rate_info.residential_commission') &&
                            !!getIn(formik.errors, 'rate_info.residential_commission')
                        ) && (
                          <FormHelperText error id="helper-text-pay_type">
                            {getIn(formik.errors, 'rate_info.residential_commission')}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1} className="w-full">
                        <InputLabel htmlFor="commercial_commission">
                          <div className="whitespace-normal">Commercial Estimate Commission ($)*</div>
                        </InputLabel>
                        <OutlinedInput
                          required
                          id="rate_info.commercial_commission"
                          type="number"
                          value={formik.values.rate_info?.commercial_commission}
                          name="rate_info.commercial_commission"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          placeholder="Ex: 100.00"
                          fullWidth
                          error={Boolean(
                            getIn(formik.touched, 'rate_info.commercial_commission') &&
                              getIn(formik.errors, 'rate_info.commercial_commission')
                          )}
                        />
                        {Boolean(
                          !!getIn(formik.touched, 'rate_info.commercial_commission') &&
                            !!getIn(formik.errors, 'rate_info.commercial_commission')
                        ) && (
                          <FormHelperText error id="helper-text-pay_type">
                            {getIn(formik.errors, 'rate_info.commercial_commission')}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                )}
              {/* </div> */}
            </Grid>
          </Grid>

          {/* ----------------------------------------Module Permissions------------------------------------- */}
          {existingData?.role !== 'company_admin' && (
            <Grid item alignItems={'stretch'} xs={12} md={12} spacing={3}>
              <Typography variant="h4" className="mb-4">
                Module Permissions*
              </Typography>
              <ModulePermissionTable
                modulePermissionsData={modulePermissionsData}
                onChange={handlePermissionChange}
                permissionData={permissions?.permissions}
              />
            </Grid>
          )}
        </Grid>

        <div className="w-full flex flex-col items-center justify-center my-3 space-y-3">
          {FormSubmissionAlertMessage(formik)}

          <Button
            type="submit"
            startIcon={formik.isSubmitting ? <LoadingOutlined /> : <SaveOutlined />}
            variant="shadow"
            size="large"
            disabled={formik.isSubmitting}
            className="py-3 px-10 text-lg"
          >
            Submit
          </Button>
        </div>
      </form>
      {fileUploadDialog && (
        <ImageCrop
          Image={profilePicture}
          open={fileUploadDialog}
          onClose={() => setFileUploadDialog(false)}
          onSubmit={handleUploadedFile}
          dialogTitle="Upload Profile Picture"
        />
      )}
    </>
  );
};

export default AddUserForm;
