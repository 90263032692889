import { PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, InputLabel, Paper, Stack, TextField, Typography } from '@mui/material';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import PaymentInput from 'components/inputs/PaymentInput';
import UniversalDialog from 'components/popups/UniversalDialog';
import CustomDataTable from 'components/tables/CustomDataTable';
import { TJobCompleteData, TJobData, TPayment } from 'components/tables/interfaces/jobTableInterface';
import useAuth from 'hooks/useAuth';
import moment from 'moment';
import { IAddFollowupPopup } from 'pages/Settings/types/types.followupPopup';
import { useEffect, useMemo, useRef, useState } from 'react';
import JobServicesInstance from 'services/services.jobs';
import { useSelector } from 'store';
import { formatCostInDecimal, formateData, snakeCaseToTitleCase } from 'utils/common';

const defaultPaymentTypes = ['cash', 'cheque'];

const JobBalance = ({
  job,
  refetchJob
}: {
  job?: TJobCompleteData;
  refetchJob: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<TJobData | undefined, Error>>;
}) => {
  const { user } = useAuth();
  const [addPaymentPopup, setAddPaymentPopup] = useState<IAddFollowupPopup>({ action: { open: false }, data: null });
  const [paymentData, setPaymentData] = useState<TPayment[]>([{}]);
  const [note, setNote] = useState('');
  const [totalAmountRemaining, setTotalAmountRemaining] = useState<number>();
  const [paymentTypes, setPaymentTypes] = useState<Array<string>>([]);
  const componentRef = useRef<HTMLDivElement | null>(null);

  const { generalSettings: settingsData } = useSelector((state) => state.settings);

  useEffect(() => {
    const selectedOption = job?.estimations?.options.find((option) => option.approved);
    const amountPaid = job?.payments?.reduce((prev, curr) => (curr.amount ? prev + curr.amount : prev), 0);
    if (!selectedOption?.grand_total || amountPaid === undefined) return;
    const amount = selectedOption?.grand_total - amountPaid;
    setPaymentData([
      {
        amount: Number(formatCostInDecimal(`${amount}`)),
        payment_type: 'cash',
        user: { id: user?._id ?? '', name: `${user?.first_name} ${user?.last_name}`, role: user?.role ?? '' }
      }
    ]);
    setTotalAmountRemaining(Number(formatCostInDecimal(`${amount}`)));
  }, [job, user]);

  useEffect(() => {
    if (!settingsData) return;

    if (
      settingsData.payment_methods?.authorize_net_details &&
      Object.keys(settingsData.payment_methods?.authorize_net_details).length > 0
    ) {
      if (defaultPaymentTypes.includes('credit_card')) return;
      defaultPaymentTypes.push('credit_card');
    }
    setPaymentTypes(defaultPaymentTypes);
  }, [settingsData]);

  const columns = useMemo<ColumnDef<TPayment>[]>(
    () => [
      {
        id: 'user',
        accessorFn: (row) => row.user?.name,
        header: () => <span>User</span>
      },
      {
        id: 'role',
        cell: ({ row }) => {
          return snakeCaseToTitleCase(row.original.user?.role as string);
        },
        header: () => <span>Role</span>
      },
      {
        id: 'payment_type',
        accessorFn: (row) => snakeCaseToTitleCase(row.payment_type as string),
        header: () => <span>Payment Type</span>
      },
      {
        id: 'cheque_image',
        header: () => <span>Check Image</span>,
        cell: ({ row }) => {
          return row.original.cheque_image ? (
            <Avatar
              sx={{ width: 70, height: 70 }}
              className={`hover:border-4 border-dashed `}
              variant="square"
              src={row.original.cheque_image}
            />
          ) : null;
        }
      },
      {
        id: 'amount_paid',
        accessorFn: (row) => formateData(Number(row.amount ?? 0), '$'),
        header: () => <span>Amount Paid</span>
      },
      {
        id: 'notes',
        accessorFn: (row) => row.note ?? ''
      },
      {
        id: 'date',
        header: () => <span>Date</span>,
        cell: ({ row }) => {
          return (
            <Stack>
              <Typography variant="body1">{moment(row.original.date).format('MM-DD-YYYY')}</Typography>
              {/* <Typography variant="caption">{moment(row.original.created_at).format('hh:mm A')}</Typography> */}
            </Stack>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isStringValid = (value: string) => value.length > 0;

  const fieldErrors = () => {
    return paymentData.some((singlePaymentData) => {
      return (
        (singlePaymentData?.payment_type === undefined ? false : !isStringValid(singlePaymentData?.payment_type)) ||
        (singlePaymentData?.amount === undefined ? false : !singlePaymentData?.amount ? true : false) ||
        (singlePaymentData?.cheque_number === undefined ? false : singlePaymentData?.cheque_number === null ? true : false) ||
        (singlePaymentData?.cheque_date === undefined ? false : singlePaymentData?.cheque_date ? false : true) ||
        (singlePaymentData?.how_did_customer_pay === undefined ? false : !isStringValid(singlePaymentData?.how_did_customer_pay)) ||
        (singlePaymentData?.card_number === undefined
          ? false
          : !singlePaymentData.card_number || singlePaymentData.card_number?.length < 13 || singlePaymentData.card_number?.length > 16
          ? true
          : false) ||
        (singlePaymentData?.expiry_month === undefined
          ? false
          : singlePaymentData.expiry_month && Number(singlePaymentData.expiry_month) <= 12 && Number(singlePaymentData.expiry_month) >= 1
          ? false
          : true) ||
        (singlePaymentData?.expiry_year === undefined
          ? false
          : !singlePaymentData.expiry_year || singlePaymentData.expiry_year?.length !== 2
          ? true
          : false) ||
        (singlePaymentData?.cvv === undefined
          ? false
          : !singlePaymentData.cvv || singlePaymentData.cvv?.length < 3 || singlePaymentData.cvv?.length > 4
          ? true
          : false) ||
        (singlePaymentData?.card_type === undefined ? false : !isStringValid(singlePaymentData?.card_type))
      );
    });
  };

  const handleAddPaymentPopup = (openState: boolean) => {
    setAddPaymentPopup({ action: { open: openState } });
  };

  // ------ Components ------
  const JobBalanceHeader = () => {
    return (
      <div className="flex items-center w-full px-4 my-6 mb-2">
        <div className="flex items-center flex-1"></div>
        <Typography className="mr-5" variant="h5">
          <strong>Job Price: &nbsp;</strong>
          {formateData(Number(job?.job_price ?? 0), '$')}
        </Typography>
        <Button onClick={(e) => handleAddPaymentPopup(true)} variant="contained" size="large" startIcon={<PlusOutlined />} color="primary">
          Add Payment
        </Button>
      </div>
    );
  };

  const handleAddPayment = async () => {
    const payload: TPayment = [
      ...paymentData.map((singlePaymentData) => {
        singlePaymentData.date = new Date().toISOString();
        return singlePaymentData;
      })
    ][0];
    delete payload.user;
    if (job?._id) {
      const response = await JobServicesInstance.addJobBalance(job._id, { ...payload, note });
      if (response) {
        refetchJob();
        handleAddPaymentPopup(false);
      }
    }
  };

  return (
    <div>
      <Paper ref={componentRef} elevation={3} className="flex flex-col items-start space-y-4">
        <JobBalanceHeader />
        <CustomDataTable data={job?.payments ?? []} columns={columns} />
      </Paper>
      {addPaymentPopup.action.open && (
        <UniversalDialog
          onClose={() => handleAddPaymentPopup(false)}
          title={'Add Payment'}
          action={{ fullWidth: true, ...addPaymentPopup.action }}
          primaryButonTitle="Add"
          disablePrimaryButton={fieldErrors()}
          onSave={() => handleAddPayment()}
        >
          <div>
            <Stack gap={1}>
              <PaymentInput
                paymentTypes={paymentTypes}
                componentRef={componentRef}
                paymentData={paymentData}
                setPaymentData={setPaymentData}
                totalAmountRemaining={totalAmountRemaining}
              />
            </Stack>
            <Stack gap={1}>
              <InputLabel>Note</InputLabel>
              <TextField
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder="Add a Note"
                multiline
                minRows={3}
                maxRows={4}
              />
            </Stack>
          </div>
        </UniversalDialog>
      )}
    </div>
  );
};

export default JobBalance;
