import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import ProductPlaceholder from 'components/cards/skeleton/ProductPlaceholder';
import MainCard from 'components/MainCard';
import { TJobItem } from 'components/tables/interfaces/jobTableInterface';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import PublicSerivcesInstance from 'services/service.noAuth';
import { categorizeEstimationOptions, checkApprovedOption } from 'utils/common';
import WidgetEstimateHeader from '../components/WidgetEstimateHeader';
import WidgetEstimateOptions from '../components/WidgetEstimateOptions';
import InvoiceSection from './Components/InvoiceSection';
import { dispatch, useSelector } from 'store';
import { setGeneralSettings } from 'store/reducers/customReducers/slice.settings';

const ViewInvoiceAndProposalPage = () => {
  //--------------constants-------------
  const location = useLocation();
  const jobId = new URLSearchParams(location.search).get('job_id');
  const componentRef = useRef<HTMLDivElement | null>(null);
  const [dataByCategory, setDataByCategory] = useState<{ [key: string]: TJobItem[] }[]>([]); //It will be an array of objects and each keys in object will represent a separate job category, a single key will contain items related to it.
  const [categoryTotal, setCategoryTotal] = useState<{ [key: string]: number }[]>([]);
  const settings = useSelector((state) => state.settings);
  //---------------------------useQuery-----------------------

  const { data: jobData, isFetched: isJobFetched } = useQuery({
    queryKey: ['single_job_data', jobId],
    queryFn: () => PublicSerivcesInstance.getJobInfo(jobId as string, 'invoice'),
    enabled: !!jobId,
    networkMode: 'always'
  });
  //----------------------useEffect----------------------------
  useEffect(() => {
    dispatch(setGeneralSettings({ ...settings, type_case: jobData?.type_case }));

    if (jobData?.job.estimations && !!jobData?.job.estimations?.options.length) {
      const approvedOptionIndex = checkApprovedOption(jobData.job.estimations.options);
      if (approvedOptionIndex !== undefined && approvedOptionIndex !== -1) {
        const formatedData = categorizeEstimationOptions([jobData.job.estimations.options[approvedOptionIndex]]);
        setCategoryTotal(formatedData.category_total);
        setDataByCategory(formatedData.data_by_category);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobData]);
  return isJobFetched && !!jobData && !!jobData?.job?.estimations ? (
    <MainCard content={false} className="uppercase" ref={componentRef}>
      <WidgetEstimateHeader jobData={jobData} componentRef={componentRef} />
      <Box padding={2} className="space-y-2">
        {checkApprovedOption(jobData.job.estimations.options) !== -1 && (
          <WidgetEstimateOptions
            dataByCategory={dataByCategory}
            categoryTotal={categoryTotal}
            approvedOptionIndex={checkApprovedOption(jobData.job.estimations.options) as number}
            jobData={jobData.job}
          />
        )}
        <InvoiceSection jobData={jobData} />
      </Box>
    </MainCard>
  ) : (
    <ProductPlaceholder />
  );
};

export default ViewInvoiceAndProposalPage;
