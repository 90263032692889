import { Card, CardContent, CardHeader, List, ListItemButton, ListItemText, ListSubheader, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { TDateFilter } from 'pages/Dashboard/types/types.dashboard';
import ReportDateFilter from 'pages/Reports/components/ReportDateFilter';
import { TServiceReport } from 'pages/Reports/types/types.report';
import { formateData } from 'utils/common';

const ServiceReportCard = ({
  selectedFilter,
  setSelectedFilter,
  reportData
}: {
  selectedFilter: TDateFilter;
  setSelectedFilter: (value: TDateFilter) => void;
  reportData: TServiceReport;
}) => {
  //-----------------------constants---------------------
  const dateFilter = [
    { label: `This Year ${new Date().getFullYear()}`, value: 'this_year' },
    { label: `Last 10 Days`, value: 'last_ten_days' },
    { label: 'Last 30 Days', value: 'last_thirty_days' },
    { label: `Last Year ${new Date().getFullYear() - 1}`, value: 'last_year' },
    { label: 'Date Range', value: 'date_range' }
  ];
  return (
    <Card className="h-fit">
      <CardHeader
        className="text-center"
        title={
          <div className="text-lg sm:text:xl lg:text-2xl">
            {selectedFilter.filter_value === 'last_year' && moment().year() - 1}
            {selectedFilter.filter_value === 'this_year' && moment().year()} Gross Income
          </div>
        }
        subheader={<div className="text-md sm:text:lg lg:text-xl">{formateData(reportData.gross_income ?? 0, '$')}</div>}
      />
      <CardContent className="space-y-4">
        <ReportDateFilter selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} dateFilters={dateFilter} />
        {reportData.statistics.map((singleJobCategory) => (
          <List
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography className="w-full p-2 mr-2 text-white" sx={{ bgcolor: singleJobCategory.color }}>
                    {singleJobCategory.job_category}
                  </Typography>
                  <Typography color={singleJobCategory.color}>{formateData(singleJobCategory.amount ?? 0, '$')}</Typography>
                </Stack>
              </ListSubheader>
            }
          >
            {singleJobCategory.services_and_products.map((singleServiceAndProduct) => (
              <ListItemButton>
                <ListItemText
                  primary={
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <span>{singleServiceAndProduct.name}</span>
                      <Typography>{formateData(singleServiceAndProduct.amount ?? 0, '$')}</Typography>
                    </Stack>
                  }
                />
              </ListItemButton>
            ))}
          </List>
        ))}
      </CardContent>
    </Card>
  );
};

export default ServiceReportCard;
