import { AxiosRequestConfig } from 'axios';
import {
  TAddServicesAndProduct,
  TGetMaterials,
  TJobCategoryData,
  TResponseCreateAndEditJobCategory,
  TResponseCreateAndEditServiceAndProduct,
  TResponseDeleteServiceAndProduct,
  TResponseGetJobCategories,
  TResponseGetServiceAndProduct,
  TResponseGetServiceAndProductByJobCategory,
  TResponseGetSingleServiceAndProduct
} from 'pages/Settings/types/types.categoriesProductAndServicesTab';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import axiosServices from 'utils/axios';
import IndexedDBServices from './services.localDb';
import { TIndexedDBResponse } from './types/types.services';
import { indexedDbStoreNames } from 'utils/constants';

class CategoriesProductAndServices {
  //------------------------------------ServiceAndProduct--------------------------------------------
  getAllServiceAndProduct = async (searchData: any) => {
    try {
      const response: TResponseGetServiceAndProduct = await axiosServices.get('api/service-product', {
        locationNeeded: false,
        params: {
          ...(searchData && { filter: JSON.stringify(searchData) })
        }
      } as AxiosRequestConfig);
      if (response) {
        return response.data.data;
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: `Unable to Fetch ServiceAndProduct.`,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
    }
  };
  getSingleServiceAndProduct = async (serviceAndProductId?: string) => {
    if (serviceAndProductId)
      try {
        const response: TResponseGetSingleServiceAndProduct = await axiosServices.get(`api/service-product/${serviceAndProductId}`);
        if (response && response.data.success) {
          return response.data.data;
        }
      } catch (error: unknown) {
        const knownError = error as { message: string };
        dispatch(
          openSnackbar({
            open: true,
            message: knownError.message,
            variant: 'alert',
            alert: {
              color: 'error'
            },
            severity: 'error',
            close: true
          })
        );
      }
  };
  createServiceAndProduct = async (values: TAddServicesAndProduct) => {
    try {
      const response: TResponseCreateAndEditServiceAndProduct = await axiosServices.post('api/service-product', values);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Service/Product created successFully`,
            variant: 'success',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: `Unable to Create New Service/Product.`,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
    }
  };

  editServiceAndProduct = async (ServiceAndProductId: string, serviceAndProductData: TAddServicesAndProduct) => {
    try {
      const response: TResponseCreateAndEditServiceAndProduct = await axiosServices.put(
        `api/service-product/${ServiceAndProductId}`,
        serviceAndProductData,
        {
          locationNeeded: false
        } as AxiosRequestConfig
      );

      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Service/Product was Updated Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: `Unable to Edit Service/Product.`,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return false;
    }
  };

  deleteServiceAndProduct = async (serviceAndProductId: string, text?: string) => {
    try {
      const response: TResponseDeleteServiceAndProduct = await axiosServices.delete(`api/service-product/${serviceAndProductId}`, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Service/Product was successfully deleted`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: `Service/Product was not deleted`,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true
        })
      );
    }
  };

  getServiceAndProductByJobCategory = async (categoryId: string) => {
    try {
      if (!navigator.onLine) {
        const indexedDBInstance = new IndexedDBServices(indexedDbStoreNames.singleJobCategores);
        const data = (await indexedDBInstance.getAllLocalData()) as TIndexedDBResponse;
        const jobCategories = data.data as { id: string; data: TAddServicesAndProduct[] }[];
        const jobCategory = jobCategories.find((cat) => cat.id === categoryId);
        return jobCategory?.data;
      }

      const response: TResponseGetServiceAndProductByJobCategory = await axiosServices.get(
        `api/service-product/services-and-products-by-job-category/${categoryId}`,
        {
          locationNeeded: false
        } as AxiosRequestConfig
      );
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: `Service/Product was not found`,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true
        })
      );
    }
  };

  //----------------------------------Job Categories-------------------------------------
  getJobCategories = async () => {
    try {
      if (!navigator.onLine) {
        const indexedDBInstance = new IndexedDBServices(indexedDbStoreNames.jobCategories);
        const data = (await indexedDBInstance.getAllLocalData()) as TIndexedDBResponse;
        return data.data as Array<TJobCategoryData>;
      }

      const response: TResponseGetJobCategories = await axiosServices.get('api/job-category');
      if (response) {
        return response.data.data;
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: `Unable to Fetch Job Categories.`,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
    }
  };

  deleteJobCategory = async (jobCategoryId: string, jobCategory: string) => {
    try {
      const response: { data: { success: boolean } } = await axiosServices.delete(`api/job-category/${jobCategoryId}`, {
        locationNeeded: false
      } as AxiosRequestConfig);

      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Job Category was successfully deleted`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: `Job Category was not deleted.`,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
    }
  };

  createJobCategory = async (values: { categories: { category_name: string; image: string }[] }) => {
    try {
      const response: TResponseCreateAndEditJobCategory = await axiosServices.post('api/job-category', values);

      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Job Categories created successfully`,
            variant: 'success',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: any) {
      const knownError = error as { message: string };
      console.log('Error:', error);

      dispatch(
        openSnackbar({
          open: true,
          message: `Error while creating job category: ${knownError.message}`,
          variant: 'alert',

          alert: {
            color: 'error'
          },
          close: true
        })
      );
    }
  };

  editJobCategory = async (jobCategoryId: string | undefined, jobCategoryData: TJobCategoryData) => {
    try {
      const response: TResponseCreateAndEditJobCategory = await axiosServices.put(`api/job-category/${jobCategoryId}`, jobCategoryData, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `${jobCategoryData.category_name} was Updated Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: `Job Category was not Updated.`,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return false;
    }
  };

  //------------------------------Material Data------------------------------
  getMaterialData = async () => {
    try {
      const response: TGetMaterials = await axiosServices.get('api/material');

      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
}

const CategoriesProductAndServicesInstance = new CategoriesProductAndServices();
export default CategoriesProductAndServicesInstance;
