import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';
import { TMeasuringTypes } from 'types/types.global';
import { TItemsMeasureUnitFieldsProps, TMeasurementUnits, TItemsMeasurementFieldsOnChangeArg } from './types.jobItems';
import NumberInput from 'components/inputs/NumberInput';

const ItemsMeasureUnitFields = (props: TItemsMeasureUnitFieldsProps) => {
  const [errorMessage, setErrorMessage] = useState('');

  const { measuringType, itemPrice, isMultipleItemsAllowed } = props;

  const [measurements, setMeasurements] = useState<TMeasurementUnits[]>(isMultipleItemsAllowed ? [] : props?.measurementData ?? []);

  useEffect(() => {
    if (isMultipleItemsAllowed) {
      handleAddMeasurement(measuringType.split('_')[0] as TMeasuringTypes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measuringType]);

  // ----------------Handlers------------------\
  const handleAddMeasurement = (receivedMeasuringType: TMeasuringTypes) => {
    const lastMeasurement = measurements[measurements.length - 1];
    if (lastMeasurement) {
      const isComplete = lastMeasurement.length !== undefined;
      if (!isComplete) {
        setErrorMessage(`This Field is required `);
        return;
      }
    }
    const newMeasurements = [...measurements];
    switch (receivedMeasuringType) {
      case 'square':
        newMeasurements.push({ length: null as unknown as number, width: null as unknown as number });
        break;
      case 'cubic':
        newMeasurements.push({ length: null as unknown as number, width: null as unknown as number, height: null as unknown as number });
        break;
      case 'linear':
      case 'units':
      default:
        newMeasurements.push({ length: null as unknown as number });
        break;
    }

    setMeasurements(newMeasurements);
  };

  const handleRemoveMeasurement = (measurementIndex: number) => {
    const newMeasurements = [...measurements];
    newMeasurements.splice(measurementIndex, 1);
    setMeasurements(newMeasurements);
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, measurementIndex: number) => {
    if (Number(event.target.value) < 0) {
      event.target.value = '';
      return null;
    }
    const newMeasurements = [...measurements];
    const value = Number(event.target.value) === 0 || isNaN(Number(event.target.value)) ? null : Number(event.target.value);

    newMeasurements[measurementIndex] = { ...newMeasurements[measurementIndex], [event.target.name]: value };
    setErrorMessage('');
    setMeasurements(newMeasurements);
  };

  const calculatePrice = () => {
    const totalCost = measurements.reduce((total, currentMeasurement) => {
      return (
        total +
        Object.values(currentMeasurement).reduce((multiplication, current) => {
          return multiplication * (current ?? 1);
        })
      );
    }, 0);
    return totalCost * itemPrice;
  };

  useEffect(() => {
    const finalJson: TItemsMeasurementFieldsOnChangeArg = {
      price: calculatePrice(),
      units: measurements.length,
      measurement_data: measurements
    };

    props?.onChange(finalJson);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurements, props.isItemChanged]);

  const measuringTypeInitial = measuringType.split('_')[0];
  switch (measuringTypeInitial) {
    case 'square':
      return (
        <div className="space-y-2">
          {measurements.map((eachMeasurement, eachMeasurementIndex, measurementsArr) => (
            <Grid container direction={'row'} columnSpacing={1}>
              <Grid item xs={5}>
                <NumberInput
                  InputProps={{
                    startAdornment: <InputAdornment position="start">L</InputAdornment>
                  }}
                  autoFocus={true}
                  args={[eachMeasurementIndex]}
                  name="length"
                  value={eachMeasurement.length}
                  onChangeHandler={handleFieldChange}
                />
              </Grid>
              <Grid item xs={5}>
                <NumberInput
                  InputProps={{
                    startAdornment: <InputAdornment position="start">W</InputAdornment>
                  }}
                  args={[eachMeasurementIndex]}
                  name="width"
                  value={eachMeasurement.width}
                  onChangeHandler={handleFieldChange}
                />
              </Grid>

              {isMultipleItemsAllowed && (
                <Grid item xs={2}>
                  {eachMeasurementIndex > 0 && (
                    <IconButton size="medium" color="error" onClick={() => handleRemoveMeasurement(eachMeasurementIndex)}>
                      <MinusOutlined />
                    </IconButton>
                  )}

                  {measurementsArr.length - 1 === eachMeasurementIndex && (
                    <IconButton size="medium" color="primary" onClick={() => handleAddMeasurement(measuringTypeInitial)}>
                      <PlusOutlined />
                    </IconButton>
                  )}
                </Grid>
              )}
            </Grid>
          ))}
          {errorMessage && <small className="text-red-600">{errorMessage}</small>}
        </div>
      );
    case 'cubic':
      return (
        <div className="space-y-2">
          {measurements.map((eachMeasurement, eachMeasurementIndex, measurementsArr) => (
            <Grid container direction={'row'} columnSpacing={1}>
              <Grid item xs={3}>
                <NumberInput
                  InputProps={{
                    startAdornment: <InputAdornment position="start">L</InputAdornment>
                  }}
                  autoFocus={true}
                  name="length"
                  value={eachMeasurement.length}
                  args={[eachMeasurementIndex]}
                  onChangeHandler={handleFieldChange}
                />
              </Grid>
              <Grid item xs={3}>
                <NumberInput
                  InputProps={{
                    startAdornment: <InputAdornment position="start">W</InputAdornment>
                  }}
                  name="width"
                  value={eachMeasurement.width}
                  args={[eachMeasurementIndex]}
                  onChangeHandler={handleFieldChange}
                />
              </Grid>
              <Grid item xs={3}>
                <NumberInput
                  InputProps={{
                    startAdornment: <InputAdornment position="start">H</InputAdornment>
                  }}
                  name="height"
                  value={eachMeasurement.height}
                  args={[eachMeasurementIndex]}
                  onChangeHandler={handleFieldChange}
                />
              </Grid>
              {isMultipleItemsAllowed && (
                <Grid item xs={3}>
                  {eachMeasurementIndex > 0 && (
                    <IconButton size="medium" color="error" onClick={() => handleRemoveMeasurement(eachMeasurementIndex)}>
                      <MinusOutlined />
                    </IconButton>
                  )}
                  {measurementsArr.length - 1 === eachMeasurementIndex && (
                    <IconButton size="medium" color="primary" onClick={() => handleAddMeasurement(measuringTypeInitial)}>
                      <PlusOutlined />
                    </IconButton>
                  )}
                </Grid>
              )}
            </Grid>
          ))}
          {errorMessage && <small className="text-red-600">{errorMessage}</small>}
        </div>
      );
    case 'linear':
      return (
        <NumberInput
          autoFocus={true}
          className="md:w-1/2 w-full"
          name="length"
          value={measurements[0]?.length}
          args={[0]}
          onChangeHandler={handleFieldChange}
        />
      );
    case 'units':
    default: {
      return (
        <NumberInput
          autoFocus={true}
          name="length"
          value={measurements[0]?.length}
          args={[0]}
          onChangeHandler={handleFieldChange}
          InputProps={{
            startAdornment: <InputAdornment position="start">Units</InputAdornment>
          }}
        />
      );
    }
  }
};

export default ItemsMeasureUnitFields;
