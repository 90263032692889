import { CloseCircleFilled, UserAddOutlined } from '@ant-design/icons';
import { AppBar, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material';
import AddExpenseForm from 'components/forms/AddExpenseForm';
import { IAddExpensePopupWActions } from 'pages/expenses/types/types.expense';

const AddExpensePopup = (props: IAddExpensePopupWActions) => {
  const handleSuccess = () => {
    props?.onClose?.();
    props?.onSave?.();
  };
  return (
    <Dialog fullWidth open={props.action?.open}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <UserAddOutlined />
          <Typography sx={{ ml: 2, flex: 1 }} variant="h4" component="div">
            {'Add Expense'}
          </Typography>
          <IconButton edge="start" color="inherit" onClick={props?.onClose}>
            <CloseCircleFilled />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <AddExpenseForm
          onSuccess={handleSuccess}
          isGlobalExpense={props.data.isGlobalExpense}
          jobData={{ id: props?.data?.id ?? '', name: props?.data?.name ?? '' }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddExpensePopup;
