import { TUniversalDialogPropsWActions } from 'types/globalTypes/type.universalDialog';
import UniversalDialog from './UniversalDialog';
import { FormHelperText, InputLabel, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import PunchingServicesInstance from 'services/services.punching';
import { useSelector } from 'store';

type TAddNotePopupProps = TUniversalDialogPropsWActions;

const AddNotePopup = (props: TAddNotePopupProps & { existingNote?: string; selectedJob: { id: string; name: string } }) => {
  const punching = useSelector((state) => state.punching);

  const [noteText, setNoteText] = useState<string>('');
  const [error, setError] = useState(false);
  useEffect(() => {
    if (props.existingNote) {
      setNoteText(props.existingNote);
    }
  }, [props.existingNote]);

  const handleAddNote = async () => {
    await PunchingServicesInstance.addPunchNote(punching.punchingDetails?.todaysPunch?._id as string, noteText);
    props.onClose();
  };

  return (
    <UniversalDialog {...props} primaryButonTitle="Add" onSave={handleAddNote}>
      <Stack spacing={1}>
        <InputLabel>Note *</InputLabel>
        <TextField
          autoFocus
          multiline
          minRows={3}
          maxRows={4}
          value={noteText}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setNoteText(event?.target.value);
            setError(!event?.target.value?.length);
          }}
          error={error}
        />
        {error && <FormHelperText error>This field is required</FormHelperText>}
      </Stack>
    </UniversalDialog>
  );
};

export default AddNotePopup;
