import { useEffect, useMemo } from 'react';

// material-ui
import {
  Alert,
  Autocomplete,
  Button,
  ButtonGroup,
  Grid,
  GridProps,
  MenuItem,
  Select,
  Stack,
  TextField,
  useMediaQuery
} from '@mui/material';
import { Theme } from '@mui/material/styles';

// third-party

// project import
import IconButton from 'components/@extended/IconButton';

// assets
import { AppstoreOutlined, CheckCircleOutlined, LeftOutlined, OrderedListOutlined, RightOutlined } from '@ant-design/icons';
import { SelectChangeEvent } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import CustomTooltip from 'components/CustomTooltip';
import dayjs from 'dayjs';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router';
import AutoAppointmentServiceInstance from 'services/services.autoAppointment';
import { dispatch, useSelector } from 'store';
import { setAreaName, setDate } from 'store/reducers/customReducers/slice.calendar';
import { months } from 'utils/constants';

// constant
const viewOptions = [
  {
    label: 'Month',
    value: 'dayGridMonth',
    icon: AppstoreOutlined
  },
  {
    label: 'Agenda',
    value: 'listWeek',
    icon: OrderedListOutlined
  }
];

// ==============================|| CALENDAR - TOOLBAR ||============================== //

export interface ToolbarProps {
  view: string;
  handleSaveBlackoutDay: () => void;
  onClickNext: () => void;
  onClickPrev: () => void;
  onClickToday: () => void;
  onChangeView: (s: string) => void;
  sx?: GridProps['sx'];
}

const Toolbar = ({ view, onClickNext, onClickPrev, onClickToday, onChangeView, handleSaveBlackoutDay, sx, ...others }: ToolbarProps) => {
  //------------------constants----------------
  const { date, areaName } = useSelector((state) => state.calendarOption);
  const { calendar_tab: selectedTab } = useParams();
  const matchDownSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  // const [viewFilter, setViewFilter] = useState(viewOptions);
  const navigate = useNavigate();
  //-------------------useQuery-----------------
  const { data: allAreaData, isFetching: isAllAreaDataFetching } = useQuery({
    queryKey: ['all_area_data_in_calendar'],
    queryFn: () => {
      return AutoAppointmentServiceInstance.getAllArea();
    },
    enabled: !!selectedTab && selectedTab === 'area_availabilities'
  });
  //-----------handlers-----
  const handleMonthChange = (event: SelectChangeEvent) => {
    dispatch(setDate(moment(date).set('month', Number(event.target.value)).toDate()));
  };

  const handleYearChange = (event: SelectChangeEvent) => {
    dispatch(setDate(moment(date).set('year', Number(event.target.value)).toDate()));
  };

  useEffect(() => {
    if (!isAllAreaDataFetching && !!allAreaData && !areaName?.length) dispatch(setAreaName(allAreaData[0]?.area_name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllAreaDataFetching]);

  const years = useMemo(() => {
    if (selectedTab === 'archive_calendar') {
      return Array.from({ length: moment().year() - 2010 + 1 }, (_, i) => 2010 + i);
    }
    return Array.from({ length: moment().year() + 50 - 2010 + 1 }, (_, i) => 2010 + i);
  }, [selectedTab]);

  const availableMonths = useMemo(() => {
    if (selectedTab === 'archive_calendar' && dayjs(date).year() === dayjs().year()) {
      return months.filter((month) => month.value <= dayjs().month());
    }

    return months;
  }, [selectedTab, date]);

  return (
    <Grid container justifyContent="space-between" spacing={matchDownSM ? 1 : 3} {...others} className="flex items-center py-2">
      <Grid item>
        <Button variant="outlined" onClick={onClickToday} size={matchDownSM ? 'small' : 'medium'}>
          Today
        </Button>
      </Grid>
      <Grid item>
        <Stack direction="row" alignItems="center" spacing={matchDownSM ? 1 : 3}>
          <IconButton onClick={onClickPrev} size={matchDownSM ? 'small' : 'large'}>
            <LeftOutlined />
          </IconButton>

          <Select
            size="small"
            value={months.find((month) => month.value === moment(date).month())?.value.toString() || '0'}
            className="w-fit"
            onChange={handleMonthChange}
            variant="outlined"
          >
            {availableMonths.map((month) => (
              <MenuItem value={month.value}>{month.label}</MenuItem>
            ))}
          </Select>
          <Select value={moment(date).year().toString()} className="w-fit" onChange={handleYearChange} variant="outlined" size="small">
            {years.map((year) => (
              <MenuItem value={year}>{year}</MenuItem>
            ))}
          </Select>
          {selectedTab === 'area_availabilities' &&
            !isAllAreaDataFetching &&
            (!!allAreaData ? (
              <Autocomplete
                value={areaName}
                onChange={(event, newValue: string) => dispatch(setAreaName(newValue))}
                disableClearable
                options={allAreaData?.map((eachArea) => eachArea.area_name) ?? []}
                renderInput={(params) => <TextField placeholder="Area Name" {...params} />}
                size="small"
                sx={{ width: '100px' }}
              />
            ) : (
              <Alert
                severity="info"
                action={<Button onClick={() => navigate('/settings/auto-appointment')}>Add Area</Button>}
                className="w-full"
              >
                No area added.
              </Alert>
            ))}
          <IconButton onClick={onClickNext} size={matchDownSM ? 'small' : 'large'}>
            <RightOutlined />
          </IconButton>
        </Stack>
      </Grid>
      <Grid item className="space-x-2">
        {selectedTab === 'blackout_days' && (
          <Button startIcon={<CheckCircleOutlined />} onClick={handleSaveBlackoutDay} variant="contained">
            Save Blackout Days
          </Button>
        )}
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          {viewOptions.map((viewOption) => {
            const Icon = viewOption.icon;
            return (
              <CustomTooltip message={viewOption.label} key={viewOption.value}>
                <Button
                  size={matchDownSM ? 'small' : 'medium'}
                  disableElevation
                  variant={viewOption.value === view ? 'contained' : 'outlined'}
                  onClick={() => onChangeView(viewOption.value)}
                >
                  <Icon style={{ fontSize: '1.3rem' }} />
                </Button>
              </CustomTooltip>
            );
          })}
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

export default Toolbar;
