import { MenuOutlined } from '@ant-design/icons';
import { Grid, Hidden, IconButton, Theme, Typography, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import { CSSProperties, ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { dispatch, useSelector } from 'store';
import { setMobileSettingsSideBarVisible } from 'store/reducers/customReducers/slice.settings';
import { NavItemType } from 'types/menu';
import { OverrideIcon } from 'types/root';
import { defaultReport, defaultSettingTab } from 'utils/constants';

// ==============================|| BREADCRUMBS ||============================== //

export interface BreadCrumbSxProps extends CSSProperties {
  mb?: string;
  bgcolor?: string;
}

interface Props {
  card?: boolean;
  divider?: boolean;
  icon?: boolean;
  icons?: boolean;
  maxItems?: number;
  navigation?: { items: NavItemType[] };
  rightAlign?: boolean;
  separator?: OverrideIcon;
  title?: boolean;
  titleBottom?: boolean;
  sx?: BreadCrumbSxProps;
}

const Breadcrumbs = ({
  card,
  divider = true,
  icon,
  icons,
  maxItems,
  navigation,
  rightAlign,
  separator,
  title,
  titleBottom,
  sx,
  ...others
}: Props) => {
  // const theme = useTheme();
  const location = useLocation();
  const [main, setMain] = useState<NavItemType | undefined>();
  const [item, setItem] = useState<NavItemType>();

  // const iconSX = {
  //   marginRight: theme.spacing(0.75),
  //   marginTop: `-${theme.spacing(0.25)}`,
  //   width: '1rem',
  //   height: '1rem',
  //   color: theme.palette.secondary.main
  // };

  useEffect(() => {
    navigation?.items?.map((menu: NavItemType, index: number) => {
      if (menu.type && menu.type === 'group') {
        getCollapse(menu as { children: NavItemType[]; type?: string });
      }
      return false;
    });
  });

  let customLocation = location.pathname;

  // only used for component demo breadcrumbs

  if (customLocation.includes('/settings')) {
    customLocation = `/settings/${defaultSettingTab}`;
  }

  if (customLocation.includes('/settings')) {
    customLocation = `/settings/${defaultSettingTab}`;
  }

  if (customLocation.includes('/reports')) {
    customLocation = `/reports/${defaultReport}`;
  }

  useEffect(() => {
    if (customLocation.includes('followup')) {
      setItem((prev) => {
        if (prev) return { ...prev, title: 'Follow Up' };

        return { title: 'Follow Up', type: 'item' };
      });
    }
  }, [customLocation]);

  useEffect(() => {
    if (customLocation.includes('/apps/profiles/user/payment')) {
      setItem(undefined);
    }
  }, [item, customLocation]);

  // set active item state
  const getCollapse = (menu: NavItemType) => {
    if (menu.children) {
      menu.children.filter((collapse: NavItemType) => {
        if (collapse.type && collapse.type === 'collapse') {
          getCollapse(collapse as { children: NavItemType[]; type?: string });
          if (collapse.url === customLocation) {
            setMain(collapse);
            setItem(collapse);
          }
        } else if (collapse.type && collapse.type === 'item') {
          if (collapse.url && customLocation.includes(collapse.url)) {
            setMain(menu);
            setItem(collapse);
          }
        }
        return false;
      });
    }
  };

  const state = useSelector((state) => state);

  const handleOpenSubMenu = (activePage: string) => {
    switch (activePage) {
      case 'settings':
        dispatch(setMobileSettingsSideBarVisible(!state.settings.mobileSideBarVisible));
        break;

      default:
        break;
    }
  };

  const mobileMenuSupportedRoutes = ['settings'];

  const renderMobileMenu = () => {
    const activePage = customLocation.split('/')[1];
    if (mobileMenuSupportedRoutes.includes(activePage))
      return (
        <IconButton color="primary" onClick={() => handleOpenSubMenu(activePage)}>
          <MenuOutlined />
        </IconButton>
      );
  };

  let breadcrumbContent: ReactElement = <Typography />;
  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  if (main && main.type === 'collapse' && main.breadcrumbs === true) {
    breadcrumbContent = (
      <>
        {title && titleBottom && (
          <Grid item>
            <Typography variant="h2">{main.title}</Typography>
          </Grid>
        )}
      </>
    );
  }

  if (item && item.type === 'item') {
    breadcrumbContent = (
      <motion.div className="flex items-center" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <Hidden mdUp>
          <div>{renderMobileMenu()}</div>
        </Hidden>
        {title && titleBottom && (
          <motion.div
            key={item.id}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 0 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            className="flex flex-col items-start space-y-4 w-full"
          >
            <Typography variant={isSmDown ? 'h4' : 'h2'} className="my-6" sx={{ paddingX: 0 }}>
              {item.title}
            </Typography>
          </motion.div>
        )}
      </motion.div>
    );
  }

  return breadcrumbContent;
};

export default Breadcrumbs;
