import { Card, CardContent, CardHeader, List, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import { TDateFilter } from 'pages/Dashboard/types/types.dashboard';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { randomHexColorCode, snakeCaseToTitleCase } from 'utils/common';
import ReportDateFilter from '../components/ReportDateFilter';

const LeadSourcesReport = ({
  selectedFilter,
  setSelectedFilter,
  reportData
}: {
  selectedFilter: TDateFilter;
  setSelectedFilter: (value: TDateFilter) => void;
  reportData: { [key: string]: number };
}) => {
  //--------------------------constants--------------------
  const dateFilter = [
    { label: 'All', value: 'all' },
    { label: `This Year ${new Date().getFullYear()}`, value: 'this_year' },
    { label: `Last Year ${new Date().getFullYear() - 1}`, value: 'last_year' },
    { label: 'Date Range', value: 'date_range' }
  ];
  const [leadSourceData, setLeadSourceData] = useState<{ label: string; value: number; color: string }[]>([]);

  useEffect(() => {
    const dummyData = Object.keys(reportData).map((singleItem) => {
      return { label: snakeCaseToTitleCase(singleItem), value: reportData[singleItem], color: randomHexColorCode() };
    });
    setLeadSourceData(dummyData);
  }, [reportData]);

  return (
    <div className="grid sm:grid-cols-1 lg:grid-cols-3 gap-2">
      <Card>
        <CardContent>
          <ReportDateFilter dateFilters={dateFilter} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />

          <CardHeader
            title={
              <div className="flex flex-row justify-between font-semibold">
                <Typography className="text-lg font-bold">Name</Typography>
                <Typography className="text-lg font-bold">Lead Count</Typography>
              </div>
            }
          />
          <List>
            {leadSourceData.map((singleItem) => (
              <ListItemButton>
                <ListItemText
                  primary={
                    <Stack
                      sx={{ paddingLeft: '0.5rem', borderLeft: `5px solid ${singleItem.color}` }}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <span>{singleItem.label}</span>
                      <Typography>{singleItem.value}</Typography>
                    </Stack>
                  }
                />
              </ListItemButton>
            ))}
          </List>
        </CardContent>
      </Card>
      <Card className="lg:col-span-2 hidden sm:block">
        <ReactApexChart
          type="bar"
          options={{
            chart: { stacked: true, toolbar: { show: false } },
            dataLabels: { enabled: false },
            title: { text: 'New Customers / Lead Sources', align: 'center' }
          }}
          series={[
            {
              name: '',
              data: leadSourceData.map((singleItem) => ({
                x: singleItem.label,
                y: singleItem.value,
                fillColor: singleItem.color
              }))
            }
          ]}
        />
      </Card>
    </div>
  );
};

export default LeadSourcesReport;
