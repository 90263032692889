import LogoIcon from './LogoIcon';
import Logo from './LogoMain';

// ==============================|| MAIN LOGO ||============================== //

interface Props {
  isIcon?: boolean;
}

const LogoSection = ({ isIcon }: Props) => (isIcon ? <LogoIcon width={50} /> : <Logo />);

export default LogoSection;
