// import TCustomerData from 'components/tables/interfaces/userTableInterface';
import { AxiosRequestConfig } from 'axios';
import { ISearch } from 'components/filters/SearchFilter';
import { TCustomerData } from 'pages/customers/types/type.customersPage';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import axiosServices from 'utils/axios';
import { IResponse, IResponseWithCustomers, IResponseWithSingleCustomer } from './types/types.services';

class CustomerServices {
  getAllCustomers = async (paginationData?: any | null, searchData?: ISearch | null, followup_filter?: string) => {
    const page = paginationData?.page + 1;
    const limit = paginationData?.rowsPerPage;
    try {
      const response: IResponseWithCustomers = await axiosServices.get(`api/customer`, {
        locationNeeded: false,
        params: {
          ...(page && { page }),
          ...(limit && { limit }),
          ...(searchData && { filter: JSON.stringify({ ...searchData, sort: { updated_at: -1 } }) }),
          followup_filter: followup_filter ?? -1
        }
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  deleteCustomer = async (customerId: string, reason: string, text?: string) => {
    try {
      const response: IResponseWithCustomers = await axiosServices.delete(`api/customer/${customerId}`, {
        locationNeeded: false,
        data: { delete_reason: reason }
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `${text} was successfully deleted`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  createCustomer = async (customerData: TCustomerData) => {
    try {
      const response: IResponseWithSingleCustomer = await axiosServices.post(`api/customer/`, customerData, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Customer Created Successfully!`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  editCustomer = async (customerId: string, customerData: TCustomerData) => {
    try {
      const response: IResponseWithSingleCustomer = await axiosServices.put(`api/customer/${customerId}`, customerData, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `${customerData.first_name.length ? customerData.first_name : 'Customer'} was Updated Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response;
      }
    } catch (error) {
      console.log('error in editCustomer', error);
    }
  };

  getSingleCustomer = async (customerId: string) => {
    try {
      const customerData: IResponseWithSingleCustomer = await axiosServices.get(`api/customer/${customerId}`);

      if (customerData.data.success) {
        return customerData.data.data;
      }
    } catch (error) {
      console.log('error in getSingleCustomer', error);
    }
  };

  suspendCustomer = async (customerId: string, reason: string) => {
    try {
      const response = await axiosServices.put(`api/customer/inactive/${customerId}`, { inactive_reason: reason });
      if (response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Customer Suspended Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  unsuspendCustomer = async (customerId: string) => {
    try {
      const response = await axiosServices.put(`api/customer/active/${customerId}`);
      if (response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Customer Activated Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  emailRatingLink = async (data: { customer_id: string }) => {
    try {
      const response = await axiosServices.post(`api/customer/send-ratings-link`, data);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Rating Link Send Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  updateCustomerNote = async (customerId: string, data: { note: string; is_alert_note?: boolean }) => {
    try {
      const response: IResponse = await axiosServices.put(`api/customer/${customerId}/update-note`, data, {
        locationNeeded: true
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Note added',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response;
      }
    } catch (error) {
      console.log('error in customer note', error);
    }
  };

  getQuickCustomerData = async (customerId: string, only?: string) => {
    try {
      let url = `api/customer/${customerId}/quick-customer-data`;

      const customerData: IResponse = await axiosServices.get(url, {
        locationNeeded: false,
        params: {
          ...(only && { only })
        }
      } as AxiosRequestConfig);

      if (customerData.data.success) {
        return customerData.data.data;
      }
    } catch (error) {
      console.log('error in get quick Customer data', error);
    }
  };
}

const CustomerServicesInstance = new CustomerServices();
export default CustomerServicesInstance;
