import { CheckOutlined, ClearOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import ProductPlaceholder from 'components/cards/skeleton/ProductPlaceholder';
import CustomTooltip from 'components/CustomTooltip';
import { TJobItem } from 'components/tables/interfaces/jobTableInterface';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ReactSignatureCanvas from 'react-signature-canvas';
import PublicSerivcesInstance from 'services/service.noAuth';
import EstimateServicesInstance from 'services/services.estimates';
import JobServicesInstance from 'services/services.jobs';
import { dispatch, useSelector } from 'store';
import { setGeneralSettings } from 'store/reducers/customReducers/slice.settings';
import { categorizeEstimationOptions, checkApprovedOption } from 'utils/common';
import WidgetEstimateHeader from '../components/WidgetEstimateHeader';
import WidgetEstimateOptions from '../components/WidgetEstimateOptions';
import WidgetEstimateTermsAndConditions from '../components/WidgetEstimateTermsAndConditions';

const UpdateEstimateSignature = () => {
  //--------------constants-----------------
  const [searchParams] = useSearchParams();
  const locaiton = useLocation();
  const pathParameter = locaiton.pathname.split('/')[2];
  const settings = useSelector((state) => state.settings);
  const jobId = searchParams.get('job_id');
  const componentRef = useRef<HTMLDivElement | null>(null);
  const [dataByCategory, setDataByCategory] = useState<{ [key: string]: TJobItem[] }[]>([]); //It will be an array of objects and each keys in object will represent a separate job category, a single key will contain items related to it.
  const [categoryTotal, setCategoryTotal] = useState<{ [key: string]: number }[]>([]);
  const [signature, setSignature] = useState('');
  const signatureRef = useRef<any>();
  const navigate = useNavigate();
  const clearSignature = () => {
    signatureRef?.current?.clear();
    setSignature('');
  };
  //---------------------------useQuery-----------------------

  const { data: jobData, isFetched: isJobFetched } = useQuery({
    queryKey: ['single_job_data', jobId],
    queryFn: () => PublicSerivcesInstance.getJobInfo(jobId as string, 'signature'),
    enabled: !!jobId,
    networkMode: 'always'
  });
  //----------------------useEffect----------------------------
  useEffect(() => {
    dispatch(setGeneralSettings({ ...settings, type_case: jobData?.type_case }));
    if (!(jobData?.job.draft_estimations && !!jobData?.job.draft_estimations?.options.length)) {
      if (jobData?.job.estimations && !!jobData?.job.estimations?.options.length) {
        const approvedOptionIndex = checkApprovedOption(jobData?.job.estimations?.options);
        if (approvedOptionIndex !== undefined && approvedOptionIndex !== -1) {
          const formatedData = categorizeEstimationOptions([jobData.job.estimations.options[approvedOptionIndex]]);
          setCategoryTotal(formatedData.category_total);
          setDataByCategory(formatedData.data_by_category);
        }
      }
      return;
    }
    const approvedOptionIndex = checkApprovedOption(jobData?.job.draft_estimations?.options);
    if (approvedOptionIndex !== undefined && approvedOptionIndex !== -1) {
      const formatedData = categorizeEstimationOptions([jobData.job.draft_estimations.options[approvedOptionIndex]]);
      setCategoryTotal(formatedData.category_total);
      setDataByCategory(formatedData.data_by_category);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobData]);
  //------------------------Handlers---------------------------
  const handleAcceptOrDeclineEstimate = async (isAcceptEstimate: boolean) => {
    if (!!jobData?.company?._id && !!jobData?.job._id) {
      let response;
      switch (isAcceptEstimate) {
        case true:
          response = await EstimateServicesInstance.noAuthAcceptEstimateOption(
            jobData?.company?._id,
            jobData?.job._id,
            signatureRef?.current?.toDataURL()
          );
          if (response) navigate(`/signature/thankyou-signoff/accept/${jobData?.job._id}`);

          break;
        case false:
          response = await EstimateServicesInstance.noAuthDeclineEstimateOption(jobData?.company?._id, jobData?.job._id);
          if (response) navigate(`/signature/thankyou-signoff/decline/${jobData?.job._id}`);

          break;
        default:
          break;
      }
    }
  };
  const handleSubmitJobCompletedSignature = async () => {
    if (!!jobData?.company?._id && !!jobData?.job._id) {
      const response = await JobServicesInstance.submitJobCompleteSignature(
        jobData?.company?._id,
        jobData?.job._id,
        signatureRef?.current?.toDataURL()
      );
      if (response) navigate(`/signature/thankyou-signoff/${jobData?.job._id}`);
    }
  };
  return isJobFetched && !!jobData ? (
    <div className="mb-12">
      <WidgetEstimateHeader componentRef={componentRef} jobData={jobData} />
      <div className="px-4">
        <WidgetEstimateOptions
          dataByCategory={dataByCategory}
          categoryTotal={categoryTotal}
          approvedOptionIndex={
            !!jobData?.job?.draft_estimations
              ? (checkApprovedOption(jobData?.job?.draft_estimations?.options) as number)
              : ((!!jobData?.job?.estimations?.options && (checkApprovedOption(jobData?.job?.estimations?.options) as number)) as number)
          }
          jobData={jobData?.job}
        />
      </div>
      <WidgetEstimateTermsAndConditions
        termsAndConditions={
          !!jobData?.job?.draft_estimations
            ? jobData?.job?.draft_estimations.terms_and_conditions
            : (jobData?.job.estimations?.terms_and_conditions as string)
        }
      />
      {!!jobData?.job?.draft_estimations && (
        <div className="flex justify-center">
          <Stack className="px-4 w-full sm:w-2/3 md:1/3 lg:w-1/3.5 space-y-2">
            <div className="flex items-center justify-between">
              <Typography>Customer Sign*&nbsp;</Typography>
              <CustomTooltip message="Clear">
                <IconButton onClick={clearSignature} color="error">
                  <ClearOutlined />
                </IconButton>
              </CustomTooltip>
            </div>
            <ReactSignatureCanvas
              penColor="black"
              canvasProps={{
                className: 'signateureCanvas',
                style: {
                  width: '100%',
                  border: '1px solid black',
                  minHeight: 200
                }
              }}
              onEnd={() => {
                setSignature(signatureRef?.current?.toDataURL());
              }}
              ref={signatureRef}
            />
            <div className="flex justify-center">
              {pathParameter === 'job-completed' && (
                <Button color="primary" variant="contained" disabled={signature.length === 0} onClick={handleSubmitJobCompletedSignature}>
                  Submit
                </Button>
              )}
              {pathParameter === 'update-estimate' && (
                <>
                  <Button
                    size="large"
                    startIcon={<CloseOutlined />}
                    variant="contained"
                    color="error"
                    className="rounded-none  w-full"
                    onClick={() => handleAcceptOrDeclineEstimate(false)}
                  >
                    Decline
                  </Button>
                  <Button
                    size="large"
                    startIcon={<CheckOutlined />}
                    variant="contained"
                    color="success"
                    disabled={signature.length === 0}
                    className="rounded-none  w-full"
                    onClick={() => handleAcceptOrDeclineEstimate(true)}
                  >
                    Accept
                  </Button>
                </>
              )}
            </div>
          </Stack>
        </div>
      )}
    </div>
  ) : (
    <ProductPlaceholder />
  );
};

export default UpdateEstimateSignature;
