import { AxiosRequestConfig } from 'axios';
import { ISearch } from 'components/filters/SearchFilter';
import { TCustomerAddSMSPopupData } from 'pages/customers/types/type.customersView';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import axiosServices from 'utils/axios';

export type TUserObject = { id: string; name: string };

export type TTextPhotosLinkPayload = {
  notify_when_customer_replies_with_photos: boolean;
  customer: { id: string; name: string };
  job?: { id: string; name: string };
  users_to_notify: TUserObject[];
};

class EmailSmsServices {
  getAllEmailsAndSms = async (
    customerId?: string,
    paginationData?: { page: number; rowsPerPage: number },
    emailsSmsSearchData?: ISearch
  ) => {
    const page = paginationData && paginationData?.page + 1;
    const limit = paginationData && paginationData?.rowsPerPage;
    try {
      const response: any = await axiosServices.get(`api/job/job-email-sms/${customerId}`, {
        params: {
          ...(page && { page }),
          ...(limit && { limit }),
          ...(emailsSmsSearchData && { filter: JSON.stringify(emailsSmsSearchData) })
        },
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  sendEmail = async (emailData: TCustomerAddSMSPopupData) => {
    try {
      const response: { data: { success: boolean } } = await axiosServices.post('api/job/send-job-email', emailData);
      if (response) {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Email sent',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
      }
      return true;
    } catch (err: any) {
      dispatch(
        openSnackbar({
          open: true,
          message: err.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return false;
    }
  };
  sendSms = async (smsData: TCustomerAddSMSPopupData) => {
    try {
      const response: { data: { success: boolean } } = await axiosServices.post('api/job/schedule-or-send-job-sms', smsData);
      if (response) {
        dispatch(
          openSnackbar({
            open: true,
            message: smsData.schedule_sms ? 'SMS Scheduled' : 'SMS sent',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
      }
      return true;
    } catch (err: any) {
      dispatch(
        openSnackbar({
          open: true,
          message: err.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return false;
    }
  };

  textPhotosLink = async (data: TTextPhotosLinkPayload) => {
    try {
      const response: { data: { success: boolean } } = await axiosServices.post('api/job/send-photos-link', data);
      if (response) {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Photos link sent',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
      }
      return true;
    } catch (err: any) {
      dispatch(
        openSnackbar({
          open: true,
          message: err.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return false;
    }
  };
}
const EmailSmsServicesInstance = new EmailSmsServices();
export default EmailSmsServicesInstance;
