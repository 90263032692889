import { DialogProps, Grid, InputLabel, Stack, TextField } from '@mui/material';
import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjsConfig';
import { useEffect, useState } from 'react';
import PunchingServicesInstance from 'services/services.punching';
import { dispatch, useSelector } from 'store';
import { getPunchDetails } from 'store/reducers/punching';
import { TUniversalDialogPropsWActions } from 'types/globalTypes/type.universalDialog';
import { getAddressFromCoordinates, getCurrentLocation } from 'utils/common';
import UniversalDialog from './UniversalDialog';

type TBreakInfo = { start_time: Dayjs | null; end_time: Dayjs | null; location?: string | null; note: string };
type TPopupActionProps = { open: boolean; fullWidth: boolean; maxWidth: DialogProps['maxWidth'] };

const AddBreakPopup = (
  props: TUniversalDialogPropsWActions & {
    isClockedIn: boolean;
    addBreakPopup: TPopupActionProps;
  }
) => {
  const punching = useSelector((state) => state.punching);
  const [loading, setLoading] = useState(false);

  const [breakTime, setBreakTime] = useState<TBreakInfo>({
    start_time: dayjs(),
    end_time: null,
    note: ''
  });

  const [isBreakStarted, setIsBreakStarted] = useState(false);

  // -------------handlers--------------

  const handleAddBreak = async () => {
    const breakInfo = localStorage.getItem('breakInfo');

    if (breakInfo) {
      setLoading(true);
      const { lat, long } = await getCurrentLocation();
      const address = await getAddressFromCoordinates(Number(lat), Number(long));
      const payload = {
        address,
        ...breakTime,
        end_time: dayjs(breakTime.end_time).toISOString(),
        start_time: dayjs(breakTime.start_time).toISOString(),
        location: { lat, long }
      };
      await PunchingServicesInstance.addBreakTime(punching.punchingDetails?.todaysPunch?._id, { breaks: [payload] });
      localStorage.removeItem('breakInfo');
      setIsBreakStarted(true);
      dispatch(getPunchDetails());
      setLoading(false);
    } else {
      const date = dayjs(punching.punchingDetails?.todaysPunch?.date);
      const start_time = dayjs(breakTime.start_time).set('year', date.year()).set('month', date.month()).set('date', date.date());
      localStorage.setItem('breakInfo', JSON.stringify({ ...breakTime, start_time }));
    }
    props.onClose();
  };

  useEffect(() => {
    const breakInfo = localStorage.getItem('breakInfo');
    if (breakInfo) {
      const parsedBreakInfo: TBreakInfo = JSON.parse(breakInfo);
      const startTime = dayjs(parsedBreakInfo.start_time);
      setBreakTime({
        ...parsedBreakInfo,
        end_time: dayjs().set('year', startTime.year()).set('month', startTime.month()).set('date', startTime.date())
      });
      setIsBreakStarted(!!parsedBreakInfo.start_time && !parsedBreakInfo.end_time);
    } else {
      setIsBreakStarted(false);
    }
  }, [props.addBreakPopup.open]);

  return (
    <UniversalDialog
      {...props}
      isPrimaryButtonLoading={loading}
      primaryButonTitle="Submit"
      onClose={() => props.onClose()}
      onSave={handleAddBreak}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Stack>
            <InputLabel>Start Time</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileTimePicker
                value={dayjs(breakTime.start_time)}
                openTo="hours"
                onChange={(newValue: dayjs.Dayjs | null) => {
                  if (newValue?.isValid() && newValue) {
                    setBreakTime((prev) => ({ ...prev, start_time: newValue }));
                  }
                }}
                disabled={isBreakStarted}
              />
            </LocalizationProvider>
          </Stack>
        </Grid>
        {isBreakStarted && (
          <Grid item xs={12} sm={6}>
            <Stack>
              <InputLabel>End Time</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker
                  value={dayjs(breakTime.end_time)}
                  openTo="hours"
                  onChange={(newValue: dayjs.Dayjs | null) => {
                    if (newValue?.isValid() && newValue) {
                      setBreakTime((prev) => ({ ...prev, end_time: newValue }));
                    }
                  }}
                />
              </LocalizationProvider>
            </Stack>
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack spacing={1}>
            <InputLabel>Note *</InputLabel>
            <TextField
              autoFocus
              multiline
              minRows={3}
              maxRows={4}
              value={breakTime.note}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                setBreakTime((prev) => ({ ...prev, note: event?.target.value }));
              }}
            />
          </Stack>
        </Grid>
      </Grid>
    </UniversalDialog>
  );
};

export default AddBreakPopup;
