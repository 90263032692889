import { Avatar, Card, CardMedia, Rating, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import IconThanks from 'assets/images/icons/icon-thanks.webp';
import { useLocation } from 'react-router';
import companyServicesInstance from 'services/services.company';
import { formatPhoneNumber } from 'utils/common';

const GeneralThankYouPage = () => {
  const location = useLocation();

  const path = location.pathname.split('/');
  const searchParams = new URLSearchParams(location.search);
  const fromModule = searchParams.get('fm');

  //-----------------useQuery------------
  const { data: company } = useQuery({
    queryKey: ['company_meta_data'],
    queryFn: () => companyServicesInstance.getCompanyMetaData(path[2]),
    enabled: path[2].length > 0
  });

  const getTitle = () => {
    switch (fromModule) {
      case 'photos-link':
        return 'Your photos were uploaded successfully. We will get back to you as soon as we review them.';

      default:
        break;
    }
  };

  return (
    <div
      style={{ background: 'radial-gradient(circle at top, #fff 0%, #fff 40%, #8d8d8d 100%)' }}
      className="h-[100dvh] w-full flex flex-col items-center p-3 gap-5"
    >
      <div className="flex flex-col items-center my-6">
        {company?.company_logo && <CardMedia sx={{ height: '5rem', width: '5rem' }} component="img" src={company?.company_logo} alt={''} />}
        <Typography color={'#2e8fc6'} variant="h1">
          {company?.company_name}
        </Typography>
      </div>
      <Card className="flex flex-col xl:w-1/3 md:w-1/2 w-full">
        <div className="flex flex-col justify-center items-center bg-green-500 p-10 space-y-5">
          <Avatar variant="circular" sx={{ width: '7.5rem', height: '7rem', backgroundColor: 'white' }}>
            <CardMedia component="img" src={IconThanks} alt="Thanks" />
          </Avatar>
          <Typography className="text-wrap text-white text-center font-normal sm:text5xl text-2xl">{getTitle()}</Typography>
        </div>
        <div className="flex flex-col justify-center items-center space-y-3 sm:p-10 py-6 px-4 w-full">
          <Rating className="text-5xl" name="rating" value={5} readOnly size="large" />
          <Typography variant="h1">{company?.company_name}</Typography>
          {!!company && (
            <div className="w-full flex flex-col items-center gap-2">
              <div className="flex space-x-1 text-center">
                <Typography className="max-w-max">
                  {company?.company_address?.address1 ?? ''} {company?.company_address?.city ?? ''} {company?.company_address?.state ?? ''}{' '}
                  {company?.company_address?.zip ?? ''}
                </Typography>
              </div>
              <div className="flex items-center space-x-1 divide-x-2 divide-gray-400">
                <Typography className="text-center align-middle">{formatPhoneNumber(company?.phone1)}</Typography>
                <Typography className="pl-1">{company?.company_email}</Typography>
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default GeneralThankYouPage;
