import { EditOutlined, StopOutlined } from '@ant-design/icons';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineSeparator } from '@mui/lab';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { TCustomerNotes } from '../types/type.customersPage';

type TNotesTimelineViewProps = { data: TCustomerNotes[] | null };

const NotesTimelineView = (props: TNotesTimelineViewProps) => {
  const { data: allNotesData } = props;

  return (
    <div>
      <Timeline position="right" className="!p-0">
        {allNotesData && allNotesData?.length > 0 ? (
          allNotesData
            .map((noteData, index) => (
              <Stack direction="row" key={index}>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot className="bg-white ">
                    {noteData.note?.length > 0 ? <EditOutlined className="text-gray-500" /> : <StopOutlined className="text-gray-500" />}
                  </TimelineDot>
                  {index !== 0 ? <TimelineConnector /> : <TimelineConnector classes={{ root: 'bg-white' }} />}
                </TimelineSeparator>
                <TimelineContent className="!pr-0 my-4">
                  <Typography className="flex items-center justify-start sm:text-xs text-[11px] text-gray-500">
                    Updated by&nbsp;<strong>{noteData?.updated_by.name}</strong>
                    &nbsp;on&nbsp;
                    <strong>{noteData?.updated_at && moment(noteData?.updated_at).format('lll')}</strong>
                  </Typography>
                  <Card className="bg-gray-100 my-1 shadow-none rounded-sm ">
                    <CardContent className="p-3 min-h-[5rem] ">
                      <Typography
                        component={'p'}
                        dangerouslySetInnerHTML={{ __html: noteData.note }}
                        className="h-full w-full bg-transparent outline-none text-lg"
                      />
                    </CardContent>
                  </Card>
                </TimelineContent>
              </Stack>
            ))
            .reverse()
        ) : (
          <Typography className="text-center text-xl py-12">There is no history.</Typography>
        )}
      </Timeline>
    </div>
  );
};

export default NotesTimelineView;
