import { CloseOutlined, DeleteFilled } from '@ant-design/icons';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  AppBar,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Slide,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { IDeletePopupWActions } from 'types/globalTypes/type.deleteDialog';
import { jobStates } from 'utils/constants';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteConfirmationPoup = (props: IDeletePopupWActions) => {
  const [reasonForDeletion, setReasonForDeletion] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    // Push a state to history to detect back navigation

    window.history.pushState(null, '', window.location.href);

    const handlePopState = () => {
      setReasonForDeletion('');
      // Close the dialog instead of navigating back
      props?.onClose?.();
    };

    // Add popstate event listener
    window.addEventListener('popstate', handlePopState);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const handleDelete = () => {
    setIsLoading(() => true);
    const response = props.onDelete(props?.data?.selectedData?._id, props?.data?.selectedData?.first_name, reasonForDeletion);
    if (response) {
      setIsLoading(() => false);
    }
  };

  const handleReasonForDeletionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReasonForDeletion(event.target.value);
  };

  const isPendingPayment: boolean = React.useMemo(() => {
    if (!props?.data?.selectedData?.payments || props?.data?.selectedData?.payments?.length < 0) {
      return false;
    }
    const total = props?.data?.selectedData?.payments.reduce((total: number, payment: any) => total + payment.amount, 0);
    return total > 0;
  }, [props?.data?.selectedData]);

  return (
    <Dialog {...props.action} fullWidth open={props.action?.open} TransitionComponent={Transition}>
      <AppBar color="error" sx={{ position: 'relative', padding: '0px !important' }}>
        <Toolbar className="w-full flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <DeleteFilled />
            <Typography variant="h5">Confirm Deletion</Typography>
          </div>

          <IconButton
            edge="end"
            color="inherit"
            onClick={() => {
              setReasonForDeletion('');
              props?.onClose();
            }}
            aria-label="close"
          >
            <CloseOutlined />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {props?.data?.selectedData?.state === jobStates.INVOICE_UNPAID && (
          <Alert title="Warning" severity="warning" className="mb-3">
            <AlertTitle variant="subtitle1" color={'warning.dark'}>
              You are about to delete a job with an outstanding balance.
            </AlertTitle>
          </Alert>
        )}
        {props?.data?.selectedData?.state !== jobStates.INVOICE_UNPAID && isPendingPayment && (
          <Alert title="Warning" severity="warning" className="mb-3">
            <AlertTitle variant="subtitle1" color={'warning.dark'}>
              This job has a pending payment. Consider if reimbursement is needed.
            </AlertTitle>
          </Alert>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {props?.data?.cautionString ? (
              <span>{props?.data?.cautionString}</span>
            ) : (
              <Typography variant="body1">
                <span className="mr-1"> Are you sure you want to delete</span>
                <strong>{props?.data?.subjectToBeDeleted}</strong> ?
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            {props?.action.reasonRequired && (
              <TextField
                autoFocus={true}
                multiline
                minRows={3}
                fullWidth
                placeholder="Reason for deletion"
                label="Reason*"
                onChange={handleReasonForDeletionChange}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={isLoading}
          type="submit"
          sx={{
            '&.Mui-disabled': {
              boxShadow: 'none !important'
            }
          }}
          onClick={handleDelete}
          color="error"
          variant="contained"
          disabled={props?.action?.reasonRequired && !reasonForDeletion?.length}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationPoup;
