import { useTheme } from '@mui/material';
import LogoMain from 'components/logo/LogoMain';
import './unauthorisedPage.css';
import { Link } from 'react-router-dom';

const ReportUnauthorizedPageimport = ({ reportName }: { reportName: string }) => {
  const theme = useTheme();
  //   const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const primaryColor = theme.palette.primary;
  return (
    <div className="page-wrap w-full">
      <div className="page-not-found space-y-8 flex flex-col items-center">
        <LogoMain />
        <h4 className="text-md" style={{ color: primaryColor.main }}>
          Access Denied !
        </h4>
        <h4 className="text-sm text-sm-btm">
          You don’t have access to this report. You can go back to the authorized report.{' '}
          <Link to={`/reports/${reportName}`}>Report Page</Link>
        </h4>
      </div>
    </div>
  );
};

export default ReportUnauthorizedPageimport;
