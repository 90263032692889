import { LoadingOutlined } from '@ant-design/icons';
import { Box, Button } from '@mui/material';
import { Formik, FormikState } from 'formik';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router';
import widgetServicesInstance from 'services/services.estimateFormWidget';
import FileUploadServiceInstance from 'services/services.fileUpload';
import { generateRandomKeyword } from 'utils/common';
import { incorrectCaptchaMessage } from 'utils/constants';
import * as Yup from 'yup';
import CreateEstimateWidgetForm from './components/CreateEstimateWidgetForm';
import ThankYouPage from './ExternalFormWidgets/ThankYouPage';
import { EstimateFormData } from './types/types.estimateFormWidget';

const EstimateFormWidgets = () => {
  //------------------------------Constants------------------
  const [captcha, setCaptcha] = useState<string>('');
  const [isThankYouPage, setIsThankYouPage] = useState(false);
  const [jobId, setJobId] = useState('');
  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const companyId = new URLSearchParams(location.search).get('company_id');
  const googleMapRef = useRef<{ resetSearchText: () => {} }>();

  //-----------------------------handlers----------------------

  const createNewCaptchaPattern = (length: number) => {
    setCaptcha(generateRandomKeyword(length));
  };
  const handleEstimateFormSubmit = async (
    values: EstimateFormData,
    {
      resetForm,
      setFieldValue
    }: {
      resetForm: (nextState?: Partial<FormikState<EstimateFormData>> | undefined) => void;
      setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    }
  ) => {
    setIsLoading(true);
    if (values.property_type === 'residential') delete values.company_name;
    delete values.captcha;
    values = { ...values, company_id: companyId };

    const newImagesData: string[] = [];
    if (!!uploadedImages.length) {
      await Promise.all(
        uploadedImages.map(async (eachFile) => {
          const isImageOrVideo = eachFile.type.startsWith('image/') || eachFile.type.startsWith('video/');

          if (!isImageOrVideo) {
            return;
          }

          const response = await FileUploadServiceInstance.uploadFile(eachFile);
          if (response && response.data) {
            newImagesData.push(response.data);
          }
        })
      );
    }

    const response = await widgetServicesInstance.createCustomerEstimate({ ...values, files: newImagesData });
    if (response?.success) {
      setJobId(response.jobId);
      resetForm();
      googleMapRef.current?.resetSearchText();
      createNewCaptchaPattern(4);
      setIsThankYouPage(true);
      return;
    }
    setIsThankYouPage(false);
    setFieldValue('captcha', '');
    createNewCaptchaPattern(4);
    setIsLoading(false);
  };

  return isThankYouPage ? (
    <ThankYouPage jobId={jobId} />
  ) : (
    <>
      <div className="xs:text-xl md:text-3xl font-bold text-center py-5">Request A Free Estimate</div>
      <div className="md:px-[20vw] lg:px-[30vw] space-y-4">
        <Formik
          initialValues={{
            property_type: 'residential',
            company_name: '',
            first_name: '',
            last_name: '',
            address: {
              address1: '',
              city: '',
              state: '',
              zip: '',
              country: ''
            },
            phone_country_code: '',
            phone: null as unknown as number,
            phone_type: 'mobile',
            email: '',
            job_details: '',
            captcha: '',
            referral: 'Other'
          }}
          validationSchema={Yup.object().shape({
            first_name: Yup.string().max(255).required('First Name is required'),
            last_name: Yup.string().max(255).required('Last Name is required'),
            property_type: Yup.string(),
            company_name: Yup.string().when('property_type', ([property_type], schema) => {
              if (property_type === 'commercial') {
                return schema.required('Company name is required');
              }
              return schema.nullable();
            }),
            phone: Yup.string().min(5, 'Numbers should be at least 5 digits long').required(),
            address: Yup.object().shape({
              address1: Yup.string().required('Address is required'),
              city: Yup.string().required('City is required'),
              state: Yup.string().required('State is required'),
              zip: Yup.string().required('Zip is required'),
              country: Yup.string().required('Zip is required')
            }),
            phone_type: Yup.string().max(255).required('Phone Type is required'),
            email: Yup.string().email('Please provide correct email address').required('Email is required'),
            job_details: Yup.string().max(255).required('Job details is required'),
            captcha: Yup.string().oneOf([captcha], incorrectCaptchaMessage).required('Captcha is required')
          })}
          onSubmit={async (values: EstimateFormData, { setSubmitting, resetForm, setFieldValue }) => {
            setSubmitting(true);
            handleEstimateFormSubmit(values, { resetForm, setFieldValue });
            setSubmitting(false);
          }}
        >
          {(formikProps) => (
            <Box component={'form'} onSubmit={formikProps.handleSubmit}>
              <CreateEstimateWidgetForm
                formik={formikProps}
                googleMapRef={googleMapRef}
                createNewCaptchaPattern={createNewCaptchaPattern}
                captcha={captcha}
                companyId={companyId as string}
                setUploadedImages={setUploadedImages}
                uploadedImages={uploadedImages}
              />
              <div className="flex justify-end mt-4">
                <Button
                  variant="contained"
                  type="submit"
                  disabled={formikProps.isSubmitting || isLoading}
                  startIcon={(formikProps.isSubmitting || isLoading) && <LoadingOutlined />}
                >
                  Submit
                </Button>
              </div>
            </Box>
          )}
        </Formik>
      </div>
    </>
  );
};

export default EstimateFormWidgets;
