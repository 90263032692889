import { ArrowLeftOutlined, CheckSquareFilled, DollarCircleFilled, EyeOutlined, PlusOutlined, ScheduleFilled } from '@ant-design/icons';
import { Button, CardContent, CardHeader, Grid, Hidden, IconButton, Paper, Stack, Typography, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import Loader from 'components/Loader';
import PayNowPopup from 'components/popups/PayNowPopup';
import CustomDataTable from 'components/tables/CustomDataTable';
import dayjs from 'dayjsConfig';
import { motion } from 'framer-motion';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import payrollServicesInstance from 'services/services.payroll';
import { useSelector } from 'store';
import { formateData, snakeCaseToTitleCase, toUrlSafeBase64 } from 'utils/common';
import EcommerceMetrix from './components/EcommerceMetrix';
import { TPayRollUserRangeData } from './types/types.payroll';

const UserPayrollRangeView = () => {
  //-------------------------Constants-------------
  const { id: userId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();

  const settings = useSelector((state) => state.settings.generalSettings);
  const timezone = useMemo(() => settings?.timezone ?? 'America/New_York', [settings]);

  const [openPayNowPopup, setOpenPayNowPopup] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<{ start_date: string | null; end_date: string | null }>({
    start_date: null,
    end_date: null
  });
  const columns = useMemo<ColumnDef<TPayRollUserRangeData>[]>(
    () => [
      {
        cell: ({ row: { original: originalData } }) => (
          <Typography
            sx={{
              '&:hover': {
                color: theme.palette.primary.main
              }
            }}
            component={Link}
            to={toUrlSafeBase64(originalData.date)}
          >
            {dayjs(originalData.date).tz(timezone).format('MM/DD/YYYY')}
          </Typography>
        ),
        id: 'user',
        header: () => <span>Date</span>
      },
      {
        accessorFn: (row) => (row.payment !== undefined && row.payment !== null ? formateData(row.payment, '$') : '-'),
        id: 'pay',
        header: () => <span>Pay</span>
      },
      {
        accessorFn: (row) => (row.paid_amount !== undefined && row.paid_amount !== null ? formateData(row.paid_amount, '$') : '-'),
        id: 'paid_amount',
        header: () => <span>Paid Amount</span>
      },
      {
        accessorFn: (row) => (row.cash_collected !== undefined && row.cash_collected !== null ? formateData(row.cash_collected) : '-'),
        id: 'cash_collected',
        header: () => <span>Cash Collected</span>
      },
      {
        accessorFn: (row) => row.cheques_collected ?? '-',
        id: 'cheques_collected',
        header: () => <span>Checkes Collected</span>
      },
      {
        cell: ({ row: { original: originalData } }) =>
          originalData.is_punch && (
            <Button component={Link} to={`/payroll/timesheet/${originalData.punch_id}`} startIcon={<EyeOutlined />} variant="contained">
              View
            </Button>
          ),
        id: 'clock_in',
        header: () => <span>Clock In/Out</span>
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  //-----------------------------useQuery-------------------
  const {
    data: payrollData,
    isLoading: isPayRollFetching,
    refetch: refetchPayrollData
  } = useQuery({
    queryKey: ['payroll_date_range', userId, selectedFilter],
    queryFn: () => {
      const filter =
        !!selectedFilter.start_date && !!selectedFilter.end_date
          ? `?filter={"start_date":"${selectedFilter.start_date}","end_date":"${selectedFilter.end_date}"}`
          : '';
      if (userId) return payrollServicesInstance.getUserPayrollRangeData(userId, filter);
    },
    enabled: !!userId?.length
  });

  return isPayRollFetching && !payrollData ? (
    <Loader />
  ) : (
    <Paper elevation={3} className="flex flex-col items-start space-y-4">
      <CardContent>
        <motion.div
          className="w-full"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.7, ease: 'easeInOut' }}
        >
          {!!payrollData?.user && (
            <CardHeader
              title={
                <Stack direction={'row'} alignItems={'center'}>
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowLeftOutlined />
                  </IconButton>
                  <div className="text-xs sm:text-md">
                    {payrollData?.user.first_name} {payrollData?.user.last_name}&nbsp;({snakeCaseToTitleCase(payrollData?.user.role)})
                  </div>
                </Stack>
              }
              action={
                <div className="flex">
                  <Hidden smDown>Payment Status:</Hidden>
                  <Typography
                    variant="subtitle1"
                    color={
                      payrollData.user.payroll.paid_amount === 0
                        ? theme.palette.info.main
                        : payrollData.user.payroll.paid_amount < payrollData.user.payroll.payment
                        ? theme.palette.warning.main
                        : theme.palette.success.main
                    }
                  >
                    &nbsp;
                    {payrollData.user.payroll.paid_amount === 0
                      ? 'Unpaid'
                      : payrollData.user.payroll.paid_amount < payrollData.user.payroll.payment
                      ? 'Partially Paid'
                      : 'Paid'}
                  </Typography>
                </div>
              }
            />
          )}
        </motion.div>
        <Grid container rowGap={2}>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} lg={4} sm={6}>
              <EcommerceMetrix
                primary="Amount Owed"
                secondary={formateData(payrollData?.user.payroll.payment ?? 0, '$')}
                color={theme.palette.info.main}
                iconPrimary={DollarCircleFilled}
              />
            </Grid>
            <Grid item xs={12} lg={4} sm={6}>
              <EcommerceMetrix
                primary="Cash Collected"
                secondary={payrollData?.user.payroll.cash_collected.toString()}
                color={theme.palette.success.main}
                iconPrimary={ScheduleFilled}
              />
            </Grid>
            <Grid item xs={12} lg={4} sm={12}>
              <EcommerceMetrix
                primary="Checks Collected"
                secondary={payrollData?.user.payroll.cheques_collected.toString()}
                color={theme.palette.warning.main}
                iconPrimary={CheckSquareFilled}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={2} sx={{ justifyContent: { lg: 'start' } }}>
            <Grid item xs={12} sm={6} md={4}>
              <DatePicker
                className="w-full"
                label="Start Date"
                value={selectedFilter.start_date ? dayjs(selectedFilter.start_date) : null}
                onChange={(newValue) => newValue && setSelectedFilter({ ...selectedFilter, start_date: newValue.toISOString() })}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DatePicker
                className="w-full"
                label="End Date"
                value={selectedFilter.end_date ? dayjs(selectedFilter.end_date) : null}
                onChange={(newValue) => newValue && setSelectedFilter({ ...selectedFilter, end_date: newValue.toISOString() })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} className="flex sm:justify-end space-x-2">
              <Button
                className="w-full sm:w-fit"
                startIcon={<PlusOutlined />}
                variant="contained"
                onClick={() => setOpenPayNowPopup(!openPayNowPopup)}
              >
                Pay Now
              </Button>
              {/* <Button className="w-full sm:w-fit" startIcon={<PlusOutlined />} variant="contained">
                Email Payroll
              </Button> */}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      {payrollData && (
        <>
          <CustomDataTable data={payrollData?.payrolls} columns={columns} hasPagination={true} />
          {openPayNowPopup && (
            <PayNowPopup
              payrollData={payrollData?.user}
              open={openPayNowPopup}
              onClose={() => {
                setOpenPayNowPopup(false);
                refetchPayrollData();
              }}
            />
          )}
        </>
      )}
    </Paper>
  );
};

export default UserPayrollRangeView;
