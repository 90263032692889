import { TextField, OutlinedInputProps, TextFieldProps } from '@mui/material';
import React from 'react';

type TNumberInput = {
  value: any;
  onChangeHandler: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, ...args: any) => void;
  InputProps?: Partial<OutlinedInputProps> | undefined;
  name?: string;
  args: Array<any>;
  className?: string;
} & TextFieldProps;

const NumberInput = ({ value, onChangeHandler, InputProps, name, args, className, ...props }: TNumberInput) => {
  return (
    <TextField
      {...props}
      InputProps={InputProps}
      variant="outlined"
      type="number"
      name={name}
      value={value}
      onChange={(event) => {
        onChangeHandler(event, ...args);
      }}
      className={className}
      onKeyDown={(e: any) => {
        if (e.key === 'e') e.preventDefault();
        if (e.key === '-') e.preventDefault();
        if (e.target.value > 0) return;
        if (e.key === 'ArrowDown' && e.target.value <= 0) e.preventDefault();
      }}
    />
  );
};

export default NumberInput;
