import { LoadingOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Button, DialogActions, DialogContent, DialogTitle, Divider, Grid, InputLabel, Stack, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import ProductPlaceholder from 'components/cards/skeleton/ProductPlaceholder';
import { Form, FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import { useEffect } from 'react';
import calendarServicesInstance from 'services/services.calendar';
import { useSelector } from 'store';
import * as Yup from 'yup';

const AddEventFrom = ({ refetchCalendarData, onCancel }: { refetchCalendarData: () => void; onCancel: () => void }) => {
  //---------------constants------------
  const { selectedEventDate, isModalOpen } = useSelector((state) => state.calendarOption);
  //---------------useQuery-------------
  const { data: eventData, isFetched: isEventDataFetched } = useQuery({
    queryKey: ['event_form_data'],
    queryFn: () => {
      if (!!selectedEventDate?.length) return calendarServicesInstance.getEventData(selectedEventDate);
    },
    enabled: isModalOpen && !!selectedEventDate && selectedEventDate?.length > 0
  });
  //-----------------formik--------------
  const formik = useFormik({
    initialValues: {
      date: selectedEventDate as string,
      job_count: null as unknown as number,
      job_price: null as unknown as number,
      bg_color: '#ffffff',
      note: ''
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const response = await calendarServicesInstance.updateEventData(values);
      if (response) {
        onCancel();
        refetchCalendarData();
      }
      setSubmitting(false);
    }
  });
  //------------------Handlers-------------------
  const handleDeleteEvent = async () => {
    if (!!eventData?._id?.length) {
      const response = await calendarServicesInstance.deleteEventData(eventData._id);
      if (response) {
        refetchCalendarData();
        onCancel();
      }
    }
  };

  //----------------------useEffect----------------
  useEffect(() => {
    if (isEventDataFetched && !!eventData && !!selectedEventDate?.length) {
      formik.setValues({
        date: moment(selectedEventDate).format('YYYY-MM-DD'),
        bg_color: eventData.bg_color,
        job_count: eventData.job_count,
        job_price: eventData.job_price,
        note: eventData.note
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData]);

  return (
    <>
      {isEventDataFetched ? (
        <FormikProvider value={formik}>
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
          <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
            <DialogTitle className="flex justify-end">
              <PlusCircleFilled rotate={45} onClick={onCancel} />
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Stack spacing={1.25}>
                    <InputLabel htmlFor="date">Date</InputLabel>
                    <TextField fullWidth id="date" disabled={true} value={formik.values.date} />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack spacing={1.25}>
                    <InputLabel htmlFor="job_count">Job Count</InputLabel>
                    <TextField value={formik.values.job_count} fullWidth id="job_count" name="job_count" onChange={formik.handleChange} />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack spacing={1.25}>
                    <InputLabel htmlFor="job_price">Job Price</InputLabel>
                    <TextField fullWidth value={formik.values.job_price} id="job_price" name="job_price" onChange={formik.handleChange} />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack spacing={1.25}>
                    <InputLabel htmlFor="bg_color">Bg Color</InputLabel>
                    <TextField
                      fullWidth
                      value={formik.values.bg_color}
                      id="bg_color"
                      name="bg_color"
                      onChange={formik.handleChange}
                      type="color"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1.25}>
                    <InputLabel htmlFor="note">Note</InputLabel>
                    <TextField
                      fullWidth
                      id="note"
                      value={formik.values.note}
                      multiline
                      rows={3}
                      onChange={formik.handleChange}
                      name="note"
                    />
                  </Stack>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Button variant="text" disabled={formik.isSubmitting || eventData === undefined} color="error" onClick={handleDeleteEvent}>
                  {'Delete'}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={formik.isSubmitting}
                  startIcon={formik.isSubmitting && <LoadingOutlined />}
                >
                  {'Save'}
                </Button>
              </Stack>
            </DialogActions>
          </Form>
          {/* </LocalizationProvider> */}
        </FormikProvider>
      ) : (
        <ProductPlaceholder />
      )}
    </>
  );
};

export default AddEventFrom;
