// ==============================|| WIDGET ROUTING ||============================== //

import AutoAppointmentWidget from 'components/widgets/AutoAppointment/AutoAppointmentWidget';
import ScheduleAppointmentFromMail from 'components/widgets/AutoAppointment/ScheduleAppointmentFromMail';
import Checkout from 'components/widgets/Checkout/Checkout';
import GeneralThankYouPage from 'components/widgets/components/GeneralThankYouPage';
import EstimateFormWidgets from 'components/widgets/EstimateFormWidgets';
import AcceptAndDeclineProposalForm from 'components/widgets/ExternalFormWidgets/Proposal/AcceptAndDeclineProposalForm';
import ThankYouPage from 'components/widgets/ExternalFormWidgets/ThankYouPage';
import ProposalPage from 'components/widgets/Proposal/ProposalPage';
import UpdateEstimateSignature from 'components/widgets/UpdateEstimateSignature/UpdateEstimateSignature';
import UploadPhotosWidget from 'components/widgets/UploadPhotos';
import ViewInvoiceAndProposalPage from 'components/widgets/ViewProposalAndInvoicePage/ViewInvoiceAndProposalPage';

const WidgetRoutes = {
  path: '/',
  children: [
    {
      path: '/estimate-widget',
      element: <EstimateFormWidgets />
    },
    {
      path: '/appointment-widget',
      element: <AutoAppointmentWidget />
    },
    {
      path: '/schedule-job',
      element: <ScheduleAppointmentFromMail />
    },
    {
      path: '/proposal',
      element: <AcceptAndDeclineProposalForm />
    },
    {
      path: '/proposal/:jobId',
      element: <ThankYouPage />
    },
    {
      path: '/signature/thankyou-signoff/:is_proposal_accepted/:jobId',
      element: <ThankYouPage />
    },
    {
      path: '/payment/thankyou',
      element: <ThankYouPage />
    },
    {
      path: '/proposal/view',
      element: <ProposalPage />
    },
    {
      path: '/signature/update-estimate',
      element: <UpdateEstimateSignature />
    },
    {
      path: '/signature/job-completed',
      element: <UpdateEstimateSignature />
    },
    {
      path: '/invoice/view',
      element: <ViewInvoiceAndProposalPage />
    },
    {
      path: '/payment/checkout/:jobId',
      element: <Checkout />
    },
    {
      path: '/upload-photos',
      element: <UploadPhotosWidget />
    },
    {
      path: '/thankyou/:compid',
      element: <GeneralThankYouPage />
    }
  ]
};

export default WidgetRoutes;
