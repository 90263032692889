import { Button, Typography } from '@mui/material';
import { TJobInfo } from 'components/widgets/types/jobInfoType';
import { useRef } from 'react';
import { useNavigate } from 'react-router';
import ReactSignatureCanvas from 'react-signature-canvas';
import JobServicesInstance from 'services/services.jobs';
import { constants } from 'utils/constants';

export const AcceptForm = ({ optionId, jobData }: { optionId: string; jobData: TJobInfo }) => {
  //------------Constants-------------------
  const signatureRef = useRef<any>();
  const navigate = useNavigate();
  const clearSignature = () => {
    signatureRef?.current?.clear();
  };
  const saveSignature = async () => {
    if (jobData?.job?._id && jobData?.company?._id) {
      const signatureDataUrl = signatureRef?.current?.toDataURL();
      const response = await JobServicesInstance.approveProposal(
        {
          company_id: jobData?.company._id,
          option_id: optionId,
          proposal_approve_signature: signatureDataUrl,
          approved_proposal_link: `${constants.REACT_APP_BASE_URL}/proposal/view?job_id=${jobData.job._id}`,
          estimation_page_link: `${constants.REACT_APP_BASE_URL}/jobs/view/${jobData.job._id}/job_status`
        },
        jobData?.job?._id
      );
      if (response && response.data && response.data.success) {
        navigate(`/proposal/${jobData.job._id}`);
      }
    }
  };

  return (
    <div className="flex flex-col justify-center space-y-3 mt-1">
      <Typography variant="h4" textAlign={'center'}>
        Please enter your signature to approve proposal
      </Typography>
      <div className="flex flex-row justify-center">
        <ReactSignatureCanvas
          penColor="black"
          canvasProps={{
            className: 'signateureCanvas',
            style: { width: '100%', maxWidth: '600px', border: '1px solid black' }
          }}
          ref={signatureRef}
        />
      </div>
      <div className="flex space-x-2 justify-center">
        <Button onClick={clearSignature} variant="outlined" color="secondary">
          Clear
        </Button>
        <Button onClick={() => saveSignature()} variant="contained" color="primary">
          Save
        </Button>
      </div>
    </div>
  );
};
