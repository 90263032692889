import { ArrowLeftOutlined, UserOutlined } from '@ant-design/icons';
import { IconButton, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import ProductPlaceholder from 'components/cards/skeleton/ProductPlaceholder';
import CustomDataTable from 'components/tables/CustomDataTable';
import moment from 'moment';
import { TUserLogs } from 'pages/Settings/types/types.userPopup';
import { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import UserServicesInstance from 'services/services.users';

const AdminLogsHistory = () => {
  const navigate = useNavigate();
  const { id: userId } = useParams();
  const location = useLocation();

  const { data: fetchUserLogs, isFetching: isUserLogsFetching } = useQuery({
    queryKey: ['user_logs'],
    queryFn: () => UserServicesInstance.getUserLogs(userId as string),
    enabled: !!userId
  });

  const formatMinutes = (totalMinutes: number) => {
    const duration = moment.duration(totalMinutes, 'minutes');
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    let formattedString = '';
    if (hours > 0) {
      formattedString += `${hours} Hour${hours !== 1 ? 's' : ''} `;
    }
    formattedString += `${minutes} Minute${minutes !== 1 ? 's' : ''}`;
    return formattedString.trim();
  };

  const columns = useMemo<ColumnDef<TUserLogs>[]>(
    () => [
      {
        id: 'date',
        header: () => <span>Date</span>,
        cell: ({ row }) => {
          const { created_at } = row.original;
          return <span>{moment(created_at).format('MM/DD/YYYY')}</span>;
        }
      },
      {
        id: 'total_hour',
        header: () => <span>Total Hour</span>,
        cell: ({ row }) => {
          const { minutes: totalMinutes } = row.original;
          return <span>{formatMinutes(totalMinutes)}</span>;
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="w-full h-full">
      <div className="my-6 flex items-center space-x-4">
        <IconButton onClick={() => navigate(-1)}>
          <ArrowLeftOutlined />
        </IconButton>
        <UserOutlined style={{ transform: 'scale(1.5)', width: 20 }} />
        <Typography className="text-3xl ">{location.state?.name}</Typography>
      </div>
      {!!fetchUserLogs && !isUserLogsFetching ? (
        <CustomDataTable hasPagination={false} columns={columns} data={fetchUserLogs.logs} />
      ) : (
        <ProductPlaceholder />
      )}
    </div>
  );
};

export default AdminLogsHistory;
