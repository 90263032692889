import InactivityReasonPopup from 'components/popups/InactivityReasonPopup';
import useAuth from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';

const ActivityTracker = ({ children }: { children: React.ReactElement }) => {
  const loggedInUser = useAuth();
  const userInactivity = loggedInUser.user?.inactivity ? loggedInUser.user?.inactivity * 60 * 1000 : 0;
  const [isInactive, setIsInactive] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!loggedInUser.user?.inactivity) return;

    const updateExpiry = () => {
      if (isPopupOpen) return;

      const newExpiryTime = Date.now() + userInactivity;
      localStorage.setItem('expireTime', JSON.stringify(newExpiryTime));
      setIsInactive(false);
    };

    const checkInactivity = () => {
      const expireTime = localStorage.getItem('expireTime');
      if (expireTime && Number(expireTime) < Date.now()) {
        setIsInactive(true);
        setIsPopupOpen(true);
      }
    };

    const debouncedUpdateExpiry = () => {
      if (isPopupOpen) return; // Ignore user activity when popup is open
      clearTimeout((window as any).activityDebounce);
      (window as any).activityDebounce = setTimeout(updateExpiry, 200);
    };

    updateExpiry();

    window.addEventListener('click', debouncedUpdateExpiry);
    window.addEventListener('mousemove', debouncedUpdateExpiry);
    window.addEventListener('touchstart', debouncedUpdateExpiry);

    const interval = setInterval(checkInactivity, 1000);

    return () => {
      clearInterval(interval);
      clearTimeout((window as any).activityDebounce);
      window.removeEventListener('click', debouncedUpdateExpiry);
      window.removeEventListener('mousemove', debouncedUpdateExpiry);
      window.removeEventListener('touchstart', debouncedUpdateExpiry);
    };
  }, [userInactivity, loggedInUser.user?.inactivity, isPopupOpen]);

  const handleInactivityReasonSuccess = () => {
    setIsInactive(false);
    setIsPopupOpen(false);
    localStorage.removeItem('expireTime');
    localStorage.removeItem('isExpired');
  };

  return <>{isInactive ? <InactivityReasonPopup open={isPopupOpen} onSuccess={handleInactivityReasonSuccess} /> : children}</>;
};

export default ActivityTracker;
