import axios, {
  AxiosRequestHeaders,
  InternalAxiosRequestConfig
  // , InternalAxiosRequestConfig
} from 'axios';
import { setSession } from 'contexts/JWTContext';
import { dispatch } from 'store';
import { LOGOUT } from 'store/reducers/actions';
import { getAccessToken } from './common';

const axiosServices = axios.create({ baseURL: process.env.REACT_APP_API_URL });

const geoLocator = async (): Promise<GeolocationPosition> => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve(position);
      },
      (e) => {
        console.log('GEOLOCATION ERROR', e);
        // if (e.PERMISSION_DENIED) {
        //   window.history.replaceState({ reason: 1 }, '', '/dashboard');
        // }
        reject(e);
      },
      {
        timeout: 10000
      }
    );
  });
};

const geolocation = async (): Promise<GeolocationPosition> => {
  return await geoLocator();
};

// // ==============================|| AXIOS ||============================== //

type TAxiosRequestHeadersWithCoords = {
  'x-longitude': string;
  'x-latitude': string;
} & AxiosRequestHeaders;

interface ICustomAxiosRequestConfig extends InternalAxiosRequestConfig<any> {
  locationNeeded?: boolean;
  serviceToken?: string;
}

axiosServices.interceptors.request.use(
  async (config: ICustomAxiosRequestConfig) => {
    let additionalHeaders = {};
    if (config.serviceToken) {
      additionalHeaders = {
        Authorization: `Bearer ${config.serviceToken}`
      };
    }
    if (config.locationNeeded || window.localStorage.getItem('locationNeeded') === 'true') {
      try {
        const locationData = await geolocation();
        additionalHeaders = {
          ...additionalHeaders,
          'x-latitude': locationData.coords.latitude.toString(),
          'x-longitude': locationData.coords.longitude.toString()
        };
      } catch (error) {
        console.log('Error obtaining geolocation:', error);
      }
    }

    config.headers = {
      ...config.headers,
      Authorization: !config.serviceToken ? `Bearer ${getAccessToken()}` : `Bearer ${config.serviceToken}`,
      ...additionalHeaders
    } as AxiosRequestHeaders & TAxiosRequestHeadersWithCoords;

    return config;
  },
  (error) => {
    console.log('Error in axios', error);
    return Promise.reject(error);
  }
);

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);

    if (!error.response.data.success) {
      switch (error.response.data.error_code) {
        case 1002:
          window.history.replaceState({ reason: 1 }, '', '/error/grant_location_access');
          break;
        case 1001:
        case 1003:
          if (!!window.localStorage.getItem('serviceToken')) {
            setSession(null);
            dispatch({ type: LOGOUT });
            window.history.replaceState({ from: '' }, '', '/login');
            window.location.href = '/login';
          }
          break;
        default:
          break;
      }
    }
    if (error.response && error.response.status === 401 && !window.location.href.includes('/login')) {
      setSession(null);
      window.location.pathname = '/login';
    }
    return Promise.reject((error.response && error.response.data) || 'Wrong Services');
  }
);

export default axiosServices;
