import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { UserProfile } from 'types/auth';
import axiosServices from 'utils/axios';

class ProfileServices {
  updateUserProfile = async (usersData: UserProfile) => {
    try {
      const response: { data: { success: boolean; data: UserProfile } } = await axiosServices.put('/api/user/me', usersData);
      if (response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Profile updated successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
}
const profileServicesInstance = new ProfileServices();
export default profileServicesInstance;
