import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import moment from 'moment';
import { TDateFilter } from 'pages/Dashboard/types/types.dashboard';
import ReportDateFilter from 'pages/Reports/components/ReportDateFilter';
import { formateData } from 'utils/common';

const GrossIncomeReportFilter = ({
  selectedFilter,
  setSelectedFilter,
  grossIncome,
  netProfit
}: {
  selectedFilter: TDateFilter;
  setSelectedFilter: (value: TDateFilter) => void;
  grossIncome: number;
  netProfit: number;
}) => {
  //--------------------constants--------------------
  const dateFilter = [
    { label: `This Year ${new Date().getFullYear()}`, value: 'this_year' },
    { label: 'Last 10 days', value: 'last_ten_days' },
    { label: 'Last 30 days', value: 'last_thirty_days' },
    { label: `Last Year ${new Date().getFullYear() - 1}`, value: 'last_year' },
    { label: 'Date Range', value: 'date_range' }
  ];

  return (
    <Card>
      {/*----------------------------------------Header----------------------- */}
      <CardHeader
        title={
          <div className="text-2xl">
            {selectedFilter.filter_value === 'last_year' && moment().year() - 1}
            {selectedFilter.filter_value === 'this_year' && moment().year()} Gross Income
          </div>
        }
        className="text-center text-gray-600"
        subheader={<div className="text-md">{formateData(grossIncome ?? 0, '$')}</div>}
      />
      <CardContent className="space-y-3 w-full">
        {/*-----------------------Date Range Fields-------------------- */}
        <ReportDateFilter dateFilters={dateFilter} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />
        <Divider orientation="horizontal" />
        <div>
          <Typography className="text-center font-semibold text-lg">{formateData(netProfit ?? 0, '$')}</Typography>
          <Typography className="text-center text-md">Net Profit</Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default GrossIncomeReportFilter;
