import * as React from 'react';

// material-ui
import { Autocomplete, Box, Grid, InputAdornment, ListItem, TextField, Typography } from '@mui/material';

// third-party
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { getGeocode } from 'use-places-autocomplete';

// project import
import { EnvironmentFilled, EnvironmentOutlined } from '@ant-design/icons';

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

type TGoogleMapsProps = {
  formik: any;
  disabled?: boolean;
  root_field_name?: string;
};

// ==============================|| GOOGLE MAP - AUTOCOMPLETE ||============================== //

const GoogleMaps = React.forwardRef(({ formik, disabled, root_field_name = 'address' }: TGoogleMapsProps, ref?: any) => {
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);

  React.useImperativeHandle(ref, () => ({
    resetSearchText() {
      setValue(null);
    }
  }));

  React.useEffect(() => {
    const fetch = throttle((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
      (autocompleteService.current as any).getPlacePredictions({ ...request }, callback);
    }, 200);

    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) {
      console.log("Google Maps API library is not loaded. Make sure you've added the key in the App.js");

      return undefined;
    }

    if (inputValue === '') {
      setOptions(() => (value ? [value] : []));
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue]);

  return (
    <Autocomplete
      id="google-map-autocomplete"
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      fullWidth
      autoHighlight
      includeInputInList
      filterSelectedOptions
      disabled={disabled}
      value={value}
      onChange={(event: React.SyntheticEvent<Element, Event>, newValue: PlaceType | null) => {
        setOptions(() => (newValue ? [newValue, ...options] : [...options]));
        setValue(newValue);
        let address1: string = '';
        let address2: string = '';
        getGeocode({ address: newValue?.description })
          .then((results: any) => {
            results[0].address_components.filter((locData: any) => {
              if (locData.types.some((eachType: string) => ['route', 'premise', 'neighborhood', 'street_number'].includes(eachType))) {
                if (locData.long_name !== undefined) address1 = address1 !== '' ? `${address1} ${locData.long_name}` : locData.long_name;
              }

              if (locData.types.some((eachType: string) => ['sublocality', 'sublocality_level_1'].includes(eachType))) {
                if (locData.long_name !== undefined) address2 = address2 !== '' ? `${address2} ${locData.long_name}` : locData.long_name;
              }

              if (locData.types.some((eachType: string) => ['locality', 'postal_town'].includes(eachType))) {
                locData.long_name !== undefined && formik.setFieldValue(`${root_field_name}.city`, locData.long_name);
                formik.setFieldTouched(`${root_field_name}.city`, false, false);
              }

              if (locData.types[0] === 'administrative_area_level_1') {
                locData.long_name !== undefined && formik.setFieldValue(`${root_field_name}.state`, locData.long_name);
                formik.setFieldTouched(`${root_field_name}.state`, false, false);
              }

              if (locData.types[0] === 'country') {
                formik.setFieldValue(`${root_field_name}.country`, locData.short_name);
                formik.setFieldTouched(`${root_field_name}.country`, false, false);
              }
              if (locData.types[0] === 'postal_code') {
                locData.long_name !== undefined && formik.setFieldValue(`${root_field_name}.zip`, locData.long_name);
                formik.setFieldTouched(`${root_field_name}.zip`, false, false);
              }
              return false;
            });
          })
          .then(() => {
            formik.setFieldValue(`${root_field_name}.address1`, address1);
            formik.setFieldValue(`${root_field_name}.address2`, address2);
            formik.setFieldTouched(`${root_field_name}.address1`, false, false);
            formik.setFieldTouched(`${root_field_name}.address2`, false, false);
          });
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete="off"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EnvironmentFilled />
              </InputAdornment>
            )
          }}
          placeholder="Search address"
          fullWidth
        />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches?.map((match: any) => [match.offset, match.offset + match.length]) ?? [0, 0]
        );

        return (
          <ListItem {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box component={EnvironmentOutlined} sx={{ color: 'text.secondary', mr: 2 }} />
              </Grid>
              <Grid item xs>
                {parts.map((part: any, index: number) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        );
      }}
    />
  );
});

export default GoogleMaps;
