import {
  AlertFilled,
  AppstoreOutlined,
  CameraFilled,
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  FileDoneOutlined,
  FileSearchOutlined,
  FormOutlined,
  MenuOutlined,
  MinusCircleOutlined,
  PlusSquareFilled,
  ReadOutlined,
  SafetyOutlined,
  SendOutlined,
  SolutionOutlined,
  StockOutlined,
  UploadOutlined,
  UserSwitchOutlined
} from '@ant-design/icons';
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import CircularWithPath from 'components/@extended/progress/CircularWithPath';
import CustomTooltip from 'components/CustomTooltip';
import Media from 'components/Media';
import RenderStatusPreviewIcons from 'components/RenderStatusPreviewIcons';
import CustomerQuickActions from 'components/buttons/CustomerQuickActions';
import AddEstimateForm from 'components/forms/AddEstimateForm';
import AssignWorkerForm from 'components/forms/AssignWorkerForm';
import EstimatorProposalAcceptanceWithoutSending from 'components/forms/EstimatorProposalAcceptanceWithoutSending';
import ReassignEstimatorForm from 'components/forms/ReassignEstimatorForm';
import ReAssignFieldWorker from 'components/forms/ReassignFieldWorker';
import AddEmailorSms from 'components/popups/AddEmailorSms';
import DeleteConfirmationPoup from 'components/popups/DeleteConfirmationPopup';
import UniversalDialog from 'components/popups/UniversalDialog';
import { TEstimateOptions } from 'components/tables/interfaces/estimateTableInterface';
import { TJobAction, TJobCompleteData, TJobCompleteOrNotComplete } from 'components/tables/interfaces/jobTableInterface';
import { DeclineForm } from 'components/widgets/ExternalFormWidgets/Proposal/Components/DeclineProposalForm';
import { TJobInfo } from 'components/widgets/types/jobInfoType';
import useAuth from 'hooks/useAuth';
import useConfig from 'hooks/useConfig';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import EmailSMS from 'pages/customers/CustomerViewTabs/EmailSMS';
import { TCustomerAddEmailSMSPopup } from 'pages/customers/types/type.customersView';
import FollowupPage from 'pages/followup/FollowupPage';
import JobInvoice from 'pages/jobs/Invoices/JobInvoice';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import CustomerServicesInstance from 'services/services.customers';
import EmailSmsServicesInstance from 'services/services.emailSms';
import EstimateServicesInstance from 'services/services.estimates';
import FileUploadServiceInstance from 'services/services.fileUpload';
import JobServicesInstance from 'services/services.jobs';
import MediaServicesInstance from 'services/services.media';
import PunchingServicesInstance, { TClockInData } from 'services/services.punching';
import { TSendProposalData } from 'services/types/types.services';
import { dispatch, useSelector } from 'store';
import { setCurrentSelectedOption } from 'store/reducers/customReducers/slice.estimateOptions';
import { setFollowUpCustomer, setFollowUpJobId } from 'store/reducers/customReducers/slice.followUps';
import { clearJobcompletionStates, setActiveNoteInputId, setJobCompletionPhotos } from 'store/reducers/customReducers/slice.jobs';
import { clearLogs } from 'store/reducers/customReducers/slice.logs';
import { getTabSummary, updateTabSummary } from 'store/reducers/customReducers/slice.tabSummary';
import { IDeletePopup } from 'types/globalTypes/type.deleteDialog';
import { TUniversalDialogProps } from 'types/globalTypes/type.universalDialog';
import ErrorBoundary from 'utils/ErrorHandlers/ErrorBoundary';
import {
  containsIncompleteJob,
  formatCurrency,
  getAddressFromCoordinates,
  getCurrentLocation,
  getGoogleEarthUrl,
  getGoogleMapsDirectionsURL,
  isFinalWorkerCompletingJob,
  isWorkerReassigned,
  snakeCaseToTitleCase
} from 'utils/common';
import { constants, jobStates } from 'utils/constants';
import { AddEstimationForm as AddEstimateOptionsForm } from '../../components/forms/AddEstimationForm';
import CompleteJob from './JobViewTabs/CompleteJob';
import JobBalance from './JobViewTabs/JobBalance';
import JobDetails from './JobViewTabs/JobDetails';
import JobExpense from './JobViewTabs/JobExpense';
import JobLogs from './JobViewTabs/JobLogs';
import NotesContainer from './JobViewTabs/NotesContainer';
import ViewEstimateOptions from './ViewEstimateOptions/ViewEstimateOptions';
import { TActionsButtons, TjobStatesActions } from './types/jobViewPageTypes';

const baseUrl = constants.REACT_APP_BASE_URL;

const availabelTabs = [
  { label: 'Job Info', value: 'job_info' },
  { label: 'Notes', value: 'notes' },
  { label: 'Emails/SMS', value: 'emails_sms' },
  { label: 'Follow Ups', value: 'follow_ups' },
  { label: 'Log', value: 'log' },
  { label: 'Expenses', value: 'expenses' },
  { label: 'Job Balance', value: 'job_balance' },
  { label: 'Media', value: 'media' }
];

export type TStateView = 'worker_info' | 'estimate_info' | null;

const JobsViewPage = () => {
  const { id: jobId, tab: activeTab } = useParams();
  //-------------------------------------useQuery----------------------------------
  const {
    data: jobData,
    refetch: refetchJob,
    isFetching: isJobDataFetching,
    isFetched: isJobFetched
  } = useQuery({
    queryKey: ['job_data', jobId],
    queryFn: () => JobServicesInstance.getSingleJobs(jobId, true),
    enabled: !!jobId?.length,
    networkMode: 'always',
    refetchOnWindowFocus: false
  });

  const { data: customerData } = useQuery({
    queryKey: ['customer_data'],
    queryFn: () => {
      if (jobData?.job.customer?.id) return CustomerServicesInstance.getSingleCustomer(jobData?.job.customer?.id);
    },
    enabled: !!jobData?.job.customer?.id
  });

  //------------------------Constants---------------------
  const theme = useTheme();
  const themeConfig = useConfig();
  const punching = useSelector((state) => state.punching);
  const navigate = useNavigate();
  const { user } = useAuth();
  const location = useLocation();
  const renderState: TStateView = new URLSearchParams(location.search).get('rs') as TStateView;
  const jobsOption = useSelector((state) => state.job),
    isEstimator = user?.role === 'estimator' || (user?.role === 'field_worker' && user.is_fw_estimator);
  const currentSelectedOption = useSelector((state) => state.estimateOptions.currentSelectedOption);
  const [activeJobTab, setActiveJobTab] = useState('job_status');
  const [isLoading, setIsLoading] = useState({ accept_decline_proposal: false, job_complete: false, job_action: false });
  const [selectedJobAction, setSelectedJobAction] = useState<number>(0);

  const { generalSettings: settingsData } = useSelector((state) => state.settings);
  const tabSummaryData = useSelector((state) => state.tabSummaryOption);
  const [jobActionIndex, setJobActionIndex] = useState<number>(-1);
  const [sendProporalLoading, setSendProporalLoading] = useState<boolean>(false);
  const [sendInvoiceLoading, setSendInvoiceLoading] = useState<boolean>(false);
  const [deleteDialog, setDeleteDialog] = useState<IDeletePopup>({ action: { open: false }, data: { subjectToBeDeleted: '' } });

  // ----------------------------------Action Buttons------------------------------
  const jobStateActions: TjobStatesActions[] = [
    {
      action: 'edit_estimate_job',
      status: [jobStates.ESTIMATION, jobStates.NEED_REVIEW, jobStates.ESTIMATION_COMPLETED, jobStates.WORK_ORDER]
    },
    {
      action: 'estimate_completed',
      status: [jobStates.NEED_REVIEW]
    },
    {
      action: 'view_proposal',
      status: [
        jobStates.NEED_REVIEW,
        jobStates.ESTIMATION_COMPLETED,
        jobStates.PROPOSAL_SENT,
        jobStates.PROPOSAL_DECLINED,
        jobStates.PROPOSAL_APPROVED,
        jobStates.WORKER_ASSIGNED,
        jobStates.INVOICE_UNPAID,
        jobStates.INVOICE_PAID
      ]
    },
    {
      action: 'edit_estimate',
      status: [
        jobStates.NEED_REVIEW,
        jobStates.ESTIMATION_COMPLETED,
        jobStates.PROPOSAL_SENT,
        jobStates.PROPOSAL_APPROVED,
        jobStates.WORKER_ASSIGNED,
        jobStates.PROPOSAL_DECLINED,
        jobStates.JOB_COMPLETED,
        jobStates.INVOICE_UNPAID,
        jobStates.INVOICE_SENT
      ]
    },
    {
      action: 'accept_without_sending',
      status: [jobStates.NEED_REVIEW, jobStates.ESTIMATION_COMPLETED]
    },
    {
      action: 'send_proposal',
      status: [
        jobStates.NEED_REVIEW,
        jobStates.ESTIMATION_COMPLETED,
        jobStates.PROPOSAL_APPROVED,
        jobStates.WORKER_ASSIGNED,
        jobStates.INVOICE_UNPAID,
        jobStates.PROPOSAL_DECLINED,
        jobStates.PROPOSAL_SENT,
        jobStates.INVOICE_PAID
      ]
    },
    {
      action: 'ressaign_estimator',
      status: [
        jobStates.ESTIMATION_COMPLETED,
        jobStates.PROPOSAL_SENT,
        jobStates.PROPOSAL_APPROVED,
        jobStates.WORKER_ASSIGNED,
        jobStates.INVOICE_UNPAID,
        jobStates.PROPOSAL_DECLINED
      ]
    },
    {
      action: 'accept_proposal',
      status: [jobStates.PROPOSAL_SENT]
    },
    {
      action: 'decline_proposal',
      status: [jobStates.PROPOSAL_SENT]
    },
    {
      action: 'assign_worker',
      status: [jobStates.PROPOSAL_APPROVED]
    },
    {
      action: 'job_completed',
      status: [jobStates.WORKER_ASSIGNED]
    },
    {
      action: 'job_not_completed',
      status: [jobStates.WORKER_ASSIGNED]
    },
    {
      action: 'edit_job',
      status: [
        jobStates.WORKER_ASSIGNED,
        ...(!!jobData?.job?.previous_states &&
        jobData?.job?.previous_states?.length > 0 &&
        isWorkerReassigned('field_worker', jobData.job.previous_states)
          ? [jobStates.INVOICE_UNPAID]
          : [])
      ]
    },
    {
      action: 'send_invoice',
      status: [jobStates.INVOICE_UNPAID]
    },
    {
      action: 'send_reciept',
      status: [jobStates.INVOICE_PAID]
    },
    {
      action: 'view_invoice',
      status: [jobStates.INVOICE_UNPAID, jobStates.INVOICE_PAID]
    },
    {
      action: 'reassign_worker',
      status: [jobStates.INVOICE_UNPAID, jobStates.INVOICE_PAID]
    },
    {
      action: 'suspend_job',
      status: [...Object.values(jobStates)]
    },
    {
      action: 'delete_job',
      status: [...Object.values(jobStates)]
    }
  ];

  const actionButtons: TActionsButtons[] = useMemo(
    () => [
      {
        key: 'edit_estimate',
        button: {
          title:
            jobData?.job.state && [jobStates.JOB_COMPLETED, jobStates.INVOICE_SENT, jobStates.INVOICE_UNPAID].includes(jobData?.job.state)
              ? 'Edit Invoice'
              : 'Edit Options',
          icon: <AppstoreOutlined />
        }
      },
      { key: 'edit_estimate_job', button: { title: 'Edit Estimate Details', icon: <FormOutlined /> } },
      {
        key: 'edit_job',
        button: {
          title: 'Edit Job',
          icon: <EditOutlined />,
          color: !!jobData?.job?.job_action && containsIncompleteJob(jobData?.job?.job_action) ? 'error' : 'primary'
        }
      },
      { key: 'ressaign_estimator', button: { title: 'Reassign Estimator', icon: <UserSwitchOutlined />, color: 'primary' } },
      { key: 'reassign_worker', button: { title: 'Reassign Worker', icon: <UserSwitchOutlined />, color: 'primary' } },
      { key: 'view_proposal', button: { title: 'View Proposal', icon: <FileSearchOutlined />, color: 'primary' } },
      { key: 'view_invoice', button: { title: 'View Invoice', icon: <ReadOutlined />, color: 'primary' } },
      { key: 'assign_worker', button: { title: 'Assign Worker', icon: <SolutionOutlined />, color: 'primary', variant: 'contained' } },
      {
        key: 'accept_without_sending',
        button: { title: 'Accept Proposal Without Sending', icon: <FileDoneOutlined />, color: 'warning', variant: 'contained' }
      },
      {
        key: 'send_proposal',
        button: { title: 'Send Proposal', icon: <SendOutlined />, color: 'primary', variant: 'contained', disabled: sendProporalLoading }
      },
      {
        key: 'send_invoice',
        button: {
          title: 'Send Invoice',
          icon: <SolutionOutlined />,
          color: 'info',
          variant: 'contained'
        }
      },
      { key: 'send_reciept', button: { title: 'Send Reciept', variant: 'contained', icon: <SolutionOutlined />, color: 'info' } },
      {
        key: 'estimate_completed',
        button: { title: 'Mark Estimate Complete', icon: <CheckCircleOutlined />, variant: 'contained', color: 'success' }
      },
      {
        key: 'decline_proposal',
        button: { title: 'Decline Proposal', icon: <CloseOutlined />, variant: 'contained', color: 'error' }
      },
      {
        key: 'accept_proposal',
        button: { title: 'Accept Proposal', icon: <CheckOutlined />, variant: 'contained', color: 'success' }
      },
      {
        key: 'job_not_completed',
        button: {
          title: 'Cancel Job',
          icon: <CloseOutlined />,
          variant: 'contained',
          color: 'error',
          disabled: jobActionIndex === -1,
          tooltip: jobActionIndex === -1 ? 'You are not assigned to this job' : ''
        }
      },
      {
        key: 'job_completed',
        button: {
          title: 'Complete Job',
          icon: <CheckOutlined />,
          variant: 'contained',
          color: 'success',
          disabled: jobActionIndex === -1,
          tooltip: jobActionIndex === -1 ? 'You are not assigned to this job' : ''
        }
      }
    ],
    [jobActionIndex, sendProporalLoading, jobData?.job.state, jobData?.job.job_action]
  );

  // -------------------------------------States-----------------------------------

  const [currentStateButtons, setCurrentStateButtons] = useState<TActionsButtons[]>();
  const [estimateFormDialogControl, setEstimateFormDialogControl] = useState({
    action: {
      open: false,
      fullScreen: true
    },
    title: ''
  });
  const [estimationOptionsFormDialogControl, setEstimationOptionsFormDialogControl] = useState({
    action: {
      open: false,
      fullScreen: true
    },
    title: ''
  });
  const [assignWorkerFormDialogControl, setAssignWorkerFormDialogControl] = useState({
    action: {
      open: false,
      fullScreen: true
    },
    title: ''
  });
  const [editJobDialogControl, setEditJobDialogControl] = useState<TUniversalDialogProps>({
    action: {
      open: false,
      fullScreen: true,
      fullWidth: false,
      maxWidth: 'xs'
    },
    title: '',
    data: {
      isAddNewAction: false,
      isActionDialogOpen: false,
      isReassignWorker: false
    }
  });
  const [deleteJobAction, setDeleteJobAction] = useState<TUniversalDialogProps>({
    action: {
      open: false,
      fullWidth: true,
      maxWidth: 'xs'
    },
    title: '',
    data: {
      jobActionId: ''
    }
  });
  const [reAssignEstimatorFormDialogControl, setReAssignEstimatorFormDialogControl] = useState({
    action: {
      open: false,
      fullWidth: true,
      maxSize: 'sm'
    },
    title: ''
  });
  const [reAssignWorkerFormDialogControl, setReAssignWorkerFormDialogControl] = useState({
    action: {
      open: false,
      fullScreen: true,
      maxSize: 'sm'
    },
    title: ''
  });
  const [declineProposalFormDialogControl, setDeclineProposalFormDialogControl] = useState({
    action: {
      open: false,
      fullWidth: true,
      maxSize: 'sm'
    },
    title: ''
  });
  const [sendPdfDialog, setSendPdfDialog] = useState({
    action: {
      open: false,
      fullWidth: true,
      maxSize: 'xs'
    },
    data: { type: '', last_sent: '' },
    title: ''
  });
  const [apwosConfDialog, setApwosConfDialog] = useState<boolean>(false);
  const [markEstimateConfDialog, setMarkEstimateConfDialog] = useState(false);
  const [jobCompleteConfDialog, setJobCompleteConfDialog] = useState<boolean>(false);
  const [addEmailOrSmsPopup, setAddEmailOrSmsPopup] = useState<TCustomerAddEmailSMSPopup>({
    action: { open: false, isEmail: true, showJobName: true },
    data: {}
  });
  const [userCoordinates, setUserCoordinates] = useState<{ lat: string | number; lng: string | number }>({ lat: '', lng: '' });

  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [mobileAddMediaAnchorEl, setMobileAddMediaAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [showTabs, setShowTabs] = useState(true);
  const [viewStates, setViewStates] = useState({ estimateView: false, workOrderView: false, jobView: true, invoiceView: false });

  const [confirmationAlert, setConfirmationAlert] = useState(false);

  //---------------------------useEffect-----------------------------

  useEffect(() => {
    if (location.state) {
      setShowTabs(!(location.state?.estimationView || location.state?.workOrderView || location.state?.invoiceView));
      setViewStates(() => ({
        estimateView: location.state?.estimationView ?? false,
        workOrderView: location.state?.workOrderView ?? false,
        jobView: location.state?.jobView ?? false,
        invoiceView: location.state?.invoiceView ?? false
      }));
    }
  }, [location.state]);

  useEffect(() => {
    const navTimeout = setTimeout(function () {
      if (navigator.geolocation) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function (position) {
            setUserCoordinates({ lat: position.coords.latitude, lng: position.coords.longitude });
          });
        }
      } else {
        console.log('No Geolocation Support.');
      }
    }, 1000);

    return () => {
      clearTimeout(navTimeout);
    };
  }, []);

  useEffect(() => {
    activeTab ? setActiveJobTab(activeTab) : setActiveJobTab(availabelTabs[0].value);
  }, [activeTab]);

  const [disableMobileJobCompleteBtn, setDisableMobileJobCompleteBtn] = useState<string | null>('You might have missed something.');

  useEffect(() => {
    if (jobData) {
      if (jobData?.job?.job_action?.[jobData?.job?.job_action.length - 1]?.field_worker?.id !== user?._id) {
        setDisableMobileJobCompleteBtn('You are not assigned to this job.');
      }
      const currentJobIndex =
        jobData?.job?.job_action?.findIndex(
          (jobAction) => jobAction?.field_worker?.id === user?._id && jobAction.is_job_complete !== true
        ) ?? -1;
      setJobActionIndex(currentJobIndex);
      const getActionButtons = () => {
        if (jobData.job.customer_sign_on_estimation === 'required' && jobData.job.draft_estimations !== null) {
          const actionButtonsToRendered: TActionsButtons[] = [
            { key: 'resend_signature_edit_option', button: { title: 'Resend Signature Request', icon: <SendOutlined /> } },
            {
              key: 'accept_proposal_edit_option',
              button: { title: 'Accept Proposal', icon: <CheckOutlined />, variant: 'contained', color: 'success' }
            },
            {
              key: 'decline_proposal_edit_option',
              button: { title: 'Decline Proposal', icon: <CloseOutlined />, variant: 'contained', color: 'error' }
            }
          ];
          return actionButtonsToRendered;
        } else {
          const currentStatusActions = jobStateActions.filter((eachState) => eachState.status.includes(jobData?.job?.state as string));

          let actionButtonsToRendered = actionButtons
            .filter((eachButton) =>
              currentStatusActions.find((eachState) => {
                const previousLength = jobData.job.previous_states?.length;
                if (eachState.action === eachButton.key) {
                  if (
                    (eachState.action === 'estimate_completed' || eachState.action === 'edit_estimate_job') &&
                    previousLength &&
                    jobData.job?.previous_states?.[previousLength - 1] === jobStates.ESTIMATION_COMPLETED
                  )
                    return false;
                  if (eachState.action === 'accept_without_sending' && !!user?.role && ['estimator', 'field_worker'].includes(user?.role))
                    return false;

                  if (
                    ['job_completed', 'job_not_completed'].includes(eachState.action) &&
                    jobData?.job?.job_action?.[0]?.is_multi_action_job_required_additional_field_workers === true &&
                    jobData?.job?.job_action?.[currentJobIndex]?.is_job_complete !== null
                  )
                    return false;
                }

                return eachState.action === eachButton.key;
              })
            )
            .map((singleButtonData) => {
              const previousLength = jobData.job.previous_states?.length;
              if (singleButtonData.key === 'ressaign_estimator') {
                return {
                  ...singleButtonData,
                  button: {
                    ...singleButtonData.button,
                    disabled:
                      previousLength && jobData.job?.previous_states?.[previousLength - 1] === jobStates.REASSIGNED_ESTIMATOR ? true : false
                  }
                };
              } else if (singleButtonData.key === 'reassign_worker') {
                return {
                  ...singleButtonData,
                  button: {
                    ...singleButtonData.button,
                    disabled:
                      previousLength && jobData.job?.previous_states?.[previousLength - 1] === jobStates.REASSIGNED_WORKER ? true : false
                  }
                };
              } else if (['job_completed'].includes(singleButtonData.key)) {
                const currentJobAction = jobData?.job?.job_action?.[jobActionIndex] as TJobAction;
                const isDisabled =
                  jobActionIndex === -1 ||
                  (currentJobAction?.customer_sign_on_job === 'required' &&
                    jobsOption.is_customer_present &&
                    !jobsOption.customer_sign_for_job_completed.length) ||
                  (currentJobAction?.completion_photos_required === 'required' && jobsOption.job_completion_photos.length === 0) ||
                  (currentJobAction?.record_materials_used === 'required' &&
                    jobsOption.materials_used.some((singleMaterialUsed) => singleMaterialUsed.units === null));

                setDisableMobileJobCompleteBtn(isDisabled ? 'You might have missed something.' : null);

                return {
                  ...singleButtonData,
                  button: {
                    ...singleButtonData.button,
                    tooltip: 'You are not assigned to this job',
                    disabled: isDisabled
                  }
                };
              }
              return singleButtonData;
            });

          if (jobData.job.previous_states?.length) {
            const previousLength = jobData.job.previous_states?.length;
            if (jobData.job?.previous_states?.[previousLength - 1] === jobStates.ESTIMATION_COMPLETED) {
              if (!actionButtonsToRendered.filter((eachButton) => eachButton.key === 'ressaign_estimator').length) {
                const extraButtonsToRendered = actionButtons.filter((eachButton) => eachButton.key === 'ressaign_estimator');
                actionButtonsToRendered = [...extraButtonsToRendered, ...actionButtonsToRendered];
              }
            } else if (jobData.job.previous_states?.[previousLength - 1] === jobStates.REASSIGNED_WORKER) {
              const extraButtonsToRendered = actionButtons.filter((eachButton) => eachButton.key === 'job_completed');
              actionButtonsToRendered = [...actionButtonsToRendered, ...extraButtonsToRendered];
            } else if (jobData.job?.previous_states?.[previousLength - 1] === jobStates.REASSIGNED_ESTIMATOR) {
              const extraButtonsToRendered = actionButtons.filter(
                (eachButton) => eachButton.key === 'edit_estimate' || eachButton.key === 'estimate_completed'
              );
              if (user?.role === 'estimator') {
                actionButtonsToRendered = extraButtonsToRendered;
              } else {
                actionButtonsToRendered = [
                  ...extraButtonsToRendered,
                  ...actionButtons.filter((eachButton) => eachButton.key === 'edit_estimate_job')
                ];
              }
            }
          }
          return actionButtonsToRendered.filter((eachButton) => {
            if (user?.role === 'estimator' && eachButton.key === 'edit_estimate_job') {
              return false;
            }
            if (user?.role === 'field_worker' && (eachButton.key === 'ressaign_estimator' || eachButton.key === 'reassign_worker'))
              return false;
            return true;
          });
        }
      };
      if (jobData?.job.state === jobStates.NEED_REVIEW) dispatch(setCurrentSelectedOption(jobData.job.estimations?.options[0]?.id));

      setCurrentStateButtons(() => getActionButtons());
    }
    return () => {
      dispatch(setFollowUpJobId(null));
      dispatch(setFollowUpCustomer(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobData, jobsOption, jobActionIndex]);

  useEffect(() => {
    !!jobId && dispatch(getTabSummary({ module: 'job', id: jobId }));

    return () => {
      dispatch(updateTabSummary({}));
      dispatch(setActiveNoteInputId(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //-----------------------------Handlers--------------------
  const handleJobTabChange = (event: React.SyntheticEvent, newValue: string) => {
    !!jobData?.job._id && dispatch(getTabSummary({ module: 'job', id: jobData?.job._id }));
    setActiveJobTab(newValue);
    navigate(`/jobs/view/${jobId}/${newValue}`);
  };

  const handleEditEstimationOption = (state: boolean) => {
    dispatch(clearLogs());
    setEstimationOptionsFormDialogControl({
      action: {
        open: state,
        fullScreen: true
      },
      title: 'Edit Estimate'
    });
  };
  const handleEditEstimate = (state: boolean) => {
    setEstimateFormDialogControl({
      action: {
        open: state,
        fullScreen: true
      },
      title: 'Edit Estimate'
    });
  };
  const handleViewProposal = () => {
    localStorage.setItem('prevPage', location.pathname);
    navigate(`/proposal/view?job_id=${jobId}`);
  };

  const handleSendProposal = async () => {
    if (jobData?.job._id && !!jobData?.job.customer?.emails?.length) {
      const sendProposalData: TSendProposalData = {
        to: jobData?.job.customer?.emails,
        proposal_link: `${baseUrl}/proposal/view?job_id=${jobData?.job._id}`
      };
      setSendProporalLoading(true);
      try {
        await EstimateServicesInstance.sendProposal(sendProposalData, jobData?.job._id);
      } finally {
        if (user?.role === 'estimator') navigate('/jobs');
        else refetchJob();
        setSendProporalLoading(false);
        return true;
      }
    }
  };

  const handleAcceptProposalWithoutSendingConfirmation = () => {
    setApwosConfDialog(true);
  };

  const handleAcceptProposal = handleAcceptProposalWithoutSendingConfirmation;

  const handleAcceptProposalWithoutSending = async (proposalApproveSignature?: string) => {
    setIsLoading({ ...isLoading, accept_decline_proposal: true });
    if (jobId && jobData?.job.estimations) {
      const stateUpdatedJobData: TEstimateOptions[] = jobData?.job.estimations.options.map((eachOption) => {
        eachOption.items.forEach((eachItem) => delete eachItem.record_materials_used);

        if (eachOption.id === currentSelectedOption) {
          return { ...eachOption, approved: true };
        }

        return eachOption;
      });

      await EstimateServicesInstance.upsertEstimateOptions(
        jobId,
        stateUpdatedJobData,
        jobData.job.estimations.terms_and_conditions,
        jobStates.PROPOSAL_APPROVED,
        undefined,
        proposalApproveSignature
      )
        .then((response) => {
          if (!!response) {
            refetchJob();
            setApwosConfDialog(false);
          }
        })
        .finally(() => {
          if (user?.role === 'estimator') navigate('/jobs');
        });
    }
    setIsLoading({ ...isLoading, accept_decline_proposal: false });
  };

  const getOptionNamebyId = (selectedOptionId: string | null) => {
    return selectedOptionId && jobData?.job.estimations?.options.find((eachOption) => eachOption.id === selectedOptionId)?.option_name;
  };

  const handleAssignWorker = (state: boolean) => {
    setAssignWorkerFormDialogControl({
      action: {
        open: state,
        fullScreen: true
      },
      title: 'Assign Worker'
    });
  };
  const handleReAssignEstimator = (state: boolean) => {
    setReAssignEstimatorFormDialogControl((prev) => ({
      action: {
        ...prev.action,
        open: state
      },
      title: 'Reassign Estimator'
    }));
  };

  const [jobNotCompleteReason, setJobNotCompleteReason] = useState<{
    state: boolean;
    title?: string;
    job_action_id: string;
    reason: string | null;
    isSuspend?: boolean;
  }>({
    state: false,
    title: '',
    job_action_id: '',
    reason: null
  });

  useEffect(() => {
    setJobNotCompleteReason((prev) => ({ ...prev, reason: jobsOption.worker_to_office_note ?? '' }));
  }, [jobsOption.worker_to_office_note]);

  const handleJobNotCompleteReasonChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setJobNotCompleteReason((prev) => ({ ...prev, reason: event.target.value }));
    return null;
  };

  const handleJobNotComplete = async (isSuspend: boolean) => {
    setIsLoading({ ...isLoading, job_complete: true });
    if (isSuspend && jobId) {
      await JobServicesInstance.suspendJob(jobId, jobNotCompleteReason.reason as string);
      setJobNotCompleteReason({ state: false, job_action_id: '', reason: null });
      refetchJob();
      setIsLoading({ ...isLoading, job_complete: false });
      return;
    }

    if (jobNotCompleteReason.reason?.length && jobId) {
      const response = await JobServicesInstance.markJobAsNotCompleted(jobId, jobNotCompleteReason.job_action_id, {
        job_not_completed_reason: jobNotCompleteReason.reason
      });

      if (response) {
        await JobServicesInstance.suspendJob(jobId, jobNotCompleteReason.reason);
        setJobNotCompleteReason({ state: false, job_action_id: '', reason: null });
        if (user?.role === 'field_worker') navigate('/jobs');
        refetchJob();
      }
    }
    setIsLoading({ ...isLoading, job_complete: false });
  };

  const handleJobComplete = () => {
    if (!!jobData && !!jobData.job) {
      const currentJobAction = jobData?.job?.job_action?.[jobActionIndex] as TJobAction;
      setIsLoading({ ...isLoading, job_complete: true });
      let stateUpdatedJobData: TJobCompleteOrNotComplete = {
        invoice_link: `${constants.REACT_APP_BASE_URL}/invoice/view?job_id=${jobId}`
      };
      if (!!currentJobAction?.customer_sign_on_job?.length && currentJobAction?.customer_sign_on_job !== 'no') {
        stateUpdatedJobData['is_customer_present'] = jobsOption?.is_customer_present;
        if (jobsOption?.is_customer_present)
          stateUpdatedJobData['customer_sign_for_job_completed'] = jobsOption?.customer_sign_for_job_completed;
      }
      if (!!currentJobAction?.completion_photos_required?.length && currentJobAction?.completion_photos_required !== 'no')
        stateUpdatedJobData['job_completion_photos'] = [
          ...(stateUpdatedJobData?.job_completion_photos ?? []),
          ...jobsOption?.job_completion_photos
        ];

      if (currentJobAction?.record_materials_used?.length && currentJobAction?.record_materials_used !== 'no') {
        const foundMaterials = jobsOption?.materials_used?.filter((singleMaterialUsed) => singleMaterialUsed?.units !== null);
        if (foundMaterials?.length > 0) {
          stateUpdatedJobData['materials_used'] = foundMaterials
            .map((material) => {
              return {
                id: material.id,
                description: material.description,
                units: material.units
              };
            })
            .filter((each) => each.units && parseInt(each.units) > 0);
        } else {
          stateUpdatedJobData.materials_used = [];
        }
      }
      if (
        isFinalWorkerCompletingJob(jobData?.job, currentJobAction?.id) &&
        settingsData?.assign_worker_page?.automatically_send_invoice.value === 'yes'
      ) {
        stateUpdatedJobData['last_invoice_sent'] = new Date().toDateString();
      }

      stateUpdatedJobData['worker_to_office_note'] = jobsOption?.worker_to_office_note;

      if (jobId) {
        JobServicesInstance.markJobAsCompleted(jobId, currentJobAction?.id, stateUpdatedJobData)
          .then(async (response) => {
            if (response && settingsData?.assign_worker_page?.automatically_send_invoice.value === 'yes') {
              handleSendInvoice();
            }
            refetchJob();
          })
          .finally(() => {
            dispatch(clearJobcompletionStates());
            localStorage.removeItem('job_completion_data');
            if (user?.role === 'field_worker') navigate('/jobs');
            setJobCompleteConfDialog(false);
            setIsLoading({ ...isLoading, job_complete: false });

            // --- mark job ended if punched
            markJobEnded();
          });
      }
    }
  };

  async function markJobEnded() {
    const todaysPunch = punching.punchingDetails?.todaysPunch;

    if (
      !!todaysPunch?.jobs?.length &&
      todaysPunch?.jobs[todaysPunch?.jobs.length - 1]?.id === jobId &&
      !todaysPunch?.jobs[todaysPunch?.jobs.length - 1]?.end
    ) {
      let location, address;
      if (navigator.onLine) {
        location = await getCurrentLocation();
        address = await getAddressFromCoordinates(Number(location.lat), Number(location.long));
      } else {
        location = { lat: '', long: '' };
        address = '';
      }
      const jobPunchData = {
        id: jobId as string,
        name: jobData?.job.job_name as string,
        time: new Date().toISOString(),
        location: location,
        address
      };
      PunchingServicesInstance.punchJobAction(todaysPunch._id, 'end', jobPunchData);
    }
  }

  const handleSendInvoice = async () => {
    if (!!jobData?.job) {
      setSendInvoiceLoading(true);
      const approvedOptionIndex = jobData?.job.estimations?.options.findIndex((singleOption) => singleOption.approved);
      if (approvedOptionIndex !== undefined && approvedOptionIndex !== -1) {
        const selectedOption = jobData.job.estimations?.options[approvedOptionIndex];
        const totalPaidAmount =
          jobData?.job?.payments?.reduce((accumulator, currentValue) => {
            return accumulator + (currentValue?.amount ?? 0);
          }, 0) || 0;
        if (selectedOption) {
          await JobServicesInstance.sendInvoice(
            {
              to: jobData.job.customer?.emails as string[],
              is_payment_added: false,
              invoice_link: `${constants.REACT_APP_BASE_URL}/invoice/view?job_id=${jobId}`,
              total_amount: (selectedOption?.sub_total + (jobData.job.is_this_job_tax_exempt ? 0 : selectedOption?.tax || 0)).toString(),
              total_amount_paid: totalPaidAmount.toString(),
              balance_due: (
                selectedOption?.sub_total +
                (jobData.job.is_this_job_tax_exempt ? 0 : selectedOption?.tax || 0) -
                totalPaidAmount
              ).toString()
            },
            jobData?.job?._id as string
          );
        }
      }
      setSendInvoiceLoading(false);
      return true;
    }
  };

  const handleActionButtonClick = (buttonType: string) => {
    switch (buttonType) {
      case 'edit_estimate':
        handleEditEstimationOption(true);
        break;
      case 'estimate_completed':
        handleEstimateCompleted();
        break;
      case 'edit_estimate_job':
        handleEditEstimate(true);
        break;
      case 'view_proposal':
        handleViewProposal();
        break;
      case 'send_proposal':
        setSendPdfDialog((prevData) => {
          return {
            action: { ...prevData.action, open: true },
            data: { type: 'proposal', last_sent: jobData?.job?.last_proposal_sent ?? '' },
            title: 'Send Proposal'
          };
        });

        break;
      case 'accept_proposal':
        handleAcceptProposal();
        break;
      case 'decline_proposal':
        handleDeclineProposal(true);
        break;
      case 'accept_without_sending':
        handleAcceptProposalWithoutSendingConfirmation();
        break;
      case 'assign_worker':
        handleAssignWorker(true);
        break;
      case 'ressaign_estimator':
        handleReAssignEstimator(true);
        break;
      case 'reassign_worker':
        handleReAssignWorker(true);
        break;
      case 'edit_job':
        handleEditJob(
          true,
          (!!jobData?.job?.previous_states && isWorkerReassigned('field_worker', jobData?.job?.previous_states)) ?? false,
          false,
          (jobData?.job?.job_action ?? [])[0]?.is_multi_action_job_required_additional_field_workers ?? false
        ); //the open:true as we are opening a dialog{the content will be decide later}, isAddNewAction:false when a user click's on edit job it will either open a job selection popup or assign worker form,isOpenPopup:will be decide if it is a multi-action job
        break;
      case 'suspend_job':
        {
          const jobActionId =
            jobData?.job?.job_action?.findIndex(
              (eachJobAction) => eachJobAction?.field_worker?.id === user?._id && eachJobAction?.is_job_complete === null
            ) ?? -1;
          setJobNotCompleteReason({
            state: true,
            title: 'Suspend Job',
            isSuspend: true,
            job_action_id: jobData?.job?.job_action?.[jobActionId]?.id as string,
            reason: null
          });
        }
        break;
      case 'delete_job': {
        deleteJobClick(true);
        break;
      }
      case 'job_not_completed':
        {
          const jobActionId =
            jobData?.job?.job_action?.findIndex(
              (eachJobAction) => eachJobAction?.field_worker?.id === user?._id && eachJobAction?.is_job_complete === null
            ) ?? -1;
          setJobNotCompleteReason({ state: true, job_action_id: jobData?.job?.job_action?.[jobActionId]?.id as string, reason: null });
        }
        break;
      case 'job_completed':
        setJobCompleteConfDialog(true);
        break;
      case 'view_invoice':
        handleViewInvoice();
        break;
      case 'send_invoice':
      case 'send_reciept':
        setSendPdfDialog((prevData) => {
          return {
            action: { ...prevData.action, open: true },
            data: { type: 'invoice', last_sent: jobData?.job.last_invoice_sent?.toString() ?? '' },
            title: 'Send Invoice'
          };
        });

        break;
      case 'resend_signature_edit_option':
        handleResendSignature();
        break;
      case 'accept_proposal_edit_option':
        editOptionAcceptProposal();
        break;
      case 'decline_proposal_edit_option':
        editOptionDeclineProposal();
        break;
    }
  };

  const handleResendSignature = async () => {
    if (!!jobData?.job._id?.length) {
      const response = await JobServicesInstance.resendSignature(jobData?.job?._id);
      if (response) refetchJob();
    }
  };
  const handleEstimateCompleted = async () => {
    if (user?.role === 'estimator') {
      setMarkEstimateConfDialog(true);
    } else {
      handleEstimateCompletedConfirmation();
    }
  };

  const handleEstimateCompletedConfirmation = async () => {
    if (!jobData?.job._id) return;
    await JobServicesInstance.markEstimateCompleted(jobData.job._id);
    if (!!user?.role && ['estimator', 'field_worker'].includes(user?.role)) {
      navigate('/jobs');
      return;
    }
    refetchJob();
  };

  const editOptionDeclineProposal = async () => {
    if (!!jobData?.company._id?.length && !!jobData?.job?._id?.length) {
      const response = await EstimateServicesInstance.authDeclineEstimateOption(jobData?.company._id, jobData?.job._id);
      if (response) refetchJob();
    }
  };

  const editOptionAcceptProposal = async () => {
    if (!!jobData?.job?._id?.length) {
      const response = await EstimateServicesInstance.authAcceptEstimateOption(jobData?.job._id);
      if (response) refetchJob();
    }
  };

  const handleEditJob = (state: boolean, isReassignWorker: boolean, isAddNewAction: boolean, isActionDialogOpen: boolean) => {
    const hasValidJob = !!jobData?.job;
    if (!isReassignWorker && state && isActionDialogOpen && hasValidJob) {
      setSelectedJobAction(() => {
        const actions = jobData?.job.job_action ?? [];
        return actions.findIndex((action) => action.is_job_complete !== true);
      });
    }

    let fullScreen = !isActionDialogOpen;
    let fullWidth = false;
    let maxWidth: 'sm' | 'xs' = 'xs';

    if (isReassignWorker || isActionDialogOpen) {
      fullWidth = true;
    }
    if (isReassignWorker) {
      maxWidth = 'sm';
    }

    let dialogTitle = 'Edit Job';
    if (isReassignWorker) {
      dialogTitle = 'Reassign Worker';
    } else if (isAddNewAction) {
      dialogTitle = 'Add Job Action';
    } else if (isActionDialogOpen) {
      dialogTitle = 'Multi Action Job';
    }

    setEditJobDialogControl(() => ({
      action: {
        fullScreen,
        fullWidth,
        maxWidth,
        open: state
      },
      title: dialogTitle,
      data: {
        isAddNewAction,
        isActionDialogOpen,
        isReassignWorker
      }
    }));
  };

  const handleViewInvoice = () => {
    navigate(`/invoice/view?job_id=${jobData?.job._id}`);
  };

  const handleReAssignWorker = (state: boolean) => {
    setReAssignWorkerFormDialogControl((prev) => ({
      action: {
        ...prev.action,
        open: state
      },
      title: 'Reassign Worker'
    }));
  };

  const handleEditEstimationOptionSuccess = () => {
    handleEditEstimationOption(false);
    refetchJob();
  };
  const handleEditEstimateSuccess = () => {
    handleEditEstimate(false);
    refetchJob();
  };

  const handleAssignWorkerSuccess = async (isAddNewAction: boolean) => {
    await refetchJob();
    setSelectedJobAction(0);
    handleAssignWorker(false);
    handleEditJob(isAddNewAction, false, isAddNewAction, false);
  };

  const handleEditJobSuccess = async (isAddNewAction: boolean) => {
    await refetchJob();
    setSelectedJobAction(0);
    handleEditJob(isAddNewAction, false, isAddNewAction, false);
    refetchJob(); //open:isAddNewAction as the add new action pop will be oprn or close will be decided by whether we are adding a new action , last one is false as we are calling this success methode when we are submitting assign worker form we will mot open multi-action job selection dialog
  };

  const handleReAssignEstimatorSuccess = () => {
    handleReAssignEstimator(false);
    refetchJob();
  };

  const handleReaAssignWorkerSuccess = () => {
    handleReAssignWorker(false);
    refetchJob();
  };

  const handleDeclineProposal = async (state: boolean) => {
    setDeclineProposalFormDialogControl((prev) => ({
      action: {
        ...prev.action,
        open: state
      },
      title: 'Decline Proposal'
    }));
  };

  const handleDeclineProposalSuccess = () => {
    handleDeclineProposal(false);
    refetchJob();
  };

  const handleUnsuspendJob = async (jobId: string) => {
    await JobServicesInstance.unsuspendJob(jobId);
    refetchJob();
  };

  const handleSelectSingleMultiActionJob = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedJobAction(() => Number(event.target.value));
  };

  const handleDeleteJobActionDialog = (open: boolean, jobActionId?: string) => {
    handleEditJob(false, false, false, false);
    setDeleteJobAction((prev) => {
      return {
        action: { ...prev.action, open },
        title: 'Delete Action',
        data: { jobActionId: jobActionId ?? '' }
      };
    });
  };

  const handleDeleteJobAction = async () => {
    if (!!jobData?.job?._id) {
      setIsLoading((prev) => ({ ...prev, job_action: true }));
      await JobServicesInstance.deleteJobAction(jobData?.job?._id, deleteJobAction.data.jobActionId);
      setIsLoading((prev) => ({ ...prev, job_action: false }));
      await refetchJob();
      handleDeleteJobActionDialog(false);
    }
  };

  //-----------------------------Renderes---------------------------

  const renderViewPageContent = (state?: string) => {
    switch (state) {
      case jobStates.ESTIMATION:
      case jobStates.WORK_ORDER: {
        if (jobData?.job.estimator?.id === user?._id) {
          dispatch(clearLogs());
          return (
            jobId && (
              <>
                {jobData?.job?.job_action?.[jobActionIndex]?.worker_commission !== undefined && (
                  <Typography className="p-4 font-semibold">
                    Est. Commission {`(${jobData.job.estimator?.name}): `}
                    &#36; {jobData?.job.estimator_commission}
                  </Typography>
                )}

                <AddEstimateOptionsForm
                  isAppointmentJob={jobData?.job?.is_appointment_job ?? false}
                  jobName={jobData?.job.job_name}
                  isEditMode={estimationOptionsFormDialogControl.action.open}
                  existingData={jobData?.job.estimations}
                  defaultTaxRate={jobData?.job.customer?.default_tax_rate as number}
                  previousStates={jobData?.job.previous_states}
                  jobId={jobId}
                  currentState={jobData?.job.state}
                  onSuccess={handleEditEstimationOptionSuccess}
                  customerData={customerData}
                />
              </>
            )
          );
        } else {
          return (
            <div className="w-full p-8">
              <Alert severity="error">
                You are not assign to this job. If you want to complete this job first assign this job to your self.{' '}
              </Alert>
            </div>
          );
        }
      }
      case jobStates.PROPOSAL_APPROVED:
      case jobStates.NEED_REVIEW:
      case jobStates.PROPOSAL_SENT:
      case jobStates.PROPOSAL_DECLINED:
        return <ViewEstimateOptions existingJobData={cloneDeep(jobData)} />;
      case jobStates.WORKER_ASSIGNED: {
        const jobActionIndex =
          jobData?.job?.job_action?.findIndex(
            (jobAction) => jobAction?.field_worker?.id === user?._id && jobAction.is_job_complete !== true
          ) ?? -1;
        return (
          <CompleteJob
            jobData={jobData}
            jobActionIndex={jobActionIndex}
            currentStateButtons={currentStateButtons}
            handleActionButtonClick={handleActionButtonClick}
            handleUnsuspendJob={handleUnsuspendJob}
          />
        );
      }
      case jobStates.JOB_COMPLETED:
      case jobStates.INVOICE_UNPAID:
      case jobStates.INVOICE_PAID:
        return isJobFetched && !!jobData && <JobInvoice jobData={jobData} refetchJob={refetchJob} />;
      case 'estimate_info':
      case 'worker_info':
        return <ViewEstimateOptions stateView={state} existingJobData={cloneDeep(jobData)} />;
      default:
        return <></>;
    }
  };

  const renderTab = () => {
    if (jobData) {
      dispatch(setFollowUpJobId(jobData.job._id));
      dispatch(setFollowUpCustomer({ id: jobData.job.customer?.id, name: jobData.job.customer?.name }));
    }
    switch (activeJobTab) {
      case 'notes':
        return (
          <ErrorBoundary>
            <NotesContainer jobData={jobData?.job} refetchJob={refetchJob} />
          </ErrorBoundary>
        );

      case 'follow_ups': {
        return (
          !!jobData && (
            <ErrorBoundary>
              <FollowupPage customerData={jobData?.job.customer} jobData={jobData?.job} />
            </ErrorBoundary>
          )
        );
      }
      case 'log':
        return (
          <ErrorBoundary>
            <JobLogs refetchJob={refetchJob} job={jobData?.job} customer={jobData?.job?.customer} />
          </ErrorBoundary>
        );
      case 'expenses':
        return (
          <ErrorBoundary>
            <JobExpense data={{ id: jobData?.job._id as string, name: jobData?.job.job_name as string }} isGlobalExpense={true} />
          </ErrorBoundary>
        );
      case 'job_balance':
        return (
          <ErrorBoundary>
            <JobBalance refetchJob={refetchJob} job={jobData?.job} />
          </ErrorBoundary>
        );
      case 'emails_sms':
        return (
          <ErrorBoundary>
            <EmailSMS
              customer={{
                _id: jobData?.job?.customer?.id,
                first_name: jobData?.job?.customer?.name.split(' ')[0],
                last_name: jobData?.job?.customer?.name.split(' ')[1],
                emails: jobData?.job.customer?.emails,
                phones: jobData?.job.customer?.phones
              }}
              jobData={jobData?.job}
              fromModule="job"
            />
          </ErrorBoundary>
        );
      case 'media':
        return (
          <ErrorBoundary>
            <Media module="jobs" customer_id={jobData?.job.customer?.id} />
          </ErrorBoundary>
        );
      case 'job_status': {
        let tabToRender = jobData?.job?.state;

        if (renderState && renderState.length) {
          tabToRender = renderState;
        }

        return <ErrorBoundary>{renderViewPageContent(tabToRender)}</ErrorBoundary>;
      }
      case 'job_info':
      default:
        return (
          <ErrorBoundary>
            <JobDetails jobData={jobData?.job} />
          </ErrorBoundary>
        );
    }
  };

  const handleAddEmailorSmsPopup = () => {
    setAddEmailOrSmsPopup({
      action: { open: false, isEmail: true, showJobName: true },
      data: {}
    });
  };

  const handleSms = async () => {
    setAddEmailOrSmsPopup({
      action: { open: true, isEmail: false, showJobName: true },
      data: {
        id: customerData?._id,
        name: `${customerData?.first_name} ${customerData?.last_name}`,
        phones: [{ phone_country_code: customerData?.phone1_country_code as string, phone: customerData?.phone1 as string }]
      }
    });
  };

  const handleMail = async () => {
    setAddEmailOrSmsPopup({
      action: { open: true, isEmail: true, showJobName: true },
      data: {
        id: customerData?._id,
        name: `${customerData?.first_name} ${customerData?.last_name}`,
        emails: customerData?.emails
      }
    });
  };

  const handleInfo = async () => {
    navigate(`/customers/view/${customerData?._id}/contact`);
  };

  const handleCall = async () => {
    const phoneNumber = `+${customerData?.phone1_country_code}${customerData?.phone1}`;
    window.location.href = `tel:${phoneNumber}`;
  };

  async function handleGetDirection() {
    const address = `${jobData?.job.address?.address1} ${jobData?.job.address?.address2}, ${jobData?.job.address?.city}, ${jobData?.job.address?.state} ${jobData?.job.address?.zip}, ${jobData?.job.address?.country}`;
    if (!!userCoordinates.lat && !!userCoordinates.lng) {
      const directionUrl = getGoogleMapsDirectionsURL(userCoordinates.lat, userCoordinates.lng, address);
      window.open(directionUrl, '_blank')?.focus();
    }
  }

  const handleGoogleEarthSearch = async () => {
    const address = `${jobData?.job.address?.address1} ${jobData?.job.address?.address2}, ${jobData?.job.address?.city}, ${jobData?.job.address?.state} ${jobData?.job.address?.zip}, ${jobData?.job.address?.country}`;
    const googleEarthUrl = getGoogleEarthUrl(address);
    window.open(googleEarthUrl, '_blank')?.focus();
  };

  const handleMapViewSearch = () => {
    const address = `${customerData?.address.address1} ${customerData?.address?.address2}, ${customerData?.address?.city}, ${customerData?.address?.state} ${customerData?.address?.zip}, ${customerData?.address?.country}`;
    const encodedAddress = encodeURIComponent(address);
    const mapsSearchURL = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
    window.open(mapsSearchURL, '_blank');
  };

  const handleStreeView = () => {
    const address = `${customerData?.address.address1} ${customerData?.address?.address2}, ${customerData?.address?.city}, ${customerData?.address?.state} ${customerData?.address?.zip}, ${customerData?.address?.country}`;

    const apiKey = constants.REACT_APP_GOOGLE_MAPS_API_KEY;

    const geoCodeurl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;
    fetch(geoCodeurl)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'OK' && data.results.length > 0) {
          const result = data.results[0];
          const { lat, lng } = result.geometry.location;
          const streetViewURL = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat},${lng}`;
          window.open(streetViewURL, '_blank');
        } else {
          console.error('Geocoding API Error:', data.status, data.error_message);
        }
      })
      .catch((error) => console.error('Fetch Error:', error));
  };

  const addMedia = async (url: string[]) => {
    let customerId;

    customerId = customerData?._id as string;

    const response = await MediaServicesInstance.addMedia(url, 'jobs', customerId, jobId, activeTab);
    if (response) {
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const FilesData = Object.values(event.target.files);
      let urls: string[] = [];
      await Promise.all(
        FilesData.map(async (eachFile) => {
          const response = await FileUploadServiceInstance.uploadFile(eachFile, undefined, false);
          if (response && response.data) {
            urls = [...urls, response.data];
          }
        })
      );
      await addMedia(urls);
    }
  };

  async function handleJobCompletionPhotos(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files?.length) {
      const FilesData = Object.values(event.target.files);
      let newImagesData: string[] = jobsOption.job_completion_photos;

      await Promise.all(
        FilesData.map(async (eachFile) => {
          const response = await FileUploadServiceInstance.uploadFile(eachFile, undefined, false);
          if (response && response.data) newImagesData = [...newImagesData, response.data];
        })
      );

      dispatch(setJobCompletionPhotos(newImagesData));
    }
  }

  function handleFocusToNote() {
    const input = document.getElementById(jobsOption.active_note_input_id);
    if (input) {
      input.focus();
      input.click();
      input.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  function onAddMediaClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (jobsOption.add_media_menu_list.length > 0) {
      setMobileAddMediaAnchorEl(event.currentTarget);
    } else {
      let inputId = 'job-add-media-input';
      if (jobsOption.add_job_media_id) inputId = jobsOption.add_job_media_id;
      const input = document.getElementById(inputId);
      if (input) {
        input.click();
      }
    }
  }

  function renderMobileAddMediaButtons() {
    return jobsOption.add_media_menu_list.map((item, index) => {
      switch (item) {
        case 'job_completion_photos':
          return (
            <>
              <input
                style={{ display: 'none' }}
                id="job-completion-media-input"
                type="file"
                multiple
                onChange={handleJobCompletionPhotos}
              />
              <Button
                classes={{
                  root: 'rounded-none text-lg !py-4 flex items-center justify-start'
                }}
                variant="contained"
                key={index}
                startIcon={<SafetyOutlined />}
                color="success"
                component="label"
                htmlFor="job-completion-media-input"
              >
                Add Job Completion Photos
              </Button>
            </>
          );

        default:
          return <Button key={index}>Add Media</Button>;
      }
    });
  }

  function renderJobHeading() {
    if (viewStates.estimateView) {
      return 'Estimate';
    } else if (viewStates.workOrderView) {
      return 'Work Order';
    } else if (viewStates.invoiceView) {
      return 'Invoice';
    }
    return 'Job View';
  }

  function handleJobCompleteClick() {
    handleActionButtonClick('job_completed');
  }

  function renderJobInstructions(jobData: any, userRole: string) {
    switch (userRole) {
      case 'company_admin': {
        return (
          <div className="flex flex-col gap-2 items-start">
            {jobData?.job_action &&
              !!jobData?.job_action?.length &&
              jobData?.job_action.map((eachAction: any) => (
                <Typography className="font-semibold sm:text-sm text-xl !p-0 leading-tight" style={{ wordBreak: 'break-word' }}>
                  {eachAction?.job_instructions}
                </Typography>
              ))}
          </div>
        );
      }
      case 'field_worker':
      default: {
        return (
          jobData?.job_action?.[jobActionIndex]?.job_instructions && (
            <Typography className="font-semibold sm:text-sm text-xl !p-0 leading-tight" style={{ wordBreak: 'break-word' }}>
              {jobData?.job_action?.[jobActionIndex]?.job_instructions}
            </Typography>
          )
        );
      }
    }
  }

  function getFieldWorkerDetails(): any {
    const currentJobIndex =
      jobData?.job?.job_action?.findIndex((jobAction) => jobAction?.field_worker?.id === user?._id && jobAction.is_job_complete !== true) ??
      -1;
    if (currentJobIndex === -1) {
      return null;
    }

    return `$${formatCurrency(jobData?.job?.job_action?.[currentJobIndex].worker_commission ?? 0)}`;
  }

  function deleteJobClick(action: boolean) {
    setDeleteDialog({
      action: { open: action, maxWidth: 'xs', reasonRequired: true },
      data: { subjectToBeDeleted: jobData?.job.job_name, selectedData: jobData?.job }
    });
  }

  async function deleteJob(id: string, name: string, reason?: string) {
    const deleteResponse = await JobServicesInstance.deleteJob(jobId as string, reason as string);
    if (deleteResponse) {
      deleteJobClick(false);
      refetchJob();
      navigate('/jobs');
    }
  }

  const estimateAssignedToMe = useMemo(() => {
    if (!user) {
      return null;
    }

    return (
      (jobData?.job?.estimator?.id === user._id || isEstimator) &&
      ((!!jobData?.job?.state && [jobStates.ESTIMATION, jobStates.NEED_REVIEW, jobStates.WORK_ORDER].includes(jobData?.job?.state)) ||
        (jobData?.job.previous_states?.includes(jobStates.REASSIGNED_ESTIMATOR) &&
          !jobData?.job.previous_states?.includes(jobStates.ESTIMATION_COMPLETED)))
    );
  }, [user, jobData?.job?.estimator?.id, jobData?.job?.state, jobData?.job.previous_states, isEstimator]);

  const deletePermission = useMemo(() => {
    if (jobData?.job.state === jobStates.ESTIMATION || jobData?.job.state === jobStates.NEED_REVIEW) {
      return user?.permissions?.includes('deleteEstimate');
    } else if (user?.permissions?.includes('deleteJob')) {
      return true;
    } else {
      return false;
    }
  }, [jobData?.job.state, user?.permissions]);

  function toggleConfirmationAlert(state: boolean) {
    setConfirmationAlert(() => state);
  }

  const [loadingDirection, setLoadingDirection] = useState(false);
  async function handleAlertConfirmation(alertUser: boolean) {
    setLoadingDirection(true);

    if (alertUser) {
      const customerPhone = `${customerData?.phone1_country_code}${customerData?.phone1}`;
      const userProfile = user?.profile_picture;
      const smsData = {
        customer: {
          id: customerData?._id,
          name: `${customerData?.first_name} ${customerData?.last_name}`
        },
        to: [customerPhone],
        message: `Your ${jobData?.company.company_name} Tech, ${user?.first_name}, is on the way and should arrive shortly.`,
        attachments: [userProfile as string],
        schedule_sms: false
      };
      await EmailSmsServicesInstance.sendSms(smsData);
    }

    if (user?.rate_info?.pay_type === 'hourly') {
      let location, address;
      if (navigator.onLine) {
        location = await getCurrentLocation();
        address = await getAddressFromCoordinates(Number(location.lat), Number(location.long));
      } else {
        location = { lat: '', long: '' };
        address = '';
      }

      const jobPunchData = {
        id: jobData?.job._id as string,
        name: jobData?.job.job_name as string,
        time: new Date().toISOString(),
        location,
        address
      };

      // ----- if punch employee, start his job
      const todaysPunch = punching.punchingDetails?.todaysPunch;
      if (!todaysPunch) {
        const clockInData: TClockInData = {
          date: new Date().toISOString(),
          clock_in: {
            time: new Date().toISOString(),
            location: location,
            address: address
          },
          is_specified_time: false
        };
        const response = await PunchingServicesInstance.clockIn(clockInData);
        if (response && response.success) {
          if (jobData?.job._id && response.punch_id) {
            await PunchingServicesInstance.punchJobAction(response.punch_id, 'start', jobPunchData);
            // ----- get direction
            handleGetDirection();
            window.location.reload();
          }
        }
      } else {
        await PunchingServicesInstance.punchJobAction(todaysPunch._id, 'start', jobPunchData);
        // ----- get direction
        handleGetDirection();
      }
    }

    setLoadingDirection(false);
    toggleConfirmationAlert(false);
  }

  const isPendingPayment: boolean = React.useMemo(() => {
    if (!jobData?.job?.payments || jobData?.job?.payments?.length < 0) {
      return false;
    }
    const total = jobData?.job?.payments.reduce((total: number, payment: any) => total + payment.amount, 0);
    return total > 0;
  }, [jobData?.job]);

  function renderDialogContent(): React.ReactElement {
    if (!jobData?.job) {
      return <></>;
    }
    const isReassign = editJobDialogControl.data.isReassignWorker;
    const isActionOpen = editJobDialogControl.data.isActionDialogOpen;

    if (isReassign && !!jobData?.job?.job_action) {
      return (
        <ReAssignFieldWorker
          jobData={jobData.job}
          onSuccess={() => handleEditJobSuccess(false)}
          jobActionIndex={jobData?.job?.job_action.findIndex((jobAction) => jobAction.is_job_complete !== true)}
          isEditMode={true}
        />
      );
    } else if (isActionOpen && !!jobData.job?.job_action) {
      return (
        <div className="flex flex-col items-start gap-y-4">
          <RadioGroup
            aria-label="multi-action-job"
            onChange={handleSelectSingleMultiActionJob}
            name="multi-action-job"
            id="multi-action-job"
            value={selectedJobAction}
          >
            {jobData.job.job_action.map((singleJobAction, jobActionIndex) => (
              <div className="flex justify-between my-2" key={singleJobAction.id}>
                <FormControlLabel
                  value={jobActionIndex}
                  control={<Radio disabled={singleJobAction.is_job_complete} />}
                  label={
                    <div className="flex space-x-2">
                      <Typography className="text-base">{singleJobAction.field_worker.name}</Typography>
                      {singleJobAction.is_job_complete === false && (
                        <Chip label="not complete" color="error" variant="combined" size="small" />
                      )}
                    </div>
                  }
                />
              </div>
            ))}
          </RadioGroup>
          <div className="w-full flex justify-between gap-x-1">
            {!(!!user && user.role === 'field_worker') && (
              <Button
                startIcon={<PlusSquareFilled />}
                color="primary"
                variant="outlined"
                onClick={() => handleEditJob(true, false, true, false)}
              >
                Add New Action
              </Button>
            )}
            {selectedJobAction > -1 &&
              jobData.job.job_action.length > 1 &&
              jobData.job.job_action[selectedJobAction]?.is_job_complete !== true && (
                <CustomTooltip message="delete this job action">
                  <Button
                    startIcon={<DeleteOutlined />}
                    color="error"
                    variant="outlined"
                    onClick={() => handleDeleteJobActionDialog(true, jobData.job.job_action?.[selectedJobAction].id)}
                  >
                    Delete Action
                  </Button>
                </CustomTooltip>
              )}
            <Button
              startIcon={<EditOutlined />}
              variant="contained"
              onClick={() => handleEditJob(true, false, selectedJobAction === 1000, false)}
            >
              Edit
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <AssignWorkerForm
          jobData={jobData?.job as TJobCompleteData}
          onSuccess={handleEditJobSuccess}
          isEditMode={true}
          isAddNewAction={editJobDialogControl.data.isAddNewAction}
          isReccuringJob={false}
          jobActionIndex={editJobDialogControl.data.isAddNewAction ? 0 : selectedJobAction}
        />
      );
    }
  }

  return (
    <div className="relative w-full sm:pb-0 pb-12">
      {/* ----------------- Job Phases & Job Price---------------- */}
      <div className="absolute top-[1rem] right-4 flex flex-col items-end gap-y-2">
        <RenderStatusPreviewIcons
          jobAction={jobData?.job.job_action ?? []}
          jobState={jobData?.job?.state as string}
          status={jobData?.job.status!}
          previousStates={jobData?.job.previous_states}
          jobId={jobData?.job._id}
          proposalSentDate={jobData?.job?.last_proposal_sent}
          invoiceSentDate={jobData?.job?.last_invoice_sent?.toString()}
        />
        {jobData?.job?.job_price !== undefined && (
          <Typography variant="h4" className="font-bold" color={'green'}>
            ${formatCurrency(jobData?.job?.job_price)}
          </Typography>
        )}
        {location.state?.workOrderView && (
          <Typography variant="h5" className="font-bold" color={'GrayText'}>
            {getFieldWorkerDetails()}
          </Typography>
        )}
      </div>

      {/* ---------------- Customer Quick Actions ----------------*/}
      <Grid item xs={12} className="sm:px-4 px-2 mt-4">
        <CustomerQuickActions
          onCallClick={handleCall}
          onEmailClick={handleMail}
          onGetDirectionClick={() => toggleConfirmationAlert(true)}
          onSmsClick={handleSms}
          onInfoClick={handleInfo}
          onGoogleEarthClick={handleGoogleEarthSearch}
          onMapViewClick={handleMapViewSearch}
          onStreetViewClick={handleStreeView}
        />
      </Grid>

      {/* ----------------- Job Phase Heading ---------------- */}
      <Grid item xs={12} className="sm:px-6 px-4 mt-4 mb-1">
        <Typography variant="body1" className="text-xl font-medium">
          {renderJobHeading()}
        </Typography>
      </Grid>

      {/* ----------------- Job Details ---------------- */}
      <Grid item container xs={12} className="space-y-2 sm:space-y-0 sm:px-6 px-4 mb-2">
        {/* ---------------- Customer Details ----------------*/}
        <Grid item xs={12} sm={8} className="flex flex-col gap-y-1">
          <Typography
            onClick={() => navigate(`/customers/view/${jobData?.job.customer?.id}/contact`)}
            className="max-w-full font-medium sm:text-2xl text-xl cursor-pointer hover:drop-shadow-sm hover:underline text-cyan-600"
          >
            <CustomTooltip message="View Customer" props={{ placement: 'right' }}>
              <span>{jobData?.job.customer?.name}</span>
            </CustomTooltip>
          </Typography>

          <Typography
            onClick={() => navigate(`/jobs/view/${jobData?.job._id}/job_info`, { state: { showTabs: true } })}
            className="max-w-full font-medium sm:text-2xl text-xl cursor-pointer hover:drop-shadow-sm hover:underline text-cyan-600"
          >
            {jobData?.job.job_name}
          </Typography>

          <Typography
            onClick={() => toggleConfirmationAlert(true)}
            className="max-w-full text-base hover:underline text-cyan-600 cursor-pointer  hover:drop-shadow-sm"
          >
            {jobData?.job.address?.address1 && `${jobData?.job.address?.address1}, `}
            {jobData?.job.address?.address2 && `${jobData?.job.address?.address2}, `}
            {jobData?.job.address?.city ? `${jobData?.job.address?.city}` : ' '}
          </Typography>
        </Grid>

        {/* ---------------- Instructions ---------------- */}
        <Grid item xs={12} sm={4} paddingY={2} borderTop={'solid lightgrey'} className="sm:border-0 border-t-[1px]">
          <div className="flex flex-col gap-y-3">
            {/* -----------------Estimator Instructions---------------- */}
            {!!jobData?.job.estimator_instructions &&
              (estimateAssignedToMe || ['company_admin', 'estimator'].includes(user?.role as string)) && (
                <Typography
                  style={{
                    color: estimateAssignedToMe ? '#228732' : 'slategrey'
                  }}
                >
                  {!estimateAssignedToMe && (
                    <Typography className="font-semibold sm:text-sm text-lg !p-0 leading-tight !text-black">
                      Estimator Instruction:
                    </Typography>
                  )}
                  <Typography
                    className={`${estimateAssignedToMe ? 'font-semibold' : 'font-normal'} sm:text-sm text-lg !p-0 leading-tight`}
                    style={{ wordBreak: 'break-word' }}
                  >
                    {jobData?.job.estimator_instructions}
                  </Typography>
                </Typography>
              )}

            {/* -----------------Job Instructions---------------- */}
            {[jobStates.WORKER_ASSIGNED].includes(jobData?.job?.state as string) && (
              <Typography
                style={{
                  color: '#228732'
                }}
              >
                {renderJobInstructions(jobData?.job, user?.role as string)}
              </Typography>
            )}

            {/* -----------------Worker Instructions---------------- */}
            {!!jobData?.job.office_to_worker_notes &&
              jobData?.job.office_to_worker_notes[jobData?.job.office_to_worker_notes.length - 1] && (
                <div
                  key={jobData?.job.office_to_worker_notes[jobData?.job.office_to_worker_notes.length - 1]?.id}
                  style={{
                    color: '#0079F9'
                  }}
                >
                  <Typography className="font-semibold sm:text-sm text-xl !p-0 leading-tight" style={{ wordBreak: 'break-word' }}>
                    {jobData?.job.office_to_worker_notes[jobData?.job.office_to_worker_notes.length - 1]?.text ?? ''}
                  </Typography>
                </div>
              )}
          </div>
        </Grid>

        {/* -----------------Job Actions---------------- */}
        <Grid container item xs={12} paddingY={3} className="sm:block hidden">
          <Grid item container xs={12} justifyContent={'end'} alignItems={'end'} spacing={0.5}>
            {!!jobData && jobData?.job.status !== 3 && deletePermission && (
              <>
                <Grid item>
                  <Button
                    classes={{ root: 'rounded-none text-[0.9rem]' }}
                    size="medium"
                    color={'error'}
                    startIcon={<DeleteOutlined />}
                    variant={'contained'}
                    onClick={() => handleActionButtonClick('delete_job')}
                  >
                    Delete Job
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    classes={{ root: 'rounded-none text-[0.9rem]' }}
                    size="medium"
                    color={'warning'}
                    startIcon={<MinusCircleOutlined />}
                    variant={'contained'}
                    onClick={() => handleActionButtonClick('suspend_job')}
                  >
                    Suspend Job
                  </Button>
                </Grid>
              </>
            )}
            {jobData?.job.status === 3 ? (
              <div className="w-full">
                <Alert
                  action={
                    <Button
                      onClick={() => handleUnsuspendJob(jobData.job._id as string)}
                      variant="contained"
                      className="min-w-max !p-2 text-base"
                      size="medium"
                    >
                      Unsuspend job
                    </Button>
                  }
                  severity="error"
                >
                  Notice: This job has been suspended. No further actions can be taken at this time.
                </Alert>
              </div>
            ) : (
              jobData?.job.status !== 0 &&
              currentStateButtons?.map((eachButton) => (
                <Grid key={eachButton.key} item>
                  <CustomTooltip message={eachButton.button.tooltip}>
                    <Button
                      classes={{ root: 'rounded-none text-[0.9rem]' }}
                      size="medium"
                      color={eachButton.button.color || 'primary'}
                      startIcon={eachButton.button.icon}
                      variant={eachButton.button.variant || 'outlined'}
                      onClick={() => handleActionButtonClick(eachButton.key)}
                      disabled={!!eachButton.button.disabled}
                    >
                      {eachButton.button.title}
                    </Button>
                  </CustomTooltip>
                </Grid>
              ))
            )}
          </Grid>
        </Grid>
      </Grid>

      <>
        {showTabs && (
          <Tabs variant="scrollable" sx={{ backgroundColor: theme.palette.grey[100] }} value={activeJobTab} onChange={handleJobTabChange}>
            {jobData?.job.state && (
              <Tab
                sx={{
                  borderRadius: 0,
                  backgroundColor: `${theme.palette.grey[200]} !important`,
                  transition: 'background-color 0.3s ease',
                  '&.Mui-selected': {
                    backgroundColor: `${theme.palette.grey[100]} !important`
                  }
                }}
                icon={<StockOutlined />}
                label={
                  <CustomTooltip message="Job Status">
                    <span>{snakeCaseToTitleCase(jobData?.job.state)}</span>
                  </CustomTooltip>
                }
                iconPosition="start"
                value="job_status"
              />
            )}
            {jobData?.job.previous_states?.length &&
              availabelTabs
                .filter((singleTab) => {
                  if (
                    !jobData?.job.previous_states?.includes(jobStates.JOB_COMPLETED) &&
                    jobData?.job.previous_states?.includes(jobStates.PROPOSAL_APPROVED)
                  )
                    return singleTab;
                  return singleTab.value !== 'job_balance';
                })
                .filter((singleTab) => (jobData.job.status === 3 || jobData.job.status === 0 ? singleTab.label === 'job_info' : singleTab))
                .map((singleTab: { label: string; value: string }) => {
                  const tabHasData = tabSummaryData[singleTab.value as keyof typeof tabSummaryData];
                  const isJobInfo = singleTab.value === 'job_info';
                  let color = isJobInfo || tabHasData ? (themeConfig.mode === 'dark' ? 'white' : 'black') : 'GrayText';
                  if (
                    !!jobData.job.worker_to_office_notes?.length &&
                    !!jobData.job.worker_to_office_notes?.[jobData.job.worker_to_office_notes.length - 1].text?.length &&
                    singleTab.value === 'notes'
                  ) {
                    color = 'tomato';
                  }
                  const fontWeight = isJobInfo || tabHasData ? 'bold' : 'light';
                  return (
                    <Tab
                      classes={{
                        selected:
                          !!jobData.job.worker_to_office_notes?.length &&
                          !!jobData.job.worker_to_office_notes?.[jobData.job.worker_to_office_notes.length - 1].text?.length &&
                          singleTab.value === 'notes'
                            ? '!text-red-500'
                            : 'text-inherit'
                      }}
                      key={singleTab.label}
                      label={<span>{snakeCaseToTitleCase(singleTab.label)}</span>}
                      value={singleTab.value}
                      sx={{
                        fontWeight,
                        color
                      }}
                    />
                  );
                })}
          </Tabs>
        )}
        <Paper elevation={0} className="overflow-hidden h-full">
          {isJobDataFetching ? (
            <div className="h-full p-6">
              <Skeleton height={100} />
              <Stack direction={'row'} className="w-full" alignItems={'center'} gap={2}>
                <Skeleton animation="wave" variant="circular" width={50} height={50} />
                <Skeleton animation="wave" variant="text" height={100} width={'100%'} />
              </Stack>
              <Stack direction={'row'} className="w-full" alignItems={'center'} gap={2}>
                <Skeleton animation="wave" variant="circular" width={50} height={50} />
                <Skeleton animation="wave" variant="text" height={100} width={'100%'} />
              </Stack>
              <Skeleton height={220} />
            </div>
          ) : (
            renderTab()
          )}
        </Paper>
      </>
      {jobId && estimationOptionsFormDialogControl.action.open === true && (
        <UniversalDialog hasPrimaryButton={false} {...estimationOptionsFormDialogControl} onClose={() => handleEditEstimationOption(false)}>
          <AddEstimateOptionsForm
            isAppointmentJob={jobData?.job?.is_appointment_job ?? false}
            jobName={jobData?.job.job_name}
            isEditMode={estimationOptionsFormDialogControl.action.open}
            existingData={cloneDeep(jobData?.job.estimations)}
            jobId={jobId}
            defaultTaxRate={jobData?.job.customer?.default_tax_rate as number}
            previousStates={jobData?.job.previous_states}
            currentState={jobData?.job.state}
            onSuccess={handleEditEstimationOptionSuccess}
            customerData={customerData}
          />
        </UniversalDialog>
      )}
      {estimateFormDialogControl.action.open && (
        <UniversalDialog hasPrimaryButton={false} {...estimateFormDialogControl} onClose={() => handleEditEstimate(false)}>
          <AddEstimateForm
            customerData={customerData}
            isEditMode={estimateFormDialogControl.action.open}
            existingData={jobData?.job}
            onSubmitSuccess={handleEditEstimateSuccess}
          />
        </UniversalDialog>
      )}
      {sendPdfDialog.action.open && (
        <UniversalDialog
          title={sendPdfDialog.title}
          action={{ ...sendPdfDialog.action }}
          onClose={() =>
            setSendPdfDialog({
              action: {
                open: false,
                fullWidth: true,
                maxSize: 'xs'
              },
              data: { type: '', last_sent: '' },
              title: ''
            })
          }
          onSave={async () => {
            !!sendPdfDialog.data.type && (sendPdfDialog.data.type === 'proposal' ? await handleSendProposal() : await handleSendInvoice());
            refetchJob();
            setSendPdfDialog({
              action: {
                open: false,
                fullWidth: true,
                maxSize: 'xs'
              },
              data: { type: '', last_sent: '' },
              title: ''
            });
          }}
          primaryButonTitle="Send"
          isPrimaryButtonLoading={sendPdfDialog.data.type === 'proposal' ? sendProporalLoading : sendInvoiceLoading}
          disablePrimaryButton={sendPdfDialog.data.type === 'proposal' ? sendProporalLoading : sendInvoiceLoading}
        >
          <Typography>
            {(sendPdfDialog.data.type === 'proposal' && !!sendPdfDialog.data.last_sent && sendPdfDialog.data.last_sent.length > 0) ||
            (sendPdfDialog.data.type === 'invoice' && !!sendPdfDialog.data.last_sent && sendPdfDialog.data.last_sent.length > 0)
              ? `A ${sendPdfDialog.data.type} was sent on ${moment(sendPdfDialog.data.last_sent).format(
                  'MM-DD-YYYY hh:mm A'
                )}.\nAre you sure you want to send ${sendPdfDialog.data.type} now?`
              : `Are you sure you want to send ${sendPdfDialog.data.type} now?`}
          </Typography>
        </UniversalDialog>
      )}
      {assignWorkerFormDialogControl.action.open && jobData?.job && (
        <UniversalDialog hasPrimaryButton={false} {...assignWorkerFormDialogControl} onClose={() => handleAssignWorker(false)}>
          <AssignWorkerForm jobData={jobData?.job} onSuccess={handleAssignWorkerSuccess} isEditMode={false} isReccuringJob={false} />
        </UniversalDialog>
      )}
      {editJobDialogControl.action.open && jobData?.job && !!jobData?.job?.job_action && (
        <UniversalDialog hasPrimaryButton={false} {...editJobDialogControl} onClose={() => handleEditJob(false, false, false, false)}>
          {renderDialogContent()}
        </UniversalDialog>
      )}
      {deleteJobAction.action.open && (
        <UniversalDialog
          primaryButonTitle="Delete"
          onSave={handleDeleteJobAction}
          {...deleteJobAction}
          onClose={() => handleDeleteJobActionDialog(false)}
          isPrimaryButtonLoading={isLoading.job_action}
        >
          <Typography>Are you sure you want to delete this action?</Typography>
        </UniversalDialog>
      )}
      {reAssignEstimatorFormDialogControl.action.open && jobData?.job && (
        <UniversalDialog hasPrimaryButton={false} {...reAssignEstimatorFormDialogControl} onClose={() => handleReAssignEstimator(false)}>
          <ReassignEstimatorForm existingData={jobData?.job} onSuccess={handleReAssignEstimatorSuccess} />
        </UniversalDialog>
      )}
      {reAssignWorkerFormDialogControl.action.open && jobData?.job && (
        <UniversalDialog hasPrimaryButton={false} {...reAssignWorkerFormDialogControl} onClose={() => handleReAssignWorker(false)}>
          <ReAssignFieldWorker jobData={jobData?.job} onSuccess={handleReaAssignWorkerSuccess} jobActionIndex={0} isEditMode={false} />
        </UniversalDialog>
      )}
      {declineProposalFormDialogControl.action.open && jobData?.job && (
        <UniversalDialog hasPrimaryButton={false} {...declineProposalFormDialogControl} onClose={() => handleDeclineProposal(false)}>
          <DeclineForm jobData={jobData as unknown as TJobInfo} onSuccess={handleDeclineProposalSuccess} />
        </UniversalDialog>
      )}
      {apwosConfDialog && user?.role !== 'estimator' && (
        <UniversalDialog
          title="Accept Proposal"
          action={{ open: apwosConfDialog, maxWidth: 'xs', fullWidth: true }}
          onClose={() => setApwosConfDialog(false)}
          onSave={() => handleAcceptProposalWithoutSending()}
          disablePrimaryButton={isLoading.accept_decline_proposal}
          isPrimaryButtonLoading={isLoading.accept_decline_proposal}
          primaryButonTitle="Accept"
        >
          <Typography variant="body1">
            You are accepting <strong className="font-bold">{getOptionNamebyId(currentSelectedOption)}</strong>. Is this correct?
          </Typography>
        </UniversalDialog>
      )}
      {apwosConfDialog && user?.role === 'estimator' && (
        <UniversalDialog
          title="Accept Proposal"
          action={{ open: apwosConfDialog, maxWidth: 'sm', fullWidth: true }}
          onClose={() => setApwosConfDialog(false)}
          primaryButonTitle="Accept"
          hasPrimaryButton={false}
        >
          <EstimatorProposalAcceptanceWithoutSending
            onSubmit={handleAcceptProposalWithoutSending}
            isSubmitting={isLoading.accept_decline_proposal}
          />
        </UniversalDialog>
      )}
      {jobCompleteConfDialog && (
        <UniversalDialog
          title="Job Complete Confirmation"
          action={{ open: jobCompleteConfDialog, maxWidth: 'xs', fullWidth: true }}
          onClose={() => setJobCompleteConfDialog(false)}
          onSave={handleJobComplete}
          disablePrimaryButton={isLoading.job_complete}
          isPrimaryButtonLoading={isLoading.job_complete}
          disableSecondaryButton={isLoading.job_complete}
          hasSecondaryButton={true}
          primaryButonTitle="Yes"
          secondaryButonTitle="No"
          handleSecondaryClick={() => setJobCompleteConfDialog(false)}
        >
          <Typography variant="body1">
            Are you sure all this information is correct and you would like to mark this job completed?
          </Typography>
        </UniversalDialog>
      )}

      {markEstimateConfDialog && (
        <UniversalDialog
          title="Mark Estimate Confirmation"
          action={{ open: markEstimateConfDialog, maxWidth: 'xs', fullWidth: true }}
          onClose={() => setMarkEstimateConfDialog(false)}
          onSave={() => handleEstimateCompletedConfirmation()}
          primaryButonTitle="OK"
          hasSecondaryButton={true}
          secondaryButonTitle="Cancel"
          handleSecondaryClick={() => setMarkEstimateConfDialog(false)}
        >
          <Typography variant="body1">
            Are you sure to mark Estimate as Completed? After mark Estimate completed it will disappeared from your list.
          </Typography>
        </UniversalDialog>
      )}
      {jobNotCompleteReason.state && (
        <UniversalDialog
          title={jobNotCompleteReason?.title ?? 'Job Not Complete Reason'}
          action={{ open: jobNotCompleteReason.state, maxWidth: 'xs', fullWidth: true }}
          onClose={() => setJobNotCompleteReason({ state: false, job_action_id: '', reason: null })}
          onSave={() => handleJobNotComplete(jobNotCompleteReason?.isSuspend ?? false)}
          primaryButonTitle="Submit"
          isPrimaryButtonLoading={isLoading.job_complete}
          disablePrimaryButton={isLoading.job_complete && !jobNotCompleteReason.reason?.length}
        >
          <div className="flex flex-col items-start justify-center space-y-2">
            {jobData?.job?.state !== jobStates.INVOICE_UNPAID && isPendingPayment && (
              <Alert title="Warning" severity="warning" className="mb-3">
                <AlertTitle variant="subtitle1" color={'warning.dark'}>
                  This job has a pending payment. Consider if reimbursement is needed.
                </AlertTitle>
              </Alert>
            )}
            {jobData?.job?.state === jobStates.INVOICE_UNPAID && (
              <Alert title="Warning" severity="warning" className="mb-3">
                <AlertTitle variant="subtitle1" color={'warning.dark'}>
                  You are about to delete a job with an outstanding balance.
                </AlertTitle>
              </Alert>
            )}
            <Typography variant="body1" className="ml-2">
              Please provide a reason *
            </Typography>
            <TextField
              value={jobNotCompleteReason.reason?.length ? jobNotCompleteReason.reason : jobsOption.worker_to_office_note}
              fullWidth
              multiline
              minRows={3}
              onChange={handleJobNotCompleteReasonChange}
            />
          </div>
        </UniversalDialog>
      )}

      {addEmailOrSmsPopup.action.open && (
        <UniversalDialog
          action={{ open: addEmailOrSmsPopup.action.open, fullWidth: true }}
          onClose={() => handleAddEmailorSmsPopup()}
          hasPrimaryButton={false}
        >
          <AddEmailorSms {...addEmailOrSmsPopup} onClose={() => handleAddEmailorSmsPopup()} />
        </UniversalDialog>
      )}

      {confirmationAlert && (
        <UniversalDialog
          action={{ open: confirmationAlert, fullWidth: true, maxWidth: 'xs' }}
          onClose={() => toggleConfirmationAlert(false)}
          title={
            <Typography variant="h4">
              <AlertFilled /> Alert Customer
            </Typography>
          }
          hasSecondaryButton
          primaryButonTitle="Yes"
          secondaryButonTitle="No"
          onSave={() => handleAlertConfirmation(true)}
          handleSecondaryClick={() => handleAlertConfirmation(false)}
          disablePrimaryButton={loadingDirection}
          isPrimaryButtonLoading={loadingDirection}
          disableSecondaryButton={loadingDirection}
        >
          {loadingDirection ? (
            <Typography>
              <CircularWithPath size={'20px'} /> Please wait...
            </Typography>
          ) : (
            <Typography>Would you like to alert customer, you are on the way ?</Typography>
          )}
        </UniversalDialog>
      )}

      {deleteDialog.action.open && <DeleteConfirmationPoup {...deleteDialog} onClose={() => deleteJobClick(false)} onDelete={deleteJob} />}

      <div className="fixed z-[999] bottom-0 sm:hidden flex items-center justify-around w-full bg-gray-50 h-[60px] shadow-[0px_-1px_10px_rgba(0,0,0,0.1)]">
        {/* -------------Add media------------- */}
        <input style={{ display: 'none' }} id="job-add-media-input" type="file" multiple onChange={handleFileUpload} />
        <Popper
          className="min-w-max max-w-full"
          sx={{ zIndex: 1200, '& .MuiPaper-root': { borderRadius: 0 } }}
          open={mobileAddMediaAnchorEl !== null}
          anchorEl={mobileAddMediaAnchorEl}
          placement={'top'}
          transition
          modifiers={[
            {
              name: 'offset',
              options: {
                // [horizontal offset, vertical offset]
                offset: [0, 12]
              }
            }
          ]}
        >
          {({ TransitionProps }) => (
            <Collapse unmountOnExit appear {...TransitionProps} timeout={100}>
              <ClickAwayListener
                onClickAway={() => {
                  setMobileAddMediaAnchorEl(null);
                }}
              >
                <Card className="bg-gray-50">
                  <CardContent className="flex flex-col gap-2">
                    {renderMobileAddMediaButtons()}
                    {jobData?.job.status !== 3 && (
                      <Button
                        classes={{
                          root: 'rounded-none text-lg !py-4 flex items-center justify-start'
                        }}
                        variant="contained"
                        startIcon={<UploadOutlined />}
                        component="label"
                        htmlFor="job-add-media-input"
                      >
                        Add Media
                      </Button>
                    )}
                  </CardContent>
                </Card>
              </ClickAwayListener>
            </Collapse>
          )}
        </Popper>
        <IconButton
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => onAddMediaClick(e)}
          className="bg-orange-600 text-white hover:bg-orange-400 text-xl p-4 !rounded-none"
        >
          <CameraFilled />
        </IconButton>

        {/* -------------Focus to notes------------- */}
        {!!jobsOption.active_note_input_id.length && (
          <IconButton onClick={handleFocusToNote} className="bg-yellow-500 text-white hover:bg-yellow-300 text-xl p-4 !rounded-none">
            <FormOutlined />
          </IconButton>
        )}

        {/* -------------Affirmative Job Action------------- */}
        {jobsOption.show_job_complete_btn && (
          <>
            {disableMobileJobCompleteBtn?.length ? (
              <CustomTooltip message={disableMobileJobCompleteBtn} props={{ placement: 'top' }}>
                <IconButton className="text-white bg-slate-300 hover:bg-slate-400 text-xl p-4 !rounded-none">
                  <CheckOutlined />
                </IconButton>
              </CustomTooltip>
            ) : (
              <IconButton
                className="bg-green-600 text-white hover:bg-green-400 disabled:bg-slate-100 text-xl p-4 !rounded-none"
                onClick={handleJobCompleteClick}
              >
                <CheckOutlined />
              </IconButton>
            )}
          </>
        )}

        {/* -------------Job menu------------- */}
        <Popper
          className="min-w-max max-w-full"
          sx={{ zIndex: 1200, '& .MuiPaper-root': { borderRadius: 0 } }}
          open={mobileMenuAnchorEl !== null}
          anchorEl={mobileMenuAnchorEl}
          placement={'top'}
          transition
          modifiers={[
            {
              name: 'offset',
              options: {
                // [horizontal offset, vertical offset]
                offset: [0, 12]
              }
            }
          ]}
        >
          {({ TransitionProps }) => (
            <Collapse unmountOnExit appear {...TransitionProps} timeout={100}>
              <ClickAwayListener onClickAway={() => setMobileMenuAnchorEl(null)}>
                <Card className="bg-gray-50">
                  <CardContent className="flex flex-col-reverse gap-2">
                    {jobData?.job.status !== 3 && deletePermission && (
                      <>
                        <Grid item>
                          <Button
                            className="flex items-center justify-start text-start"
                            classes={{
                              root: 'rounded-none text-lg !py-4',
                              outlined: 'bg-transparent'
                            }}
                            fullWidth
                            size="large"
                            color={'error'}
                            startIcon={<DeleteOutlined />}
                            variant={'contained'}
                            onClick={() => handleActionButtonClick('delete_job')}
                          >
                            Delete Job
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            className="flex items-center justify-start text-start"
                            classes={{
                              root: 'rounded-none text-lg !py-4',
                              outlined: 'bg-transparent'
                            }}
                            fullWidth
                            size="large"
                            color={'warning'}
                            startIcon={<MinusCircleOutlined />}
                            variant={'contained'}
                            onClick={() => handleActionButtonClick('suspend_job')}
                          >
                            Suspend Job
                          </Button>
                        </Grid>
                      </>
                    )}
                    {jobData?.job.status !== 0 &&
                      currentStateButtons?.map((eachButton) => (
                        <div className="w-full">
                          <Button
                            className="flex items-center justify-start text-start"
                            classes={{
                              root: 'rounded-none text-lg !py-4',
                              outlined: 'bg-transparent'
                            }}
                            fullWidth
                            size="large"
                            color={eachButton.button.color || 'primary'}
                            startIcon={eachButton.button.icon}
                            variant={eachButton.button.variant || 'outlined'}
                            onClick={() => handleActionButtonClick(eachButton.key)}
                            disabled={!!eachButton.button.disabled}
                          >
                            {eachButton.button.title}
                          </Button>
                        </div>
                      ))}
                  </CardContent>
                </Card>
              </ClickAwayListener>
            </Collapse>
          )}
        </Popper>
        <IconButton
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => setMobileMenuAnchorEl((prev) => (prev === null ? e.currentTarget : null))}
          className="text-white text-xl p-4 !rounded-none"
          sx={{
            backgroundColor: theme.palette.primary.light,
            ':hover': { backgroundColor: theme.palette.primary.light, color: theme.palette.primary.lighter }
          }}
        >
          <MenuOutlined />
        </IconButton>
      </div>
    </div>
  );
};

export default JobsViewPage;
