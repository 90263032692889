import { TJobInfo } from 'components/widgets/types/jobInfoType';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import axiosServices from 'utils/axios';

type TPayNowPayload = {
  company_id: string;
  payment_type: string;
  amount: number;
  card_number?: string;
  expiry_month?: string;
  expiry_year?: string;
  cvv?: string;
  card_type?: string;
};

type TSettingsInfo = {
  referrals: string[];
};

class PublicServices {
  getJobInfo = async (jobId: string, source?: string) => {
    try {
      const response = await axiosServices.get(`/api/job/no-auth/${jobId}/${source ?? ''}`);
      return response.data.data as TJobInfo;
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  payNow = async (jobId: string, payload: TPayNowPayload) => {
    try {
      const response = await axiosServices.post(`/api/job/pay-now/${jobId}`, payload);
      return response.data;
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  systemReferrals = async (companyId: string) => {
    try {
      const response = await axiosServices.get(`/api/setting/no-auth/${companyId}`);
      if (response.data.success) {
        return response.data.data as TSettingsInfo;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
}

const PublicSerivcesInstance = new PublicServices();
export default PublicSerivcesInstance;
