import { Card } from '@mui/material';
import { TDateFilter } from 'pages/Dashboard/types/types.dashboard';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { TGrossIncomeReport } from '../types/types.report';
import GrossIncomeReportFilter from './GrossIncome/GrossIncomeReportFilter';

const GrossIncomeReport = ({
  isReportDataFetching,
  selectedFilter,
  setSelectedFilter,
  reportData
}: {
  isReportDataFetching: boolean;
  selectedFilter: TDateFilter;
  setSelectedFilter: (value: TDateFilter) => void;
  reportData: TGrossIncomeReport;
}) => {
  //-----------------Constants---------------
  const [seriesData, setSeriesData] = useState<{ name: string; data: number[] }[]>([
    { name: 'Gross Income', data: [] },
    { name: 'Payroll', data: [] },
    { name: 'Other Expenses', data: [] },
    { name: 'Net Profit', data: [] }
  ]);
  const grossIncomeOption = {
    chart: {
      // offsetX: -15,
      toolbar: { show: false }
    },
    title: { text: 'Gross Income', align: 'center' as 'center' },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: reportData.graph_statistics?.map((singleData) => singleData.date)
    }
  };
  //-------------------------------useEffect-----------------

  useEffect(() => {
    if (reportData.graph_statistics?.length) {
      const tempData: { name: string; data: number[] }[] = JSON.parse(JSON.stringify(seriesData));
      reportData.graph_statistics.forEach((singleData) => {
        singleData.counts.map((singleCount, countIndex) => tempData[countIndex].data.push(singleCount));
      });
      setSeriesData(tempData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData]);

  return (
    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-3">
      {/*-----------------Bar Graph------------------------ */}
      {!!seriesData.length && (
        <Card className="sm:col-span-2">
          <div className={`hidden sm:block`}>
            <ReactApexChart
              type="bar"
              series={[
                { name: 'Gross Income', data: seriesData[0].data },
                { name: 'Payroll', data: seriesData[1].data },
                { name: 'Other Expenses', data: seriesData[2].data },
                { name: 'Net Profit', data: seriesData[3].data }
              ]}
              options={grossIncomeOption}
            />
          </div>
        </Card>
      )}
      <div className="sm:col-span-2 lg:col-span-1 grid sm:grid-cols-2 lg:grid-cols-1 gap-4">
        {/*-----------------Gross Income Filter Card------------------------ */}

        {reportData && (
          <GrossIncomeReportFilter
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            grossIncome={reportData.statistics.gross_income}
            netProfit={reportData.statistics.net_profit}
          />
        )}
        {/*-----------------Pie Chart------------------------ */}

        {reportData?.statistics &&
          !(
            reportData?.statistics?.sales_tax === 0 &&
            reportData?.statistics?.payrolls === 0 &&
            reportData?.statistics?.expenses === 0
          ) && (
            <Card className="p-2">
              <ReactApexChart
                type="pie"
                series={[reportData?.statistics?.sales_tax, reportData?.statistics?.payrolls, reportData?.statistics?.expenses]}
                options={{ labels: ['Sales Tax', 'Payrolls', 'Other Expenses'], legend: { position: 'bottom', offsetY: -5 } }}
              />
            </Card>
          )}
      </div>
    </div>
  );
};

export default GrossIncomeReport;
