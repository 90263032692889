import { InboxOutlined } from '@ant-design/icons';
import {
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';
import RenderMeasurementsUnits from 'components/ShowMeasurementUnits';
import { IEstimationOption, TJobData, TPayment } from 'components/tables/interfaces/jobTableInterface';
import useTimezone from 'hooks/useTimezone';
import { useEffect, useState } from 'react';
import { useSelector } from 'store';
import { formatCurrency, getStateAbbrevation, toTitleCase } from 'utils/common';
import { invoicePdfMessage } from 'utils/constants';

const InvoiceJobCategoryAndTransactionDetails = (props: { jobData: TJobData; approvedOptionIndex: number; amountPaid: number }) => {
  //---------------------------Constants-----------------------
  const { jobData, approvedOptionIndex, amountPaid } = props;
  const theme = useTheme();
  const formatDateTime = useTimezone();
  const settingsState = useSelector((state) => state.settings.generalSettings);
  const primaryColor = theme.palette.primary.main;
  const selectedOption = jobData?.job?.estimations?.options?.[approvedOptionIndex];
  const [organizedData, setOrganizedData] = useState<Array<IEstimationOption['items']>>([]);
  const [categoryTotals, setCategoryTotals] = useState<{ [key: string]: number }>({});
  const [selectedPaymentIndex, setSelectedPaymentIndex] = useState<number | null>(null);

  //------------------------------------useEffect---------------------------
  useEffect(() => {
    if (selectedOption) {
      const organizedDataArray: Array<IEstimationOption['items']> =
        selectedOption.items.reduce((acc: Array<Array<typeof item>>, item) => {
          const existingArray = acc.find((arr) => arr[0]?.job_category.name === item.job_category.name);
          if (existingArray) {
            existingArray.push(item);
          } else {
            acc.push([item]);
          }

          const { job_category, taxable, price } = item;
          if (job_category.name) {
            setCategoryTotals((prevCategoryTotals) => ({
              ...prevCategoryTotals,
              [job_category.name]: (prevCategoryTotals[job_category.name] || 0) + price + taxable
            }));
          }

          return acc;
        }, []) || [];
      setOrganizedData(organizedDataArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobData]);

  //------------------------------------Handlers---------------------------
  const handleDialogClose = () => {
    setSelectedPaymentIndex(null);
  };

  //----------------------------Components---------------------
  const ImageDialog = () => {
    return (
      <Dialog open={selectedPaymentIndex !== null} onClose={handleDialogClose}>
        <DialogContent>
          <CardMedia component="img" src={jobData?.job?.payments?.[selectedPaymentIndex ?? 0]?.cheque_image} alt="Image" />
        </DialogContent>
      </Dialog>
    );
  };
  const SummarySection = ({ label, value, bold = false }: { label: string; value: number; bold?: boolean }) => (
    <Stack direction={'row'} className="justify-between" spacing={5} color="white">
      <Typography align="right" className={bold ? 'font-bold' : ''}>
        {label}
      </Typography>
      <Typography
        align="right"
        className={`${
          label === 'Tax' && jobData.job.is_this_job_tax_exempt && selectedOption?.tax !== 0 ? 'line-through' : 'none'
        } font-mono text-base`}
      >
        &#36;{formatCurrency(value)}
      </Typography>
    </Stack>
  );

  return (
    <>
      {jobData && (
        <>
          <Grid container gap={3}>
            {/*-----------------------------------Job Categories------------------------*/}

            <Grid item xs={12} className="space-y-5 " padding={1}>
              {organizedData?.map((jobCateory: IEstimationOption['items'], index) => (
                <TableContainer>
                  <Typography variant="h6" className="p-3" sx={{ backgroundColor: primaryColor, color: 'white' }}>
                    {jobCateory[0].job_category.name}
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Item</TableCell>
                        <TableCell align="left">Description</TableCell>
                        <TableCell align="left">Measurement </TableCell>
                        <TableCell align="left">Tax</TableCell>
                        <TableCell align="right">Price</TableCell>
                      </TableRow>
                    </TableHead>
                    {jobCateory && (
                      <TableBody>
                        {jobCateory.map((items, index) => (
                          <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align="left">{items.service_and_product.name}</TableCell>
                            <TableCell align="left" sx={{ textOverflow: 'ellipsis' }}>
                              {items.description}
                            </TableCell>
                            <TableCell align="left">
                              <RenderMeasurementsUnits
                                measurementData={items.measurements}
                                measure={items.measure_type}
                                showUnitsOnProposal={items.show_units_on_proposal}
                              />
                            </TableCell>
                            <TableCell align="left" className="font-mono text-base">
                              ${formatCurrency(items.taxable)}
                            </TableCell>
                            <TableCell align="right" className="font-mono text-base">
                              ${formatCurrency(items.price)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell colSpan={4} align="right">
                            Total
                          </TableCell>
                          <TableCell align="right" className="font-mono text-base">
                            ${formatCurrency(categoryTotals[`${organizedData[index][0].job_category.name}`])}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              ))}
              {/*-----------------------------------TRANSACTION ACTIVITY------------------------*/}
              <Grid item xs={12}>
                <TableContainer>
                  <Typography variant="h6" className="p-3" sx={{ backgroundColor: primaryColor, color: 'white' }}>
                    Transaction Activity
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Date</TableCell>
                        <TableCell align="left">Payment Type</TableCell>
                        <TableCell align="left">Amount Paid</TableCell>
                      </TableRow>
                    </TableHead>
                    {jobData?.job?.payments ? (
                      <TableBody>
                        {jobData?.job?.payments?.map((paymentData: TPayment, index: number) => {
                          return (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell align="left">
                                {paymentData.date &&
                                  formatDateTime(paymentData.date, {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: settingsState?.time_format === '12hrs'
                                  })}
                              </TableCell>
                              <TableCell align="left">
                                {paymentData.payment_type === 'cheque' ? 'Check' : toTitleCase(paymentData.payment_type as string)}
                              </TableCell>
                              <TableCell align="left" className="font-mono text-base">
                                ${formatCurrency(paymentData.amount)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <CardContent className="w-full">
                            <Stack className="mt-4 flex flex-col items-center gap-2">
                              <InboxOutlined style={{ height: 20, transform: 'scale(3)', color: 'GrayText' }} />
                              <Typography color={'GrayText'}>No Data</Typography>
                            </Stack>
                          </CardContent>
                        </TableCell>
                      </TableRow>
                    )}
                  </Table>
                </TableContainer>
              </Grid>
              {/*-----------------------------------Over All Summary------------------------*/}
              <div className="flex sm:flex-row flex-col justify-between ">
                {jobData?.payment_info_on_invoice ? (
                  <>
                    <div className="text-left w-full p-4 flex flex-col items-start gap-3" style={{ backgroundColor: '#34495f10' }}>
                      <div>
                        <Typography variant="body2">{'Please make check payable to:'}</Typography>
                        <Typography variant="body1">{jobData?.company.company_name}</Typography>
                      </div>
                      <div>
                        <Typography variant="body2">{'Send Payment To:'}</Typography>
                        <Typography variant="body1">
                          {jobData?.job.address?.address1} {jobData?.job.address?.address2}, {jobData?.job.address?.city},&nbsp;
                          {getStateAbbrevation(jobData?.job.address?.state ?? '')} {jobData?.job.address?.zip}
                        </Typography>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="flex flex-col p-5 sm:w-1/2 w-full" style={{ backgroundColor: '#34495f' }}>
                  <SummarySection label="Sub Total" value={jobData.job.estimations?.options[approvedOptionIndex]?.sub_total || 0} bold />
                  <SummarySection label="Tax" value={jobData.job.estimations?.options[approvedOptionIndex]?.tax || 0} bold />
                  {selectedOption?.sub_total !== undefined && (
                    <SummarySection
                      label="Grand Total"
                      value={selectedOption?.sub_total + (jobData.job.is_this_job_tax_exempt ? 0 : selectedOption?.tax || 0)}
                      bold
                    />
                  )}
                  {amountPaid !== undefined && <SummarySection label="Paid Amount" value={amountPaid} bold />}
                  {jobData && amountPaid !== undefined && selectedOption?.sub_total !== undefined && (
                    <SummarySection
                      label="Balance Due"
                      value={selectedOption?.sub_total + (jobData.job.is_this_job_tax_exempt ? 0 : selectedOption?.tax || 0) - amountPaid}
                      bold
                    />
                  )}
                </div>
              </div>
            </Grid>
            {selectedPaymentIndex !== null && <ImageDialog />}
          </Grid>
          <Typography textAlign={'center'} variant="h6" fontWeight={'bold'} className="my-4">
            {invoicePdfMessage}
          </Typography>
        </>
      )}
    </>
  );
};

export default InvoiceJobCategoryAndTransactionDetails;
