import axiosServices from 'utils/axios';
// import { IResponseWithJobs, IResponseWithScheduleSMS } from './types/types.services';
import { AxiosRequestConfig } from 'axios';
import { ISearch } from 'components/filters/SearchFilter';
import {
  TAssignWorker,
  TJobCompleteData,
  TJobCompleteOrNotComplete,
  TJobData,
  TOfficeToWorkerNote,
  TPayment,
  TProposalApproval,
  TProposalDecline,
  TScheduleSMSData,
  TSendInvoice
} from 'components/tables/interfaces/jobTableInterface';
import { TResponseWMaterialsUsed } from 'pages/jobs/types/jobViewPageTypes';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { storedAPIInIndexedDB, storedDataInIndexedDB } from 'utils/common';
import { indexedDbStoreNames } from 'utils/constants';
import IndexedDBServices from './services.localDb';
import {
  IResponseWithCreateJob,
  IResponseWithEstimates,
  IResponseWithGetRecurringJobName,
  IResponseWithJobs,
  IResponseWithScheduleSMS,
  IResponseWithSingleJob,
  TIndexedDBResponse,
  TProposalAcceptAndDecline,
  TSendInvoiceWResponse,
  TUserInvoicesResponse
} from './types/types.services';

type TResponseWNotes = { data: { success: boolean; data: TOfficeToWorkerNote } };
class JobServices {
  getAllJobs = async (
    paginationData?: { page: number; rowsPerPage: number },
    searchData?: ISearch,
    queryPayload?: { [key: string]: string },
    // filter_type?: string,
    // filter_field?: string,
    isTodaysJob = false
  ) => {
    const page = paginationData && paginationData?.page + 1;
    const limit = paginationData && paginationData?.rowsPerPage;
    try {
      if (!navigator.onLine) {
        const indexedDBInstance = new IndexedDBServices(indexedDbStoreNames.jobs);
        const allJobs = (await indexedDBInstance.getAllLocalData()) as TIndexedDBResponse;
        return allJobs.data as {
          jobs: TJobCompleteData[];
          count: number;
          page: number;
          page_size: number;
          pages: number;
        };
      }

      const response: IResponseWithJobs = await axiosServices.get('api/job/', {
        params: {
          ...(page && { page }),
          ...(limit && { limit }),
          ...(searchData && { filter: JSON.stringify({ ...searchData, sort: { updated_at: -1 } }) }),
          ...queryPayload,
          is_today_job: isTodaysJob
        }
      });
      if (response && response.data.success) {
        await storedDataInIndexedDB(indexedDbStoreNames.jobs, response.data.data);
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  getSingleJobs = async (jobId?: string, includes_instruction_colors: boolean = false) => {
    if (!navigator.onLine) {
      const indexedDBInstance = new IndexedDBServices(indexedDbStoreNames.singleJobs);
      const data = (await indexedDBInstance.getAllLocalData()) as TIndexedDBResponse;
      const allJobs = data?.data as Array<TJobData>;
      const job = allJobs?.find((job) => job?.job?._id === jobId);
      return job;
    }

    if (jobId) {
      try {
        const response: IResponseWithSingleJob = await axiosServices.get(
          `api/job/${jobId}?includes_instruction_colors=${includes_instruction_colors}`
        );

        if (response && response.data.success) {
          return response.data.data;
        }
      } catch (error: unknown) {
        const knownError = error as { message: string };
        dispatch(
          openSnackbar({
            open: true,
            message: knownError.message,
            variant: 'alert',
            alert: {
              color: 'error'
            },
            severity: 'error',
            close: true
          })
        );
      }
    }
  };

  // Pankaj Changes--------------------------

  getRecurringJobName = async (jobId: string) => {
    try {
      const response: IResponseWithGetRecurringJobName = await axiosServices.get(`api/job/recurring-job-name/${jobId}`);
      if (response && response.data.success) {
        return response.data.data?.job_name;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  deleteJob = async (JobId: string, delete_reason: string) => {
    try {
      const response: IResponseWithJobs = await axiosServices.delete(`api/job/${JobId}`, {
        sendLocation: true,
        data: { delete_reason }
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Job was successfully deleted`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const deleteJobError = error as { success: boolean; message: string };

      dispatch(
        openSnackbar({
          open: true,
          message: deleteJobError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },

          close: true
        })
      );
    }
  };
  deleteJobAction = async (JobId: string, jobActionId: string) => {
    try {
      const response: IResponseWithJobs = await axiosServices.delete(`api/job/action/${JobId}?job_action_id=${jobActionId}`, {
        sendLocation: true
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Job Action was successfully deleted`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const deleteJobError = error as { success: boolean; message: string };

      dispatch(
        openSnackbar({
          open: true,
          message: deleteJobError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },

          close: true
        })
      );
    }
  };

  createJob = async (JobData: TJobCompleteData) => {
    try {
      const response: IResponseWithCreateJob = await axiosServices.post(`api/job/`, JobData);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Job Created Successfully!`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  createRecurringJob = async (jobId: string, jobData: TAssignWorker, followupId: string) => {
    try {
      const response: IResponseWithCreateJob = await axiosServices.post(
        `api/job/recurring-job/${jobId}?followup_id=${followupId}`,
        jobData
      );
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Worker Assigned Successfully!`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  createJobWithPriorEstimate = async (jobData: any) => {
    try {
      const response: { data: { success: boolean; data?: { job_id: string } } } = await axiosServices.post(
        `api/job/create-prior-estimation`,
        jobData
      );
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Job Created Successfully!`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  editJob = async (JobId: string, JobData: TJobCompleteData) => {
    try {
      if (!navigator.onLine) {
        await storedAPIInIndexedDB({ apiUrl: `api/job/${JobId}`, data: JobData, requestType: 'put' });
        return;
      }

      const response: IResponseWithJobs = await axiosServices.put(`api/job/${JobId}`, JobData);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Job Updated Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  assignFieldWorker = async (JobId: string, JobData: TAssignWorker, isEditMode: boolean, parent_followup_id?: string) => {
    try {
      if (!navigator.onLine) {
        await storedAPIInIndexedDB({
          apiUrl: `api/job/assign-worker/${JobId}${!!parent_followup_id ? `parent_followup_id=${parent_followup_id}` : ''}`,
          data: JobData,
          requestType: 'put'
        });
        return;
      }

      const response: IResponseWithJobs = await axiosServices.put(
        `api/job/assign-worker/${JobId}?parent_followup_id=${parent_followup_id ?? ''}`,
        JobData
      );
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: isEditMode ? 'Job Updated Successfully' : `Worker Assinged Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  markJobAsCompleted = async (JobId: string, jobActionId: string, JobData: TJobCompleteOrNotComplete) => {
    try {
      if (!navigator.onLine) {
        await storedAPIInIndexedDB({
          apiUrl: `api/job/mark-job-as-completed/${JobId}?job_action_id=${jobActionId}`,
          data: JobData,
          requestType: 'put'
        });
        return;
      }

      const response: IResponseWithJobs = await axiosServices.put(
        `api/job/mark-job-as-completed/${JobId}?job_action_id=${jobActionId}`,
        JobData
      );

      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Job mark as completed`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  markJobAsNotCompleted = async (JobId: string, jobActionId: string, JobData: TJobCompleteOrNotComplete) => {
    try {
      if (!navigator.onLine) {
        await storedAPIInIndexedDB({
          apiUrl: `api/job/mark-job-as-not-completed/${JobId}?job_action_id=${jobActionId}`,
          data: JobData,
          requestType: 'put'
        });
        return;
      }

      const response: IResponseWithJobs = await axiosServices.put(
        `api/job/mark-job-as-not-completed/${JobId}?job_action_id=${jobActionId}`,
        JobData
      );
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Job mark as not completed`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  markTaxExempt = async (JobId: string, taxExemptData: { is_this_job_tax_exempt: boolean; tax_exempt_photos: string[] }) => {
    try {
      if (!navigator.onLine) {
        await storedAPIInIndexedDB({ apiUrl: `api/job/tax-exempt/${JobId}`, data: taxExemptData, requestType: 'put' });
        return;
      }

      const response: IResponseWithJobs = await axiosServices.put(`api/job/tax-exempt/${JobId}`, taxExemptData);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Tax exempt updated sucessfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  reassignFieldWorker = async (jobId: string, jobData: TAssignWorker) => {
    try {
      const response = await axiosServices.put(`api/job/reassign-worker/${jobId}`, jobData);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Worker assigned Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  addScheduleSMS = async (TScheduleSMSDataFlat: TScheduleSMSData) => {
    try {
      const response: IResponseWithScheduleSMS = await axiosServices.post(`api/job/schedule-or-send-job-sms`, TScheduleSMSDataFlat);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Scheduled Added Successfully!`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  //------Approval And Decline
  approveProposal = async (approvedProposal: TProposalApproval, jobId: string) => {
    try {
      const response: TProposalAcceptAndDecline = await axiosServices.post(`api/job/approve-proposal/${jobId}`, approvedProposal);
      if (response && response.data.success) {
        return response;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  declineProposal = async (declinedProposal: TProposalDecline, jobId: string) => {
    try {
      const response: IResponseWithJobs = await axiosServices.post(`api/job/decline-proposal/${jobId}`, declinedProposal);
      if (response && response.data.success) {
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  invoicePayment = async (jobId: string, paymentdData: { payments: TPayment[] }) => {
    try {
      const response: {
        data: {
          success: boolean;
        };
      } = await axiosServices.post(`api/job/add-payments/${jobId}`, paymentdData);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Payment done successfully.`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  sendInvoice = async (sendInvoiceData: TSendInvoice, jobId: string) => {
    try {
      const response: TSendInvoiceWResponse = await axiosServices.post(`api/job/send-invoice/${jobId}`, sendInvoiceData);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Invoice Sent Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  getCustomerInvoice = async (jobId: string) => {
    try {
      const response: TUserInvoicesResponse = await axiosServices.get(`api/job/invoices/${jobId}`);
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  getInvoices = async (
    paginationData?: { page: number; rowsPerPage: number },
    searchData?: ISearch,
    filter_type?: string,
    filter_field?: string
  ) => {
    try {
      const page = paginationData && paginationData?.page + 1;
      const limit = paginationData && paginationData?.rowsPerPage;
      const response: TUserInvoicesResponse = await axiosServices.get(`api/invoices`, {
        params: {
          ...(page && { page }),
          ...(limit && { limit }),
          ...(searchData && { filter: JSON.stringify(searchData) }),
          ...(!!filter_type && !!filter_field && { type: filter_type, field: filter_field })
        }
      });
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  addJobBalance = async (jobId: string, paymentdData: TPayment) => {
    try {
      const response: {
        data: {
          success: boolean;
        };
      } = await axiosServices.post(`api/job/add-balance/${jobId}`, paymentdData);
      return response.data.success;
    } catch (error: any) {
      dispatch(
        openSnackbar({
          open: true,
          message: error.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
    }
  };

  markEstimateCompleted = async (jobId: string) => {
    try {
      const response = await axiosServices.put(`api/job/mark-estimate-as-completed/${jobId}`);
      if (response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Estimate Completed Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
      }
    } catch (error: any) {
      dispatch(
        openSnackbar({
          open: true,
          message: error.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
    }
  };

  suspendJob = async (jobId: string, reason: string) => {
    try {
      const response = await axiosServices.post(`api/job/suspend/${jobId}`, { suspend_reason: reason });
      if (response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Job Suspended Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  unsuspendJob = async (jobId: string) => {
    try {
      const response = await axiosServices.put(`api/job/unsuspend/${jobId}`);
      if (response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Job Activated Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  resendSignature = async (jobId: string) => {
    try {
      const response = await axiosServices.post(`api/job/resend-customer-signature-request/${jobId}`);
      if (response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Send Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  submitJobCompleteSignature = async (companyId: string, jobId: string, customer_sign_for_job_completed: string) => {
    try {
      const response: IResponseWithEstimates = await axiosServices.post(`api/job/job-completed/customer-signature/${jobId}`, {
        company_id: companyId,
        customer_sign_for_job_completed: customer_sign_for_job_completed
      });
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Signature Accepted Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  getMaterialsUsed = async (jobId: string) => {
    try {
      const response: TResponseWMaterialsUsed = await axiosServices.get(`api/job/materials/${jobId}`);
      if (response.data && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  addNote = async (jobId: string, data: { type: 'office_to_worker_notes' | 'worker_to_office_notes'; text: string }) => {
    try {
      const response: TResponseWNotes = await axiosServices.post(`api/job/add-note/${jobId}`, data);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Note added Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  editNote = async (jobId: string, data: { type: 'office_to_worker_notes' | 'worker_to_office_notes'; text: string; id: string }) => {
    try {
      const response: TResponseWNotes = await axiosServices.put(`api/job/update-note/${jobId}`, data);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Note Edited Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  deleteNote = async (jobId: string, data: { id: string; type: 'office_to_worker_notes' | 'worker_to_office_notes' }) => {
    try {
      const response = await axiosServices.put(`api/job/delete-note/${jobId}`, data);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Note Deleted Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  sendNoteToOffice = async (jobId: string, note: string) => {
    try {
      const response: TResponseWNotes = await axiosServices.post(`api/job/send-note-to-office/${jobId}`, { note });
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Note added Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
}

const JobServicesInstance = new JobServices();
export default JobServicesInstance;
