import { Typography } from '@mui/material';
import { snakeCaseToTitleCase } from 'utils/common';
import { TMeasurementUnits } from './forms/JobOptionItem/types.jobItems';

const RenderMeasurementsUnits = ({
  measurementData,
  measure,
  showUnitsOnProposal
}: {
  measurementData: TMeasurementUnits[];
  measure: string;
  showUnitsOnProposal: boolean;
}) => {
  const total = measurementData.reduce((total, currentMeasurement) => {
    return (
      total +
      Object.values(currentMeasurement)
        .map((each) => {
          if (!each) return 1;
          return each;
        })
        .reduce((multiplication, current) => {
          return multiplication * current;
        }, 1)
    );
  }, 0);

  return (
    <div>
      <div className="min-w-max flex items-center flex-wrap sm:flex-nowrap">
        {measurementData.map((eachMeasurement, eachMeasurementIndex, eachMeasurementArray) => {
          if (!!eachMeasurement.length && !eachMeasurement.width && !eachMeasurement.height) return eachMeasurement.length;
          return (
            <div key={eachMeasurementIndex} className="min-w-max flex justify-end sm:justify-center items-center">
              <div>
                <span>{`(`}&nbsp;</span>
                {Object.values(eachMeasurement).map((eachMeasurementValue, eachMeasurementValueIndex, eachMeasurementValueArray) => {
                  return (
                    <>
                      {eachMeasurementValue}'&nbsp;
                      {eachMeasurementValueArray.length - 1 !== eachMeasurementValueIndex && <strong>x&nbsp;</strong>}
                    </>
                  );
                })}
                <span>{`)`}</span>
              </div>
              {eachMeasurementArray.length - 1 !== eachMeasurementIndex && <strong className="mx-2">+</strong>}
            </div>
          );
        })}
        <div className="min-w-max flex justify-center items-end">
          <strong className="mx-2">=</strong>
          <span>{total}</span>
          <Typography className="mx-2" variant="body2">
            {snakeCaseToTitleCase(measure)}
          </Typography>
        </div>
      </div>
      {showUnitsOnProposal ? 'Units will be shown in proposal' : ''}
    </div>
  );
};

export default RenderMeasurementsUnits;
