import { CircularProgress, Typography } from '@mui/material';

const CircularLoader = (props: { text?: string }) => {
  return (
    <div className="flex items-center space-x-4">
      <CircularProgress color="secondary" className="text-sm" />

      <Typography className="text-xl" color="textSecondary">
        {props?.text ? props.text : 'Loading...'}
      </Typography>
    </div>
  );
};

export default CircularLoader;
