import { MenuItem, Select, Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { TDateFilter } from 'pages/Dashboard/types/types.dashboard';

const JobReportDateFilter = ({
  dateFilters,
  selectedFilter,
  setSelectedFilter,
  isJobCompleted = false
}: {
  dateFilters: { label: string; value: string }[];
  selectedFilter: TDateFilter;
  setSelectedFilter: (value: TDateFilter) => void;
  isJobCompleted?: boolean;
}) => {
  return (
    <div className="w-full space-y-4">
      <Select
        name="filter_value"
        className="w-full"
        value={selectedFilter.filter_value}
        onChange={(event) => setSelectedFilter({ ...selectedFilter, filter_value: event.target.value })}
      >
        {dateFilters?.map((singleData: { label: string; value: string }, index: number) => (
          <MenuItem key={index} value={singleData.value}>
            {singleData.label}
          </MenuItem>
        ))}
      </Select>
      {/*-----------------------Date Range Fields-------------------- */}
      {selectedFilter.filter_value === 'date_range' && (
        <Stack direction="row" divider={<div className="flex flex-row items-center"> &#8210;</div>} className="space-x-2 w-full">
          <DatePicker
            className="w-full"
            label="Start Date"
            value={dayjs(selectedFilter.start_date)}
            onChange={(newValue) => newValue && setSelectedFilter({ ...selectedFilter, start_date: newValue.toISOString() })}
            disablePast={!isJobCompleted}
            maxDate={dayjs(selectedFilter.end_date)}
          />
          <DatePicker
            className="w-full"
            label="End Date"
            value={dayjs(selectedFilter.end_date)}
            onChange={(newValue) => newValue && setSelectedFilter({ ...selectedFilter, end_date: newValue.toISOString() })}
            minDate={dayjs(selectedFilter.start_date)}
            disableFuture={isJobCompleted}
          />
        </Stack>
      )}
    </div>
  );
};
export default JobReportDateFilter;
