// material-ui
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import LogoMain from './logo/LogoMain';

// loader style
const LoaderWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: -22,
  left: 0,
  zIndex: 2001,
  width: '100%',
  '& > * + *': {
    marginTop: theme.spacing(2)
  }
}));

// ==============================|| Loader ||============================== //

export interface LoaderProps extends LinearProgressProps {}

const Loader = () => {
  const [scale, setScale] = useState<number>(1);

  // Function to toggle the scale value between 0 and 1
  const toggleScale = () => {
    setScale(scale === 1 ? 1.2 : 1);
  };

  useEffect(() => {
    const intervalId = setInterval(toggleScale, 800); // Change scale every second
    return () => {
      clearInterval(intervalId); // Clear the interval when the component unmounts
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale]);

  return (
    <LoaderWrapper>
      <LinearProgress color="primary" />
      <div className="h-[100dvh] w-full flex justify-center items-center backdrop-blur-sm backdrop">
        <motion.div
          animate={{ scale }} // Use the 'scale' state to control the animation
          transition={{ duration: 1 }} // Animation duration
        >
          <LogoMain />
        </motion.div>
      </div>
    </LoaderWrapper>
  );
};

export default Loader;
