import { Alert, Step, StepLabel, Stepper } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { TJobCompleteData } from 'components/tables/interfaces/jobTableInterface';
import useAuth from 'hooks/useAuth';
import { TCustomerData } from 'pages/customers/types/type.customersPage';
import { Ref, useRef, useState } from 'react';
import JobServicesInstance from 'services/services.jobs';
import { dispatch, useSelector } from 'store';
import { clearLogs } from 'store/reducers/customReducers/slice.logs';
import AddEstimateForm from './AddEstimateForm';
import { AddEstimationForm as AddEstimateOptionsForm } from './AddEstimationForm';
import AddJobForm from './AddJobForm';

export type TEstimateFormRef = { next: () => void };

const JobFormWizard = (
  props: { existingData?: TCustomerData; isEditMode?: boolean; customerData?: TCustomerData; onSuccess?: () => void },
  ref: any
) => {
  //------------Constants-------------------
  const estimateFormRef = useRef<TEstimateFormRef>();
  const customerAction = useSelector((state) => state.customerActions);
  const { user } = useAuth();
  const steps = [`Add ${customerAction.action_title}`, 'Add Estimate Options'];
  const [activeStep, setActiveStep] = useState(0);
  const [jobId, setJobId] = useState('');
  const [assignedEstimator, setAssignedEstimator] = useState<string | null>(null);

  //-----------------useQuery--------------
  const { data: singleJobData } = useQuery({
    queryKey: ['single_job_data', jobId],
    queryFn: () => JobServicesInstance.getSingleJobs(jobId),
    enabled: activeStep === 1
  });

  //----------------Handlers-------------------
  const handleEstimateFormSubmit = (jobId: string, assignedEstimatorId?: string) => {
    setAssignedEstimator(assignedEstimatorId ?? null);
    setJobId(jobId);
    setActiveStep((prev) => prev + 1);
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return customerAction.action_title === 'Estimate' ? (
          <AddEstimateForm
            ref={estimateFormRef as Ref<TEstimateFormRef>}
            customerData={props.customerData}
            onSubmitSuccess={handleEstimateFormSubmit}
          />
        ) : (
          customerAction.action_title === 'Job' && (
            <AddJobForm
              existingData={{} as unknown as TJobCompleteData}
              customerData={props.customerData}
              onSubmitSuccess={handleEstimateFormSubmit}
            />
          )
        );

      case 1:
        if (customerAction.action_title === 'Job' || (assignedEstimator?.length && user?._id === assignedEstimator)) {
          dispatch(clearLogs());
          return (
            !!singleJobData && (
              <AddEstimateOptionsForm
                jobId={jobId}
                jobName={singleJobData?.job.job_name}
                defaultTaxRate={singleJobData.job.customer?.default_tax_rate as number}
                onSuccess={props?.onSuccess}
                isEditMode={!!singleJobData?.job.estimations?.options.length}
                existingData={singleJobData?.job.estimations}
                previousStates={singleJobData?.job.previous_states}
                customerData={props.customerData}
              />
            )
          );
        } else {
          return (
            <div className="w-full flex items-center justify-center">
              <Alert severity="error">
                You are not assign to this job. If you want to complete this job first assign this job to your self.{' '}
              </Alert>
            </div>
          );
        }
      default:
        return null;
    }
  };

  return (
    <>
      <div className="w-full flex justify-center my-6">
        <Stepper activeStep={activeStep} className="md:w-1/2 w-full p-2">
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel className="text-xs">{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      {getStepContent(activeStep)}
    </>
  );
};

export default JobFormWizard;
