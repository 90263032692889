import { Card, CardContent, Skeleton, Typography } from '@mui/material';
import { GoogleMap, InfoWindow, Marker, MarkerClusterer, useLoadScript } from '@react-google-maps/api';
import { useQuery } from '@tanstack/react-query';
import car_marker_true from 'assets/images/icons/truck-green.png';
import car_marker_cluster from 'assets/images/icons/truck-primary.png';
import car_marker_false from 'assets/images/icons/truck-red.png';
import car_marker_idol from 'assets/images/icons/truck-yellow.png';
import CircularLoader from 'components/loaders/circularLoader';
import useAuth from 'hooks/useAuth';
import useConfig from 'hooks/useConfig';
import useTimezone from 'hooks/useTimezone';
import moment from 'moment';
import DashboardWidgetCard from 'pages/Dashboard/components/DashboardWidgetCard';
import { TGpsCoordinates } from 'pages/Reports/types/types.report';
import { useEffect, useMemo, useRef, useState } from 'react';
import DashboardServicesInstance from 'services/services.dashboard';
import PageConfigurationServicesInstance from 'services/services.pageConfigurations';
import { storedDataInIndexedDB } from 'utils/common';
import { googleMapsApiKey, indexedDbStoreNames } from 'utils/constants';
import GrossIncome from './components/GrossIncome';
import JobSuccessMetrics from './components/JobSuccessMetrics';
import LifecycleStatusGraph from './components/LifecycleStatusGraph';
import OpenInvoice from './components/OpenInvoice';
import { TDashboardGraphData, TDateFilter } from './types/types.dashboard';

const mapContainerStyle = {
  width: '100%',
  height: '70dvh'
};

const userActivityStatusToCarIcon = {
  0: car_marker_false,
  1: car_marker_true,
  2: car_marker_idol
};

const defaultLocation = { center: { lat: Number(40.7128), lng: Number(-74.006) }, all: [] };

const clusterStyles = [
  {
    url: car_marker_cluster, // Your custom cluster icon path
    height: 50,
    width: 50,
    textColor: '#ffffff',
    textSize: 12
  },
  {
    url: car_marker_cluster,
    height: 60,
    width: 60,
    textColor: '#ffffff',
    textSize: 14
  },
  {
    url: car_marker_cluster,
    height: 70,
    width: 70,
    textColor: '#ffffff',
    textSize: 16
  }
];

type TWorkerCardProps = {
  workerData: {
    key: string;
    position: {
      lat: number;
      lng: number;
    };
    title: string;
    icon: string;
    last_online: string;
  };
};

function WorkerMarkerCard({ workerData }: TWorkerCardProps) {
  const themeConfig = useConfig();
  const formatetimezone = useTimezone();

  const timeAgoString = (ts: string) => {
    const d = moment.duration(moment().diff(moment(ts)));
    return `${d.days()} days ${d.hours()} hours ${d.minutes()} minutes ago`;
  };

  return (
    <Card className="bg-transparent relative flex flex-col items-center w-full !p-0" variant="elevation" elevation={0}>
      <CardContent
        className={`flex flex-col items-start gap-2 !p-0 !z-[2] ${themeConfig.mode === 'dark' ? 'dark:bg-gray-800' : 'bg-white'}`}
      >
        <Typography className="font-bold text-lg">{workerData.title ?? ''}</Typography>
        <Typography className="text-base">
          Reported on {formatetimezone(workerData?.last_online ?? '')} ({timeAgoString(workerData?.last_online)})
        </Typography>
      </CardContent>
    </Card>
  );
}

const DashboardPage = () => {
  //-------------------constants-------------
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey ?? ''
  });
  const { user: loggedInUser } = useAuth();
  const mapRef = useRef<google.maps.Map | null>(null);

  const [jobWonResponse, setJobWonResponse] = useState<TDashboardGraphData>();
  const [jobWonDateFilter, setJobWonDateFilter] = useState<TDateFilter>({
    filter_value: 'this_year',
    start_date: String(new Date()),
    end_date: String(new Date())
  });
  const [colorData, setColorData] = useState<{ estimate_created: string; jobs_completed: string }>({
    estimate_created: '',
    jobs_completed: ''
  });

  const [markerMenuAnchorEl, setMarkerMenuAnchorEl] = useState<{
    position: { lat: number; lng: number } | null;
    workerData?: {
      key: string;
      position: {
        lat: number;
        lng: number;
      };
      title: string;
      icon: string;
      last_online: string;
    };
  }>({ position: null });

  const [companySettingsData, setCompanySettingsData] = useState();

  const jobsWonPermission: boolean = !!loggedInUser?.permissions?.includes('getJobWon');
  const gpsPermission: boolean = !!loggedInUser?.permissions?.includes('getGPSCordinates');
  const isSuperAdmin: boolean = loggedInUser?.role === 'super_admin';
  const grossIncomePermission: boolean = !!loggedInUser?.permissions?.includes('getGrossIncome');
  const openInvoicePermission: boolean = !!loggedInUser?.permissions?.includes('getOpenInvoices');

  //--------------------------------useQuery----------------------
  const { data: jobWonData, isFetching: isJobWonDataFteching } = useQuery({
    queryKey: ['job_won', jobWonDateFilter],
    queryFn: () => DashboardServicesInstance.getJobWonData(jobWonDateFilter),
    enabled: jobsWonPermission && !isSuperAdmin
  });

  const { data: dashboardData, isFetched: isDashboardWidgetDataFetched } = useQuery({
    queryKey: ['dashboard_data'],
    queryFn: () => DashboardServicesInstance.getDashboardData(),
    enabled: !isSuperAdmin
  });

  const { data: allUserLocations, isFetched: isLocationDataFetched } = useQuery({
    queryKey: ['GPS_Cordinates'],
    queryFn: () => DashboardServicesInstance.reportData('getGPSCordinates', {} as TDateFilter),
    enabled: !!gpsPermission
  });

  //-------------------------------useEffects---------------------
  useEffect(() => {
    dashboardData &&
      dashboardData.forEach((singleData) => {
        switch (singleData.title) {
          case 'Open Estimates':
            setColorData((prevColorData) => ({ ...prevColorData, estimate_created: singleData.background_color }));
            break;
          case 'Open Work Orders':
            setColorData((prevColorData) => ({ ...prevColorData, jobs_completed: singleData.background_color }));
            break;
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData]);

  useEffect(() => {
    const fetchSettings = async () => {
      const response = await PageConfigurationServicesInstance.getPageConfig();
      const storeDta = await storedDataInIndexedDB(indexedDbStoreNames.settings, response);
      if (storeDta) {
        setCompanySettingsData(storeDta.data);
      }
    };
    if (loggedInUser?.permissions?.includes('getSystemConfiguration')) fetchSettings();
  }, [loggedInUser]);

  const userLocationMarkers = useMemo(() => {
    const allKnownTypeLocations: TGpsCoordinates[] = Array.isArray(allUserLocations) ? allUserLocations : [];

    const filtered = allKnownTypeLocations.filter((location) => {
      const hasValidTime = location.last_time !== null;
      const hasValidLatitude = location.latitude && !isNaN(Number(location.latitude));
      const hasValidLongitude = location.longitude && !isNaN(Number(location.longitude));
      return hasValidTime && hasValidLatitude && hasValidLongitude;
    });

    if (filtered.length === 0) return defaultLocation;

    return {
      center: {
        lat: Number(filtered[0].latitude),
        lng: Number(filtered[0].longitude)
      },
      all: filtered.map((location) => {
        const { latitude, longitude, activity_status, user, last_time } = location;
        let icon = userActivityStatusToCarIcon[activity_status] || car_marker_false;
        if (last_time) {
          if (moment().diff(moment(last_time), 'minutes') <= 5) {
            icon = car_marker_true;
          } else if (moment().diff(moment(last_time), 'minutes') > 5 && moment().diff(moment(last_time), 'minutes') <= 20) {
            icon = car_marker_idol;
          } else {
            icon = car_marker_false;
          }
        }

        return {
          key: user.id,
          position: { lat: Number(latitude), lng: Number(longitude) },
          title: user.name,
          icon,
          last_online: location.last_time
        };
      })
    };
  }, [allUserLocations, userActivityStatusToCarIcon, car_marker_false]);

  useEffect(() => {
    if (!mapRef.current) return;
    if (!userLocationMarkers.all.length) return;

    // Build LatLngBounds for all job markers
    const bounds = new window.google.maps.LatLngBounds();
    userLocationMarkers.all.forEach((marker) => {
      bounds.extend(marker.position);
    });

    // Fit map to these bounds
    mapRef.current.fitBounds(bounds);
  }, [userLocationMarkers]);

  const handleMapLoad = (map: google.maps.Map) => {
    mapRef.current = map;
  };

  return (
    <div className="flex flex-col gap-4">
      {/* ------------------Dashboard Widgets-------------------- */}

      <DashboardWidgetCard dashboardData={dashboardData} isDashboardWidgetDataFetched={isDashboardWidgetDataFetched} />

      <div className="flex sm:flex-row flex-col items-start gap-4 w-full">
        <div className="flex flex-col items-center gap-4 lg:w-1/3 md:1/2 w-full max-h-min">
          {/* ------------------Gross Income-------------------- */}
          {grossIncomePermission && <GrossIncome />}

          {/* ------------------Open Invoice-------------------- */}
          {openInvoicePermission && <OpenInvoice />}

          {/* ------------------Job Success Metrics-------------------- */}
          <JobSuccessMetrics
            isJobWonDataFteching={isJobWonDataFteching}
            jobWonData={jobWonData}
            jobWonDateFilter={jobWonDateFilter}
            setJobWonResponse={setJobWonResponse}
            setJobWonDateFilter={setJobWonDateFilter}
            colorData={colorData}
          />
        </div>

        <div className="flex flex-col w-full h-full items-start gap-4">
          {/* ------------------GPS Cordinates-------------------- */}
          {gpsPermission && (
            <div className="flex w-full rounded-sm">
              {isLoaded && isLocationDataFetched && !!allUserLocations ? (
                <GoogleMap
                  onLoad={handleMapLoad}
                  mapContainerClassName="map-container rounded-md"
                  center={userLocationMarkers.center}
                  zoom={8}
                  key={googleMapsApiKey}
                  mapContainerStyle={mapContainerStyle}
                >
                  <MarkerClusterer
                    averageCenter
                    enableRetinaIcons
                    gridSize={8}
                    options={{
                      styles: clusterStyles,
                      calculator: (markers, numStyles) => {
                        // Extract titles from each marker
                        const markerTitles = markers
                          .map((marker) => marker.getTitle() || '')
                          .filter((t) => t)
                          .join(', ');

                        // Return the cluster properties
                        return {
                          text: String(markers.length), // The number displayed on the cluster
                          index: Math.min(numStyles, markers.length),
                          title: markerTitles // The tooltip shown when hovering over the cluster
                        };
                      }
                    }}
                  >
                    {(clusterer) => {
                      return (
                        <>
                          {userLocationMarkers.all.map((markerProps) => (
                            <Marker
                              key={markerProps.key}
                              position={markerProps.position}
                              title={markerProps.title}
                              icon={markerProps.icon}
                              clusterer={clusterer}
                              onClick={() => {
                                // Center and open InfoWindow
                                if (mapRef.current) {
                                  mapRef.current.panTo(markerProps.position);
                                  mapRef.current.setZoom(20); // If you want to zoom in on click
                                }
                                setMarkerMenuAnchorEl((prev) => {
                                  if (prev.position?.lat === markerProps.position.lat && prev.position?.lng === markerProps.position.lng) {
                                    // Hide if same marker is clicked again
                                    return { position: null };
                                  }
                                  return { position: markerProps.position, workerData: markerProps };
                                });
                              }}
                            />
                          ))}
                          {markerMenuAnchorEl.position && markerMenuAnchorEl.workerData && (
                            <InfoWindow
                              position={markerMenuAnchorEl.position}
                              options={{
                                pixelOffset: new window.google.maps.Size(0, -55)
                              }}
                              onCloseClick={() => setMarkerMenuAnchorEl({ position: null })}
                            >
                              <WorkerMarkerCard workerData={markerMenuAnchorEl.workerData} />
                            </InfoWindow>
                          )}
                        </>
                      );
                    }}
                  </MarkerClusterer>
                </GoogleMap>
              ) : (
                <div className="w-full relative">
                  <div className="absolute top-1/2 left-1/2 w-full flex items-center justify-start">
                    <CircularLoader />
                  </div>
                  <Skeleton variant="rectangular" height={'70dvh'} />
                </div>
              )}
            </div>
          )}

          {/* ------------------Job Won-------------------- */}
          {jobsWonPermission && (
            <div className="sm:flex hidden w-full rounded-md">
              {isJobWonDataFteching === false && !!jobWonResponse ? (
                <LifecycleStatusGraph
                  isJobWonDataFteching={isJobWonDataFteching}
                  companySettings={companySettingsData}
                  jobWonResponse={jobWonResponse}
                  jobWonDateFilter={jobWonDateFilter}
                  colorData={colorData}
                />
              ) : (
                <div className="min-w-full sm:p-4 p-1 shadow-inner ">
                  <Skeleton variant="rectangular" className="h-full" sx={{ minHeight: { sm: '300px', lg: '500px' } }} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
