import { TDateFilter } from 'pages/Dashboard/types/types.dashboard';
import { TServiceReport } from '../types/types.report';
import ServiceReportCard from './ServicesReport/ServiceReportCard';
import ServiceReportGraph from './ServicesReport/ServiceReportGraph';
import { useEffect, useState } from 'react';
import { randomHexColorCode } from 'utils/common';

const ServiceReport = ({
  selectedFilter,
  setSelectedFilter,
  reportData
}: {
  selectedFilter: TDateFilter;
  setSelectedFilter: (value: TDateFilter) => void;
  reportData: TServiceReport;
}) => {
  const [serviceData, setServiceData] = useState<TServiceReport>();
  useEffect(() => {
    const dummyData = reportData.statistics.map((singleJobCatgory: TServiceReport['statistics'][0]) => {
      singleJobCatgory.color = randomHexColorCode();
      return singleJobCatgory;
    });
    setServiceData({ statistics: dummyData, gross_income: reportData.gross_income });
  }, [reportData]);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
      {serviceData && <ServiceReportCard selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} reportData={serviceData} />}
      {!!serviceData?.statistics.length && (
        <div className="col-span-1 lg:col-span-2">
          <ServiceReportGraph reportData={serviceData} />
        </div>
      )}
    </div>
  );
};

export default ServiceReport;
