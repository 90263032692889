import { FilePdfOutlined } from '@ant-design/icons';
import { CardMedia, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';

export const WidgetRenderMedia = (file: File, index: number) => {
  const mimeType = file.type.split('/')[0];

  if (!!mimeType) {
    switch (mimeType) {
      case 'image':
        return (
          <CardMedia
            component={'img'}
            src={URL.createObjectURL(file)}
            alt={`Media ${index + 1}`}
            loading="lazy"
            className="h-full max-h-56"
          />
        );
      case 'video':
        return <CardMedia component={'video'} src={URL.createObjectURL(file)} controls className="h-full" />;
      case 'audio':
        return <CardMedia component={'audio'} src={URL.createObjectURL(file)} controls className="max-h-28 sm:min-w-80 min-w-10" />;
      default:
        return (
          <Stack
            className="border-2 w-full h-full"
            alignItems="center"
            justifyContent="center"
            minHeight={'150px'}
            minWidth={'250px'}
            height="100%"
            sx={{ gap: 1 }}
          >
            <IconButton sx={{ fontSize: '40px' }}>
              <FilePdfOutlined />
            </IconButton>
            <Typography variant="body2" color="textSecondary" className="text-md font-semibold break-all text-center mx-3">
              {file.name}.{mimeType[1]}
            </Typography>
          </Stack>
        );
    }
  }
};
