import { CloseCircleFilled } from '@ant-design/icons';
import {
  AppBar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  IconButton,
  Slide,
  Toolbar,
  Typography
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, useEffect, useState } from 'react';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type TExpensesPermissionsProps = {
  title: string;
  open: boolean;
  expensePermissionData: string[];
  existingPermissions: string[];
  onSave: (arg0: string[]) => void;
  onClose: () => void;
};

type ReportItem = {
  value: string;
  label: string;
};

const ExpensesPermissions = (props: TExpensesPermissionsProps) => {
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);

  useEffect(() => {
    if (props.existingPermissions) {
      setSelectedPermissions(props.existingPermissions);
    }
  }, [props.existingPermissions]);

  const convertArray = (): ReportItem[] => {
    return props.expensePermissionData
      .filter((eachPermission) => !['createExpense', 'getExpenses'].includes(eachPermission))
      .map((item) => {
        const words = item
          .replace('get', '')
          .replace(/([A-Z])/g, ' $1')
          .trim();

        const label = words.charAt(0).toUpperCase() + words.slice(1);
        if (label === 'Expenses') {
          return { value: item, label: 'Expense Page' };
        }

        return { value: item, label: label };
      });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, permission: string) => {
    setSelectedPermissions((prevSelected) => {
      const newPermission = [...prevSelected];
      if (checked) {
        return [...newPermission, permission];
      } else {
        return newPermission.filter((singleMaterial) => singleMaterial !== permission);
      }
    });
  };

  const handleSave = () => {
    const isExpensePermissionSelected = selectedPermissions.some(
      (singlePermission) =>
        !['createExpense', 'getExpenses'].includes(singlePermission) && props.expensePermissionData.includes(singlePermission)
    );
    let dummyExpensePermission = new Set(selectedPermissions);

    isExpensePermissionSelected ? dummyExpensePermission.add('createExpense') : dummyExpensePermission.delete('createExpense');
    props.onSave(Array.from(dummyExpensePermission));
    props.onClose();
  };
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setSelectedPermissions((prevSelectedPermission) => {
        return [
          ...prevSelectedPermission,
          ...props.expensePermissionData.filter(
            (singleExpensePermission) =>
              singleExpensePermission !== 'getExpenses' && !prevSelectedPermission.includes(singleExpensePermission)
          )
        ];
      });
    } else {
      setSelectedPermissions((prevSelectedPermission) => {
        return prevSelectedPermission.filter(
          (singleSelectedPermission) =>
            singleSelectedPermission === 'getExpenses' || !props.expensePermissionData.includes(singleSelectedPermission)
        );
      });
    }
  };
  return (
    <Dialog maxWidth="xs" fullWidth open TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <div className="w-full flex items-center text-lg">{props.title}</div>
          <IconButton edge="start" color="inherit" onClick={() => props.onClose()} aria-label="close">
            <CloseCircleFilled />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <FormGroup aria-label="expense" row={true} className="flex flex-col w-full">
          <FormControlLabel
            key="select_all"
            control={
              <Checkbox
                checked={
                  !props.expensePermissionData.some(
                    (eachExpensePermission) =>
                      eachExpensePermission !== 'getExpenses' && !selectedPermissions.includes(eachExpensePermission)
                  )
                }
                onChange={(event) => handleSelectAll(event, event.target.checked)}
              />
            }
            label={<Typography className="font-semibold">All Expense</Typography>}
          />
          {convertArray()?.map((eachExpensePermission) => {
            return (
              <FormControlLabel
                key={eachExpensePermission.label}
                control={
                  <Checkbox
                    checked={selectedPermissions.includes(eachExpensePermission.value)}
                    onChange={(event) => handleCheckboxChange(event, event.target.checked, eachExpensePermission.value)}
                  />
                }
                label={eachExpensePermission.label}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExpensesPermissions;
