import { PlusOutlined } from '@ant-design/icons';
import { Button, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import Loader from 'components/Loader';
import CircularLoader from 'components/loaders/circularLoader';
import AddExpensePopup from 'components/popups/AddExpensePopup';
import CustomDataTable, { rowsPerPageOptions } from 'components/tables/CustomDataTable';
import { motion } from 'framer-motion';
import moment from 'moment';
import { IAddExpensePopup, TExpenseData } from 'pages/expenses/types/types.expense';
import { useMemo, useState } from 'react';
import ExpenseServicesInstance from 'services/services.expense';
import { formateData, snakeCaseToTitleCase } from 'utils/common';
import { useEffect } from 'react';
const JobExpense = ({ data, isGlobalExpense }: { data: { id: string; name: string }; isGlobalExpense?: boolean }) => {
  //-----------------------------Constants------------------------------
  const [addExpensePopup, setAddExpensePopup] = useState<IAddExpensePopup>({
    action: { open: false },
    data: { ...data, isGlobalExpense: true }
  });
  useEffect(() => {
    setAddExpensePopup((prevState) => ({
      ...prevState,
      data: { ...prevState.data, isGlobalExpense: isGlobalExpense ?? false }
    }));
  }, [isGlobalExpense]);
  const handleAddExpense = (isExpensePopup: boolean) => {
    setAddExpensePopup({ ...addExpensePopup, action: { open: isExpensePopup } });
  };
  const [paginationData, setPaginationData] = useState({ page: 0, rowsPerPage: rowsPerPageOptions[0] });

  const columns = useMemo<ColumnDef<TExpenseData>[]>(
    () => [
      {
        accessorFn: (row) => row.date,
        id: 'expense_date',
        header: () => <span>Expense Date</span>,
        cell: ({ row }) => <span>{moment(row.original.date).format('MM/DD/YYYY') ?? '-'}</span>
      },
      {
        accessorFn: (row) => row?.job?.name ?? '-',
        id: 'job_name',
        header: () => <span>Job Name</span>
      },
      {
        accessorFn: (row) => row?.created_by?.name ?? '-',
        id: 'expense_user',
        header: () => <span>Expense User</span>
      },
      {
        accessorFn: (row) => snakeCaseToTitleCase(row.expense_type) ?? '-',
        id: 'expense_type',
        header: () => <span>Expense Type</span>
      },
      {
        accessorFn: (row) => row?.description ?? '-',
        id: 'description',
        header: () => <span>Description</span>
      },
      {
        accessorFn: (row) => (row.price !== undefined && row.price !== null ? formateData(row.price, '$') : '-'),
        id: 'price',
        header: () => <span>Price</span>
      },
      {
        accessorFn: (row) => (row.reimbursed_expense ? 'Yes' : 'No'),
        id: 'reimbursed_expense',
        header: () => <span>Reimbursed Expense</span>
      },
      {
        accessorFn: (row) => row?.reimbursed_user?.name ?? '-',
        id: 'reimbursed_user',
        header: () => <span>Reimbursed User</span>
      },
      {
        accessorFn: (row) =>
          row.reimbursed_amount !== undefined && row.reimbursed_amount !== null ? formateData(Number(row.reimbursed_amount), '$') : '-',
        id: 'reimbursed_amount',
        header: () => <span>Reimbursed Amount</span>
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  //-------------------------Header------------------------
  const ExpenseHeader = () => {
    return (
      <Stack direction="row" className="justify-between px-6 my-6">
        <div className="flex items-center space-x-3">{/*Filter*/}</div>
        <Button startIcon={<PlusOutlined />} onClick={() => handleAddExpense(true)} variant="contained">
          Add Expense
        </Button>
      </Stack>
    );
  };
  const handleChangePagination = (page: number, rowsPerPage: number) => {
    setPaginationData({ page, rowsPerPage });
  };
  //--------------------------useQuery------------------------
  const {
    data: allExpenseData,
    refetch: refetchExpense,
    isLoading: isExpenseFetchLoading
  } = useQuery({
    queryKey: ['expense_data', paginationData],
    queryFn: () =>
      ExpenseServicesInstance.getAllExpense(paginationData, {
        search: [
          [
            {
              field_name: 'job.id',
              field_value: data.id ?? '',
              operator: 'exactmatch'
            }
          ]
        ]
      })
  });

  return isExpenseFetchLoading ? (
    <Loader />
  ) : (
    <div className="space-y-2">
      <motion.div
        className="w-full"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.7, ease: 'easeInOut' }}
      >
        <ExpenseHeader />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        className="flex flex-col items-start space-y-4 w-full"
      >
        {!isExpenseFetchLoading ? (
          <CustomDataTable
            data={allExpenseData.data.expenses || []}
            columns={columns}
            count={allExpenseData?.data?.count}
            isDataLoading={isExpenseFetchLoading}
            onPaginationChange={handleChangePagination}
          />
        ) : (
          <CircularLoader />
        )}
      </motion.div>
      {addExpensePopup.action.open && (
        <AddExpensePopup {...addExpensePopup} onClose={() => handleAddExpense(false)} onSave={refetchExpense} />
      )}
    </div>
  );
};

export default JobExpense;
