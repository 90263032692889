import { createSlice } from '@reduxjs/toolkit';

// types
type TFollowUpActions = {
  job_id: string;
  customer: { id: string; name?: string };
};

// initial state
const initialState: TFollowUpActions = {
  job_id: '',
  customer: { id: '', name: '' }
};

// ==============================|| SLICE ||============================== //

const followUpActions = createSlice({
  name: 'followup_actions',
  initialState,
  reducers: {
    setFollowUpJobId(state, action) {
      state.job_id = action.payload;
    },
    setFollowUpCustomer(state, action) {
      state.customer = action.payload;
    }
  }
});

export default followUpActions.reducer;

export const { setFollowUpJobId, setFollowUpCustomer } = followUpActions.actions;
