// import TUserData from 'components/tables/interfaces/userTableInterface';

import { AxiosRequestConfig } from 'axios';
import { ISearch } from 'components/filters/SearchFilter';
import TUserData from 'components/tables/interfaces/userTableInterface';
import { TUserLogs } from 'pages/Settings/types/types.userPopup';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { ThemeMode } from 'types/config';
import axiosServices from 'utils/axios';
import { IResponse, IResponseWithEstimators, IResponseWithUsers } from './types/types.services';

class UserServices {
  private availableRoles = [
    // move to constants
    { value: 'estimator', label: 'Estimator' },
    { value: 'field_worker', label: 'Field Worker' },
    { value: 'office_worker', label: 'Office Worker' },
    { value: 'company_admin', label: 'Company Admin' }
  ];

  loginAsAnotherUser = async (userEmail: string) => {
    const loginAsAnotherUserObject = { email: userEmail, login_as_another_user: true };
    try {
      const response: IResponse = await axiosServices.post('api/auth/login/', loginAsAnotherUserObject, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  getAllUsers = async (paginationData?: { page: number; rowsPerPage: number }, searchData?: ISearch) => {
    const page = paginationData && paginationData?.page + 1;
    const limit = paginationData && paginationData?.rowsPerPage;
    try {
      const response: IResponseWithUsers = await axiosServices.get('api/user', {
        params: {
          ...(page && { page }),
          ...(limit && { limit }),
          ...(searchData && { filter: JSON.stringify(searchData) })
        },
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  getUser = async (userId: string) => {
    try {
      const response: any = await axiosServices.get(`api/user/${userId}`, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  deleteUser = async (userId: string, userFirstName?: string) => {
    try {
      const response: IResponseWithUsers = await axiosServices.delete(`api/user/${userId}`, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `${userFirstName} was successfully deleted`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: `Unable to delete ${userFirstName}.`,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true,

          severity: 'error'
        })
      );
    }
  };

  createUser = async (userData: TUserData) => {
    try {
      const response: IResponseWithUsers = await axiosServices.post(`api/user/`, userData, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `${this.availableRoles.find((role) => role.value === userData.role)?.label} ${
              userData.first_name
            } was successfully regitered`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  editUser = async (userId: string, userData: TUserData) => {
    try {
      const response: IResponseWithUsers = await axiosServices.put(`api/user/${userId}`, userData, {
        locationNeeded: false
      } as AxiosRequestConfig);

      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `${this.availableRoles.find((role) => role.value === userData.role)?.label}: ${userData.first_name}'s record updated`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
    }
  };

  switchThemeMode = async (userId: string, mode: ThemeMode) => {
    try {
      const response: IResponseWithUsers = await axiosServices.patch(`api/user/switch-theme-mode/${userId}`, { theme_mode: mode }, {
        locationNeeded: true
      } as AxiosRequestConfig);

      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Theme mode updated successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
    }
  };

  getUserLogs = async (userId: string) => {
    try {
      const response = await axiosServices.get(`api/user/logs/${userId}`);
      if (response && response.data.success) {
        return response.data.data as { logs: Array<TUserLogs>; name: string };
      }
    } catch (error) {
      console.log(error);
      openSnackbar({
        open: true,
        message: `Unable to get user Logs.`,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      });
    }
  };

  getAllEstimator = async (searchData?: ISearch) => {
    try {
      const response: IResponseWithEstimators = await axiosServices.get(`api/user/estimators`, {
        params: {
          ...(searchData && { filter: JSON.stringify(searchData) })
        },
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
}

const UserServicesInstance = new UserServices();
export default UserServicesInstance;
