import { getIn, useFormik } from 'formik';
// third party
import { Button, Card, CardContent, FormHelperText, Grid, ImageListItem, InputLabel, Modal, Stack, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { DateTimePicker, LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';

import trimFc from 'utils/trimFc';
import * as Yup from 'yup';

import { CloseCircleFilled, DeleteOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Autocomplete, Divider, IconButton, ImageList, ImageListItemBar } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useQuery } from '@tanstack/react-query';
import FormSubmissionAlertMessage from 'components/FormSubmissionAlertMessage';
import MainCard from 'components/MainCard';
import { TScheduleSMSData } from 'components/tables/interfaces/jobTableInterface';
import { universalRenerMedia } from 'components/UniversalRenderMedia';
import dayjs, { Dayjs } from 'dayjs';
import useAuth from 'hooks/useAuth';
import moment from 'moment';
import { TCustomerData } from 'pages/customers/types/type.customersPage';
import { useEffect, useState } from 'react';
import FileUploadServiceInstance from 'services/services.fileUpload';
import UserServicesInstance from 'services/services.users';

// --------------------------types-------------------
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
type AddScheduleSMSFormProps = {
  openSheduleSMS: boolean;
  setOpenSheduleSMS: (u: any) => void;
  onAddScheduleSMSFromSubmit: (values: TScheduleSMSData) => void;
  onClose: () => void;
  data: {
    sheduleSMSValues?: TScheduleSMSData;
    customer: TCustomerData;
    selectedUserId?: string;
    message?: string;
    scheduleDateAndTime?: string | null;
  };
};
type TContact = {
  id: string;
  phone: string;
  phone_country_code: string;
  first_name: string;
  last_name: string;
  type: string;
};

const AddScheduleSMSForm = (props: AddScheduleSMSFormProps) => {
  // --------------------------constants-------------------
  const theme = useTheme();
  const { user } = useAuth();
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [conatctList, setContactList] = useState<TContact[]>();

  // --------------------------useQuery-------------------

  const { data: allUsers } = useQuery({
    queryKey: ['all_users'],
    queryFn: () =>
      UserServicesInstance.getAllUsers(undefined, {
        search: [
          [
            {
              field_name: 'status',
              field_value: 1,
              operator: 'exactmatch'
            }
          ]
        ]
      })
  });

  // --------------------------formik-------------------
  const formik = useFormik<TScheduleSMSData>({
    initialValues: { schedule_sms: true } as TScheduleSMSData,
    validationSchema: Yup.object().shape({
      to: props.openSheduleSMS ? Yup.array().of(Yup.string()).required('This field is required') : Yup.string().nullable(),
      message: props.openSheduleSMS ? Yup.string().required('This field is required') : Yup.string().nullable(),
      regards:
        !!conatctList && conatctList.some((singleContact) => singleContact.id === selectedUserId && singleContact.type === 'customer')
          ? Yup.string().required('This field is required')
          : Yup.string().nullable(),
      scheduled_date_and_time: props.openSheduleSMS ? Yup.string().required('This field is required') : Yup.string().nullable()
    }),

    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      setSubmitting(true);
      await handleFormSubmit(values);
      setSubmitting(false);
    }
  });
  // --------------------------handlers-------------------

  const handleFormSubmit = async (values: TScheduleSMSData) => {
    props.onAddScheduleSMSFromSubmit(values);
    return;
  };
  const handleCloseSheduleSMS = () => props.setOpenSheduleSMS(false);
  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setIsImageUploading(true);
      const files = event.target.files;
      const FilesData = Object.values(files);
      const newImagesData: string[] = [...(formik.values.attachments ?? [])];
      try {
        const promise = FilesData.map((eachFile) => {
          return FileUploadServiceInstance.uploadFile(eachFile).then((response) => {
            if (response) newImagesData.push(response.data);
          });
        });

        await Promise.all(promise);
      } catch (error) {
        console.error('An unexpected error occurred:', error);
      } finally {
        formik.setFieldValue('attachments', newImagesData);
        setIsImageUploading(false);
      }
    }
  };
  const handleRemoveSelectedfile = (index: number) => {
    if (!!formik.values?.attachments?.length) {
      const dummyData = [...formik.values.attachments];
      dummyData.splice(index, 1);
      formik.setFieldValue('attachments', dummyData);
    }
  };
  const handleToChange = (event: React.SyntheticEvent<Element, Event>, newValue: TContact) => {
    setSelectedUserId(newValue.id);
    formik.setFieldValue('to', [`${newValue.phone_country_code}${newValue.phone}`]);
    if (newValue.type === 'customer') formik.setFieldValue('regards', user?.company_name);
  };
  // --------------------------useEffect-------------------
  useEffect(() => {
    const dummyContactList: TContact[] = [];
    if (!!allUsers?.users.length) {
      dummyContactList.push(
        ...allUsers.users.map((singleUser) => ({
          id: singleUser._id ?? '',
          phone: singleUser.phone ?? '',
          phone_country_code: singleUser.phone_country_code ?? '',
          first_name: singleUser.first_name ?? '',
          last_name: singleUser.last_name ?? '',
          type: 'user'
        }))
      );
      dummyContactList.push({
        id: props.data.customer._id ?? '',
        phone: props.data.customer.phone1,
        phone_country_code: props.data.customer.phone1_country_code ?? '',
        first_name: props.data.customer.first_name,
        last_name: props.data.customer.last_name,
        type: 'customer'
      });
    }
    setContactList(dummyContactList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUsers]);

  useEffect(() => {
    if (!!conatctList?.length) {
      if (!!props.data.sheduleSMSValues && !!Object.keys(props.data.sheduleSMSValues).length) {
        formik.setValues(props.data.sheduleSMSValues);

        const selectedContact = conatctList?.find(
          (singleData) => `${singleData?.phone_country_code}${singleData?.phone}` === props.data.sheduleSMSValues?.to[0]
        );
        setSelectedUserId(selectedContact?.id);
        return;
      }
      if (props.data.selectedUserId) {
        const selectedContact = conatctList?.find((singleData) => singleData.id === props.data.selectedUserId);
        formik.setFieldValue('to', [`${selectedContact?.phone_country_code}${selectedContact?.phone}`]);
        setSelectedUserId(props.data.selectedUserId);
      }
    }
    if (props.data.message) {
      formik.setFieldValue('message', props.data.message);
    }
    !!props.data.scheduleDateAndTime
      ? formik.setFieldValue('scheduled_date_and_time', moment(props.data.scheduleDateAndTime).subtract(1, 'hour'))
      : formik.setFieldValue('scheduled_date_and_time', moment());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conatctList]);

  useEffect(() => {
    console.log('formik.errors::::', formik.errors);
  }, [formik.errors]);

  return (
    <Modal
      open={props.openSheduleSMS}
      onClose={handleCloseSheduleSMS}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form noValidate onSubmit={formik.handleSubmit}>
        <MainCard
          title="Schedule Text Message"
          modal
          darkTitle
          content={false}
          secondary={
            <IconButton
              onClick={() => {
                props?.onClose();
              }}
            >
              <CloseCircleFilled />
            </IconButton>
          }
        >
          <CardContent>
            <Grid container spacing={3.5}>
              {!!conatctList && conatctList?.length > 0 && (
                <Grid item xs={12} md={12}>
                  <InputLabel htmlFor="send_to">To*</InputLabel>
                  <Autocomplete
                    id="send_to"
                    value={selectedUserId ? conatctList?.find((singleData) => singleData.id === selectedUserId) : ({} as TContact)}
                    disableClearable
                    getOptionLabel={(option) =>
                      !!Object.keys(option)?.length
                        ? option.type === 'user'
                          ? `${option.first_name} ${option.last_name} ${option.phone}`
                          : `Customer: ${option.first_name} ${option.last_name} ${option.phone}`
                        : ''
                    }
                    onChange={handleToChange}
                    isOptionEqualToValue={(option, optionValue) =>
                      !!Object.keys(option)?.length && !!Object.keys(optionValue)?.length && option.phone === optionValue.phone
                    }
                    options={conatctList}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder=""
                        sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                      />
                    )}
                  />
                  {formik.touched.to && formik.errors.to && (
                    <FormHelperText error id="helper-text-send_to">
                      {formik.errors.to}
                    </FormHelperText>
                  )}
                </Grid>
              )}

              <Grid item xs={12} md={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="message">Message*</InputLabel>
                  <TextField
                    id="message"
                    fullWidth
                    placeholder="Message"
                    name="message"
                    multiline
                    rows={5}
                    onChange={trimFc(formik)}
                    value={formik.values?.message}
                  />
                  {formik.touched.message && formik.errors.message && (
                    <FormHelperText error id="helper-text-message">
                      {formik.errors.message}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>

              {!!conatctList &&
                conatctList.some((singleContact) => singleContact.id === selectedUserId && singleContact.type === 'customer') && (
                  <Grid item xs={12} md={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="regards">Regards*</InputLabel>
                      <TextField
                        id="regards"
                        fullWidth
                        placeholder="regards"
                        name="regards"
                        multiline
                        rows={3}
                        onChange={trimFc(formik)}
                        value={formik.values?.regards}
                      />
                      {formik.touched.regards && formik.errors.regards && (
                        <FormHelperText error id="helper-text-regards">
                          {formik.errors.regards}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                )}

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} className="flex space-x-5 items-center">
                    <input
                      style={{ display: 'none' }}
                      id="upload-job-completion"
                      type="file"
                      accept="image/*,video/*"
                      multiple
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileUpload(event)}
                    />
                    <label htmlFor="upload-job-completion">
                      <Button
                        component="span"
                        startIcon={isImageUploading ? <LoadingOutlined /> : <UploadOutlined />}
                        disabled={isImageUploading}
                        variant="dashed"
                      >
                        Upload
                      </Button>
                    </label>
                  </Grid>
                  {!!formik.values.attachments && (
                    <Grid item xs={12}>
                      <ImageList cols={5} rowHeight={'auto'} className="space-x-1 space-y-1">
                        {formik.values.attachments?.map((singleFileUrl: string, index: number) => (
                          <ImageListItem key={index} component={Card} className="border border-1-black">
                            {universalRenerMedia(singleFileUrl, index)}

                            <ImageListItemBar
                              className="p-1 bg-transparent"
                              position="top"
                              actionIcon={
                                <DeleteOutlined className="font-lg text-red-500" onClick={() => handleRemoveSelectedfile(index)} />
                              }
                              actionPosition="right"
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                    </Grid>
                  )}
                  <Grid />
                </Grid>
              </Grid>

              {/* -----------------------------------------Scheduled Date & Time------------------------------ */}
              <Grid item className="space-y-2" xs={12} sm={6}>
                <InputLabel htmlFor="scheduled_time">Schedule Date & Time</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    className="w-full"
                    value={dayjs(formik.values.scheduled_date_and_time)}
                    minDate={dayjs(new Date())}
                    maxDate={!!props.data.scheduleDateAndTime ? dayjs(props.data.scheduleDateAndTime) : undefined}
                    maxDateTime={!!props.data.scheduleDateAndTime ? dayjs(props.data.scheduleDateAndTime) : undefined}
                    onChange={(newValue: Dayjs | null) => {
                      formik.setFieldValue(
                        'scheduled_date_and_time',
                        newValue !== null && newValue?.toString() !== 'Invalid Date' ? newValue.toISOString() : ''
                      );
                    }}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock
                    }}
                  />
                  {getIn(formik.touched, 'scheduled_date_and_time') && getIn(formik.errors, 'scheduled_date_and_time') && (
                    <FormHelperText error id="helper-text-first_name">
                      {getIn(formik.errors, 'scheduled_date_and_time')}
                    </FormHelperText>
                  )}
                </LocalizationProvider>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <div className="w-full flex flex-row items-center justify-center my-3 space-x-3">
            {FormSubmissionAlertMessage(formik)}

            <Button variant="contained" disabled={formik.isSubmitting} type="submit" startIcon={formik.isSubmitting && <LoadingOutlined />}>
              Submit
            </Button>
          </div>
        </MainCard>
      </form>
    </Modal>
  );
};

export default AddScheduleSMSForm;
