import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  FileOutlined,
  FileTextOutlined,
  SendOutlined,
  UserOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router';
import { containsIncompleteJob, getResponseStatus, isWorkerReassigned, snakeCaseToTitleCase } from 'utils/common';
import { jobStates } from 'utils/constants';
import CustomTooltip from './CustomTooltip';
import useTimezone from 'hooks/useTimezone';
import { TJobAction } from './tables/interfaces/jobTableInterface';

const RenderStatusPreviewIcons = ({
  jobAction,
  jobState,
  status,
  previousStates,
  jobId,
  proposalSentDate,
  invoiceSentDate
}: {
  jobAction: TJobAction[];
  jobState: string;
  status: number;
  previousStates?: string[];
  jobId?: string;
  proposalSentDate?: string;
  invoiceSentDate?: string;
}) => {
  const navigate = useNavigate();

  //todo:add switch case for navigation
  type Phases = { [key: string]: string };

  const phases: Phases = {
    estimationView: '/jobs/view/',
    proposalView: '/proposal/view?job_id=',
    workOrderView: '/jobs/view/',
    invoiceView: '/invoice/view?job_id='
  };

  const handleStateButtonClick = (pathPhase: string) => {
    switch (pathPhase) {
      case 'estimationOptions':
        navigate(`${phases.estimationView}${jobId}/job_status`, {
          state: { estimationView: true, workOrderView: false, invoiceView: false }
        });
        break;
      case 'estimationView':
        navigate(`${phases.estimationView}${jobId}/job_status?rs=estimate_info`, {
          state: { estimationView: true, workOrderView: false, invoiceView: false }
        });
        break;
      case 'proposalView':
        navigate(`${phases.proposalView}${jobId}`);
        break;
      case 'workOrderView':
        navigate(`${phases.workOrderView}${jobId}/job_status`, {
          state: { estimationView: false, workOrderView: true, invoiceView: false }
        });
        break;
      case 'fieldWorkerView':
        navigate(`${phases.workOrderView}${jobId}/job_status?rs=worker_info`, {
          state: { estimationView: false, workOrderView: true, invoiceView: false }
        });
        break;
      case 'invoiceView':
        navigate(`${phases.invoiceView}${jobId}`, { state: { estimationView: false, workOrderView: false, invoiceView: true } });
        break;
      case 'unpaidInvoiceView':
        navigate(`${phases.estimationView}${jobId}/job_status`, {
          state: { estimationView: false, workOrderView: false, invoiceView: true }
        });
        break;
      default:
        console.log('Invalid pathPhase', pathPhase);
        break;
    }
    // navigate(pathPhase);
  };

  const getIncompleteJobActions = () => {
    return jobAction
      .filter(({ is_job_complete, field_worker }) => !is_job_complete && field_worker?.name)
      .map(({ field_worker }) => field_worker.name);
  };

  const formatetimezone = useTimezone();
  const renderIcons = () => {
    let lastProposalSent, lastInvoiceSent;
    if (!!proposalSentDate) lastProposalSent = formatetimezone(proposalSentDate);
    if (!!invoiceSentDate) lastInvoiceSent = formatetimezone(invoiceSentDate);
    const previousLength = previousStates?.length;
    const latestPreviousState = previousStates !== undefined && previousLength !== undefined && previousStates[previousLength - 1];
    switch (jobState) {
      case jobStates.WORK_ORDER:
      case jobStates.ESTIMATION:
      case jobStates.NEED_REVIEW:
        return (
          <>
            <CustomTooltip
              message={
                previousStates !== undefined &&
                previousLength !== undefined &&
                ([jobStates.ESTIMATION_COMPLETED, jobStates.REASSIGNED_ESTIMATOR].includes(previousStates[previousLength - 1])
                  ? snakeCaseToTitleCase(previousStates[previousLength - 1])
                  : jobStates.NEED_REVIEW === jobState
                  ? 'Needs Review'
                  : 'Create')
              }
            >
              <IconButton
                onClick={() => handleStateButtonClick(`estimationOptions`)}
                size="medium"
                className={`${
                  status === 3
                    ? 'bg-black'
                    : previousStates !== undefined &&
                      previousLength !== undefined &&
                      ([jobStates.ESTIMATION_COMPLETED, jobStates.REASSIGNED_ESTIMATOR].includes(previousStates[previousLength - 1])
                        ? 'bg-red-400 hover:bg-red-400'
                        : jobStates.NEED_REVIEW === jobState
                        ? 'bg-cyan-400 hover:bg-cyan-500'
                        : 'bg-yellow-500 hover:bg-yellow-600')
                } rounded-sm`}
                sx={{ padding: '0 !important' }}
              >
                {previousStates !== undefined &&
                  previousLength !== undefined &&
                  ([jobStates.ESTIMATION_COMPLETED, jobStates.REASSIGNED_ESTIMATOR].includes(previousStates[previousLength - 1]) ||
                  jobStates.NEED_REVIEW === jobState ? (
                    <FileTextOutlined className="text-white" />
                  ) : (
                    <EditOutlined className="text-white" />
                  ))}
              </IconButton>
            </CustomTooltip>
          </>
        );
      case jobStates.PROPOSAL_SENT:
      case jobStates.PROPOSAL_APPROVED:
      case jobStates.PROPOSAL_DECLINED:
        return (
          <>
            <CustomTooltip
              message={
                !!previousStates && isWorkerReassigned('estimator', previousStates)
                  ? snakeCaseToTitleCase(jobStates.REASSIGNED_ESTIMATOR)
                  : 'View'
              }
            >
              <IconButton
                onClick={() => handleStateButtonClick(`estimationView`)}
                size="medium"
                className={`${
                  status === 3
                    ? 'bg-black'
                    : !!previousStates && isWorkerReassigned('estimator', previousStates)
                    ? 'bg-red-400 hover:bg-red-500'
                    : 'bg-cyan-400 hover:bg-cyan-500'
                } rounded-sm`}
                sx={{ padding: '0 !important' }}
              >
                {<EyeOutlined className="text-white" />}
              </IconButton>
            </CustomTooltip>
            <CustomTooltip
              message={
                jobStates.PROPOSAL_APPROVED === jobState
                  ? 'Approved'
                  : jobStates.PROPOSAL_DECLINED === jobState
                  ? 'Decline'
                  : jobStates.PROPOSAL_SENT === jobState && lastProposalSent && getResponseStatus(new Date(lastProposalSent), 'proposal')
              }
            >
              <IconButton
                onClick={() => handleStateButtonClick('estimationView')}
                size="medium"
                className={`${
                  status === 3
                    ? 'bg-black'
                    : jobStates.PROPOSAL_APPROVED === jobState
                    ? 'bg-green-400 hover:bg-green-500'
                    : jobStates.PROPOSAL_DECLINED === jobState
                    ? 'bg-red-400 hover:bg-red-500'
                    : jobStates.PROPOSAL_SENT === latestPreviousState &&
                      lastProposalSent &&
                      Math.floor((new Date().getTime() - new Date(lastProposalSent).getTime()) / (1000 * 60 * 60)) > 24
                    ? 'bg-red-600 hover:bg-red-700'
                    : 'bg-cyan-600 hover:bg-cyan-700'
                } rounded-sm`}
                sx={{ padding: '0 !important' }}
              >
                {jobStates.PROPOSAL_APPROVED === jobState ? (
                  <CheckOutlined className="text-white" />
                ) : jobStates.PROPOSAL_DECLINED === jobState ? (
                  <CloseOutlined className="text-white" />
                ) : (
                  jobStates.PROPOSAL_SENT === latestPreviousState &&
                  (lastProposalSent && Math.floor((new Date().getTime() - new Date(lastProposalSent).getTime()) / (1000 * 60 * 60)) > 24 ? (
                    <WarningOutlined className="text-white" />
                  ) : (
                    <SendOutlined className="text-white" />
                  ))
                )}
              </IconButton>
            </CustomTooltip>
          </>
        );
      case jobStates.WORKER_ASSIGNED:
        const isIncompleteJobAction = containsIncompleteJob(jobAction);
        return (
          <>
            <CustomTooltip
              message={
                !!previousStates && isWorkerReassigned('estimator', previousStates)
                  ? snakeCaseToTitleCase(jobStates.REASSIGNED_ESTIMATOR)
                  : 'View'
              }
            >
              <IconButton
                onClick={() => handleStateButtonClick(`estimationView`)}
                size="medium"
                className={`${
                  status === 3
                    ? 'bg-black'
                    : !!previousStates && isWorkerReassigned('estimator', previousStates)
                    ? 'bg-red-400 hover:bg-red-500'
                    : 'bg-cyan-400 hover:bg-cyan-500'
                } rounded-sm`}
                sx={{ padding: '0 !important' }}
              >
                {<EyeOutlined className="text-white" />}
              </IconButton>
            </CustomTooltip>
            <CustomTooltip message={'View'}>
              <IconButton
                onClick={() => handleStateButtonClick(`proposalView`)}
                size="medium"
                className={`${status === 3 ? 'bg-black' : 'bg-cyan-400 hover:bg-cyan-500'} rounded-sm`}
                sx={{ padding: '0 !important' }}
              >
                {<EyeOutlined className="text-white" />}
              </IconButton>
            </CustomTooltip>
            <CustomTooltip
              message={isIncompleteJobAction ? getIncompleteJobActions().join(',') + ' has marked job as not complete' : 'Assigned'}
            >
              <IconButton
                onClick={() => handleStateButtonClick(`workOrderView`)}
                size="medium"
                className={`${
                  status === 3 ? 'bg-black' : isIncompleteJobAction ? 'bg-red-600 hover:bg-red-700' : 'bg-cyan-600 hover:bg-cyan-700'
                }`}
                sx={{ padding: '0 !important' }}
              >
                {isIncompleteJobAction ? <ExclamationCircleOutlined className="text-white" /> : <UserOutlined className="text-white" />}
              </IconButton>
            </CustomTooltip>
          </>
        );
      case jobStates.JOB_COMPLETED:
      case jobStates.INVOICE_UNPAID:
      case jobStates.INVOICE_SENT:
      case jobStates.INVOICE_PAID:
        return (
          <>
            <CustomTooltip
              message={
                !!previousStates && isWorkerReassigned('estimator', previousStates)
                  ? snakeCaseToTitleCase(jobStates.REASSIGNED_ESTIMATOR)
                  : 'View'
              }
            >
              <IconButton
                onClick={() => handleStateButtonClick(`estimationView`)}
                size="medium"
                className={`${
                  status === 3
                    ? 'bg-black'
                    : !!previousStates && isWorkerReassigned('estimator', previousStates)
                    ? 'bg-red-400 hover:bg-red-500'
                    : 'bg-cyan-400 hover:bg-cyan-500'
                } rounded-sm`}
                sx={{ padding: '0 !important' }}
              >
                {<EyeOutlined className="text-white" />}
              </IconButton>
            </CustomTooltip>
            <CustomTooltip message={'View'}>
              <IconButton
                onClick={() => handleStateButtonClick(`proposalView`)}
                size="medium"
                className={`${status === 3 ? 'bg-black' : 'bg-cyan-400 hover:bg-cyan-500'} rounded-sm`}
                sx={{ padding: '0 !important' }}
              >
                {<EyeOutlined className="text-white" />}
              </IconButton>
            </CustomTooltip>
            <CustomTooltip
              message={
                !!previousStates && isWorkerReassigned('field_worker', previousStates)
                  ? snakeCaseToTitleCase(jobStates.REASSIGNED_WORKER)
                  : 'View'
              }
            >
              <IconButton
                onClick={() => handleStateButtonClick(`fieldWorkerView`)}
                size="medium"
                className={`${
                  status === 3
                    ? 'bg-black'
                    : !!previousStates && isWorkerReassigned('field_worker', previousStates)
                    ? 'bg-red-400 hover:bg-red-500'
                    : 'bg-cyan-400 hover:bg-cyan-500'
                } rounded-sm`}
                sx={{ padding: '0 !important' }}
              >
                {<EyeOutlined className="text-white" />}
              </IconButton>
            </CustomTooltip>
            <CustomTooltip
              message={
                jobState === jobStates.INVOICE_PAID
                  ? 'paid'
                  : [jobStates.JOB_COMPLETED, jobStates.INVOICE_UNPAID].includes(jobState)
                  ? jobStates.INVOICE_SENT === latestPreviousState && lastInvoiceSent
                    ? getResponseStatus(new Date(lastInvoiceSent), 'invoice')
                    : 'unpaid'
                  : jobStates.INVOICE_SENT === jobState && lastInvoiceSent && getResponseStatus(new Date(lastInvoiceSent), 'invoice')
              }
            >
              <IconButton
                onClick={() => handleStateButtonClick(jobState === jobStates.INVOICE_PAID ? `invoiceView` : `unpaidInvoiceView`)}
                size="medium"
                className={`${
                  status === 3
                    ? 'bg-black'
                    : jobState === jobStates.INVOICE_PAID
                    ? 'bg-green-400 hover:bg-green-500'
                    : 'bg-red-400 hover:bg-red-500'
                } rounded-sm`}
                sx={{ padding: '0 !important' }}
              >
                {jobState === jobStates.INVOICE_PAID ? (
                  <FileOutlined className="text-white" />
                ) : [jobStates.JOB_COMPLETED, jobStates.INVOICE_UNPAID].includes(jobState) &&
                  jobStates.INVOICE_SENT === latestPreviousState &&
                  lastInvoiceSent ? (
                  Math.floor((new Date().getTime() - new Date(lastInvoiceSent).getTime()) / (1000 * 60 * 60)) > 24 ? (
                    <WarningOutlined className="text-white" />
                  ) : (
                    <SendOutlined className="text-white" />
                  )
                ) : (
                  <FileOutlined className="text-white" />
                )}
              </IconButton>
            </CustomTooltip>
          </>
        );
      default:
        break;
    }
  };
  return <div className="flex items-center gap-2 justify-between">{renderIcons()}</div>;
};
export default RenderStatusPreviewIcons;
