import { snakeCaseToTitleCase } from './common';

export const defaultSettingTab = 'general-settings';
export const defaultReport = 'gross_income_report';
export const timezone = 'Asia/Kolkata';

export const minutesScale = [
  { value: '5', label: '5 minutes' },
  { value: '10', label: '10 minutes' },
  { value: '15', label: '15 minutes' },
  { value: '20', label: '20 minutes' },
  { value: '25', label: '25 minutes' },
  { value: '30', label: '30 minutes' },
  { value: '35', label: '35 minutes' },
  { value: '40', label: '40 minutes' },
  { value: '45', label: '45 minutes' },
  { value: '50', label: '50 minutes' },
  { value: '55', label: '55 minutes' },
  { value: '60', label: '60 minutes' }
];

export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const stripeClientSecret: string = process.env.REACT_APP_STRIPE_CLIENT_SECRET as string;
export const stripePublishKey: string = process.env.REACT_APP_STRIPE_PUBLISH_KEY as string;

export const sampleCustomerImportCsv =
  'https://rampwin-test-bucket.s3.ap-south-1.amazonaws.com/uploads/2025/1/zippfleet-file-import-customers-sample-&-6da1efac-5295-4b66-a0e5-e832828320e1.csv';

export const reportFilterName = {
  gross_income_report: 'getGrossIncome',
  total_invoice_report: 'getInvoiceDetails',
  open_invoice_report: 'getOpenInvoices',
  expenses_report: 'getExpensesReport',
  customer_lead_sources: 'getCustomersAndLeadSources',
  field_worker_jobs_completed: 'getFieldWorkerJobs',
  field_worker_jobs_assigned: 'getFieldWorkerJobs',
  office_administrator_report: 'getOfficeAdministrators',
  gps_coordinate_report: 'getGPSCordinates',
  services_report: 'getServicesReport',
  material_report: 'getSuppliesAndMaterialUsedReport',
  sales_tax_report: 'getSalesTaxReport',
  payroll_report: 'getPayrollReport',
  completed_projects: 'getCompletedProjects',
  accounts_receivable_report: 'getAccountsReceivable'
};

type TConstantsType = {
  REACT_APP_GOOGLE_MAPS_API_KEY: string | undefined;
  CURR_ENVIRONMENT: string | undefined;
  REACT_APP_BASE_URL: string | undefined;
};

export const constants: TConstantsType = {
  REACT_APP_GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  CURR_ENVIRONMENT: process.env.CURR_ENVIRONMENT,
  REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL
};

export const modules = [
  { name: 'User', value: 'user' },
  { name: 'Customer', value: 'customer' },
  { name: 'Job', value: 'job' },
  { name: 'Job Categories', value: 'job_categories' },
  { name: 'Services & Products', value: 'services_and_products' },
  { name: 'Estimate', value: 'estimate' },
  { name: 'Invoices', value: 'invoices' },
  { name: 'Configuration', value: 'configuration' },
  { name: 'Payroll', value: 'payroll' }
];

export const permissionsTypes = [
  { name: 'Create', value: 'create' },
  { name: 'Read', value: 'read' },
  { name: 'Update', value: 'update' },
  { name: 'Delete', value: 'delete' },
  { name: 'Admin', value: 'admin' }
];

type TDefaultPermissionsForRoles = {
  office_worker: {
    none: Array<string>;
    read: Array<string>;
    write: Array<string>;
    company_admin: Array<string>;
  };
  field_worker: {
    none: Array<string>;
    read: Array<string>;
    write: Array<string>;
    company_admin: Array<string>;
  };
  estimator: {
    none: Array<string>;
    read: Array<string>;
    write: Array<string>;
    admin: Array<string>;
  };
  company_admin: {
    none: Array<string>;
    read: Array<string>;
    write: Array<string>;
    company_admin: Array<string>;
  };
};

const adminPermissions = [
  'user',
  'add_payment',
  'user_log',
  'customers',
  'job_categories',
  'services_and_products',
  'invoices',
  'configuration',
  'payroll',
  'open_job',
  'estimate'
];

export const defaultPermissionsForRoles: TDefaultPermissionsForRoles = {
  office_worker: {
    none: ['user', 'add_payment', 'reports', 'calendar', 'training_videos', 'expenses'],
    read: ['user_log', 'payroll'],
    write: ['customers', 'open_job', 'job_categories', 'services_and_products', 'invoices', 'estimate', 'configuration'],
    company_admin: []
  },
  field_worker: {
    none: [
      'user',
      'add_payment',
      'user_log',
      'customers',
      'job_categories',
      'services_and_products',
      'invoices',
      'configuration',
      'reports',
      'calendar',
      'training_videos',
      'expenses'
    ],
    read: ['payroll'],
    write: ['open_job', 'estimate'],
    company_admin: []
  },
  estimator: {
    none: [
      'user',
      'add_payment',
      'user_log',
      'customers',
      'job_categories',
      'services_and_products',
      'invoices',
      'configuration',
      'reports',
      'calendar',
      'training_videos',
      'expenses'
    ],
    read: ['payroll'],
    write: ['open_job', 'estimate'],
    admin: []
  },
  company_admin: {
    none: [],
    read: [],
    write: [],
    company_admin: adminPermissions
  }
};
export const availableRoles = [
  { value: 'estimator', label: 'Estimator' },
  { value: 'field_worker', label: 'Field Worker' },
  { value: 'office_worker', label: 'Office Worker' }
];
export const userRoles = [
  { value: 'estimator', label: 'Estimator' },
  { value: 'field_worker', label: 'Field Worker' },
  { value: 'office_worker', label: 'Office Worker' },
  { value: 'company_admin', label: 'Company Admin' }
];

export const availableStatus = [
  { value: 1, label: 'Active' },
  { value: 2, label: 'Inactive' },
  { value: 0, label: 'Deleted' }
];

export const notifyProposalApprovedItems = [
  { value: '0', label: 'None' },
  { value: '1', label: 'Only Mine' },
  { value: '2', label: 'All' }
];

export const notificationSendingChannels = [
  { value: 2, label: 'Email' },
  { value: 1, label: 'SMS' }
];

export const payType = [
  { value: 'hourly', label: 'Hourly' },
  { value: 'salary', label: 'Salary' },
  { value: 'commission', label: 'Commission Based' }
];

export const punchTypes = [
  { value: 'auto/system_calculator', label: 'Auto/System Calculator' },
  { value: 'clock_in/clock_out', label: 'Clock In / Clock Out' }
];

export const phoneTypes = [
  { value: 'mobile', label: 'Mobile' },
  { value: 'office', label: 'Office' },
  { value: 'home', label: 'Home' }
];

export const actionTypes = [
  { value: 'job', label: 'Job' },
  { value: 'estimate', label: 'Estimate' },
  { value: 'follow_up', label: 'Follow Up' }
];
export const expense_type = [
  { value: 'accounting/legal', label: 'Accounting / Legal' },
  { value: 'advertising/marketing', label: 'Advertising / Marketing' },
  { value: 'amortization', label: 'Amortization' },
  { value: 'bank_fees', label: 'Bank Fees' },
  { value: 'bonuses', label: 'Bonuses' },
  { value: 'car/truck_expenses', label: 'Car / Truck Expenses' },
  { value: 'computer_and_software', label: 'Computer And Software' },
  { value: 'depreciation', label: 'Depreciation' },
  { value: 'donations/contributions', label: 'Donations / Contributions' },
  { value: 'dues_and_subscriptions', label: 'Dues And Subscriptions' },
  { value: 'education/training', label: 'Education / Training' },
  { value: 'equipment_and_shipping', label: 'Equipment And Shipping' },
  { value: 'gas', label: 'Gas' },
  { value: 'gifts', label: 'Gifts' },
  { value: 'insurance', label: 'Insurance' },
  { value: 'interest', label: 'Interest' },
  { value: 'job_supplies/materials', label: 'Job Supplies / Material' },
  { value: 'licence/permits', label: 'Licence / Permits' },
  { value: 'meals_and_entertainment', label: 'Meals And Entertainment' },
  { value: 'office_supplies/equipment', label: 'Office Supplies / Equipment' },
  { value: 'other', label: 'Other' },
  { value: 'payroll_compensate', label: 'Payroll Compensate' },
  { value: 'payroll_taxes', label: 'Payroll Taxes' },
  { value: 'rent_and_utilities', label: 'Rent And Utilities' },
  { value: 'repairs_and_maintenance', label: 'Repairs And Maintenance' },
  { value: 'retirement_plans', label: 'Retirement Plans' },
  { value: 'taxes', label: 'Taxes' },
  { value: 'travel', label: 'Travel' }
];
export const jobTypes = [
  { value: 'product', label: 'Product' },
  { value: 'service', label: 'Service' }
];
export const measure = [
  { value: 'square_inch', label: 'Square Inch' },
  { value: 'square_foot', label: 'Square Foot' },
  { value: 'square_yard', label: 'Square Yard' },
  { value: 'cubic_inch', label: 'Cubic Inch' },
  { value: 'cubic_foot', label: 'Cubic Foot' },
  { value: 'cubic_yard', label: 'Cubic Yard' },
  { value: 'linear_inch', label: 'Linear Inch' },
  { value: 'linear_foot', label: 'Linear Foot' },
  { value: 'linear_yard', label: 'Linear Yard' },
  { value: 'unit', label: 'Unit' }
];

export const worker_commission = [10, 20, 30, 40];
export const customer_cost_per_unit = [150, 200, 250];
export const statesData = [
  { value: 'alabama', label: 'Alabama' },
  { value: 'alaska', label: 'Alaska' },
  { value: 'arizona', label: 'Arizona' },
  { value: 'arkansas', label: 'Arkansas' },
  { value: 'california', label: 'California' },
  { value: 'colorado', label: 'Colorado' },
  { value: 'connecticut', label: 'Connecticut' },
  { value: 'delaware', label: 'Delaware' },
  { value: 'florida', label: 'Florida' },
  { value: 'georgia', label: 'Georgia' },
  { value: 'hawaii', label: 'Hawaii' },
  { value: 'idaho', label: 'Idaho' },
  { value: 'illinois', label: 'Illinois' },
  { value: 'indiana', label: 'Indiana' },
  { value: 'iowa', label: 'Iowa' },
  { value: 'kansas', label: 'Kansas' },
  { value: 'kentucky', label: 'Kentucky' },
  { value: 'louisiana', label: 'Louisiana' },
  { value: 'maine', label: 'Maine' },
  { value: 'maryland', label: 'Maryland' },
  { value: 'massachusetts', label: 'Massachusetts' },
  { value: 'michigan', label: 'Michigan' },
  { value: 'minnesota', label: 'Minnesota' },
  { value: 'mississippi', label: 'Mississippi' },
  { value: 'missouri', label: 'Missouri' },
  { value: 'montana', label: 'Montana' },
  { value: 'nebraska', label: 'Nebraska' },
  { value: 'nevada', label: 'Nevada' },
  { value: 'new hampshire', label: 'New Hampshire' },
  { value: 'new jersey', label: 'New Jersey' },
  { value: 'new mexico', label: 'New Mexico' },
  { value: 'new york', label: 'New York' },
  { value: 'north carolina', label: 'North Carolina' },
  { value: 'north dakota', label: 'North Dakota' },
  { value: 'ohio', label: 'Ohio' },
  { value: 'oklahoma', label: 'Oklahoma' },
  { value: 'oregon', label: 'Oregon' },
  { value: 'pennsylvania', label: 'Pennsylvania' },
  { value: 'rhode island', label: 'Rhode Island' },
  { value: 'south carolina', label: 'South Carolina' },
  { value: 'south dakota', label: 'South Dakota' },
  { value: 'tennessee', label: 'Tennessee' },
  { value: 'texas', label: 'Texas' },
  { value: 'utah', label: 'Utah' },
  { value: 'vermont', label: 'Vermont' },
  { value: 'virginia', label: 'Virginia' },
  { value: 'washington', label: 'Washington' },
  { value: 'west virginia', label: 'West Virginia' },
  { value: 'wisconsin', label: 'Wisconsin' },
  { value: 'wyoming', label: 'Wyoming' }
];

export const referral = [
  { value: 'google', label: 'Google' },
  { value: 'facebook', label: 'Facebook' },
  { value: 'instagram', label: 'Instagram' },
  { value: 'friend/neighbour', label: 'Friends/Neighbour' },
  { value: 'other', label: 'Other' }
];
export const timezones = [
  'Africa/Abidjan',
  'Africa/Accra',
  'Africa/Addis_Ababa',
  'Africa/Algiers',
  'Africa/Asmara',
  'Africa/Bamako',
  'Africa/Bangui',
  'Africa/Banjul',
  'Africa/Bissau',
  'Africa/Blantyre',
  'Africa/Brazzaville',
  'Africa/Bujumbura',
  'Africa/Cairo',
  'Africa/Casablanca',
  'Africa/Ceuta',
  'Africa/Conakry',
  'Africa/Dakar',
  'Africa/Dar_es_Salaam',
  'Africa/Djibouti',
  'Africa/Douala',
  'Africa/El_Aaiun',
  'Africa/Freetown',
  'Africa/Gaborone',
  'Africa/Harare',
  'Africa/Johannesburg',
  'Africa/Juba',
  'Africa/Kampala',
  'Africa/Khartoum',
  'Africa/Kigali',
  'Africa/Kinshasa',
  'Africa/Lagos',
  'Africa/Libreville',
  'Africa/Lome',
  'Africa/Luanda',
  'Africa/Lubumbashi',
  'Africa/Lusaka',
  'Africa/Malabo',
  'Africa/Maputo',
  'Africa/Maseru',
  'Africa/Mbabane',
  'Africa/Mogadishu',
  'Africa/Monrovia',
  'Africa/Nairobi',
  'Africa/Ndjamena',
  'Africa/Niamey',
  'Africa/Nouakchott',
  'Africa/Ouagadougou',
  'Africa/Porto-Novo',
  'Africa/Sao_Tome',
  'Africa/Tripoli',
  'Africa/Tunis',
  'Africa/Windhoek',
  'America/Adak',
  'America/Anchorage',
  'America/Anguilla',
  'America/Antigua',
  'America/Araguaina',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca',
  'America/Argentina/Cordoba',
  'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Aruba',
  'America/Asuncion',
  'America/Atikokan',
  'America/Bahia',
  'America/Bahia_Banderas',
  'America/Barbados',
  'America/Belem',
  'America/Belize',
  'America/Blanc-Sablon',
  'America/Boa_Vista',
  'America/Bogota',
  'America/Boise',
  'America/Cambridge_Bay',
  'America/Campo_Grande',
  'America/Cancun',
  'America/Caracas',
  'America/Cayenne',
  'America/Cayman',
  'America/Chicago',
  'America/Chihuahua',
  'America/Costa_Rica',
  'America/Creston',
  'America/Cuiaba',
  'America/Curacao',
  'America/Danmarkshavn',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Denver',
  'America/Detroit',
  'America/Dominica',
  'America/Edmonton',
  'America/Eirunepe',
  'America/El_Salvador',
  'America/Fort_Nelson',
  'America/Fortaleza',
  'America/Glace_Bay',
  'America/Goose_Bay',
  'America/Grand_Turk',
  'America/Grenada',
  'America/Guadeloupe',
  'America/Guatemala',
  'America/Guayaquil',
  'America/Guyana',
  'America/Halifax',
  'America/Havana',
  'America/Hermosillo',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Inuvik',
  'America/Iqaluit',
  'America/Jamaica',
  'America/Juneau',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Kralendijk',
  'America/La_Paz',
  'America/Lima',
  'America/Los_Angeles',
  'America/Lower_Princes',
  'America/Maceio',
  'America/Managua',
  'America/Manaus',
  'America/Marigot',
  'America/Martinique',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Menominee',
  'America/Merida',
  'America/Metlakatla',
  'America/Mexico_City',
  'America/Miquelon',
  'America/Moncton',
  'America/Monterrey',
  'America/Montevideo',
  'America/Montserrat',
  'America/Nassau',
  'America/New_York',
  'America/Nipigon',
  'America/Nome',
  'America/Noronha',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Ojinaga',
  'America/Panama',
  'America/Pangnirtung',
  'America/Paramaribo',
  'America/Phoenix',
  'America/Port-au-Prince',
  'America/Port_of_Spain',
  'America/Porto_Velho',
  'America/Puerto_Rico',
  'America/Punta_Arenas',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Recife',
  'America/Regina',
  'America/Resolute',
  'America/Rio_Branco',
  'America/Santarem',
  'America/Santiago',
  'America/Santo_Domingo',
  'America/Sao_Paulo',
  'America/Scoresbysund',
  'America/Sitka',
  'America/St_Barthelemy',
  'America/St_Johns',
  'America/St_Kitts',
  'America/St_Lucia',
  'America/St_Thomas',
  'America/St_Vincent',
  'America/Swift_Current',
  'America/Tegucigalpa',
  'America/Thule',
  'America/Thunder_Bay',
  'America/Tijuana',
  'America/Toronto',
  'America/Tortola',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yakutat',
  'America/Yellowknife',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Macquarie',
  'Antarctica/Mawson',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'Arctic/Longyearbyen',
  'Asia/Aden',
  'Asia/Almaty',
  'Asia/Amman',
  'Asia/Anadyr',
  'Asia/Aqtau',
  'Asia/Aqtobe',
  'Asia/Ashgabat',
  'Asia/Atyrau',
  'Asia/Baghdad',
  'Asia/Bahrain',
  'Asia/Baku',
  'Asia/Bangkok',
  'Asia/Barnaul',
  'Asia/Beirut',
  'Asia/Bishkek',
  'Asia/Brunei',
  'Asia/Chita',
  'Asia/Choibalsan',
  'Asia/Colombo',
  'Asia/Damascus',
  'Asia/Dhaka',
  'Asia/Dili',
  'Asia/Dubai',
  'Asia/Dushanbe',
  'Asia/Famagusta',
  'Asia/Gaza',
  'Asia/Hebron',
  'Asia/Ho_Chi_Minh',
  'Asia/Hong_Kong',
  'Asia/Hovd',
  'Asia/Irkutsk',
  'Asia/Jakarta',
  'Asia/Jayapura',
  'Asia/Jerusalem',
  'Asia/Kabul',
  'Asia/Kamchatka',
  'Asia/Karachi',
  'Asia/Kathmandu',
  'Asia/Khandyga',
  'Asia/Kolkata',
  'Asia/Krasnoyarsk',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Asia/Kuwait',
  'Asia/Macau',
  'Asia/Magadan',
  'Asia/Makassar',
  'Asia/Manila',
  'Asia/Muscat',
  'Asia/Nicosia',
  'Asia/Novokuznetsk',
  'Asia/Novosibirsk',
  'Asia/Omsk',
  'Asia/Oral',
  'Asia/Phnom_Penh',
  'Asia/Pontianak',
  'Asia/Pyongyang',
  'Asia/Qatar',
  'Asia/Qostanay',
  'Asia/Qyzylorda',
  'Asia/Riyadh',
  'Asia/Sakhalin',
  'Asia/Samarkand',
  'Asia/Seoul',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Asia/Srednekolymsk',
  'Asia/Taipei',
  'Asia/Tashkent',
  'Asia/Tbilisi',
  'Asia/Tehran',
  'Asia/Thimphu',
  'Asia/Tokyo',
  'Asia/Tomsk',
  'Asia/Ulaanbaatar',
  'Asia/Urumqi',
  'Asia/Ust-Nera',
  'Asia/Vientiane',
  'Asia/Vladivostok',
  'Asia/Yakutsk',
  'Asia/Yangon',
  'Asia/Yekaterinburg',
  'Asia/Yerevan',
  'Atlantic/Azores',
  'Atlantic/Bermuda',
  'Atlantic/Canary',
  'Atlantic/Cape_Verde',
  'Atlantic/Faroe',
  'Atlantic/Madeira',
  'Atlantic/Reykjavik',
  'Atlantic/South_Georgia',
  'Atlantic/St_Helena',
  'Atlantic/Stanley',
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Broken_Hill',
  'Australia/Currie',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Hobart',
  'Australia/Lindeman',
  'Australia/Lord_Howe',
  'Australia/Melbourne',
  'Australia/Perth',
  'Australia/Sydney',
  'Canada/Atlantic',
  'Canada/Central',
  'Canada/Eastern',
  'Canada/Mountain',
  'Canada/Newfoundland',
  'Canada/Pacific',
  'Europe/Amsterdam',
  'Europe/Andorra',
  'Europe/Astrakhan',
  'Europe/Athens',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/Bratislava',
  'Europe/Brussels',
  'Europe/Bucharest',
  'Europe/Budapest',
  'Europe/Busingen',
  'Europe/Chisinau',
  'Europe/Copenhagen',
  'Europe/Dublin',
  'Europe/Gibraltar',
  'Europe/Guernsey',
  'Europe/Helsinki',
  'Europe/Isle_of_Man',
  'Europe/Istanbul',
  'Europe/Jersey',
  'Europe/Kaliningrad',
  'Europe/Kiev',
  'Europe/Kirov',
  'Europe/Lisbon',
  'Europe/Ljubljana',
  'Europe/London',
  'Europe/Luxembourg',
  'Europe/Madrid',
  'Europe/Malta',
  'Europe/Mariehamn',
  'Europe/Minsk',
  'Europe/Monaco',
  'Europe/Moscow',
  'Europe/Oslo',
  'Europe/Paris',
  'Europe/Podgorica',
  'Europe/Prague',
  'Europe/Riga',
  'Europe/Rome',
  'Europe/Samara',
  'Europe/San_Marino',
  'Europe/Sarajevo',
  'Europe/Saratov',
  'Europe/Simferopol',
  'Europe/Skopje',
  'Europe/Sofia',
  'Europe/Stockholm',
  'Europe/Tallinn',
  'Europe/Tirane',
  'Europe/Ulyanovsk',
  'Europe/Uzhgorod',
  'Europe/Vaduz',
  'Europe/Vatican',
  'Europe/Vienna',
  'Europe/Vilnius',
  'Europe/Volgograd',
  'Europe/Warsaw',
  'Europe/Zagreb',
  'Europe/Zaporozhye',
  'Europe/Zurich',
  'Indian/Antananarivo',
  'Indian/Chagos',
  'Indian/Christmas',
  'Indian/Cocos',
  'Indian/Comoro',
  'Indian/Kerguelen',
  'Indian/Mahe',
  'Indian/Maldives',
  'Indian/Mauritius',
  'Indian/Mayotte',
  'Indian/Reunion',
  'Pacific/Apia',
  'Pacific/Auckland',
  'Pacific/Bougainville',
  'Pacific/Chatham',
  'Pacific/Chuuk',
  'Pacific/Easter',
  'Pacific/Efate',
  'Pacific/Enderbury',
  'Pacific/Fakaofo',
  'Pacific/Fiji',
  'Pacific/Funafuti',
  'Pacific/Galapagos',
  'Pacific/Gambier',
  'Pacific/Guadalcanal',
  'Pacific/Guam',
  'Pacific/Honolulu',
  'Pacific/Kiritimati',
  'Pacific/Kosrae',
  'Pacific/Kwajalein',
  'Pacific/Majuro',
  'Pacific/Marquesas',
  'Pacific/Midway',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Norfolk',
  'Pacific/Noumea',
  'Pacific/Pago_Pago',
  'Pacific/Palau',
  'Pacific/Pitcairn',
  'Pacific/Pohnpei',
  'Pacific/Port_Moresby',
  'Pacific/Rarotonga',
  'Pacific/Saipan',
  'Pacific/Tahiti',
  'Pacific/Tarawa',
  'Pacific/Tongatapu',
  'Pacific/Wake',
  'Pacific/Wallis',
  'UTC'
];
export const characters = 'abcdefghijklmnopqrstuvwxyz';

export const jobStates = {
  ESTIMATION: 'estimation',
  ESTIMATION_COMPLETED: 'estimation_completed',
  NEED_REVIEW: 'need_review',
  PROPOSAL_APPROVED: 'proposal_approved',
  PROPOSAL_DECLINED: 'proposal_declined',
  WORK_ORDER: 'work_order',
  PROPOSAL_SENT: 'proposal_sent',
  WORKER_ASSIGNED: 'worker_assigned',
  JOB_COMPLETED: 'job_completed',
  JOB_NOT_COMPLETED: 'job_not_completed',
  INVOICE_UNPAID: 'invoice_unpaid',
  INVOICE_PAID: 'invoice_paid',
  INVOICE_SENT: 'invoice_sent',
  REASSIGNED_ESTIMATOR: 'reassign_estimator',
  REASSIGNED_WORKER: 'reassign_worker'
};

export const invoiceMessage =
  'It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank you!';
export const invoicePdfMessage = 'Thank you for using our services';
export const proposalMessage =
  'By accepting this proposal, you agree to the terms & conditions. Please see end of document for terms & conditions.';

export const jobNameFormats = {
  JOB_NUMBER: 'job_number',
  TODAY_DATE: "today's_date",
  CUSTOMER_NAME_HYPHRN_DATE: 'customer_name_hyphen_date',
  REVERSE: 'date_hyphen_customer_name',
  CUSTOM_NAME: 'custom_name'
};

export const proposalApprovalMessage =
  "We've been notified that you've approved our estimate. We'll be assigning your job to one of our highly skilled technicians. You'll be receiving a follow-up email with your job details.";
export const declineApprovalMessage = "We've received your reason for declining and will be in touch with your shortly.";
export const editEstimateThankYouMessage = "Thanks! You're Awesome, Seriously!";
export const updatedEstimateAcceptMessage =
  "Your signature has been successfully submitted. We'll follow up with you soon for more details.";
export const updatedEstimateDeclineMessage =
  "Your response has been successfully submitted. We'll follow up with you soon for more details.";
export const dashboardFollowUpsTypes = [
  { label: 'Yours ', value: 'your' },
  { label: 'Unassigned ', value: 'unassigned' },
  { label: 'Assigned ', value: 'assigned' },
  { label: 'Overdue', value: 'overdue' },
  { label: 'Future', value: 'future' },
  { label: 'Custom', value: 'custom' }
];
export const dashboardEstimatesTypes = [
  { label: 'Today', value: 'today' },
  { label: 'Tomorrow', value: 'tomorrow' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Custom', value: 'custom' },
  { label: 'N/A Date', value: 'none_reminder' }
];
export const dashboardWorkOrderTypes = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Tomorrow', value: 'tomorrow' },
  { label: 'Custom', value: 'custom' },
  { label: 'Range', value: 'range' },
  { label: 'Hide', value: 'hide' },
  { label: 'N/A Date', value: 'none_reminder' }
];
export const dashboardInvoiceTypes = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Custom', value: 'custom' },
  { label: 'Range', value: 'range' }
];
export const dashboardDateTypes = [
  { label: 'Originate Date', value: 'originate_date' },
  { label: 'Scheduled Date', value: 'scheduled_date' }
];
export const reportFilter = [
  { label: 'Gross Income', value: 'gross_income_report' },
  { label: 'Invoices Paid / Unpaid', value: 'total_invoice_report' },
  { label: 'Open Invoices', value: 'open_invoice_report' },
  { label: 'Expenses Report', value: 'expenses_report' },
  { label: 'New Customers / Lead Sources', value: 'customer_lead_sources' },
  { label: 'Field Workers Job Completed', value: 'field_worker_jobs_completed' },
  { label: 'Field Workers Job Assigned', value: 'field_worker_jobs_assigned' },
  { label: 'Office Administrators', value: 'office_administrator_report' },
  { label: 'GPS Coordinates', value: 'gps_coordinate_report' },
  { label: 'Services Report', value: 'services_report' },
  { label: 'Supplies and Material Used Report', value: 'material_report' },
  { label: 'Sales Tax Report', value: 'sales_tax_report' },
  { label: 'Payroll Report', value: 'payroll_report' },
  { label: 'Completed Project Report', value: 'completed_projects' },
  { label: 'Accounts Receivable', value: 'accounts_receivable_report' }
];
export const motionContainer = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
};

export const motionItem = {
  hidden: { y: -20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};

export const phoneType = [
  { value: 'mobile', label: 'Mobile' },
  { value: 'home', label: 'Home' },
  { value: 'office', label: 'Office' },
  { value: 'fax', label: 'Fax' }
];

export const businessTypes = [
  { value: 'Partnership', label: 'Partnership' },
  { value: 'Limited Liability', label: 'Limited Liability' },
  { value: 'Co-operative', label: 'Co-operative' },
  { value: 'Non-profit Corporation', label: 'Non-profit Corporation' },
  { value: 'Corporation', label: 'Corporation' }
];

export const businessIndustries = [
  { value: 'AGRICULTURE', label: 'Agriculture' },
  { value: 'AUTOMOTIVE', label: 'Automotive' },
  { value: 'BANKING', label: 'Banking' },
  { value: 'CONSTRUCTION', label: 'Construction' },
  { value: 'CONSUMER', label: 'Consumer' },
  { value: 'EDUCATION', label: 'Education' },
  { value: 'ELECTRONICS', label: 'Electronics' },
  { value: 'ENGINEERING', label: 'Engineering' },
  { value: 'ENERGY', label: 'Energy' },
  { value: 'FAST_MOVING_CONSUMER_GOODS', label: 'Fast Moving Consumer Goods' },
  { value: 'FINANCIAL', label: 'Financial' },
  { value: 'FINTECH', label: 'Fintech' },
  { value: 'FOOD_AND_BEVERAGE', label: 'Food and Beverage' },
  { value: 'GOVERNMENT', label: 'Government' },
  { value: 'HEALTHCARE', label: 'Healthcare' },
  { value: 'HOSPITALITY', label: 'Hospitality' },
  { value: 'INSURANCE', label: 'Insurance' },
  { value: 'JEWELRY', label: 'Jewelry' },
  { value: 'LEGAL', label: 'Legal' },
  { value: 'MANUFACTURING', label: 'Manufacturing' },
  { value: 'MEDIA', label: 'Media' },
  { value: 'NOT_FOR_PROFIT', label: 'Not for Profit' },
  { value: 'OIL_AND_GAS', label: 'Oil and Gas' },
  { value: 'ONLINE', label: 'Online' },
  { value: 'PROFESSIONAL_SERVICES', label: 'Professional Services' },
  { value: 'RAW_MATERIALS', label: 'Raw Materials' },
  { value: 'REAL_ESTATE', label: 'Real Estate' },
  { value: 'RELIGION', label: 'Religion' },
  { value: 'RETAIL', label: 'Retail' },
  { value: 'TECHNOLOGY', label: 'Technology' },
  { value: 'TELECOMMUNICATIONS', label: 'Telecommunications' },
  { value: 'TRANSPORTATION', label: 'Transportation' },
  { value: 'TRAVEL', label: 'Travel' }
];

export const jobPositions = ['Director', 'GM', 'VP', 'CEO', 'CFO', 'General Counsel', 'Other'];

export const defaultCalendarTab = 'job_calendar';

export const indexedDbStoreNames = {
  jobs: 'jobs',
  me: 'me',
  permissions: 'permissions',
  punch: 'punch',
  api: 'api',
  singleJobs: 'singleJobs',
  proposalTemplate: 'proposalTemplate',
  jobCategories: 'jobCategories',
  singleJobCategores: 'singleJobCategores',
  settings: 'settings'
};
export const reminderTypes = [
  { value: '1daybefore', label: '1 Day before' },
  { value: '2daybefore', label: '2 Day before' },
  { value: 'choosecustomedate', label: 'Choose Custom Date' }
];
export const defaultSearch = {
  search: [
    [
      {
        field_name: 'status',
        field_value: 1,
        operator: 'exactmatch'
      }
    ]
  ]
};
export const months = [
  { label: 'January', value: 0 },
  { label: 'February', value: 1 },
  { label: 'March', value: 2 },
  { label: 'April', value: 3 },
  { label: 'May', value: 4 },
  { label: 'June', value: 5 },
  { label: 'July', value: 6 },
  { label: 'August', value: 7 },
  { label: 'September', value: 8 },
  { label: 'October', value: 9 },
  { label: 'November', value: 10 },
  { label: 'December', value: 11 }
];
export const weekdays = [
  { label: 'Sunday', value: 7 },
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 }
];
export const incorrectCaptchaMessage = 'You entered an incorrect CAPTCHA. Please try again';
export const autoAppointmentBookedMessage = 'Your appointment booking was successfull.';
export const estimateWidgetThankYouPageMessage1 = 'Your estimate has been successfully submitted';
export const estimateWidgetThankYouPageMessage2 = "We'll follow up with soon for more details.";
export const logActionList = [
  { label: 'Form submission', value: 'form_submission' },
  { label: 'Customer Added', value: 'customer_added' },
  { label: 'Customer Updated', value: 'customer_updated' },
  { label: 'Customer Deleted', value: 'customer_deleted' },
  { label: 'Customer Viewed', value: 'customer_viewed' },
  { label: 'Estimate Added', value: 'estimate_added' },
  { label: 'Assign Estimator', value: 'assign_estimator' },
  { label: 'Estimated Created', value: 'estimate_created' },
  { label: 'Estimate Updated', value: 'estimate_updated' },
  { label: 'Estimate Completed', value: 'estimate_completed' },
  { label: 'Estimator Re-Assigned', value: 'estimator_reassigned' },
  { label: 'Proposal Emailed', value: 'proposal_emailed' },
  { label: 'Proposal Follow Up Set', value: 'proposal_follow_up_set' },
  { label: 'Proposal Declined', value: 'proposal_declined' },
  { label: 'Proposal Approved', value: 'proposal_approved' },
  { label: 'Field Worker Assigned', value: 'field_worker_assigned' },
  { label: 'Field Worker Re-Assigned', value: 'field_worker_re_assigned' },
  { label: 'Followup Added', value: 'followup_added' },
  { label: 'Followup Update', value: 'followup_update' },
  { label: 'Followup Complete', value: 'followup_complete' },
  { label: 'Followup Not Complete', value: 'followup_not_complete' },
  { label: 'Followup Deleted', value: 'followup_deleted' },
  { label: 'Job Added', value: 'job_added' },
  { label: 'Job Deleted', value: 'job_deleted' },
  { label: 'Job Terminated', value: 'job_terminated' },
  { label: 'Job Completed', value: 'job_completed' },
  { label: 'Invoice Generate', value: 'invoice_generate' },
  { label: 'Invoice Follow Up Set', value: 'invoice_follow_up_set' },
  { label: 'Invoice Emailed', value: 'invoice_emailed' },
  { label: 'Payment Taken', value: 'payment_taken' },
  { label: 'Partial Payment Taken', value: 'partial_payment_taken' },
  { label: 'Send Email', value: 'send_email' },
  { label: 'Send SMS', value: 'send_sms' },
  { label: 'Received SMS', value: 'received_sms' },
  { label: 'Login', value: 'login' },
  { label: 'Logout', value: 'logout' }
];
export const scheduleAreaInfoMessage =
  'Define your work areas to optimize scheduling. Set specific days for each area to streamline appointments and limit travel time. Ideal for service companies managing multiple locations or field workers with set workdays. Not needed for businesses where customers visit a fixed location, like hair salons.';
export const scheduleByDayInfo =
  'This means that you will be available to serve your customers on certain days of the week, but not at specific times. For example, you might be available on Monday, Wednesday, and Friday from 9am to 5pm.';
export const scheduleByTimeBlock =
  'This means that you will be available to serve your customers on certain days of the week, but not at specific times. For example, you might be available on Monday, Wednesday, and Friday from 9am to 5pm.';
export const canCustomerAutoScheduleInfo =
  '﻿Auto scheduling is a feature that allows customers to automatically schedule their appointment as soon as they accepts their proposal. This eleminates the need for administators to manually enter the appointment date and time.';
export const scheduleSmsInfoForEstimator = 'Ensure estimators don’t forget their estimates by sending timely text reminders';
export const scheduleSmsInfoForFieldWorker = 'Ensure field worker don’t forget their job by sending timely text reminders';
export const calendarConfigInstruction = `
    WELCOME TO THE OVERBOOKING CONFIGURATION SECTION OF YOUR CALENDAR.
    Here, you have the flexibility to set limits based on either the number of jobs or the total price of all the jobs for a specific day to manage your bookings effectively. By setting these limits, you can easily visualize the status of your calendar through color-coded borders. Let's walk through how to use this feature:<br/><br/>
    <b>SETTING LIMITS:</b><br/><br/>
    1. CHOOSE CRITERIA: Decide whether you want to set limits based on the number of jobs or the total price of jobs.<br/>
    2. ENTER VALUE: Input the larger limit value first to be checked as the primary condition for a specific day.<br/><br/>
    <b>COLOR-CODED BORDERS:</b><br/><br/>
    1. BOOKED LIMIT Select a color for the border of the calendar day when the primary set limit is reached. For example, choose red to indicate that you are fully booked and can't accommodate any more jobs on that day.<br/>
    2. ALMOST BOOKED LIMIT Optionally, you can choose a second condition to set a different color for the border when you are approaching the larger booking limit. For instance, select orange to signify that you are nearly booked and have limited availability.<br/><br/>
    <b>VISUALIZATION:</b><br/><br/>
    By using color-coded borders, you can easily visualize your booking status at a glance. The borders will help you quickly identify days when you're fully booked or nearing capacity, allowing for efficient scheduling and resource management.<br/><br/>
    <b>EXAMPLE SCENARIO:</b><br/><br/>
    <ul style="list-style-type: disc; padding-left: 20px;">
    <li>If your set limit is $1,000 for a day, and the jobs already booked total $800, you will see the border color change to reflect the status:</li>
    </ul>
    <ul style="list-style-type: circle; padding-left: 40px;">
      <li><b>RED BORDER:</b> Indicates that you have reached the primary booking limit of $1,000.</li>
      <li><b>ORANGE BORDER:</b> Indicates that you are approaching the primary booking limit of $1,000.</li>
    </ul>

    <br/>
    <b>NOTE:</b> Ensure to review and adjust your limits and colors regularly to optimize your booking management and ensure efficient scheduling.<br/><br/>
    Let the system know your days are booked, you can choose job count or the total job price per day.
  `;
export const jobNumberToolTipMessage =
  'The job number represents the current job number. When creating a new job, its job number will be the next sequential value';
export const personalEmailTooltipMessage =
  'If you want to receive notifications at your personal email, just enter it in the personal email field.';
export const companyEmailTooltipMessage = "If you don't fill in your personal email you'll get all notifications your company field.";
export const jobNamesFormate = [
  { value: jobNameFormats.JOB_NUMBER, label: snakeCaseToTitleCase(jobNameFormats.JOB_NUMBER) },
  { value: jobNameFormats.TODAY_DATE, label: snakeCaseToTitleCase(jobNameFormats.TODAY_DATE) },
  { value: jobNameFormats.CUSTOMER_NAME_HYPHRN_DATE, label: snakeCaseToTitleCase(jobNameFormats.CUSTOMER_NAME_HYPHRN_DATE) },
  { value: jobNameFormats.REVERSE, label: snakeCaseToTitleCase(jobNameFormats.REVERSE) },
  { value: jobNameFormats.CUSTOM_NAME, label: snakeCaseToTitleCase(jobNameFormats.CUSTOM_NAME) }
];
export const invoiceCompanyDisplayPaymentInfoMessage =
  'Check this box to include company name and address at the bottom of the invoice for payment purposes. This will inform your customers who they need to make the checks payble to and where they should be mailing their payment.';
export const invoiceCompanyDetailsMessage =
  'Please enter your company name and billing address. This information tells your customers who to make checks payable to and where to mail payments.';
export const venmoClientIdTooltipMessage = [
  'If you are a new merchant, <a href="https://www.paypal.com/unifiedonboarding/entry?products=ppcp" style="font-weight: bold;">sign up for a PayPal business account</a>.',
  'Get client ID by following this link: <a href="https://www.paypal.com/dashboard" style="font-weight: bold;">Log in to Dashboard</a>.',
  'Select Apps & Credentials.',
  ' To create a new project, select Create App.',
  'Copy the client ID and paste it here.'
];
export const hideCheckboxTooltipMessage = 'By Selecting hide, you are only hiding the option when you are assigning a field worker';
export const multiActionJobTypeToolTip =
  'You are about to assign a field worker for the next job action. Can both actions be done simultaneously, or does the prior action need to be completed before assigning a field worker to the next job action?';
export const recurringJobFollowup = 'This Is A Recurring Job, To Add Next Recurrence';
export const sequentialJobActionFollowup = 'Job Action Completed / This is Multi Action Job';

export const importCustomerFields = [
  {
    field_name: 'first_name',
    label: 'first_name',
    required: false
  },
  {
    field_name: 'last_name',
    label: 'last_name',
    required: false
  },
  {
    field_name: 'property_type',
    label: 'property_type',
    required: true
  },
  {
    field_name: 'customer_company_name',
    label: 'customer_company_name',
    required: false
  },
  {
    field_name: 'phone1',
    label: 'phone1',
    required: true
  },
  {
    field_name: 'phone1_country_code',
    label: 'phone1_country_code',
    required: true
  },
  {
    field_name: 'phone1_type',
    label: 'phone1_type',
    required: true
  },
  {
    field_name: 'phone1_extension',
    label: 'phone1_extension',
    required: false
  },
  {
    field_name: 'phone2',
    label: 'phone2',
    required: false
  },
  {
    field_name: 'phone2_country_code',
    label: 'phone2_country_code',
    required: false
  },
  {
    field_name: 'phone2_type',
    label: 'phone2_type',
    required: false
  },
  {
    field_name: 'phone2_extension',
    label: 'phone2_extension',
    required: false
  },
  {
    field_name: 'phone3',
    label: 'phone3',
    required: false
  },
  {
    field_name: 'phone3_country_code',
    label: 'phone3_country_code',
    required: false
  },
  {
    field_name: 'phone3_type',
    label: 'phone3_type',
    required: false
  },
  {
    field_name: 'phone3_extension',
    label: 'phone3_extension',
    required: false
  },
  {
    field_name: 'emails',
    label: 'emails',
    required: true
  },
  {
    field_name: 'address.address1',
    label: 'address.address1',
    required: true
  },
  {
    field_name: 'address.address2',
    label: 'address.address2',
    required: false
  },
  {
    field_name: 'address.cross_street',
    label: 'address.cross_street',
    required: false
  },
  {
    field_name: 'address.city',
    label: 'address.city',
    required: true
  },
  {
    field_name: 'address.state',
    label: 'address.state',
    required: true
  },
  {
    field_name: 'address.zip',
    label: 'address.zip',
    required: true
  },
  {
    field_name: 'address.country',
    label: 'address.country',
    required: true
  },
  {
    field_name: 'bill_to_you',
    label: 'bill_to_you',
    required: false
  },
  {
    field_name: 'billing_contact.first_name',
    label: 'billing_contact.first_name',
    required: false
  },
  {
    field_name: 'billing_contact.last_name',
    label: 'billing_contact.last_name',
    required: false
  },
  {
    field_name: 'billing_contact.emails',
    label: 'billing_contact.emails',
    required: false
  },
  {
    field_name: 'billing_contact.address.address1',
    label: 'billing_contact.address.address1',
    required: true
  },
  {
    field_name: 'billing_contact.address.address2',
    label: 'billing_contact.address.address2',
    required: false
  },
  {
    field_name: 'billing_contact.address.city',
    label: 'billing_contact.address.city',
    required: true
  },
  {
    field_name: 'billing_contact.address.state',
    label: 'billing_contact.address.state',
    required: true
  },
  {
    field_name: 'billing_contact.address.zip',
    label: 'billing_contact.address.zip',
    required: true
  },
  {
    field_name: 'billing_contact.address.country',
    label: 'billing_contact.address.country',
    required: true
  },
  {
    field_name: 'billing_contact.phone1',
    label: 'billing_contact.phone1',
    required: true
  },
  {
    field_name: 'billing_contact.phone1_country_code',
    label: 'billing_contact.phone1_country_code',
    required: true
  },
  {
    field_name: 'billing_contact.phone1_type',
    label: 'billing_contact.phone1_type',
    required: true
  },
  {
    field_name: 'billing_contact.phone1_extension',
    label: 'billing_contact.phone1_extension',
    required: false
  },
  {
    field_name: 'billing_contact.phone2',
    label: 'billing_contact.phone2',
    required: false
  },
  {
    field_name: 'billing_contact.phone2_country_code',
    label: 'billing_contact.phone2_country_code',
    required: false
  },
  {
    field_name: 'billing_contact.phone2_type',
    label: 'billing_contact.phone2_type',
    required: false
  },
  {
    field_name: 'billing_contact.phone2_extension',
    label: 'billing_contact.phone2_extension',
    required: false
  },
  {
    field_name: 'billing_contact.reason',
    label: 'billing_contact.reason',
    required: false
  },
  {
    field_name: 'customer_notification_preference',
    label: 'customer_notification_preference',
    required: false
  },
  {
    field_name: 'referral',
    label: 'referral',
    required: false
  },
  {
    field_name: 'default_tax_rate',
    label: 'default_tax_rate',
    required: false
  },
  {
    field_name: 'private_office_notes',
    label: 'private_office_notes',
    required: false
  }
];
