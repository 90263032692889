import { IconButton, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import JobDateColumn from 'components/columns/JobDateColumn';
import CustomTooltip from 'components/CustomTooltip';
import { ISearch } from 'components/filters/SearchFilter';
import RenderStatusPreviewIcons from 'components/RenderStatusPreviewIcons';
import CustomDataTable, { rowsPerPageOptions } from 'components/tables/CustomDataTable';
import { TJobCompleteData } from 'components/tables/interfaces/jobTableInterface';
import useAuth from 'hooks/useAuth';
import useTimezone from 'hooks/useTimezone';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import JobServicesInstance from 'services/services.jobs';
import { useSelector } from 'store';
import { handleRedirectToGoogleMap, snakeCaseToTitleCase } from 'utils/common';
import { jobStates } from 'utils/constants';

type TCustomerJobDetailsProps = { customerId: string };

const CustomerJobsDetail = (props: TCustomerJobDetailsProps) => {
  const { user } = useAuth();
  const settingsState = useSelector((state) => state.settings.generalSettings);
  const theme = useTheme();
  const navigate = useNavigate();
  const primaryColor = `${theme.palette.primary.main}`;

  const formatDateTime = useTimezone();
  const availabelTabs: { label: string; value: string }[] = [
    {
      label: 'All',
      value: 'all'
    },
    {
      label: 'Estimates',
      value: 'estimates'
    },
    {
      label: 'Work Orders',
      value: 'work-orders'
    }
  ];

  const defaultSearchData = {
    search: [
      [
        {
          field_name: 'status',
          field_value: 1,
          operator: 'exactmatch'
        }
      ],
      [
        {
          field_name: 'customer.id',
          field_value: props.customerId,
          operator: 'exactmatch'
        }
      ]
    ]
  };

  const [paginationData, setPaginationData] = useState({ page: 0, rowsPerPage: rowsPerPageOptions[0] });
  const [searchData, setSearchData] = useState<ISearch>(defaultSearchData);
  const {
    data: allJobsData,
    isLoading: isJobFetching
    // refetch: refetchJobs
  } = useQuery({
    queryKey: ['jobs', searchData, paginationData],
    queryFn: () => JobServicesInstance.getAllJobs(paginationData, searchData),
    enabled: user?.permissions && user?.permissions.includes('getJobs') && !!props.customerId,
    networkMode: 'always'
  });

  const handleChangePagination = (page: number, rowsPerPage: number) => {
    setPaginationData({ page, rowsPerPage });
  };

  const [activeTab, setActiveTab] = useState('all');

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
    switch (newValue) {
      case 'estimates':
        setSearchData({
          search: [
            ...defaultSearchData.search,
            [
              {
                field_value: jobStates.ESTIMATION,
                operator: 'in',
                field_name: 'previous_states'
              }
            ],
            [
              {
                field_value: jobStates.WORK_ORDER,
                operator: 'not_in',
                field_name: 'previous_states'
              }
            ],
            [
              {
                field_value: jobStates.WORKER_ASSIGNED,
                operator: 'not_in',
                field_name: 'previous_states'
              }
            ]
          ]
        });
        break;
      case 'work-orders':
        setSearchData({
          search: [
            ...defaultSearchData.search,
            [
              {
                field_value: [jobStates.WORK_ORDER],
                operator: 'in',
                field_name: 'previous_states'
              },
              {
                field_value: [jobStates.WORKER_ASSIGNED],
                operator: 'in',
                field_name: 'previous_states'
              }
            ]
          ]
        });
        break;
      default:
        setSearchData(defaultSearchData);
        break;
    }
  };

  const columns = useMemo<ColumnDef<TJobCompleteData & { job_followups: number }>[]>(
    () => [
      {
        accessorFn: (row) => row.job_name,
        id: 'job_name',
        cell: ({
          row: {
            original: { _id, job_name, job_followups }
          }
        }) => (
          <div className="flex w-full items-center justify-between">
            <Typography
              sx={{
                '&:hover': {
                  color: primaryColor
                }
              }}
              className={`cursor-pointer`}
              onClick={() => navigate(`/jobs/view/${_id}/job_status`)}
            >
              {job_name}
            </Typography>
            {job_followups > 0 && (
              <CustomTooltip message={'Total Follow Ups'}>
                <IconButton
                  size="small"
                  sx={{
                    backgroundColor: `${theme.palette.error.main} !important`,
                    fontSize: '12px',
                    padding: '1px'
                  }}
                  className=" text-white rounded-2xl w-5 h-5"
                  onClick={(event) => {
                    event.stopPropagation();
                    navigate(`/jobs/view/${_id}/follow_ups`);
                  }}
                >
                  {job_followups}
                </IconButton>
              </CustomTooltip>
            )}
          </div>
        ),
        header: () => <span>Job Name</span>
      },
      {
        accessorFn: (row) => {
          return activeTab === 'estimates' ? row.estimator_instructions ?? '' : row.job_action?.[0]?.job_instructions ?? '';
        },
        id: 'estimator_instructions',
        header: () => <span>{activeTab === 'estimates' ? 'Estimte Instruction' : 'Worker Instructions'}</span>
      },
      {
        accessorFn: (row) => row.address?.address1,
        id: 'address1',
        cell: ({ row }) => (
          <Typography
            onClick={() => {
              if (row.original.address) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { address2, ...address } = row.original.address;
                handleRedirectToGoogleMap(address);
              }
            }}
            sx={{ '&:hover': { color: theme.palette.primary.light }, cursor: 'pointer' }}
          >
            {row.original.address?.address1}
          </Typography>
        ),
        header: () => <span>Address</span>
      },
      {
        accessorFn: (row) => row.address?.city,
        id: 'city',
        cell: (info) => info.getValue(),
        header: () => <span>City</span>
      },

      {
        id: 'created_at',
        cell: ({ row }) =>
          row.original.created_at && (
            <div className="text-xs text-gray-500">
              {formatDateTime(row.original.created_at, {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: settingsState?.time_format === '12hrs'
              })}
            </div>
          ),
        header: () => <span>Orginate Date</span>
      },
      {
        id: 'date',
        cell: ({ row }) => {
          return (
            <div className="text-xs text-gray-500 ">
              {activeTab === 'estimates' ? (
                row.original.estimate_reminder?.scheduled_date_and_time ? (
                  formatDateTime(row.original.estimate_reminder?.scheduled_date_and_time, {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: settingsState?.time_format === '12hrs'
                  })
                ) : (
                  ''
                )
              ) : !!row.original.job_action ? (
                <JobDateColumn jobAction={row.original.job_action} />
              ) : (
                'N/A'
              )}
            </div>
          );
        },
        header: () => {
          return activeTab === 'estimates' ? <span>Estimate Date</span> : <span>Job Date</span>;
        }
      },
      {
        id: 'state',
        cell: ({ row }) => {
          const previousStateLength = row.original.previous_states?.length;
          if (previousStateLength) {
            if (
              row.original.previous_states?.[previousStateLength - 1] === jobStates.REASSIGNED_ESTIMATOR ||
              row.original.previous_states?.[previousStateLength - 1] === jobStates.REASSIGNED_WORKER
            ) {
              return snakeCaseToTitleCase(row.original.previous_states?.[previousStateLength - 1]);
            }
          }
          return snakeCaseToTitleCase(row.original.state as string);
        },
        header: () => <span>Current State</span>
      },
      {
        id: 'status_preview',
        header: () => (
          <div className="flex w-40">
            <div className="ml-3 w-full flex justify-start space-x-9">
              <span>E</span>
              <span>P</span>
              <span>W</span>
              <span>I</span>
            </div>
          </div>
        ),
        cell: ({ row }) => {
          return (
            <div className="flex flex-col items-center p-0">
              <div className="w-full flex justify-start space-x-3">
                <RenderStatusPreviewIcons
                  jobAction={row.original.job_action ?? []}
                  jobState={row.original?.state as string}
                  status={row.original.status!}
                  previousStates={row.original.previous_states}
                  jobId={row.original._id}
                  proposalSentDate={row?.original?.last_proposal_sent}
                  invoiceSentDate={row.original?.last_invoice_sent?.toString()}
                />
              </div>
            </div>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTab]
  );

  return (
    <>
      <div className="w-full p-4">
        <Tabs onChange={handleTabChange} value={activeTab} variant="standard">
          {availabelTabs.map((eachTab) => (
            <Tab label={eachTab.label} value={eachTab.value} />
          ))}
        </Tabs>
      </div>

      <CustomDataTable
        hasPagination
        onPaginationChange={handleChangePagination}
        columns={columns}
        isDataLoading={isJobFetching}
        data={allJobsData?.jobs || []}
        count={allJobsData?.count}
      />
    </>
  );
};

export default CustomerJobsDetail;
