import { Card, CardContent, MenuItem, Select, SelectChangeEvent, Skeleton, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import DashboardServicesInstance from 'services/services.dashboard';
import { formateData } from 'utils/common';
import { timezone } from 'utils/constants';
import { TDateFilter } from '../types/types.dashboard';

const GrossIncome = () => {
  //--------------------constants--------------------
  const dateFilter = [
    { label: `This Year ${new Date().getFullYear()}`, value: 'this_year' },
    { label: 'Last 30 days', value: 'last_30_days' },
    { label: 'Last 10 days', value: 'last_10_days' },
    { label: `Last Year ${new Date().getFullYear() - 1}`, value: 'last_year' },
    { label: 'Date Range', value: 'date_range' }
  ];
  const [grossIncomeDateFilter, setGrossIncomeDateFilter] = useState<TDateFilter>({
    filter_value: 'this_year',
    start_date: `${new Date().getFullYear()}-01-01T00:00:00`,
    end_date: dayjs(new Date().toLocaleString('en-US', { timeZone: timezone })).format('YYYY-MM-DDTHH:mm')
  });

  //--------------------useQuery----------------------
  const { data: grossIncomeData } = useQuery({
    queryKey: ['gross_income', grossIncomeDateFilter.start_date, grossIncomeDateFilter.end_date],
    queryFn: () => DashboardServicesInstance.getGrossIncomeData(grossIncomeDateFilter.start_date, grossIncomeDateFilter.end_date)
  });

  //----------------constants----------
  const financeSummary = [
    { label: 'Sales Tax', value: grossIncomeData?.sales_tax ?? 0, color: '#dc3545', url: '/reports/sales_tax_report' },
    { label: 'PayRoll', value: grossIncomeData?.payrolls ?? 0, color: '#007bff', url: '/payroll' },
    { label: 'Expenses', value: grossIncomeData?.expenses ?? 0, color: '#ff9800', url: '/expenses?filter=this_year' }
  ];

  //---------------------Handlers-----------------
  const handleGrossIncomeDateFilter = (event: SelectChangeEvent<string>) => {
    let date = new Date();
    const today = dayjs(date.toLocaleString('en-US', { timeZone: timezone })).format('YYYY-MM-DDTHH:mm');
    switch (event.target.value) {
      case 'this_year':
        setGrossIncomeDateFilter({
          start_date: `${new Date().getFullYear()}-01-01T00:00:00`,
          end_date: today,
          filter_value: event.target.value
        });

        break;
      case 'last_10_days':
        date.setDate(date.getDate() - 10);
        setGrossIncomeDateFilter({
          start_date: dayjs(date.toLocaleString('en-US', { timeZone: timezone })).format('YYYY-MM-DDTHH:mm'),
          end_date: today,
          filter_value: event.target.value
        });
        break;

      case 'last_30_days':
        date.setDate(date.getDate() - 30);
        setGrossIncomeDateFilter({
          start_date: dayjs(date.toLocaleString('en-US', { timeZone: timezone })).format('YYYY-MM-DDTHH:mm'),
          end_date: today,
          filter_value: event.target.value
        });
        break;
      case 'last_year':
        setGrossIncomeDateFilter({
          start_date: `${new Date().getFullYear() - 1}-01-01T00:00:00`,
          end_date: `${new Date().getFullYear() - 1}-12-31T23:59:59`,
          filter_value: event.target.value
        });
        break;
      case 'date_range':
        setGrossIncomeDateFilter({
          ...grossIncomeDateFilter,
          filter_value: event.target.value
        });
        break;
    }
  };

  return (
    <Card className="w-full shadow-md max-h-min h-full">
      {!!grossIncomeData ? (
        <CardContent className="flex flex-col items-center max-h-min w-full gap-3">
          {/*-----------------------Gross Income Header-------------------- */}
          <div className="flex flex-col items-center gap-2 w-full">
            <Typography textAlign={'center'} className="font-semibold text-2xl text-gray-500 hover:underline">
              <Link to="/reports/gross_income_report">Gross Income</Link>
            </Typography>

            <Typography color={'green'} className="font-semibold text-3xl hover:underline">
              <Link to="/reports/gross_income_report">{formateData(grossIncomeData?.gross_income ?? 0, '$')}</Link>
            </Typography>

            <Select fullWidth name="filter_value" value={grossIncomeDateFilter.filter_value} onChange={handleGrossIncomeDateFilter}>
              {dateFilter?.map((singleData: { label: string; value: string }, index: number) => (
                <MenuItem key={index} value={singleData.value}>
                  {singleData.label}
                </MenuItem>
              ))}
            </Select>
          </div>

          {/*-----------------------Date Range Fields-------------------- */}

          {grossIncomeDateFilter.filter_value === 'date_range' && (
            <div className="flex flex-col items-center justify-center gap-4 mt-2 w-full px-2">
              <DatePicker
                className="w-full"
                label="Start Date"
                value={dayjs(grossIncomeDateFilter.start_date)}
                onChange={(newValue) =>
                  newValue && setGrossIncomeDateFilter({ ...grossIncomeDateFilter, start_date: newValue.format('YYYY-MM-DDTHH:mm') })
                }
              />
              <DatePicker
                className="w-full"
                label="End Date"
                value={dayjs(grossIncomeDateFilter.end_date)}
                onChange={(newValue) =>
                  newValue && setGrossIncomeDateFilter({ ...grossIncomeDateFilter, end_date: newValue.format('YYYY-MM-DDTHH:mm') })
                }
              />
            </div>
          )}
          {/*-----------------------Sub Cards-------------------- */}

          <div className="flex flex-col items-center justify-between w-full divide-y">
            {financeSummary.map((singleItem, index) => (
              <CardContent
                key={index}
                className="flex flex-col justify-center h-full min-w-max w-full"
                sx={{ color: singleItem.color, textAlign: 'center' }}
              >
                <Typography variant={singleItem.value > Math.pow(10, 7) ? 'h5' : 'h4'} className="font-semibold hover:underline">
                  <Link to={singleItem.url}>{formateData(singleItem.value ?? 0, '$')}</Link>
                </Typography>
                <Typography className="text-xs w-full hover:underline">
                  <Link to={singleItem.url}>{singleItem.label}</Link>
                </Typography>
              </CardContent>
            ))}
          </div>
        </CardContent>
      ) : (
        <CardContent className="space-y-3 h-full" sx={{ minHeight: { sm: '300px', lg: '350px' } }}>
          <div className="flex justify-between">
            <Skeleton variant="text" width={100} height={30} />
            <Skeleton variant="text" width={100} height={30} />
          </div>
          <div className={`grid grid-cols-2 gap-4 lg:gap-1 h-full`}>
            <Skeleton variant="rectangular" className="lg:col-span-1 col-span-2 h-full" />
            <Skeleton variant="rectangular" className="lg:col-span-1 col-span-2 h-full" />
            <Skeleton variant="rectangular" className="col-span-2 h-full" />
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default GrossIncome;
