import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TDateFilter } from 'pages/Dashboard/types/types.dashboard';
import ReactApexChart from 'react-apexcharts';
import { formateData } from 'utils/common';
import ReportDateFilter from '../components/ReportDateFilter';
import { TTotalInvoiceReport } from '../types/types.report';

const TotalInvoiceReport = ({
  selectedFilter,
  setSelectedFilter,
  reportData
}: {
  selectedFilter: TDateFilter;
  setSelectedFilter: (value: TDateFilter) => void;
  reportData: TTotalInvoiceReport;
}) => {
  //------------------------Constants------------------------
  const theme = useTheme();
  const dateFilter = [
    { label: 'All', value: 'all' },
    { label: `This Year ${new Date().getFullYear()}`, value: 'this_year' },
    { label: 'Last 10 days', value: 'last_ten_days' },
    { label: 'Last 30 days', value: 'last_thirty_days' },
    { label: `Last Year ${new Date().getFullYear() - 1}`, value: 'last_year' },
    { label: 'Date Range', value: 'date_range' }
  ];
  const paidAndUnpaidInvoiceData = [
    { label: 'Paid Invoices', value: Number(reportData?.paid_invoices.toFixed(2)) || 0, color: theme.palette.success.main },
    { label: 'Unpaid Invoices', value: Number(reportData?.unpaid_invoices.toFixed(2)) || 0, color: theme.palette.error.main }
  ];
  const donutChartOption = {
    labels: ['Paid Invoices', 'Unpaid Invoices'],
    colors: [paidAndUnpaidInvoiceData[0].color, paidAndUnpaidInvoiceData[1].color],
    legend: {
      show: false
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              label: 'Paid',
              formatter: (w: { config: any }) => {
                const paidPrecentage = isFinite(
                  (reportData?.paid_invoices / (reportData?.paid_invoices + reportData?.unpaid_invoices)) * 100
                )
                  ? (reportData?.paid_invoices / (reportData?.paid_invoices + reportData?.unpaid_invoices)) * 100
                  : 0;
                return `${paidPrecentage.toFixed(1)}%`;
              }
            }
          }
        }
      }
    }
  };

  return (
    <Card className="p-4 space-y-3 lg:w-1/3">
      <CardHeader title={<div className="text-center text-xl text-gray-600">Invoice Paid/Unpaid</div>} />
      {/*---------------------------Date Filter----------------------------------------*/}
      <ReportDateFilter selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} dateFilters={dateFilter} />
      {/*------------------------------Donut Chart-------------------------- */}
      {!(paidAndUnpaidInvoiceData[0].value === 0 && paidAndUnpaidInvoiceData[1].value === 0) && (
        <ReactApexChart
          series={[paidAndUnpaidInvoiceData[0].value, paidAndUnpaidInvoiceData[1].value]}
          type="donut"
          options={donutChartOption}
        />
      )}
      {/*----------------------------Paid and Unpaid Invoice Card------------------------ */}
      <PaidAndUnpaidInvoiceCard paidAndUnpaidInvoiceData={paidAndUnpaidInvoiceData} />
      {/*----------------------------Footer------------------------ */}
      {/* don't delete */}
      {/* <Typography sx={{ color: theme.palette.error.main }} className="text-center">
        Overall Unpaid Invoicessss&nbsp;
        {reportData && formateData(reportData.overall_unpaid_invoices ?? 0, '$')}
      </Typography> */}
    </Card>
  );
};

const PaidAndUnpaidInvoiceCard = ({
  paidAndUnpaidInvoiceData
}: {
  paidAndUnpaidInvoiceData: { label: string; value: number; color: string }[];
}) => {
  return (
    <div className="grid grid-cols-2 gap-3">
      {paidAndUnpaidInvoiceData.map((singleItem) => (
        <CardContent className="flex flex-col justify-center w-full shadow-lg shadow-slate-200 rounded-md flex-1">
          <Typography className="text-md text-center" color="textSecondary">
            {singleItem.label}
          </Typography>
          <Typography variant="h4" className="text-center" color={singleItem.color}>
            {formateData(singleItem.value ?? 0, '$')}
          </Typography>
        </CardContent>
      ))}
    </div>
  );
};
export default TotalInvoiceReport;
