import { ArrowDownOutlined, ArrowUpOutlined, InboxOutlined } from '@ant-design/icons';
import {
  CardContent,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { flexRender, getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import TableSkeleton from 'components/skeleton/TableSkeleton';
import TablePaginationActions from 'components/third-party/ReactTablePagination';
import { useEffect, useState } from 'react';
import { IReactTable } from './interfaces/tableInterface';

export const rowsPerPageOptions: number[] = [20, 50, 100];

const CustomDataTable = (props: IReactTable) => {
  const { columns, data, onPaginationChange = () => {}, hasPagination = true } = props;
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageOptions[0]);

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  const tableInstance = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    pageCount: props?.count,
    initialState: { pagination: { pageIndex: page, pageSize: rowsPerPage } },
    manualPagination: true
  });

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    event?.preventDefault();
    setPage(newPage);
    onPaginationChange(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    onPaginationChange(0, parseInt(event.target.value, 10));
  };
  useEffect(() => {
    if (props.toggleFilter !== null) {
      setRowsPerPage(20);
      setPage(0);
      onPaginationChange(0, 20);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.toggleFilter]);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }}>
        <TableHead>
          {tableInstance.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableCell
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    style={{
                      cursor: header.column.getCanSort() ? 'pointer' : 'default'
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div className="flex gap-x-3">
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <ArrowUpOutlined />,
                          desc: <ArrowDownOutlined />
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>
        {props?.isDataLoading ? (
          <TableSkeleton />
        ) : (
          <>
            {data.length ? (
              <TableBody>
                {tableInstance.getRowModel().rows.map((row) => {
                  return (
                    <TableRow hover key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>;
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableRow>
                <TableCell colSpan={12}>
                  <CardContent className="w-full flex sm:flex-col flex-row justify-start items-center">
                    <Stack className="mt-4 flex flex-col items-center gap-2">
                      <InboxOutlined style={{ height: 20, transform: 'scale(3)', color: 'GrayText' }} />
                      <Typography color={'GrayText'}>No Data</Typography>
                    </Stack>
                  </CardContent>
                </TableCell>
              </TableRow>
            )}
          </>
        )}
        {hasPagination && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                colSpan={12}
                count={props?.count || data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default CustomDataTable;
