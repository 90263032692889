import { useRoutes } from 'react-router-dom';

// project import
import { GlobalStyles } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useSelector } from 'store';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import WidgetRoutes from './WidgetRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const routes = useRoutes([LoginRoutes, MainRoutes, WidgetRoutes]);
  const { user } = useAuth();

  const settings = useSelector((state) => state.settings);

  const transformText = () => {
    if (user?.setting) {
      if (user?.setting.type_case === 'free_form') return 'none';
      if (user?.setting.type_case === 'uppercase' || user?.setting.type_case === 'lowercase') return user?.setting.type_case;
      if (user?.setting.type_case === 'title_case') return 'capitalize';
    } else if (settings.generalSettings?.type_case) {
      const type_case = settings.generalSettings?.type_case;
      if (type_case === 'free_form') return 'none';
      if (type_case === 'uppercase' || type_case === 'lowercase') return type_case;
      if (type_case === 'title_case') return 'capitalize';
    } else return 'none';
  };

  return (
    <>
      <GlobalStyles styles={{ '*': { textTransform: transformText() }, button: { textTransform: `${transformText()} !important` } }} />
      {routes}
    </>
  );
}
