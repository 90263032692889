import { createSlice } from '@reduxjs/toolkit';
import { TJobCompleteData } from 'components/tables/interfaces/jobTableInterface';
import { TMaterialUsed } from 'pages/jobs/types/jobViewPageTypes';

type TJobSliceState = {
  is_customer_present: boolean;
  customer_sign_for_job_completed: string;
  job_completion_photos: string[];
  allJobs: TJobCompleteData[];
  materials_used: TMaterialUsed[];
  active_note_input_id: string;
  add_job_media_id: string;
  add_media_menu_list: string[];
  show_job_complete_btn: boolean;
  worker_to_office_note: string;
};

//--------------------Initial State-----------------
const initialState: TJobSliceState = {
  is_customer_present: true,
  customer_sign_for_job_completed: '',
  job_completion_photos: [],
  allJobs: [],
  materials_used: [],
  active_note_input_id: '',
  add_job_media_id: '',
  add_media_menu_list: [],
  show_job_complete_btn: false,
  worker_to_office_note: ''
};

//--------------Slice--------------
const jobActions = createSlice({
  name: 'job',
  initialState,
  reducers: {
    clearJobcompletionStates(state) {
      state.is_customer_present = true;
      state.customer_sign_for_job_completed = '';
      state.job_completion_photos = [];
      state.materials_used = [];
    },
    setMaterialsUsed(state, action) {
      state.materials_used = action.payload;
    },

    setIsCustomerPresent(state, action) {
      state.is_customer_present = action.payload;
    },
    setJobCompletionPhotos(state, action) {
      state.job_completion_photos = action.payload;
    },
    setCustomerSign(state, action) {
      state.customer_sign_for_job_completed = action.payload;
    },
    setAllJobs(state, action) {
      state.allJobs = action.payload;
    },
    setActiveNoteInputId(state, action: { payload: string }) {
      state.active_note_input_id = action.payload;
    },
    setAddJobMediaInputId(state, action: { payload: string }) {
      state.add_job_media_id = action.payload;
    },
    pushToAddMediaMenu(state, action: { payload: string }) {
      const newList = [...state.add_media_menu_list, action.payload];
      state.add_media_menu_list = newList;
    },
    removeFromAddMediaMenu(state, action: { payload: string }) {
      const newList = [...state.add_media_menu_list].filter((each) => each !== action.payload);
      state.add_media_menu_list = newList;
    },
    setShowJobCompleteBtn(state, action: { payload: boolean }) {
      state.show_job_complete_btn = action.payload;
    },
    setWorkerToOfficeNote(state, action: { payload: string }) {
      state.worker_to_office_note = action.payload;
    }
  }
});

export default jobActions.reducer;
export const {
  clearJobcompletionStates,
  setMaterialsUsed,
  setCustomerSign,
  setIsCustomerPresent,
  setJobCompletionPhotos,
  setAllJobs,
  setActiveNoteInputId,
  pushToAddMediaMenu,
  removeFromAddMediaMenu,
  setShowJobCompleteBtn,
  setWorkerToOfficeNote,
  setAddJobMediaInputId
} = jobActions.actions;
