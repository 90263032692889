import { ArrowLeftOutlined, EyeOutlined } from '@ant-design/icons';
import { IconButton } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import Loader from 'components/Loader';
import CustomDataTable from 'components/tables/CustomDataTable';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'store';
import { TPunchData } from 'store/reducers/punching';

const PunchList = () => {
  const punching = useSelector((state) => state.punching);
  const [punchList, setPunchList] = useState<TPunchData[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!punching?.punchingDetails) return;
    const punches = punching.punchingDetails.allPunches.map((punch) => punch);
    punches.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    setPunchList(punches);
  }, [punching.punchingDetails]);

  const columns = useMemo<ColumnDef<TPunchData>[]>(
    () => [
      {
        accessorFn: (row) => moment(row.date).format('MM-DD-YYYY'),
        id: 'date',
        header: () => <span>Date</span>
      },
      {
        id: 'action',
        header: () => <span>Action</span>,
        cell: ({ row }) => {
          return (
            <IconButton color="primary" onClick={() => navigate(`/punch/view/${row.original._id}`)}>
              <EyeOutlined />
            </IconButton>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return punching.isPunchLoading ? (
    <Loader />
  ) : (
    <>
      <IconButton onClick={() => navigate(-1)}>
        <ArrowLeftOutlined />
      </IconButton>
      <CustomDataTable data={punchList} columns={columns} />
    </>
  );
};

export default PunchList;
