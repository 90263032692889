import { LoadingOutlined } from '@ant-design/icons';
import { Box, Button, Card, CardContent, CardHeader, Step, StepButton, Stepper } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import CreateEstimateWidgetForm from 'components/widgets/components/CreateEstimateWidgetForm';
import dayjs from 'dayjs';
import { Formik, FormikContextType, FormikState } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import AutoAppointmentServiceInstance from 'services/services.autoAppointment';
import widgetServicesInstance from 'services/services.estimateFormWidget';
import FileUploadServiceInstance from 'services/services.fileUpload';
import { characters, incorrectCaptchaMessage } from 'utils/constants';
import * as Yup from 'yup';
import ThankYouPage from '../ExternalFormWidgets/ThankYouPage';
import { TAppointmentWidget } from '../types/types.appointmentWidget';
import { EstimateFormData } from '../types/types.estimateFormWidget';
import AppointmentDateAndTimeSelection from './AppointmentDateAndTimeSelection';
import AreaSelectionStep from './AreaSelectionStep';

const AutoAppointmentWidget = () => {
  //--------------------constants-------------------
  const steps = ['Select Area', 'Pickup Date & Time', 'Schedule Appointment'];
  const location = useLocation();
  const companyId = new URLSearchParams(location.search).get('company_id');
  const serviceId = new URLSearchParams(location.search).get('service_id');
  const googleMapRef = useRef<{ resetSearchText: () => {} }>();

  //--------------------States-------------------
  const [activeStep, setActiveStep] = useState(0);
  const [IsLastStep, setIsLastStep] = useState(false);

  const [isThankYouPage, setIsThankYouPage] = useState(false);
  const [captcha, setCaptcha] = useState<string>('');
  const [jobId, setJobId] = useState<string>('');
  const [uploadedImages, setUploadedImages] = useState<File[]>([]);

  //--------------------useQuery-------------------
  const { data: appointmentConfigData, isFetched: isAppointmentWidgetDataFetched } = useQuery({
    queryKey: ['appointment_config'],
    queryFn: () => {
      if (companyId && serviceId) return AutoAppointmentServiceInstance.getAppointmentWidgetData(companyId, serviceId);
    }
  });

  //-----------------------------handlers----------------------
  const createNewCaptchaPattern = (length: number) => {
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    setCaptcha(result);
  };

  const handleScheduleAppointmentFormSubmit = async (
    values: EstimateFormData & TAppointmentWidget,
    {
      resetForm,
      setFieldValue
    }: {
      resetForm: (nextState?: Partial<FormikState<EstimateFormData & TAppointmentWidget>> | undefined) => void;
      setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    }
  ) => {
    if (values.property_type === 'residential') delete values.company_name;
    delete values.captcha;
    values = { ...values, company_id: companyId };
    const response = await widgetServicesInstance.scheduleAppointment(values);
    if (response?.success) {
      resetForm();
      googleMapRef.current?.resetSearchText();
      createNewCaptchaPattern(6);
      setJobId(response.jobId);
      return true;
    }
    setFieldValue('captcha', '');
    createNewCaptchaPattern(6);
    return false;
  };

  const handleNext = async (
    values: EstimateFormData & TAppointmentWidget,
    {
      setSubmitting,
      resetForm,
      setFieldValue
    }: {
      setSubmitting: (isSubmitting: boolean) => void;
      resetForm: (nextState?: Partial<FormikState<EstimateFormData & TAppointmentWidget>> | undefined) => void;
      setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    }
  ) => {
    if (activeStep === 2) {
      setSubmitting(true);
      if (appointmentConfigData?.auto_appointment_schedule.schedule.type !== 'hour') delete values.appointment_timeblock;
      else {
        const startTime = dayjs(values.appointment_timeblock?.start_time);
        values.schedule_date = dayjs(values.schedule_date)
          .set('hour', startTime.get('hour'))
          .set('minute', startTime.get('minute'))
          .toISOString();
      }

      const newImagesData: string[] = [];
      if (!!uploadedImages.length) {
        await Promise.all(
          uploadedImages.map(async (eachFile) => {
            const isImageOrVideo = eachFile.type.startsWith('image/') || eachFile.type.startsWith('video/');

            if (!isImageOrVideo) {
              return;
            }

            const response = await FileUploadServiceInstance.uploadFile(eachFile);
            if (response && response.data) {
              newImagesData.push(response.data);
            }
          })
        );
      }

      const response = await handleScheduleAppointmentFormSubmit({ ...values, files: newImagesData }, { resetForm, setFieldValue });
      if (response) {
        setIsThankYouPage(true);
      }
      setSubmitting(false);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderStepContent = (formikProps: FormikContextType<EstimateFormData & TAppointmentWidget>) => {
    if (!!appointmentConfigData) {
      switch (activeStep) {
        case 0:
          return (
            <AreaSelectionStep
              activeStep={activeStep}
              appointmentConfigData={appointmentConfigData}
              isAppointmentWidgetDataFetched={isAppointmentWidgetDataFetched}
            />
          );
        case 1:
          if (appointmentConfigData)
            return <AppointmentDateAndTimeSelection activeStep={activeStep} appointmentConfigData={appointmentConfigData} />;
          break;
        case 2:
          return (
            <div className="space-y-2">
              <div className="space-y-4">
                {!!appointmentConfigData && (
                  <AreaSelectionStep
                    activeStep={activeStep}
                    appointmentConfigData={appointmentConfigData}
                    isAppointmentWidgetDataFetched={isAppointmentWidgetDataFetched}
                  />
                )}
                {appointmentConfigData && (
                  <AppointmentDateAndTimeSelection activeStep={activeStep} appointmentConfigData={appointmentConfigData} />
                )}
              </div>

              <CreateEstimateWidgetForm
                formik={formikProps as FormikContextType<EstimateFormData & TAppointmentWidget>}
                googleMapRef={googleMapRef}
                createNewCaptchaPattern={createNewCaptchaPattern}
                captcha={captcha}
                companyId={companyId as string}
                setUploadedImages={setUploadedImages}
                uploadedImages={uploadedImages}
              />
            </div>
          );
      }
    }
  };
  useEffect(() => {
    if (activeStep === 2) setIsLastStep(true);
    else setIsLastStep(false);
    document.getElementById('auto_appoinment')?.scrollIntoView({ behavior: 'auto' });
  }, [activeStep]);
  return isThankYouPage ? (
    <ThankYouPage jobId={jobId} />
  ) : (
    <CardContent className="md:px-[20vw] lg:px-[30vw] space-y-4" id="auto_appoinment">
      <CardHeader title={<div className="xs:text-xl md:text-3xl font-semibold text-center">Schedule Your Appointment</div>} />
      <Stepper nonLinear activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label} completed={activeStep > index}>
            <StepButton color="inherit">{label}</StepButton>
          </Step>
        ))}
      </Stepper>
      <Formik
        initialValues={{
          schedule_date: '',
          appointment_timeblock: {
            start_time: '',
            end_time: ''
          },
          area: {
            id: '',
            name: ''
          },
          service: {
            id: '',
            name: ''
          },
          property_type: 'residential',
          company_name: '',
          first_name: '',
          last_name: '',
          address: {
            address1: '',
            city: '',
            state: '',
            zip: '',
            country: ''
          },
          phone: null as unknown as number,
          phone_country_code: '',
          phone_type: 'mobile',
          email: '',
          job_details: '',
          captcha: '',
          referral: 'Other'
        }}
        validationSchema={Yup.object().shape({
          schedule_date: Yup.string().when([], () => (activeStep > 0 ? Yup.string().required('This field is required') : Yup.string())),
          appointment_timeblock: Yup.object().when([], () =>
            activeStep > 0 && appointmentConfigData?.auto_appointment_schedule.schedule.type === 'hour'
              ? Yup.object().shape({ start_time: Yup.string().required('Select a preferred time slot') })
              : Yup.object().shape({ start_time: Yup.string() })
          ),
          area: Yup.object().shape({
            id: Yup.string().required('This field is required')
          }),
          service: Yup.object().shape({
            id: Yup.string().required('This field is required')
          }),
          company_name:
            activeStep === 2 && IsLastStep
              ? Yup.string().when('property_type', ([property_type], schema) => {
                  if (property_type === 'commercial') {
                    return schema.required('This field is required');
                  }
                  return schema.nullable();
                })
              : Yup.string(),
          referral: activeStep === 2 && IsLastStep ? Yup.string().required('This field is required') : Yup.string(),
          first_name: activeStep === 2 && IsLastStep ? Yup.string().required('This field is required') : Yup.string(),
          last_name: activeStep === 2 && IsLastStep ? Yup.string().required('This field is required') : Yup.string(),
          address: Yup.object().shape({
            address1: activeStep === 2 && IsLastStep ? Yup.string().required('This field is required') : Yup.string(),
            city: activeStep === 2 && IsLastStep ? Yup.string().required('This field is required') : Yup.string(),
            state: activeStep === 2 && IsLastStep ? Yup.string().required('This field is required') : Yup.string(),
            zip: activeStep === 2 && IsLastStep ? Yup.string().required('This field is required') : Yup.string(),
            country: activeStep === 2 && IsLastStep ? Yup.string().required('This field is required') : Yup.string()
          }),
          phone_type: activeStep === 2 && IsLastStep ? Yup.string().required('This field is required') : Yup.string(),
          phone:
            activeStep === 2 && IsLastStep
              ? Yup.string().min(5, 'Numbers should be at least 5 digits long').required('This field is required')
              : Yup.string().nullable(),

          email:
            activeStep === 2 && IsLastStep
              ? Yup.string().email('Please provide correct email address').required('This field is required')
              : Yup.string(),
          job_details: activeStep === 2 && IsLastStep ? Yup.string().required('This field is required') : Yup.string(),
          captcha:
            activeStep === 2 && IsLastStep
              ? Yup.string().oneOf([captcha], incorrectCaptchaMessage).required('This field is required')
              : Yup.string()
        })}
        onSubmit={async (values, { setSubmitting, resetForm, setFieldValue }) =>
          handleNext(values, { setSubmitting, resetForm, setFieldValue })
        }
      >
        {(formikProps: FormikContextType<EstimateFormData & TAppointmentWidget>) => (
          <Card>
            <CardContent component={'form'} onSubmit={formikProps.handleSubmit}>
              {renderStepContent(formikProps)}
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                  variant="contained"
                  sx={{ mr: 1 }}
                  type="submit"
                  disabled={formikProps.isSubmitting}
                  startIcon={formikProps.isSubmitting && <LoadingOutlined />}
                >
                  {activeStep < 2 ? 'Next' : 'Submit'}
                </Button>
              </Box>
            </CardContent>
          </Card>
        )}
      </Formik>
    </CardContent>
  );
};

export default AutoAppointmentWidget;
