import { createSlice } from '@reduxjs/toolkit';

const initialState = { action_title: '' };

const universalActionOption = createSlice({
  name: 'universal_action_page',
  initialState,
  reducers: {
    setActionTitle(state, action) {
      state.action_title = action.payload;
    }
  }
});
export default universalActionOption.reducer;

export const { setActionTitle } = universalActionOption.actions;
