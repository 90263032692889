import {
  Checkbox,
  Divider,
  FormControlLabel,
  Hidden,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import AddEmailorSms from 'components/popups/AddEmailorSms';
import UniversalDialog from 'components/popups/UniversalDialog';
import useTimezone from 'hooks/useTimezone';
import { TCustomerData } from 'pages/customers/types/type.customersPage';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'store';
import { formatPhoneNumber, handleRedirectToGoogleMap, snakeCaseToTitleCase, toTitleCase } from 'utils/common';
import { TCustomerAddEmailSMSPopup } from '../types/type.customersView';

const ContactDetails = ({ data }: { data?: TCustomerData }) => {
  //------------------------Constants---------------------------
  const theme = useTheme();
  const formatDateTime = useTimezone();
  const settingsState = useSelector((state) => state.settings.generalSettings);
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const [addEmailOrSmsPopup, setAddEmailOrSmsPopup] = useState<TCustomerAddEmailSMSPopup>({
    action: { open: false, isEmail: true, showJobName: true },
    data: {}
  });
  const notificationSendingChannels: { value: number; label: string }[] = useMemo(
    () => [
      { value: 1, label: 'Email' },
      { value: 2, label: 'SMS' }
    ],
    []
  );
  const [customerDetails, setCustomerDetils] = useState<{ label: string; value: string | JSX.Element }[] | null>(null);
  //----------------------useEffects-------------------
  useEffect(() => {
    const customerDetailsData: { label: string; value: string | JSX.Element }[] = [
      {
        label: 'Created At:',
        value:
          data?.created_at !== undefined
            ? formatDateTime(data.created_at, {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: settingsState?.time_format === '12hrs'
              })
            : ''
      },
      { label: 'Customer Name:', value: `${data?.first_name ?? ''} ${data?.last_name ?? ''}` },
      {
        label: 'Address1:',
        value: (
          <Typography
            onClick={() => {
              if (data) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { address2, ...address } = data.address;
                handleRedirectToGoogleMap(address);
              }
            }}
            sx={{ '&:hover': { color: theme.palette.primary.light }, cursor: 'pointer' }}
          >
            {data?.address.address1}
          </Typography>
        )
      },
      {
        label: 'Address2:',
        value: (
          <Typography
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              if (data) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { address1, ...address } = data.address;
                handleRedirectToGoogleMap(address);
              }
            }}
            sx={{ '&:hover': { color: theme.palette.primary.light }, cursor: 'pointer' }}
          >
            {data?.address.address2}
          </Typography>
        )
      },
      { label: 'City', value: data?.address.city ?? '' },
      { label: 'State', value: data?.address.state ?? '' },
      {
        label: 'Zip',
        value: data?.address.zip ?? ''
      },
      {
        label: 'Country',
        value: data?.address.country ?? ''
      },
      { label: 'Cross Street:', value: data?.address.cross_street ?? '' },
      { label: 'Referral', value: data?.referral ?? '' },
      { label: 'Residential Or Commercial', value: data?.property_type ? toTitleCase(data?.property_type) : '' },
      {
        label: 'Notification',
        value: (
          <Stack direction={'row'} spacing={2}>
            {notificationSendingChannels.map((items: { value: number; label: string }, index) => {
              return (
                <FormControlLabel
                  value={items.value}
                  name="customer_notification_preference"
                  id="customer_notification_preference"
                  color="primary"
                  checked={!!data?.customer_notification_preference?.length && data?.customer_notification_preference.includes(items.value)}
                  control={<Checkbox />}
                  label={items.label}
                />
              );
            })}
          </Stack>
        )
      },
      {
        label: 'Email',
        value: (
          <Typography
            onClick={() => {
              setAddEmailOrSmsPopup({
                action: { open: true, isEmail: true, showJobName: true },
                data: {
                  id: data?._id,
                  name: `${data?.first_name} ${data?.last_name}`,
                  emails: data?.emails
                }
              });
            }}
            sx={{ cursor: 'pointer', '&:hover': { color: theme.palette.primary.main } }}
          >
            {data?.emails.join(',\n')}
          </Typography>
        )
      },
      {
        label: 'Phone1',
        value: data?.phone1 ? (
          <a href={`tel:+${data?.phone1_country_code}${data?.phone1}${data?.phone1_extension ? `,${data?.phone1_extension}` : ''}`}>
            <span>{snakeCaseToTitleCase(data?.phone1_type as string)}</span> &nbsp;{' '}
            {/* {`+${data.phone1_country_code}${data.phone1_extension ? ` (${data.phone1_extension})` : ''} ${data?.phone1}${
              data.phone1_extension ? ` x${data.phone1_extension}` : ''
            }`} */}
            {formatPhoneNumber(data?.phone1)}
            {}
          </a>
        ) : (
          '-'
        )
      },
      {
        label: 'Phone2',
        value: data?.phone2 ? (
          <a href={`tel:+${data?.phone2_country_code}${data?.phone2}${data?.phone2_extension ? `,${data?.phone2_extension}` : ''}`}>
            <span>{snakeCaseToTitleCase(data?.phone2_type as string)}</span> &nbsp;{' '}
            {/* {`+${data.phone2_country_code}${data.phone2_extension ? ` (${data.phone2_extension})` : ''} ${data?.phone2}${
              data.phone2_extension ? ` x${data.phone2_extension}` : ''
            }`} */}
            {formatPhoneNumber(data?.phone2)}
          </a>
        ) : (
          '-'
        )
      },
      {
        label: 'Phone3',
        value: data?.phone3 ? (
          <a href={`tel:+${data?.phone3_country_code}${data?.phone3}${data?.phone3_extension ? `,${data?.phone3_extension}` : ''}`}>
            <span>{snakeCaseToTitleCase(data?.phone3_type as string)}</span> &nbsp;{' '}
            {/* {`+${data.phone3_country_code}${data.phone3_extension ? ` (${data.phone3_extension})` : ''} ${data?.phone3}${
              data.phone3_extension ? ` x${data.phone3_extension}` : ''
            }`}{' '} */}
            {formatPhoneNumber(data?.phone3)}
          </a>
        ) : (
          '-'
        )
      },
      { label: 'Will This Be Billed To You?:', value: `${data?.bill_to_you ? 'Yes' : 'No'}` },
      { label: 'Private Office Notes:', value: data?.private_office_notes ?? '' }
    ];

    if (data?.property_type === 'commercial') {
      customerDetailsData.splice(2, 0, { label: 'Company Name:', value: data?.customer_company_name });
    }

    setCustomerDetils(customerDetailsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, notificationSendingChannels]);
  const handleAddEmailorSmsPopup = () => {
    setAddEmailOrSmsPopup({
      action: { open: false, isEmail: true, showJobName: true },
      data: {}
    });
  };
  return (
    <>
      {data && customerDetails && (
        <Stack direction={{ xs: 'column', md: 'row' }} padding={2}>
          <Table>
            <TableBody>
              {customerDetails
                .slice(0, Math.ceil(mdDown ? customerDetails.length : customerDetails.length / 2))
                .map((singleData, index) => (
                  <TableRow key={index} className="border-hidden">
                    <TableCell>
                      <Typography variant="subtitle1" className="font-bold text-gray-400">
                        {singleData.label}
                      </Typography>
                    </TableCell>
                    <TableCell className="p-2">
                      <Typography variant="body1">{singleData.value}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Divider orientation="vertical" flexItem />
          <Hidden mdDown>
            <Table>
              <TableBody>
                {customerDetails.slice(Math.ceil(customerDetails.length / 2)).map((singleData, index) => (
                  <TableRow key={index} className="border-hidden">
                    <TableCell>
                      <Typography variant="subtitle1" className="font-bold text-gray-400">
                        {singleData.label}
                      </Typography>
                    </TableCell>
                    <TableCell className="p-2">
                      <Typography variant="body1">{singleData.value}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Hidden>
        </Stack>
      )}
      {addEmailOrSmsPopup.action.open && (
        <UniversalDialog
          action={{ open: addEmailOrSmsPopup.action.open, fullWidth: true }}
          onClose={() => handleAddEmailorSmsPopup()}
          hasPrimaryButton={false}
        >
          <AddEmailorSms {...addEmailOrSmsPopup} onClose={() => handleAddEmailorSmsPopup()} />
        </UniversalDialog>
      )}
    </>
  );
};

export default ContactDetails;
