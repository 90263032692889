import axiosServices from 'utils/axios';
import { IResponseWithFollowups } from './types/types.services';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import TFollowupData from 'components/tables/interfaces/followupTableInterface';
import { AxiosRequestConfig } from 'axios';
import { ISearch } from 'components/filters/SearchFilter';

class FollowupServices {
  getAllFollowup = async (paginationData?: { page: number; rowsPerPage: number }, searchData?: ISearch | null) => {
    const page = paginationData && paginationData?.page + 1;
    const limit = paginationData && paginationData?.rowsPerPage;
    try {
      const response: IResponseWithFollowups = await axiosServices.get('api/followup', {
        params: {
          ...(page && { page }),
          ...(limit && { limit }),
          ...(searchData && { filter: JSON.stringify({ ...searchData, sort: { created_at: -1 } }) })
        },
        locationNeeded: false
      } as AxiosRequestConfig);

      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  getIsAssignedFollwup = async () => {
    try {
      const response: { data: { success: boolean; data: { is_assigned_followups: boolean } } } = await axiosServices.get(
        'api/followup/is-assigned-followup',
        {
          locationNeeded: false
        } as AxiosRequestConfig
      );

      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  deleteFollowup = async (followupId: string, reason: string) => {
    try {
      const response: IResponseWithFollowups = await axiosServices.delete(`api/followup/${followupId}`, {
        locationNeeded: false,
        data: { delete_reason: reason }
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Follow-up successfully deleted`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  createFollowup = async (followupData: TFollowupData) => {
    try {
      const response: IResponseWithFollowups = await axiosServices.post(`api/followup`, followupData, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Followup Created Successfully!`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  editFollowup = async (followupId: string, followupData: TFollowupData) => {
    try {
      const response: IResponseWithFollowups = await axiosServices.put(`api/followup/${followupId}`, followupData, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Followup was Updated Successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  markFollowupComplete = async (followupId: string, description: string) => {
    try {
      const response = await axiosServices.put(`api/followup/mark-as-complete/${followupId}`, { description });
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Followup Marked completed successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  markFollowupNotComplete = async (followupId: string, data: { description: string; note: string; callback_date: string }) => {
    try {
      const response = await axiosServices.put(`api/followup/mark-as-not-complete/${followupId}`, data);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Followup Not marked as complete`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: any) {
      dispatch(
        openSnackbar({
          open: true,
          message: error.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return false;
    }
  };

  bulkDeleteFollowup = async (followupIds: string[], reason: string) => {
    try {
      const response: IResponseWithFollowups = await axiosServices.delete(`api/followup/bulk-delete`, {
        locationNeeded: false,
        data: { delete_reason: reason, ids: followupIds }
      } as AxiosRequestConfig);

      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Follow ups deleted in bulk`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
      return false;
    }
  };

  bulkMarkFollowupComplete = async (followupIds: string[], description: string) => {
    try {
      const response = await axiosServices.post(`api/followup/bulk-mark-complete`, { ids: followupIds, description });
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Followup Marked completed in bulk`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
}

const FollowupServicesInstance = new FollowupServices();
export default FollowupServicesInstance;
