import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import axiosServices from 'utils/axios';
import { IResponseWithMaterial, IResponseWithMaterials } from './types/types.services';
import { ISearch } from 'components/filters/SearchFilter';
import { AxiosRequestConfig } from 'axios';
import TMaterialData from 'components/tables/interfaces/materialTableInterface';

class MaterialServices {
  getAllMaterials = async (paginationData?: { page: number; rowsPerPage: number }, searchData?: ISearch) => {
    const page = paginationData && paginationData?.page + 1;
    const limit = paginationData && paginationData?.rowsPerPage;
    try {
      const response: IResponseWithMaterials = await axiosServices.get('api/material', {
        params: {
          ...(page && { page }),
          ...(limit && { limit }),
          ...(searchData && { filter: JSON.stringify(searchData) })
        },
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  deleteMaterial = async (materialId: string, materialName?: string) => {
    try {
      const response: IResponseWithMaterials = await axiosServices.delete(`api/material/${materialId}`, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Material was successfully deleted`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  createMaterial = async (materialData: TMaterialData) => {
    try {
      const response: IResponseWithMaterials = await axiosServices.post(`api/material/`, materialData, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Material was successfully Created`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  editMaterial = async (materialId: string, materialData: TMaterialData) => {
    try {
      const response: IResponseWithMaterials = await axiosServices.put(`api/material/${materialId}`, materialData, {
        locationNeeded: false
      } as AxiosRequestConfig);

      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Material updated`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  updateMaterialUnits = async (materialId: string, availableUnits: number) => {
    try {
      const response: { data: { success: boolean } } = await axiosServices.put(`api/material/update-units/${materialId}`, {
        available_units: availableUnits
      });
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Material was successfully updated.`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
  //------get Single Material data---------
  getMaterial = async (materialId: string) => {
    try {
      const response: IResponseWithMaterial = await axiosServices.get(`api/material/${materialId}`, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        return response.data.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
}

const MaterialServicesInstance = new MaterialServices();
export default MaterialServicesInstance;
