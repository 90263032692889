import { useLayoutEffect, useState } from 'react';

// material-ui
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project import
import menuItem from 'menu-items';
import NavGroup from './NavGroup';

import { HORIZONTAL_MAX_ITEM } from 'config';
import useConfig from 'hooks/useConfig';
import { useSelector } from 'store';

// types
import useAuth from 'hooks/useAuth';
import { UserProfile } from 'types/auth';
import { MenuOrientation } from 'types/config';
import { NavItemType } from 'types/menu';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const theme = useTheme();
  const { user, permissions: allPermissions } = useAuth();

  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { menuOrientation } = useConfig();
  const { drawerOpen } = useSelector((state) => state.menu);
  const [selectedItems, setSelectedItems] = useState<string | undefined>('');
  const [selectedLevel, setSelectedLevel] = useState<number>(0);
  const [menuItems, setMenuItems] = useState<{ items: NavItemType[] }>({ items: [] });

  useLayoutEffect(() => {
    setMenuItems(menuItem);
    // eslint-disable-next-line
  }, [menuItem]);

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
  let lastItemIndex = menuItems.items.length - 1;
  let remItems: NavItemType[] = [];
  let lastItemId: string;

  //  first it checks menu item is more than giving HORIZONTAL_MAX_ITEM after that get lastItemid by giving horizontal max
  // item and it sets horizontal menu by giving horizontal max item lastly slice menuItem from array and set into remItems

  if (lastItem && lastItem < menuItems.items.length) {
    lastItemId = menuItems.items[lastItem - 1].id!;
    lastItemIndex = lastItem - 1;
    remItems = menuItems.items.slice(lastItem - 1, menuItems.items.length).map((item) => ({
      title: item.title,
      elements: item.children,
      icon: item.icon
    }));
  }

  const getAllowedNavigationItems = (menuItems: { items: NavItemType[] }, user?: UserProfile | null): NavItemType[] => {
    const allMenuItems = menuItems.items.slice(0, lastItemIndex + 1)[0]?.children;
    const allowedNavigationItems: NavItemType[] = [];

    if (user && allMenuItems && allPermissions) {
      const userPermissions: string[] | undefined = user.permissions;
      allMenuItems.forEach((eachMenuItem) => {
        switch (eachMenuItem.id) {
          case 'company_management': {
            // routePermissions?.find((routePermission) => routePermission === eachMenuItem.id);

            user.role &&
              userPermissions?.some((eachPermission) => ['deleteCompany'].includes(eachPermission)) &&
              allowedNavigationItems.push({ ...eachMenuItem });
            break;
          }
          case 'customers': {
            user.role &&
              userPermissions?.some((eachPermission) =>
                ['createCustomer', 'getCustomers', 'updateCustomer', 'deleteCustomer'].includes(eachPermission)
              ) &&
              allowedNavigationItems.push({ ...eachMenuItem });
            break;
          }
          case 'jobs': {
            user.role &&
              userPermissions?.some((eachPermission) => ['createJob', 'getJobs', 'updateJob', 'deleteJob'].includes(eachPermission)) &&
              allowedNavigationItems.push({ ...eachMenuItem });
            break;
          }
          case 'expenses': {
            user.role &&
              userPermissions?.some((eachPermission) => ['createExpense', 'getExpenses', 'deleteExpense'].includes(eachPermission)) &&
              allowedNavigationItems.push({ ...eachMenuItem });
            break;
          }
          case 'payroll': {
            user.role &&
              userPermissions?.some((eachPermission) => ['createPayroll', 'getPayrolls', 'deletePayroll'].includes(eachPermission)) &&
              allowedNavigationItems.push({ ...eachMenuItem });
            break;
          }
          case 'settings': {
            user.role &&
              userPermissions?.some((eachPermission) => ['getSystemConfiguration', 'updateSystemConfiguration'].includes(eachPermission)) &&
              allowedNavigationItems.push({ ...eachMenuItem });
            break;
          }
          case 'dashboard': {
            user.role !== 'super_admin' && allowedNavigationItems.push({ ...eachMenuItem });
            break;
          }
          case 'reports': {
            // userPermissions?.some((eachPermission) => ['getReports'].includes(eachPermission)) &&
            userPermissions?.some((eachPermission) =>
              [
                'getJobWon',
                'getGrossIncome',
                'getInvoiceDetails',
                'getOpenInvoices',
                'getExpensesReport',
                'getCustomersAndLeadSources',
                'getFieldWorkerJobs',
                'getOfficeAdministrators',
                'getGPSCordinates',
                'getServicesReport',
                'getSuppliesAndMaterialUsedReport',
                'getSalesTaxReport',
                'getPayrollReport',
                'getCompletedProjects',
                'getAccountsReceivable'
              ].includes(eachPermission)
            ) && allowedNavigationItems.push({ ...eachMenuItem });
            break;
          }
          case 'punch': {
            if (user.rate_info?.pay_type !== 'hourly') {
              break;
            }
            user.role !== 'super_admin' &&
              user.rate_info?.punch_type !== 'auto/system_calculator' &&
              userPermissions?.some((eachPermission) => ['upsertPunch', 'getPunch'].includes(eachPermission)) &&
              allowedNavigationItems.push({ ...eachMenuItem });
            break;
          }
          case 'calendar': {
            user.role !== 'super_admin' &&
              userPermissions?.some((eachPermission) =>
                ['createCalendar', 'getCalendars', 'updateCalendar', 'deleteCalendar'].includes(eachPermission)
              ) &&
              allowedNavigationItems.push({ ...eachMenuItem });
            break;
          }
          case 'invoices': {
            user.role !== 'super_admin' &&
              userPermissions?.some((eachPermission) => ['getInvoices'].includes(eachPermission)) &&
              allowedNavigationItems.push({ ...eachMenuItem });
            break;
          }
          default:
            break;
        }
      });

      const dummyMenuItems = JSON.parse(JSON.stringify(menuItems));
      dummyMenuItems.items.slice(0, lastItemIndex + 1)[0].children = allowedNavigationItems;

      return dummyMenuItems.items;
    }
    return [];
  };

  return (
    <Box
      sx={{
        pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
        '& > ul:first-of-type': { mt: 0 },
        display: isHorizontal ? { xs: 'block', lg: 'flex' } : 'block'
      }}
    >
      {getAllowedNavigationItems(menuItems, user).map((item) => {
        return (
          <NavGroup
            key={item.id}
            setSelectedItems={setSelectedItems}
            setSelectedLevel={setSelectedLevel}
            selectedLevel={selectedLevel}
            selectedItems={selectedItems}
            lastItem={lastItem!}
            remItems={remItems}
            lastItemId={lastItemId}
            item={item}
          />
        );
      })}
    </Box>
  );
};

export default Navigation;
