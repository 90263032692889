import { CloseOutlined } from '@ant-design/icons';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineSeparator } from '@mui/lab';
import {
  AppBar,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Fade,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import useConfig from 'hooks/useConfig';
import useTimezone from 'hooks/useTimezone';
import { forwardRef } from 'react';
import { TInactivityReasonData } from 'services/services.punching';
import { ThemeMode } from 'types/config';

type TInactivityReasonDisplay = {
  open: boolean;
  data: TInactivityReasonData[] | null;
  onClose: () => void;
  name: string;
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Fade appear={true} ref={ref} {...props} />;
});

const InactivityReasonDisplay = (props: TInactivityReasonDisplay) => {
  const formatTime = useTimezone();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const themeConfig = useConfig();

  return (
    <Dialog
      {...(!isMobile ? { fullWidth: true } : { fullScreen: true })}
      maxWidth={'xs'}
      open={props.open}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar className="flex justify-between items-center">
          <span className="flex items-center gap-x-4">
            <Typography variant="h4" className="min-w-max">
              {props.name}'s
            </Typography>
            <Typography variant="h5" className="mt-1">
              Inactivity Reasons
            </Typography>
          </span>
          <IconButton edge="end" color="inherit" onClick={props.onClose} aria-label="close">
            <CloseOutlined />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent className="flex flex-col gap-y-4 !p-4">
        <Timeline position="right" className="!p-0">
          {props.data
            ?.map((item, index) => (
              <Stack direction="row" key={index}>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot className="bg-gray-300 " />
                  {index !== 0 ? <TimelineConnector /> : <TimelineConnector classes={{ root: 'bg-transparent' }} />}
                </TimelineSeparator>
                <TimelineContent className="!p-0 !pl-2 my-4">
                  <Typography className="flex items-center justify-start sm:text-xs text-sm text-gray-400">
                    <strong>{formatTime(item.time)}</strong>
                  </Typography>
                  <Card
                    classes={{ root: themeConfig.mode === ThemeMode.DARK ? 'bg-black' : 'bg-gray-100' }}
                    className="my-1 rounded-sm shadow-none !p-0"
                  >
                    <CardContent className="py-4 px-2">
                      <Typography
                        component={'p'}
                        dangerouslySetInnerHTML={{ __html: item.reason }}
                        className="h-full w-full bg-transparent outline-none text-lg"
                      />
                    </CardContent>
                    <CardActions>
                      <Typography className="text-gray-500 items-center text-[11px]">{item.address}</Typography>
                    </CardActions>
                  </Card>
                </TimelineContent>
              </Stack>
            ))
            .reverse()}
        </Timeline>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default InactivityReasonDisplay;
