import { PlusOutlined } from '@ant-design/icons';
import { Autocomplete, Button, Checkbox, FormControlLabel, InputLabel, Paper, Stack, TextField, Typography } from '@mui/material';
import { QueryObserverResult, RefetchOptions, useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { ISearch } from 'components/filters/SearchFilter';
import UniversalDialog from 'components/popups/UniversalDialog';
import CustomDataTable, { rowsPerPageOptions } from 'components/tables/CustomDataTable';
import { TJobCompleteData, TJobData } from 'components/tables/interfaces/jobTableInterface';
import useTimezone from 'hooks/useTimezone';
import { IAddFollowupPopup } from 'pages/Settings/types/types.followupPopup';
import React, { useEffect, useMemo, useState } from 'react';
import LogsServicesInstance from 'services/services.logs';
import { useSelector } from 'store';
import { snakeCaseToTitleCase } from 'utils/common';
import { logActionList } from 'utils/constants';

type TLogsHistory = {
  job?: TJobCompleteData;
  customer?: { id: string; name: string };
  refetchJob: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<TJobData | undefined, Error>>;
};

const JobLogs = (props: TLogsHistory) => {
  const { job, refetchJob } = props;
  const formatDateTime = useTimezone();
  const settingsState = useSelector((state) => state.settings.generalSettings);

  // ------- states -------
  const [paginationData, setPaginationData] = useState({ page: 0, rowsPerPage: rowsPerPageOptions[0] });
  const [displayViewedLogs, setDisplayViewedLogs] = useState<boolean>(false);

  const [searchData, setSearchData] = useState<ISearch | null>(null);
  const [isLogAddLoading, setIsLogAddLoading] = useState(false);

  const [addLogPopup, setAddLogPopup] = useState<IAddFollowupPopup>({ action: { open: false }, data: null });
  const [filter, setFilter] = useState({ action: '' });

  // ------- useQueries --------
  const { data: allJobLogs, isFetching: isLogsFetching } = useQuery({
    queryKey: [paginationData, searchData, displayViewedLogs],
    queryFn: () => LogsServicesInstance.getAllLogs(displayViewedLogs, paginationData, searchData),
    enabled: !!searchData && searchData?.search.length > 0
  });

  useEffect(() => {
    if (!filter.action) {
      setSearchData(null);
    }
    setSearchData({
      search: [
        [
          {
            field_name: 'action',
            field_value: filter.action,
            operator: 'contains'
          }
        ]
      ]
    });
  }, [filter]);

  useEffect(() => {
    if (job && job._id) {
      setSearchData({
        search: [
          [
            {
              field_name: 'job.id',
              field_value: job._id,
              operator: 'exactmatch'
            }
          ]
        ]
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job?._id]);

  // ------ handlers ------

  const handleChangePagination = (page: number, rowsPerPage: number) => {
    setPaginationData({ page, rowsPerPage });
  };

  // ------ columns -------

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'date',
        header: () => <span>Date</span>,
        cell: ({ row }) => {
          return (
            <Stack>
              <Typography variant="body1">
                {formatDateTime(row.original.created_at, {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour12: settingsState?.time_format === '12hrs'
                })}
              </Typography>
              <Typography variant="caption">
                {formatDateTime(row.original.created_at, {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: settingsState?.time_format === '12hrs'
                })}
              </Typography>
            </Stack>
          );
        }
      },
      {
        id: 'user',
        header: () => <span>User</span>,
        cell: ({ row }) => {
          return <div>{row.original?.user?.name || 'System'}</div>;
        }
      },
      {
        id: 'job_name',
        cell: ({ row }) => {
          return row.original?.job?.id ? row.original?.job?.name : '';
        },
        header: () => <span>Job Name</span>
      },
      {
        id: 'action',
        accessorFn: (row) => snakeCaseToTitleCase(row.action),
        header: () => <span>Action</span>
      },
      {
        id: 'description',
        accessorFn: (row) => row.description,
        header: () => <span>Description</span>
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleLogAdd = (openState: boolean) => {
    setAddLogPopup({ action: { open: openState } });
  };

  const [customerLogDescription, setCustomerLogDescription] = useState<string | null>(null);
  const handleCustomerLogDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerLogDescription(event.target.value);
  };

  const handleAddCustomerLog = async () => {
    setIsLogAddLoading(true);
    if (customerLogDescription && props.customer?.id) {
      const data = {
        description: customerLogDescription,
        module: 'job',
        action: 'log_added',
        is_customer_log_also: false,
        customer: { id: props.customer?.id, name: props.customer?.name },
        job: { id: job?._id ?? '', name: job?.job_name ?? '' }
      };
      const response = await LogsServicesInstance.createLog(data);
      if (response) {
        refetchJob();
        handleLogAdd(false);
        setIsLogAddLoading(false);
      }
    }
  };

  const handleFilterChange = (field_name: string, field_value: string) => {
    setFilter((prevFilter) => {
      const dummyData = JSON.parse(JSON.stringify(prevFilter));
      dummyData[field_name] = field_value;
      return dummyData;
    });
  };

  // ------ Components ------

  const FolllowupHeader = () => {
    return (
      <div className="flex items-center justify-between w-full px-4 my-6">
        <div className="sm:w-1/2 lg:w-1/3 w-full mr-2">
          <Autocomplete
            value={logActionList.find((singleUser) => filter.action === singleUser.value)}
            onChange={(event: any, newValue: { label: string; value: string } | null) =>
              !!newValue?.value && handleFilterChange('action', newValue.value)
            }
            id="controllable-states-demo"
            getOptionLabel={(option) => option.label}
            options={logActionList ?? []}
            renderInput={(params: any) => <TextField {...params} placeholder="Action" />}
          />
        </div>
        <div className="space-x-2">
          <FormControlLabel
            value={true}
            color="primary"
            checked={displayViewedLogs}
            control={
              <Checkbox onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setDisplayViewedLogs(checked)} />
            }
            label={'Viewed logs'}
          />
          <Button variant="contained" size="large" startIcon={<PlusOutlined />} color="primary" onClick={() => handleLogAdd(true)}>
            Add Log
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Paper elevation={3} className="flex flex-col items-start space-y-4">
        <FolllowupHeader />
        <CustomDataTable
          data={allJobLogs?.logs || []}
          columns={columns}
          count={allJobLogs?.count}
          onPaginationChange={handleChangePagination}
          isDataLoading={isLogsFetching}
        />
      </Paper>
      {addLogPopup.action.open && (
        <UniversalDialog
          onClose={() => handleLogAdd(false)}
          title={'Add new log'}
          action={{ fullWidth: true, ...addLogPopup.action }}
          primaryButonTitle="Add"
          disablePrimaryButton={isLogAddLoading}
          isPrimaryButtonLoading={isLogAddLoading}
          onSave={() => handleAddCustomerLog()}
        >
          <Stack gap={1}>
            <InputLabel>Description*</InputLabel>
            <TextField placeholder="Add a description" multiline minRows={3} maxRows={4} onChange={handleCustomerLogDescriptionChange} />
          </Stack>
        </UniversalDialog>
      )}
    </div>
  );
};

export default JobLogs;
