import useConfig from 'hooks/useConfig';
import ReactApexChart from 'react-apexcharts';
import { TDashboardGraphData, TDateFilter } from '../types/types.dashboard';

const LifecycleStatusGraph = ({
  colorData,
  jobWonResponse,
  jobWonDateFilter,
  companySettings,
  isJobWonDataFteching
}: {
  colorData: { estimate_created: string; jobs_completed: string };
  jobWonResponse: TDashboardGraphData;
  jobWonDateFilter: TDateFilter;
  companySettings?: any;
  isJobWonDataFteching: boolean;
}) => {
  const themeConfig = useConfig();

  return (
    <div className="w-full rounded-md">
      {jobWonDateFilter.filter_value === 'today' || jobWonDateFilter.filter_value === 'yesterday' ? (
        <>
          {jobWonResponse.single_day_date && (
            <ReactApexChart
              type="bar"
              options={{
                theme: {
                  mode: themeConfig.mode,
                  monochrome: {
                    enabled: true,
                    color: '#255aee',
                    shadeTo: 'light',
                    shadeIntensity: 0.65
                  }
                },
                chart: { stacked: true, toolbar: { show: false } },
                dataLabels: { enabled: false },

                title: { text: 'New Customers / Lead Sources', align: 'center' }
              }}
              series={[
                {
                  name: '',
                  data: [
                    {
                      x: 'Estimate Created',
                      y: [jobWonResponse.single_day_date[0]],
                      fillColor: colorData.estimate_created
                    },
                    {
                      x: 'Proposal Accepted',
                      y: [jobWonResponse.single_day_date[1]],
                      fillColor: '#28a745'
                    },
                    {
                      x: 'Jobs Completed',
                      y: [jobWonResponse.single_day_date[2]],
                      fillColor: colorData.jobs_completed
                    }
                  ]
                }
              ]}
            />
          )}
        </>
      ) : (
        <ReactApexChart
          options={{
            theme: {
              mode: themeConfig.mode
            },
            chart: { stacked: false, toolbar: { show: false }, selection: { enabled: false }, zoom: { enabled: false } },
            xaxis: {
              title: {
                text: ['this_year', 'last_year'].includes(jobWonDateFilter.filter_value) ? 'Month' : 'Day'
              }
            },
            dataLabels: { enabled: false },
            labels: jobWonResponse?.labels,
            title: { text: 'Billing', align: 'center' as 'center' },
            legend: { show: true }
          }}
          series={[
            {
              data: jobWonResponse.estimation_created || [],
              name: 'Estimate Created',
              color: colorData.estimate_created
            },
            {
              data: jobWonResponse.proposal_accepted || [],
              name: 'Proposal Accepted',
              color: '#28a745'
            },
            {
              data: jobWonResponse.job_completed || [],
              name: 'Jobs Completed',
              color: colorData.jobs_completed
            }
          ]}
          type="line"
        />
      )}
    </div>
  );
};

export default LifecycleStatusGraph;
