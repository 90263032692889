import { AlertOutlined } from '@ant-design/icons';
import { Divider, Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import UniversalDialog from 'components/popups/UniversalDialog';
import { useFormik } from 'formik';
import moment from 'moment';
import { TCustomerData } from 'pages/customers/types/type.customersPage';
import { useEffect, useRef, useState } from 'react';
import CustomerServicesInstance from 'services/services.customers';
import NotesTimelineView from './NotesTimelineView';

type TCustomerNotesProps = {
  customerData: TCustomerData;
  onNoteUpdate?: () => void;
};

const CustomerNotes = (props: TCustomerNotesProps) => {
  const { customerData } = props;
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [viewHistory, setViewHistory] = useState<{ state: boolean; data: any; type: 'customer' | 'alert' | null }>({
    state: false,
    data: null,
    type: null
  });

  // ----------------------------------effects-------------------------------------

  //----------------------------------Formik-------------------------------------
  const customerNote = useFormik<{ note: string }>({
    initialValues: { note: customerData.customer_notes?.[customerData.customer_notes.length - 1]?.note ?? '' },
    onSubmit: async (values, { setSubmitting }) => {
      props?.onNoteUpdate?.();
    }
  });

  const alertNote = useFormik<{ note: string }>({
    initialValues: { note: customerData.alert_notes?.[customerData.alert_notes.length - 1]?.note ?? '' },
    onSubmit: async (values, { setSubmitting }) => {
      props?.onNoteUpdate?.();
    }
  });

  // -------------------------- Use refs to track live note values --------------------------
  const customerNoteRef = useRef(customerNote.values.note);
  const alertNoteRef = useRef(alertNote.values.note);

  useEffect(() => {
    customerNoteRef.current = customerNote.values.note;
  }, [customerNote.values.note]);

  useEffect(() => {
    alertNoteRef.current = alertNote.values.note;
  }, [alertNote.values.note]);

  const checkCustomerNotes = async () => {
    if (!customerData?._id) return;
    const lastSavedNote = customerData.customer_notes?.[customerData.customer_notes.length - 1]?.note ?? '';
    if (customerNoteRef.current !== lastSavedNote) {
      await CustomerServicesInstance.updateCustomerNote(customerData._id, {
        note: customerNoteRef.current,
        is_alert_note: false
      });
      props?.onNoteUpdate?.();
    }
  };
  const checkAlertNotes = async () => {
    if (!customerData?._id) return;

    const lastSavedAlert = customerData.alert_notes?.[customerData.alert_notes.length - 1]?.note ?? '';
    if (alertNoteRef.current !== lastSavedAlert) {
      await CustomerServicesInstance.updateCustomerNote(customerData._id, {
        note: alertNoteRef.current,
        is_alert_note: true
      });
      props?.onNoteUpdate?.();
    }
  };

  // -------------------------- Auto-save on unmount (using refs) --------------------------
  useEffect(() => {
    return () => {
      // Check customer note
      checkCustomerNotes();
      // Check alert note
      checkAlertNotes();
    };
  }, []);

  return (
    <>
      <Grid container spacing={2} padding={2}>
        {/* ----------------------Customer Notes---------------------- */}
        <Grid item xs={12} md={5.75} component={'form'} onSubmit={customerNote.handleSubmit} onReset={customerNote.handleReset}>
          <Typography className="mb-2 font-medium">Customer Notes</Typography>
          <TextField
            value={customerNote.values.note}
            onChange={(e) => customerNote.setFieldValue('note', e.target.value)}
            placeholder="Write a note here..."
            minRows={4}
            multiline
            className="w-full bg-transparent"
          />

          {customerData.customer_notes && customerData.customer_notes.length > 0 && (
            <Typography
              className="flex items-center justify-end sm:text-xs text-[10px] text-gray-400 cursor-pointer underline mt-1"
              onClick={() => setViewHistory({ state: true, data: customerData.customer_notes, type: 'customer' })}
            >
              Updated by&nbsp;<strong>{customerData.customer_notes?.[customerData.customer_notes.length - 1]?.updated_by.name}</strong>
              &nbsp;on&nbsp;
              <strong>
                {customerData.customer_notes?.[customerData.customer_notes.length - 1]?.updated_at &&
                  moment(customerData.customer_notes?.[customerData.customer_notes.length - 1]?.updated_at).format('lll')}
              </strong>
            </Typography>
          )}
        </Grid>

        {/* ----------------------Divider---------------------- */}
        <Grid item xs={0} md={0.5} className="flex items-center justify-center">
          <Divider orientation="vertical" />
        </Grid>

        {/* ----------------------Alert Notes---------------------- */}
        <Grid item xs={12} md={5.75} component={'form'} onSubmit={alertNote.handleSubmit} onReset={alertNote.handleReset}>
          <Typography className="mb-2 font-medium">
            <AlertOutlined className="mr-1" />
            Customer Alert Notes
          </Typography>

          <TextField
            color="error"
            error
            value={alertNote.values.note}
            sx={{ '& .MuiInputBase-root': { color: 'tomato' } }}
            onChange={(e) => alertNote.setFieldValue('note', e.target.value)}
            placeholder="Write alert note here..."
            minRows={4}
            multiline
            className="w-full bg-transparent"
          />

          {customerData.alert_notes && customerData.alert_notes.length > 0 && (
            <Typography
              className="flex items-center justify-end sm:text-xs text-[10px] text-gray-400 cursor-pointer underline mt-1"
              onClick={() => setViewHistory({ state: true, data: customerData.alert_notes, type: 'alert' })}
            >
              Updated by&nbsp;<strong>{customerData.alert_notes?.[customerData.alert_notes.length - 1]?.updated_by.name}</strong>
              &nbsp;on&nbsp;
              <strong>
                {customerData.alert_notes?.[customerData.alert_notes.length - 1]?.updated_at &&
                  moment(customerData.alert_notes?.[customerData.alert_notes.length - 1]?.updated_at).format('lll')}
              </strong>
            </Typography>
          )}
        </Grid>
      </Grid>
      {viewHistory.state && (
        <UniversalDialog
          onClose={() => setViewHistory({ state: false, data: null, type: null })}
          hasPrimaryButton={false}
          action={{ open: viewHistory.state, ...(isMobile ? { fullScreen: true } : { fullWidth: true }), maxWidth: 'sm' }}
          title={`${customerData.first_name}'s ${viewHistory.type === 'alert' ? 'alert ' : ' '}notes history`}
        >
          <NotesTimelineView data={viewHistory.data} />
        </UniversalDialog>
      )}
    </>
  );
};

export default CustomerNotes;
