import { DeleteOutlined, EditOutlined, LoadingOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import FormSubmissionAlertMessage from 'components/FormSubmissionAlertMessage';
import UniversalDialog from 'components/popups/UniversalDialog';
import { IEstimationOption, TJobCompleteData, TOfficeToWorkerNote, TScheduleSMSData } from 'components/tables/interfaces/jobTableInterface';
import { universalRenerMedia } from 'components/UniversalRenderMedia';
import { getIn, useFormik } from 'formik';
import useAuth from 'hooks/useAuth';
import useTimezone from 'hooks/useTimezone';
import { TCustomerData } from 'pages/customers/types/type.customersPage';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import EstimateServicesInstance from 'services/services.estimates';
import JobServicesInstance from 'services/services.jobs';
import MediaServicesInstance from 'services/services.media';
import PageConfigurationServicesInstance from 'services/services.pageConfigurations';
import { dispatch, useSelector } from 'store';
import { getGeneralSettings } from 'store/reducers/customReducers/slice.settings';
import { TMedia } from 'types/globalTypes/type.media';
import { formatePhonesData, formatJobName } from 'utils/common';
import { jobStates } from 'utils/constants';
import trimFc from 'utils/trimFc';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import AddScheduleSMSForm from './AddScheduleSMSForm';
import GoogleMaps from './google-map-autocomplete/GoogleMap';
import SelectJobImages from './SelectJobImages';

type TEditOfficeToWorkerNotes = { index: number; text: string };

const AddWorkOrderForm = (props: {
  existingData?: TJobCompleteData;
  isEditMode?: boolean;
  customerData?: TCustomerData;
  onSubmitSuccess?: (jobid: string) => void;
}) => {
  // ----------------------------------------Constants----------------------------------------
  const estimateOptions = [
    { title: 'Use a template from old estimate', value: 'prior' },
    { title: 'Create a new estimate', value: 'new' }
  ];
  const [files, setFiles] = useState<string[]>([]);

  const { existingData, isEditMode, customerData } = props;
  const [isPriorEstimateSelected, setIsPriorEstimateSelected] = useState<boolean>();
  const [officeToWorkerNotes, setOfficeToWorkerNotes] = useState<string>('');
  const [editOfficeToWorkerNotes, setEditOfficeToWorkerNotes] = useState<TEditOfficeToWorkerNotes>();
  const [openSheduleSMS, setOpenSheduleSMS] = useState(false);
  const [sheduleSMSValues, setSheduleSMSValues] = useState<TScheduleSMSData>();
  const [selectedEstimate, setSelectedEstimate] = useState<TJobCompleteData>({} as TJobCompleteData);
  const [allEstimatesData, setAllEstimatesData] = useState<TJobCompleteData[]>([] as unknown as TJobCompleteData[]);
  const [selectedEstimateOption, setSelectedEstimateOption] = useState<string>();
  const [openJobImagesPopup, setOpenJobImagesPopup] = useState<boolean>(false);

  const { user } = useAuth();
  const theme = useTheme();
  const formatDateTime = useTimezone();
  const settingsState = useSelector((state) => state.settings.generalSettings);

  //----------------------------------useQuery--------------------------------
  const { data: mediaData } = useQuery({
    queryKey: ['media_data'],
    queryFn: () => MediaServicesInstance.getMedia('customers', false, customerData?._id, 'media'),
    enabled: !!customerData?._id && customerData?._id.length > 0
  });

  const { data: allPageConfigData } = useQuery({
    queryKey: ['page_config'],
    queryFn: () => PageConfigurationServicesInstance.getPageConfig()
  });
  const { data: allJobsData } = useQuery({
    queryKey: ['all_estimates'],
    queryFn: () =>
      JobServicesInstance.getAllJobs(undefined, {
        search: [
          [
            {
              field_name: 'customer.id',
              field_value: customerData?._id ?? '',
              operator: 'exactmatch'
            }
          ],
          [
            {
              field_name: 'company_id',
              field_value: user?.company_id ?? '',
              operator: 'exactmatch'
            }
          ],
          [
            {
              field_name: 'status',
              field_value: 1,
              operator: 'exactmatch'
            }
          ],
          [
            {
              field_name: 'previous_states',
              field_value: [jobStates.NEED_REVIEW],
              operator: 'in'
            }
          ]
        ]
      })
  });

  useEffect(() => {
    if (allJobsData?.jobs?.length) {
      setSelectedEstimateOption(estimateOptions[0].value);
      setIsPriorEstimateSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allJobsData]);

  //-------------------------------------Formik---------------------------

  const formik = useFormik({
    initialValues: {
      job_name: '',
      state: 'work_order',
      customer: {
        id: '',
        name: '',
        phones: [],
        emails: []
      },
      is_customer_address: true,
      display_job_images: false,
      address: {
        address1: '',
        address2: '',
        zip: '',
        state: '',
        city: '',
        country: ''
      },
      office_to_worker_notes: [] as TOfficeToWorkerNote[],
      log_note: 'Setting up an Estimate/Assigning an Estimator.',
      job_images: []
    },
    validationSchema: Yup.object().shape({
      log_note: Yup.string().max(255).required('This field is required'),
      address: Yup.object().when('is_customer_address', ([is_customer_address], schema) => {
        if (!is_customer_address) {
          return schema.shape({
            address1: Yup.string().required('Address is required'),
            zip: Yup.string().required('Zip is required'),
            state: Yup.string().required('State is required'),
            city: Yup.string().required('City is required'),
            country: Yup.string().required('Country is required')
          });
        }
        return schema.nullable();
      })
    }),

    onSubmit: async (values: TJobCompleteData, { setSubmitting }) => {
      setSubmitting(true);

      if (allJobsData?.jobs?.length && selectedEstimateOption === 'prior' && Object.keys(selectedEstimate).length === 0) {
        setIsPriorEstimateSelected(false);

        return;
      }

      if (officeToWorkerNotes.length) {
        const currentDateTime = new Date();
        const newOfficeToWorkerNotes = [...(formik.values.office_to_worker_notes ?? ([] as TOfficeToWorkerNote[]))];
        newOfficeToWorkerNotes.push({
          text: officeToWorkerNotes,
          id: uuidv4(),
          created_at: currentDateTime.toISOString(),
          created_by: { id: user?._id as string, name: `${user?.first_name} ${user?.last_name}` },
          updated_at: currentDateTime.toISOString(),
          updated_by: { id: user?._id as string, name: `${user?.first_name} ${user?.last_name}` }
        });
        values.office_to_worker_notes = newOfficeToWorkerNotes;
        setOfficeToWorkerNotes('');
      }
      await handleJobFormSubmit(values);
      dispatch(getGeneralSettings());

      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (!isEditMode && !!existingData && Object.keys(existingData).length > 1) {
      formik.setValues({ ...existingData, is_customer_address: true, log_note: 'Setting up an Estimate/Assigning an Estimator.' });
    }
  }, [existingData, isEditMode]);

  useEffect(() => {
    if (customerData?.address && !!Object.keys(customerData?.address).length) {
      formik.setFieldValue('address', {
        address1: customerData?.address.address1 as string,
        address2: customerData?.address.address2 as string,
        zip: customerData?.address.zip as string,
        state: customerData?.address.state as string,
        city: customerData?.address.city as string,
        country: customerData?.address.country as string
      });
      formik.setFieldValue('customer', {
        id: customerData?._id ?? '',
        name: customerData?.first_name ?? '',
        phones: !!customerData ? formatePhonesData(customerData) : [],
        emails: customerData?.emails ?? []
      });
    }
  }, [customerData?.address?.address1]);

  const handleJobFormSubmit = async (values: TJobCompleteData) => {
    let response: any;
    if (!values.display_job_images) delete values.job_images;
    if (isEditMode && existingData) {
      //   existingData._id && (await CustomerServicesInstance.editCustomer(existingData?._id, formattedValue));
    } else {
      if (selectedEstimateOption === 'prior' && isPriorEstimateSelected) {
        const priorEstimateValues: any = { ...values, job_id: selectedEstimate._id };
        delete priorEstimateValues.state;
        response = await JobServicesInstance.createJobWithPriorEstimate(priorEstimateValues);
      } else {
        if (values.is_customer_address) {
          delete values.address;
        }
        response = await JobServicesInstance.createJob(values);

        if (response && response.job.id) {
          if (selectedEstimateOption === 'prior' && !!selectedEstimate?.estimations?.options?.length) {
            const tempOptionData = JSON.parse(JSON.stringify(selectedEstimate));
            if (tempOptionData.estimations && tempOptionData.estimations.options) {
              tempOptionData.estimations.options.forEach((eachOption: IEstimationOption) => {
                eachOption.id = uuidv4();
              });
              await EstimateServicesInstance.upsertEstimateOptions(
                response.job.id,
                tempOptionData.estimations.options,
                tempOptionData.estimations?.terms_and_conditions,
                'need_review'
              );
            }
          }
        }
      }
    }

    if (!!sheduleSMSValues && !!Object.keys(sheduleSMSValues).length) {
      const scheduleObj: any = {
        ...sheduleSMSValues,
        job: { id: response.job.id, name: response.job.name as string },
        customer: { id: response.customer?.id as string, name: response.customer?.name as string }
      };

      await JobServicesInstance.addScheduleSMS(scheduleObj);
    }

    props?.onSubmitSuccess?.(response.job.id);
  };
  // --------------------------Handler-------------------
  const handleDisplayJobImages = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === 'true') {
      setOpenJobImagesPopup(true);
      return;
    }
    formik.setFieldValue('display_job_images', false);
    formik.setFieldValue('job_images', []);
  };
  const handleSubmitJobImages = (jobImages: string[]) => {
    if (!!jobImages.length) {
      formik.setFieldValue('job_images', jobImages);
      formik.setFieldValue('display_job_images', true);
      setOpenJobImagesPopup(false);
    }
  };
  const handleRemoveSelectedfile = (index: number) => {
    if (!!formik.values?.job_images?.length) {
      const dummyData = [...formik.values.job_images];
      dummyData.splice(index, 1);
      formik.setFieldValue('job_images', dummyData);
      if (dummyData.length === 0) formik.setFieldValue('display_job_images', false);
    }
  };

  //----SheduleSMS--------
  const handleSheduleSMS = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpenSheduleSMS(true);
  };

  const onAddScheduleSMSFromSubmit = (values: TScheduleSMSData) => {
    setSheduleSMSValues(values);
    setOpenSheduleSMS(false);
  };

  //-----Notes---------
  const handleOfficeToWorkerNotes = () => {
    if (officeToWorkerNotes.length) {
      const currentDateTime = new Date();
      const newOfficeToWorkerNotes = [...(formik.values.office_to_worker_notes ?? ([] as TOfficeToWorkerNote[]))];
      newOfficeToWorkerNotes.push({
        text: officeToWorkerNotes,
        id: uuidv4(),
        created_at: currentDateTime.toISOString(),
        created_by: { id: user?._id as string, name: `${user?.first_name} ${user?.last_name}` },
        updated_at: currentDateTime.toISOString(),
        updated_by: { id: user?._id as string, name: `${user?.first_name} ${user?.last_name}` }
      });
      formik.setFieldValue('office_to_worker_notes', newOfficeToWorkerNotes);
      setOfficeToWorkerNotes('');
    }
  };

  const handleRemoveNote = (noteIndex: number) => {
    const newNotesData = [...(formik.values.office_to_worker_notes ?? [])];
    newNotesData.splice(noteIndex, 1);
    formik.setFieldValue('office_to_worker_notes', newNotesData);
  };

  const handleEditNote = (noteIndex: number) => {
    setEditOfficeToWorkerNotes({ text: '', index: noteIndex });
  };

  const handleSaveEditedNote = (noteIndex: number) => {
    const newNotesData = [...(formik.values.office_to_worker_notes ?? [])];
    if (editOfficeToWorkerNotes?.index === noteIndex && editOfficeToWorkerNotes.text.length) {
      newNotesData[noteIndex].text = editOfficeToWorkerNotes.text;
      newNotesData[noteIndex].updated_at = new Date().toISOString();
      newNotesData[noteIndex].updated_by = { id: user?._id as string, name: `${user?.first_name} ${user?.last_name}` };
      setEditOfficeToWorkerNotes({ text: '', index: null as unknown as number });
      formik.setFieldValue('office_to_worker_notes', newNotesData);
    }
  };

  //----------------------------------useEffects--------------------------------
  useEffect(() => {
    if (!isEditMode) {
      formik.setFieldValue(
        'job_name',
        formatJobName(
          allPageConfigData?.job_page?.job_name ?? '',
          customerData?.first_name ?? '',
          customerData?.last_name ?? '',
          allPageConfigData?.job_page.job_number
        )
      );

      formik.setFieldValue('is_customer_address', allPageConfigData?.job_page?.is_customer_address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPageConfigData?.job_page]);

  useEffect(() => {
    if (formik.values.is_customer_address) {
      if (customerData?.address && !!Object.keys(customerData?.address).length) {
        formik.setFieldValue('address', {
          address1: customerData?.address.address1 as string,
          address2: customerData?.address.address2 as string,
          zip: customerData?.address.zip as string,
          state: customerData?.address.state as string,
          city: customerData?.address.city as string,
          country: customerData?.address.country as string
        });
      }
    } else {
      formik.setFieldValue('address', { address1: '', address2: '', state: '', city: '', zip: '', country: '' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.is_customer_address, customerData?.address]);

  useEffect(() => {
    if (allJobsData) {
      const dummyData = allJobsData.jobs.filter((singleJob: TJobCompleteData) => singleJob.estimations?.options.length !== 0);
      setAllEstimatesData(dummyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allJobsData]);
  useEffect(() => {
    !!mediaData &&
      setFiles(() => {
        const urls = (mediaData as TMedia[]).map((singleMedia: TMedia) => singleMedia.url);
        return urls;
      });
  }, [mediaData]);

  return (
    <>
      <Grid container spacing={2} component={'form'} onSubmit={formik.handleSubmit}>
        <Grid item xs={12} container spacing={3}>
          {/* -----------------------------------------Job Name--------------------------------- */}
          <Grid item xs={12} sm={6} md={formik.values.display_job_images ? 3 : 4}>
            <InputLabel htmlFor="job_name">Job Name</InputLabel>
            <OutlinedInput
              id="job_name"
              value={formik.values.job_name}
              name="job_name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              fullWidth
            />
          </Grid>

          {/* -----------------------------------------DO YOU WANT TO CHOOSE PRIOR ESTIMATE?*---------------------------------- */}
          {allEstimatesData.length > 0 && (
            <>
              <Grid item xs={12} sm={6} md={formik.values.display_job_images ? 3 : 4}>
                <InputLabel htmlFor="do_you_want_to_choose_prior_estimate">Do you want to choose a prior estimate *</InputLabel>
                <Autocomplete
                  id="do_you_want_to_choose_prior_estimate"
                  value={estimateOptions.find((singleValue) => singleValue.value === selectedEstimateOption)}
                  disableClearable
                  getOptionLabel={(option) => option.title}
                  onChange={(event: React.SyntheticEvent<Element, Event>, newValue: { value: string; title: string }) => {
                    if (newValue.value === 'new') {
                      setSelectedEstimate({} as TJobCompleteData);
                    }
                    setSelectedEstimateOption(newValue.value);
                  }}
                  options={estimateOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                    />
                  )}
                />
              </Grid>
              {selectedEstimateOption === 'prior' && (
                <Grid item xs={12} sm={6} md={formik.values.display_job_images ? 3 : 4}>
                  <InputLabel htmlFor="select_prior_estimate">Select Prior Estimate*</InputLabel>
                  <Autocomplete
                    id="select_prior_estimate"
                    value={allEstimatesData.find((singleData) => singleData._id === selectedEstimate._id)}
                    disableClearable
                    getOptionLabel={(option) => option?.job_name ?? ''}
                    onChange={(event: React.SyntheticEvent<Element, Event>, newValue: TJobCompleteData) => {
                      setIsPriorEstimateSelected(true);
                      setSelectedEstimate(newValue);
                    }}
                    options={allEstimatesData}
                    renderInput={(params) => (
                      <TextField
                        helperText={!isPriorEstimateSelected && 'This Field is required'}
                        error={!isPriorEstimateSelected}
                        {...params}
                        sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                      />
                    )}
                  />
                </Grid>
              )}
            </>
          )}

          {/* -----------------------------------------Is this job at customer's address ?---------------------------------- */}
          <Grid item xs={12} sm={6} md={formik.values.is_customer_address ? 4 : 12}>
            <InputLabel htmlFor="is_customer_address">Is this job at customer's address ?</InputLabel>
            <Stack direction={'row'} spacing={2}>
              <RadioGroup
                row
                aria-label="is_customer_address"
                onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => {
                  formik.setFieldValue('is_customer_address', value === 'true');
                }}
                name="is_customer_address"
                id="is_customer_address"
                value={formik.values.is_customer_address}
              >
                <FormControlLabel value={true} control={<Radio />} label={'Yes'} />
                <FormControlLabel value={false} control={<Radio />} label={'No'} />
              </RadioGroup>
            </Stack>
          </Grid>

          {/**------------------Address*--------------------------- */}
          {!formik?.values?.is_customer_address && (
            <Grid item xs={12} spacing={1}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <GoogleMaps formik={formik} />
                </Grid>
                {/* -----------------------------------------Address 1------------------------------ */}
                <Grid item xs={12} sm={6}>
                  <Stack>
                    <InputLabel required htmlFor="address1">
                      Address1
                    </InputLabel>
                    <TextField
                      multiline
                      minRows={2}
                      maxRows={2}
                      id="address1"
                      name="address.address1"
                      placeholder=""
                      value={formik?.values?.address?.address1}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      fullWidth
                      error={getIn(formik.touched, 'address.address1') && getIn(formik.errors, 'address.address1')}
                    />
                    {getIn(formik.touched, 'address.address1') && getIn(formik.errors, 'address.address1') && (
                      <FormHelperText error id="helper-text-address1">
                        {getIn(formik.errors, 'address.address1')}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>

                {/* -----------------------------------------Address 2------------------------------ */}
                <Grid item xs={12} sm={6}>
                  <Stack>
                    <InputLabel htmlFor="address2">Address2</InputLabel>
                    <TextField
                      multiline
                      minRows={2}
                      maxRows={2}
                      id="address2"
                      name="address.address2"
                      placeholder=""
                      value={formik?.values?.address?.address2}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                    />
                  </Stack>
                </Grid>
                {/* -----------------------------------------City------------------------------ */}
                <Grid item xs={12} sm={3}>
                  <Stack>
                    <InputLabel required htmlFor="city">
                      City
                    </InputLabel>
                    <TextField
                      id="city"
                      name="address.city"
                      placeholder=""
                      value={formik?.values?.address?.city}
                      onChange={trimFc(formik)}
                      onBlur={formik.handleBlur}
                      fullWidth
                      error={getIn(formik.touched, 'address.city') && getIn(formik.errors, 'address.city')}
                    />
                    {getIn(formik.touched, 'address.city') && getIn(formik.errors, 'address.city') && (
                      <FormHelperText error id="helper-text-city">
                        {getIn(formik.errors, 'address.city')}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                {/* -----------------------------------------State------------------------------ */}
                <Grid item xs={12} sm={3}>
                  <Stack>
                    <InputLabel required htmlFor="state">
                      State
                    </InputLabel>
                    <TextField
                      id="state"
                      name="address.state"
                      placeholder=""
                      value={formik?.values?.address?.state}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      error={getIn(formik.touched, 'address.state') && getIn(formik.errors, 'address.state')}
                    />
                    {getIn(formik.touched, 'address.state') && getIn(formik.errors, 'address.state') && (
                      <FormHelperText error id="helper-text-state">
                        {getIn(formik.errors, 'address.state')}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>

                {/* -----------------------------------------Zip------------------------------ */}
                <Grid item xs={12} sm={3}>
                  <Stack>
                    <InputLabel required htmlFor="zip">
                      Zip
                    </InputLabel>
                    <TextField
                      id="zip"
                      name="address.zip"
                      placeholder=""
                      value={formik?.values?.address?.zip}
                      onChange={trimFc(formik)}
                      onBlur={formik.handleBlur}
                      fullWidth
                      error={getIn(formik.touched, 'address.zip') && getIn(formik.errors, 'address.zip')}
                    />
                    {getIn(formik.touched, 'address.zip') && getIn(formik.errors, 'address.zip') && (
                      <FormHelperText error id="helper-text-zip">
                        {getIn(formik.errors, 'address.zip')}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                {/* -----------------------------------------country------------------------------ */}
                <Grid item xs={12} sm={3}>
                  <Stack>
                    <InputLabel required htmlFor="country">
                      Country
                    </InputLabel>
                    <TextField
                      id="country"
                      name="address.country"
                      placeholder=""
                      value={formik?.values?.address?.country}
                      onChange={trimFc(formik)}
                      onBlur={formik.handleBlur}
                      fullWidth
                      error={getIn(formik.touched, 'address.country') && getIn(formik.errors, 'address.country')}
                    />
                    {getIn(formik.touched, 'address.country') && getIn(formik.errors, 'address.country') && (
                      <FormHelperText error id="helper-text-country">
                        {getIn(formik.errors, 'address.country')}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* -----------------------------------------Would you like to display any job images?---------------------------------- */}
          <Grid item xs={12} md={3}>
            <Stack>
              <InputLabel htmlFor="display_job_images">Would you like to display any job images?</InputLabel>
              <Stack direction={'row'} spacing={2}>
                <RadioGroup
                  row
                  aria-label="display_job_images"
                  onChange={handleDisplayJobImages}
                  name="display_job_images"
                  id="display_job_images"
                  value={formik.values.display_job_images ? 'true' : 'false'}
                >
                  <FormControlLabel value={true} control={<Radio />} label={'Yes'} />
                  <FormControlLabel value={false} control={<Radio />} label={'No'} />
                </RadioGroup>
              </Stack>
            </Stack>
          </Grid>
          {/*-----------------------------------------------------Display Job Images----------------------------------------------------------- */}
          {formik.values.display_job_images && !!formik.values?.job_images?.length && (
            <Grid item xs={12}>
              <ImageList className="space-x-1 space-y-1" cols={5}>
                {formik.values.job_images?.map((singleFileUrl: string, index: number) => (
                  <ImageListItem
                    key={index}
                    component={Card}
                    className="flex justify-center"
                    sx={{ maxHeight: '250px', minHeight: '100px', minWidth: '100px' }}
                  >
                    {universalRenerMedia(singleFileUrl, index)}
                    <ImageListItemBar
                      className="p-1 bg-transparent"
                      position="top"
                      actionIcon={<DeleteOutlined className="font-lg text-red-500" onClick={() => handleRemoveSelectedfile(index)} />}
                      actionPosition="right"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Grid>
          )}
        </Grid>

        {/*-----------------------------------Schedule SMS----------------------- */}
        <Grid item xs={12}>
          <Button className="mb-4 space-y-4" variant="dashed" color="primary" onClick={handleSheduleSMS}>
            Add a Schedule SMS
          </Button>
        </Grid>

        {/* -----------------------------Office To Worker Notes------------------------------ */}

        <Grid item xs={12} sm={8} md={6} className="space-y-2">
          <div>
            <InputLabel htmlFor="office_to_worker_notes">Office To Worker Notes</InputLabel>

            <TextField
              id={`office_to_worker_notes`}
              fullWidth
              name={`office_to_worker_notes`}
              multiline
              rows={3}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                setOfficeToWorkerNotes(event.target.value);
              }}
              value={officeToWorkerNotes}
            />
          </div>
          <Button
            className="mt-4 space-y-4 self-end"
            variant="outlined"
            color="primary"
            type="reset"
            onClick={() => handleOfficeToWorkerNotes()}
          >
            Add Note
          </Button>

          {formik.values?.office_to_worker_notes?.map((officeToWorkerNote, noteIndex) => {
            const date = formatDateTime(officeToWorkerNote.created_at, {
              year: 'numeric',
              day: '2-digit',
              month: '2-digit',
              hour12: settingsState?.time_format === '12hrs'
            });
            const time = formatDateTime(officeToWorkerNote.created_at, {
              hour: '2-digit',
              minute: '2-digit',
              hour12: settingsState?.time_format === '12hrs'
            });
            return (
              <Card className=" w-full rounded-sm bg-yellow-50 mt-3">
                <CardHeader
                  className="mb-2 p-3"
                  title={
                    <div className="flex items-center justify-between">
                      {officeToWorkerNote.text !== '' && (
                        <div className="flex items-start justify-between">
                          <div className="flex items-center md:flex-row flex-col space-x-2">
                            <Typography variant="body2">
                              <span className="text-gray-500 font-bold">Created At:</span> <span>{date}</span>&nbsp;
                              <span> {time}</span>
                            </Typography>
                          </div>
                        </div>
                      )}
                      {editOfficeToWorkerNotes?.index !== noteIndex ? (
                        <ButtonGroup variant="text">
                          <IconButton autoFocus color="primary" onClick={() => handleEditNote(noteIndex)}>
                            <EditOutlined />
                          </IconButton>

                          <IconButton color="error" onClick={() => handleRemoveNote(noteIndex)}>
                            <PlusOutlined className="rotate-45" />
                          </IconButton>
                        </ButtonGroup>
                      ) : (
                        <ButtonGroup variant="text">
                          <Button
                            autoFocus
                            size="small"
                            // variant="outlined"
                            color="primary"
                            startIcon={<SaveOutlined />}
                            onClick={() => handleSaveEditedNote(noteIndex)}
                          >
                            Save
                          </Button>
                          <Button
                            startIcon={<PlusOutlined className="rotate-45" />}
                            size="small"
                            color="error"
                            onClick={() => setEditOfficeToWorkerNotes({ text: '', index: null as unknown as number })}
                          >
                            Cancel
                          </Button>
                        </ButtonGroup>
                      )}
                    </div>
                  }
                />
                <CardContent className="border-l-2 border-l-yellow-300 m-3 flex items-start pt-1">
                  {editOfficeToWorkerNotes?.index !== noteIndex ? (
                    // <Typography  variant="body1" className="break-words">
                    //   {}
                    // </Typography>
                    <p className="font-sans" dangerouslySetInnerHTML={{ __html: officeToWorkerNote?.text }} />
                  ) : (
                    <TextField
                      variant="standard"
                      className="w-full"
                      id={`office_to_worker_notes[${noteIndex}]`}
                      name={`office_to_worker_notes[${noteIndex}]`}
                      multiline
                      rows={3}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setEditOfficeToWorkerNotes({ index: noteIndex, text: event.target.value });
                      }}
                      value={editOfficeToWorkerNotes.text || officeToWorkerNote?.text}
                    />
                  )}
                </CardContent>
              </Card>
            );
          })}
        </Grid>

        {/* -----------------------------Log Notes------------------------------ */}
        <Grid item xs={12} sm={8} md={6}>
          <Stack>
            <InputLabel htmlFor="log_note">Log Notes*</InputLabel>
            <TextField
              id="log_note"
              placeholder="Log Notes"
              name="log_note"
              multiline
              rows={3}
              onChange={trimFc(formik)}
              value={formik.values?.log_note}
            />
            {formik.touched.log_note && formik.errors.log_note && (
              <FormHelperText error id="helper-text-log_note-signup">
                {formik.errors?.log_note}
              </FormHelperText>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            {FormSubmissionAlertMessage(formik)}

            <Button
              type="submit"
              startIcon={formik.isSubmitting ? <LoadingOutlined /> : <SaveOutlined />}
              variant="shadow"
              size="large"
              className="py-3 px-10 text-lg"
              disabled={formik.isSubmitting}
            >
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>
      {openJobImagesPopup && (
        <UniversalDialog
          onClose={() => setOpenJobImagesPopup(false)}
          hasPrimaryButton={false}
          action={{ open: openJobImagesPopup, fullWidth: true, maxWidth: 'sm' }}
          title={'Job Media'}
        >
          <SelectJobImages files={files} onSubmit={handleSubmitJobImages} jobImages={existingData?.job_images} />
        </UniversalDialog>
      )}
      {!!openSheduleSMS && !!customerData && (
        <AddScheduleSMSForm
          data={{
            customer: customerData,
            sheduleSMSValues: sheduleSMSValues ?? ({} as TScheduleSMSData)
          }}
          openSheduleSMS={openSheduleSMS}
          setOpenSheduleSMS={setOpenSheduleSMS}
          onAddScheduleSMSFromSubmit={onAddScheduleSMSFromSubmit}
          onClose={() => setOpenSheduleSMS(false)}
        />
      )}
    </>
  );
};

export default AddWorkOrderForm;
