import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TDateFilter } from 'pages/Dashboard/types/types.dashboard';
import ReactApexChart from 'react-apexcharts';
import { formateData } from 'utils/common';
import ReportDateFilter from '../components/ReportDateFilter';
import { TCompletedProject } from '../types/types.report';

const CompletedProjectReport = ({
  selectedFilter,
  setSelectedFilter,
  reportData
}: {
  selectedFilter: TDateFilter;
  setSelectedFilter: (value: TDateFilter) => void;
  reportData: TCompletedProject;
}) => {
  //------------------------Constants------------------------
  const theme = useTheme();
  const dateFilter = [
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: `This Year ${new Date().getFullYear()}`, value: 'this_year' },
    { label: 'Last 10 days', value: 'last_ten_days' },
    { label: 'Last 30 days', value: 'last_thirty_days' },
    { label: `Last Year ${new Date().getFullYear() - 1}`, value: 'last_year' },
    { label: 'Date Range', value: 'date_range' }
  ];
  const completedProjectData = [
    { label: 'Job Count', value: reportData.statistics.count, color: theme.palette.success.main },
    { label: 'Job Price', value: Number(reportData.statistics.job_price.toFixed(2)), color: theme.palette.error.main }
  ];

  const completedJobcountOption = {
    chart: {
      toolbar: { show: false }
    },
    title: { text: 'Completed Job Count', align: 'center' as 'center' },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: reportData.graph_statistics?.map((singleData) => singleData.date)
    }
  };
  const completedJobPriceOption = {
    chart: {
      toolbar: { show: false }
    },
    title: { text: 'Completed Job Price', align: 'center' as 'center' },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: reportData.graph_statistics?.map((singleData) => singleData.date)
    }
  };

  return (
    <div className="grid gap-2 lg:grid-cols-2">
      {/*--------------------Completed Project Card----------------- */}
      <Card className="p-4 col-span-2 space-y-3">
        <CardHeader
          className="flex flex-col md:flex-row"
          title={<div className="text-2xl  text-gray-600">Completed Projects</div>}
          action={
            <div className="py-7 md:py-0">
              <ReportDateFilter selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} dateFilters={dateFilter} />
            </div>
          }
        />
        {/*----------------------------Total Job Price and Count------------------------ */}
        <div className="grid grid-cols-2 gap-2">
          {completedProjectData.map((singleItem) => (
            <CardContent className="flex flex-col justify-center w-full shadow-lg shadow-slate-200 rounded-md">
              <Typography className="text-md text-center" color="textSecondary">
                {singleItem.label}
              </Typography>
              <Typography variant="h4" className="text-center" color={singleItem.color}>
                {singleItem.label === 'Job Count' ? singleItem.value : formateData(singleItem.value ?? 0, '$')}
              </Typography>
            </CardContent>
          ))}
        </div>{' '}
      </Card>
      {/*----------------------Completed Project Chart---------------------------------- */}
      <Card className="col-span-1 hidden sm:block">
        <ReactApexChart
          series={[
            {
              name: 'Job Count',
              data: reportData.graph_statistics?.map((singleData) => singleData.counts[0]) as number[],
              color: theme.palette.success.main
            }
          ]}
          type="bar"
          options={completedJobcountOption}
        />
      </Card>
      <Card className="sm:col-span-1 hidden sm:block">
        <ReactApexChart
          series={[
            {
              data: reportData.graph_statistics?.map((singleData) => singleData.counts[1]) as number[],
              name: 'Job Price',
              color: theme.palette.error.main
            }
          ]}
          type="bar"
          options={completedJobPriceOption}
        />
      </Card>
    </div>
  );
};

export default CompletedProjectReport;
