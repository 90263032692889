import { FilePdfOutlined } from '@ant-design/icons';
import { CardMedia, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import mime from 'mime';
import { getFileNameFromURL } from 'utils/common';

export const universalRenerMedia = (fileUrl: string, index: number) => {
  const mimeType = mime.getType(fileUrl)?.split('/');

  if (!!mimeType) {
    switch (mimeType[0]) {
      case 'image':
        return <CardMedia component={'img'} src={fileUrl} alt={`Media ${index + 1}`} loading="lazy" className="h-full max-h-56" />;
      case 'video':
        return <CardMedia component={'video'} src={fileUrl} controls className="h-full" />;
      case 'audio':
        return <CardMedia component={'audio'} src={fileUrl} controls className="max-h-28 sm:min-w-80 min-w-10" />;
      default:
        return (
          <Stack
            className="border-2 w-full h-full"
            alignItems="center"
            justifyContent="center"
            minHeight={'150px'}
            minWidth={'250px'}
            height="100%"
            sx={{ gap: 1 }}
          >
            <IconButton sx={{ fontSize: '40px' }}>
              <FilePdfOutlined />
            </IconButton>
            <Typography variant="body2" color="textSecondary" className="text-md font-semibold break-all text-center mx-3">
              {getFileNameFromURL(fileUrl)}.{mimeType[1]}
            </Typography>
          </Stack>
        );
    }
  }
};
