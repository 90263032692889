import { FileOutlined, PrinterFilled } from '@ant-design/icons';
import { ButtonGroup, IconButton, Stack, Table, TableBody, TableRow, Typography } from '@mui/material';
import { TJobItem } from 'components/tables/interfaces/jobTableInterface';
import useConfig from 'hooks/useConfig';
import useTimezone from 'hooks/useTimezone';
import { RefObject, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import ReactToPrint from 'react-to-print';
import { formatPhoneNumber, getStateAbbrevation, toTitleCase } from 'utils/common';
import { TJobInfo } from '../types/jobInfoType';
import { useSelector } from 'store';

const WidgetEstimateHeader = ({
  jobData,
  componentRef,
  dataByCategory,
  categoryTotal
}: {
  jobData?: TJobInfo;
  componentRef?: RefObject<HTMLDivElement>;
  dataByCategory?: { [key: string]: TJobItem[] }[];
  categoryTotal?: { [key: string]: number }[];
}) => {
  //----------------constants------------------
  const themeConfig = useConfig();
  const location = useLocation();
  const formatDateTime = useTimezone();
  const settingsState = useSelector((state) => state.settings.generalSettings);
  const pathParameters = location.pathname.split('/')[1];
  const [headerFields, setHeaderFields] = useState<string[]>([]);

  //------------------Handlers-------------------
  const renderItems = (field: string) => {
    switch (field) {
      case 'To':
        return (
          <div>
            <Typography variant="body2">{toTitleCase(jobData?.job?.customer?.name ?? '')}</Typography>
            <Typography variant="body2">
              {jobData?.job?.address && (
                <>
                  <Typography>
                    {jobData.job.address.address1 && `${jobData.job.address.address1}, `}
                    {jobData.job.address.address2 && `${jobData.job.address.address2}, `}
                  </Typography>
                  <Typography>
                    {jobData.job.address.city && `${jobData.job.address.city}, `}
                    {jobData.job.address.state && `${getStateAbbrevation(jobData.job.address.state)}, `}
                    {jobData.job.address.zip && `${jobData.job.address.zip}`}
                  </Typography>
                </>
              )}
            </Typography>

            <Typography variant="body2">{formatPhoneNumber(jobData?.job?.customer?.phones[0].phone ?? '')}</Typography>
          </div>
        );
      case 'Bill To':
        return (
          <div>
            <Typography variant="body2">
              {jobData?.job?.customer?.billing_contact?.first_name}&nbsp;&nbsp;{jobData?.job?.customer?.billing_contact?.last_name}
            </Typography>
            <Typography variant="body2">
              {jobData?.job?.customer && jobData?.job?.customer?.billing_contact && (
                <>
                  <Typography>
                    {jobData.job.customer.billing_contact.address.address1 && `${jobData.job.customer.billing_contact.address.address1}, `}
                    {jobData.job.customer.billing_contact.address.address2 && `${jobData.job.customer.billing_contact.address.address2}, `}
                  </Typography>
                  <Typography>
                    {jobData.job.customer.billing_contact.address.city && `${jobData.job.customer.billing_contact.address.city}, `}
                    {jobData.job.customer.billing_contact.address.state &&
                      `${getStateAbbrevation(jobData.job.customer.billing_contact.address.state)}, `}
                    {jobData.job.customer.billing_contact.address.zip && `${jobData.job.customer.billing_contact.address.zip}`}
                  </Typography>
                </>
              )}
            </Typography>

            <Typography variant="body2">{formatPhoneNumber(jobData?.job?.customer?.billing_contact?.phone1 ?? '')}</Typography>
            {/* Not sure what to display here */}
          </div>
        );
      case 'Job Address':
        return (
          <div>
            <Typography variant="body2">
              {jobData?.job?.address && (
                <>
                  <Typography>
                    {jobData.job.address.address1 && `${jobData.job.address.address1}, `}
                    {jobData.job.address.address2 && `${jobData.job.address.address2}, `}
                  </Typography>
                  <Typography>
                    {jobData.job.address.city && `${jobData.job.address.city}, `}
                    {jobData.job.address.state && `${getStateAbbrevation(jobData.job.address.state)}, `}
                    {jobData.job.address.zip && `${jobData.job.address.zip}`}
                  </Typography>
                </>
              )}
            </Typography>
          </div>
        );
      case 'Job Name':
        return <Typography variant="body2">{jobData?.job?.job_name}</Typography>;
      case 'Billing Email':
        return <Typography variant="body2">{jobData?.job?.customer?.billing_contact?.emails[0]}</Typography>;
      case 'Customer Email':
        return <Typography variant="body2">{jobData?.job?.customer?.emails[0]}</Typography>;
      case 'Billing Phone':
        return <Typography variant="body2">{formatPhoneNumber(jobData?.job?.customer?.billing_contact?.phone1 ?? '')}</Typography>;
      case 'Customer Phone':
        return <Typography variant="body2">{formatPhoneNumber(jobData?.job?.customer?.phones[0]?.phone ?? '')}</Typography>;
      case 'Attention':
        return <Typography variant="body2">{toTitleCase(jobData?.job?.customer?.name ?? '')}</Typography>;
      case 'Invoice#':
        return <Typography variant="body2">{jobData?.job?.invoice_number}</Typography>;
      case 'Invoice Date':
        return (
          <Typography variant="body2">
            {jobData?.job?.open_invoice_date
              ? formatDateTime(jobData?.job?.open_invoice_date, {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: settingsState?.time_format === '12hrs'
                })
              : jobData?.job?.job_completed_date &&
                formatDateTime(jobData?.job?.job_completed_date, {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: settingsState?.time_format === '12hrs'
                })}
          </Typography>
        );
      default:
        return null;
    }
  };

  const isFieldEmpty = (field: string) => {
    if (!jobData) return false;

    const { customer, address, job_name, invoice_number, open_invoice_date, job_completed_date } = jobData.job || {};
    const billing_contact = customer?.billing_contact || {};
    const phones = customer?.phones || [];

    switch (field) {
      case 'To':
      case 'Job Address':
        return [address?.address1, address?.address2, address?.state, address?.city, address?.zip].some((value) => value?.length > 0);

      case 'Bill To':
        return [
          billing_contact.address?.address1,
          billing_contact.address?.address2,
          billing_contact.address?.city,
          billing_contact.address?.state,
          billing_contact.address?.zip
        ].some((value) => value?.length > 0);

      case 'Job Name':
        return job_name?.length > 0;

      case 'Billing Email':
        return billing_contact.emails?.[0]?.length > 0;

      case 'Customer Email':
        return customer?.emails?.[0]?.length > 0;

      case 'Billing Phone':
        return [billing_contact.phone1].some(Boolean);

      case 'Customer Phone':
        return [phones[0]?.phone_country_code, phones[0]?.phone, phones[0]?.phone_extension].some(Boolean);

      case 'Attention':
        return customer?.name?.length > 0;

      case 'Invoice#':
        return !!invoice_number;

      case 'Invoice Date':
        return !!(open_invoice_date || job_completed_date);

      default:
        return true;
    }
  };

  useEffect(() => {
    if (!!pathParameters) {
      switch (pathParameters) {
        case 'invoice':
          setHeaderFields(['Job Name', 'Job Address', 'Bill To', 'Billing Email', 'Billing Phone', 'Invoice#']);
          break;
        case 'jobs':
          setHeaderFields(['To', 'Invoice#', 'Invoice Date', 'Job Name']);
          break;
        case 'proposal':
          setHeaderFields(['Job Name', 'Job Address', 'Customer Email', 'Customer Phone']);
          break;
        case 'signature':
          setHeaderFields(['Job Name', 'Job Details', 'Attention', 'Customer Email', 'Customer Phone']);
          break;
      }
    }
  }, [pathParameters]);

  return (
    <>
      {!!jobData && Object.keys(jobData).length > 0 && (
        <div className="space-y-3">
          {pathParameters === 'jobs' ? (
            <Typography variant="h4" p={2} className="font-bold" textAlign={'center'}>
              {jobData.company.company_name}
            </Typography>
          ) : (
            <div className="flex flex-row justify-between">
              <Typography variant="h4" className="font-bold p-4 text-orange-500">
                {jobData.company.company_name}
              </Typography>
              <ButtonGroup variant="text" size="large">
                {!!jobData?.job?.estimate_pdf_url?.length && (
                  <IconButton onClick={() => (window.location.href = `${jobData?.job?.estimate_pdf_url}`)}>
                    <FileOutlined className="text-2xl" />
                  </IconButton>
                )}

                {!!componentRef && (
                  <ReactToPrint
                    trigger={() => (
                      <IconButton>
                        <PrinterFilled className="text-2xl" />
                      </IconButton>
                    )}
                    content={() => componentRef.current}
                  />
                )}
              </ButtonGroup>
            </div>
          )}
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            padding={2}
            className="p-6"
            spacing={2}
            sx={{
              backgroundColor: themeConfig.mode === 'dark' ? 'ThreeDLightShadow' : 'white'
            }}
          >
            <Table>
              <TableBody className="space-y-5">
                {headerFields.slice(0, Math.ceil(headerFields.length / 2)).map(
                  (singleData, index) =>
                    singleData &&
                    isFieldEmpty(singleData) && (
                      <TableRow key={index} className="text-left border-hidden flex flex-col">
                        <Typography variant="subtitle2" className="font-semibold">
                          {singleData}
                        </Typography>
                        <Typography
                          className="break-all"
                          style={{
                            color: themeConfig.mode === 'dark' ? 'gray' : 'GrayText'
                          }}
                        >
                          {renderItems(singleData)}
                        </Typography>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
            <div className="border-l border-gray-300" />

            <Table>
              <TableBody className="space-y-5">
                {headerFields.slice(Math.ceil(headerFields.length / 2)).map(
                  (singleData, index) =>
                    singleData &&
                    isFieldEmpty(singleData) && (
                      <TableRow key={index} className="text-left border-hidden flex flex-col">
                        <Typography variant="subtitle2" className="font-semibold">
                          {singleData}
                        </Typography>
                        <Typography
                          style={{
                            color: themeConfig.mode === 'dark' ? 'gray' : 'GrayText'
                          }}
                          className="break-all"
                        >
                          {renderItems(singleData)}
                        </Typography>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </Stack>
        </div>
      )}
    </>
  );
};

export default WidgetEstimateHeader;
