import { AxiosRequestConfig } from 'axios';
import { ISearch } from 'components/filters/SearchFilter';
import { TExpenseData } from 'pages/expenses/types/types.expense';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import axiosServices from 'utils/axios';

class ExpenseServices {
  //----------------------Expense---------------------------
  getAllExpenseType = async () => {
    const response: { data: { data: { label: string; value: string; permission: string }[]; success: boolean } } = await axiosServices.get(
      `/api/expense/expense-types`
    );
    if (response && response.data && response.data.success) {
      return response.data.data;
    }
  };
  getAllExpense = async (paginationData?: any | null, searchData?: ISearch, is_global_expense?: boolean) => {
    try {
      const page = paginationData?.page + 1;
      const limit = paginationData?.rowsPerPage;
      const params: any = {
        ...(page && { page }),
        ...(limit && { limit }),
        ...(searchData && { filter: JSON.stringify(searchData) })
      };
      // Add is_global_expense filter if provided
      if (is_global_expense !== undefined) {
        params.is_global_expense = is_global_expense;
      }
      const response = await axiosServices.get(`/api/expense/`, {
        params,
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        return response.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  createExpense = async (expenseData: TExpenseData) => {
    try {
      const response = await axiosServices.post(`/api/expense/`, { ...expenseData } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Expense Created SuccessFully',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return response.data.success;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
}

const ExpenseServicesInstance = new ExpenseServices();
export default ExpenseServicesInstance;
