import { TPunchData } from 'store/reducers/punching';
import { TPayrollTimesSheetResponse } from 'pages/payrolls/types/types.payroll';
import axiosServices from 'utils/axios';
import IndexedDBServices from './services.localDb';
import { TIndexedDBResponse } from './types/types.services';
import { storedAPIInIndexedDB, storedDataInIndexedDB } from 'utils/common';
import { AxiosRequestConfig } from 'axios';
import { indexedDbStoreNames } from 'utils/constants';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
// import { IResponseWithJobs, IResponseWithScheduleSMS } from './types/types.services';

export type TClockInData = {
  date: string;
  clock_in: { time: string; location: { lat: string; long: string }; address: string };
  is_specified_time?: boolean;
  job?: { id: string; name: string };
};
export type TClockOutData = {
  clock_out: { time: string; location: { lat: string; long: string }; address: string };
  is_specified_time?: boolean;
  is_auto_logout: boolean;
};

export type TInactivityReasonData = {
  time: string;
  location: { lat: string; long: string };
  address: string;
  reason: string;
};
type TUpadtePunchData = {
  job?: {
    id: string;
    name: string;
  };
  note?: string;
  inactivity_reason?: string;
};

type AddBreakData = {
  breaks: { address: string; location: { lat: string; long: string } | null; start_time: string; end_time: string; note: string }[];
};

class PunchingServices {
  allPunches = async () => {
    try {
      if (!navigator.onLine) {
        const indexedDBInstance = new IndexedDBServices(indexedDbStoreNames.punch);
        const punchResponse = (await indexedDBInstance.getAllLocalData()) as TIndexedDBResponse;
        return punchResponse;
      }

      const response: { data: { data: TPunchData[] } } = await axiosServices.get('/api/punch', {
        locationNeeded: true
      } as AxiosRequestConfig);
      const punchResponse = await storedDataInIndexedDB(indexedDbStoreNames.punch, response.data);
      return punchResponse;
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  clockIn = async (clockInData: TClockInData) => {
    try {
      if (!navigator.onLine) {
        await storedAPIInIndexedDB({ apiUrl: 'api/punch/clock-in', data: clockInData, requestType: 'post' });
        return { success: true };
      }
      const response = await axiosServices.post(
        'api/punch/clock-in',
        {
          ...clockInData
        },
        {
          locationNeeded: true
        } as AxiosRequestConfig
      );
      if (response && response.data.success) {
        return response.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  clockOut = async (punchId: string, clockOutData: TClockOutData) => {
    try {
      if (!navigator.onLine) {
        await storedAPIInIndexedDB({ apiUrl: `api/punch/clock-out/${punchId}`, data: clockOutData, requestType: 'post' });
        return { success: true };
      }
      const response = await axiosServices.post(
        `api/punch/clock-out/${punchId}`,
        {
          ...clockOutData
        },
        {
          locationNeeded: true
        } as AxiosRequestConfig
      );
      if (response && response.data.success) {
        return response.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  updatePunch = async (punchId: string, punchData: TUpadtePunchData) => {
    try {
      const response = await axiosServices.patch(`api/punch/update-punch/${punchId}`, {
        ...punchData
      });
      if (response && response.data.success) {
        return response.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  getPunchList = async (punchId: string) => {
    try {
      const response: TPayrollTimesSheetResponse = await axiosServices.get(`api/punch/${punchId}`);
      if (response.data.success && response.data) return response.data.data;
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  addBreakTime = async (punchId: string | undefined, breakTime: AddBreakData) => {
    try {
      await axiosServices.post(`api/punch/add-break/${punchId}`, breakTime);
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  addInactivityReason = async (punchId: string, reasonData: TInactivityReasonData) => {
    try {
      const response = await axiosServices.patch(`api/punch/add-inactivity-reason/${punchId}`, {
        ...reasonData
      });
      if (response && response.data.success) {
        return response.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  addPunchNote = async (punchId: string | undefined, note: string) => {
    try {
      const response = await axiosServices.put(`api/punch/add-note/${punchId}`, { note });

      if (response.data.success)
        dispatch(
          openSnackbar({
            open: true,
            message: 'Punch note added.',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            severity: 'success',
            close: true
          })
        );
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };

  punchJobAction = async (
    punchId: string,
    action: 'start' | 'end',
    jobData: {
      id: string;
      name: string;
      time: string;
      specified_time?: string;
      location?: { lat: string; long: string };
      address?: string;
    }
  ) => {
    try {
      if (!navigator.onLine) {
        action === 'start'
          ? await storedAPIInIndexedDB({ apiUrl: `api/punch/start-job/${punchId}`, data: jobData, requestType: 'patch' })
          : await storedAPIInIndexedDB({ apiUrl: `api/punch/end-job/${punchId}`, data: jobData, requestType: 'patch' });
        return { success: true };
      }

      const url = action === 'start' ? `api/punch/start-job/${punchId}` : `api/punch/end-job/${punchId}`;

      const response = await axiosServices.patch(
        url,
        {
          ...jobData
        },
        {
          locationNeeded: true
        } as AxiosRequestConfig
      );
      if (response && response.data.success) {
        return response.data;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
}

const PunchingServicesInstance = new PunchingServices();
export default PunchingServicesInstance;
