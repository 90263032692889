import { CloseOutlined, UserAddOutlined } from '@ant-design/icons';
import { AppBar, Dialog, DialogContent, IconButton, Slide, Toolbar, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import AddUserForm from 'components/forms/AddUserForm';
import { IAddUserPopupWActions } from 'pages/Settings/types/types.userPopup';
import { forwardRef } from 'react';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddUserPopup = (props: IAddUserPopupWActions) => {
  const handleSuccess = () => {
    props?.onClose();
    props?.onSave();
  };

  return (
    <Dialog fullScreen {...props.action} open={props.action?.open} TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative', padding: 0 }}>
        <Toolbar className="!pl-6 !pr-2">
          <UserAddOutlined />
          <Typography sx={{ ml: 2, flex: 1 }} variant="h4" component="div">
            {props?.action?.isEditMode ? 'Edit User' : 'Add User'}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={props?.onClose} aria-label="close">
            <CloseOutlined className="font-bold text-xl" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent className="pb-32">
        <AddUserForm existingData={props?.data?.selectedData} isEditMode={props?.action?.isEditMode} onSuccess={handleSuccess} />
      </DialogContent>
    </Dialog>
  );
};

export default AddUserPopup;
