import { DeleteOutlined, UndoOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Autocomplete,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import GoogleMaps from 'components/forms/google-map-autocomplete/GoogleMap';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import { FormikContextType, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { CountryData } from 'react-phone-input-2';
import PublicSerivcesInstance from 'services/service.noAuth';
import { handlePhoneNumberInputChange } from 'utils/common';
import trimFc from 'utils/trimFc';
import { TAppointmentWidget } from '../types/types.appointmentWidget';
import { EstimateFormData } from '../types/types.estimateFormWidget';
import { WidgetRenderMedia } from './WidgetRenderMedia';

const CreateEstimateWidgetForm = ({
  formik,
  googleMapRef,
  createNewCaptchaPattern,
  captcha,
  companyId,
  uploadedImages,
  setUploadedImages
}: {
  formik: FormikContextType<EstimateFormData> | FormikContextType<EstimateFormData & TAppointmentWidget>;
  googleMapRef: React.MutableRefObject<
    | {
        resetSearchText: () => {};
      }
    | undefined
  >;
  createNewCaptchaPattern: (length: number) => void;
  captcha: string;
  companyId: string;
  uploadedImages: File[];
  setUploadedImages: React.Dispatch<React.SetStateAction<File[]>>;
}) => {
  const {
    values,
    handleChange,
    setFieldValue,
    touched,
    errors
  }: FormikContextType<EstimateFormData> | FormikContextType<EstimateFormData & TAppointmentWidget> = useFormikContext();

  const [referrals, setReferrals] = useState<{ label: string; value: string }[]>([]);

  const { data: referralsData } = useQuery({
    queryKey: ['referrals'],
    queryFn: () => PublicSerivcesInstance.systemReferrals(companyId)
  });

  useEffect(() => {
    if (!referralsData) return;
    setReferrals(referralsData.referrals.map((referral) => ({ label: referral, value: referral })));
  }, [referralsData]);

  //------------------------handlers--------------------
  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesData = Object.values(event.target.files);

      setUploadedImages(filesData);
    }
  };

  const handleRemoveSelectedImage = (index: number) => {
    setUploadedImages((prev) => {
      const newImages = [...prev];
      newImages.splice(index, 1);
      return newImages;
    });
  };
  //--------------------------------------useEffects-------------------------
  useEffect(() => {
    createNewCaptchaPattern(4);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2}>
      {/*----------------------------Residential Or Commercial------------------------------*/}
      <Grid item xs={12} className="flex justify-between" component={FormControl}>
        <RadioGroup row value={values.property_type} name="property_type" onChange={handleChange}>
          <FormControlLabel value="residential" control={<Radio />} label="Residential" />
          <FormControlLabel value="commercial" control={<Radio />} label="Commercial" />
        </RadioGroup>
      </Grid>
      {/*----------------------------Company Name----------------------------------------------*/}
      {values.property_type === 'commercial' && (
        <Grid item xs={12}>
          <InputLabel>Company Name*</InputLabel>
          <TextField
            id="company_name"
            fullWidth
            placeholder="Company Name*"
            name="company_name"
            onChange={handleChange}
            value={values.company_name}
          />
          {touched.company_name && errors.company_name && (
            <FormHelperText error id="helper-text-company_name">
              {errors.company_name}
            </FormHelperText>
          )}
        </Grid>
      )}
      {/*----------------------------First Name----------------------------------------------*/}
      <Grid item xs={12} sm={6}>
        <InputLabel>First Name*</InputLabel>
        <TextField
          id="first_name"
          fullWidth
          placeholder="First Name*"
          name="first_name"
          onChange={handleChange}
          value={values.first_name}
        />
        {touched.first_name && errors.first_name && (
          <FormHelperText error id="helper-text-first_name">
            {errors.first_name}
          </FormHelperText>
        )}
      </Grid>
      {/*----------------------------Last Name----------------------------------------------*/}
      <Grid item xs={12} sm={6}>
        <InputLabel> Last Name*</InputLabel>
        <TextField id="last_name" fullWidth placeholder="Last Name*" name="last_name" onChange={handleChange} value={values.last_name} />
        {touched.last_name && errors.last_name && (
          <FormHelperText error id="helper-text-last_name">
            {errors.last_name}
          </FormHelperText>
        )}
      </Grid>

      {/*----------------------------Search Address*----------------------------------------------*/}
      <Grid item xs={12}>
        <InputLabel>Search Address</InputLabel>
        <GoogleMaps formik={formik} ref={googleMapRef} />{' '}
      </Grid>

      {/*----------------------------Address*----------------------------------------------*/}
      <Grid item xs={12}>
        <InputLabel>Address*</InputLabel>
        <TextField
          id="Address1"
          fullWidth
          placeholder="Address*"
          name="address.address1"
          onChange={handleChange}
          value={values.address.address1}
        />
        {touched.address?.address1 && errors.address?.address1 && (
          <FormHelperText error id="helper-text-address?.address1">
            {errors.address?.address1}
          </FormHelperText>
        )}
      </Grid>
      {/*----------------------------City----------------------------------------------*/}
      <Grid item xs={12} sm={6}>
        <InputLabel>City*</InputLabel>
        <TextField id="city" fullWidth placeholder="City*" name="address.city" onChange={handleChange} value={values.address.city} />
        {touched.address?.city && errors.address?.city && (
          <FormHelperText error id="helper-text-address?.city">
            {errors.address?.city}
          </FormHelperText>
        )}
      </Grid>

      {/*----------------------------State----------------------------------------------*/}
      <Grid item xs={12} sm={6}>
        <InputLabel>State*</InputLabel>
        <TextField id="state" fullWidth placeholder="State*" name="address.state" onChange={handleChange} value={values.address.state} />
        {touched.address?.state && errors.address?.state && (
          <FormHelperText error id="helper-text-address?.state">
            {errors.address?.state}
          </FormHelperText>
        )}
      </Grid>
      {/*----------------------------Zip----------------------------------------------*/}
      <Grid item xs={12} sm={6}>
        <InputLabel>Zip*</InputLabel>
        <TextField id="zip" fullWidth placeholder="Zip*" name="address.zip" onChange={handleChange} value={values.address.zip} />
        {touched.address?.zip && errors.address?.zip && (
          <FormHelperText error id="helper-text-address?.zip">
            {errors.address?.zip}
          </FormHelperText>
        )}
      </Grid>
      {/*----------------------------country----------------------------------------------*/}
      <Grid item xs={12} sm={6}>
        <InputLabel>Country*</InputLabel>
        <TextField
          id="country"
          fullWidth
          placeholder="country*"
          name="address.country"
          onChange={handleChange}
          value={values.address.country}
        />
        {touched.address?.country && errors.address?.country && (
          <FormHelperText error id="helper-text-address?.country">
            {errors.address?.country}
          </FormHelperText>
        )}
      </Grid>
      {/*----------------------------Phone----------------------------------------------*/}
      <Grid item xs={12}>
        <InputLabel>Phone*</InputLabel>
        <PhoneNumberInput
          id="phone"
          value={values.phone && `${values.phone_country_code}${values.phone}`}
          onChange={(phone: string, country: CountryData, e: React.ChangeEvent<HTMLInputElement>) =>
            handlePhoneNumberInputChange(phone, country, e, formik, 'phone')
          }
        />
        {touched.phone && errors.phone && (
          <FormHelperText error id="helper-text-phone">
            {errors.phone}
          </FormHelperText>
        )}
      </Grid>
      {/*----------------------------Phone Type----------------------------------------------*/}
      <Grid item xs={12} component={FormControl}>
        <RadioGroup row value={values.phone_type} name="phone_type" onChange={handleChange}>
          <FormControlLabel value="mobile" control={<Radio />} label="Cell/Mobile Phone" />
          <FormControlLabel value="home" control={<Radio />} label="Home Phone" />
        </RadioGroup>
      </Grid>
      {/*----------------------------Email----------------------------------------------*/}
      <Grid item xs={12}>
        <InputLabel>Email*</InputLabel>
        <TextField id="email" fullWidth placeholder="Email*" name="email" onChange={handleChange} value={values.email} />
        {touched.email && errors.email && (
          <FormHelperText error id="helper-text-email">
            {errors.email}
          </FormHelperText>
        )}
      </Grid>
      {/*----------------------------Job Details----------------------------------------------*/}
      <Grid item xs={12}>
        <InputLabel>Job Details*</InputLabel>
        <TextField
          id="job_details"
          fullWidth
          placeholder="Job Details*"
          name="job_details"
          onChange={trimFc(formik)}
          value={values.job_details}
          multiline
          rows={4}
        />
        {touched.job_details && errors.job_details && (
          <FormHelperText error id="helper-text-job_details">
            {errors.job_details}
          </FormHelperText>
        )}
      </Grid>
      {/*----------------------------Referral----------------------------------------------*/}
      <Grid item xs={12}>
        <Stack spacing={1}>
          <InputLabel>How did you hear about this?</InputLabel>
          <Autocomplete
            id="referral"
            value={referrals.find((singleData) => singleData.label === values.referral) || { value: '', label: '' }}
            disableClearable
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue: { label: string; value: string }) => {
              setFieldValue('referral', newValue.label);
            }}
            options={referrals}
            renderInput={(params) => <TextField {...params} placeholder="How did you hear about this?" />}
          />
        </Stack>
      </Grid>
      {/*----------------------------Upload File/Photo----------------------------------------------*/}
      <Grid item xs={12} className="my-4">
        <input
          style={{ display: 'none' }}
          id="upload-file"
          type="file"
          accept="image/*,video/*"
          multiple
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileUpload(event)}
        />
        <label htmlFor="upload-file">
          <Button
            variant="dashed"
            color="primary"
            component="span"
            size="large"
            className="lg:w-6/12 w-full py-3"
            startIcon={<UploadOutlined />}
          >
            Upload File/Photo
          </Button>
        </label>
      </Grid>

      {/*----------------------------Image Render----------------------------------------------*/}

      <Grid item xs={12}>
        <ImageList cols={5} rowHeight={'auto'} className="space-1">
          {!!uploadedImages &&
            uploadedImages?.map((singleFileUrl: File, index: number) => (
              <ImageListItem
                key={index}
                component={Card}
                className="flex justify-center"
                sx={{ maxHeight: '250px', minHeight: '200px', minWidth: '200px' }}
              >
                {WidgetRenderMedia(singleFileUrl, index)}

                <ImageListItemBar
                  className="p-1 bg-transparent"
                  position="top"
                  actionIcon={
                    <DeleteOutlined
                      className="font-lg text-white bg-red-500 p-2 rounded-sm"
                      onClick={() => handleRemoveSelectedImage(index)}
                    />
                  }
                  actionPosition="right"
                />
              </ImageListItem>
            ))}
        </ImageList>
      </Grid>
      {/*----------------------------Captcha----------------------------------------------*/}
      <Grid item xs={12}>
        <div className="flex flex-row space-x-4 my-2 justify-between">
          <Typography
            className="text-4xl font-medium normal-case border-2 border-dashed p-4 select-none text-gray-500"
            sx={{ fontFamily: 'Ribeye Marrow' }}
          >
            {captcha}
          </Typography>
          <Button variant="text" onClick={() => createNewCaptchaPattern(4)} startIcon={<UndoOutlined className="rotate-45" />}>
            Generate New
          </Button>
        </div>
        <TextField
          id="captcha"
          fullWidth
          placeholder="Captcha*"
          name="captcha"
          onChange={handleChange}
          value={values.captcha}
          inputProps={{ style: { textTransform: 'none' } }}
        />
        {touched.captcha && errors.captcha && (
          <FormHelperText error id="helper-text-captcha">
            {errors.captcha}
          </FormHelperText>
        )}
      </Grid>
    </Grid>
  );
};

export default CreateEstimateWidgetForm;
