import { ArrowLeftOutlined } from '@ant-design/icons';
import { Card, Divider, IconButton, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import CategoriesProductAndServicesInstance from 'services/services.categoriesProductAndServices';
import companyServicesInstance from 'services/services.company';

const ViewServiceAndProduct = () => {
  //--------------------------Constants---------------
  const [serviceAndProductDetail, setServiceAndProductDetail] = useState<{ label: string; value: string | number }[]>([]);
  const navigate = useNavigate();
  const { service_product_id: serviceAndProductId } = useParams();
  //----------------------useQuery--------------------
  const { data: serviceAndProduct } = useQuery({
    queryKey: ['service_and_product'],
    queryFn: () => CategoriesProductAndServicesInstance.getSingleServiceAndProduct(serviceAndProductId)
  });

  const { data: companyData } = useQuery({
    queryKey: ['company_data_in_view_service_and_product', serviceAndProduct],
    queryFn: () => companyServicesInstance.getSingleCompanyData(serviceAndProduct?.company_id ?? '')
  });

  //------------------------UseEffect--------------------------
  useEffect(() => {
    if (serviceAndProduct && companyData) {
      setServiceAndProductDetail([
        { label: 'Company Name', value: companyData?.company_name ?? '' },
        {
          label: 'Job Catgeory',
          value: serviceAndProduct?.job_category?.name ?? ''
        },
        { label: 'Job Type', value: serviceAndProduct?.job_type ?? '' },
        { label: 'Item Name', value: serviceAndProduct?.item_name ?? '' },
        { label: 'Description To Field Worker', value: serviceAndProduct?.description_to_field_worker ?? '' },
        {
          label: 'Proposal Description',
          value: serviceAndProduct?.proposal_description ?? ''
        },
        {
          label: 'Private Notes',
          value: serviceAndProduct?.private_notes ?? ''
        },
        {
          label: 'Unit/Measure',
          value: serviceAndProduct?.measure ?? ''
        },
        { label: 'Company Cost Per Unit', value: serviceAndProduct?.company_cost_per_unit ?? '' },
        { label: 'Customer Cost Per Unit', value: serviceAndProduct?.customer_cost_per_unit ?? '' },
        { label: 'Minimum Customer Cost:', value: serviceAndProduct?.minimum_customer_cost ?? '' },
        { label: 'Time per Unit:', value: serviceAndProduct?.minutes_per_unit ?? '' },
        { label: 'Worker Commission per Unit:', value: serviceAndProduct?.worker_commission_per_unit ?? '' },
        { label: 'Minimum Worker Commssion:', value: serviceAndProduct?.minimum_worker_commission ?? '' },
        {
          label: 'Material Needed for the Service:',
          value: `${serviceAndProduct?.materials
            .map((singleMaterial, index, array) =>
              index === array.length - 1 ? singleMaterial.description : `${singleMaterial.description}, `
            )
            .join(' ')}`
        },

        { label: 'Customer Presence:', value: serviceAndProduct?.customer_presence ? '1' : '0' }
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceAndProduct, companyData]);

  return (
    <>
      {serviceAndProduct && (
        <Stack direction={{ xs: 'column', md: 'row' }} padding={2} component={Card}>
          <Table>
            <div className="flex items-center justify-start space-x-2">
              <IconButton onClick={() => navigate(-1)}>
                <ArrowLeftOutlined />
              </IconButton>
              <Typography variant="h4">View Services & Product</Typography>
            </div>
            <TableBody>
              {serviceAndProductDetail.slice(0, Math.ceil(serviceAndProductDetail.length / 2)).map((singleData, index) => (
                <TableRow key={index} className="border-hidden">
                  <TableCell className="w-1/2">
                    <Typography variant="subtitle1" className="font-bold text-gray-400">
                      {singleData.label}
                    </Typography>
                  </TableCell>
                  <TableCell className="p-2 w-1/2">
                    <Typography variant="body1" className="break-all">
                      {singleData.value}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Divider orientation="vertical" flexItem />
          <Table>
            <TableBody>
              {serviceAndProductDetail.slice(Math.ceil(serviceAndProductDetail.length / 2)).map((singleData, index) => (
                <TableRow key={index} className="border-hidden">
                  <TableCell className="w-1/2">
                    <Typography variant="subtitle1" className="font-bold text-gray-400">
                      {singleData.label}
                    </Typography>
                  </TableCell>
                  <TableCell className="p-2 w-1/2">
                    <Typography variant="body1" className="break-all">
                      {singleData.value}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Stack>
      )}
    </>
  );
};

export default ViewServiceAndProduct;
