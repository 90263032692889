import MainCard from 'components/MainCard';
import { TJobData } from 'components/tables/interfaces/jobTableInterface';
import WidgetEstimateHeader from 'components/widgets/components/WidgetEstimateHeader';
import { TJobInfo } from 'components/widgets/types/jobInfoType';
import { useEffect, useRef, useState } from 'react';
import InvoiceJobCategoryAndTransactionDetails from './Components/InvoiceJobCategoryAndTransactionDetails';
import InvoicePayment from './Components/InvoicePayment';

const JobInvoice = ({ jobData, refetchJob }: { jobData: TJobData; refetchJob: () => void }) => {
  //-----------------------constants-------------------------
  const [approvedOptionIndex, setApprovedOptionIndex] = useState<number | null>(null);
  const [amountPaid, setAmountPaid] = useState<number | null>(0);
  const componentRef = useRef<HTMLDivElement | null>(null);
  //-----------------------useEffects--------------------------
  useEffect(() => {
    if (!!jobData) {
      setApprovedOptionIndex(jobData?.job.estimations?.options.findIndex((singleOption) => singleOption.approved) || 0);
      let totalPaidAmount: number = 0;
      jobData?.job?.payments?.forEach((paymentData) => {
        totalPaidAmount += paymentData?.amount || 0;
      });
      setAmountPaid(totalPaidAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobData]);

  return (
    <>
      <MainCard content={false} className="uppercase" ref={componentRef}>
        <WidgetEstimateHeader jobData={jobData as unknown as TJobInfo} />
        {approvedOptionIndex != null && amountPaid != null && (
          <InvoiceJobCategoryAndTransactionDetails jobData={jobData} approvedOptionIndex={approvedOptionIndex} amountPaid={amountPaid} />
        )}
      </MainCard>

      <div className="p-8">
        {approvedOptionIndex != null &&
          amountPaid !== null &&
          amountPaid <
            (jobData?.job?.estimations?.options?.[approvedOptionIndex]?.sub_total as number) +
              (jobData.job.is_this_job_tax_exempt ? 0 : (jobData?.job?.estimations?.options?.[approvedOptionIndex]?.tax as number)) && (
            <InvoicePayment
              refetchJob={refetchJob}
              approvedOptionIndex={approvedOptionIndex}
              amountPaid={amountPaid}
              jobData={jobData}
              componentRef={componentRef}
            />
          )}
      </div>
    </>
  );
};

export default JobInvoice;
