import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Checkbox,
  Chip,
  createFilterOptions,
  DialogProps,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import FormSubmissionAlertMessage from 'components/FormSubmissionAlertMessage';
import MainCard from 'components/MainCard';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import CustomerCreateUpdateDialog from 'components/popups/CustomerCreateUpdateDialog';
import { getIn, useFormik } from 'formik';
import { TCustomerData } from 'pages/customers/types/type.customersPage';
import { lazy, Suspense, useEffect, useState } from 'react';
import { CountryData } from 'react-phone-input-2';
import CustomerServicesInstance from 'services/services.customers';
import PageConfigurationServicesInstance from 'services/services.pageConfigurations';
import { useSelector } from 'store';
import { handlePhoneNumberInputChange, toTitleCase } from 'utils/common';
import { phoneType } from 'utils/constants';
import trimFc from 'utils/trimFc';
import * as Yup from 'yup';
import GoogleMaps from './google-map-autocomplete/GoogleMap';
// import { strict } from 'assert';

const GoogleMapAutocomplete = lazy(() => import('./google-map-autocomplete'));

const filterBillingEmails = createFilterOptions<string>();
const filterEmails = createFilterOptions<string>();
const defaultBillingInfo = {
  first_name: '',
  last_name: '',
  emails: [],
  address: {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: ''
  },
  phone1: '',
  phone1_extension: null as unknown as number,
  phone1_country_code: '',

  phone1_type: 'mobile',
  phone2: '',
  phone2_extension: null as unknown as number,
  phone2_country_code: '',

  phone2_type: 'mobile',
  reason: ''
};
type TCustomerConfirmationDialog = {
  action: { open: boolean; maxWidth: DialogProps['maxWidth']; title: string; header: string };
  data: TCustomerData[] | null;
  customerFormValues: TCustomerData | null;
  type: 'edit' | 'add' | 'update';
  extraData?: any;
};

const AddCustomerForm = (props: { onSuccess: () => void; existingData?: TCustomerData; isEditMode?: boolean; setCustomerData?: any }) => {
  const theme = useTheme();

  // ----------------------------------------Constants----------------------------------------
  const {
    existingData,
    isEditMode = false,
    onSuccess = () => {}
    // setCustomerData = (arg0: React.Dispatch<React.SetStateAction<unknown>>) => null
  } = props;

  const { generalSettings: settingsData } = useSelector((state) => state.settings);

  const [billToYou, setBillToYou] = useState<boolean>(true);

  const [isShowPropertyType, setIsShowPropertyType] = useState<boolean>(false);
  const [showPhone1ExtensionNumber, setShowPhone1ExtensionNumber] = useState<boolean>(false);
  const [showPhone2ExtensionNumber, setShowPhone2ExtensionNumber] = useState<boolean>(false);
  const [showPhone3ExtensionNumber, setShowPhone3ExtensionNumber] = useState<boolean>(false);

  const [showBillingPhone1ExtensionNumber, setShowBillingPhone1ExtensionNumber] = useState<boolean>(false);
  const [showBillingPhone2ExtensionNumber, setShowBillingPhone2ExtensionNumber] = useState<boolean>(false);

  const [showCustomerNotification, setShowCustomerNotification] = useState<number[]>([1, 2]);

  const { data: allPageConfigData } = useQuery({
    queryKey: ['page_config'],
    queryFn: () => PageConfigurationServicesInstance.getPageConfig()
  });

  const [referralOptions, setReferralOptions] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const defaultCreateUpdateCustomerState: TCustomerConfirmationDialog = {
    action: { open: false, maxWidth: 'sm' as DialogProps['maxWidth'], title: '', header: '' },
    data: null,
    customerFormValues: null,
    type: 'add'
  };
  const [customerConfirmationDialog, setCustomerConfirmationDialog] =
    useState<TCustomerConfirmationDialog>(defaultCreateUpdateCustomerState);
  const propertyTypes = [
    { value: 'residential', label: 'Residential' },
    { value: 'commercial', label: 'Commercial' }
  ];

  const emails: string[] = [];
  const billing_contact_emails: string[] = [];
  const notificationSendingChannels: { value: number; label: string }[] = [
    { value: 1, label: 'Email' },
    { value: 2, label: 'SMS' }
  ];

  // --------------------------Other functions-------------------
  const formik = useFormik<TCustomerData>({
    initialValues: {
      /**MAIN CONTACT INFO */
      first_name: '',
      last_name: '',
      property_type: 'residential',
      customer_company_name: '',
      phone1: '',
      phone1_country_code: '',
      phone1_extension: null as unknown as number,
      phone1_type: 'mobile',
      phone2: '',
      phone2_country_code: '',
      phone2_extension: null as unknown as number,
      phone2_type: 'mobile',
      phone3: '',
      phone3_country_code: '',
      phone3_extension: null as unknown as number,
      phone3_type: 'mobile',
      emails: [],
      address: { address1: '', address2: '', city: '', state: '', zip: '', cross_street: '', country: '' },
      customer_notification_preference: [1, 2],
      referral: '',
      default_tax_rate: settingsData?.default_tax_rate ?? 0,
      private_office_notes: '',

      //--------BILLING CONTACT INFO ------------
      bill_to_you: true,
      billing_contact: defaultBillingInfo
    },
    validationSchema: Yup.object().shape({
      property_type: Yup.string().required('This field is required'),
      emails: Yup.array()
        .of(Yup.string().email('Must be a valid email').max(255, 'Email must be less than 255 characters').required('Email is required'))
        .min(1, 'At least one email is required') // Ensuring at least one email is present
        .required('Email list cannot be empty'),
      phone1: Yup.string().min(5, 'Numbers should be at least 5 digits long').required('Phone number is required'),
      phone2: Yup.string().min(5, 'Numbers should be at least 5 digits long'),
      phone3: Yup.string().min(5, 'Numbers should be at least 5 digits long'),
      phoneType: Yup.string(),
      address: Yup.object().shape({
        address1: Yup.string().required('Address 1 is required'),
        address2: Yup.string().nullable(),
        state: Yup.string().required('State is required'),
        city: Yup.string().required('City is required'),
        zip: Yup.string().required('Zip is required'),
        country: Yup.string().required('Country is required')
      }),
      customer_company_name: isShowPropertyType ? Yup.string().required('This field is required.') : Yup.string().nullable(),
      billing_contact: Yup.object().when('bill_to_you', {
        is: false,
        then: (schema) =>
          schema.shape({
            address: Yup.object().shape({
              address1: Yup.string().required('Billing Address 1 is required'),
              address2: Yup.string().nullable(),
              state: Yup.string().required('Billing State is required'),
              city: Yup.string().required('Billing City is required'),
              zip: Yup.string().required('Billing Zip is required'),
              country: Yup.string().required('Billing Country is required')
            }),
            phone1: Yup.string().min(5, 'Numbers should be at least 5 digits long').required('This field is required.'),
            phone2: Yup.string().min(5, 'Numbers should be at least 5 digits long'),
            emails: Yup.array().of(Yup.string().email('Must be a valid email'))
          }),
        otherwise: (schema) => schema.nullable()
      })
    }),

    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      setSubmitting(true);
      handleCustomerFormSubmit(values, setSubmitting).finally(() => setSubmitting(false));
    }
  });

  const handleCustomerFormSubmit = async (values: TCustomerData, setSubmitting: (arg0: boolean) => void) => {
    const existingCustomerWithSameNumber = await CustomerServicesInstance.getAllCustomers(
      null,
      {
        search: [
          [
            {
              field_name: 'status',
              field_value: 1,
              operator: 'exactmatch'
            }
          ],
          [
            {
              field_name: 'phone1',
              field_value: values.phone1,
              operator: 'exactmatch'
            },
            {
              field_name: 'emails',
              field_value: values.emails,
              operator: 'in'
            }
          ]
        ]
      },
      undefined
    );
    const payload = {
      ...values,
      customer_notification_preference: showCustomerNotification,
      referral: values.referral === 'Select' ? '' : values.referral,
      bill_to_you: billToYou,
      billing_contact: billToYou
        ? {
            first_name: formik.values.first_name,
            last_name: formik.values.last_name,
            address: formik.values.address,
            phone1: formik.values.phone1,
            phone1_extension: formik.values.phone1_extension,
            phone1_country_code: formik.values.phone1_country_code,
            phone2_country_code: formik.values.phone2_country_code,

            phone1_type: formik.values.phone1_type,
            phone2: formik.values.phone1,
            phone2_extension: formik.values.phone1_extension,
            phone2_type: formik.values.phone1_type,
            emails: formik.values.emails,
            reason: ''
          }
        : formik.values.billing_contact
    };
    if (existingCustomerWithSameNumber) {
      if (!existingCustomerWithSameNumber.customers.length) {
        setCustomerConfirmationDialog({
          action: {
            open: true,
            maxWidth: 'xs',
            header: 'Create New Record',
            title: 'Are you sure you want to perform this operation?'
          },
          data: null,
          customerFormValues: payload,
          type: 'add'
        });
      } else {
        if (existingData?.phone1 !== payload.phone1) {
          setCustomerConfirmationDialog({
            action: {
              open: true,
              maxWidth: 'sm',
              header: 'Update / Create New Record',
              title: 'Customer(s) with this Phone Number / Email already exist. Please Select a customer to update or create a new record'
            },
            data: existingCustomerWithSameNumber.customers,
            customerFormValues: payload,
            type: 'update'
          });
        } else {
          setCustomerConfirmationDialog({
            action: {
              open: true,
              maxWidth: 'sm',
              header: 'Update Record',
              title: 'Are you sure you want to perform this operation?'
            },
            data: existingCustomerWithSameNumber.customers,
            customerFormValues: payload,
            type: 'edit',
            extraData: { customerId: existingData?._id }
          });
        }
      }
    }
    return;
    // setCustomerData();
    // handleCreateUpdateCustomer(values, setSubmitting);
  };

  const handleCustomerCreateUpdateClose = () => {
    setCustomerConfirmationDialog(defaultCreateUpdateCustomerState);
  };

  const handleCustomerCreateUpdateDialogSuccess = (createdCustomer?: TCustomerData) => {
    props.setCustomerData(createdCustomer);
    onSuccess();
  };
  const handleChangeBillToYou = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('bill_to_you', event.target.value === 'true');
    setBillToYou(event.target?.value === 'true');
    if (!(event.target?.value === 'true')) formik.setFieldValue('billing_contact', { ...defaultBillingInfo });
  };
  // --------------------------UseEffects-------------------

  useEffect(() => {
    if (settingsData?.default_tax_rate && !isEditMode) formik.setFieldValue('default_tax_rate', settingsData?.default_tax_rate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsData]);

  useEffect(() => {
    if (allPageConfigData && !isEditMode) {
      setBillToYou(allPageConfigData.customer_page.billed_to_you);
      setIsShowPropertyType(allPageConfigData.customer_page.property_type === 'commercial');

      formik.setValues((prevValues) => ({
        ...prevValues,
        property_type: allPageConfigData.customer_page.property_type
      }));
    }
    allPageConfigData &&
      setReferralOptions(
        allPageConfigData.referrals
          ? allPageConfigData.referrals?.map((eachReferral) => ({ value: eachReferral.toLowerCase(), label: toTitleCase(eachReferral) }))
          : []
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPageConfigData]);

  useEffect(() => {
    if (existingData && isEditMode) {
      formik.setFieldValue('property_type', existingData.property_type);
      formik.setFieldValue('first_name', existingData.first_name);
      formik.setFieldValue('last_name', existingData.last_name);
      formik.setFieldValue('customer_company_name', existingData.customer_company_name);
      formik.setFieldValue('phone1', existingData.phone1);
      formik.setFieldValue('phone1_country_code', existingData.phone1_country_code);
      formik.setFieldValue('phone1_extension', existingData.phone1_extension);
      formik.setFieldValue('phone1_type', existingData.phone1_type ?? 'mobile');
      formik.setFieldValue('phone2', existingData.phone2);
      formik.setFieldValue('phone2_country_code', existingData.phone2_country_code);
      formik.setFieldValue('phone2_extension', existingData.phone2_extension);
      formik.setFieldValue('phone2_type', existingData.phone2_type);
      formik.setFieldValue('phone3', existingData.phone3);
      formik.setFieldValue('phone3_country_code', existingData.phone3_country_code);
      formik.setFieldValue('phone3_extension', existingData.phone3_extension);
      formik.setFieldValue('phone3_type', existingData.phone3_type);
      formik.setFieldValue('emails', existingData.emails);
      formik.setFieldValue(
        'address',
        existingData?.address
          ? existingData?.address
          : { address1: '', address2: '', city: '', state: '', zip: '', cross_street: '', country: '' }
      );
      formik.setFieldValue('referral', existingData.referral);
      formik.setFieldValue('default_tax_rate', existingData.default_tax_rate);
      formik.setFieldValue('private_office_notes', existingData.private_office_notes);

      formik.setFieldValue('billing_contact', existingData?.billing_contact ? existingData.billing_contact : defaultBillingInfo);

      setBillToYou(existingData.bill_to_you);
      setIsShowPropertyType(existingData.property_type === 'commercial');
      setShowPhone1ExtensionNumber(existingData.phone1_type === 'office' ? true : false);
      setShowPhone2ExtensionNumber(existingData.phone2_type === 'office' ? true : false);
      setShowPhone3ExtensionNumber(existingData.phone3_type === 'office' ? true : false);

      setShowBillingPhone1ExtensionNumber(existingData.billing_contact.phone1_type === 'office' ? true : false);
      setShowBillingPhone2ExtensionNumber(existingData.billing_contact.phone2_type === 'office' ? true : false);

      setShowCustomerNotification(existingData.customer_notification_preference);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingData]);
  useEffect(() => {
    console.log('formik.errors', formik.errors);
  }, [formik.errors]);

  function scrollToErrorField() {
    const allErrors = Object.entries(formik.errors);

    if (allErrors.length > 0) {
      const firstError = allErrors[0];

      let elemId = firstError[0];
      if (typeof firstError[1] === 'object') {
        elemId = Object.keys(firstError[1])[0];
      }

      const firstErrorElement = document.getElementById(elemId);
      if (firstErrorElement) {
        firstErrorElement.focus();
        firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }

  return (
    <>
      <form
        noValidate
        onSubmit={(event) => {
          scrollToErrorField();
          formik.handleSubmit(event);
        }}
      >
        <Grid container spacing={2.5}>
          {/**-----------------MAIN CONTACT INFO---------------------- */}
          <Grid item xs={12} md={6}>
            <MainCard title="MAIN CONTACT INFO">
              <Grid container spacing={3}>
                {/* -----------------------------------------First Name--------------------------------- */}
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="firstname">First Name</InputLabel>
                    <OutlinedInput
                      id="firstname"
                      type="firstname"
                      value={formik.values.first_name}
                      name="first_name"
                      onBlur={formik.handleBlur}
                      onChange={trimFc(formik)}
                      placeholder="John"
                      fullWidth
                      error={Boolean(formik.touched.first_name && formik.errors.first_name)}
                    />
                  </Stack>
                </Grid>
                {/* -----------------------------------------Last Name---------------------------------- */}
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="last_name">Last Name</InputLabel>
                    <OutlinedInput
                      id="last_name"
                      type="last_name"
                      value={formik.values.last_name}
                      name="last_name"
                      onBlur={formik.handleBlur}
                      onChange={trimFc(formik)}
                      placeholder="Doe"
                      fullWidth
                      error={Boolean(formik.touched.last_name && formik.errors.last_name)}
                    />
                  </Stack>
                </Grid>
                {/* -----------------------------------------Email------------------------------ */}

                <Grid item xs={12} md={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="emails">Email*</InputLabel>
                    <Autocomplete
                      value={formik.values.emails}
                      id="emails"
                      multiple
                      fullWidth
                      autoHighlight
                      // freeSolo
                      disableCloseOnSelect
                      options={emails}
                      onBlur={formik.handleBlur}
                      getOptionLabel={(option) => option}
                      onChange={(event, newValue) => {
                        formik.setFieldValue('emails', newValue);
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filterEmails(options, params);
                        const { inputValue } = params;
                        const isExisting = options.some((option) => inputValue === option);
                        if (inputValue !== '' && !isExisting) {
                          filtered.push(inputValue);
                        }

                        return filtered;
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {!emails.some((v) => option.includes(v)) ? `Add "${option}"` : option}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="emails"
                          placeholder="Enter emails separated by commas"
                          error={Boolean(formik.touched.emails && formik.errors.emails)}
                          helperText={
                            Boolean(formik.touched.emails && formik.errors.emails)
                              ? formik.errors.emails
                              : 'Press enter or comma to add multiple emails'
                          }
                          onBlur={(event) => {
                            event.preventDefault();

                            // Cast event.target to HTMLInputElement to access its value
                            const inputElement = event.target as HTMLInputElement;
                            const inputValue = inputElement.value.trim();

                            if (inputValue) {
                              // Split by comma and trim each email
                              const newEmails: string[] = inputValue
                                .split(',')
                                .map((email: string) => email.trim())
                                .filter((email: string) => email);
                              // If valid emails are present, update formik value
                              if (newEmails.length > 0) {
                                const updatedEmails: string[] = [...formik.values.emails, ...newEmails];
                                formik.setFieldValue('emails', updatedEmails);
                                formik.setTouched({ ...formik.touched, emails: true });
                                // Clear the input field after emails are added
                                inputElement.value = '';
                              }
                            }
                          }}
                          onKeyDown={(event) => {
                            if (event.key === ',') {
                              event.preventDefault();

                              // Cast event.target to HTMLInputElement to access its value
                              const inputElement = event.target as HTMLInputElement;
                              const inputValue = inputElement.value.trim();

                              if (inputValue) {
                                // Split by comma and trim each email
                                const newEmails: string[] = inputValue
                                  .split(',')
                                  .map((email: string) => email.trim())
                                  .filter((email: string) => email);
                                // If valid emails are present, update formik value
                                if (newEmails.length > 0) {
                                  const updatedEmails: string[] = [...formik.values.emails, ...newEmails];
                                  formik.setFieldValue('emails', updatedEmails);
                                  formik.setTouched({ ...formik.touched, emails: true });
                                  // Clear the input field after emails are added
                                  inputElement.value = '';
                                }
                              }
                            }
                          }}
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          let error = false;
                          if (formik.touched.emails && formik.errors.emails && typeof formik.errors.emails !== 'string') {
                            if (typeof formik.errors.emails[index] === 'object') error = true;
                          }

                          return (
                            <Chip
                              {...getTagProps({ index })}
                              variant="combined"
                              color={error ? 'error' : 'secondary'}
                              label={
                                <Typography variant="caption" color="secondary.dark">
                                  {option}
                                </Typography>
                              }
                              deleteIcon={<CloseOutlined style={{ fontSize: '0.875rem' }} />}
                              size="small"
                            />
                          );
                        })
                      }
                    />
                  </Stack>
                </Grid>

                {/* ---------------------------Residential or Commercial---------------------------------- */}
                <Grid item xs={12} md={isShowPropertyType ? 6 : 12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="property_type">Residential or Commercial*</InputLabel>
                    <Autocomplete
                      id="property_type"
                      value={propertyTypes.find((singleData) => singleData.value === formik.values.property_type)}
                      disableClearable
                      isOptionEqualToValue={(option, optionValue) => option.value === optionValue.value}
                      getOptionLabel={(option) => option.label}
                      onChange={(event: React.SyntheticEvent<Element, Event>, newValue: { label: string; value: string }) => {
                        formik.setFieldValue('property_type', newValue.value);
                        setIsShowPropertyType(newValue.value === 'commercial' ? true : false);
                      }}
                      options={propertyTypes}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Residential or Commercial"
                          sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                        />
                      )}
                    />
                    {formik.touched.property_type && formik.errors.property_type && (
                      <FormHelperText error id="helper-text-property_type">
                        {formik.errors.property_type}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>

                {/*---------------------------------Your Company Name------------------------*/}
                {isShowPropertyType && (
                  <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="customer_company_name">Your Company Name*</InputLabel>
                      <OutlinedInput
                        id="customer_company_name"
                        type="customer_company_name"
                        value={formik.values.customer_company_name}
                        name="customer_company_name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder=""
                        fullWidth
                        error={Boolean(formik.touched.customer_company_name && formik.errors.customer_company_name)}
                      />
                      {formik.touched.customer_company_name && formik.errors.customer_company_name && (
                        <FormHelperText error id="helper-text-customer_company_name-signup">
                          {formik.errors?.customer_company_name}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                )}

                {/* -----------------------------------------Phone1*------------------------------ */}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {/* -----------------------------------------Phone Type 1------------------------------ */}
                    <Grid item xs={12} sm={showPhone1ExtensionNumber ? 3 : 6} md={showPhone1ExtensionNumber ? 4 : 6}>
                      <InputLabel htmlFor="p_type">Type</InputLabel>
                      <Autocomplete
                        id="p_type"
                        value={phoneType.find((singleData) => singleData.value === formik.values.phone1_type)}
                        disableClearable
                        getOptionLabel={(option) => option.label}
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: { label: string; value: string }) => {
                          formik.setFieldValue('phone1_type', newValue.value);
                          setShowPhone1ExtensionNumber(newValue.value === 'office' ? true : false);
                        }}
                        options={phoneType.filter((eachType) => eachType.value !== 'fax')}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Phone Type"
                            sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                          />
                        )}
                      />
                      {formik.touched.phone1_type && formik.errors.phone1_type && (
                        <FormHelperText error id="helper-text-role">
                          {formik.errors.phone1_type}
                        </FormHelperText>
                      )}
                    </Grid>

                    {/* -----------------------------------------extension_phone_1------------------------------ */}
                    {showPhone1ExtensionNumber && (
                      <Grid item xs={12} sm={3} md={3}>
                        <InputLabel htmlFor="phone1_extension">Extension Number</InputLabel>
                        <OutlinedInput
                          type="number"
                          id="phone1_extension"
                          value={formik.values.phone1_extension}
                          name="phone1_extension"
                          onBlur={formik.handleBlur}
                          onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            if (Number(event.target.value) < 0) {
                              event.target.value = '';
                              return null;
                            }
                            formik.handleChange(event);
                          }}
                          placeholder=""
                          fullWidth
                        />
                      </Grid>
                    )}

                    {/* -----------------------------------------Phone1*------------------------------ */}
                    <Grid item xs={12} sm={6} md={showPhone1ExtensionNumber ? 5 : 6}>
                      <InputLabel htmlFor="phone1">Phone 1*</InputLabel>
                      <PhoneNumberInput
                        error={Boolean(formik.touched?.phone1) && Boolean(formik.errors?.phone1)}
                        id="phone1"
                        value={`${formik.values.phone1_country_code}${formik.values.phone1}`}
                        onChange={(phone: string, country: CountryData, e: React.ChangeEvent<HTMLInputElement>) =>
                          handlePhoneNumberInputChange(phone, country, e, formik, 'phone1')
                        }
                        autoFocus={false}
                        focus={true}
                      />
                      {Boolean(formik.touched?.phone1) && Boolean(formik.errors?.phone1) && (
                        <FormHelperText error id="helper-text-first_name-signup">
                          {formik.errors?.phone1}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                {/* -----------------------------------------Phone2------------------------------ */}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {/* -----------------------------------------Phone Type 2------------------------------ */}
                    <Grid item xs={12} sm={showPhone2ExtensionNumber ? 3 : 6} md={showPhone2ExtensionNumber ? 4 : 6}>
                      <InputLabel htmlFor="phone2_type">Type</InputLabel>
                      <Autocomplete
                        id="phone2_type"
                        value={phoneType.find((singleData) => singleData.value === formik.values.phone2_type)}
                        disableClearable
                        getOptionLabel={(option) => option.label}
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: { label: string; value: string }) => {
                          formik.setFieldValue('phone2_type', newValue.value);
                          setShowPhone2ExtensionNumber(newValue.value === 'office' ? true : false);
                        }}
                        options={phoneType}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Phone Type"
                            sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                          />
                        )}
                      />
                      {formik.touched.phone2_type && formik.errors.phone2_type && (
                        <FormHelperText error id="helper-text-phone2_type">
                          {formik.errors.phone2_type}
                        </FormHelperText>
                      )}
                    </Grid>

                    {/* -----------------------------------------extension_phone_2------------------------------ */}
                    {showPhone2ExtensionNumber && (
                      <Grid item xs={12} sm={3} md={3}>
                        <InputLabel htmlFor="phone2_extension">Extension Number</InputLabel>
                        <OutlinedInput
                          type="number"
                          id="phone2_extension"
                          value={formik.values.phone2_extension}
                          name="phone2_extension"
                          onBlur={formik.handleBlur}
                          onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            if (Number(event.target.value) < 0) {
                              event.target.value = '';
                              return null;
                            }
                            formik.handleChange(event);
                          }}
                          placeholder=""
                          fullWidth
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6} md={showPhone2ExtensionNumber ? 5 : 6}>
                      <InputLabel htmlFor="phone2">Phone 2</InputLabel>

                      <PhoneNumberInput
                        autoFocus={false}
                        error={Boolean(formik.touched?.phone2) && Boolean(formik.errors?.phone2)}
                        id="phone2"
                        value={formik.values.phone2 && `${formik.values.phone2_country_code}${formik.values.phone2}`}
                        onChange={(phone: string, country: CountryData, e: React.ChangeEvent<HTMLInputElement>) =>
                          handlePhoneNumberInputChange(phone, country, e, formik, 'phone2')
                        }
                      />
                      {Boolean(formik.touched?.phone2) && Boolean(formik.errors?.phone2) && (
                        <FormHelperText error id="helper-text-first_name-signup">
                          {formik.errors?.phone2}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {/* -----------------------------------------Phone3------------------------------ */}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {/* -----------------------------------------Phone Type 3------------------------------ */}
                    <Grid item xs={12} sm={showPhone3ExtensionNumber ? 3 : 6} md={showPhone3ExtensionNumber ? 4 : 6}>
                      <InputLabel htmlFor="phone3_type">Type</InputLabel>
                      <Autocomplete
                        id="phone3_type"
                        value={phoneType.find((singleData) => singleData.value === formik.values.phone3_type)}
                        disableClearable
                        getOptionLabel={(option) => option.label}
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: { label: string; value: string }) => {
                          formik.setFieldValue('phone3_type', newValue.value);
                          setShowPhone3ExtensionNumber(newValue.value === 'office' ? true : false);
                        }}
                        options={phoneType}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Phone Type"
                            sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                          />
                        )}
                      />
                      {formik.touched.phone3_type && formik.errors.phone3_type && (
                        <FormHelperText error id="helper-text-phone3_type">
                          {formik.errors.phone3_type}
                        </FormHelperText>
                      )}
                    </Grid>

                    {/* -----------------------------------------extension_phone_3------------------------------ */}
                    {showPhone3ExtensionNumber && (
                      <Grid item xs={12} sm={3} md={3}>
                        <InputLabel htmlFor="phone3_extension">Extension Number</InputLabel>
                        <OutlinedInput
                          type="number"
                          id="phone3_extension"
                          value={formik.values.phone3_extension}
                          name="phone3_extension"
                          onBlur={formik.handleBlur}
                          onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            if (Number(event.target.value) < 0) {
                              event.target.value = '';
                              return null;
                            }
                            formik.handleChange(event);
                          }}
                          placeholder=""
                          fullWidth
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6} md={showPhone3ExtensionNumber ? 5 : 6}>
                      <InputLabel htmlFor="phone3">Phone 3</InputLabel>

                      <PhoneNumberInput
                        autoFocus={false}
                        error={Boolean(formik.touched?.phone3) && Boolean(formik.errors?.phone3)}
                        id="phone3"
                        value={formik.values.phone3 && `${formik.values.phone3_country_code}${formik.values.phone3}`}
                        onChange={(phone: string, country: CountryData, e: React.ChangeEvent<HTMLInputElement>) =>
                          handlePhoneNumberInputChange(phone, country, e, formik, 'phone3')
                        }
                      />
                      {Boolean(formik.touched?.phone3) && Boolean(formik.errors?.phone3) && (
                        <FormHelperText error id="helper-text-first_name-signup">
                          {formik.errors?.phone3}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                {/* -----------------------------------------Customer Address----------------------------- */}
                <Grid item xs={12}>
                  <Grid container gap={2}>
                    {/* -----------------------------------------Address 1------------------------------ */}
                    <Typography className="mb-2" variant="h4">
                      Address
                    </Typography>
                    <Suspense>
                      <GoogleMapAutocomplete required={true} formik={formik} />
                    </Suspense>

                    {/* -----------------------------------------Cross Street------------------------------ */}
                    <Grid item xs={12} sm={3}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="cross_street">Cross Street</InputLabel>
                        <TextField
                          id="address.cross_street"
                          name="address.cross_street"
                          placeholder=""
                          value={formik.values.address?.cross_street}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          fullWidth
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item alignItems={'stretch'} xs={12}>
                  <Divider className="mb-3" />
                  <div className="flex md:flex-row flex-col md:space-y-0 space-y-4 mt-4 sm:space-x-4">
                    <Stack spacing={1}>
                      <InputLabel htmlFor="customer_notification_preference">Customer notification</InputLabel>
                      <Stack direction={'row'} spacing={2}>
                        {notificationSendingChannels.map((items: { value: number; label: string }, index) => {
                          return (
                            <FormControlLabel
                              value={items.value}
                              name="customer_notification_preference"
                              id="customer_notification_preference"
                              color="primary"
                              checked={!!showCustomerNotification.length && showCustomerNotification.includes(items.value)}
                              control={
                                <Checkbox
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const newCheckObject: Array<number> = showCustomerNotification.length
                                      ? [...showCustomerNotification]
                                      : [];
                                    if (event.target.checked) {
                                      newCheckObject.push(Number(event.target.value));
                                      setShowCustomerNotification([...new Set(newCheckObject)]);
                                    } else {
                                      setShowCustomerNotification(newCheckObject.filter((obj) => obj !== Number(event.target.value)));
                                    }
                                  }}
                                />
                              }
                              label={items.label}
                            />
                          );
                        })}
                      </Stack>
                    </Stack>

                    <Stack className="w-full" spacing={1}>
                      <InputLabel htmlFor="referral">Referral</InputLabel>
                      <Autocomplete
                        id="referral"
                        value={
                          referralOptions.find((singleData) => singleData.label === formik.values.referral) || { label: '', value: '' }
                        }
                        disableClearable
                        getOptionLabel={(option) => option.label}
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: { label: string; value: string }) => {
                          formik.setFieldValue('referral', newValue.label);
                        }}
                        options={referralOptions}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder=""
                            sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                          />
                        )}
                      />

                      {formik.touched.referral && formik.errors.referral && (
                        <FormHelperText error id="helper-text-referral">
                          {formik.errors.referral}
                        </FormHelperText>
                      )}
                    </Stack>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} md={12} lg={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="default_tax_rate">Default tax rate</InputLabel>
                    <OutlinedInput
                      id="default_tax_rate"
                      type="number"
                      value={formik.values.default_tax_rate}
                      name="default_tax_rate"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder=""
                      fullWidth
                    />
                  </Stack>
                </Grid>

                {/* -----------------------------Private Office Notes------------------------------ */}
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="private_office_notes">Private Office Notes</InputLabel>
                    <TextField
                      id="private_office_notes"
                      fullWidth
                      placeholder="Private Office Notes"
                      name="private_office_notes"
                      multiline
                      rows={3}
                      onChange={trimFc(formik)}
                      value={formik.values?.private_office_notes}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </MainCard>
          </Grid>

          {/**-----------------BILLING CONTACT INFO---------------------- */}
          <Grid item xs={12} md={6}>
            <MainCard
              title={
                <div className="flex w-full sm:flex-row flex-col sm:items-center items-start justify-between sm:space-y-0 space-y-2">
                  <Typography variant="h5">BILLING CONTACT INFO</Typography>
                  <div className="flex sm:items-center items-start sm:space-x-2 space-x-0 sm:flex-row flex-col">
                    <InputLabel htmlFor="bill_to_you">Will this be billed to you?</InputLabel>
                    <RadioGroup
                      sx={{ padding: 0 }}
                      row
                      aria-label="bill_to_you"
                      onChange={handleChangeBillToYou}
                      name="bill_to_you"
                      id="bill_to_you"
                      value={billToYou}
                    >
                      <FormControlLabel value={true} control={<Radio />} label={'Yes'} />
                      <FormControlLabel value={false} control={<Radio />} label={'No'} />
                    </RadioGroup>
                  </div>
                </div>
              }
            >
              <Grid container spacing={3.5}>
                {/**------------------Will this be billed to you?--------------------------- */}

                {!billToYou && (
                  <>
                    {/**------------------Billing Contact First Name--------------------------- */}
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel>Billing Contact First Name</InputLabel>
                        <TextField
                          id="billing_contact.first_name"
                          name="billing_contact.first_name"
                          placeholder=""
                          value={formik.values?.billing_contact && formik.values?.billing_contact?.first_name}
                          onChange={trimFc(formik)}
                          onBlur={formik.handleBlur}
                          error={
                            Boolean(getIn(formik.errors, 'billing_contact.first_name')) &&
                            getIn(formik.errors, 'billing_contact.first_name')
                          }
                          fullWidth
                        />

                        {Boolean(getIn(formik.errors, 'billing_contact.first_name')) && (
                          <FormHelperText error id="helper-text-billing_contact-first_name">
                            {getIn(formik.errors, 'billing_contact.first_name')}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>

                    {/**------------------Billing Contact Last Name--------------------------- */}
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel>Billing Contact Last Name</InputLabel>
                        <TextField
                          id="billing_contact.last_name"
                          name="billing_contact.last_name"
                          placeholder=""
                          value={formik.values.billing_contact?.last_name}
                          onChange={trimFc(formik)}
                          onBlur={formik.handleBlur}
                          error={
                            Boolean(getIn(formik.errors, 'billing_contact.last_name')) && getIn(formik.errors, 'billing_contact.last_name')
                          }
                          fullWidth
                        />
                        {Boolean(getIn(formik.errors, 'billing_contact.last_name')) && (
                          <FormHelperText error id="helper-text-billing_contact-last_name">
                            {getIn(formik.errors, 'billing_contact.last_name')}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>

                    {/**------------------Billing Email--------------------------- */}
                    <Grid item xs={12} sm={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="billing_contact.emails">Billing Email</InputLabel>
                        <Autocomplete
                          id="billing_contact.emails"
                          multiple
                          fullWidth
                          autoHighlight
                          freeSolo
                          disableCloseOnSelect
                          options={billing_contact_emails}
                          onBlur={formik.handleBlur}
                          value={formik.values.billing_contact && formik.values.billing_contact.emails}
                          getOptionLabel={(billing_contact_emails) => billing_contact_emails}
                          onChange={(event, newValue) => {
                            const billingEmailExist = billing_contact_emails.includes(newValue[newValue.length - 1]);
                            if (!billingEmailExist) {
                              formik.setFieldValue('billing_contact.emails', newValue);
                            } else {
                              formik.setFieldValue('billing_contact.emails', newValue);
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filterBillingEmails(options, params);
                            const { inputValue } = params;
                            const isExisting = options.some((option) => inputValue === option);
                            if (inputValue !== '' && !isExisting) {
                              filtered.push(inputValue);
                            }

                            return filtered;
                          }}
                          renderOption={(props, option) => {
                            return (
                              <Box component="li" {...props}>
                                {!billing_contact_emails.some((v) => option.includes(v)) ? `Add "${option}"` : option}
                              </Box>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="billing_contact_emails"
                              placeholder=""
                              error={
                                Boolean(getIn(formik.errors, 'billing_contact.emails')) && getIn(formik.errors, 'billing_contact.emails')
                              }
                            />
                          )}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => {
                              let error = false;
                              if (
                                getIn(formik.touched, 'billing_contact.emails') &&
                                getIn(formik.errors, 'billing_contact.emails') &&
                                typeof getIn(formik.errors, 'billing_contact.emails') !== 'string'
                              ) {
                                if (typeof getIn(formik.errors, `billing_contact.emails[${index}]`) === 'object') error = true;
                              }

                              return (
                                <Chip
                                  {...getTagProps({ index })}
                                  variant="combined"
                                  color={error ? 'error' : 'secondary'}
                                  label={
                                    <Typography variant="caption" color="secondary.dark">
                                      {option}
                                    </Typography>
                                  }
                                  deleteIcon={<CloseOutlined style={{ fontSize: '0.875rem' }} />}
                                  size="small"
                                />
                              );
                            })
                          }
                        />
                        {Boolean(getIn(formik.errors, 'billing_contact.emails')) && (
                          <FormHelperText error id="helper-text-billing_contact-emails">
                            {getIn(formik.errors, 'billing_contact.emails')}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>

                    {/* -----------------------Billing Phone1*------------------------ */}
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        {/* ---------------------Phone Type 1------------------------------ */}
                        <Grid item xs={12} sm={showBillingPhone1ExtensionNumber ? 3 : 6} md={showBillingPhone1ExtensionNumber ? 3 : 4}>
                          <InputLabel>Type</InputLabel>
                          <Autocomplete
                            id="billing_contact.phone1_type"
                            value={phoneType.find((singleData) => singleData.value === formik.values.billing_contact?.phone1_type)}
                            disableClearable
                            getOptionLabel={(option) => option.label}
                            onChange={(event: React.SyntheticEvent<Element, Event>, newValue: { label: string; value: string }) => {
                              formik.setFieldValue('billing_contact.phone1_type', newValue.value);
                              setShowBillingPhone1ExtensionNumber(newValue.value === 'office' ? true : false);
                            }}
                            options={phoneType}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Phone Type"
                                sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                              />
                            )}
                          />
                        </Grid>
                        {/* -----------------------Billing Phone1 Extension*------------------------ */}
                        {showBillingPhone1ExtensionNumber && (
                          <Grid item xs={12} sm={3} md={3}>
                            <InputLabel htmlFor="billing_phone1_extension">Extension Number</InputLabel>
                            <OutlinedInput
                              id="billing_contact.phone1_extension"
                              type="number"
                              value={formik.values.billing_contact && formik.values.billing_contact.phone1_extension}
                              name="billing_contact.phone1_extension"
                              onBlur={formik.handleBlur}
                              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                if (Number(event.target.value) < 0) {
                                  event.target.value = '';
                                  return null;
                                }
                                formik.handleChange(event);
                              }}
                              placeholder=""
                              fullWidth
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={6} md={showBillingPhone1ExtensionNumber ? 6 : 8}>
                          <InputLabel>Billing Phone1*</InputLabel>
                          <PhoneNumberInput
                            autoFocus={false}
                            error={getIn(formik.touched, 'billing_contact.phone1') && getIn(formik.errors, 'billing_contact.phone1')}
                            id="billing_contact.phone1"
                            value={
                              formik.values?.billing_contact?.phone1 &&
                              `${formik.values?.billing_contact?.phone1_country_code}${formik.values?.billing_contact?.phone1}`
                            }
                            onChange={(phone: string, country: CountryData, e: React.ChangeEvent<HTMLInputElement>) =>
                              handlePhoneNumberInputChange(phone, country, e, formik, 'billing_contact.phone1')
                            }
                          />
                          {getIn(formik.touched, 'billing_contact.phone1') && getIn(formik.errors, 'billing_contact.phone1') && (
                            <FormHelperText error id="helper-text-billing_contact.phone1">
                              {getIn(formik.errors, 'billing_contact.phone1')}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* --------------------Billing Phone 2------------------------- */}
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        {/* -------------------------------Phone Type 2---------------------- */}
                        <Grid item xs={12} sm={showBillingPhone2ExtensionNumber ? 3 : 6} md={showBillingPhone2ExtensionNumber ? 3 : 4}>
                          <InputLabel>Type</InputLabel>
                          <Autocomplete
                            id="billing_contact.phone2_type"
                            value={phoneType.find((singleData) => singleData.value === formik.values.billing_contact?.phone2_type)}
                            disableClearable
                            getOptionLabel={(option) => option.label}
                            onChange={(event: React.SyntheticEvent<Element, Event>, newValue: { label: string; value: string }) => {
                              formik.setFieldValue('billing_contact.phone2_type', newValue.value);
                              setShowBillingPhone2ExtensionNumber(newValue.value === 'office' ? true : false);
                            }}
                            options={phoneType}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Phone Type"
                                sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                              />
                            )}
                          />
                        </Grid>
                        {/* -----------------------Billing Phone2 Extension*------------------------ */}
                        {showBillingPhone2ExtensionNumber && (
                          <Grid item xs={12} sm={3} md={3}>
                            <InputLabel htmlFor="billing_phone2_extension">Extension Number</InputLabel>
                            <OutlinedInput
                              type="number"
                              id="billing_contact.phone2_extension"
                              value={formik.values.billing_contact && formik.values.billing_contact.phone2_extension}
                              name="billing_contact.phone2_extension"
                              onBlur={formik.handleBlur}
                              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                if (Number(event.target.value) < 0) {
                                  event.target.value = '';
                                  return null;
                                }
                                formik.handleChange(event);
                              }}
                              placeholder=""
                              fullWidth
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={6} md={showBillingPhone2ExtensionNumber ? 6 : 8}>
                          <InputLabel>Billing Phone2</InputLabel>

                          <PhoneNumberInput
                            autoFocus={false}
                            id="billing_contact.phone2"
                            value={
                              formik.values?.billing_contact?.phone2 &&
                              `${formik.values?.billing_contact?.phone2_country_code}${formik.values?.billing_contact?.phone2}`
                            }
                            onChange={(phone: string, country: CountryData, e: React.ChangeEvent<HTMLInputElement>) =>
                              handlePhoneNumberInputChange(phone, country, e, formik, 'billing_contact.phone2')
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    {/**------------------Billing Address*--------------------------- */}
                    <Grid item xs={12} spacing={1}>
                      <Typography className="mb-2" variant="h4">
                        Billing Address*
                      </Typography>
                      <GoogleMaps formik={formik} root_field_name="billing_contact.address" />
                    </Grid>
                    {/**------------------Billing Address1*--------------------------- */}
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel>Billing Address1*</InputLabel>
                        <TextField
                          multiline
                          minRows={2}
                          id="billing_contact.address.address1"
                          name="billing_contact.address.address1"
                          placeholder=""
                          value={formik.values.billing_contact && formik.values?.billing_contact?.address.address1}
                          onChange={trimFc(formik)}
                          onBlur={formik.handleBlur}
                          error={
                            Boolean(getIn(formik.errors, 'billing_contact.address.address1')) &&
                            Boolean(getIn(formik.touched, 'billing_contact.address.address1'))
                          }
                          fullWidth
                        />

                        {Boolean(getIn(formik.errors, 'billing_contact.address.address1')) &&
                          Boolean(getIn(formik.touched, 'billing_contact.address.address1')) && (
                            <FormHelperText error id="helper-text-billing_details-first_name">
                              {getIn(formik.errors, 'billing_contact.address.address1')}
                            </FormHelperText>
                          )}
                      </Stack>
                    </Grid>

                    {/**------------------Billing Address2--------------------------- */}
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel>Billing Address2</InputLabel>
                        <TextField
                          multiline
                          minRows={2}
                          id="billing_contact.address.address2"
                          name="billing_contact.address.address2"
                          placeholder=""
                          value={formik.values.billing_contact && formik.values.billing_contact.address.address2}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            Boolean(getIn(formik.errors, 'billing_contact.address.address2')) &&
                            getIn(formik.errors, 'billing_contact.address.address2')
                          }
                          fullWidth
                        />
                        {Boolean(getIn(formik.errors, 'billing_contact.address.address2')) && (
                          <FormHelperText error id="helper-text-billing_contact-address.address2">
                            {getIn(formik.errors, 'billing_contact.address.address2')}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>

                    {/**------------------Billing City--------------------------- */}
                    <Grid item xs={12} sm={4}>
                      <Stack spacing={1}>
                        <InputLabel>Billing City*</InputLabel>
                        <TextField
                          id="billing_contact.address.city"
                          name="billing_contact.address.city"
                          placeholder=""
                          value={formik.values.billing_contact && formik.values.billing_contact?.address.city}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            Boolean(getIn(formik.errors, 'billing_contact.address.city')) &&
                            Boolean(getIn(formik.touched, 'billing_contact.address.city'))
                          }
                          fullWidth
                        />
                        {Boolean(getIn(formik.errors, 'billing_contact.address.city')) &&
                          Boolean(getIn(formik.touched, 'billing_contact.address.city')) && (
                            <FormHelperText error id="helper-text-billing_details-first_name">
                              {getIn(formik.errors, 'billing_contact.address.city')}
                            </FormHelperText>
                          )}
                      </Stack>
                    </Grid>

                    {/**------------------Billing State--------------------------- */}
                    <Grid item xs={12} sm={4}>
                      <Stack spacing={1}>
                        <InputLabel>Billing State*</InputLabel>
                        <TextField
                          id="billing_contact.address.state"
                          name="billing_contact.address.state"
                          placeholder=""
                          value={formik.values.billing_contact && formik.values.billing_contact.address.state}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            Boolean(getIn(formik.errors, 'billing_contact.address.state')) &&
                            Boolean(getIn(formik.touched, 'billing_contact.address.state'))
                          }
                          fullWidth
                        />
                        {Boolean(getIn(formik.errors, 'billing_contact.address.state')) &&
                          Boolean(getIn(formik.touched, 'billing_contact.address.state')) && (
                            <FormHelperText error id="helper-text-billing_details-first_name">
                              {getIn(formik.errors, 'billing_contact.address.state')}
                            </FormHelperText>
                          )}
                      </Stack>
                    </Grid>

                    {/**------------------Billing Zip--------------------------- */}
                    <Grid item xs={12} sm={4}>
                      <Stack spacing={1}>
                        <InputLabel>Billing Zip*</InputLabel>
                        <TextField
                          id="billing_contact.address.zip"
                          name="billing_contact.address.zip"
                          placeholder=""
                          value={formik.values.billing_contact && formik.values.billing_contact.address.zip}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            Boolean(getIn(formik.errors, 'billing_contact.address.zip')) &&
                            Boolean(getIn(formik.touched, 'billing_contact.address.zip'))
                          }
                          fullWidth
                        />
                        {Boolean(getIn(formik.errors, 'billing_contact.address.zip')) &&
                          Boolean(getIn(formik.touched, 'billing_contact.address.zip')) && (
                            <FormHelperText error id="helper-text-billing_details-first_name">
                              {getIn(formik.errors, 'billing_contact.address.zip')}
                            </FormHelperText>
                          )}
                      </Stack>
                    </Grid>

                    {/* Billing Country */}
                    <Grid item xs={12} sm={4}>
                      <Stack spacing={1}>
                        <InputLabel>Billing Country*</InputLabel>
                        <TextField
                          id="billing_contact.address.country"
                          name="billing_contact.address.country"
                          placeholder=""
                          value={formik.values.billing_contact && formik.values.billing_contact.address.country}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            Boolean(getIn(formik.errors, 'billing_contact.address.country')) &&
                            Boolean(getIn(formik.touched, 'billing_contact.address.country'))
                          }
                          fullWidth
                        />
                        {Boolean(getIn(formik.errors, 'billing_contact.address.country')) &&
                          Boolean(getIn(formik.touched, 'billing_contact.address.country')) && (
                            <FormHelperText error id="helper-text-billing_details-first_name">
                              {getIn(formik.errors, 'billing_contact.address.country')}
                            </FormHelperText>
                          )}
                      </Stack>
                    </Grid>

                    {/* ----------------------Why are we billing them instead of you?---------------------- */}
                    <Grid item xs={12} md={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="billing_contact_reason">Why are we billing them instead of you?</InputLabel>
                        <TextField
                          id="billing_contact.reason"
                          name="billing_contact.reason"
                          fullWidth
                          placeholder=""
                          multiline
                          rows={3}
                          onChange={trimFc(formik)}
                          value={formik.values?.billing_contact && formik.values?.billing_contact.reason}
                        />
                      </Stack>
                    </Grid>
                  </>
                )}
              </Grid>
            </MainCard>
          </Grid>
        </Grid>
        <div className="flex flex-col items-center justify-center mt-5 space-y-3">
          {FormSubmissionAlertMessage(formik)}
          <LoadingButton
            type="submit"
            loading={formik.isSubmitting}
            loadingPosition="start"
            startIcon={<SaveOutlined />}
            variant="contained"
            size="large"
            className="py-3 px-10 text-lg"
            disabled={formik.isSubmitting}
          >
            Save
          </LoadingButton>
        </div>
      </form>
      {customerConfirmationDialog.action.open && (
        <CustomerCreateUpdateDialog
          {...customerConfirmationDialog}
          handleClose={handleCustomerCreateUpdateClose}
          handleSuccess={handleCustomerCreateUpdateDialogSuccess}
        />
      )}
    </>
  );
};

export default AddCustomerForm;
