import { CheckCircleFilled, ClearOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import CustomTooltip from 'components/CustomTooltip';
import { TUpdateEstimate } from 'components/tables/interfaces/jobTableInterface';
import { useFormik } from 'formik';
import { useRef } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';
import * as Yup from 'yup';

const EstimationUpdateForm = ({ onSave }: { onSave: (values?: any) => Promise<void> }) => {
  //-------------------constants---------------
  const theme = useTheme();
  const signatureRef = useRef<any>();
  //--------------------Formik-----------------
  const formik = useFormik<TUpdateEstimate>({
    initialValues: {
      update_reason: '',
      customer_sign_on_estimation: 'no',
      is_customer_present: false,
      customer_sign_for_estimation_updated: ''
    },
    validationSchema: Yup.object().shape({
      update_reason: Yup.string().required('This field is required'),
      customer_sign_for_estimation_updated: Yup.string().when('is_customer_present', ([is_customer_present], schema) => {
        if (is_customer_present) return schema.required('This field is required');
        return schema.nullable();
      })
    }),
    onSubmit: async (values: TUpdateEstimate, { setSubmitting }) => {
      setSubmitting(true);

      handleSubmit(values, setSubmitting);
    }
  });
  const handleSubmit = (values: TUpdateEstimate, setSubmitting: (isSubmitting: boolean) => void) => {
    if (values.customer_sign_on_estimation === 'no') {
      delete values?.is_customer_present;
      delete values?.customer_sign_for_estimation_updated;
    } else if (values?.is_customer_present === false) delete values?.customer_sign_for_estimation_updated;
    onSave(values).finally(() => setSubmitting(false));
  };
  //------------------Handlers-------------------
  const clearSignature = () => {
    signatureRef?.current?.clear();
    formik.setFieldValue('customer_sign_for_estimation_updated', '');
  };

  return (
    <Grid container component={'form'} onSubmit={formik.handleSubmit} spacing={2}>
      <Grid item xs={12}>
        <InputLabel>Please provide a reason for this updation: *</InputLabel>
        <TextField
          fullWidth
          multiline
          minRows={3}
          onChange={formik.handleChange}
          name="update_reason"
          error={!!formik.touched.update_reason && !!formik.errors.update_reason}
        />
        {formik.touched.update_reason && formik.errors.update_reason && (
          <FormHelperText error id="helper-text-update_reason-signup">
            {formik.errors?.update_reason}
          </FormHelperText>
        )}
      </Grid>
      <Grid item xs={12}>
        <InputLabel>Does customer need to sign-off on this job?*</InputLabel>
        <RadioGroup row value={formik.values.customer_sign_on_estimation} onChange={formik.handleChange} name="customer_sign_on_estimation">
          <FormControlLabel control={<Radio />} value={'required'} label="Yes" />
          <FormControlLabel control={<Radio />} value={'no'} label="No" />
        </RadioGroup>
        {formik.touched.customer_sign_on_estimation && formik.errors.customer_sign_on_estimation && (
          <FormHelperText error id="helper-text-.customer_sign_on_estimation-signup">
            {formik.errors?.customer_sign_on_estimation}
          </FormHelperText>
        )}
      </Grid>
      {formik.values.customer_sign_on_estimation === 'required' && (
        <Grid item xs={12} sm={6}>
          <InputLabel>Is Customer Present?*</InputLabel>
          <RadioGroup
            row
            value={formik.values.is_customer_present}
            onChange={(event, newValue) => formik.setFieldValue('is_customer_present', newValue === 'true')}
            name="is_customer_present"
          >
            <FormControlLabel control={<Radio />} value={true} label="Yes" />
            <FormControlLabel control={<Radio />} value={false} label="No" />
          </RadioGroup>
          {formik.touched.is_customer_present && formik.errors.is_customer_present && (
            <FormHelperText error id="helper-text-.is_customer_present-signup">
              {formik.errors?.is_customer_present}
            </FormHelperText>
          )}
        </Grid>
      )}
      {formik.values.customer_sign_on_estimation === 'required' && formik.values.is_customer_present && (
        <Grid item xs={12}>
          <Stack>
            <div className="flex items-center justify-between">
              <Typography>
                Customer Sign*&nbsp;
                {!!formik.values?.customer_sign_for_estimation_updated && (
                  <CheckCircleFilled style={{ color: theme.palette.success.main }} />
                )}
              </Typography>
              <CustomTooltip message="Clear">
                <IconButton onClick={clearSignature} color="error">
                  <ClearOutlined />
                </IconButton>
              </CustomTooltip>
            </div>
            <ReactSignatureCanvas
              penColor="black"
              canvasProps={{
                className: 'signateureCanvas',
                style: {
                  width: '100%',
                  border:
                    !!formik.touched.customer_sign_for_estimation_updated && !!formik.errors.customer_sign_for_estimation_updated
                      ? '1px solid red'
                      : '1px solid black',
                  minHeight: 200
                }
              }}
              ref={signatureRef}
              onEnd={() => formik.setFieldValue('customer_sign_for_estimation_updated', signatureRef?.current?.toDataURL())}
            />
          </Stack>
          {formik.touched.customer_sign_for_estimation_updated && formik.errors.customer_sign_for_estimation_updated && (
            <FormHelperText error id="helper-text-.customer_sign_for_estimation_updated-signup">
              {formik.errors?.customer_sign_for_estimation_updated}
            </FormHelperText>
          )}
        </Grid>
      )}
      <Grid item xs={12} className="flex justify-end">
        <Button
          type="submit"
          variant="contained"
          startIcon={formik.isSubmitting && <LoadingOutlined />}
          disabled={
            formik.isSubmitting ||
            (formik.values.customer_sign_on_estimation === 'required' &&
              formik.values.is_customer_present === true &&
              formik.values?.customer_sign_for_estimation_updated?.length === 0)
          }
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default EstimationUpdateForm;
