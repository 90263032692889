import { DeleteOutlined, LoadingOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { DatePicker, DateTimePicker, LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useQuery } from '@tanstack/react-query';
import CustomTooltip from 'components/CustomTooltip';
import FormSubmissionAlertMessage from 'components/FormSubmissionAlertMessage';
import UniversalDialog from 'components/popups/UniversalDialog';
import {
  IEstimationOption,
  IReminder,
  TAssignWorker,
  TJobAction,
  TJobCompleteData,
  TOfficeToWorkerNote,
  TScheduleSMSData
} from 'components/tables/interfaces/jobTableInterface';
import TUserData from 'components/tables/interfaces/userTableInterface';
import { universalRenerMedia } from 'components/UniversalRenderMedia';
import dayjs, { Dayjs } from 'dayjs';
import { getIn, useFormik } from 'formik';
import useAuth from 'hooks/useAuth';
import { ChangeEvent, useEffect, useState } from 'react';
import CustomerServicesInstance from 'services/services.customers';
import JobServicesInstance from 'services/services.jobs';
import MediaServicesInstance from 'services/services.media';
import UserServicesInstance from 'services/services.users';
import { useSelector } from 'store';
import { TMedia } from 'types/globalTypes/type.media';
import { checkApprovedOption, formatCostInDecimal, formatCurrency, handleJobReminder, isAssignWorkerFieldHidden } from 'utils/common';
import { multiActionJobTypeToolTip, notificationSendingChannels, reminderTypes, scheduleSmsInfoForFieldWorker } from 'utils/constants';
import trimFc, { ltrim, rtrim } from 'utils/trimFc';
import { v4 as uuidv4 } from 'uuid';
import { uuid } from 'uuidv4';
import * as Yup from 'yup';
import AddScheduleSMSForm from './AddScheduleSMSForm';
import SelectJobImages from './SelectJobImages';

//-----------Types----------

const AssignWorkerForm = ({
  isEditMode,
  isAddNewAction,
  jobData,
  jobActionIndex,
  onSuccess,
  isReccuringJob = false,
  followupId
}: {
  isEditMode: boolean;
  isAddNewAction?: boolean;
  jobData: TJobCompleteData;
  jobActionIndex?: number;
  onSuccess?: (isAddNewAction: boolean) => void;
  isReccuringJob: boolean;
  followupId?: string;
}) => {
  //-------------------------------------Constants------------------------

  const theme = useTheme();
  const { user } = useAuth();

  const [files, setFiles] = useState<string[]>([]);
  const [openSheduleSMS, setOpenSheduleSMS] = useState(false);
  const [workerCommission, setWorkerCommission] = useState('');
  const [sheduleSMSValues, setSheduleSMSValues] = useState<TScheduleSMSData>();
  const [reminder, setReminder] = useState<string>('1daybefore');
  const [officeToWorkerNotes, setOfficeToWorkerNotes] = useState<string>('');
  const [isMaterialRequired, setIsMaterialRequired] = useState<boolean>(false);
  const [openJobImagesPopup, setOpenJobImagesPopup] = useState<boolean>(false);
  const [openMultiActionDialog, setOpenMultiActionDialog] = useState<boolean>(false);
  const [isShowMoreOptionSelected, setIsShowMoreOptionSelected] = useState(false);
  const [isAssignWorkerLoading, setIsAassignWorkerLoading] = useState(false);
  const [triggerAddNewAction, setTriggerAddNewAction] = useState(false);

  const [availableWorkers, setAvailableWorkers] = useState<any[]>([]);

  //----------------Selector---------------------
  const { generalSettings: generalSettingsData } = useSelector((state) => state.settings);

  //-------------------------------------------------useQuery----------------------------
  const { data: mediaData } = useQuery({
    queryKey: ['media_data'],
    queryFn: () => MediaServicesInstance.getMedia('customers', false, jobData?.customer?.id ?? '', 'media'),
    enabled: !!jobData?.customer?.id
  });
  const { data: allWorker, isLoading: isWorkersFetching } = useQuery({
    queryKey: ['all_worker_data'],
    queryFn: () =>
      UserServicesInstance.getAllUsers(undefined, {
        search: [
          [
            {
              field_name: 'status',
              field_value: 1,
              operator: 'exactmatch'
            }
          ],
          [{ field_name: 'role', field_value: ['field_worker', 'company_admin'], operator: 'in' }]
        ]
      })
  });
  const { data: customerData } = useQuery({
    queryKey: ['customer_data'],
    queryFn: () => CustomerServicesInstance.getSingleCustomer(jobData.customer?.id ?? ''),
    enabled: !!jobData.customer?.id
  });
  const { data: recurringJobName, isLoading: isRecurringJobNameFetching } = useQuery({
    queryKey: ['recurring_job_name', isReccuringJob, jobData],
    queryFn: () => {
      if (!!jobData._id && isReccuringJob === true) {
        return JobServicesInstance.getRecurringJobName(jobData.parent_job_reference?.id ?? jobData._id);
      }
    }
  });

  //-----------------------------------------Formik-----------------------------
  const formik = useFormik<TAssignWorker>({
    initialValues: {
      id: '',
      job_name: '',
      job_instructions: '',
      field_worker: { id: '', name: '' },
      worker_commission: null as unknown as number,
      is_job_require_date: false,
      job_reminder: {
        exclude_time: false,
        scheduled_date_and_time: null,
        reminder_date: '',
        reminder_method: [1, 2] as number[]
      },
      customer_want_call_first_for_worker: false,
      display_job_images: false,
      job_images: [],
      customer_sign_on_job: 'no',
      completion_photos_required: 'no',
      is_multi_action_job_required_additional_field_workers: false,
      multi_action_job_type: undefined,
      job_frequency: 'one_time',
      record_materials_used: 'no',
      office_to_worker_notes: jobData.office_to_worker_notes as TOfficeToWorkerNote[],
      log_note: 'Setting up a Job/Assigning a Field Worker.',
      field_worker_can_view_pricing: true,
      automatically_send_invoice: true //for initial user only
      // For the initial worker: There is no issue with handling `view_pricing`, and we can show the job phase based on this variable.
      // The challenge arises with the `auto_send_invoice` feature.
      // In multi-action jobs, if one worker marks their task as complete (and `auto_send_invoice` is set to true), we should not send the invoice immediately, as other workers may still be working on their tasks.
      // The invoice should only be sent after all workers have completed their tasks and the entire job is marked as complete.
    },

    validationSchema: Yup.object().shape({
      job_name: Yup.string().required('This field is required'),
      job_instructions: Yup.string().required('This field is required'),
      job_reminder: Yup.object().when('is_job_require_date', ([is_job_require_date], schema) => {
        return is_job_require_date === true
          ? schema.shape({
              reminder_date: Yup.string().test({
                name: 'reminder_date',
                exclusive: true,
                message: 'Reminder date is required',
                test: function (value) {
                  return reminder === 'choosecustomedate' ? !!value : true;
                }
              }),

              scheduled_date_and_time: Yup.string().required('This field is required')
            })
          : schema.nullable();
      }),
      field_worker: Yup.object().shape({
        id: Yup.string().required('This field is required')
      }),
      worker_commission: Yup.string().when('field_worker', ([field_worker]) => {
        const selectedWorker = allWorker?.users.find((eachWorker) => eachWorker._id === field_worker.id);
        if (!selectedWorker) {
          return Yup.string().nullable().optional();
        }
        if (selectedWorker?.rate_info?.pay_type === 'commission') {
          return Yup.string().required('This field is required');
        }
        return Yup.string().nullable().optional();
      }),
      log_note: Yup.string().max(255).required('This field is required'),
      sequential_action_instruction: Yup.string().when('multi_action_job_type', {
        is: 'sequential',
        then: (schema) => schema.required('This Field is required'),
        otherwise: (schema) => schema.nullable()
      })
    }),

    onSubmit: async (values) => {
      handleOfficeToWorkerNotes();
      if (values.is_multi_action_job_required_additional_field_workers && !!values?.multi_action_job_type === false) {
        setOpenMultiActionDialog(true);
        return;
      }

      values.worker_commission = Number(values.worker_commission);
      handleSubmit(values);
    }
  });

  const handleSubmit = (values: TAssignWorker) => {
    if (isReccuringJob) handleRecurringJobSubmit(JSON.parse(JSON.stringify(values)));
    else handleAssignWorkerFormSubmit(JSON.parse(JSON.stringify(values)));
  };

  const handleRecurringJobSubmit = async (values: TAssignWorker) => {
    if (!isMaterialRequired) delete values.record_materials_used;
    if (!values.display_job_images) delete values.job_images;
    if (values.is_job_require_date) {
      values.job_reminder = {
        ...values.job_reminder,
        reminder_date: handleJobReminder(formik, reminder)
      } as IReminder;
    } else {
      // values.job_reminder = { reminder_date: '', reminder_method: [], scheduled_date_and_time: '' };
      delete values.job_reminder;
    }
    if (values.field_worker_can_view_pricing) {
      delete values.automatically_send_invoice;
    }
    if (jobData?._id && jobData.job_name) {
      setIsAassignWorkerLoading(true);
      const editedJobResponse = await JobServicesInstance.createRecurringJob(
        jobData?.parent_job_reference?.id ?? jobData._id,
        values,
        followupId ?? ''
      );
      setIsAassignWorkerLoading(false);
      if (editedJobResponse) {
        if (sheduleSMSValues) {
          const scheduleObj: TScheduleSMSData = {
            ...sheduleSMSValues,
            job: {
              id: jobData?._id,
              name: jobData.job_name
            },
            customer: {
              id: jobData.customer?.id as string,
              name: jobData.customer?.name as string
            }
          };
          await JobServicesInstance.addScheduleSMS(scheduleObj);
        }
        if (values.is_multi_action_job_required_additional_field_workers) {
          formik.resetForm();
          setOpenMultiActionDialog(false);
        }
        onSuccess?.(
          values.is_multi_action_job_required_additional_field_workers === true && values.multi_action_job_type === 'simultaneously'
        );
        setTriggerAddNewAction((prev) => !prev);
      }
    }
  };
  const handleAssignWorkerFormSubmit = async (values: TAssignWorker) => {
    delete values.job_name;
    if (!isMaterialRequired) delete values.record_materials_used;
    if (!values.display_job_images) delete values.job_images;
    if (values.is_job_require_date) {
      values.job_reminder = {
        ...values.job_reminder,
        reminder_date: handleJobReminder(formik, reminder)
      } as IReminder;
    } else {
      // values.job_reminder = { reminder_date: '', reminder_method: [], scheduled_date_and_time: '' };
      delete values.job_reminder;
    }

    if (values.field_worker_can_view_pricing) {
      delete values.automatically_send_invoice;
    }

    if (jobData?._id && jobData.job_name) {
      setIsAassignWorkerLoading(true);

      const editedJobResponse = await JobServicesInstance.assignFieldWorker(jobData?._id, values, isEditMode, followupId);
      setIsAassignWorkerLoading(false);
      if (editedJobResponse) {
        if (sheduleSMSValues) {
          const scheduleObj: TScheduleSMSData = {
            ...sheduleSMSValues,
            job: {
              id: jobData?._id,
              name: jobData.job_name
            },
            customer: {
              id: jobData.customer?.id as string,
              name: jobData.customer?.name as string
            }
          };
          await JobServicesInstance.addScheduleSMS(scheduleObj);
        }
        formik.resetForm();
        setOpenMultiActionDialog(false);
        onSuccess?.(
          values.is_multi_action_job_required_additional_field_workers === true && values.multi_action_job_type === 'simultaneously'
        );
        setTriggerAddNewAction((prev) => !prev);
      }
    }
  };

  //----------------------------------Handlers-----------------------------
  const handleJobRequireDate = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === 'true') {
      setReminder('1daybefore');
      formik.setFieldValue('job_reminder', {
        scheduled_date_and_time: null,
        reminder_date: new Date().toISOString(),
        reminder_method: [1, 2],
        exclude_time: generalSettingsData?.assign_worker_page?.is_job_require_date.exclude_time
      });
    } else {
      formik.setFieldValue('job_reminder.exclude_time', generalSettingsData?.assign_worker_page?.is_job_require_date.exclude_time);
    }
    formik.setFieldValue('is_job_require_date', value === 'true');
  };
  const getDefaultFieldWorker = () => {
    const matchedWorker = generalSettingsData?.default_field_worker
      ? allWorker?.users.some((eachFieldWorker) => eachFieldWorker._id === generalSettingsData.default_field_worker.id)
      : false;

    return matchedWorker ? generalSettingsData?.default_field_worker : { id: '', name: '' };
  };

  const onAddScheduleSMSFromSubmit = (values: TScheduleSMSData) => {
    setSheduleSMSValues(values);
    setOpenSheduleSMS(false);
  };
  //-----------DisplayJobImages---------------
  const handleSubmitJobImages = (jobImages: string[]) => {
    if (!!jobImages.length) {
      formik.setFieldValue('job_images', jobImages);
      formik.setFieldValue('display_job_images', true);
      setOpenJobImagesPopup(false);
    }
  };
  const handleDisplayJobImages = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === 'true') {
      setOpenJobImagesPopup(true);
      return;
    }
    formik.setFieldValue('display_job_images', false);
    formik.setFieldValue('job_images', []);
  };
  const handleRemoveSelectedfile = (index: number) => {
    if (!!formik.values?.job_images?.length) {
      const dummyData = [...formik.values.job_images];
      dummyData.splice(index, 1);
      formik.setFieldValue('job_images', dummyData);
      if (dummyData.length === 0) formik.setFieldValue('display_job_images', false);
    }
  };

  const handleOfficeToWorkerNotes = () => {
    if (!!officeToWorkerNotes?.length && !!ltrim(rtrim(officeToWorkerNotes))?.length) {
      const currentDateTime = new Date();
      const newOfficeToWorkerNotes = [...(formik.values.office_to_worker_notes ?? ([] as TOfficeToWorkerNote[]))];
      newOfficeToWorkerNotes.push({
        text: officeToWorkerNotes,
        id: uuid(),
        created_at: currentDateTime.toISOString(),
        created_by: { id: user?._id as string, name: `${user?.first_name} ${user?.last_name}` },
        updated_at: currentDateTime.toISOString(),
        updated_by: { id: user?._id as string, name: `${user?.first_name} ${user?.last_name}` }
      });
      formik.setFieldValue('office_to_worker_notes', newOfficeToWorkerNotes);
    }
  };

  const handleWorkerComission = (event: React.SyntheticEvent, newValue: { value?: string; label?: string }) => {
    formik.setFieldValue('field_worker', { id: newValue?.value ?? '', name: newValue?.label ?? '' });
    const isCommissionBasedWorker = availableWorkers.find(
      (fieldWorker) => newValue?.value === fieldWorker._id && fieldWorker?.rate_info?.pay_type === 'commission'
    );

    if (isCommissionBasedWorker) {
      formik.setFieldValue('worker_commission', workerCommission.length ? Math.round(parseInt(workerCommission)) : '');
    } else {
      formik.setFieldValue('worker_commission', '');
    }
  };
  //---------------------------------------useEffects--------------------------
  useEffect(() => {
    console.log('errors in assign worker form ', formik.errors);
  }, [formik.errors]);

  useEffect(() => {
    const sumOfAllCommission =
      jobData.estimations?.options[checkApprovedOption(jobData.estimations?.options)].calculated_worker_commission ?? 0;

    setWorkerCommission(() => sumOfAllCommission.toFixed(3));
  }, [jobData]);

  useEffect(() => {
    if (!!allWorker?.users && generalSettingsData?.default_field_worker?.id?.length && !isEditMode)
      formik.setFieldValue('field_worker', getDefaultFieldWorker());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allWorker?.users, isEditMode, generalSettingsData?.default_field_worker]);

  useEffect(() => {
    let allWorkers: TUserData[] = [];
    let avlWrkrs: any[] = [];

    if (allWorker?.users && allWorker?.users?.length > 0) {
      allWorkers = allWorker.users;

      if (isEditMode) {
        if (isAddNewAction) {
          const alreadyAssignedWorkers = jobData.job_action?.flatMap((eachAction) => eachAction.field_worker.id);
          if (alreadyAssignedWorkers && alreadyAssignedWorkers?.length > 0 && allWorker?.users && allWorker?.users?.length > 0) {
            avlWrkrs = allWorker?.users
              .filter((eachWorker) => !alreadyAssignedWorkers.includes(eachWorker._id as string))
              .map((singleWorker) => ({
                value: singleWorker._id,
                label: `${singleWorker.first_name} ${singleWorker.last_name}`
              }));
          }
        } else {
          avlWrkrs = allWorkers.map((singleWorker) => ({
            value: singleWorker._id,
            label: `${singleWorker.first_name} ${singleWorker.last_name}`
          }));
          formik.setFieldValue('field_worker', getDefaultFieldWorker());
        }
      } else {
        avlWrkrs = allWorkers.map((singleWorker) => ({
          value: singleWorker._id,
          label: `${singleWorker.first_name} ${singleWorker.last_name}`
        }));
        formik.setFieldValue('field_worker', getDefaultFieldWorker());
      }
    } else {
      avlWrkrs = [];
    }

    setAvailableWorkers(avlWrkrs);
  }, [allWorker?.users, isEditMode, isAddNewAction, jobData.job_action]);

  useEffect(() => {
    if (
      Object.keys(jobData).length > 0 &&
      (isEditMode || (!!generalSettingsData?.assign_worker_page && Object.keys(generalSettingsData?.assign_worker_page).length > 0))
    ) {
      const currenWorker = jobData.job_action?.[jobActionIndex as number];
      const sumOfAllCommission =
        jobData.estimations?.options[checkApprovedOption(jobData.estimations?.options)].calculated_worker_commission ?? 0;

      const lastJobAction = jobData.job_action?.[jobData.job_action?.length - 1];
      let jobInstructions = '';

      if (isEditMode && !isAddNewAction) {
        jobInstructions = currenWorker?.job_instructions ?? '';
      } else if (isAddNewAction) {
        jobInstructions = lastJobAction?.sequential_action_instruction ?? '';
      } else {
        const approvedOption = jobData?.estimations?.options?.find((singleEstimateOption) => singleEstimateOption?.approved === true);
        const descriptions =
          approvedOption?.items
            ?.map((eachItem) => (eachItem?.description_to_field_worker?.length ? eachItem.description_to_field_worker : ''))
            .join('\n') ?? '';

        jobInstructions = (jobData?.estimator_instructions ?? '').concat('\n', descriptions);
      }

      formik.setValues({
        ...(isReccuringJob === false && { job_name: jobData?.job_name }),
        id: isEditMode && !isAddNewAction ? currenWorker?.id ?? '' : uuidv4(),
        job_instructions: jobInstructions,

        field_worker:
          isEditMode && !isAddNewAction
            ? (currenWorker?.field_worker as TJobAction['field_worker'])
            : { id: '', name: '', profile_picture: '' },

        worker_commission: sumOfAllCommission.toFixed(3).length
          ? Math.round(parseInt(sumOfAllCommission.toFixed(3)))
          : (null as unknown as number),
        is_job_require_date: isEditMode
          ? currenWorker?.is_job_require_date ?? false
          : generalSettingsData?.assign_worker_page?.is_job_require_date.value === 'yes',
        job_reminder:
          isEditMode && !!currenWorker?.job_reminder && !isAddNewAction
            ? { ...currenWorker?.job_reminder, exclude_time: currenWorker?.job_reminder?.exclude_time ?? false }
            : {
                scheduled_date_and_time: null,
                reminder_date: '',
                reminder_method: [] as unknown as number[],
                exclude_time: generalSettingsData?.assign_worker_page?.is_job_require_date.exclude_time
              },

        display_job_images: isEditMode
          ? isReccuringJob
            ? false
            : currenWorker?.display_job_images ?? false
          : generalSettingsData?.assign_worker_page?.display_job_images.value === 'yes',
        job_images: isEditMode && !isReccuringJob ? currenWorker?.job_images : [],
        customer_want_call_first_for_worker: isEditMode
          ? isReccuringJob
            ? false
            : currenWorker?.customer_want_call_first_for_worker ?? false
          : generalSettingsData?.assign_worker_page?.customer_want_call_first.value === 'yes',

        field_worker_can_view_pricing: isEditMode
          ? jobData.field_worker_can_view_pricing ?? generalSettingsData?.assign_worker_page?.field_worker_can_view_pricing.value === 'yes'
          : generalSettingsData?.assign_worker_page?.field_worker_can_view_pricing.value === 'yes',

        automatically_send_invoice: isEditMode
          ? jobData.automatically_send_invoice ?? generalSettingsData?.assign_worker_page?.automatically_send_invoice.value === 'yes'
          : generalSettingsData?.assign_worker_page?.automatically_send_invoice.value === 'yes',

        ...(!!user &&
          user.role !== 'field_worker' &&
          (!isEditMode ||
            isReccuringJob ||
            isAddNewAction ||
            currenWorker?.is_multi_action_job_required_additional_field_workers === false) && {
            is_multi_action_job_required_additional_field_workers:
              isEditMode && (isReccuringJob || !isAddNewAction)
                ? (currenWorker?.is_multi_action_job_required_additional_field_workers as boolean)
                : generalSettingsData?.assign_worker_page?.is_multi_action_job_required_additional_field_workers.value === 'yes'
          }),

        customer_sign_on_job: isEditMode
          ? currenWorker?.customer_sign_on_job ?? 'no'
          : (generalSettingsData?.assign_worker_page?.customer_sign_on_job.value as 'no'),

        completion_photos_required: isEditMode
          ? currenWorker?.completion_photos_required ?? 'no'
          : (generalSettingsData?.assign_worker_page?.completion_photos_required.value as 'no'),

        job_frequency: isEditMode ? jobData.job_frequency : generalSettingsData?.assign_worker_page?.job_frequency.value,

        record_materials_used: isEditMode
          ? currenWorker?.record_materials_used
          : generalSettingsData?.assign_worker_page?.record_materials_used.value,

        office_to_worker_notes: isReccuringJob ? [] : jobData.office_to_worker_notes ?? [],
        log_note: isEditMode ? '' : 'Setting up a Job/Assigning a Field Worker.'
      });
      const flaggedMedia: string[] = [];
      jobData?.estimations?.options.forEach((eachOption) => {
        if (!!eachOption.approved) {
          eachOption.files?.forEach((eachFile) => {
            if (!!eachFile.flagForJob) flaggedMedia.push(eachFile.url);
          });
        }
      });

      if (flaggedMedia.length > 0) {
        formik.setFieldValue('display_job_images', true);
        formik.setFieldValue('job_images', flaggedMedia);
      }

      const approvedEstimateIndex = checkApprovedOption(jobData.estimations?.options as IEstimationOption[]);
      const isMaterialUsed =
        jobData.estimations?.options[approvedEstimateIndex].items.some((singleItem) => singleItem.record_materials_used) ?? false;
      setIsMaterialRequired(isMaterialUsed);

      setOfficeToWorkerNotes(
        jobData.office_to_worker_notes ? jobData.office_to_worker_notes[jobData.office_to_worker_notes?.length - 1]?.text : ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobData, generalSettingsData, triggerAddNewAction]);

  useEffect(() => {
    !!mediaData &&
      setFiles(() => {
        const urls = (mediaData as TMedia[]).map((singleMedia: TMedia) => singleMedia.url);
        return urls;
      });
  }, [mediaData]);

  useEffect(() => {
    if (isReccuringJob && isRecurringJobNameFetching === false) formik.setFieldValue('job_name', recurringJobName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecurringJobNameFetching]);

  useEffect(() => {
    if (!isEditMode && generalSettingsData?.assign_worker_page) {
      formik.setFieldValue('job_reminder.exclude_time', generalSettingsData?.assign_worker_page.is_job_require_date.exclude_time ?? false);
    }
  }, [generalSettingsData?.assign_worker_page, isEditMode]);

  const getComissionInput = () => {
    const selectedWorker = allWorker?.users.find((worker: TUserData) => formik.values?.field_worker?.id === worker._id);
    if (selectedWorker) {
      if (selectedWorker.rate_info?.pay_type === 'hourly') {
        return <TextField fullWidth disabled aria-disabled value={'Punch Emp.'} InputProps={{ startAdornment: '$' }} />;
      }

      return (
        <TextField
          inputProps={{ min: 0 }}
          fullWidth
          inputMode="decimal"
          id="worker_commission"
          name="worker_commission"
          value={formik.values.worker_commission ?? ''}
          onBlur={(event) => {
            const numericValue = parseFloat(event.target.value);
            if (!isNaN(numericValue)) {
              formik.setFieldValue('worker_commission', numericValue.toFixed(2));
            }
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            formik.setFieldValue('worker_commission', formatCostInDecimal(event.target.value));
          }}
          placeholder={selectedWorker.rate_info?.pay_type === 'salary' ? 'Salaried Emp.' : ''}
          InputProps={{ startAdornment: '$' }}
          error={!!formik.touched.worker_commission && !!formik.errors.worker_commission}
          helperText={formik.touched.worker_commission && formik.errors.worker_commission}
        />
      );
    }
  };

  return (
    <>
      <Grid
        container
        component={'form'}
        onSubmit={(event) => {
          const allErrors = Object.keys(formik.errors);

          if (allErrors.length > 0) {
            const firstError = allErrors[0];

            const firstErrorElement = document.getElementById(firstError);
            if (firstErrorElement) {
              firstErrorElement.focus();
              firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          }

          formik.handleSubmit(event);
        }}
        spacing={4}
        columnSpacing={12}
      >
        {/* --------------------------------LEFT SECTION-------------------------------------- */}
        <Grid item container sm={6} xs={12} spacing={4} justifyContent={'flex-start'}>
          {/* -----------------------------------------Job Name--------------------------------- */}
          <Grid item xs={12}>
            <InputLabel className="whitespace-pre-line" htmlFor="job_name">
              Job Name
            </InputLabel>
            <OutlinedInput
              id="job_name"
              disabled={!isReccuringJob}
              value={formik.values.job_name}
              name="job_name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              fullWidth
              error={!!formik.touched.job_name && !!formik.errors.job_name}
              className="text-black"
            />
          </Grid>
          {/* -----------------------------Job Assigned to *------------------------------ */}
          <Grid item sm={6} xs={12}>
            <InputLabel className="whitespace-pre-line" htmlFor="field_worker">
              Job Assigned To *
            </InputLabel>
            <Autocomplete
              loading={isWorkersFetching}
              loadingText="Fetching Workers..."
              options={availableWorkers}
              isOptionEqualToValue={(option, optionValue) => {
                return option.value === optionValue.value;
              }}
              disableClearable
              getOptionLabel={(option) => option.label}
              value={(() => {
                const field_worker = (allWorker?.users ?? []).find((singleData) => singleData._id === formik.values?.field_worker?.id);
                return field_worker
                  ? { value: field_worker._id, label: `${field_worker.first_name} ${field_worker.last_name}` }
                  : { value: '', label: '' };
              })()}
              onChange={handleWorkerComission}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                />
              )}
            />
            {getIn(formik.touched, 'field_worker.id') && getIn(formik.errors, 'field_worker.id') && (
              <FormHelperText error id="helper-text-first_name">
                {getIn(formik.errors, 'field_worker.id')}
              </FormHelperText>
            )}
          </Grid>

          {/* -----------------------------------------Worker Commission--------------------------------- */}
          <Grid item sm={6} xs={12} className="space-y-2">
            {formik.values.field_worker?.name && (
              <Stack>
                <InputLabel className="whitespace-pre-line" htmlFor="worker_commission">
                  Worker Commission
                </InputLabel>
                {getComissionInput()}
              </Stack>
            )}
          </Grid>

          {/* -----------------------------Instructions/Details for Worker *------------------------------ */}
          <Grid item sm={6} xs={12}>
            <InputLabel className="whitespace-pre-line">Job Instructions/Details *</InputLabel>
            <TextField
              id="job_instructions"
              fullWidth
              name="job_instructions"
              multiline
              rows={3}
              onChange={trimFc(formik)}
              value={formik.values?.job_instructions}
              error={!!formik.touched.job_instructions && !!formik.errors.job_instructions}
            />
            {formik.touched.job_instructions && formik.errors.job_instructions && (
              <FormHelperText error id="helper-text-job_instructions -signup">
                {formik.errors?.job_instructions}
              </FormHelperText>
            )}
          </Grid>

          {/* -----------------------------Job Price----------------------------- */}
          <Grid item xs={12} sm={6} className="space-y-2">
            <InputLabel className="whitespace-pre-line" htmlFor="">
              Job Price*
            </InputLabel>

            <TextField
              InputProps={{ readOnly: true, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              value={formatCurrency(jobData.job_price)}
              fullWidth
            />
          </Grid>

          {/* -----------------------------Office To Worker Notes------------------------------ */}
          <Grid item xs={12}>
            <Stack>
              <InputLabel className="whitespace-pre-line" htmlFor="office_to_worker_notes">
                Office To Worker Notes
              </InputLabel>
              <Divider className="mb-3" />

              <TextField
                className="w-full"
                id={`office_to_worker_notes`}
                fullWidth
                name={`office_to_worker_notes`}
                multiline
                rows={3}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  setOfficeToWorkerNotes(event.target.value ?? '');
                }}
                value={officeToWorkerNotes}
              />
            </Stack>
          </Grid>
        </Grid>

        {/* --------------------------------RIGHT SECTION-------------------------------------- */}
        <Grid item container sm={6} xs={12} alignItems={'flex-start'} justifyContent={'start'} spacing={4} height={'max-content'}>
          {!isEditMode &&
            !!generalSettingsData?.assign_worker_page &&
            Object.values(generalSettingsData?.assign_worker_page).some((singleOption) => singleOption?.is_hidden) && (
              <Grid item xs={12}>
                <Button onClick={() => setIsShowMoreOptionSelected((prev) => !prev)} variant="dashed">
                  {isShowMoreOptionSelected ? 'Show less options' : 'Show more options'}
                </Button>
              </Grid>
            )}

          {/* ---------------------------REQUIRE DATE-------------------- */}
          {isAssignWorkerFieldHidden(
            generalSettingsData?.assign_worker_page,
            'is_job_require_date',
            isShowMoreOptionSelected,
            isEditMode
          ) && (
            <Grid item xs={12} sm={6}>
              <InputLabel className="whitespace-pre-line" htmlFor="is_job_require_date">
                Does this job require a date?
              </InputLabel>
              <RadioGroup
                row
                aria-label="is_job_require_date"
                onChange={handleJobRequireDate}
                name="is_job_require_date"
                id="is_job_require_date"
                value={formik.values.is_job_require_date ? 'true' : 'false'}
              >
                <FormControlLabel value={true} control={<Radio />} label={'Yes'} />
                <FormControlLabel value={false} control={<Radio />} label={'No'} />
              </RadioGroup>
            </Grid>
          )}

          {/* ---------------------------SCHEDULE DATE INPUT-------------------- */}
          {formik.values.is_job_require_date && (
            <Grid item xs={12} container spacing={1} className="border-b-2 pb-2 mx-4 !px-0">
              {/* -----------------------------------------Scheduled Date ------------------------------ */}
              <Grid item className="space-y-2" xs={12} sm={6} md={4}>
                <InputLabel htmlFor="scheduled_time">
                  {formik.values.job_reminder?.exclude_time ? 'Schedule Date' : 'Schedule Date & Time'}
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {formik.values.job_reminder?.exclude_time ? (
                    <DatePicker
                      disablePast
                      className="w-full"
                      value={dayjs(formik.values.job_reminder?.scheduled_date_and_time)}
                      onChange={(newValue: Dayjs | null) => {
                        formik.setFieldValue('job_reminder.scheduled_date_and_time', newValue?.isValid() ? newValue?.toISOString() : null);
                      }}
                    />
                  ) : (
                    <DateTimePicker
                      className="w-full"
                      value={dayjs(formik.values.job_reminder?.scheduled_date_and_time)}
                      disablePast
                      onChange={(newValue: Dayjs | null) => {
                        formik.setFieldValue('job_reminder.scheduled_date_and_time', newValue?.isValid() ? newValue?.toISOString() : null);
                      }}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock
                      }}
                    />
                  )}
                  {getIn(formik.touched, 'job_reminder.scheduled_date_and_time') &&
                    getIn(formik.errors, 'job_reminder.scheduled_date_and_time') && (
                      <FormHelperText error id="helper-text-first_name">
                        {getIn(formik.errors, 'job_reminder.scheduled_date_and_time')}
                      </FormHelperText>
                    )}
                </LocalizationProvider>
                <FormControlLabel
                  value={formik.values.job_reminder?.exclude_time}
                  name="job_reminder.exclude_time"
                  id="job_reminder.exclude_time"
                  color="primary"
                  checked={formik.values.job_reminder?.exclude_time}
                  control={
                    <Checkbox
                      onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                        formik.setFieldValue('job_reminder.exclude_time', checked)
                      }
                    />
                  }
                  label={'Exclude Time'}
                />
              </Grid>

              {/* -----------------------------------------Reminder------------------------------ */}
              <Grid item className="space-y-2" xs={12} sm={6} md={4}>
                <InputLabel className="whitespace-pre-line" htmlFor="job_reminder">
                  Reminder
                </InputLabel>
                <Autocomplete
                  isOptionEqualToValue={(option, optionValue) => {
                    return option.value === optionValue.value;
                  }}
                  id="job_reminder"
                  value={reminderTypes.find((singleData) => singleData.value === reminder) || { label: '', value: '' }}
                  disableClearable
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue: { label: string; value: string }) => {
                    setReminder(newValue.value);
                    if (newValue.value === 'choosecustomedate') formik.setFieldValue('job_reminder.reminder_date', new Date());
                  }}
                  options={reminderTypes}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder=""
                      sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                    />
                  )}
                />
              </Grid>
              {/* -----------------------------------------Reminder Date------------------------------ */}
              {reminder === 'choosecustomedate' && (
                <Grid item className="space-y-2" xs={12} sm={6} md={4}>
                  <InputLabel className="whitespace-pre-line" htmlFor="job_reminder.reminder_date">
                    Reminder Date
                  </InputLabel>
                  <DatePicker
                    className="w-full"
                    maxDate={dayjs(formik.values.job_reminder?.scheduled_date_and_time)}
                    value={dayjs(formik.values.job_reminder?.reminder_date)}
                    onChange={(newValue: Dayjs | null) => {
                      formik.setFieldValue('job_reminder.reminder_date', newValue?.toISOString());
                    }}
                  />
                  {getIn(formik.touched, 'job_reminder.reminder_date') && getIn(formik.errors, 'job_reminder.reminder_date') && (
                    <FormHelperText error id="helper-text-first_name">
                      {getIn(formik.errors, 'job_reminder.reminder_date')}
                    </FormHelperText>
                  )}
                </Grid>
              )}

              {/* -----------------------------------------Reminder Method------------------------------ */}
              <Grid item className="space-y-2" xs={12} sm={6} md={3}>
                <InputLabel className="whitespace-pre-line" htmlFor="job_reminder.reminder_method">
                  Reminder Method
                </InputLabel>
                <Stack direction={'row'} spacing={2}>
                  {notificationSendingChannels.map((items: { value: number; label: string }, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        value={items.value}
                        name="job_reminder.reminder_method"
                        id="job_reminder.reminder_method"
                        color="primary"
                        checked={formik.values.job_reminder?.reminder_method.includes(items.value)}
                        control={
                          <Checkbox
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              const newCheckObject: Array<number> = [...(formik.values.job_reminder?.reminder_method ?? [])];
                              if (event.target.checked) {
                                newCheckObject.push(Number(event.target.value));
                                formik.setFieldValue('job_reminder.reminder_method', [...new Set(newCheckObject)]);
                              } else {
                                formik.setFieldValue(
                                  'job_reminder.reminder_method',
                                  newCheckObject.filter((obj) => obj !== Number(event.target.value))
                                );
                              }
                            }}
                          />
                        }
                        label={items.label}
                      />
                    );
                  })}
                </Stack>
              </Grid>
            </Grid>
          )}

          {/* -----------------------------------------Can field worker view pricing---------------------------------- */}
          {isAssignWorkerFieldHidden(
            generalSettingsData?.assign_worker_page,
            'field_worker_can_view_pricing',
            isShowMoreOptionSelected,
            isEditMode
          ) && (
            <Grid item xs={12} sm={6}>
              <InputLabel className="whitespace-pre-line" htmlFor="field_worker_can_view_pricing">
                Can field worker view pricing?
              </InputLabel>
              <RadioGroup
                row
                aria-label="field_worker_can_view_pricing"
                value={formik.values.field_worker_can_view_pricing ? 'true' : 'false'}
                onChange={(event: ChangeEvent<HTMLInputElement>, value: string) => {
                  formik.setFieldValue('field_worker_can_view_pricing', value === 'true');
                  if (value === 'true') {
                    formik.setFieldValue('automatically_send_invoice', true);
                  }
                }}
                name="field_worker_can_view_pricing"
                id="field_worker_can_view_pricing"
              >
                <FormControlLabel value={true} control={<Radio />} label={'Yes'} />
                <FormControlLabel value={false} control={<Radio />} label={'No'} />
              </RadioGroup>
            </Grid>
          )}

          {/* -----------------------------------------DO YOU WANT TO AUTOMATICALLY SEND INVOICE?---------------------------------- */}
          {!formik.values.field_worker_can_view_pricing &&
            isAssignWorkerFieldHidden(
              generalSettingsData?.assign_worker_page,
              'automatically_send_invoice',
              isShowMoreOptionSelected,
              isEditMode
            ) && (
              <Grid item xs={12} sm={6}>
                <InputLabel className="whitespace-pre-line" htmlFor="automatically_send_invoice">
                  Do you want to automatically send invoice?
                </InputLabel>
                <RadioGroup
                  row
                  aria-label="automatically_send_invoice"
                  value={formik.values.automatically_send_invoice ? 'true' : 'false'}
                  onChange={(event: ChangeEvent<HTMLInputElement>, value: string) =>
                    formik.setFieldValue('automatically_send_invoice', value === 'true')
                  }
                  name="automatically_send_invoice"
                  id="automatically_send_invoice"
                >
                  <FormControlLabel value={true} control={<Radio />} label={'Yes'} />
                  <FormControlLabel value={false} control={<Radio />} label={'No'} />
                </RadioGroup>
              </Grid>
            )}

          {/* -----------------------------------------Does customer want a call first?---------------------------------- */}
          {isAssignWorkerFieldHidden(
            generalSettingsData?.assign_worker_page,
            'customer_want_call_first',
            isShowMoreOptionSelected,
            isEditMode
          ) && (
            <Grid item xs={12} sm={6}>
              <InputLabel className="max-w-full break-all" htmlFor="customer_want_call_first_for_worker">
                Call customer first?
              </InputLabel>
              <RadioGroup
                row
                aria-label="customer_want_call_first_for_worker"
                value={formik.values.customer_want_call_first_for_worker ? 'true' : 'false'}
                onChange={(event: ChangeEvent<HTMLInputElement>, value: string) =>
                  formik.setFieldValue('customer_want_call_first_for_worker', value === 'true')
                }
                name="customer_want_call_first_for_worker"
                id="customer_want_call_first_for_worker"
              >
                <FormControlLabel value={true} control={<Radio />} label={'Yes'} />
                <FormControlLabel value={false} control={<Radio />} label={'No'} />
              </RadioGroup>
            </Grid>
          )}

          {/* -----------------------------------------Record Material Used?---------------------------------- */}
          {isMaterialRequired &&
            isAssignWorkerFieldHidden(
              generalSettingsData?.assign_worker_page,
              'record_materials_used',
              isShowMoreOptionSelected,
              isEditMode
            ) && (
              <Grid item xs={12} sm={6}>
                <InputLabel className="whitespace-pre-line" htmlFor="record_materials_used">
                  Record Material Used?
                </InputLabel>
                <RadioGroup
                  row
                  name="record_materials_used"
                  value={formik.values.record_materials_used}
                  onChange={(event: ChangeEvent<HTMLInputElement>, value: string) => formik.setFieldValue('record_materials_used', value)}
                  id="record_materials_used"
                >
                  <FormControlLabel value={'required'} control={<Radio />} label={'Required'} />
                  <FormControlLabel value={'optional'} control={<Radio />} label={'Optional'} />
                  <FormControlLabel value={'no'} control={<Radio />} label={'No'} />
                </RadioGroup>
              </Grid>
            )}
          {/* -----------------------------------------DOES CUSTOMER NEED TO SIGN-OFF ON THIS JOB?---------------------------------- */}

          {isAssignWorkerFieldHidden(
            generalSettingsData?.assign_worker_page,
            'customer_sign_on_job',
            isShowMoreOptionSelected,
            isEditMode
          ) && (
            <Grid item xs={12} sm={6}>
              <InputLabel className="whitespace-pre-line" htmlFor="customer_sign_on_job">
                Does Customer Need To Sign-Off On This Job?
              </InputLabel>
              <RadioGroup
                row
                name="customer_sign_on_job"
                value={formik.values.customer_sign_on_job}
                onChange={(event: ChangeEvent<HTMLInputElement>, value: string) => formik.setFieldValue('customer_sign_on_job', value)}
                id="customer_sign_on_job"
              >
                <FormControlLabel value={'required'} control={<Radio />} label={'Required'} />
                <FormControlLabel value={'optional'} control={<Radio />} label={'Optional'} />
                <FormControlLabel value={'no'} control={<Radio />} label={'No'} />
              </RadioGroup>
            </Grid>
          )}

          {/* -----------------------------------------ARE COMPLETION PHOTOS REQUIRED?---------------------------------- */}
          {isAssignWorkerFieldHidden(
            generalSettingsData?.assign_worker_page,
            'completion_photos_required',
            isShowMoreOptionSelected,
            isEditMode
          ) && (
            <Grid item xs={12} sm={6}>
              <InputLabel className="whitespace-pre-line" htmlFor="completion_photos_required">
                Are Completion Photos Required?
              </InputLabel>
              <RadioGroup
                row
                name="completion_photos_required"
                value={formik.values.completion_photos_required}
                onChange={(event: ChangeEvent<HTMLInputElement>, value: string) =>
                  formik.setFieldValue('completion_photos_required', value)
                }
                id="completion_photos_required"
              >
                <FormControlLabel value={'required'} control={<Radio />} label={'Required'} />
                <FormControlLabel value={'optional'} control={<Radio />} label={'Optional'} />
                <FormControlLabel value={'no'} control={<Radio />} label={'No'} />
              </RadioGroup>
            </Grid>
          )}

          {/* -----------------------------------------Job frequency---------------------------------- */}
          {isAssignWorkerFieldHidden(generalSettingsData?.assign_worker_page, 'job_frequency', isShowMoreOptionSelected, isEditMode) && (
            <Grid item xs={12} sm={6}>
              <InputLabel className="whitespace-pre-line" htmlFor="job_frequency">
                Job frequency
              </InputLabel>
              <RadioGroup
                row
                aria-label="job_frequency"
                onChange={(event: ChangeEvent<HTMLInputElement>, value: string) => formik.setFieldValue('job_frequency', value)}
                name="job_frequency"
                id="job_frequency"
                value={formik.values.job_frequency}
              >
                <FormControlLabel value={'one_time'} control={<Radio />} label={'One Time'} />
                <FormControlLabel value={'recurring'} control={<Radio />} label={'Recurring'} />
              </RadioGroup>
            </Grid>
          )}

          {/* -----------------------------------------Would you like to display any job images?---------------------------------- */}
          {isAssignWorkerFieldHidden(
            generalSettingsData?.assign_worker_page,
            'display_job_images',
            isShowMoreOptionSelected,
            isEditMode
          ) && (
            <Grid item xs={12} sm={6}>
              <Stack>
                <InputLabel className="whitespace-pre-line" htmlFor="display_job_images">
                  Would you like to display any job images?
                </InputLabel>
                <RadioGroup
                  row
                  aria-label="display_job_images"
                  onChange={handleDisplayJobImages}
                  name="display_job_images"
                  id="display_job_images"
                  value={formik.values.display_job_images ? 'true' : 'false'}
                >
                  <FormControlLabel value={true} control={<Radio />} label={'Yes'} />
                  <FormControlLabel value={false} control={<Radio />} label={'No'} />
                </RadioGroup>
              </Stack>
            </Grid>
          )}

          {/* -----------------------------------------IS THIS A MULTI-ACTION JOB REQUIRING ADDITIONAL ASSIGNED FIELD WORKERS?---------------------------------- */}
          {isAssignWorkerFieldHidden(
            generalSettingsData?.assign_worker_page,
            'is_multi_action_job_required_additional_field_workers',
            isShowMoreOptionSelected,
            isEditMode
          ) &&
            (!isEditMode ||
              isReccuringJob ||
              isAddNewAction ||
              jobData?.job_action?.[jobActionIndex as number].is_multi_action_job_required_additional_field_workers === false) && (
              <Grid item xs={12}>
                <CustomTooltip
                  message={
                    isAddNewAction
                      ? 'Does this multi-action job require an additional field worker?'
                      : 'Is this a multi-action job requiring additional assigned field workers?'
                  }
                >
                  <InputLabel className="max-w-full break-all" htmlFor="is_multi_action_job_required_additional_field_workers">
                    <Typography className="whitespace-break-spaces">
                      {isAddNewAction
                        ? 'Does this multi-action job require an additional field worker?'
                        : 'Is this a multi-action job requiring additional assigned field workers?'}
                    </Typography>
                  </InputLabel>
                </CustomTooltip>
                <RadioGroup
                  row
                  aria-label="is_multi_action_job_required_additional_field_workers"
                  onChange={(event: ChangeEvent<HTMLInputElement>, value: string) =>
                    formik.setFieldValue('is_multi_action_job_required_additional_field_workers', value === 'true')
                  }
                  name="is_multi_action_job_required_additional_field_workers"
                  id="is_multi_action_job_required_additional_field_workers"
                  value={formik.values.is_multi_action_job_required_additional_field_workers ? 'true' : 'false'}
                >
                  <FormControlLabel value={true} control={<Radio />} label={'Yes'} />
                  <FormControlLabel value={false} control={<Radio />} label={'No'} />
                </RadioGroup>
              </Grid>
            )}
        </Grid>
        {/*-----------------------------------------------------Display Job Images----------------------------------------------------------- */}
        {formik.values.display_job_images && !!formik.values?.job_images?.length && (
          <Grid item xs={12}>
            <Accordion defaultExpanded={formik.values.display_job_images}>
              <AccordionSummary>
                Job images to be displayed {formik.values.job_images?.length > 0 && `(${formik.values.job_images?.length})`}
              </AccordionSummary>
              <AccordionDetails>
                <ImageList className="space-x-1 space-y-1" cols={5}>
                  {formik.values.job_images?.map((singleFileUrl: string, index: number) => (
                    <ImageListItem
                      key={index}
                      className="flex justify-center"
                      sx={{ maxHeight: '300px', minHeight: '100px', minWidth: '200px' }}
                    >
                      {universalRenerMedia(singleFileUrl, index)}
                      <ImageListItemBar
                        className="p-1 bg-transparent"
                        position="top"
                        actionIcon={
                          <IconButton onClick={() => handleRemoveSelectedfile(index)} className="bg-red-400 text-white !p-1">
                            <DeleteOutlined className="font-lg" />
                          </IconButton>
                        }
                        actionPosition="right"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}

        {/* -----------------------------Log Notes------------------------------ */}
        <Grid item xs={12} sm={6}>
          <InputLabel className="whitespace-pre-line" htmlFor="log_note">
            Log Note*
          </InputLabel>
          <TextField
            id="log_note"
            fullWidth
            placeholder="Add a note"
            name="log_note"
            multiline
            value={formik.values.log_note}
            rows={3}
            onChange={trimFc(formik)}
            error={getIn(formik.touched, 'log_note') && getIn(formik.errors, 'log_note')}
            helperText={getIn(formik.touched, 'log_note') && getIn(formik.errors, 'log_note')}
          />
        </Grid>

        <Grid item xs={12} className="w-full flex sm:flex-row flex-col sm:items-center items-end justify-end my-3 gap-y-2">
          {FormSubmissionAlertMessage(formik)}

          <div className="flex items-center gap-2">
            <div className="flex items-center space-x-2">
              <CustomTooltip message={scheduleSmsInfoForFieldWorker} props={{ arrow: true, placement: 'left' }}>
                <QuestionCircleFilled />
              </CustomTooltip>
              <Button variant="outlined" size="large" color="primary" className="min-w-max" onClick={() => setOpenSheduleSMS(true)}>
                Add a Schedule SMS
              </Button>
            </div>
            <Button
              className="min-w-max"
              variant="contained"
              id="assign-worker"
              name="assign-worker"
              disabled={formik.isSubmitting || isAssignWorkerLoading || isWorkersFetching || isRecurringJobNameFetching}
              type="submit"
              size="large"
              startIcon={formik.isSubmitting && <LoadingOutlined />}
            >
              {isEditMode ? 'Save' : 'Assign Worker'}
            </Button>
          </div>
        </Grid>
      </Grid>
      {openMultiActionDialog && (
        <UniversalDialog
          action={{ open: openMultiActionDialog, maxWidth: 'sm', fullWidth: true }}
          title={'Multi Action Job'}
          hasPrimaryButton={false}
          onClose={() => setOpenMultiActionDialog(false)}
        >
          <div className="space-y-3">
            <InputLabel className="whitespace-pre-line">{multiActionJobTypeToolTip}</InputLabel>
            <RadioGroup
              aria-label="multi_action_job_type"
              onChange={formik.handleChange}
              name="multi_action_job_type"
              id="multi_action_job_type"
              value={formik.values.multi_action_job_type}
            >
              <FormControlLabel value={'simultaneously'} control={<Radio />} label={'Simultaneously'} />
              <FormControlLabel
                value={'sequential'}
                control={<Radio />}
                label={'The First Job Needs To Be Completed Before Assigning The Next Job'}
              />
            </RadioGroup>
            {formik.values.multi_action_job_type === 'sequential' && (
              <div>
                <InputLabel className="whitespace-pre-line">What are the instructions for this action?</InputLabel>
                <TextField
                  id="sequential_action_instruction"
                  fullWidth
                  name="sequential_action_instruction"
                  multiline
                  rows={2}
                  onChange={trimFc(formik)}
                  value={formik.values?.sequential_action_instruction}
                  error={!!formik.touched.sequential_action_instruction && !!formik.errors.sequential_action_instruction}
                />
                {formik.touched.sequential_action_instruction && formik.errors.sequential_action_instruction && (
                  <FormHelperText error id="sequential_action_instruction">
                    {formik.errors?.sequential_action_instruction}
                  </FormHelperText>
                )}
              </div>
            )}
            <div className="flex sm:flex-row flex-col justify-end space-2">
              {FormSubmissionAlertMessage(formik)}
              <LoadingButton
                loading={formik.isSubmitting || isAssignWorkerLoading}
                type="submit"
                id="multi-action-popup"
                name="multi-action-popup"
                variant="contained"
                size="large"
                className="min-w-max"
                startIcon={formik.isSubmitting && <LoadingOutlined />}
                onClick={() => {
                  if (
                    !!formik.values?.multi_action_job_type &&
                    formik.values?.multi_action_job_type?.length > 0 &&
                    (formik.values.multi_action_job_type !== 'sequential' ||
                      (!!formik.values?.sequential_action_instruction && formik.values?.sequential_action_instruction?.length > 0))
                  ) {
                    handleSubmit(formik.values);
                  }
                }}
              >
                {formik.isSubmitting || isAssignWorkerLoading ? 'Saving...' : 'Save'}
              </LoadingButton>
            </div>
          </div>
        </UniversalDialog>
      )}
      {openJobImagesPopup && (
        <UniversalDialog
          onClose={() => setOpenJobImagesPopup(false)}
          hasPrimaryButton={false}
          action={{ open: openJobImagesPopup, fullWidth: true, maxWidth: 'sm' }}
          title={'Job Media'}
        >
          <SelectJobImages files={files} jobImages={jobData.job_images} onSubmit={handleSubmitJobImages} />
        </UniversalDialog>
      )}
      {openSheduleSMS && !!customerData && (
        <AddScheduleSMSForm
          data={{
            customer: customerData,
            selectedUserId: formik.values.field_worker?.id,
            sheduleSMSValues: sheduleSMSValues ?? ({} as TScheduleSMSData),
            scheduleDateAndTime: formik.values.job_reminder?.scheduled_date_and_time
          }}
          openSheduleSMS={openSheduleSMS}
          setOpenSheduleSMS={setOpenSheduleSMS}
          onAddScheduleSMSFromSubmit={onAddScheduleSMSFromSubmit}
          onClose={() => {
            setOpenSheduleSMS(false);
          }}
        />
      )}
    </>
  );
};

export default AssignWorkerForm;
