import axiosServices from 'utils/axios';
import { AxiosRequestConfig } from 'axios';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';

class websiteService {
  sendWebsiteLink = async (userData: any) => {
    try {
      const response: any = await axiosServices.post(`/api/send-website-link`, userData, {
        locationNeeded: false
      } as AxiosRequestConfig);
      if (response && response.data.success) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Texted website link successfully`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
        return true;
      }
    } catch (error: unknown) {
      const knownError = error as { message: string };
      dispatch(
        openSnackbar({
          open: true,
          message: knownError.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          severity: 'error',
          close: true
        })
      );
    }
  };
}

const websiteServiceInstance = new websiteService();
export default websiteServiceInstance;
