import { CardMedia, Divider, Stack, Table, TableBody, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import { TJobCompleteData } from 'components/tables/interfaces/jobTableInterface';
import useAuth from 'hooks/useAuth';
import useTimezone from 'hooks/useTimezone';
import { useEffect, useState } from 'react';
import { useSelector } from 'store';
import { handleRedirectToGoogleMap, snakeCaseToTitleCase } from 'utils/common';
import { jobStates } from 'utils/constants';

const JobDetails = (props: { jobData?: TJobCompleteData }) => {
  //------------------------Constants--------------------------
  const theme = useTheme();
  const { user } = useAuth();

  const { jobData } = props;
  const formatDateTime = useTimezone();
  const settingsState = useSelector((state) => state.settings.generalSettings);
  const [jobDetail, setJobDetail] = useState<{ label: string; value: string | number | JSX.Element }[]>([]);

  //------------------------UseEffect--------------------------
  useEffect(() => {
    const currentJobIndex =
        jobData?.job_action?.findIndex((jobAction) => jobAction?.field_worker?.id === user?._id && jobAction.is_job_complete !== true) ??
        -1,
      currentWorker = jobData?.job_action?.[currentJobIndex];

    setJobDetail([
      { label: 'Job Name', value: jobData?.job_name ?? '' },
      {
        label: 'Job date&time',
        value: jobData?.previous_states?.includes(jobStates.PROPOSAL_APPROVED)
          ? currentWorker?.job_reminder?.scheduled_date_and_time
            ? formatDateTime(currentWorker?.job_reminder.scheduled_date_and_time, {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: settingsState?.time_format === '12hrs'
              })
            : 'N/A'
          : jobData?.estimate_reminder?.scheduled_date_and_time
          ? formatDateTime(jobData.estimate_reminder.scheduled_date_and_time, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: settingsState?.time_format === '12hrs'
            })
          : 'N/A'
      },
      {
        label: 'Job address',
        value: (
          <Typography
            onClick={() => {
              if (jobData?.address) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { address1, ...address } = jobData?.address;
                handleRedirectToGoogleMap(address);
              }
            }}
            sx={{ '&:hover': { color: theme.palette.primary.light }, cursor: 'pointer' }}
          >
            {jobData?.address?.address1}
          </Typography>
        )
      },
      {
        label: jobData?.previous_states?.includes(jobStates.PROPOSAL_APPROVED)
          ? 'Job Instructions/Details:'
          : 'Estimate Instructions/Details',
        value: jobData?.previous_states?.includes(jobStates.PROPOSAL_APPROVED)
          ? currentWorker?.job_instructions ?? ''
          : jobData?.estimator_instructions ?? ''
      },
      { label: 'Job price', value: jobData?.job_price?.toFixed(2) ?? '0.00' },
      {
        label: 'Worker',
        value: jobData?.previous_states?.includes(jobStates.PROPOSAL_APPROVED)
          ? currentWorker?.field_worker?.name ?? ''
          : jobData?.estimator?.name ?? ''
      },
      {
        label: 'Commission',
        value: jobData?.previous_states?.includes(jobStates.PROPOSAL_APPROVED)
          ? currentWorker?.worker_commission
            ? `$${currentWorker?.worker_commission.toFixed(2)}`
            : ''
          : jobData?.estimator_commission
          ? `$${jobData?.estimator_commission.toFixed(2)}`
          : ''
      },
      {
        label: 'Reminder',
        value: jobData?.previous_states?.includes(jobStates.PROPOSAL_APPROVED)
          ? currentWorker?.job_reminder?.reminder_date
            ? formatDateTime(currentWorker.job_reminder?.reminder_date, {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: settingsState?.time_format === '12hrs'
              })
            : 'N/A'
          : jobData?.estimate_reminder?.reminder_date
          ? formatDateTime(jobData.estimate_reminder?.reminder_date, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: settingsState?.time_format === '12hrs'
            })
          : 'N/A'
      },
      {
        label: 'Reminder method',
        value: jobData?.previous_states?.includes(jobStates.PROPOSAL_APPROVED)
          ? currentWorker?.job_reminder?.reminder_method?.length
            ? currentWorker.job_reminder.reminder_method.map((method) => (method === 1 ? 'Email' : method === 2 ? 'SMS' : '')).join(', ')
            : 'N/A'
          : jobData?.estimate_reminder?.reminder_method?.length
          ? jobData.estimate_reminder.reminder_method.map((method) => (method === 1 ? 'Email' : method === 2 ? 'SMS' : '')).join(', ')
          : 'N/A'
      },
      { label: 'Call me first', value: jobData?.customer_want_call_first_for_estimator ? `YES` : 'No' },
      { label: 'Job status', value: snakeCaseToTitleCase(jobData?.state ?? '') },
      { label: 'Approve signature', value: jobData?.proposal_approve_signature ?? '' }
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobData]);

  return (
    <>
      {jobData && (
        <Stack direction={{ xs: 'column', md: 'row' }} padding={2}>
          <Table>
            <TableBody>
              {jobDetail.slice(0, Math.ceil(jobDetail.length / 2)).map((singleData, index) => (
                <TableRow key={index} className="border-hidden">
                  <TableCell className="w-1/2">
                    <Typography variant="subtitle1" className="font-bold text-gray-400">
                      {singleData.label}
                    </Typography>
                  </TableCell>
                  <TableCell className="p-2 w-1/2">
                    <Typography variant="body1">{singleData.value}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Divider orientation="vertical" flexItem />
          <Table>
            <TableBody>
              {jobDetail.slice(Math.ceil(jobDetail.length / 2)).map((singleData, index) => (
                <TableRow key={index} className="border-hidden">
                  <TableCell className="w-1/2">
                    <Typography variant="subtitle1" className="font-bold text-gray-400">
                      {singleData.label}
                    </Typography>
                  </TableCell>
                  <TableCell className="p-2 w-1/2">
                    {singleData.label === 'Approve signature' ? (
                      <CardMedia src={singleData.value as string} component={'img'} />
                    ) : (
                      <Typography variant="body1">{singleData.value}</Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Stack>
      )}
    </>
  );
};

export default JobDetails;
