import { EnvironmentFilled } from '@ant-design/icons';
import { Button, CardHeader, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import Loader from 'components/Loader';
import CustomDataTable from 'components/tables/CustomDataTable';
import dayjs from 'dayjsConfig';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import PunchingServicesInstance from 'services/services.punching';
import { useSelector } from 'store';
import { calculateTimeDifference } from 'utils/common';
import { TPunchDetails } from './types/types.payroll';

const PayrollUserPunchList = () => {
  //-------------------constants--------------
  const { id: punchId } = useParams();
  const settings = useSelector((state) => state.settings.generalSettings);
  const timezone = useMemo(() => settings?.timezone ?? 'America/New_York', [settings]);

  const columns = useMemo<ColumnDef<TPunchDetails>[]>(
    () => [
      {
        accessorFn: (row) => (row?.clock_in?.time ? dayjs(row.clock_in.time).tz(timezone).format('MM-DD-YYYY hh:mm A') : '-'),
        id: 'clock_in',
        header: () => <span>In Time</span>
      },
      {
        accessorFn: (row) => (row?.clock_out?.time ? dayjs(row.clock_out.time).tz(timezone).format('MM-DD-YYYY hh:mm A') : '-'),
        id: 'clock_out',
        header: () => <span>Out Time</span>
      },
      {
        accessorFn: (row) =>
          row?.clock_in?.time && row?.clock_out?.time ? calculateTimeDifference(dayjs(row.clock_out.time), dayjs(row.clock_in.time)) : '-',
        id: 'total_hours',
        header: () => <span>Total Hours</span>
      },
      {
        accessorFn: (row) => (row?.clock_in?.time ? dayjs(row.clock_in.time).tz(timezone).format('MM-DD-YYYY') : '-'),
        id: 'date',
        header: () => <span>Date</span>
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //-------------------useQuery---------------
  const {
    data: punchData,
    isFetched: isPunchDataFetched,
    isLoading: isPunchLoading
  } = useQuery({
    queryKey: ['get_punch_list'],
    queryFn: () => {
      if (punchId) return PunchingServicesInstance.getPunchList(punchId);
    },
    enabled: !!punchId
  });

  return (
    <div>
      {isPunchDataFetched && !!punchData ? (
        <>
          <CardHeader
            title={
              <Typography variant="body1">
                Payroll Punch List - <strong className="text-base">{punchData?.user.name}</strong>
              </Typography>
            }
            action={
              <Button variant="contained" startIcon={<EnvironmentFilled />} component={Link} to={`/punch/view/${punchId}`} color="primary">
                View punch timeline
              </Button>
            }
          />
          <CustomDataTable data={punchData.punching_details} columns={columns} isDataLoading={isPunchLoading} />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default PayrollUserPunchList;
