import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import tabSummariesInstance from 'services/services.tabSummaries';

type Tabs = {
  notes?: boolean;
  emails_sms?: boolean;
  follow_ups?: boolean;
  logs?: boolean;
  'customer-jobs'?: boolean;
  'customer-jobs-invoices'?: boolean;
  log?: boolean;
  expenses?: boolean;
  media?: boolean;
  job_balance?: boolean;
};

export const getTabSummary = createAsyncThunk('tab-summary', async ({ module, id }: { module: string; id: string }) => {
  const response: Tabs = await tabSummariesInstance.getTabSummary(module, id);

  return response;
});

const tabSummarySlice = createSlice({
  name: 'tab-summary',
  initialState: {} as Tabs,
  reducers: {
    updateTabSummary(state, action) {
      state = action.payload;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTabSummary.fulfilled, (state, action) => {
      state = action.payload;
      return state;
    });
  }
});
export default tabSummarySlice.reducer;
export const { updateTabSummary } = tabSummarySlice.actions;
