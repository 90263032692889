import { Card, CardContent, Chip, MenuItem, Select, SelectChangeEvent, Skeleton, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { TDashboardGraphData, TDateFilter, TJobWon } from '../types/types.dashboard';

const JobSuccessMetrics = ({
  isJobWonDataFteching,
  jobWonData,
  colorData,
  jobWonDateFilter,
  setJobWonDateFilter,
  setJobWonResponse
}: {
  isJobWonDataFteching: boolean;
  jobWonData?: TJobWon;
  colorData: { estimate_created: string; jobs_completed: string };
  jobWonDateFilter: TDateFilter;
  setJobWonDateFilter: (value: TDateFilter) => void;
  setJobWonResponse: (value: TDashboardGraphData) => void;
}) => {
  const dateFilter = [
    { label: 'This Year 2024', value: 'this_year' },
    { label: 'Last 10 days', value: 'last_ten_days' },
    { label: 'Last 30 days', value: 'last_thirty_days' },
    { label: 'Year 2023', value: 'last_year' },
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'Date Range', value: 'date_range' }
  ];
  const totalJobs = [
    { label: 'Estimate Created', value: jobWonData?.statistics?.estimation_created ?? 0, color: colorData.estimate_created },
    { label: 'Proposal Approved', value: jobWonData?.statistics?.proposal_accepted ?? 0, color: '#28a745' },
    { label: 'Jobs Completed', value: jobWonData?.statistics?.job_completed ?? 0, color: colorData.jobs_completed }
  ];

  //----------------------------handlers---------------------
  const handleChangeDateFilter = (event: SelectChangeEvent<string>) => {
    const dummyData = {
      ...jobWonDateFilter,
      filter_value: event.target.value
    };
    setJobWonDateFilter(JSON.parse(JSON.stringify(dummyData)));
  };
  //----------------------------useEffects---------------------
  useEffect(() => {
    if (!isJobWonDataFteching && !!jobWonData) {
      if (jobWonDateFilter.filter_value === 'today' || jobWonDateFilter.filter_value === 'yesterday') {
        const dummyData: { labels: string[]; single_day_date: number[] } = { labels: [], single_day_date: [] };
        jobWonData.graph_statistics.forEach((singleData: TJobWon['graph_statistics'][0]) => {
          dummyData.labels.push(singleData.date);
          dummyData.single_day_date.push(singleData.counts[0]);
        });
        setJobWonResponse(dummyData);
        return;
      }
      const dummyData: { labels: string[]; estimation_created: number[]; job_completed: number[]; proposal_accepted: number[] } = {
        labels: [],
        estimation_created: [],
        job_completed: [],
        proposal_accepted: []
      };
      const graphStatistics = JSON.parse(
        JSON.stringify(
          ['this_year', 'last_year'].includes(jobWonDateFilter.filter_value)
            ? jobWonData.graph_statistics
            : jobWonData.graph_statistics.reverse()
        )
      );
      graphStatistics.forEach((singleData: TJobWon['graph_statistics'][0]) => {
        dummyData.labels.push(String(singleData.date));
        dummyData.estimation_created.push(singleData.counts[0]);
        dummyData.proposal_accepted.push(singleData.counts[1]);
        dummyData.job_completed.push(singleData.counts[2]);
      });
      setJobWonResponse(dummyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isJobWonDataFteching]);

  return !jobWonData ? null : (
    <Card className="w-full shadow-md max-h-min h-full">
      {!isJobWonDataFteching ? (
        <CardContent className="flex flex-col items-center max-h-min w-full gap-3">
          <div className="flex flex-col items-center gap-2 w-full">
            <div className="flex items-center gap-3">
              <Typography textAlign={'center'} className="font-semibold text-2xl text-gray-500 hover:underline">
                Job Won
              </Typography>
              {jobWonData && (
                <Chip label={`${Math.round(jobWonData?.job_won)}%`} color="error" variant="outlined" size="small" className="text-xs" />
              )}
            </div>

            <Select
              className="w-full"
              size="small"
              name="filter_value"
              value={jobWonDateFilter.filter_value}
              onChange={handleChangeDateFilter}
            >
              {dateFilter?.map((singleData: { label: string; value: string }, index: number) => (
                <MenuItem key={index} value={singleData.value}>
                  {singleData.label}
                </MenuItem>
              ))}
            </Select>
          </div>

          {jobWonDateFilter.filter_value === 'date_range' && (
            <div className="flex flex-col justify-center w-full gap-4">
              <DatePicker
                className="w-full"
                label="Start Date"
                value={dayjs(jobWonDateFilter.start_date)}
                onChange={(newValue) =>
                  newValue &&
                  setJobWonDateFilter({
                    ...jobWonDateFilter,
                    start_date: newValue.format('YYYY-MM-DDTHH:mm')
                  })
                }
              />
              <DatePicker
                className="w-full"
                label="End Date"
                value={dayjs(jobWonDateFilter.end_date)}
                onChange={(newValue) =>
                  newValue &&
                  setJobWonDateFilter({
                    ...jobWonDateFilter,
                    end_date: newValue.format('YYYY-MM-DDTHH:mm')
                  })
                }
              />
            </div>
          )}
          <div className="flex flex-col items-center justify-between w-full divide-y">
            {totalJobs.map((singleItem, index) => (
              <CardContent
                key={index}
                className="flex flex-col justify-center h-full min-w-max w-full"
                sx={{ color: singleItem.color, textAlign: 'center' }}
              >
                <Typography variant={singleItem.value > Math.pow(10, 7) ? 'h3' : 'h2'} className="font-semibold">
                  {singleItem.value}
                </Typography>
                <Typography className="text-xs w-full">{singleItem.label}</Typography>
              </CardContent>
            ))}
          </div>
        </CardContent>
      ) : (
        <CardContent className="space-y-3 h-full overflow-y-hidden">
          <div className="flex justify-between">
            <Skeleton variant="text" width={100} height={30} />
            <Skeleton variant="text" width={100} height={30} />
          </div>
          <Skeleton variant="rectangular" className="w-full h-1/3" />
          <Skeleton variant="rectangular" className="w-full h-1/3" />
          <Skeleton variant="rectangular" className="w-full h-1/3" />
        </CardContent>
      )}
    </Card>
  );
};

export default JobSuccessMetrics;
