import {
  Autocomplete,
  Button,
  Card,
  FormControlLabel,
  FormHelperText,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  useTheme
} from '@mui/material';
import { DatePicker, DateTimePicker, LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useQuery } from '@tanstack/react-query';
import {
  IReminder,
  TAssignWorker,
  TJobCompleteData,
  TOfficeToWorkerNote,
  TScheduleSMSData
} from 'components/tables/interfaces/jobTableInterface';
import dayjs, { Dayjs } from 'dayjs';
import { getIn, useFormik } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { Checkbox } from '@mui/material';
import CustomTooltip from 'components/CustomTooltip';
import FormSubmissionAlertMessage from 'components/FormSubmissionAlertMessage';
import { universalRenerMedia } from 'components/UniversalRenderMedia';
import UniversalDialog from 'components/popups/UniversalDialog';
import TUserData from 'components/tables/interfaces/userTableInterface';
import useAuth from 'hooks/useAuth';
import CustomerServicesInstance from 'services/services.customers';
import JobServicesInstance from 'services/services.jobs';
import MediaServicesInstance from 'services/services.media';
import UserServicesInstance from 'services/services.users';
import { useSelector } from 'store';
import { TMedia } from 'types/globalTypes/type.media';
import { checkApprovedOption, formatCostInDecimal, handleJobReminder } from 'utils/common';
import { notificationSendingChannels, reminderTypes } from 'utils/constants';
import trimFc, { ltrim, rtrim } from 'utils/trimFc';
import { uuid } from 'uuidv4';
import * as Yup from 'yup';
import AddScheduleSMSForm from './AddScheduleSMSForm';
import SelectJobImages from './SelectJobImages';

//-----------Types----------

const ReAssignWorkerForm = (props: { jobData: TJobCompleteData; onSuccess?: () => void; jobActionIndex: number; isEditMode: boolean }) => {
  //-------------------------------------Constants------------------------
  const { jobData, jobActionIndex, isEditMode } = props;
  const { user } = useAuth();
  const theme = useTheme();

  const [workerCommission, setWorkerCommission] = useState(0);
  const [openSheduleSMS, setOpenSheduleSMS] = useState(false);
  const [sheduleSMSValues, setSheduleSMSValues] = useState<TScheduleSMSData>();
  const [reminder, setReminder] = useState<string>('1daybefore');
  const [officeToWorkerNotes, setOfficeToWorkerNotes] = useState<string>('');

  const [openJobImagesPopup, setOpenJobImagesPopup] = useState<boolean>(false);
  const [files, setFiles] = useState<string[]>([]);

  //-------------------------------------------------useQuery----------------------------
  const { data: mediaData } = useQuery({
    queryKey: ['media_data'],
    queryFn: () => MediaServicesInstance.getMedia('customers', false, jobData?.customer?.id ?? '', 'media'),
    enabled: !!jobData?.customer?.id
  });

  const { data: allWorker } = useQuery({
    queryKey: ['all_worker_data'],
    queryFn: () =>
      UserServicesInstance.getAllUsers(undefined, {
        search: [
          [
            {
              field_name: 'status',
              field_value: 1,
              operator: 'exactmatch'
            }
          ],
          [{ field_name: 'role', field_value: ['field_worker', 'company_admin'], operator: 'in' }]
        ]
      })
  });

  const { generalSettings: generalSettingsData } = useSelector((state) => state.settings);
  const { data: customerData } = useQuery({
    queryKey: ['customer_data'],
    queryFn: () => CustomerServicesInstance.getSingleCustomer(jobData.customer?.id ?? ''),
    enabled: !!jobData.customer?.id
  });
  //---------------------------------------Handlers--------------------------
  const getDefaultFieldWorker = () => {
    const matchedWorker = allWorker?.users.some((eachFieldWorker) => eachFieldWorker._id === generalSettingsData?.default_field_worker.id);
    return matchedWorker ? generalSettingsData?.default_field_worker : { id: '', name: '' };
  };

  const onAddScheduleSMSFromSubmit = (values: TScheduleSMSData) => {
    setSheduleSMSValues(values);
    setOpenSheduleSMS(false);
  };

  const handleOfficeToWorkerNotes = () => {
    const currentDateTime = new Date();
    const newOfficeToWorkerNotes = [...(formik.values.office_to_worker_notes ?? ([] as TOfficeToWorkerNote[]))];
    newOfficeToWorkerNotes.push({
      text: ltrim(rtrim(officeToWorkerNotes)),
      id: uuid(),
      created_at: currentDateTime.toISOString(),
      created_by: { id: user?._id as string, name: `${user?.first_name} ${user?.last_name}` },
      updated_at: currentDateTime.toISOString(),
      updated_by: { id: user?._id as string, name: `${user?.first_name} ${user?.last_name}` }
    });
    formik.setFieldValue('office_to_worker_notes', newOfficeToWorkerNotes);
    setOfficeToWorkerNotes('');
  };
  const handleWorkerComission = (event: React.SyntheticEvent, newValue: { value?: string; label?: string }) => {
    formik.setFieldValue('field_worker', { id: newValue?.value ?? '', name: newValue?.label ?? '' });
    const worker = allWorker?.users.find(
      (fieldWorker) => newValue?.value === fieldWorker._id && fieldWorker?.rate_info?.pay_type === 'commission'
    );

    if (worker) {
      formik.setFieldValue('worker_commission', Math.round(workerCommission));
      return;
    }
    formik.setFieldValue('worker_commission', '');
  };
  //-----------------------------------------Formik-----------------------------
  const formik = useFormik<TAssignWorker>({
    initialValues: {
      id: '',
      field_worker: { id: '', name: '' },
      worker_commission: null as unknown as number,
      job_instructions: '',
      display_job_images: false,
      job_images: [],
      is_job_require_date: true,
      job_reminder: {
        exclude_time: false,
        scheduled_date_and_time: new Date().toISOString(),
        reminder_date: '',
        reminder_method: [1, 2] as unknown as number[]
      },
      customer_want_call_first_for_worker: false,
      field_worker_can_view_pricing: true,
      record_materials_used: 'no',
      customer_sign_on_job: 'no',
      completion_photos_required: 'no',
      office_to_worker_notes: [] as TOfficeToWorkerNote[],
      log_note: 'Re-Assigning Field Worker'
    },

    validationSchema: Yup.object().shape({
      job_instructions: Yup.string().required('This field is required'),
      field_worker: Yup.object().shape({
        id: Yup.string().required('This field is required')
      }),
      job_reminder: Yup.object().when('is_job_require_date', (is_job_require_date, schema) => {
        return is_job_require_date
          ? schema.shape({
              reminder_date: Yup.string().test({
                name: 'reminder_date',
                exclusive: true,
                message: 'Reminder date is required',
                test: function (value) {
                  return reminder === 'choosecustomedate' ? !!value : true;
                }
              }),
              scheduled_date_and_time: Yup.string().required('This field is required')
            })
          : schema.nullable();
      }),
      log_note: Yup.string().max(255).required('This field is required')
    }),

    onSubmit: async (values: TAssignWorker, { setSubmitting }) => {
      values.worker_commission = Number(values.worker_commission);
      setSubmitting(true);
      await handleAssignWorkerFormSubmit(values);
      setSubmitting(false);
    }
  });

  const [isAssignWorkerLoading, setIsAassignWorkerLoading] = useState(false);

  const handleAssignWorkerFormSubmit = async (values: TAssignWorker) => {
    values.job_reminder = {
      ...values.job_reminder,
      reminder_date: handleJobReminder(formik, reminder),
      is_reminder_sent: false
    } as IReminder;
    if (jobData?._id && jobData.job_name) {
      setIsAassignWorkerLoading(true);
      const editedJobResponse = await JobServicesInstance.reassignFieldWorker(jobData?._id, values);
      setIsAassignWorkerLoading(false);
      if (editedJobResponse) {
        if (sheduleSMSValues) {
          const scheduleObj: TScheduleSMSData = {
            ...sheduleSMSValues,
            job: {
              id: jobData?._id,
              name: jobData.job_name
            },
            customer: {
              id: jobData.customer?.id as string,
              name: jobData.customer?.name as string
            }
          };
          await JobServicesInstance.addScheduleSMS(scheduleObj);
        }
        props?.onSuccess?.();
      }
    }
  };

  const handleJobRequireDate = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === 'true') {
      setReminder('1daybefore');
      formik.setFieldValue('job_reminder', {
        scheduled_date_and_time: new Date().toISOString(),
        reminder_date: new Date().toISOString(),
        reminder_method: [1, 2]
      });
    }
    formik.setFieldValue('is_job_require_date', value === 'true');
  };

  const handleDisplayJobImages = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === 'true') {
      setOpenJobImagesPopup(true);
      return;
    }
    formik.setFieldValue('display_job_images', false);
    formik.setFieldValue('job_images', []);
  };

  const handleSubmitJobImages = (jobImages: string[]) => {
    if (!!jobImages.length) {
      formik.setFieldValue('job_images', jobImages);
      formik.setFieldValue('display_job_images', true);
      setOpenJobImagesPopup(false);
    }
  };

  const handleRemoveSelectedfile = (index: number) => {
    if (!!formik.values?.job_images?.length) {
      const dummyData = [...formik.values.job_images];
      dummyData.splice(index, 1);
      formik.setFieldValue('job_images', dummyData);
      if (dummyData.length === 0) formik.setFieldValue('display_job_images', false);
    }
  };

  //---------------------------------------useEffects--------------------------
  useEffect(() => {
    !!mediaData &&
      setFiles(() => {
        const urls = (mediaData as TMedia[]).map((singleMedia: TMedia) => singleMedia.url);
        return urls;
      });
  }, [mediaData]);

  useEffect(() => {
    console.log('errors in re-assign worker form ', formik.errors);
  }, [formik.errors]);

  useEffect(() => {
    if (
      Boolean(
        allWorker?.users.some(
          (fieldWorker) => formik.values?.field_worker?.id === fieldWorker._id && fieldWorker?.rate_info?.pay_type === 'commission'
        )
      )
    )
      formik.setFieldValue('worker_commission', workerCommission);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.field_worker]);

  useEffect(() => {
    if (!!allWorker?.users && generalSettingsData?.default_field_worker?.id?.length) {
      formik.setFieldValue('field_worker', getDefaultFieldWorker());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allWorker?.users, generalSettingsData?.default_field_worker]);
  useEffect(() => {
    if (jobData) {
      if (isEditMode) {
        const currentJobAction = jobData?.job_action?.[jobActionIndex];
        if (!!currentJobAction) {
          formik.setValues({
            id: currentJobAction?.id,
            field_worker: currentJobAction?.field_worker,
            worker_commission: currentJobAction?.worker_commission,
            job_instructions: currentJobAction?.job_instructions,
            is_job_require_date: currentJobAction?.is_job_require_date,
            job_reminder: currentJobAction?.job_reminder,
            customer_want_call_first_for_worker: currentJobAction?.customer_want_call_first_for_worker,
            field_worker_can_view_pricing: jobData?.field_worker_can_view_pricing ?? false,
            automatically_send_invoice: jobData.automatically_send_invoice ?? true,
            record_materials_used: currentJobAction?.record_materials_used,
            customer_sign_on_job: currentJobAction?.customer_sign_on_job,
            completion_photos_required: currentJobAction?.completion_photos_required,
            office_to_worker_notes: jobData.office_to_worker_notes ?? [],
            display_job_images: jobData.display_job_images ?? false,
            job_images: jobData.job_images ?? [],
            log_note: ''
          });
        }
      } else {
        formik.setValues({
          id: uuid(),
          field_worker: { id: '', name: '', profile_picture: '' },
          worker_commission: null as unknown as number,
          job_instructions: '',
          is_job_require_date: generalSettingsData?.assign_worker_page?.is_job_require_date.value === 'yes',
          job_reminder: {
            exclude_time: false,
            scheduled_date_and_time: new Date().toISOString(),
            reminder_date: '',
            reminder_method: [1, 2] as unknown as number[]
          },
          display_job_images: generalSettingsData?.assign_worker_page?.display_job_images.value === 'yes',
          job_images: [],
          customer_want_call_first_for_worker: generalSettingsData?.assign_worker_page?.customer_want_call_first.value === 'yes',
          field_worker_can_view_pricing: generalSettingsData?.assign_worker_page?.field_worker_can_view_pricing.value === 'yes',
          automatically_send_invoice: generalSettingsData?.assign_worker_page?.automatically_send_invoice.value === 'yes',
          record_materials_used: generalSettingsData?.assign_worker_page?.record_materials_used.value ?? 'no',
          customer_sign_on_job: generalSettingsData?.assign_worker_page?.customer_sign_on_job.value ?? 'no',
          completion_photos_required: generalSettingsData?.assign_worker_page?.completion_photos_required.value ?? 'no',
          office_to_worker_notes: jobData.office_to_worker_notes ?? [],
          log_note: isEditMode ? '' : 'Re-Assigning Field Worker'
        });
      }

      setOfficeToWorkerNotes(jobData?.office_to_worker_notes?.[jobData?.office_to_worker_notes.length - 1]?.text ?? '');
      const sumOfAllCommission =
        jobData.estimations?.options[checkApprovedOption(jobData.estimations?.options)].items.reduce(
          (accumulator, currentValue) => accumulator + (currentValue.worker_commission ?? 0),
          0
        ) ?? 0;
      setWorkerCommission(sumOfAllCommission);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobData]);

  const getComissionInput = () => {
    const selectedWorker = allWorker?.users.find((worker: TUserData) => formik.values?.field_worker?.id === worker._id);
    if (selectedWorker) {
      if (selectedWorker.rate_info?.pay_type === 'hourly') {
        return <TextField fullWidth disabled aria-disabled value={'Punch Emp.'} InputProps={{ startAdornment: '$' }} />;
      }

      return (
        <TextField
          inputProps={{ min: 0 }}
          fullWidth
          type="number"
          inputMode="decimal"
          id="worker_commission"
          name="worker_commission"
          value={formik.values.worker_commission ?? ''}
          onBlur={(event) => {
            const numericValue = parseFloat(event.target.value);
            if (!isNaN(numericValue)) {
              formik.setFieldValue('worker_commission', numericValue.toFixed(2));
            }
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            formik.setFieldValue('worker_commission', formatCostInDecimal(event.target.value));
          }}
          placeholder={selectedWorker.rate_info?.pay_type === 'salary' ? 'Salaried Emp.' : ''}
          InputProps={{ startAdornment: '$' }}
          error={!!formik.touched.worker_commission && !!formik.errors.worker_commission}
          helperText={formik.touched.worker_commission && formik.errors.worker_commission}
        />
      );
    }
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        component={'form'}
        onSubmit={(event) => {
          const allErrors = Object.keys(formik.errors);

          if (allErrors.length > 0) {
            const firstError = allErrors[0];

            const firstErrorElement = document.getElementById(firstError);
            if (firstErrorElement) {
              firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
              firstErrorElement.focus();
            }
          }
          handleOfficeToWorkerNotes();
          formik.handleSubmit(event);
        }}
        p={2}
      >
        {/* -----------------------------Job Assigned to *------------------------------ */}
        <Grid item xs={12} sm={6} className="space-y-2">
          <InputLabel htmlFor="field_worker">Job Assigned To *</InputLabel>
          <Autocomplete
            options={(allWorker?.users ?? []).map((singleWorker) => ({
              value: singleWorker._id,
              label: `${singleWorker.first_name} ${singleWorker.last_name}`
            }))}
            disableClearable
            getOptionLabel={(option) => option.label}
            value={(() => {
              const field_worker = (allWorker?.users ?? []).find((singleData) => singleData._id === formik.values?.field_worker?.id);
              return field_worker
                ? { value: field_worker._id, label: `${field_worker.first_name} ${field_worker.last_name}` }
                : { value: '', label: '' };
            })()}
            onChange={handleWorkerComission}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
              />
            )}
          />
          {getIn(formik.touched, 'field_worker.id') && getIn(formik.errors, 'field_worker.id') && (
            <FormHelperText error id="helper-text-first_name">
              {getIn(formik.errors, 'field_worker.id')}
            </FormHelperText>
          )}
        </Grid>

        {/* -----------------------------------------Worker Commission--------------------------------- */}
        <Grid item xs={12} sm={6}>
          {formik.values.field_worker?.name && (
            <Stack className="space-y-2">
              <InputLabel htmlFor="worker_commission">Worker Commission</InputLabel>
              {getComissionInput()}
            </Stack>
          )}
        </Grid>

        {/* -----------------------------------------ARE COMPLETION PHOTOS REQUIRED?---------------------------------- */}
        <Grid item xs={12} sm={6}>
          <InputLabel className="whitespace-pre-line" htmlFor="completion_photos_required">
            Are Completion Photos Required?
          </InputLabel>
          <RadioGroup
            row
            name="completion_photos_required"
            value={formik.values.completion_photos_required}
            onChange={(event: ChangeEvent<HTMLInputElement>, value: string) => formik.setFieldValue('completion_photos_required', value)}
            id="completion_photos_required"
          >
            <FormControlLabel value={'required'} control={<Radio />} label={'Required'} />
            <FormControlLabel value={'optional'} control={<Radio />} label={'Optional'} />
            <FormControlLabel value={'no'} control={<Radio />} label={'No'} />
          </RadioGroup>
        </Grid>

        {/* -----------------------------------------DOES CUSTOMER NEED TO SIGN-OFF ON THIS JOB?---------------------------------- */}
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="customer_sign_on_job">Does Customer Need To Sign-Off On This Job?</InputLabel>
          <RadioGroup
            row
            name="customer_sign_on_job"
            value={formik.values.customer_sign_on_job}
            onChange={(event: ChangeEvent<HTMLInputElement>, value: string) => formik.setFieldValue('customer_sign_on_job', value)}
            id="customer_sign_on_job"
          >
            <FormControlLabel value={'required'} control={<Radio />} label={'Required'} />
            <FormControlLabel value={'optional'} control={<Radio />} label={'Optional'} />
            <FormControlLabel value={'no'} control={<Radio />} label={'No'} />
          </RadioGroup>
        </Grid>

        {/* -----------------------------------------Record Material Used?---------------------------------- */}
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="record_materials_used">Record Material Used?</InputLabel>
          <RadioGroup
            row
            name="record_materials_used"
            value={formik.values.record_materials_used}
            onChange={(event: ChangeEvent<HTMLInputElement>, value: string) => formik.setFieldValue('record_materials_used', value)}
            id="record_materials_used"
          >
            <FormControlLabel value={'required'} control={<Radio />} label={'Required'} />
            <FormControlLabel value={'optional'} control={<Radio />} label={'Optional'} />
            <FormControlLabel value={'no'} control={<Radio />} label={'No'} />
          </RadioGroup>
        </Grid>

        {/* -----------------------------------------Does customer want a call first?---------------------------------- */}
        <Grid item xs={12} sm={6} md={4} lg={6}>
          <CustomTooltip message="Customer requests technician call before arrival">
            <InputLabel htmlFor="customer_want_call_first_for_worker" className="max-w-full break-all">
              Customer requests technician call before arrival
            </InputLabel>
          </CustomTooltip>
          <RadioGroup
            row
            aria-label="customer_want_call_first_for_worker"
            value={formik.values.customer_want_call_first_for_worker ? 'true' : 'false'}
            onChange={(event: ChangeEvent<HTMLInputElement>, value: string) =>
              formik.setFieldValue('customer_want_call_first_for_worker', value === 'true')
            }
            name="customer_want_call_first_for_worker"
            id="customer_want_call_first_for_worker"
          >
            <FormControlLabel value={true} control={<Radio />} label={'Yes'} />
            <FormControlLabel value={false} control={<Radio />} label={'No'} />
          </RadioGroup>
        </Grid>

        {/* -----------------------------------------Can field worker view pricing---------------------------------- */}
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <InputLabel htmlFor="field_worker_can_view_pricing">Can field worker view pricing?</InputLabel>
          <RadioGroup
            row
            aria-label="field_worker_can_view_pricing"
            value={formik.values.field_worker_can_view_pricing ? 'true' : 'false'}
            onChange={(event: ChangeEvent<HTMLInputElement>, value: string) => {
              formik.setFieldValue('field_worker_can_view_pricing', value === 'true');
              if (value === 'true') {
                formik.setFieldValue('automatically_send_invoice', true);
              }
            }}
            name="field_worker_can_view_pricing"
            id="field_worker_can_view_pricing"
          >
            <FormControlLabel value={true} control={<Radio />} label={'Yes'} />
            <FormControlLabel value={false} control={<Radio />} label={'No'} />
          </RadioGroup>
        </Grid>

        {/* -----------------------------------------DO YOU WANT TO AUTOMATICALLY SEND INVOICE?---------------------------------- */}
        {!formik.values.field_worker_can_view_pricing && (
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <InputLabel htmlFor="automatically_send_invoice">Do you want to automatically send invoice?</InputLabel>
            <RadioGroup
              row
              aria-label="automatically_send_invoice"
              value={formik.values.automatically_send_invoice ? 'true' : 'false'}
              onChange={(event: ChangeEvent<HTMLInputElement>, value: string) =>
                formik.setFieldValue('automatically_send_invoice', value === 'true')
              }
              name="automatically_send_invoice"
              id="automatically_send_invoice"
            >
              <FormControlLabel value={true} control={<Radio />} label={'Yes'} />
              <FormControlLabel value={false} control={<Radio />} label={'No'} />
            </RadioGroup>
          </Grid>
        )}

        {/* ---------------------------REQUIRE DATE-------------------- */}
        <Grid item xs={12} sm={6}>
          <InputLabel className="whitespace-pre-line" htmlFor="is_job_require_date">
            Does this job require a date?
          </InputLabel>
          <RadioGroup
            row
            aria-label="is_job_require_date"
            onChange={handleJobRequireDate}
            name="is_job_require_date"
            id="is_job_require_date"
            value={formik.values.is_job_require_date ? 'true' : 'false'}
          >
            <FormControlLabel value={true} control={<Radio />} label={'Yes'} />
            <FormControlLabel value={false} control={<Radio />} label={'No'} />
          </RadioGroup>
        </Grid>

        {formik.values.is_job_require_date && (
          <Grid xs={12} item>
            <Grid container spacing={2}>
              <Grid item className="space-y-2" xs={12} sm={6}>
                <InputLabel htmlFor="scheduled_time">
                  {formik.values.job_reminder?.exclude_time ? 'Schedule Date' : 'Schedule Date & Time'}
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {formik.values.job_reminder?.exclude_time ? (
                    <DatePicker
                      className="w-full"
                      value={dayjs(formik.values.job_reminder?.scheduled_date_and_time)}
                      minDate={dayjs(new Date())}
                      onChange={(newValue: Dayjs | null) => {
                        formik.setFieldValue(
                          'job_reminder.scheduled_date_and_time',
                          newValue !== null && newValue?.toString() !== 'Invalid Date' ? newValue.toISOString() : ''
                        );
                      }}
                    />
                  ) : (
                    <DateTimePicker
                      className="w-full"
                      value={dayjs(formik.values.job_reminder?.scheduled_date_and_time)}
                      minDate={dayjs(new Date())}
                      onChange={(newValue: Dayjs | null) => {
                        formik.setFieldValue(
                          'job_reminder.scheduled_date_and_time',
                          newValue !== null && newValue?.toString() !== 'Invalid Date' ? newValue.toISOString() : ''
                        );
                      }}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock
                      }}
                    />
                  )}
                  {getIn(formik.touched, 'job_reminder.scheduled_date_and_time') &&
                    getIn(formik.errors, 'job_reminder.scheduled_date_and_time') && (
                      <FormHelperText error id="helper-text-first_name">
                        {getIn(formik.errors, 'job_reminder.scheduled_date_and_time')}
                      </FormHelperText>
                    )}
                </LocalizationProvider>
                <FormControlLabel
                  value={formik.values.job_reminder?.exclude_time}
                  name="job_reminder.exclude_time"
                  id="job_reminder.exclude_time"
                  color="primary"
                  checked={formik.values.job_reminder?.exclude_time}
                  control={
                    <Checkbox
                      onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                        formik.setFieldValue('job_reminder.exclude_time', checked)
                      }
                    />
                  }
                  label={'Exclude Time'}
                />
              </Grid>
              {/* -----------------------------------------Reminder------------------------------ */}
              <Grid item className="space-y-2" xs={12} sm={6}>
                <InputLabel htmlFor="job_reminder">Reminder</InputLabel>
                <Autocomplete
                  id="job_reminder"
                  value={reminderTypes.find((singleData) => singleData.value === reminder)}
                  disableClearable
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue: { label: string; value: string }) => setReminder(newValue.value)}
                  options={reminderTypes}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder=""
                      sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                    />
                  )}
                />
              </Grid>
              {/* -----------------------------------------Reminder Date------------------------------ */}
              {reminder === 'choosecustomedate' && (
                <Grid item className="space-y-2" xs={12} sm={6}>
                  <InputLabel htmlFor="job_reminder.reminder_date">Reminder Date</InputLabel>
                  <DatePicker
                    className="w-full"
                    maxDate={dayjs(formik.values.job_reminder?.scheduled_date_and_time)}
                    value={dayjs(formik.values.job_reminder?.reminder_date)}
                    onChange={(newValue: Dayjs | null) => {
                      formik.setFieldValue('job_reminder.reminder_date', newValue?.toISOString());
                    }}
                  />
                  {getIn(formik.touched, 'job_reminder.reminder_date') && getIn(formik.errors, 'job_reminder.reminder_date') && (
                    <FormHelperText error id="helper-text-first_name">
                      {getIn(formik.errors, 'job_reminder.reminder_date')}
                    </FormHelperText>
                  )}
                </Grid>
              )}
              {/* -----------------------------------------Reminder Method------------------------------ */}
              <Grid item className="space-y-2" xs={12} sm={6}>
                <InputLabel htmlFor="job_reminder.reminder_method">Reminder Method</InputLabel>
                <Stack direction={'row'} spacing={2}>
                  {notificationSendingChannels.map((items: { value: number; label: string }, index) => {
                    return (
                      <FormControlLabel
                        value={items.value}
                        name="job_reminder.reminder_method"
                        id="job_reminder.reminder_method"
                        color="primary"
                        checked={formik.values.job_reminder?.reminder_method.includes(items.value)}
                        control={
                          <Checkbox
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              const newCheckObject: Array<number> = [...(formik.values.job_reminder?.reminder_method ?? [])];
                              if (event.target.checked) {
                                newCheckObject.push(Number(event.target.value));
                                formik.setFieldValue('job_reminder.reminder_method', [...new Set(newCheckObject)]);
                              } else {
                                formik.setFieldValue(
                                  'job_reminder.reminder_method',
                                  newCheckObject.filter((obj) => obj !== Number(event.target.value))
                                );
                              }
                            }}
                          />
                        }
                        label={items.label}
                      />
                    );
                  })}
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* -----------------------------------------Would you like to display any job images?---------------------------------- */}

        <Grid item xs={12} sm={6}>
          <Stack>
            <InputLabel className="whitespace-pre-line" htmlFor="display_job_images">
              Would you like to display any job images?
            </InputLabel>
            <RadioGroup
              row
              aria-label="display_job_images"
              onChange={handleDisplayJobImages}
              name="display_job_images"
              id="display_job_images"
              value={formik.values.display_job_images ? 'true' : 'false'}
            >
              <FormControlLabel value={true} control={<Radio />} label={'Yes'} />
              <FormControlLabel value={false} control={<Radio />} label={'No'} />
            </RadioGroup>
          </Stack>
        </Grid>

        {/*-----------------------------------------------------Display Job Images----------------------------------------------------------- */}
        {formik.values.display_job_images && !!formik.values?.job_images?.length && (
          <Grid item xs={12}>
            <ImageList className="space-x-1 space-y-1" cols={5}>
              {formik.values.job_images?.map((singleFileUrl: string, index: number) => (
                <ImageListItem
                  key={index}
                  component={Card}
                  className="flex justify-center"
                  sx={{ maxHeight: '250px', minHeight: '100px', minWidth: '100px' }}
                >
                  {universalRenerMedia(singleFileUrl, index)}
                  <ImageListItemBar
                    className="p-1 bg-transparent"
                    position="top"
                    actionIcon={<DeleteOutlined className="font-lg text-red-500" onClick={() => handleRemoveSelectedfile(index)} />}
                    actionPosition="right"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>
        )}

        {/* -----------------------------------------Schedule SMS---------------------------------- */}
        <Grid item xs={12}>
          <Button variant="outlined" color="primary" onClick={() => setOpenSheduleSMS(true)}>
            Add a Schedule SMS
          </Button>
        </Grid>

        {/* -----------------------------Instructions/Details for Worker *------------------------------ */}
        <Grid item xs={12} sm={6}>
          <InputLabel>Instructions/Details for Worker *</InputLabel>
          <TextField
            id="job_instructions"
            fullWidth
            name="job_instructions"
            multiline
            rows={3}
            onChange={trimFc(formik)}
            value={formik.values?.job_instructions}
            error={!!formik.touched.job_instructions && !!formik.errors.job_instructions}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && !event.shiftKey) {
                formik.submitForm();
                event.preventDefault();
              }
            }}
          />
          {formik.touched.job_instructions && formik.errors.job_instructions && (
            <FormHelperText error id="helper-text-job_instructions -signup">
              {formik.errors?.job_instructions}
            </FormHelperText>
          )}
        </Grid>

        {/* -----------------------------Log Notes------------------------------ */}
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="log_note">Log Note*</InputLabel>
          <TextField
            className="w-full"
            id="log_note"
            fullWidth
            placeholder="Add a note"
            name="log_note"
            multiline
            value={formik.values.log_note}
            rows={3}
            onChange={formik.handleChange}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && !event.shiftKey) {
                formik.submitForm();
                event.preventDefault();
              }
            }}
          />
          {getIn(formik.touched, 'log_note') && getIn(formik.errors, 'log_note') && (
            <FormHelperText error id="helper-text-first_name">
              {getIn(formik.errors, 'log_note')}
            </FormHelperText>
          )}
        </Grid>

        {/* -----------------------------Office To Worker Notes------------------------------ */}
        <Grid item sm={6} xs={12} className="space-y-2">
          <div>
            <InputLabel htmlFor="office_to_worker_notes">Office To Worker Notes</InputLabel>
            <TextField
              className="w-full"
              id={`office_to_worker_notes`}
              fullWidth
              name={`office_to_worker_notes`}
              multiline
              rows={3}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                setOfficeToWorkerNotes(event.target.value);
              }}
              value={officeToWorkerNotes}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && !event.shiftKey) {
                  formik.submitForm();
                  event.preventDefault();
                }
              }}
            />
          </div>
        </Grid>
        {/*-----------------------------------------------------Row7----------------------------------------------------------- */}

        <div className="w-full flex flex-row items-center justify-end my-3 space-x-3">
          {FormSubmissionAlertMessage(formik)}
          <Button
            className="min-w-max"
            variant="contained"
            disabled={formik.isSubmitting || isAssignWorkerLoading}
            startIcon={formik.isSubmitting && <LoadingOutlined />}
            type="submit"
            size="large"
          >
            Assign Worker
          </Button>
        </div>
      </Grid>
      {openSheduleSMS && !!customerData && (
        <AddScheduleSMSForm
          data={{
            customer: customerData,
            selectedUserId: formik.values.field_worker?.id,
            sheduleSMSValues: sheduleSMSValues ?? ({} as TScheduleSMSData),
            scheduleDateAndTime: formik.values.job_reminder?.scheduled_date_and_time
          }}
          openSheduleSMS={openSheduleSMS}
          setOpenSheduleSMS={setOpenSheduleSMS}
          onAddScheduleSMSFromSubmit={onAddScheduleSMSFromSubmit}
          onClose={() => {
            setOpenSheduleSMS(false);
          }}
        />
      )}
      {openJobImagesPopup && (
        <UniversalDialog
          onClose={() => setOpenJobImagesPopup(false)}
          hasPrimaryButton={false}
          action={{ open: openJobImagesPopup, fullWidth: true, maxWidth: 'sm' }}
          title={'Job Media'}
        >
          <SelectJobImages files={files} jobImages={jobData.job_images} onSubmit={handleSubmitJobImages} />
        </UniversalDialog>
      )}
    </>
  );
};

export default ReAssignWorkerForm;
