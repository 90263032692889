import { Card, CardContent, Table, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { TDateFilter } from 'pages/Dashboard/types/types.dashboard';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { randomHexColorCode } from 'utils/common';
import ReportDateFilter from '../components/ReportDateFilter';
import { TOfficeAdministratorReport } from '../types/types.report';
import { TInactivityReasonData } from 'services/services.punching';
import InactivityReasonDisplay from '../components/InactivityReasonDisplay';

const OfficeAdministratorReport = ({
  selectedFilter,
  setSelectedFilter,
  reportData
}: {
  selectedFilter: TDateFilter;
  setSelectedFilter: (value: TDateFilter) => void;
  reportData: TOfficeAdministratorReport;
}) => {
  //--------------------------constants--------------------
  const [officeAdministratorData, setOfficeAdministratorData] = useState<
    { name: string; hours: number; auto_logouts: number; color: string; delay_reasons: any[] }[]
  >([]);

  const [inactivityReasonPopup, setInactivityReasonPopup] = useState<{
    state: boolean;
    data: TInactivityReasonData[] | null;
    name: string;
  }>({
    state: false,
    data: null,
    name: ''
  });

  useEffect(() => {
    const dummyData = reportData.map((singleItem) => {
      return {
        name: singleItem.name,
        hours: singleItem.hours,
        auto_logouts: singleItem.auto_logouts,
        color: randomHexColorCode(),
        delay_reasons: singleItem.delay_reasons
      };
    });
    setOfficeAdministratorData(dummyData);
  }, [reportData]);

  const dateFilter = [
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'Last 10 Days', value: 'last_ten_days' },
    { label: 'Last 30 Days', value: 'last_thirty_days' },
    { label: 'Last Year', value: 'last_year' },
    { label: 'Date Range', value: 'date_range' }
  ];

  return (
    <div className="grid sm:grid-cols-1 lg:grid-cols-12 gap-4">
      <Card className="lg:col-span-5 justify-center !p-0">
        <CardContent className="space-y-4 !p-0">
          <div className="flex justify-end p-4">
            <ReportDateFilter dateFilters={dateFilter} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />
          </div>
          <Table>
            <TableHead>
              <TableRow>
                {['Name', 'Hours', 'Auto logouts', 'Reasons for Inactivity'].map((item) => (
                  <TableCell colSpan={item === 'Name' ? 3 : 1} align={item === 'Name' ? 'left' : 'center'}>
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {officeAdministratorData.map((singleItem) => (
              <TableRow>
                <TableCell colSpan={3} width={400}>
                  {singleItem.name}
                </TableCell>
                <TableCell colSpan={1} align="center">
                  {singleItem.hours}
                </TableCell>
                <TableCell colSpan={1} align="center">
                  {singleItem.auto_logouts ?? 'N/A'}
                </TableCell>
                <TableCell colSpan={1} align="center">
                  <Tooltip title="View Details" arrow placement="right">
                    <Typography
                      onClick={() => {
                        if (singleItem.delay_reasons.length === 0) return;
                        setInactivityReasonPopup(() => ({ state: true, data: singleItem.delay_reasons, name: singleItem.name }));
                      }}
                      sx={singleItem.delay_reasons.length > 0 ? { color: 'primary.main' } : { color: 'initial' }}
                      className="hover:underline cursor-pointer underline-offset-2 hover:scale-[1.1] transition-all ease-in"
                    >
                      {singleItem.delay_reasons.length ?? 'N/A'}
                    </Typography>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </CardContent>
      </Card>
      <Card className="lg:col-span-7 justify-center">
        <ReactApexChart
          type="bar"
          options={{
            chart: { stacked: true, toolbar: { show: false } },
            dataLabels: { enabled: false },
            title: { text: 'Administrator Report', align: 'center' }
          }}
          series={[
            {
              name: '',
              data: officeAdministratorData.map((singleItem) => ({
                x: singleItem.name,
                y: singleItem.hours,
                fillColor: singleItem.color
              }))
            }
          ]}
        />
      </Card>

      {inactivityReasonPopup.state && (
        <InactivityReasonDisplay
          open={inactivityReasonPopup.state}
          data={inactivityReasonPopup.data}
          name={inactivityReasonPopup.name}
          onClose={() => setInactivityReasonPopup({ state: false, data: null, name: '' })}
        />
      )}
    </div>
  );
};

export default OfficeAdministratorReport;
