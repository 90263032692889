// material-ui
import { Grid, InputLabel, OutlinedInput, Stack } from '@mui/material';
// project imports

// assets
import { FormHelperText } from '@mui/material';
import trimFc from 'utils/trimFc';
import GoogleMaps from './GoogleMap';

// ==============================|| FORMS VALIDATION - ADDRESS ||============================== //

function GoogleMapAutocomplete({ formik, required = true }: { formik: any; required?: boolean }) {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <GoogleMaps formik={formik} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Stack spacing={1}>
            <InputLabel required={required}>Address 1</InputLabel>
            <OutlinedInput
              multiline
              minRows={3}
              maxRows={3}
              id="address1"
              name="address.address1"
              placeholder="1st Line"
              value={formik.values?.address?.address1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.touched?.address?.address1 && formik.errors?.address?.address1)}
              fullWidth
            />
            {Boolean(formik.touched?.address?.address1 && formik.errors?.address?.address1) && (
              <FormHelperText error id="helper-text-zip">
                {formik.errors?.address?.address1}
              </FormHelperText>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack spacing={1}>
            <InputLabel>Address 2 (Optional)</InputLabel>
            <OutlinedInput
              multiline
              minRows={3}
              maxRows={3}
              id="address2"
              name="address.address2"
              placeholder="2nd Line"
              value={formik.values?.address?.address2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
              error={Boolean(formik.touched?.address?.address2 && formik.errors?.address?.address2)}
              // helperText={Boolean(formik.touched?.address?.address2 && formik.errors?.address?.address2) && formik.errors?.address?.address2}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Stack spacing={1}>
            <InputLabel required={required}>City</InputLabel>
            <OutlinedInput
              id="city"
              name="address.city"
              placeholder="Enter City"
              value={formik.values?.address?.city}
              onChange={trimFc(formik)}
              onBlur={formik.handleBlur}
              error={Boolean(formik.touched?.address?.city && formik.errors?.address?.city)}
              fullWidth
            />
            {Boolean(formik.touched?.address?.city && formik.errors?.address?.city) && (
              <FormHelperText error id="helper-text-zip">
                {formik.errors?.address?.city}
              </FormHelperText>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Stack spacing={1}>
            <InputLabel required={required}>State</InputLabel>
            <OutlinedInput
              id="state"
              name="address.state"
              placeholder="Enter State"
              value={formik.values?.address?.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.touched?.address?.state && formik.errors?.address?.state)}
              fullWidth
            />
            {Boolean(formik.touched?.address?.state && formik.errors?.address?.state) && (
              <FormHelperText error id="helper-text-zip">
                {formik.errors?.address?.state}
              </FormHelperText>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Stack spacing={1}>
            <InputLabel required={required}>Zip</InputLabel>
            <OutlinedInput
              id="zip"
              name="address.zip"
              placeholder="Zip"
              value={formik.values?.address?.zip}
              onChange={trimFc(formik)}
              onBlur={formik.handleBlur}
              error={Boolean(formik.touched?.address?.zip && formik.errors?.address?.zip)}
              fullWidth
            />
            {Boolean(formik.touched?.address?.zip && formik.errors?.address?.zip) && (
              <FormHelperText error id="helper-text-zip">
                {formik.errors?.address?.zip}
              </FormHelperText>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4} mt={1}>
        <Stack spacing={1}>
          <InputLabel required={required}>Country</InputLabel>
          <OutlinedInput
            id="country"
            name="address.country"
            placeholder="country"
            value={formik.values?.address?.country}
            onChange={trimFc(formik)}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched?.address?.country && formik.errors?.address?.country)}
            fullWidth
          />
          {Boolean(formik.touched?.address?.country && formik.errors?.address?.country) && (
            <FormHelperText error id="helper-text-country">
              {formik.errors?.address?.country}
            </FormHelperText>
          )}
        </Stack>
      </Grid>
    </>
  );
}

export default GoogleMapAutocomplete;
