import { PlusOutlined } from '@ant-design/icons';
import { InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { Stack } from '@mui/system';
// import { DatePicker } from '@mui/x-date-pickers';

// third party

// project import
// import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { availableStatus } from 'utils/constants';

type TSearchFilterProps = {
  title?: string;
  placeholder?: string;
  onChange: (arg0: { search_text: string; status?: number }) => void;
  isStatusRequired?: boolean;
};

const SearchFilter = (props: TSearchFilterProps) => {
  const {
    title = 'Search',
    placeholder = 'Enter a search keyword',
    onChange = (arg0: { search_text: string; status?: number }) => {},
    isStatusRequired = false
  } = props;

  const [searchFilterData, setSearchFilterData] = useState<{ search_text: string; status?: number }>({ search_text: '', status: 1 });

  useEffect(() => {
    let searchTimeOut: NodeJS.Timeout;

    searchTimeOut = setTimeout(() => {
      onChange(searchFilterData);
    }, 1000);

    return () => {
      clearTimeout(searchTimeOut);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilterData.search_text, searchFilterData.status]);

  const handleSearchTexChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    setSearchFilterData((prev) => ({ ...prev, search_text: value }));
  };

  const handleStatusChange = (event: SelectChangeEvent<number>) => {
    setSearchFilterData((prev) => ({ ...prev, status: Number(event.target.value) }));
  };

  return (
    <div className="w-full min-w-max">
      <Stack>
        <InputLabel htmlFor="search_text_field">{title || 'Search'}</InputLabel>
        <OutlinedInput
          placeholder={placeholder}
          fullWidth
          required
          type="text"
          name="search_text"
          id="search_text_field"
          value={searchFilterData.search_text}
          onChange={handleSearchTexChange}
          endAdornment={
            !!searchFilterData.search_text?.length && (
              <PlusOutlined className="rotate-45" onClick={() => setSearchFilterData((prev) => ({ ...prev, search_text: '' }))} />
            )
          }
        />
      </Stack>

      {isStatusRequired && (
        <Stack>
          <InputLabel>Status</InputLabel>
          <Select
            fullWidth
            value={searchFilterData.status}
            displayEmpty
            name="status"
            renderValue={(selected) => availableStatus.find((status) => status.value === selected)?.label}
            onChange={handleStatusChange}
          >
            {availableStatus.map((status) => (
              <MenuItem value={status.value}>{status.label}</MenuItem>
            ))}
          </Select>
        </Stack>
      )}
    </div>
  );
};

export type TSearchConditionsObject = {
  field_name: string;
  field_value: string | Array<string> | number | boolean;
  operator: string;
};

export type ISearch = {
  search: Array<Array<TSearchConditionsObject>>;
};

export default SearchFilter;
