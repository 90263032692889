import { CheckCircleOutlined, EnvironmentOutlined, FileOutlined, SettingOutlined, UserOutlined, WarningOutlined } from '@ant-design/icons';
import { EventInput } from '@fullcalendar/core';
import { Button, Tab, Tabs, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import ProductPlaceholder from 'components/cards/skeleton/ProductPlaceholder';
import UniversalDialog from 'components/popups/UniversalDialog';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import AutoAppointmentServiceInstance from 'services/services.autoAppointment';
import calendarServicesInstance from 'services/services.calendar';
import { dispatch, useSelector } from 'store';
import { setAreaName, setEvents, setPreviousYear } from 'store/reducers/customReducers/slice.calendar';
import { formateData } from 'utils/common';
import Calendar from './components/Calendar';
import CalendarSetingsPage from './components/CalendarSetingsPage';
import { TCalendar } from './types/types.calendar';

const CalendarPage = () => {
  //---------------------constants--------------
  const { calendar_tab: selectedTab } = useParams();
  const { date, areaName, previous_year } = useSelector((state) => state.calendarOption);
  const [titleData, setTitleData] = useState<{ [key: string]: { total_job_count: number; total_job_price: number } }>({});
  const navigate = useNavigate();
  const theme = useTheme();

  //---------------------useQuery--------------------
  const { data: autoAppointmentConfigData, isFetched: isAutoAppointmentConfigDataFetched } = useQuery({
    queryKey: ['appointment_data_in_calendar'],
    queryFn: () => AutoAppointmentServiceInstance.getAppointmentConfig()
    // refetchOnWindowFocus: false
  });
  const {
    data: calendarAllData,
    isFetched: isCalendarDataFetched,
    refetch: refetchCalendarData
  } = useQuery({
    queryKey: ['calendar', selectedTab, date, areaName],
    queryFn: () => {
      //for selcted_tab=== blackout_days  api will be called on year change
      if (
        !!selectedTab &&
        (selectedTab !== 'blackout_days' ||
          (selectedTab === 'blackout_days' && (previous_year === null || previous_year !== moment(date).year())))
      ) {
        return calendarServicesInstance.getCalendarData(selectedTab, moment(date).month() + 1, moment(date).year(), areaName);
      }
    },
    enabled:
      !!selectedTab?.length &&
      [
        'job_calendar',
        'estimate_calendar',
        'archive_calendar',
        'town_calendar',
        'area_calendar',
        'blackout_days',
        'area_availabilities'
      ].includes(selectedTab),
    refetchOnWindowFocus: false
  });

  //---------------------constants--------------
  const [openSettings, setOpenSettings] = useState(false);
  const [calendarTabs, setCalendarTabs] = useState([
    { value: 'job_calendar', label: 'Job Calendar' },
    { value: 'estimate_calendar', label: 'Estimate Calendar' },
    { value: 'archive_calendar', label: 'Archive Calendar' },
    { value: 'town_calendar', label: 'Town Calendar' }
  ]);
  //------------------Handlers------------------
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTitleData({});
    dispatch(setAreaName(''));
    dispatch(setEvents(''));
    navigate(`/calendar/${newValue}`);
  };
  const handleCloseCalendarSettings = () => {
    setOpenSettings(false);
    refetchCalendarData();
  };
  //-------------------useEffect-----------------
  useEffect(() => {
    if (
      !!selectedTab &&
      ![
        'job_calendar',
        'estimate_calendar',
        'archive_calendar',
        'town_calendar',
        'area_calendar',
        'blackout_days',
        'area_availabilities'
      ].includes(selectedTab)
    )
      navigate('/calendar/job_calendar');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    setTitleData({});
    const formateEventData = (eventData: TCalendar, eventType: string, jobIndex?: number) => {
      switch (eventType) {
        case 'title':
          const currentDate = moment(eventData.date).format('YYYY-MM-DD');
          setTitleData((prevTitleData) => {
            prevTitleData[currentDate] = {
              total_job_price: (prevTitleData[currentDate]?.total_job_price ?? 0) + (eventData?.total_job_price ?? 0),
              total_job_count: (prevTitleData[currentDate]?.total_job_count ?? 0) + (eventData?.total_job_count ?? 0)
            };
            return prevTitleData;
          });
          break;
        case 'body':
          switch (selectedTab) {
            case 'job_calendar': {
              if (jobIndex !== undefined) {
                return {
                  date: eventData.date,
                  backgroundColor: theme.palette.info.light,
                  editable: false,
                  extendedProps: {
                    icon: <UserOutlined />,
                    _id: eventData?.jobs?.[jobIndex]?.field_worker?.id,
                    user: { name: eventData?.jobs?.[jobIndex]?.field_worker?.name, id: eventData?.jobs?.[jobIndex]?.field_worker?.id },
                    count: eventData?.jobs?.[jobIndex]?.count,
                    price: formateData(eventData?.jobs?.[jobIndex]?.job_price ?? 0, '$'),
                    contentType: 'body',
                    job_id: eventData?.jobs?.[jobIndex].job_id ?? ''
                  }
                };
              }
              break;
            }
            case 'estimate_calendar':
              return (
                jobIndex !== undefined && {
                  date: eventData.date,
                  backgroundColor: theme.palette.info.light,
                  editable: false,
                  extendedProps: {
                    icon: <UserOutlined />,
                    _id: eventData?.jobs?.[jobIndex]?.estimator?.id,
                    count: eventData?.jobs?.[jobIndex]?.count,
                    user: { name: eventData?.jobs?.[jobIndex]?.estimator?.name, id: eventData?.jobs?.[jobIndex]?.estimator?.id },

                    price: formateData(eventData?.jobs?.[jobIndex]?.job_price ?? 0, '$'),
                    contentType: 'body'
                  }
                }
              );
            case 'archive_calendar':
              return (
                jobIndex !== undefined && {
                  date: eventData.date,
                  backgroundColor: theme.palette.info.light,
                  editable: false,
                  extendedProps: {
                    icon: <UserOutlined />,
                    _id: eventData?.jobs?.[jobIndex]?.field_worker?.id,
                    user: { name: eventData?.jobs?.[jobIndex]?.field_worker?.name, id: eventData?.jobs?.[jobIndex]?.field_worker?.id },
                    count: eventData?.jobs?.[jobIndex]?.count,
                    price: formateData(eventData?.jobs?.[jobIndex]?.job_price ?? 0, '$'),
                    contentType: 'body'
                  }
                }
              );
            case 'town_calendar':
              return (
                jobIndex !== undefined && {
                  date: eventData.date,
                  backgroundColor: theme.palette.info.light,
                  editable: false,
                  extendedProps: {
                    icon: <EnvironmentOutlined />,
                    count: eventData?.jobs?.[jobIndex].count,
                    city: eventData?.jobs?.[jobIndex].city,
                    contentType: 'body'
                  }
                }
              );
            case 'area_calendar':
              return {
                date: moment(eventData.date).format('YYYY-MM-DD'),
                backgroundColor: theme.palette.info.light,
                editable: false,
                extendedProps: {
                  icon: <EnvironmentOutlined />,
                  count: eventData?.count,
                  area_name: eventData?.area_name,
                  contentType: 'body'
                }
              };
            case 'area_availabilities': {
              const isHourlySchedule = autoAppointmentConfigData?.schedule.type === 'hour';

              if (!isHourlySchedule) {
                const isBooked = autoAppointmentConfigData?.scheduling_limit.by_job_count
                  ? (eventData?.count as number) >= autoAppointmentConfigData.scheduling_limit.limit
                  : (eventData?.price as number) >= (autoAppointmentConfigData?.scheduling_limit?.limit as number);

                if (isBooked)
                  return [
                    {
                      date: moment(eventData.date).format('YYYY-MM-DD'),
                      backgroundColor: theme.palette.error.main,
                      editable: false,
                      extendedProps: {
                        icon: <WarningOutlined />,
                        data: 'I am booked.',
                        contentType: 'body'
                      }
                    }
                  ];

                return [
                  {
                    date: moment(eventData.date).format('YYYY-MM-DD'),
                    backgroundColor: theme.palette.success.main,
                    editable: false,
                    extendedProps: {
                      icon: <CheckCircleOutlined />,
                      data: 'Available',
                      contentType: 'body'
                    }
                  }
                ];
              }
              const dummyEventData: EventInput[] = [];
              eventData?.time_blocks?.forEach((eachTimeBlock) => {
                if (
                  autoAppointmentConfigData.scheduling_limit.by_job_count
                    ? eachTimeBlock.count < autoAppointmentConfigData.scheduling_limit.limit
                    : eachTimeBlock.price < autoAppointmentConfigData.scheduling_limit.limit
                ) {
                  dummyEventData.push({
                    date: moment(eventData.date).format('YYYY-MM-DD'),
                    backgroundColor: theme.palette.success.light,
                    editable: false,
                    extendedProps: {
                      icon: <CheckCircleOutlined />,
                      data: `${moment(eachTimeBlock?.start_time).format('hh:mm a')} - ${moment(eachTimeBlock?.end_time).format('hh:mm a')}`,
                      contentType: 'body'
                    }
                  });
                }
              });

              if (!dummyEventData?.length)
                return [
                  {
                    date: moment(eventData.date).format('YYYY-MM-DD'),
                    backgroundColor: theme.palette.error.main,
                    editable: false,
                    extendedProps: {
                      icon: <WarningOutlined />,
                      data: 'I am booked.',
                      contentType: 'body'
                    }
                  }
                ];

              return dummyEventData;
            }
          }
          break;

        case 'note':
          return {
            title: eventData.note,
            date: eventData.date,
            backgroundColor: '#FFFFE0',
            textColor: 'orange',
            extendedProps: {
              icon: <FileOutlined />,

              contentType: 'note'
            },
            editable: false
          };
      }
    };

    if (isCalendarDataFetched) {
      if (calendarAllData?.length === 0) {
        dispatch(setEvents([]));
        return;
      }

      let tempData: EventInput[] = [];
      switch (selectedTab) {
        case 'job_calendar':
        case 'estimate_calendar':
        case 'archive_calendar':
        case 'town_calendar':
          calendarAllData?.forEach((dateData: TCalendar) => {
            if (!!selectedTab?.length) {
              if (['job_calendar', 'estimate_calendar', 'archive_calendar'].includes(selectedTab))
                formateEventData(dateData, 'title') as EventInput;
              if (!!dateData?.jobs?.length) {
                dateData?.jobs?.forEach((eachJob, jobIndex) => tempData.push(formateEventData(dateData, 'body', jobIndex) as EventInput));
              }
            }
            if (!!dateData?.note?.length) tempData.push(formateEventData(dateData, 'note') as EventInput);
          });

          break;
        case 'area_calendar':
          calendarAllData?.forEach(
            (dateData: TCalendar) => !dateData.blackout_day && tempData.push(formateEventData(dateData, 'body') as EventInput)
          );
          break;
        case 'area_availabilities':
          calendarAllData?.forEach((dateData: TCalendar) => {
            if (!dateData.blackout_day) tempData.push(...(formateEventData(dateData, 'body') as EventInput[]));
          });
          break;
        case 'blackout_days':
          dispatch(setPreviousYear(moment(date).year()));

          break;
      }
      dispatch(setEvents(tempData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarAllData]);

  useEffect(() => {
    if (!!autoAppointmentConfigData && Object.keys(autoAppointmentConfigData).length > 0 && isAutoAppointmentConfigDataFetched) {
      setCalendarTabs([
        ...calendarTabs,
        { value: 'area_calendar', label: `${autoAppointmentConfigData?.area} Calendar` },
        { value: 'blackout_days', label: 'Blackout Days Calendar' },
        { value: 'area_availabilities', label: `${autoAppointmentConfigData?.area} Availabilities Calendar` }
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoAppointmentConfigDataFetched]);

  return (
    <div>
      {!!selectedTab?.length ? (
        <>
          <div className="flex justify-end my-4">
            <Button startIcon={<SettingOutlined />} variant="shadow" onClick={() => setOpenSettings(true)}>
              Settings
            </Button>
          </div>
          <Tabs variant="fullWidth" scrollButtons="auto" value={selectedTab} onChange={handleTabChange}>
            {calendarTabs.map((eachTab) => (
              <Tab label={eachTab.label} value={eachTab.value} />
            ))}
          </Tabs>
          <Calendar
            titleData={titleData}
            appointmentConfigData={autoAppointmentConfigData}
            calendarAllData={calendarAllData}
            isCalendarDataFetched={isCalendarDataFetched}
            refetchCalendarData={refetchCalendarData}
          />
          {openSettings && (
            <UniversalDialog
              action={{ open: openSettings, fullScreen: true }}
              hasPrimaryButton={false}
              onClose={() => setOpenSettings(false)}
              title="Calendar Config"
            >
              <CalendarSetingsPage onClose={handleCloseCalendarSettings} />
            </UniversalDialog>
          )}
        </>
      ) : (
        <ProductPlaceholder />
      )}
    </div>
  );
};
export default CalendarPage;
