import { EditOutlined, LoadingOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { DatePicker, DateTimePicker, LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useQuery } from '@tanstack/react-query';
import FormSubmissionAlertMessage from 'components/FormSubmissionAlertMessage';
import { IReminder, TJobCompleteData, TOfficeToWorkerNote, TScheduleSMSData } from 'components/tables/interfaces/jobTableInterface';
import TUserData, { SingleEstimator } from 'components/tables/interfaces/userTableInterface';
import dayjs, { Dayjs } from 'dayjs';
import { getIn, useFormik } from 'formik';
import useAuth from 'hooks/useAuth';
import useTimezone from 'hooks/useTimezone';
import { ChangeEvent, ForwardedRef, MouseEvent, useEffect, useState } from 'react';
import CustomerServicesInstance from 'services/services.customers';
import EstimateServicesInstance from 'services/services.estimates';
import JobServicesInstance from 'services/services.jobs';
import UserServicesInstance from 'services/services.users';
import { dispatch, useSelector } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { formatCostInDecimal, handleEstimateReminder } from 'utils/common';
import { notificationSendingChannels, reminderTypes } from 'utils/constants';
import trimFc, { ltrim, rtrim } from 'utils/trimFc';
import { uuid } from 'uuidv4';
import * as Yup from 'yup';
import AddScheduleSMSForm from './AddScheduleSMSForm';
import { TEstimateFormRef } from './JobFormWizard';

type TEditOfficeToWorkerNotes = { index: number; text: string };

const ReassignEstimatorForm = (
  {
    existingData,
    onSuccess
  }: {
    existingData: TJobCompleteData;
    onSuccess: () => void;
  },
  ref: ForwardedRef<TEstimateFormRef>
) => {
  const theme = useTheme();
  const { user } = useAuth();
  const formatDateTime = useTimezone();

  const [officeToWorkerNotes, setOfficeToWorkerNotes] = useState<string>('');
  const [editOfficeToWorkerNotes, setEditOfficeToWorkerNotes] = useState<TEditOfficeToWorkerNotes>();
  const [openSheduleSMS, setOpenSheduleSMS] = useState(false);
  const [sheduleSMSValues, setSheduleSMSValues] = useState<TScheduleSMSData>();
  const [allEstimator, setAllEstimator] = useState<SingleEstimator[]>([]);
  const [reminder, setReminder] = useState<string>('1daybefore');

  /** Api Calls */
  const { data: allFetchedEstimators } = useQuery({
    queryKey: ['all_estimator_data'],
    queryFn: () =>
      UserServicesInstance.getAllUsers(undefined, {
        search: [
          [
            {
              field_name: 'status',
              field_value: 1,
              operator: 'exactmatch'
            }
          ],
          [
            { field_name: 'role', field_value: ['estimator', 'company_admin'], operator: 'in' },
            { field_name: 'is_fw_estimator', field_value: true, operator: 'exactmatch' }
          ]
        ]
      })
  });

  const { generalSettings: generalSettingsData } = useSelector((state) => state.settings);
  const { data: customerData } = useQuery({
    queryKey: ['customer_data'],
    queryFn: () => CustomerServicesInstance.getSingleCustomer(existingData.customer?.id ?? ''),
    enabled: !!existingData.customer?.id
  });
  // --------------------------Other functions-------------------
  const formik = useFormik<Omit<TJobCompleteData, 'state'>>({
    initialValues: {
      estimator_instructions: '',
      is_estimate_require_date: true,
      estimator: { id: '', name: '' },
      estimator_commission: null as unknown as number,
      estimate_reminder: {
        exclude_time: false,
        scheduled_date_and_time: new Date().toISOString(),
        reminder_date: '',
        reminder_method: [1, 2] as unknown as number[]
      },
      office_to_worker_notes: [] as TOfficeToWorkerNote[],
      log_note: 'Re-Assign Estimator'
    },

    validationSchema: Yup.object().shape({
      estimator_instructions: Yup.string().required('This field is required'),
      estimator: Yup.object().shape({
        id: Yup.string().required('This field is required')
      }),
      estimate_reminder: Yup.object().when('is_estimate_require_date', (is_estimate_require_date, schema) => {
        return is_estimate_require_date
          ? schema.shape({
              reminder_date: Yup.string().test({
                name: 'reminder_date',
                exclusive: true,
                message: 'Reminder date is required',
                test: function (value) {
                  return reminder === 'choosecustomedate' ? !!value : true;
                }
              }),
              scheduled_date_and_time: Yup.string().required('This field is required')
            })
          : schema.nullable();
      }),
      estimator_commission: Yup.number().when('estimator', ([estimator]) => {
        const selectedEstimator = allFetchedEstimators?.users.find((eachEstimator) => eachEstimator._id === estimator.id);
        if (!selectedEstimator) {
          return Yup.number().nullable().optional();
        }
        if (selectedEstimator?.rate_info?.pay_type === 'commission') {
          return Yup.number().required('This field is required');
        }
        return Yup.number().nullable().optional();
      }),
      log_note: Yup.string().max(255).required('This field is required')
    }),

    onSubmit: async (values, { setSubmitting }) => {
      values.estimator_commission = Number(values.estimator_commission);
      setSubmitting(true);
      await handleJobFormSubmit(values);
      setSubmitting(false);
    }
  });

  //------------------------------------------Handlers----------------------
  const handleJobFormSubmit = async (values: Omit<TJobCompleteData, 'state'>) => {
    if (values && existingData && existingData?._id && existingData.job_name) {
      values.estimate_reminder = {
        ...values.estimate_reminder,
        reminder_date: handleEstimateReminder(formik, reminder)
      } as IReminder;

      const estimateUpdated = await EstimateServicesInstance.reAssignEstimator(existingData?._id, values);
      if (estimateUpdated) {
        const job_id = existingData?._id,
          jobName = existingData.job_name,
          cid = existingData.customer?.id as string,
          cname = existingData.customer?.name as string;

        if (sheduleSMSValues && job_id) {
          const scheduleObj: TScheduleSMSData = {
            ...sheduleSMSValues,
            job: { id: job_id, name: jobName },
            customer: { id: cid, name: cname }
          };
          await JobServicesInstance.addScheduleSMS(scheduleObj)
            .then((response) => {
              if (response) {
                dispatch(
                  openSnackbar({
                    open: true,
                    message: 'Job Updated Successfully',
                    variant: 'alert',
                    alert: {
                      color: 'success'
                    },
                    close: false
                  })
                );
              }
            })
            .catch(console.error);
        }
        onSuccess();
      }
    }
  };
  const handleSheduleSMS = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpenSheduleSMS(true);
  };

  const onAddScheduleSMSFromSubmit = (values: TScheduleSMSData) => {
    setSheduleSMSValues(values);
    setOpenSheduleSMS(false);
  };

  const getDefaultEstimator = () => {
    const matchedEstimator = allFetchedEstimators?.users.some(
      (eachEstimator) => eachEstimator._id === generalSettingsData?.default_estimator?.id
    );
    return matchedEstimator ? generalSettingsData?.default_estimator : { id: '', name: '' };
  };
  //------------------------------------------Notes Handler----------------------
  const handleOfficeToWorkerNotes = () => {
    if (ltrim(rtrim(officeToWorkerNotes)).length > 0) {
      const currentDateTime = new Date();
      const newOfficeToWorkerNotes = [...(formik.values.office_to_worker_notes ?? ([] as TOfficeToWorkerNote[]))];
      newOfficeToWorkerNotes.push({
        text: officeToWorkerNotes,
        id: uuid(),
        created_at: currentDateTime.toISOString(),
        created_by: { id: user?._id as string, name: `${user?.first_name} ${user?.last_name}` },
        updated_at: currentDateTime.toISOString(),
        updated_by: { id: user?._id as string, name: `${user?.first_name} ${user?.last_name}` }
      });
      formik.setFieldValue('office_to_worker_notes', newOfficeToWorkerNotes);
      setOfficeToWorkerNotes('');
    }
  };
  const handleRemoveNote = (noteIndex: number) => {
    const newNotesData = [...(formik.values.office_to_worker_notes ?? [])];
    newNotesData.splice(noteIndex, 1);
    formik.setFieldValue('office_to_worker_notes', newNotesData);
  };

  const handleEditNote = (noteIndex: number) => {
    setEditOfficeToWorkerNotes({ text: '', index: noteIndex });
  };

  const handleSaveEditedNote = (noteIndex: number) => {
    const newNotesData = [...(formik.values.office_to_worker_notes ?? [])];
    if (editOfficeToWorkerNotes?.index === noteIndex && editOfficeToWorkerNotes.text.length) {
      newNotesData[noteIndex].text = editOfficeToWorkerNotes.text;
      newNotesData[noteIndex].updated_at = new Date().toISOString();
      newNotesData[noteIndex].updated_by = { id: user?._id as string, name: `${user?.first_name} ${user?.last_name}` };
      setEditOfficeToWorkerNotes({ text: '', index: null as unknown as number });
      formik.setFieldValue('office_to_worker_notes', newNotesData);
    }
  };
  const handleDateTimePicker = (newValue: Dayjs | null) => {
    formik.setFieldValue(
      'estimate_reminder.scheduled_date_and_time',
      newValue !== null && newValue?.toString() !== 'Invalid Date' ? newValue.toISOString() : ''
    );
  };
  const handleReminderMethode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCheckObject: Array<number> = [...(formik.values.estimate_reminder?.reminder_method ?? [])];
    if (event.target.checked) {
      newCheckObject.push(Number(event.target.value));
      formik.setFieldValue('estimate_reminder.reminder_method', [...new Set(newCheckObject)]);
    } else {
      formik.setFieldValue(
        'estimate_reminder.reminder_method',
        newCheckObject.filter((obj) => obj !== Number(event.target.value))
      );
    }
  };
  //------------------------------useEffects--------------------------
  useEffect(() => {
    if (existingData) formik.setFieldValue('estimator_instructions', existingData?.estimator_instructions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingData]);

  useEffect(() => {
    if (!!allFetchedEstimators?.users) {
      setAllEstimator(
        allFetchedEstimators?.users?.map((singleEstimator) => ({
          value: singleEstimator?._id ?? '',
          label: `${singleEstimator.first_name} ${singleEstimator.last_name}`
        }))
      );
      if (!!generalSettingsData?.default_estimator?.id.length) formik.setFieldValue('estimator', getDefaultEstimator());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFetchedEstimators?.users, generalSettingsData?.default_estimator]);

  useEffect(() => {
    const estimator = allFetchedEstimators?.users.find((estimator) => formik.values?.estimator?.id === estimator._id);
    if (!estimator) {
      return;
    }
    if (estimator?.rate_info?.pay_type === 'hourly' || estimator?.rate_info?.pay_type === 'salary') {
      formik.setFieldValue('estimator_commission', '');
    } else {
      formik.setFieldValue(
        'estimator_commission',
        existingData?.customer?.property_type === 'residential'
          ? estimator.rate_info?.residential_commission
          : estimator.rate_info?.commercial_commission
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.estimator]);

  const getComissionInput = () => {
    const selectedEstimator = allFetchedEstimators?.users.find((estimator: TUserData) => formik.values?.estimator?.id === estimator._id);
    if (selectedEstimator) {
      if (selectedEstimator.rate_info?.pay_type === 'hourly') {
        return <TextField fullWidth disabled aria-disabled value={'Punch Emp.'} InputProps={{ startAdornment: '$' }} />;
      }

      return (
        <TextField
          inputProps={{ min: 0 }}
          fullWidth
          inputMode="decimal"
          id="estimator_commission"
          name="estimator_commission"
          value={formik.values.estimator_commission ?? ''}
          onBlur={(event) => {
            const numericValue = parseFloat(event.target.value);
            if (!isNaN(numericValue)) {
              formik.setFieldValue('estimator_commission', numericValue.toFixed(2));
            }
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            formik.setFieldValue('estimator_commission', formatCostInDecimal(event.target.value));
          }}
          placeholder={selectedEstimator.rate_info?.pay_type === 'salary' ? 'Salaried Emp.' : ''}
          InputProps={{ startAdornment: '$' }}
          error={!!formik.touched.estimator_commission && !!formik.errors.estimator_commission}
          helperText={formik.touched.estimator_commission && formik.errors.estimator_commission}
        />
      );
    }
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        component={'form'}
        onSubmit={(event) => {
          const allErrors = Object.keys(formik.errors);

          if (allErrors.length > 0) {
            const firstError = allErrors[0];

            const firstErrorElement = document.getElementById(firstError);
            if (firstErrorElement) {
              firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
              firstErrorElement.focus();
            }
          }
          handleOfficeToWorkerNotes();
          formik.handleSubmit(event);
        }}
      >
        {/* -----------------------------Estimate Assigned to *------------------------------ */}
        <Grid item xs={6} className="space-y-2">
          <InputLabel htmlFor="estimator">Estimate Assigned to*</InputLabel>
          <Autocomplete
            id="estimator"
            value={
              allEstimator.find((singleData: SingleEstimator) => singleData.value === formik.values?.estimator?.id) || {
                value: '',
                label: ''
              }
            }
            disableClearable
            getOptionLabel={(option) => option.label}
            onChange={(event: React.SyntheticEvent<Element, Event>, newValue: { label: string; value: string }) =>
              formik.setFieldValue('estimator', { id: newValue.value, name: newValue.label })
            }
            options={allEstimator}
            renderInput={(params) => (
              <TextField
                error={getIn(formik.touched, 'estimator.id') && getIn(formik.errors, 'estimator.id')}
                {...params}
                placeholder=""
                sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
              />
            )}
          />
          {getIn(formik.touched, 'estimator.id') && getIn(formik.errors, 'estimator.id') && (
            <FormHelperText error id="helper-text-first_name">
              {getIn(formik.errors, 'estimator.id')}
            </FormHelperText>
          )}
        </Grid>
        {/* -----------------------------------------Estimator Commission--------------------------------- */}

        {!!formik.values.estimator?.id.length && (
          <Grid item xs={6} className="space-y-2">
            <InputLabel htmlFor="estimator_commission">Estimator Commission</InputLabel>
            {getComissionInput()}
          </Grid>
        )}

        {/* -----------------------------------------Scheduled Date And Time------------------------------ */}
        <Grid container xs={12} spacing={3} item>
          {/* -----------------------------------------Scheduled Date ------------------------------ */}

          <Grid item className="space-y-2" xs={12} sm={6}>
            <InputLabel htmlFor="scheduled_time">
              {formik.values.estimate_reminder?.exclude_time ? 'Schedule Date' : 'Schedule Date & Time'}
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {formik.values.estimate_reminder?.exclude_time ? (
                <DatePicker
                  className="w-full"
                  value={dayjs(formik.values.estimate_reminder?.scheduled_date_and_time)}
                  minDate={dayjs(new Date())}
                  onChange={(newValue: Dayjs | null) => {
                    formik.setFieldValue(
                      'estimate_reminder.scheduled_date_and_time',
                      newValue !== null && newValue?.toString() !== 'Invalid Date' ? newValue.toISOString() : ''
                    );
                  }}
                />
              ) : (
                <DateTimePicker
                  className="w-full"
                  value={dayjs(formik.values.estimate_reminder?.scheduled_date_and_time)}
                  minDate={dayjs(new Date())}
                  onChange={handleDateTimePicker}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock
                  }}
                />
              )}
              {getIn(formik.touched, 'estimate_reminder.scheduled_date_and_time') &&
                getIn(formik.errors, 'estimate_reminder.scheduled_date_and_time') && (
                  <FormHelperText error id="helper-text-first_name">
                    {getIn(formik.errors, 'estimate_reminder.scheduled_date_and_time')}
                  </FormHelperText>
                )}
            </LocalizationProvider>
            <FormControlLabel
              value={formik.values.estimate_reminder?.exclude_time}
              name="estimate_reminder.exclude_time"
              id="estimate_reminder.exclude_time"
              color="primary"
              checked={formik.values.estimate_reminder?.exclude_time}
              control={
                <Checkbox
                  onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                    formik.setFieldValue('estimate_reminder.exclude_time', checked)
                  }
                />
              }
              label={'Exclude Time'}
            />
          </Grid>

          {/* -----------------------------------------Reminder------------------------------ */}
          <Grid item className="space-y-2" xs={12} sm={6}>
            <InputLabel htmlFor="estimate_reminder">Reminder</InputLabel>
            <Autocomplete
              id="estimate_reminder"
              fullWidth
              value={reminderTypes.find((singleData) => singleData.value === reminder)}
              disableClearable
              getOptionLabel={(option) => option.label}
              onChange={(event, newValue: { label: string; value: string }) => setReminder(newValue.value)}
              options={reminderTypes}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder=""
                  sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                />
              )}
            />
          </Grid>
          {/* -----------------------------------------Reminder Date------------------------------ */}
          {reminder === 'choosecustomedate' && (
            <Grid item className="space-y-2" xs={12} sm={6}>
              <InputLabel htmlFor="estimate_reminder.reminder_date">Reminder Date</InputLabel>
              <DatePicker
                className="w-full"
                minDate={dayjs()}
                maxDate={dayjs(formik.values.estimate_reminder?.scheduled_date_and_time)}
                value={dayjs(formik.values.estimate_reminder?.reminder_date)}
                onChange={(newValue: Dayjs | null) => formik.setFieldValue('estimate_reminder.reminder_date', newValue?.toISOString())}
              />
              {getIn(formik.touched, 'estimate_reminder.reminder_date') && getIn(formik.errors, 'estimate_reminder.reminder_date') && (
                <FormHelperText error id="helper-text-first_name">
                  {getIn(formik.errors, 'estimate_reminder.reminder_date')}
                </FormHelperText>
              )}
            </Grid>
          )}
          {/* -----------------------------------------Reminder Method------------------------------ */}
          <Grid item className="space-y-2" xs={12} sm={6}>
            <InputLabel htmlFor="estimate_reminder.reminder_method">Reminder Method</InputLabel>
            <Stack direction={'row'} spacing={2}>
              {notificationSendingChannels.map((items: { value: number; label: string }, index) => {
                return (
                  <FormControlLabel
                    value={items.value}
                    name="estimate_reminder.reminder_method"
                    id="estimate_reminder.reminder_method"
                    color="primary"
                    checked={formik.values.estimate_reminder?.reminder_method.includes(items.value)}
                    control={<Checkbox onChange={handleReminderMethode} />}
                    label={items.label}
                  />
                );
              })}
            </Stack>
          </Grid>
        </Grid>
        {/* -----------------------------------------Estimator Instruction------------------------------ */}

        <Grid item xs={12} className="space-y-2">
          <InputLabel htmlFor="estimator_instructions">Instructions/Details for Estimator *</InputLabel>
          <TextField
            id="estimator_instructions"
            fullWidth
            name="estimator_instructions"
            multiline
            rows={2}
            onChange={trimFc(formik)}
            value={formik.values?.estimator_instructions}
            error={!!formik.touched.estimator_instructions && !!formik.errors.estimator_instructions}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && !event.shiftKey) {
                formik.submitForm();
                event.preventDefault();
              }
            }}
          />
          {formik.touched.estimator_instructions && formik.errors.estimator_instructions && (
            <FormHelperText error id="helper-text-estimator_instructions-signup">
              {formik.errors?.estimator_instructions}
            </FormHelperText>
          )}
        </Grid>

        {/* -----------------------------Add a Schdule Sms------------------------------ */}
        <Grid item xs={12}>
          <Button className="mb-4 space-y-4" variant="outlined" color="primary" onClick={handleSheduleSMS}>
            Add a Schedule SMS
          </Button>
        </Grid>
        {/* -----------------------------Office To Worker Notes------------------------------ */}

        <Grid item xs={12} sm={6} className="space-y-2">
          <InputLabel htmlFor="office_to_worker_notes">Office To Worker Notes</InputLabel>
          <Divider className="mb-3" />

          <TextField
            className=" w-full"
            id={`office_to_worker_notes`}
            name={`office_to_worker_notes`}
            multiline
            rows={3}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setOfficeToWorkerNotes(event.target.value)}
            value={officeToWorkerNotes}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && !event.shiftKey) {
                formik.submitForm();
                event.preventDefault();
              }
            }}
          />
          <div>
            <Button
              className="mt-4 space-y-4 w-1/12"
              variant="outlined"
              color="primary"
              type="reset"
              onClick={() => handleOfficeToWorkerNotes()}
            >
              Add
            </Button>
          </div>

          {formik.values?.office_to_worker_notes?.map((officeToWorkerNote, noteIndex) => {
            const date = formatDateTime(officeToWorkerNote.created_at, {
              year: 'numeric',
              day: '2-digit',
              month: '2-digit',
              hour12: generalSettingsData?.time_format === '12hrs'
            });
            const time = formatDateTime(officeToWorkerNote.created_at, {
              hour: '2-digit',
              minute: '2-digit',
              hour12: generalSettingsData?.time_format === '12hrs'
            });
            return (
              <Card className=" w-full rounded-sm bg-yellow-100 mt-3">
                <CardHeader
                  className="mb-2 p-3"
                  title={
                    <div className="flex items-center justify-between">
                      {officeToWorkerNote.text !== '' && (
                        <div className="flex items-start justify-between">
                          <div className="flex items-center md:flex-row flex-col space-x-2">
                            <Typography variant="body2">
                              <span className="text-gray-500 font-bold">Created At:</span> <span>{date}</span>&nbsp;
                              <span> {time}</span>
                            </Typography>
                          </div>
                        </div>
                      )}
                      {editOfficeToWorkerNotes?.index !== noteIndex ? (
                        <ButtonGroup variant="text">
                          <IconButton autoFocus color="primary" onClick={() => handleEditNote(noteIndex)}>
                            <EditOutlined />
                          </IconButton>

                          <IconButton color="error" onClick={() => handleRemoveNote(noteIndex)}>
                            <PlusOutlined className="rotate-45" />
                          </IconButton>
                        </ButtonGroup>
                      ) : (
                        <ButtonGroup variant="text">
                          <Button
                            autoFocus
                            size="small"
                            // variant="outlined"
                            color="primary"
                            startIcon={<SaveOutlined />}
                            onClick={() => handleSaveEditedNote(noteIndex)}
                          >
                            Save
                          </Button>
                          <Button
                            startIcon={<PlusOutlined className="rotate-45" />}
                            size="small"
                            // variant="outlined"
                            color="error"
                            onClick={() => setEditOfficeToWorkerNotes({ text: '', index: null as unknown as number })}
                          >
                            Cancel
                          </Button>
                        </ButtonGroup>
                      )}
                    </div>
                  }
                />
                <CardContent className="border-l-4 m-3 flex items-start pt-1">
                  {editOfficeToWorkerNotes?.index !== noteIndex ? (
                    <Typography variant="body1" className="break-words">
                      {officeToWorkerNote?.text}
                    </Typography>
                  ) : (
                    <TextField
                      variant="standard"
                      className="w-full"
                      id={`office_to_worker_notes[${noteIndex}]`}
                      name={`office_to_worker_notes[${noteIndex}]`}
                      multiline
                      rows={3}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setEditOfficeToWorkerNotes({ index: noteIndex, text: event.target.value });
                      }}
                      value={editOfficeToWorkerNotes?.text || officeToWorkerNote?.text}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter' && !event.shiftKey) {
                          formik.submitForm();
                          event.preventDefault();
                        }
                      }}
                    />
                  )}
                </CardContent>
              </Card>
            );
          })}
        </Grid>
        {/* -----------------------------Log Notes------------------------------ */}
        <Grid item xs={12} md={12} className="space-y-2">
          <InputLabel htmlFor="log_note">Log Notes*</InputLabel>
          <TextField
            className="md:w-1/2 w-full"
            id="log_note"
            fullWidth
            placeholder="Log Notes"
            name="log_note"
            multiline
            rows={3}
            onChange={trimFc(formik)}
            value={formik.values?.log_note}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && !event.shiftKey) {
                formik.submitForm();
                event.preventDefault();
              }
            }}
          />
        </Grid>
        <div className="w-full flex flex-row items-center justify-end mt-5 space-x-3">
          {FormSubmissionAlertMessage(formik)}
          <Button variant="contained" disabled={formik.isSubmitting} type="submit" startIcon={formik.isSubmitting && <LoadingOutlined />}>
            Save
          </Button>
        </div>
      </Grid>
      {openSheduleSMS && !!customerData && (
        <AddScheduleSMSForm
          data={{
            customer: customerData,
            selectedUserId: formik.values.estimator?.id,
            sheduleSMSValues: sheduleSMSValues ?? ({} as TScheduleSMSData),
            scheduleDateAndTime: formik.values.estimate_reminder?.scheduled_date_and_time
          }}
          openSheduleSMS={openSheduleSMS}
          setOpenSheduleSMS={setOpenSheduleSMS}
          onAddScheduleSMSFromSubmit={onAddScheduleSMSFromSubmit}
          onClose={() => {
            setOpenSheduleSMS(false);
          }}
        />
      )}
    </>
  );
};

export default ReassignEstimatorForm;
