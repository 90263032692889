import { CloseOutlined, DeleteOutlined, EyeFilled, LoadingOutlined, MoreOutlined, SaveOutlined, SubnodeOutlined } from '@ant-design/icons';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Button,
  ButtonGroup,
  CardContent,
  ClickAwayListener,
  Collapse,
  Grid,
  Icon,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  InputLabel,
  List,
  ListItemButton,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import CustomTooltip from 'components/CustomTooltip';
import { universalRenerMedia } from 'components/UniversalRenderMedia';
import { AddJobItemForm } from 'components/forms/JobOptionItem/AddJobItemForm';
import UniversalDialog from 'components/popups/UniversalDialog';
import CategoryItemDisplayTable from 'components/tables/CategoryItemDisplayTable';
import { TJobItem } from 'components/tables/interfaces/jobTableInterface';
import { motion } from 'framer-motion';
import _, { cloneDeep } from 'lodash';
import mime from 'mime';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import CategoriesProductAndServicesInstance from 'services/services.categoriesProductAndServices';
import FileUploadServiceInstance from 'services/services.fileUpload';
import MediaServicesInstance from 'services/services.media';
import { dispatch } from 'store';
import { setActiveTabTitle, setOptionsData } from 'store/reducers/customReducers/slice.estimateOptions';
import { setActiveNoteInputId, setAddJobMediaInputId } from 'store/reducers/customReducers/slice.jobs';
import { addLogs } from 'store/reducers/customReducers/slice.logs';
import ErrorBoundary from 'utils/ErrorHandlers/ErrorBoundary';
import { formateData } from 'utils/common';
import { motionContainer, motionItem } from 'utils/constants';
import * as v4 from 'uuidv4';
import JobCategoryCards from './JobCategoriesCard';
import { TJobCategory, TOptionTabs, TSelecetdJobCategory } from './types/types.estimateOptions';
import { Stage, Layer, Image,Line } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import Konva from 'konva';
import { TwitterPicker } from "react-color";

type TEstimateOptionsProps = {
  optionData?: TOptionTabs[];
  isEditMode?: boolean;
  previousStates?: string[];
  defaultTaxRate: number;
  isAppointmentJob?: boolean;
  customerData: any;
  jobId: string;
};

interface LineData {
  points: number[];
  stroke: string
}

const EstimateOptions = (props: TEstimateOptionsProps) => {
  //  ----- Set Option Data estimate note empty -------
  props?.optionData?.forEach((option) => {
    option.data.estimate_note = '';
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const timeout = useRef<NodeJS.Timeout>();
  const [openAddOption, setOpenAddOption] = useState<boolean>(false);
  const [isNewOptionAdded, setIsNewOptionAdded] = useState<boolean>(false);
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [optionTabs, setOptionTabs] = useState<TOptionTabs[]>([
    {
      title: 'Option 1',
      value: 'option-1',
      id: v4.uuid(),
      optionId: 0,
      data: { option_name: 'option-1', proposal_note: '', estimate_note: '' }
    }
  ]);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [activeOptionTab, setActiveOptionTab] = useState<TOptionTabs>(optionTabs[0]);
  const [selectedOptionToBeEdited, setSelectedOptionToBeEdited] = useState<number>(null as unknown as number);
  const [allJobCategories, setAllJobCategories] = useState<TJobCategory[]>([]);
  const [clickedJobCategory, setClickedJobCategory] = useState<TSelecetdJobCategory>();

  const [optionNewName, setOptionNewName] = useState<string | null>();
  const [optionErrorAlert, setOptionErrorAlert] = useState<boolean>(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [imageObj, setImageObj] = useState<HTMLImageElement | null>(null);
  const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
  const [lines, setLines] = useState<LineData[]>([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [strokeColor, setStrokeColor] = useState("#ff0000");
  const stageRef = useRef<Konva.Stage>(null);
  const imageListItemRef = useRef<HTMLLIElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [drawingImageIndex, setDrawingImageIndex] = useState<number | null>(null);
  const [originalImageURL, setOriginalImageURL] = useState<string | null>(null);
  const [imageIndex, setImageIndex] = useState<number | null>(null);
  const [imageMenuAnchor, setImageMenuAnchor] = useState<{ anchor: null | HTMLButtonElement; open: boolean; data?: any }>({
    anchor: null,
    open: false
  });

  const presetColors = [
    "#000000", "#FFFFFF", "#FF0000", "#00FF00", "#0000FF",
    "#FFFF00", "#FF00FF", "#00FFFF", "#800000", "#808000",
    "#008000", "#800080", "#808080", "#C0C0C0", "#FFA500"
  ];

  //-----------------------------useQuery-----------------------
  const { data: jobCategoriesData } = useQuery({
    queryKey: ['job_categories'],
    queryFn: CategoriesProductAndServicesInstance.getJobCategories,
    networkMode: 'always'
  });

  //--------------------useEffects------------------------
  useEffect(() => {
    dispatch(setActiveNoteInputId('estimate_option_proposal_note'));
    dispatch(setAddJobMediaInputId('upload-file-options'));
    if (props.isEditMode && props.optionData) {
      setOptionTabs(props.optionData);
      setActiveOptionTab(props.optionData[0]);
    }
    return () => {
      dispatch(setActiveNoteInputId(''));
    };
  }, []);

  useEffect(() => {
    if (imageObj && imageListItemRef.current) {
      const maxWidth = Math.min(window.innerWidth - 40, 300);
      const maxHeight = Math.min(window.innerHeight - 40, 250);
      const imageHeight = imageObj.height;
      const imageWidth = imageObj.width;

      if (imageWidth > 0 && imageHeight > 0) {
        let newWidth = imageWidth;
        let newHeight = imageHeight;;

        if (imageWidth > maxWidth) {
          newWidth = maxWidth;
        }
        if (imageHeight > maxHeight) {
          newHeight = maxHeight;
        }

        setStageSize({ width: newWidth, height: newHeight });
      }
      if (imageListItemRef.current) {
        imageListItemRef.current.style.height = 'auto';
        imageListItemRef.current.style.maxHeight = `${Math.min(stageSize.height, maxHeight)}px`;
        imageListItemRef.current.style.width = `${Math.min(stageSize.width, maxWidth)}px`; // Set width of ImageListItem
      }
    }
  }, [imageObj, stageSize.width]);

  useEffect(() => {
    if (jobCategoriesData) {
      const dummyJobCategoriesData: { _id: string; title: string; type: string; background: string; icon: string }[] =
        jobCategoriesData.map((jobCategoryData: { _id?: string; category_name: string; image?: string }) => ({
          _id: jobCategoryData._id ? jobCategoryData._id : '',
          type: '',
          title: jobCategoryData.category_name,
          background: 'green',
          icon: jobCategoryData.image ?? ''
        }));
      setAllJobCategories(dummyJobCategoriesData);
    }
  }, [jobCategoriesData]);

  useEffect(() => {
    if (optionTabs) {
      const allOptionsData = JSON.parse(JSON.stringify(optionTabs)).map((eachOption: TOptionTabs) => ({
        ...eachOption.data,
        option_name: eachOption.title,
        id: eachOption.id,
        items: eachOption.data.items
          ? eachOption.data.items.map((eachItem) => ({
              job_category: eachItem.job_category,
              image: eachItem.image,
              service_and_product: eachItem.service_and_product,
              worker_commission: eachItem.worker_commission ?? 0,
              description: eachItem.description,
              description_to_field_worker: eachItem.description_to_field_worker,
              units: eachItem.units,
              taxable: eachItem.taxable,
              item_details: eachItem.item_details,
              price: eachItem.price,
              measure_type: eachItem.measure_type,
              measurements: eachItem.measurements,
              id: eachItem.id,
              show_units_on_proposal: eachItem.show_units_on_proposal
            }))
          : []
      }));

      dispatch(setOptionsData(allOptionsData));
    }
  }, [optionTabs]);

  useEffect(() => {
    if (isNewOptionAdded) {
      setActiveOptionTab(optionTabs[optionTabs.length - 1]);
      setIsNewOptionAdded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewOptionAdded]);

  useEffect(() => {
    dispatch(setActiveTabTitle(activeOptionTab.title));
  }, [activeOptionTab]);
  //-----------------------handlers--------------------------

  //tab--------------------------

  const handleTabChange = (newValue: number) => {
    if (newValue === Number(activeOptionTab.optionId)) return;
    const dummyOptionTabsArray = [...optionTabs];
    if (!dummyOptionTabsArray[dummyOptionTabsArray.length - 1].data.items?.length) {
      setOptionErrorAlert(true);
      return;
    }
    const optionTab = optionTabs.find((tab) => tab.optionId === newValue);
    setOptionErrorAlert(false);

    if (optionTab) {
      dispatch(
        addLogs({
          description: `${activeOptionTab.title} estimate updated`,
          action: `estimate_updated`
          // ,  attachments: []
        })
      );
      setActiveOptionTab(optionTab);
    }
  };


  //option--------------------------
  const handleOptionNameSave = (optionIndex: number) => {
    const newOptionTabs = [...optionTabs];
    if (optionNewName && optionNewName.length) {
      newOptionTabs[optionIndex].title = optionNewName;
      setOptionTabs(newOptionTabs);
      setOptionNewName(null);
      setSelectedOptionToBeEdited(null as unknown as number);
    }
  };


  const handleMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    if (e.type === 'mousedown' && drawingImageIndex !== null) {
      setIsDrawing(true);
      const stage = e.target.getStage();
      const pos = stage?.getPointerPosition();
      if (pos) {
        setLines([...lines, { points: [pos.x, pos.y], stroke: strokeColor }]);
      }
    }
  };

  const handleMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    if (e.type === 'mousemove' && isDrawing && drawingImageIndex !== null) {
      if (!isDrawing) {
        return;
      }
      const stage = e.target.getStage();
      const point = stage?.getPointerPosition();
      if (point && lines.length > 0) {
        const lastLine = lines[lines.length - 1];
        const newPoints = lastLine.points.concat([point.x, point.y]);
        const newLines = [...lines];
        newLines[lines.length - 1] = { ...lastLine, points: newPoints };
        setLines(newLines);
      }
    }
  };

  const handleMouseUp = (e: KonvaEventObject<MouseEvent>) => {
    if (e.type === 'mouseup' && isDrawing && drawingImageIndex !== null) {
      setIsDrawing(false);
    }
  };

  const handleTouchStart = (e: KonvaEventObject<TouchEvent>) => {
    if (drawingImageIndex !== null && e.evt.touches && e.evt.touches.length === 1) {
      setIsDrawing(true);
      const stage = e.target.getStage();
      const pos = stage?.getPointerPosition();
      if (pos) {
        setLines([...lines, { points: [pos.x, pos.y], stroke: strokeColor }]);
      }
      e.evt.preventDefault();
    }
  };

  const handleTouchMove = (e: KonvaEventObject<TouchEvent>) => {
    if (isDrawing && drawingImageIndex !== null && e.evt.touches && e.evt.touches.length === 1) {
      if (!isDrawing) {
        return;
      }
      const stage = e.target.getStage();
      const point = stage?.getPointerPosition();
      if (point && lines.length > 0) {
        const lastLine = lines[lines.length - 1];
        const newPoints = lastLine.points.concat([point.x, point.y]);
        const newLines = [...lines];
        newLines[lines.length - 1] = { ...lastLine, points: newPoints };
        setLines(newLines);
      }
      e.evt.preventDefault(); // Prevent default touch behavior
    }
  };

  const handleTouchEnd = (e: KonvaEventObject<TouchEvent>) => {
    if (isDrawing && drawingImageIndex !== null) {
      setIsDrawing(false);
    }
  };

  const handleDeleteDrawing = () => {
    setLines([]);
    setShowPicker(false)
  };


  const handleLoadImage = async (url: string, index: number) => {
    setLines([]);
    setImageIndex(index)
    setOriginalImageURL(url);
    setDrawingImageIndex(index);
    setImageMenuAnchor((prev) => {
      return {
        anchor: prev.anchor,
        open: false,
        data: prev.data
      };
    });
  
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const objectURL = URL.createObjectURL(blob);
  
      const img = new window.Image();
      img.crossOrigin = "anonymous";
      img.src = objectURL;
  
      img.onload = () => {
        setImageObj(img);
        setIsImageLoaded(true);
        setStageSize({ width: img.width, height: img.height });
        URL.revokeObjectURL(objectURL);
      };
    } catch (error) {
      console.error("Error loading image:", error);
    }
  };
  
  const handleSaveDrawing = async (index: number) => {
    if (lines.length === 0) {
      handleCancelDrawing();
      return;
    }
  
    if (stageRef.current && drawingImageIndex !== null) {
      const blob = await new Promise<Blob | null>((resolve) => {
        stageRef.current?.toBlob({
          mimeType: "image/png",
          quality: 1.0,
          callback: (blob) => resolve(blob),
        });
      });
  
      if (!blob) {
        console.error("Failed to generate image blob.");
        return;
      }
  
      const file = new File([blob], "drawing.png", { type: "image/png" });
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      const fileList = dataTransfer.files;
      const fakeEvent = { target: { files: fileList } } as ChangeEvent<HTMLInputElement>;
  
      deleteMedia(index);
      handleFileUpload(fakeEvent);
  
      setImageIndex(null);
      setDrawingImageIndex(null);
      setImageObj(null);
      setLines([]);
      setShowPicker(false);
      setIsImageLoaded(false);
    }
  };
  
  
  
  
  
  const handleCancelDrawing = () => {
    if (drawingImageIndex !== null && originalImageURL) {
      const newOptionTabs = [...optionTabs];
      const updatedFiles = newOptionTabs[activeOptionTab.optionId].data.files?.map((file, index) => {
        if (index === drawingImageIndex) {
          return { ...file, url: originalImageURL };
        }
        return file;
      });
      newOptionTabs[activeOptionTab.optionId].data.files = updatedFiles;
      setOptionTabs(newOptionTabs);
      setActiveOptionTab((prev) => ({ ...prev, data: newOptionTabs[prev.optionId].data }));
    }
    setImageIndex(null)
    setDrawingImageIndex(null);
    setShowPicker(false)
    setImageObj(null);
    setLines([]);
    setIsImageLoaded(false);
    setOriginalImageURL(null);
  };

  const handleAddNewOption = () => {
    const dummyOptionTabsArray = [...optionTabs];
    if (!dummyOptionTabsArray[dummyOptionTabsArray.length - 1].data.items?.length) {
      setOptionErrorAlert(true);
      return;
    }
    dispatch(
      addLogs({
        description: `Option ${optionTabs[optionTabs.length - 1].optionId + 2}`,
        action: 'estimate_created'
      })
    );
    setOptionTabs((prev) => {
      return [
        ...prev,
        {
          title: `Option ${prev[prev.length - 1].optionId + 2}`,
          value: `option-${prev[prev.length - 1].optionId + 2}`,
          id: v4.uuid(),
          optionId: prev[prev.length - 1].optionId + 1,
          data: { option_name: `Option ${prev[prev.length - 1].optionId + 2}`, proposal_note: '', estimate_note: '' }
        }
      ];
    });
    setIsNewOptionAdded(true);
    setOpenAddOption(false);
    setOptionErrorAlert(false);
  };

  const handleAddDuplicateOption = () => {
    const dummyOptionTabsArray = [...optionTabs];
    if (!dummyOptionTabsArray[dummyOptionTabsArray.length - 1].data.items?.length) {
      setOptionErrorAlert(true);
      return;
    }
    setOptionTabs((prev) => {
      return [
        ...prev,
        {
          ...prev[activeOptionTab.optionId],
          title: `Option ${prev[prev.length - 1].optionId + 2}`,
          value: `option-${prev[prev.length - 1].optionId + 2}`,
          id: v4.uuid(),
          optionId: prev[prev.length - 1].optionId + 1
        }
      ];
    });
    dispatch(
      addLogs({
        description: `Estimate Duplicate`,
        action: 'estimate_duplicate'
        // ,  attachments: []
      })
    );
    setOpenAddOption(false);
    setOptionErrorAlert(false);
    setIsNewOptionAdded(true);
  };

  const deleteOptionTab = (optionId: number) => {
    const newOptionTabs = [...optionTabs];
    const optionIndex = newOptionTabs.findIndex((eachTab) => eachTab.optionId === optionId);
    dispatch(
      addLogs({
        description: `${newOptionTabs[optionIndex].title} Estimate Delete`,
        action: 'estimate_delete'
        // ,  attachments: []
      })
    );
    if (optionIndex === newOptionTabs.length - 1) {
      const previousTab = optionTabs.find((tab, tabIndex) => tabIndex === optionIndex - 1 && tab);
      previousTab && setActiveOptionTab(previousTab);
    } else if (optionIndex === newOptionTabs.length - 2) {
      const previousTab = optionTabs.find((tab, tabIndex) => tabIndex === optionIndex && tab);
      previousTab && setActiveOptionTab(previousTab);
    } else {
      setActiveOptionTab((prev) => prev);
    }

    setOptionTabs(newOptionTabs.filter((tab) => tab.optionId !== optionId));
  };

  const handleJobCategoryClick = (categoryId: string, categoryTitle: string) => {
    setClickedJobCategory({ categoryId, categoryTitle });
  };

  const handleEditItem = (jobItemData: TJobItem) => {
    const newOptionTabs = JSON.parse(JSON.stringify(optionTabs));
    const updatedItemIndex = newOptionTabs[activeOptionTab.optionId].data.items.findIndex(
      (singleItem: TJobItem) => singleItem.id === jobItemData.id
    );
    newOptionTabs[activeOptionTab.optionId].data.items[updatedItemIndex] = jobItemData;
    calculateOverallCostOfItems(newOptionTabs);
  };
  const closeAddItemsDialog = () => {
    setClickedJobCategory(undefined);
  };

  const handleAddItems = (newItems: TJobItem[]) => {
    if (newItems.length) {
      const newOptionTabs = JSON.parse(JSON.stringify(optionTabs));

      newOptionTabs[activeOptionTab.optionId].data = {
        ...newOptionTabs[activeOptionTab.optionId].data,
        items: [...((newOptionTabs[activeOptionTab.optionId].data.items as TJobItem[]) ?? []), ...newItems]
      };
      calculateOverallCostOfItems(newOptionTabs);
    }
  };

  const handleDeleteCategory = (categoryId: string) => {
    const newOptionTabs = _.cloneDeep(optionTabs);
    const items = activeOptionTab.data.items?.filter((item) => item.job_category.id !== categoryId);
    newOptionTabs[activeOptionTab.optionId].data = {
      ...newOptionTabs[activeOptionTab.optionId].data,
      items: items ?? []
    };

    calculateOverallCostOfItems(newOptionTabs);
  };

 const handleDeleteItem = (itemId: string) => {
    const newOptionTabs = _.cloneDeep(optionTabs);
    const items = activeOptionTab.data.items?.filter((item) => item.id !== itemId);
    newOptionTabs[activeOptionTab.optionId].data = {
      ...newOptionTabs[activeOptionTab.optionId].data,
      items: items ?? []
    };
    calculateOverallCostOfItems(newOptionTabs);
  };

  const calculateSubTotalCost = (options: TOptionTabs) => {
    const allItems = options.data.items;
    return allItems?.length ? allItems.reduce((total, currentItem) => (currentItem ? total + currentItem.price : total), 0) : 0;
  };
  const calculateOverallCostOfItems = (newOptionTabs: TOptionTabs[]) => {
    const subTotalCost = calculateSubTotalCost(newOptionTabs[activeOptionTab.optionId]);
    // const totalTax = newOptionTabs
    // .flatMap((entry) => entry.data.items)
    // .reduce((total, currentItem) => total + (currentItem?.taxable ?? 0), 0);
    const totalTax =
      newOptionTabs[activeOptionTab.optionId]?.data?.items?.reduce((total, currentItem) => total + (currentItem?.taxable ?? 0), 0) ?? 0;

    const grandTotalCost = subTotalCost + totalTax;

    newOptionTabs[activeOptionTab.optionId].data = {
      ...newOptionTabs[activeOptionTab.optionId].data,
      sub_total: subTotalCost,
      tax: totalTax,
      grand_total: grandTotalCost
    };

    setActiveOptionTab(newOptionTabs[activeOptionTab.optionId]);
    setOptionTabs(newOptionTabs);
    setOptionErrorAlert(false);
  };
  const renderTotalAmountSection = () => {
    const costInfo = activeOptionTab.data;

    return (
      <CardContent className="mr-2 sm:mr-12 space-y-2">
        <SummarySection label="Sub Total" value={costInfo.sub_total ?? 0} bold />
        <SummarySection label="Tax" value={costInfo.tax ?? 0} bold />
        <SummarySection label="Grand Total" value={costInfo.grand_total ?? 0} bold />
      </CardContent>
    );
  };

  const addMedia = async (url: string[]) => {
    const customerId = props?.customerData?._id;
    const jobId = props?.jobId;

    await MediaServicesInstance.addMedia(url, 'jobs', customerId, jobId, 'media');
  };

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      setIsFileUploading(() => true);
      const FilesData = Object.values(event.target.files);
      const newImagesData = [...(optionTabs[activeOptionTab.optionId].data.files ?? [])];
      const uploadResponse = await Promise.all(FilesData.map((eachFile) => FileUploadServiceInstance.uploadFile(eachFile)));
      if (uploadResponse) {
        const allUploadedImages = uploadResponse.map((response) => {
          newImagesData.push({ url: response?.data, flagForJob: false, addToProposal: false });
          return response?.data;
        });
        addMedia(allUploadedImages);
      }

      optionTabs[activeOptionTab.optionId].data.files = newImagesData;

      setOptionTabs(cloneDeep(optionTabs));

      setActiveOptionTab((prev) => ({ ...prev, data: optionTabs[prev.optionId].data }));
      setIsFileUploading(false);

      const lastIndex = newImagesData.length - 1;
      timeout.current = setTimeout(() => {
        const lastFileEl: HTMLButtonElement | null = document.getElementById(`image-menu-${lastIndex}`) as HTMLButtonElement;
        lastFileEl?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        if (lastFileEl) {
          setImageMenuAnchor({
            anchor: lastFileEl,
            open: false,
            data: { file: newImagesData[lastIndex], index: lastIndex }
          });
        }
      }, 600);
    }
    event.target.value = ""
  };

  const deleteMedia = (index: number) => {
    const newOptionTabs = [...optionTabs];

    newOptionTabs[activeOptionTab.optionId].data = {
      ...newOptionTabs[activeOptionTab.optionId].data,
      files: newOptionTabs[activeOptionTab.optionId].data?.files?.filter((file, findex) => findex !== index)
    };
    setOptionTabs(newOptionTabs);

    setActiveOptionTab((prev) => ({ ...prev, data: newOptionTabs[activeOptionTab.optionId].data }));
  };

  const handleFieldChange = ({ target: { name, value } }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newOptionTabs = [...optionTabs];
    newOptionTabs[activeOptionTab.optionId].data = {
      ...newOptionTabs[activeOptionTab.optionId].data,
      [name]: value
    };
    setActiveOptionTab((prev) => ({
      ...prev,
      data: newOptionTabs[activeOptionTab.optionId].data
    }));
    setOptionTabs(newOptionTabs);
  };


  const allUploadedFiles: { url: string; flagForJob: boolean; addToProposal: boolean }[] = useMemo(() => {
    if (activeOptionTab.data.files === undefined) return [];
    return activeOptionTab.data.files;
  }, [activeOptionTab.data.files]);

  useEffect(() => {
    return () => {
      timeout.current && clearTimeout(timeout.current);
    };
  }, []);

  function handleFlagImage(singleFile: { url: string; flagForJob: boolean }) {
    const newOptionTabs = [...optionTabs];
    newOptionTabs[activeOptionTab.optionId].data = {
      ...newOptionTabs[activeOptionTab.optionId].data,
      files: newOptionTabs[activeOptionTab.optionId].data?.files?.map((file, findex) => {
        if (file.url === singleFile.url) {
          return { ...file, flagForJob: !file.flagForJob };
        }
        return file;
      })
    };
    setOptionTabs(newOptionTabs);
    setActiveOptionTab((prev) => ({ ...prev, data: newOptionTabs[activeOptionTab.optionId].data }));
    setImageMenuAnchor((prev) => {
      return {
        anchor: prev.anchor,
        open: false,
        data: prev.data
      };
    });
  }

  function handleAddToProposal(singleFile: { url: string; addToProposal: boolean }) {
    const newOptionTabs = [...optionTabs];
    newOptionTabs[activeOptionTab.optionId].data = {
      ...newOptionTabs[activeOptionTab.optionId].data,
      files: newOptionTabs[activeOptionTab.optionId].data?.files?.map((file, findex) => {
        if (file.url === singleFile.url) {
          return { ...file, addToProposal: !file.addToProposal };
        }
        return file;
      })
    };
    setOptionTabs(newOptionTabs);
    setActiveOptionTab((prev) => ({ ...prev, data: newOptionTabs[activeOptionTab.optionId].data }));
    setImageMenuAnchor((prev) => {
      return {
        anchor: prev.anchor,
        open: false,
        data: prev.data
      };
    });
  }

  return (
    <div className="flex flex-col w-full">
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        sx={{
          backgroundColor: theme.palette.grey[100],
          display: 'flex',
          flexDirection: 'column-reverse'
        }}
        value={activeOptionTab.optionId}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="estimate options"
      >
        {optionTabs.map((eachTab, eachTabIndex) => (
          <div key={eachTab.optionId} className="flex items-center" style={{ position: 'relative' }}>
            <Tab
              sx={{
                color: eachTab.optionId === activeOptionTab.optionId ? theme.palette.primary.lighter : 'primary.main',
                padding: 0,
                paddingX: 1,
                backgroundColor: eachTab.optionId === activeOptionTab.optionId ? theme.palette.primary.main : 'inherit',
                borderRadius: 0
              }}
              value={eachTab.optionId}
              label={
                selectedOptionToBeEdited === eachTabIndex ? (
                  <div className="space-x-2 flex items-center">
                    <input
                      className="outline-none border-none p-3 bg-transparent max-w-sm place-content-start "
                      autoFocus
                      value={optionNewName ?? eachTab.title}
                      onFocus={(event) => {
                        event.target.select();
                      }}
                      onChange={(event) => {
                        setOptionNewName(event.target.value);
                      }}
                    />
                    <IconButton
                      onClick={() => {
                        handleOptionNameSave(eachTabIndex);
                      }}
                    >
                      <SaveOutlined />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setSelectedOptionToBeEdited(null as unknown as number);
                      }}
                    >
                      <CloseOutlined />
                    </IconButton>
                  </div>
                ) : (
                  eachTab.title
                )
              }
              onClick={() => handleTabChange(eachTab.optionId)}
              onDoubleClick={() => {
                setSelectedOptionToBeEdited(eachTab.optionId);
              }}
            />

            {eachTab.optionId !== 0 && selectedOptionToBeEdited !== eachTab.optionId && (
              <DeleteOutlined
                style={{ position: 'absolute', top: '3px', right: '1px', color: 'red' }}
                onClick={() => deleteOptionTab(eachTab.optionId)}
              />
            )}
          </div>
        ))}
        {!!props.previousStates && !props.previousStates.includes('proposal_approved') && (
          <CustomTooltip message="Add option">
            <Icon
              className="cursor-pointer hover:translate-x-1 ease-in-out duration-100 flex items-center justify-center"
              color="primary"
              sx={{ width: 50, height: 50 }}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                setOpenAddOption((prev) => !prev);
              }}
            >
              <SubnodeOutlined />
            </Icon>
          </CustomTooltip>
        )}
        {openAddOption && (
          <div className="flex gap-2 my-2">
            <Button variant="outlined" size="small" onClick={handleAddNewOption}>
              Add New Option
            </Button>
            <Button variant="outlined" size="small" onClick={handleAddDuplicateOption}>
              Duplicate Option
            </Button>
          </div>
        )}
      </Tabs>

      {optionErrorAlert && (
        <Alert severity="error">
          <AlertTitle>You haven't selected any item in previous option.</AlertTitle>
        </Alert>
      )}
      <ErrorBoundary>
        <Grid container spacing={2.5} className="mt-2 sm:p-4 p-2">
          <Grid container item xs={12} spacing={2}>
            {/*---------------Add Media---------------------- */}
            <Grid item xs={12}>
                <input
                  type="file"
                  accept="image/*, video/*"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileUpload}
              />
              <input style={{ display: 'none' }} id="upload-file-options" type="file" multiple onChange={handleFileUpload} />

              <label htmlFor="upload-file-options" className="hidden md:block">
                <Button
                  variant="dashed"
                  color="primary"
                  component="span"
                  startIcon={isFileUploading && <LoadingOutlined />}
                  disabled={isFileUploading}
                >
                  Add Media
                </Button>
              </label>
            </Grid>
            {isFileUploading && (
                  <Grid item xs={12} sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center', alignItems: 'center', height: '40px' }}>
                    <CircularProgress size={24} />
                  </Grid>
                )}
            {!!allUploadedFiles?.length && (
              <Grid item xs={12}>
                <Accordion classes={{ root: 'bg-transparent min-h-max overflow-y-hidden' }} defaultExpanded>
                  <AccordionSummary aria-controls="panel1-content" id="panel1-header">
                    View Uploaded Media {allUploadedFiles.length > 0 && `( ${allUploadedFiles.length} )`}
                  </AccordionSummary>
                  <AccordionDetails classes={{ root: 'min-h-max flex sm:flex-row flex-col gap-2 max-w-full overflow-x-auto' }}>

                    {allUploadedFiles.map((singleFile, index: number) => {
                      const mimeType = mime.getType(singleFile.url)?.split('/');

                      return (
                        <>
                          {imageIndex === index &&<div ref={containerRef} style={{ display: 'inline-block' }}>
                            {drawingImageIndex !== null && isImageLoaded && (
                              <ImageListItem
                                ref={imageListItemRef}
                                className="flex items-center justify-start my-2 !bg-gray-100 rounded-md border pt-[100px]"
                                sx={{ height: 'auto', minWidth: '200px', maxHeight: '300px' }}
                              >
                                <Stage
                                  ref={stageRef}
                                  width={stageSize.width}
                                  height={stageSize.height}
                                  onMouseDown={handleMouseDown}
                                  onMouseMove={handleMouseMove}
                                  onMouseUp={handleMouseUp}
                                  onTouchStart={handleTouchStart}
                                  onTouchMove={handleTouchMove}
                                  onTouchEnd={handleTouchEnd}
                                  onTouchCancel={handleTouchEnd}
                                >
                                  <Layer>
                                    {imageObj && <Image image={imageObj} width={stageSize.width} height={stageSize.height} />}
                                    {lines.map((line, i) => (
                                      <Line
                                        key={i}
                                        points={line.points}
                                        stroke={line.stroke}
                                        strokeWidth={2}
                                        tension={0.5}
                                        lineCap="round"
                                        globalCompositeOperation={isDrawing ? 'source-over' : 'source-over'}
                                      />
                                    ))}
                                  </Layer>
                                </Stage>
                                <ImageListItemBar
                                  position="top"
                                  className="bg-transparent"
                                  actionIcon={
                                    <ButtonGroup variant="contained">
                                      <CustomTooltip message="Delete Drawing">
                                        <IconButton
                                          size="large"
                                          onClick={handleDeleteDrawing}
                                          className="bg-red-500 hover:bg-red-500 focus:bg-red-500 text-white rounded-none"
                                        >
                                          <DeleteOutlined />
                                        </IconButton>
                                      </CustomTooltip>
                                      <CustomTooltip message="Pick Color">
                                      <div className="relative">
                                        <IconButton
                                          size="large"
                                          onClick={() => setShowPicker(!showPicker)}
                                          className="bg-blue-500 hover:bg-blue-500 focus:bg-blue-500 text-white rounded-none ml-2"
                                        >
                                          🎨
                                        </IconButton>
                                        {showPicker && (
                                          <div className="absolute top-full left-0 z-10 bg-gray-200 p-1 shadow-lg rounded">
                                            <TwitterPicker
                                              triangle="hide"
                                              colors={presetColors}
                                              color={strokeColor}
                                              onChangeComplete={(color) => {
                                                setStrokeColor(color.hex);
                                                setShowPicker(false);
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                      </CustomTooltip>
                                      <CustomTooltip message="Save Drawing">
                                      <IconButton
                                        size="large"
                                        onClick={()=>handleSaveDrawing(index)}
                                        className="bg-green-500 hover:bg-green-500 focus:bg-green-500 text-white rounded-none ml-2"
                                      >
                                        <SaveOutlined />
                                      </IconButton>
                                      </CustomTooltip>
                                      <CustomTooltip message="Close Drawing Window">
                                      <IconButton
                                        size="large"
                                        onClick={handleCancelDrawing}
                                        className="bg-gray-400 hover:bg-gray-400 focus:bg-gray-400 text-white rounded-none ml-2"
                                      >
                                        <CloseOutlined />
                                      </IconButton>
                                      </CustomTooltip>
                                    </ButtonGroup>
                                  }
                                  actionPosition="right"
                                />
                              </ImageListItem>
                            )}
                          </div>}
                          {imageIndex !== index &&<ImageListItem
                          key={index}
                          className="flex items-center justify-start my-2 !bg-gray-100 rounded-md border pt-[48px]"
                          sx={{ height: '300px', minWidth: '200px', paddingTop: mimeType?.[0] === 'audio' ? '35px' : '0px' }}
                        >
                          {universalRenerMedia(singleFile.url, index)}
                          <ImageListItemBar
                            position="top"
                            className="bg-transparent"
                            actionIcon={
                              <ButtonGroup variant="contained">
                                <CustomTooltip message="Delete Media">
                                <IconButton
                                  size="large"
                                  onClick={() => deleteMedia(index)}
                                  className="bg-red-500 hover:bg-red-500 focus:bg-red-500 text-white rounded-none"
                                >
                                  <DeleteOutlined />
                                </IconButton>
                                </CustomTooltip>
                                <CustomTooltip message="Show Image">
                                  <IconButton
                                    LinkComponent={'a'}
                                    size="large"
                                    className="bg-teal-500 hover:bg-teal-500 focus:bg-teal-500 text-white rounded-none"
                                    href={singleFile.url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <EyeFilled />
                                  </IconButton>
                                </CustomTooltip>
                                <CustomTooltip message="More Options">
                                <IconButton
                                  id={`image-menu-${index}`}
                                  size="large"
                                  className="bg-primary-main hover:bg-primary-main focus:bg-primary-main text-white text-lg rounded-none"
                                  onClick={(event) => {
                                    setImageMenuAnchor((prev) => {
                                      if (prev.open && prev.anchor === event.currentTarget) {
                                        return { ...prev, open: false };
                                      }
                                      return {
                                        anchor: event.currentTarget?event.currentTarget:prev.anchor,
                                        open: true,
                                        data: { file: singleFile, index }
                                      };
                                    });
                                  }}
                                >
                                  <MoreOutlined />
                                </IconButton>
                                </CustomTooltip>
                              </ButtonGroup>
                            }
                            actionPosition="right"
                          />
                          </ImageListItem>}
                        </>

                      );
                    })}
                  </AccordionDetails>
                </Accordion>
                    <Popper
                      sx={{ zIndex: 99999, minWidth: '200px' }}
                      open={imageMenuAnchor.open}
                      anchorEl={imageMenuAnchor.anchor}
                      disablePortal={false}
                      placement={'bottom-end'}
                      transition
                      key={imageMenuAnchor.data?.file.url}
                    >
                      {({ TransitionProps }) => (
                        <Collapse unmountOnExit appear {...TransitionProps} timeout={100}>
                          <ClickAwayListener
                            onClickAway={() => {
                              setImageMenuAnchor((prev) => {
                                return {
                                  anchor: prev.anchor,
                                  open: false,
                                  data: prev.data
                                };
                              });
                            }}
                          >
                            <List component={Paper}>
                            {/* <ListItemButton className = "hidden md:block" onClick={() => fileInputRef.current?.click()}>
                              Take another picture
                            </ListItemButton> */}
                            <ListItemButton onClick={() => handleLoadImage(imageMenuAnchor.data?.file.url,imageMenuAnchor.data?.index)}>
                                Draw on picture
                            </ListItemButton>
                              <ListItemButton divider onClick={() => handleAddToProposal(imageMenuAnchor.data?.file)}>
                                {imageMenuAnchor.data?.file.addToProposal ? 'Remove from proposal' : 'Add to proposal'}
                              </ListItemButton>
                              <ListItemButton divider onClick={() => handleFlagImage(imageMenuAnchor.data?.file)}>
                                {imageMenuAnchor.data?.file.flagForJob ? 'Remove flag image' : 'Flag for job'}
                              </ListItemButton>                          
                            </List>
                          </ClickAwayListener>
                        </Collapse>
                      )}
                    </Popper>
              </Grid>
            )}

            {/*---------------Notes that will be displayed for this option on the proposal---------------------- */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="proposal_note">Notes that will be displayed for this option on the proposal</InputLabel>
                <TextField
                  id="estimate_option_proposal_note"
                  fullWidth
                  placeholder=""
                  name="proposal_note"
                  multiline
                  rows={2}
                  onChange={handleFieldChange}
                  value={activeOptionTab.data.proposal_note}
                />
              </Stack>
            </Grid>

            {/*---------------Estimate Notes---------------------- */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="estimate_note">Estimate Notes</InputLabel>
                <TextField
                  id="estimate_note"
                  fullWidth
                  placeholder=""
                  name="estimate_note"
                  multiline
                  rows={2}
                  onChange={handleFieldChange}
                  value={activeOptionTab.data.estimate_note}
                />
              </Stack>
            </Grid>
          </Grid>
          {/*---------------Job Categories---------------------- */}

          <Grid item xs={12} className="mt-6">
            {!!allJobCategories.length ? (
              <>
                <Typography variant="h5" padding={2}>
                  Job Categories:
                </Typography>
                <motion.div
                  variants={motionContainer}
                  initial="hidden"
                  animate="visible"
                  className="flex flex-wrap gap-3 md:flex-row flex-col"
                >
                  {allJobCategories.map((eachCategory, index) => (
                    <motion.div key={index} variants={motionItem}>
                      <JobCategoryCards data={eachCategory} onClick={handleJobCategoryClick} />
                    </motion.div>
                  ))}
                </motion.div>
              </>
            ) : (
              <div className="w-full">
                <Alert
                  severity="error"
                  action={
                    <Button onClick={() => navigate('/settings/categories-product-services/job-categories')}>Add Job Categories</Button>
                  }
                >
                  No job categories added.
                </Alert>
              </div>
            )}
          </Grid>
          {!!activeOptionTab && (
            <Grid item xs={12} className="mt-6">
              <CategoryItemDisplayTable
                isAppointmentJob={props.isAppointmentJob}
                handleDeleteCategory={handleDeleteCategory}
                handleDeleteItem={handleDeleteItem}
                data={activeOptionTab}
                handleEditItem={handleEditItem}
                taxRate={props.defaultTaxRate}
              />
            </Grid>
          )}

          {!!activeOptionTab.data.items?.length && (
            <Grid item xs={12}>
              <div className="flex justify-end items-end bg-gray-50 rounded ">{renderTotalAmountSection()}</div>
            </Grid>
          )}

          {clickedJobCategory && (
            <UniversalDialog
              title={clickedJobCategory.categoryTitle}
              action={{ open: clickedJobCategory.categoryTitle.length > 0, maxWidth: 'md', fullScreen: isMobile, fullWidth: !isMobile }}
              primaryButonTitle="Add Job Item"
              hasSecondaryButton={false}
              hasPrimaryButton={false}
              onClose={closeAddItemsDialog}
            >
              <AddJobItemForm
                handleJobItems={handleAddItems}
                onAddJobItem={closeAddItemsDialog}
                categoryInfo={clickedJobCategory}
                taxRate={props.defaultTaxRate as number}
              />
            </UniversalDialog>
          )}
        </Grid>
      </ErrorBoundary>
    </div>
  );
};

const SummarySection = ({ label, value, bold = false }: { label: string; value: number; bold?: boolean }) => (
  <Stack direction={'row'} className="justify-between" spacing={5}>
    <Typography align="right" className="font-bold">
      {label}
    </Typography>
    <Typography align="right">{formateData(value ?? 0, '$')}</Typography>
  </Stack>
);

export default EstimateOptions;
