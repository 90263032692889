import { InboxOutlined } from '@ant-design/icons';
import { Autocomplete, CardContent, Stack, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import ProductPlaceholder from 'components/cards/skeleton/ProductPlaceholder';
import useAuth from 'hooks/useAuth';
import moment from 'moment';
import { TDateFilter, TSalesTax } from 'pages/Dashboard/types/types.dashboard';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import DashboardServicesInstance from 'services/services.dashboard';
import ErrorBoundary from 'utils/ErrorHandlers/ErrorBoundary';
import { reportFilter, reportFilterName } from 'utils/constants';
import ReportUnauthorizedPage from 'utils/permission-guard/ReportUnauthorizedPage';
import AccountsReceivableReport from './ReportFilters/AccountsReceivableReport';
import CompletedProjectReport from './ReportFilters/CompletedProjectReport';
import ExpenseReport from './ReportFilters/ExpenseReport';
import GpsCoordinatesReport from './ReportFilters/GpsCoordinatesReport';
import GrossIncomeReport from './ReportFilters/GrossIncomeReport';
import JobsAssignedReport from './ReportFilters/JobsAssignedReport';
import JobsCompletedReport from './ReportFilters/JobsCompletedReport';
import LeadSourcesReport from './ReportFilters/LeadSourcesReport';
import MaterialAndSupplies from './ReportFilters/MaterialAndSupplies';
import OfficeAdministratorReport from './ReportFilters/OfficeAdministratorReport';
import OpenInvoicesReport from './ReportFilters/OpenInvoicesReport';
import PayRollReport from './ReportFilters/PayRollReport';
import SalesTaxReport from './ReportFilters/SalesTaxReport';
import ServiceReport from './ReportFilters/ServiceReport';
import TotalInvoiceReport from './ReportFilters/TotalInvoiceReport';
import {
  TCompletedProject,
  TExpenseReport,
  TFieldWorkerJobReport,
  TGpsCoordinates,
  TGrossIncomeReport,
  TMaterialAndSupplies,
  TOfficeAdministratorReport,
  TOpenInvoicesReport,
  TServiceReport,
  TTotalInvoiceReport
} from './types/types.report';

const filterValue = (reportFilter?: string) => {
  switch (reportFilter) {
    case 'sales_tax_report':
      return 'date_range';
    case 'customer_lead_sources':
    case 'total_invoice_report':
      return 'all';
    case 'field_worker_jobs_completed':
    case 'field_worker_jobs_assigned':
    case 'accounts_receivable_report':
    case 'office_administrator_report':
    case 'completed_projects':
      return 'today';
    default:
      return 'this_year';
  }
};
const ReportsPage = () => {
  //---------------------------Constants-----------------
  const navigate = useNavigate();
  const { report_filter } = useParams();
  const { user } = useAuth();
  const [salesTaxDateFilter, setSalesTaxDateFilter] = useState<{ year: number; quater: string }>({ year: moment().year(), quater: 'year' });
  const [selectedFilter, setSelectedFilter] = useState<TDateFilter>({
    start_date: report_filter === 'sales_tax_report' ? moment().startOf('year').toISOString() : moment().toISOString(),
    end_date: report_filter && report_filter === 'sales_tax_report' ? moment().endOf('year').toISOString() : moment().toISOString(),
    filter_value: filterValue(report_filter),
    is_completed: true
  });
  //--------------------useQuery----------------------

  // todo: @Harsh517-tech415 The api calling should be moved to individual Components to maintain Single Responsibility
  const {
    data: reportData,
    isFetching: isReportDataFetching,
    refetch: refetchReportdata
  } = useQuery({
    queryKey: ['report_data', selectedFilter, report_filter],
    queryFn: () => DashboardServicesInstance.reportData(reportFilterName[report_filter as keyof typeof reportFilterName], selectedFilter),
    enabled: !!reportFilterName[report_filter as keyof typeof reportFilterName],
    refetchOnWindowFocus: false
  });

  //-----------------------useEffects------------------
  useEffect(() => {
    if (reportFilterName[report_filter as keyof typeof reportFilterName] === undefined) {
      const reportRoute =
        reportFilter.find((eachReport) => user?.permissions?.includes(reportFilterName[eachReport.value as keyof typeof reportFilterName]))
          ?.value ?? '';
      navigate(`/reports/${reportRoute}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report_filter]);

  //--------------------------Handlers-------------------
  const renderReport = (reportData: unknown) => {
    if (!!user?.role?.length && user.permissions?.includes(reportFilterName[report_filter as keyof typeof reportFilterName])) {
      if (isReportDataFetching) {
        return <ProductPlaceholder />;
      } else if (!reportData || Object.keys(reportData).length === 0) {
        return (
          <CardContent className="flex flex-col items-center w-full min-h-max justify-center pt-12 gap-2">
            <InboxOutlined style={{ height: 20, transform: 'scale(5)', color: 'GrayText' }} />
            <Typography color={'GrayText'}>No Data</Typography>
          </CardContent>
        );
      } else if (!isReportDataFetching && reportData !== undefined) {
        switch (report_filter) {
          case 'gross_income_report': {
            return (
              <GrossIncomeReport
                isReportDataFetching={isReportDataFetching}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                reportData={reportData as TGrossIncomeReport}
              />
            );
          }

          case 'total_invoice_report': {
            return (
              <TotalInvoiceReport
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                reportData={reportData as TTotalInvoiceReport}
              />
            );
          }
          case 'open_invoice_report':
            return <OpenInvoicesReport reportData={reportData as TOpenInvoicesReport} />;

          case 'expenses_report':
            return (
              <ExpenseReport
                reportData={reportData as TExpenseReport}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
              />
            );

          case 'customer_lead_sources':
            return (
              <LeadSourcesReport
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                reportData={reportData as { [key: string]: number }}
              />
            );

          case 'field_worker_jobs_completed': {
            return (
              <JobsCompletedReport
                reportData={reportData as TFieldWorkerJobReport}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
              />
            );
          }

          case 'field_worker_jobs_assigned': {
            return (
              <JobsAssignedReport
                reportData={reportData as TFieldWorkerJobReport}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
              />
            );
          }

          case 'office_administrator_report':
            return (
              <OfficeAdministratorReport
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                reportData={reportData as TOfficeAdministratorReport}
              />
            );

          case 'gps_coordinate_report':
            return (
              <GpsCoordinatesReport
                reportData={reportData as TGpsCoordinates[]}
                isReportDataFetching={isReportDataFetching}
                refetchReportdata={refetchReportdata}
              />
            );
          case 'services_report':
            return (
              <ServiceReport
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                reportData={reportData as TServiceReport}
              />
            );
          case 'material_report':
            return (
              <MaterialAndSupplies
                isReportDataFetching={isReportDataFetching}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                reportData={reportData as TMaterialAndSupplies}
                refetchReportdata={refetchReportdata}
              />
            );
          case 'sales_tax_report':
            return (
              <SalesTaxReport
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                reportData={reportData as TSalesTax}
                salesTaxDateFilter={salesTaxDateFilter}
                setSalesTaxDateFilter={setSalesTaxDateFilter}
              />
            );
          case 'payroll_report':
            return (
              <PayRollReport
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                reportData={reportData as { name: string; amount: number }[]}
              />
            );
          case 'completed_projects':
            return (
              <CompletedProjectReport
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                reportData={reportData as TCompletedProject}
              />
            );
          case 'accounts_receivable_report':
            return (
              <AccountsReceivableReport
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                reportData={reportData as { [key: string]: number }}
              />
            );
        }
      }
    } else
      return (
        <ReportUnauthorizedPage
          reportName={
            reportFilter.find((eachReport) =>
              user?.permissions?.includes(reportFilterName[eachReport.value as keyof typeof reportFilterName])
            )?.value ?? ''
          }
        />
      );
  };

  const handleReportFilterChange = (event: SyntheticEvent<Element, Event>, newValue: { label: string; value: string }) => {
    navigate(`/reports/${newValue.value}`);
    setSelectedFilter({
      start_date: report_filter === 'sales_tax_report' ? moment().startOf('year').toISOString() : moment().toISOString(),
      end_date: report_filter && report_filter === 'sales_tax_report' ? moment().endOf('year').toISOString() : moment().toISOString(),
      filter_value: newValue.value && filterValue(newValue.value),
      is_completed: true
    });
  };

  return (
    <Stack spacing={3}>
      <Autocomplete
        id="reports"
        value={
          reportFilter.find((singleItem) => {
            return report_filter === singleItem.value;
          }) || { label: '', value: '' }
        }
        disableClearable
        isOptionEqualToValue={(option, optionValue) => {
          return option.value === optionValue.value;
        }}
        getOptionLabel={(option) => option.label}
        onChange={handleReportFilterChange}
        options={reportFilter.filter((eachReport) =>
          user?.permissions?.includes(reportFilterName[eachReport.value as keyof typeof reportFilterName])
        )}
        renderInput={(params) => <TextField required {...params} />}
      />
      <ErrorBoundary>{renderReport(reportData)}</ErrorBoundary>
    </Stack>
  );
};

export default ReportsPage;
