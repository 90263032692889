import { Card, CardContent, Skeleton, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { TDashboardWidgetCard } from '../types/types.dashboard';

const DashboardWidgetCard = ({
  dashboardData,
  isDashboardWidgetDataFetched
}: {
  dashboardData?: TDashboardWidgetCard[];
  isDashboardWidgetDataFetched: boolean;
}) => {
  return (
    <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-2">
      {isDashboardWidgetDataFetched
        ? dashboardData?.map((singleCardData: TDashboardWidgetCard, index: number) => (
            <DashboardSingleCard key={index} {...singleCardData} />
          ))
        : [1, 2, 3, 4].map(() => (
            <CardContent className="space-y-3 flex flex-col items-center justify-between h-full" component={Card}>
              <Skeleton variant="text" className="w-full" />
              {/* ----------------------INFO------------------------ */}
              <div className={`w-full flex items-center md:justify-around justify-center`}>
                {[1, 2, 3].map(() => (
                  <Skeleton variant="rounded" width={120} height={60} />
                ))}
              </div>
            </CardContent>
          ))}
    </div>
  );
};

const DashboardSingleCard = ({ title, background_color, info }: TDashboardWidgetCard) => {
  const navigate = useNavigate();
  return (
    <Card
      className="shadow-md rounded-sm h-full !p-2 transition-all ease-in duration-300"
      sx={{
        color: '#fff',
        background: `${background_color}`
      }}
    >
      <CardContent className="space-y-3 flex flex-col items-center justify-between h-full !p-3">
        <Typography
          onClick={() => {
            switch (title) {
              case 'Follow Ups':
                navigate('/customers?followup_field=0');
                break;
              case 'Open Estimates':
                navigate('/jobs?type=estimate&field=0');
                break;
              case 'Open Work Orders':
                navigate('/jobs?type=work_order&field=0');
                break;
              case 'Open Invoices':
                navigate(`/invoices?type=open_invoice&field=0`);
                break;
            }
          }}
          className={`${'transition-all duration-300 hover:underline underline-offset-4 cursor-pointer'} font-bold  text-center text-lg`}
        >
          {title}
        </Typography>
        {/* ----------------------INFO------------------------ */}
        <div className={`w-full flex items-center justify-`}>
          {info?.map((singleInfo: { title: string; data: number; redirect_url: string }, index) => {
            return (
              Object.keys(singleInfo).length && (
                <div
                  className={'cursor-pointer flex flex-row items-center justify-center w-full'}
                  onClick={() => {
                    switch (title) {
                      case 'Follow Ups':
                        navigate(`/customers?followup_field=${index + 1}`);
                        break;
                      case 'Open Estimates':
                        navigate(`/jobs?type=estimate&field=${index + 1}`);
                        break;
                      case 'Open Work Orders':
                        navigate(`/jobs?type=work_order&field=${index + 1}`);
                        break;
                      case 'Open Invoices':
                        navigate(`/invoices?type=open_invoice&field=${index + 1}`);
                        break;
                    }
                  }}
                  key={index}
                >
                  <div className="flex flex-col items-center hover:scale-[1.08] transition-all ease-in-out w-full hover:bg-gray-300/20 rounded-sm">
                    <Typography className="text-[12px]">{singleInfo.title}</Typography>
                    <Typography className="text-2xl font-semibold">{singleInfo.data}</Typography>
                  </div>
                </div>
              )
            );
          })}
        </div>
      </CardContent>
    </Card>
  );
};

export default DashboardWidgetCard;
