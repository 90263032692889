import { createSlice } from '@reduxjs/toolkit';

// types
type TCustomerAction = {
  action_title: string;
};

// initial state
const initialState: TCustomerAction = {
  action_title: ''
};

// ==============================|| SLICE ||============================== //

const customerActions = createSlice({
  name: 'customer_actions',
  initialState,
  reducers: {
    setActionTitle(state, action) {
      state.action_title = action.payload;
    }
  }
});

export default customerActions.reducer;

export const { setActionTitle } = customerActions.actions;
