import { WarningOutlined } from '@ant-design/icons';
import { IconButton, Paper, Skeleton, Tab, Tabs, TextField, Typography, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import ActionButtonsGroup from 'components/buttons/ActionButtonsGroup/ActionButtonsGroup';
import { TAvailableActionButtons } from 'components/buttons/ActionButtonsGroup/types.actionButtonGroup';
import CustomerQuickActions from 'components/buttons/CustomerQuickActions';
import ProductPlaceholder from 'components/cards/skeleton/ProductPlaceholder';
import CustomTooltip from 'components/CustomTooltip';
import Media from 'components/Media';
import AddActionPopup from 'components/popups/AddActionPopup';
import AddCutsomerPopup from 'components/popups/AddCutsomerPopup';
import AddEmailorSms from 'components/popups/AddEmailorSms';
import DeleteConfirmationPoup from 'components/popups/DeleteConfirmationPopup';
import UniversalDialog from 'components/popups/UniversalDialog';
import useAuth from 'hooks/useAuth';
import { TAddCustomerPopup } from 'pages/customers/types/type.customersPage';
import FollowupPage from 'pages/followup/FollowupPage';
import { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { dispatch, useSelector } from 'store';
import { setCustomerDetils } from 'store/reducers/customReducers/slice.customer';
import { setFollowUpCustomer } from 'store/reducers/customReducers/slice.followUps';
import { getTabSummary, updateTabSummary } from 'store/reducers/customReducers/slice.tabSummary';
import { IDeletePopup } from 'types/globalTypes/type.deleteDialog';
import { formatePhonesData, getGoogleEarthUrl, getGoogleMapsDirectionsURL } from 'utils/common';
import { constants } from 'utils/constants';
import ErrorBoundary from 'utils/ErrorHandlers/ErrorBoundary';
import CustomerServicesInstance from '../../services/services.customers';
import ContactDetails from './CustomerViewTabs/ContactDetails';
import CustomerBilling from './CustomerViewTabs/CustomerBilling';
import CustomerJobInvoices from './CustomerViewTabs/CustomerJobInvoices';
import CustomerJobsDetail from './CustomerViewTabs/CustomerJobsDetail';
import CustomerLogs from './CustomerViewTabs/CustomerLogs';
import EmailSMS from './CustomerViewTabs/EmailSMS';
import CustomerNotes from './CustomerViewTabs/Notes';
import { TCustomerData } from './types/type.customersPage';
import { TCustomerAddEmailSMSPopup, TCustomerViewTab } from './types/type.customersView';

const QuickCustomerData = lazy(() => import('./components/QuickCustomerData'));

const CustomerView = () => {
  //--------------------------constants------------------------
  const navigate = useNavigate();
  const { user } = useAuth();
  const theme = useTheme();
  const { id: customerId, tab } = useParams();
  const state = useSelector((state) => state.tabSummaryOption);
  const availableTabs = [
    { title: 'Contact', value: 'contact' },
    { title: 'Billing', value: 'customer-billing' },
    { title: 'Notes', value: 'notes' },
    { title: 'Emails/SMS', value: 'emails_sms' },
    { title: 'Follow Ups', value: 'follow_ups' },
    { title: 'Logs', value: 'logs' },
    { title: 'Jobs', value: 'customer-jobs' },
    { title: 'Invoices', value: 'customer-jobs-invoices' },
    { title: 'Media', value: 'media' }
  ];
  const [addCustomerPopup, setAddCustomerPopup] = useState<TAddCustomerPopup>({ action: { open: false }, data: null });
  const [addActionPopup, setAddActionPopup] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>('contact');
  const [customerData, setCustomerData] = useState<TCustomerData>();
  const [addEmailOrSmsPopup, setAddEmailOrSmsPopup] = useState<TCustomerAddEmailSMSPopup>({
    action: { open: false, isEmail: true, showJobName: true },
    data: {}
  });

  const [actionButtons, setActionButtons] = useState<TAvailableActionButtons[]>();

  const [userCoordinates, setUserCoordinates] = useState<{ lat: string | number; lng: string | number }>({ lat: '', lng: '' });

  const [deleteDialog, setDeleteDialog] = useState<IDeletePopup>({
    action: { open: false, reasonRequired: true },
    data: { subjectToBeDeleted: '' }
  });

  const [suspendCustomerReason, setSuspendCustomerReason] = useState<{ state: boolean; inactive_reason: string; customerId: string }>({
    state: false,
    inactive_reason: '',
    customerId: ''
  });

  //--------------------------useQuery------------------------
  const {
    data: fetchedCustomerData,
    isFetched: iscustomerDataFetched,
    isFetching: isCustomerDataFetching,
    refetch: refetchCustomers
  } = useQuery({
    queryKey: ['customer_view', customerId],
    queryFn: () => CustomerServicesInstance.getSingleCustomer(`${customerId}`),
    enabled: !!customerId
  });

  const { data: followUpsCount, isFetching: followupsFetching } = useQuery({
    queryKey: ['customer_followup_data', customerId],
    queryFn: () => CustomerServicesInstance.getQuickCustomerData(customerId as string, 'followup'),
    enabled: !!customerId
  });

  //--------------------------useEffect------------------------

  useEffect(() => {
    if (fetchedCustomerData) {
      setCustomerData(fetchedCustomerData);
      dispatch(setFollowUpCustomer({ id: fetchedCustomerData?._id, name: fetchedCustomerData?.first_name }));

      dispatch(
        setCustomerDetils({
          view: true,
          selectedCustomer: {
            id: fetchedCustomerData?._id as string,
            name: `${fetchedCustomerData.first_name} ${fetchedCustomerData.last_name}`
          }
        })
      );

      if (fetchedCustomerData.status === 1) {
        setActionButtons(() => ['edit', 'add_action', 'suspend']);
        if (user?.permissions && user?.permissions.includes('deleteCustomer')) {
          setActionButtons((prev) => (prev ? [...prev, 'delete'] : ['delete']));
        }
      } else {
        setActionButtons(() => ['unsuspend']);
      }
    }
    return () => {
      dispatch(
        setCustomerDetils({
          view: false,
          selectedCustomer: { id: '', name: '' }
        })
      );
    };
  }, [fetchedCustomerData]);

  useEffect(() => {
    setSelectedTab(tab ?? 'contact');
  }, [tab]);

  useEffect(() => {
    if (iscustomerDataFetched && !!customerData?._id && customerData?._id?.length > 0) {
      dispatch(getTabSummary({ module: 'customer', id: customerData?._id }));
    }
  }, [customerData, iscustomerDataFetched]);

  useEffect(() => {
    const navTimeout = setTimeout(function () {
      if (navigator.geolocation) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function (position) {
            setUserCoordinates({ lat: position.coords.latitude, lng: position.coords.longitude });
          });
        }
      } else {
        console.log('No Geolocation Support.');
      }
    }, 1000);

    return () => {
      clearTimeout(navTimeout);
      refetchCustomers();
      dispatch(updateTabSummary({}));
    };
  }, []);

  //--------------------------handlers------------------------

  const handleTabChange = (tabValue: string) => {
    customerId && dispatch(getTabSummary({ module: 'customer', id: customerId }));
    navigate(`/customers/view/${customerId}/${tabValue}`);
  };

  const renderTab = () => {
    switch (selectedTab) {
      case 'contact':
        return (
          <ErrorBoundary>
            <ContactDetails data={customerData} />
          </ErrorBoundary>
        );
      case 'customer-billing':
        return (
          customerData?.billing_contact && (
            <ErrorBoundary>
              <CustomerBilling data={{ ...customerData?.billing_contact, _id: customerData?._id }} />
            </ErrorBoundary>
          )
        );
      case 'notes':
        return (
          <ErrorBoundary>
            {fetchedCustomerData && <CustomerNotes onNoteUpdate={refetchCustomers} customerData={fetchedCustomerData as TCustomerData} />}
          </ErrorBoundary>
        );
      case 'emails_sms':
        return (
          <ErrorBoundary>
            {!!customerData && (
              <EmailSMS
                customer={{
                  first_name: customerData.first_name,
                  last_name: customerData.last_name,
                  emails: customerData.emails,
                  phones: formatePhonesData(customerData),
                  _id: customerData._id
                }}
                showFilter={true}
                showJobName={true}
                fromModule="customer"
              />
            )}
          </ErrorBoundary>
        );
      case 'follow_ups':
        return (
          <ErrorBoundary>
            {!!fetchedCustomerData && (
              <FollowupPage
                customerData={{
                  id: fetchedCustomerData?._id as string,
                  name: `${fetchedCustomerData?.first_name} ${fetchedCustomerData?.last_name}`,
                  phones: formatePhonesData(fetchedCustomerData),
                  emails: fetchedCustomerData?.emails
                }}
              />
            )}
          </ErrorBoundary>
        );
      case 'logs':
        return (
          <ErrorBoundary>
            {!!customerData?._id && (
              <CustomerLogs
                customer={{ id: customerData?._id as string, name: `${customerData?.first_name} ${customerData?.first_name}` }}
              />
            )}
          </ErrorBoundary>
        );
      case 'customer-jobs':
        return <ErrorBoundary>{customerData?._id && <CustomerJobsDetail customerId={customerData?._id as string} />}</ErrorBoundary>;
      case 'customer-jobs-invoices':
        return (
          <ErrorBoundary>
            <CustomerJobInvoices customerId={customerData?._id as string} />
          </ErrorBoundary>
        );
      case 'media':
        return (
          <ErrorBoundary>
            <Media module="customers" />
          </ErrorBoundary>
        );
      default:
        return <></>;
    }
  };

  const handleEditCustomer = (rowOriginal: TCustomerData) => {
    setAddCustomerPopup({ action: { open: true, isEditMode: true }, data: { selectedData: rowOriginal } });
  };
  const handleAddCustomer = (openState: boolean) => {
    setAddCustomerPopup({ action: { open: openState } });
  };

  /* *** Wrapping these function in useCallback to prevent unnecessary re-renders of children when this parent component re-renders *** */
  const handleAddEmailorSmsPopup = useCallback(() => {
    setAddEmailOrSmsPopup({
      action: { open: false, isEmail: true, showJobName: true },
      data: {}
    });
  }, []);

  const handleSms = useCallback(() => {
    setAddEmailOrSmsPopup({
      action: { open: true, isEmail: false, showJobName: true },
      data: {
        id: customerData?._id,
        name: `${customerData?.first_name} ${customerData?.last_name}`,
        phones: [
          {
            phone_country_code: customerData?.phone1_country_code as string,
            phone: customerData?.phone1 as string
          }
        ]
      }
    });
  }, [customerData, setAddEmailOrSmsPopup]);

  const handleMail = useCallback(() => {
    setAddEmailOrSmsPopup({
      action: { open: true, isEmail: true, showJobName: true },
      data: {
        id: customerData?._id,
        name: `${customerData?.first_name} ${customerData?.last_name}`,
        emails: customerData?.emails
      }
    });
  }, [customerData, setAddEmailOrSmsPopup]);

  const handleInfo = useCallback(() => {
    navigate(`/customers/view/${customerData?._id}/contact`);
  }, [navigate, customerData]);

  const handleCall = useCallback(() => {
    const phoneNumber = `+${customerData?.phone1_country_code}${customerData?.phone1}`;
    window.location.href = `tel:${phoneNumber}`;
  }, [customerData]);

  const handleGetDirection = useCallback(() => {
    const address = `${customerData?.address.address1} ${customerData?.address?.address2}, ${customerData?.address?.city}, ${customerData?.address?.state} ${customerData?.address?.zip}, ${customerData?.address?.country}`;
    if (!!userCoordinates.lat && !!userCoordinates.lng) {
      const directionUrl = getGoogleMapsDirectionsURL(userCoordinates.lat, userCoordinates.lng, address);
      window.open(directionUrl, '_blank')?.focus();
    }
  }, [customerData, userCoordinates]);

  const handleGoogleEarthSearch = useCallback(() => {
    const address = `${customerData?.address.address1} ${customerData?.address?.address2}, ${customerData?.address?.city}, ${customerData?.address?.state} ${customerData?.address?.zip}, ${customerData?.address?.country}`;
    const googleEarthUrl = getGoogleEarthUrl(address);
    window.open(googleEarthUrl, '_blank')?.focus();
  }, [customerData]);

  const handleMapViewSearch = useCallback(() => {
    const address = `${customerData?.address.address1} ${customerData?.address?.address2}, ${customerData?.address?.city}, ${customerData?.address?.state} ${customerData?.address?.zip}, ${customerData?.address?.country}`;
    const encodedAddress = encodeURIComponent(address);
    const mapsSearchURL = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
    window.open(mapsSearchURL, '_blank');
  }, [customerData]);

  const handleStreetView = useCallback(() => {
    const address = `${customerData?.address.address1} ${customerData?.address?.address2}, ${customerData?.address?.city}, ${customerData?.address?.state} ${customerData?.address?.zip}, ${customerData?.address?.country}`;
    const apiKey = constants.REACT_APP_GOOGLE_MAPS_API_KEY;

    const geoCodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;

    fetch(geoCodeUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'OK' && data.results.length > 0) {
          const result = data.results[0];
          const { lat, lng } = result.geometry.location;
          const streetViewURL = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat},${lng}`;
          window.open(streetViewURL, '_blank');
        } else {
          console.error('Geocoding API Error:', data.status, data.error_message);
        }
      })
      .catch((error) => console.error('Fetch Error:', error));
  }, [customerData]);

  const handleSuspendCustomer = async () => {
    const response = await CustomerServicesInstance.suspendCustomer(
      suspendCustomerReason.customerId,
      suspendCustomerReason.inactive_reason
    );
    if (response) {
      handleSuspendCustomerReasonDialog(false);
      navigate('/customers');
    }
  };

  const handleUnsuspendCustomer = async () => {
    await CustomerServicesInstance.unsuspendCustomer(customerId as string);
  };

  const handleSuspendCustomerReasonDialog = (state: boolean) => {
    setSuspendCustomerReason({ customerId: customerId as string, inactive_reason: '', state });
  };

  const handleSuspendCustomerReasonChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSuspendCustomerReason((prev) => ({ ...prev, inactive_reason: event.target.value }));
  };

  function handleActions(action: TAvailableActionButtons) {
    switch (action) {
      case 'add_action':
        setAddActionPopup(true);
        break;
      case 'edit':
        !!customerData && handleEditCustomer(customerData);
        break;
      case 'delete':
        handleDeleteCustomerAction('open', customerData);
        break;
      case 'suspend':
        handleSuspendCustomerReasonDialog(true);
        break;
      case 'unsuspend':
        handleUnsuspendCustomer();
        break;
      default:
        break;
    }
  }

  function handleDeleteCustomerAction(action: 'open' | 'close', customerData?: TCustomerData) {
    switch (action) {
      case 'open':
        setDeleteDialog({
          action: { open: true, maxWidth: 'xs', reasonRequired: true },
          data: { subjectToBeDeleted: customerData?.first_name ?? '', selectedData: customerData }
        });
        break;
      case 'close':
        setDeleteDialog({ action: { open: false, maxWidth: 'xs', reasonRequired: true } });
        break;
      default:
        break;
    }
  }

  async function handleDeleteSelectedCustomer(
    selectedCustomerToBeDeleted: string,
    selectedCustomerToBeDeletedFirstName?: string | undefined,
    reason?: string
  ) {
    if (reason) {
      const deleteResponse = await CustomerServicesInstance.deleteCustomer(customerId as string, reason, customerData?.first_name);
      if (deleteResponse) {
        handleDeleteCustomerAction('close');
        refetchCustomers();
      }
    }
    return true;
  }

  return (
    <Paper className="w-full h-full">
      {/* ---------- Customer Quick Actions ---------- */}
      <CustomerQuickActions
        containerClassName="absolute sm:top-[1.4rem] top-4 right-2 sm:left-[200px]"
        onCallClick={handleCall}
        onEmailClick={handleMail}
        onGetDirectionClick={handleGetDirection}
        onSmsClick={handleSms}
        onInfoClick={handleInfo}
        onGoogleEarthClick={handleGoogleEarthSearch}
        onMapViewClick={handleMapViewSearch}
        onStreetViewClick={handleStreetView}
      />
      <div className="sm:flex-row flex-col flex sm:justify-between sm:items-center items-end p-6 gap-4">
        <div className="flex w-full gap-4 flex-row flex-wrap items-center">
          {/* ---------- Customer Name ---------- */}
          <Typography color={'primary.dark'} className="text-2xl sm:text-3xl drop-shadow-sm cursor-pointer" onClick={handleInfo}>
            {customerData?.first_name}
            &nbsp;
            {customerData?.last_name}
          </Typography>

          {/* ---------- Alert Notes Count ---------- */}
          {!!customerData?.alert_notes?.[customerData?.alert_notes.length - 1]?.note?.length && (
            <CustomTooltip message={customerData?.alert_notes?.[customerData?.alert_notes.length - 1]?.note} props={{ placement: 'top' }}>
              <IconButton
                id="alert-notes-btn"
                color="error"
                size="small"
                onClick={() => {
                  handleTabChange('notes');
                }}
              >
                <WarningOutlined color="inherit" className="text-2xl" />
              </IconButton>
            </CustomTooltip>
          )}

          {/* ---------- Follow Ups Count ---------- */}
          {followupsFetching ? (
            <Skeleton variant="circular" width={35} height={35} />
          ) : (
            <>
              {followUpsCount > 0 && (
                <CustomTooltip message="Total follow ups" props={{ placement: 'top' }}>
                  <IconButton
                    size="small"
                    sx={{
                      color: `${theme.palette.error.main} !important`,
                      border: `1px solid ${theme.palette.error.main}`,
                      fontSize: '12px',
                      padding: '1px'
                    }}
                    className="rounded-2xl min-w-5 min-h-5"
                    onClick={() => {
                      handleTabChange('follow_ups');
                    }}
                  >
                    {followUpsCount}
                  </IconButton>
                </CustomTooltip>
              )}
            </>
          )}

          {/* ---------- Quick Stats ---------- */}
          <Suspense
            fallback={
              <div className="flex items-center gap-1">
                <Skeleton variant="rectangular" width={50} height={35} />
                <Skeleton variant="rectangular" width={50} height={35} />
                <Skeleton variant="rectangular" width={70} height={35} />
              </div>
            }
          >
            <QuickCustomerData customerId={customerData?._id as string} />
          </Suspense>
        </div>

        {!!customerData && <ActionButtonsGroup handleActions={(action) => handleActions(action)} buttons={actionButtons} />}
      </div>

      <Tabs
        sx={{ backgroundColor: theme.palette.grey[100] }}
        value={selectedTab}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="customer view tabs"
        allowScrollButtonsMobile
        TabIndicatorProps={{ style: { display: 'none' } }}
      >
        {availableTabs
          .filter((tab) => !(tab.value === 'customer-billing' && customerData?.bill_to_you))
          .map(
            (eachTab: TCustomerViewTab) =>
              (eachTab.value !== 'customer-jobs-invoices'
                ? true
                : eachTab.value === 'customer-jobs-invoices' && user?.permissions?.includes('getInvoices')) && (
                <Tab
                  classes={{ root: 'rounded-none', selected: 'bg-primary-lighter' }}
                  className={`font-${
                    state[eachTab.value as keyof typeof state] || ['contact', 'customer-billing'].includes(eachTab.value) ? 'bold' : 'light'
                  }`}
                  sx={{
                    color: state[eachTab.value as keyof typeof state] === false ? 'GrayText' : 'inherit',
                    ...(customerData?.alert_notes?.[customerData?.alert_notes?.length - 1]?.note?.length &&
                      eachTab.value === 'notes' && { color: 'tomato !important' }),
                    marginX: 0.5
                  }}
                  value={eachTab.value}
                  label={<span>{eachTab.title}</span>}
                  onClick={() => handleTabChange(eachTab.value)}
                />
              )
          )}
      </Tabs>
      <Paper elevation={3} className="mt-2 overflow-hidden h-full">
        {isCustomerDataFetching ? <ProductPlaceholder /> : renderTab()}
      </Paper>

      {addCustomerPopup.action.open && (
        <AddCutsomerPopup
          {...addCustomerPopup}
          onClose={() => {
            handleAddCustomer(false);
          }}
          onSave={() => handleAddCustomer(false)}
          setCustomerData={setCustomerData}
        />
      )}

      {addActionPopup && customerData && (
        <AddActionPopup customerData={customerData} addActionPopup={addActionPopup} onClose={() => setAddActionPopup(false)} />
      )}

      {addEmailOrSmsPopup.action.open && (
        <UniversalDialog
          action={{ open: addEmailOrSmsPopup.action.open, fullWidth: true }}
          onClose={handleAddEmailorSmsPopup}
          hasPrimaryButton={false}
        >
          <AddEmailorSms {...addEmailOrSmsPopup} onClose={handleAddEmailorSmsPopup} />
        </UniversalDialog>
      )}

      {deleteDialog && (
        <DeleteConfirmationPoup
          {...deleteDialog}
          onClose={() => handleDeleteCustomerAction('close')}
          onDelete={handleDeleteSelectedCustomer}
        />
      )}

      {suspendCustomerReason.state && (
        <UniversalDialog
          title="Customer Suspend Reason"
          action={{ open: suspendCustomerReason.state, maxWidth: 'xs', fullWidth: true }}
          onClose={() => setSuspendCustomerReason((prev) => ({ ...prev, state: false, inactive_reason: '' }))}
          onSave={() => handleSuspendCustomer()}
          primaryButonTitle="Submit"
          disablePrimaryButton={!suspendCustomerReason.inactive_reason?.length}
        >
          <div className="flex flex-col items-start justify-center space-y-2">
            <Typography variant="body1" className="ml-2">
              Reason For Suspend The Customer*
            </Typography>
            <TextField fullWidth multiline minRows={3} onChange={handleSuspendCustomerReasonChange} />
          </div>
        </UniversalDialog>
      )}
    </Paper>
  );
};

export default CustomerView;
