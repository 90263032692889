export const ltrim = (str: string) => {
  if (!str && !str.length) return str;
  return str.replace(/^\s+/g, '');
};

export const rtrim = (str: string) => {
  if (!str && !str.length) return str;
  return str.replace(/\s+$/g, ' ');
};

function trimFc(formik: any) {
  return (event: any) => {
    const ff = ltrim(rtrim(event.target.value));
    formik.setFieldValue(event.target.name, ff);
  };
}

export const trimString = (string: string): string => {
  return ltrim(rtrim(string));
};

export default trimFc;
