import {
  Box,
  Button,
  ButtonBase,
  ButtonGroup,
  CardContent,
  ClickAwayListener,
  Divider,
  FormHelperText,
  InputLabel,
  Paper,
  Popper,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Avatar from 'components/@extended/Avatar';
import Transitions from 'components/@extended/Transitions';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import MainCard from 'components/MainCard';
import UniversalDialog from 'components/popups/UniversalDialog';
import { useFormik } from 'formik';
import useAuth from 'hooks/useAuth';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import websiteServiceInstance from 'services/services.textWebsiteLink';
import * as Yup from 'yup';

// assets
import {
  CalendarOutlined,
  GlobalOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
  UnlockOutlined,
  UserOutlined
} from '@ant-design/icons';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import CustomTooltip from 'components/CustomTooltip';
import Loader from 'components/Loader';
import { CountryData } from 'react-phone-input-2';
import AuthResetPassword from 'sections/auth/auth-forms/AuthResetPassword';
import { dispatch } from 'store';
import { setIsTodaysJob } from 'store/reducers/customReducers/slice.settings';
import { handlePhoneNumberInputChange } from 'utils/common';

const Profile = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { logout, user, resetLogin } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isPasswordChangeOpen, setIsPasswordChangeOpen] = useState(false);
  const handleLogout = async () => {
    setLoading(true);
    await logout(false, user?.rate_info?.punch_type);
    setLoading(false);

    navigate(`/login`, {
      state: {
        from: ''
      }
    });
  };

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleResetLogin = async () => {
    try {
      setLoading(true);
      await resetLogin();
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  //----------------------open close text website link ----------------------------//
  const [openTextWebsiteDialogue, setOpenTextWebsiteDialogue] = useState<boolean>(false);
  const phoneNumberInputRef = useRef<HTMLInputElement | null>(null);
  const [disableSaveBtn, setDisableSaveBtn] = useState<boolean>(false);

  const handleNavigateToSettings = () => {
    navigate('/settings/general-settings');
    handleCloseDialogue();
  };
  type TextWebsite = {
    phone: string;
    textValue: string;
    website: any;
    phone_country_code: any;
  };

  const formik = useFormik<TextWebsite>({
    initialValues: {
      phone: 'null',
      textValue: `Here's that link, it's the simplest way to submit a free quote.`,
      website: user?.company_url,
      phone_country_code: ''
    },
    validationSchema: Yup.object({
      phone: Yup.string().min(5, 'Numbers should be at least 5 digits long').required('Phone number is required'),
      website: Yup.string().url('Invalid URL').required('Website is required')
    }),
    enableReinitialize: true,
    onSubmit: async (values: TextWebsite) => {
      // backend api integrate
      try {
        setDisableSaveBtn(true);
        const sendLinkSms = await websiteServiceInstance.sendWebsiteLink(values);
        if (sendLinkSms) {
          handleCloseDialogue();
          setDisableSaveBtn(false);
        }
      } catch (error) {
        return error;
      }
    }
  });

  const handleClickOpenDialogue = () => {
    handleToggle();
    document.querySelector('.textLinkPhoneNumberInput #phone');
    setOpenTextWebsiteDialogue(true);
  };
  const handleCloseDialogue = () => {
    setOpenTextWebsiteDialogue(false);
    formik.resetForm();
  };

  return (
    <>
      {loading && <Loader />}
      <ButtonBase
        sx={{
          zIndex: 10,
          p: 0.25,
          bgcolor: 'transparent',
          borderRadius: 1,
          // '&:hover': { bgcolor: theme.palette.mode === ThemeMode.DARK ? 'secondary.light' : 'secondary.lighter' },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2
          }
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" alignItems="center" sx={{ p: 0.5 }}>
          <Typography variant="h5" className="sm:flex hidden min-w-max mr-6" color={theme.palette.primary.main}>
            {user?.first_name}&nbsp;
            {user?.last_name}
          </Typography>

          {user?.profile_picture?.length ? (
            <Avatar
              alt="profile user"
              src={user?.profile_picture}
              sx={{ width: 35, height: 35 }}
              color="primary"
              classes={{ img: 'rounded-full' }}
              className="border border-zinc-300 "
            />
          ) : (
            <Avatar
              alt="profile user"
              sx={{ width: 35, height: 35, background: `${theme.palette.primary.lighter}`, color: `${theme.palette.primary.main}` }}
              color="primary"
              className="border border-zinc-300"
            >
              {user?.first_name && user?.first_name[0].toUpperCase()}
              {user?.last_name && user?.last_name[0].toUpperCase()}
            </Avatar>
          )}
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-start"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -50]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" position="top-right" in={open} {...TransitionProps}>
            <Paper
              sx={{
                zIndex: 1,
                boxShadow: theme.customShadows.z1,
                width: 290,
                minWidth: 240,
                maxWidth: 290,
                [theme.breakpoints.down('md')]: {
                  minWidth: 250
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard elevation={0} border={false} content={false}>
                  <Stack component={CardContent} direction="column" alignItems="end" className="mt-4">
                    <Typography variant="h5" color={theme.palette.primary.main} className="flex sm:hidden pr-12">
                      {user?.first_name}&nbsp;
                      {user?.last_name}
                    </Typography>
                    <Typography variant="body2" color={'textSecondary'} className="w-full text-end pr-12">
                      {user?.email}
                    </Typography>
                  </Stack>

                  {user?.role !== 'office_worker' && (
                    <ListItemButton
                      onClick={() => {
                        handleToggle();
                        navigate('/jobs');
                        dispatch(setIsTodaysJob(true));
                      }}
                    >
                      <ListItemIcon>
                        <CalendarOutlined />
                      </ListItemIcon>
                      <ListItemText className="cursor-pointer">Today's Jobs</ListItemText>
                    </ListItemButton>
                  )}

                  <ListItemButton
                    onClick={() => {
                      handleToggle();
                      navigate('/settings/my-profile');
                    }}
                  >
                    <ListItemIcon>
                      <UserOutlined />
                    </ListItemIcon>

                    <ListItemText primary="Manage Profile" />
                  </ListItemButton>

                  <ListItemButton>
                    <ListItemIcon>
                      <GlobalOutlined />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={{ marginRight: '8px' }} onClick={handleClickOpenDialogue}>
                            Text Website Link
                          </span>
                          <CustomTooltip message="Click this to know how it is work.">
                            <QuestionCircleOutlined />
                          </CustomTooltip>
                        </Typography>
                      }
                    />
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => {
                      handleToggle();
                      setIsPasswordChangeOpen(true);
                    }}
                  >
                    <ListItemIcon>
                      <UnlockOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Change Password" />
                  </ListItemButton>
                  <Box sx={{ border: 1, borderColor: 'divider' }}>
                    <ButtonGroup variant="outlined" fullWidth size="large">
                      {window.localStorage.getItem('oldServiceToken') !== null && (
                        <Button color="inherit" variant="text" onClick={handleResetLogin} startIcon={<ReloadOutlined />} size="large">
                          Reset Login
                        </Button>
                      )}
                      <Divider orientation="vertical" flexItem />
                      <Button color="inherit" variant="text" onClick={handleLogout} startIcon={<LogoutOutlined />} size="large">
                        Logout
                      </Button>
                    </ButtonGroup>
                  </Box>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>

      {isPasswordChangeOpen && (
        <UniversalDialog
          title={'Change Password'}
          action={{ open: isPasswordChangeOpen, fullWidth: true, maxWidth: 'xs' }}
          onClose={() => setIsPasswordChangeOpen(false)}
          hasPrimaryButton={false}
        >
          <AuthResetPassword isResetPassword={true} onClose={() => setIsPasswordChangeOpen(false)} />
        </UniversalDialog>
      )}

      {/* Text Link Website Pop up */}
      {openTextWebsiteDialogue && (
        <UniversalDialog
          onClose={handleCloseDialogue}
          hasPrimaryButton={false}
          action={{ open: openTextWebsiteDialogue, fullWidth: true, maxWidth: 'sm' }}
          title={'Text Website Link'}
          styleData="md:top-0 sm:top-auto top-[-35%]"
        >
          <form onSubmit={formik.handleSubmit} className="space-y-1">
            <div ref={phoneNumberInputRef}>
              <InputLabel htmlFor="phone">Phone *</InputLabel>
              <PhoneNumberInput
                inputProps={{
                  autoFocus: true
                }}
                id="phone"
                className="textLinkPhoneNumberInput"
                value={`${formik.values.phone_country_code}${formik.values.phone}`}
                onChange={(phone: string, country: CountryData, e: React.ChangeEvent<HTMLInputElement>) =>
                  handlePhoneNumberInputChange(phone, country, e, formik, 'phone')
                }
              />
              {Boolean(formik.touched?.phone) && Boolean(formik.errors?.phone) && (
                <FormHelperText error id="helper-text-first_name-signup">
                  {formik.errors?.phone}
                </FormHelperText>
              )}
            </div>
            <div>
              <InputLabel htmlFor="text">Text </InputLabel>
              <TextField
                id="text"
                margin="dense"
                type="text"
                fullWidth
                variant="outlined"
                name="textValue"
                onChange={formik.handleChange}
                value={formik.values.textValue}
                multiline={true}
                rows={2}
              />
            </div>
            <div>
              <InputLabel htmlFor="website" style={{ alignItems: 'center' }} className="flex flex-col md:flex-row md:justify-between">
                Website *
                {!user?.company_url && !user?.company_url?.length && <Button onClick={handleNavigateToSettings}>Add Website Link</Button>}
              </InputLabel>
              <TextField
                margin="dense"
                type="text"
                fullWidth
                variant="outlined"
                disabled
                name="website"
                onChange={formik.handleChange}
                value={formik.values.website}
              />
            </div>
            <Button variant="contained" className="float-end" type="submit" disabled={disableSaveBtn}>
              Send
            </Button>
          </form>
        </UniversalDialog>
      )}
    </>
  );
};

export default Profile;
