import { LikeTwoTone } from '@ant-design/icons';
import { Avatar, Button, Card, CardContent, Divider, Rating, Typography, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import PublicSerivcesInstance from 'services/service.noAuth';
import { dispatch, useSelector } from 'store';
import { setGeneralSettings } from 'store/reducers/customReducers/slice.settings';
import { formatPhoneNumber } from 'utils/common';
import {
  autoAppointmentBookedMessage,
  declineApprovalMessage,
  editEstimateThankYouMessage,
  estimateWidgetThankYouPageMessage1,
  estimateWidgetThankYouPageMessage2,
  proposalApprovalMessage,
  updatedEstimateAcceptMessage,
  updatedEstimateDeclineMessage
} from 'utils/constants';

const ThankYouPage = ({ jobId }: { jobId?: string }) => {
  //-------------------------Constants---------------
  // const navigate = useNavigate();
  const theme = useTheme();
  const locaiton = useLocation();
  const navigate = useNavigate();
  const pathParameter = locaiton.pathname.split('/')[1];
  const primaryColor = theme.palette.primary.main;
  const data = useParams();
  const [isProposalApproved, setIsProposalApproved] = useState<boolean>(null as unknown as boolean);
  const settings = useSelector((state) => state.settings);
  //-------------------useQuery------------------
  const { data: jobData } = useQuery({
    queryKey: ['single_job_data', data.jobId],
    queryFn: () =>
      PublicSerivcesInstance.getJobInfo(
        ['appointment-widget', 'schedule-job', 'estimate-widget'].includes(pathParameter) ? (jobId as string) : data.jobId ?? '',
        'thankyou'
      ),
    enabled: !['payment'].includes(pathParameter)
  });

  useEffect(() => {
    dispatch(setGeneralSettings({ ...settings, type_case: jobData?.type_case }));

    if (jobData?.job?.previous_states && jobData?.job?.previous_states?.length > 0) {
      if (jobData?.job?.previous_states.includes('proposal_approved')) {
        setIsProposalApproved(true);
      } else if (jobData?.job?.previous_states.includes('proposal_declined')) {
        return setIsProposalApproved(false);
      } else {
        // navigate(`/proposal/view?job_id=${jobData?.job._id}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobData]);

  return (
    <CardContent className="md:px-[20vw] lg:px-[30vw] space-y-4 normal-case">
      {pathParameter !== 'payment' && !!jobData && (
        <Typography variant="h2" className="font-bold text-center ">
          {jobData.company.company_name}
        </Typography>
      )}
      <Card className="flex flex-col">
        <div className="flex flex-col justify-center items-center bg-green-500 p-10 space-y-5">
          <Avatar variant="circular" sx={{ width: '7.5rem', height: '7rem', backgroundColor: 'white' }}>
            <LikeTwoTone style={{ fontSize: '5rem' }} twoToneColor={primaryColor} />
          </Avatar>
          <Typography variant="h1" className="text-wrap text-white text-center font-normal">
            {jobData &&
              (pathParameter === 'proposal'
                ? isProposalApproved
                  ? 'Thank you for approving our proposal!'
                  : 'Thank You For Your Submission!'
                : pathParameter === 'signature' && !!jobData.job?.customer_sign_on_estimation && editEstimateThankYouMessage)}
            {['payment', 'estimate-widget'].includes(pathParameter) && editEstimateThankYouMessage}
            {['appointment-widget', 'schedule-job'].includes(pathParameter) && autoAppointmentBookedMessage}
          </Typography>
        </div>
        <div className="flex flex-col justify-center items-center space-y-3 p-10">
          {((pathParameter === 'proposal' && isProposalApproved) ||
            (!!jobData && pathParameter === 'signature' && !!jobData.job?.customer_sign_on_estimation) ||
            ['payment', 'appointment-widget', 'schedule-job', 'estimate-widget'].includes(pathParameter)) && (
            <Rating name="simple-controlled" value={5} readOnly size="large" />
          )}

          <Typography sx={{ textAlign: 'center' }}>
            {pathParameter === 'proposal' &&
              (isProposalApproved ? `${jobData?.job.customer?.name}, ${proposalApprovalMessage}` : declineApprovalMessage)}
            {pathParameter === 'signature' &&
              (data.is_proposal_accepted === 'accept'
                ? `${jobData?.job.customer?.name}, ${updatedEstimateAcceptMessage}`
                : updatedEstimateDeclineMessage)}
            {pathParameter === 'payment' && 'Your invoice order has been processed successfully.'}
            {pathParameter === 'estimate-widget' && (
              <div>
                <Typography className="text-center">{estimateWidgetThankYouPageMessage1}</Typography>
                <Typography className="text-center">{estimateWidgetThankYouPageMessage2}</Typography>
              </div>
            )}
          </Typography>
          {!['payment', 'estimate-widget'].includes(pathParameter) && (
            <Typography sx={{ textAlign: 'center' }}>{"FOR QUESTIONS, DON'T HESITATE TO CONTACT US."}</Typography>
          )}
          {jobData?.job.can_customer_auto_schedule &&
            pathParameter === 'proposal' &&
            !jobData.job.previous_states.includes('assign_worker') &&
            jobData.job.state === 'proposal_approved' && (
              <>
                <Typography sx={{ textAlign: 'center' }}>{'Would you like to schedule your job?'}</Typography>
                <Button onClick={() => navigate(`/schedule-job?company_id=${jobData.company._id}&job_id=${jobData.job._id}`)}>
                  Click Here
                </Button>
              </>
            )}
          {!!jobData && (
            <Typography variant="h2" className="font-bold text-center ">
              {jobData?.company.company_name}
            </Typography>
          )}
          {!!jobData && (
            <div>
              <div className="sm:flex space-x-1">
                <Typography>{jobData.company?.company_address?.address1 && `${jobData.company?.company_address?.address1}, `}</Typography>
                <Typography>{jobData.company?.company_address?.city && `${jobData.company?.company_address?.city}, `}</Typography>
                <Typography>{jobData.company?.company_address?.state && `${jobData.company?.company_address?.state}, `}</Typography>
                <Typography>{jobData.company?.company_address?.zip && `${jobData.company?.company_address?.zip}`}</Typography>
              </div>
              <div className="sm:flex sm:flex-row sm:space-x-2">
                <Typography>
                  {formatPhoneNumber(jobData.company?.phone1)}

                  {/* {`+${jobData.company?.phone1_country_code}${
                    jobData.company?.phone1_extension ? ` (${jobData.company?.phone1_extension})` : ''
                  } ${jobData.company?.phone1}${jobData.company?.phone1_extension ? ` x${jobData.company?.phone1_extension}` : ''}`} */}
                </Typography>
                <Divider
                  style={{ height: '20px', backgroundColor: 'black' }}
                  orientation="vertical"
                  sx={{
                    display: { xs: 'none', sm: '' }
                  }}
                />
                <Typography>{jobData.company?.company_email}</Typography>
              </div>
            </div>
          )}
        </div>
      </Card>
    </CardContent>
  );
};
export default ThankYouPage;
