import {
  CameraFilled,
  CheckCircleFilled,
  CheckCircleOutlined,
  CheckOutlined,
  ClearOutlined,
  CloseOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  LoadingOutlined,
  SendOutlined
} from '@ant-design/icons';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import CustomTooltip from 'components/CustomTooltip';
import { TJobData } from 'components/tables/interfaces/jobTableInterface';
import { universalRenerMedia } from 'components/UniversalRenderMedia';
import { trimEnd, trimStart } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import ReactSignatureCanvas from 'react-signature-canvas';
import FileUploadServiceInstance from 'services/services.fileUpload';
import JobServicesInstance from 'services/services.jobs';
import MediaServicesInstance from 'services/services.media';
import { dispatch, useSelector } from 'store';
import {
  clearJobcompletionStates,
  pushToAddMediaMenu,
  removeFromAddMediaMenu,
  setActiveNoteInputId,
  setAddJobMediaInputId,
  setCustomerSign,
  setIsCustomerPresent,
  setJobCompletionPhotos,
  setMaterialsUsed,
  setShowJobCompleteBtn,
  setWorkerToOfficeNote
} from 'store/reducers/customReducers/slice.jobs';
import { TMedia } from 'types/globalTypes/type.media';
import { jobStates } from 'utils/constants';
import { TMaterialUsed } from '../types/jobViewPageTypes';
import ViewEstimateOptions from '../ViewEstimateOptions/ViewEstimateOptions';

const CompleteJob = ({
  jobData,
  jobActionIndex,
  handleUnsuspendJob,
  handleActionButtonClick
}: {
  jobData: TJobData | undefined;
  jobActionIndex: number;
  currentStateButtons: any;
  handleActionButtonClick: any;
  handleUnsuspendJob: any;
}) => {
  //-------------------------useQuery--------------------
  const { data: materialsData, isFetched: isMaterialsDataFetched } = useQuery({
    queryKey: ['materials_used_data'],
    queryFn: () => {
      if (!!jobData?.job?._id) return JobServicesInstance.getMaterialsUsed(jobData?.job?._id);
    },
    enabled:
      !!jobData &&
      !!jobData?.job?.job_action &&
      !!jobData.job?.job_action?.[jobActionIndex]?.record_materials_used &&
      jobData.job?.job_action[jobActionIndex]?.record_materials_used !== 'no'
  });
  //------------------------Constants----------------
  const jobOptions = useSelector((state) => state.job);
  const signatureRef = useRef<any>();
  const completionPhotosRef = useRef<any>();
  const materialsRef = useRef<any>();
  const theme = useTheme();
  const location = useLocation();
  const [files, setFiles] = useState<TMedia[]>([]);
  const [jobImages, setJobImages] = useState<string[]>([]);
  const [isCompletionMediaUploading, setIsCompletionMediaUploading] = useState(false);
  const [isAddMediaUploading, setIsAddMediaUploading] = useState(false);
  const [isErrorInAddMediaImages, setIsErrorInAddMediaImages] = useState('');
  const [isErrorInJobCompletionImages, setIsErrorInJobCompletionImages] = useState(
    jobData?.job?.job_action?.[jobActionIndex]?.completion_photos_required === 'required' ? 'This field is required' : ''
  );
  const [viewStates, setViewStates] = useState({ estimateView: false, workOrderView: false, jobView: true });

  const [notesForOffice, setNotesForOffice] = useState<{ note: string; already_sent: boolean }>({ note: '', already_sent: false });
  const [displayNotesAccorion, setDisplayNotesAccorion] = useState(false);

  function handleNoteChange(event: React.ChangeEvent<HTMLInputElement>) {
    setNotesForOffice((prev) => {
      return { note: event.target.value ?? '', already_sent: false };
    });
    dispatch(setWorkerToOfficeNote(event.target.value ?? ''));
  }

  useEffect(() => {
    if (location.state) {
      setViewStates(() => ({
        estimateView: location.state?.estimationView ?? false,
        workOrderView: location.state?.workOrderView ?? false,
        jobView: location.state?.jobView ?? false
      }));
    }
  }, [location.state]);

  //-------------Handler----------------------
  const handleIsCustomerPresentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setIsCustomerPresent((event.target as HTMLInputElement).value === 'true'));
    if (!((event.target as HTMLInputElement).value === 'true')) clearSignature();
  };

  const clearSignature = () => {
    signatureRef?.current?.clear();
    dispatch(setCustomerSign(''));
  };

  const handleMaterialUsedUnit = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const unit = event.target.value;
    if (unit.charAt(0) === '-') {
      event.target.value = '';
      return null;
    }
    const dummyData = JSON.parse(JSON.stringify(jobOptions.materials_used));
    dummyData[index].units = unit.length ? Number(unit) : null;
    materialsRef.current = dummyData;
    dispatch(setMaterialsUsed(dummyData));
  };

  //-----------DisplayJobImages---------------
  const handleFilesChange = async (allFiles: string[]) => {
    if (!!jobData?.job._id) {
      const response = await MediaServicesInstance.addMedia(allFiles, 'jobs', jobData.job.customer?.id, jobData.job._id, 'media');
      if (response) {
        setFiles((prevFiles) => [...prevFiles, ...response]);
      }
    }
  };

  const handleRemoveSelectedfile = async (index: number, isJobCompletionImages: boolean, mediaId?: string) => {
    if (isJobCompletionImages) {
      const dummyData = [...jobOptions.job_completion_photos];
      dummyData.splice(index, 1);

      dispatch(setJobCompletionPhotos([...dummyData]));
    } else {
      if (mediaId) {
        const dummyData = [...files];
        dummyData.splice(index, 1);
        setFiles(() => dummyData);
        await MediaServicesInstance.deleteMedia(mediaId);
      }
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, isJobCompletionImages: boolean) => {
    if (event.target.files?.length) {
      isJobCompletionImages ? setIsCompletionMediaUploading(true) : setIsAddMediaUploading(true);
      const FilesData = Object.values(event.target.files);
      let newImagesData: string[] = isJobCompletionImages ? jobOptions.job_completion_photos : [];
      let isError = false;
      await Promise.all(
        FilesData.map(async (eachFile) => {
          const response = await FileUploadServiceInstance.uploadFile(eachFile, undefined, false);
          if (response && response.data) newImagesData = [...newImagesData, response.data];
        })
      );
      isJobCompletionImages ? setIsCompletionMediaUploading(false) : setIsAddMediaUploading(false);
      if (isError) {
        isJobCompletionImages
          ? setIsErrorInJobCompletionImages('Please upload an image/video')
          : setIsErrorInAddMediaImages('Please upload an image/video');
        return;
      }
      if (isJobCompletionImages) {
        setIsErrorInJobCompletionImages('');
        dispatch(setJobCompletionPhotos(newImagesData));
        completionPhotosRef.current = newImagesData;
      } else {
        setIsErrorInAddMediaImages('');
        handleFilesChange(newImagesData);
      }
    }
  };

  async function handleSendNoteToOffice() {
    if (trimEnd(trimStart(notesForOffice.note)) === '') return;
    const response = await JobServicesInstance.sendNoteToOffice(jobData?.job?._id as string, notesForOffice.note);
    if (response !== undefined && response?.data.success) {
      setNotesForOffice((prev) => ({ ...prev, already_sent: true }));
    }
  }

  //---------------------useEffects-------------------

  useEffect(() => {
    if (jobData?.job) {
      const images = jobData.job?.job_action?.[jobActionIndex]?.job_images ?? [];
      setJobImages((prev) => [...prev, ...images]);
    }
  }, [jobData?.job]);

  useEffect(() => {
    if (
      jobData?.job?.job_action?.[jobActionIndex]?.completion_photos_required === 'required' &&
      jobOptions.job_completion_photos.length === 0
    )
      setIsErrorInJobCompletionImages('This field is required');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobOptions.job_completion_photos]);

  useEffect(() => {
    const localData = localStorage.getItem('job_completion_data');
    if (localData) {
      const data = JSON.parse(localData);
      if (data?.jobId === jobData?.job._id) {
        dispatch(setCustomerSign(data?.signature));
        signatureRef.current?.fromDataURL(data?.signature);

        dispatch(setJobCompletionPhotos(data?.job_completion_photos ?? []));
        completionPhotosRef.current = data?.job_completion_photos ?? [];

        dispatch(setMaterialsUsed(data?.materials_used ?? []));
        materialsRef.current = data?.materials_used ?? [];
      }
    } else {
      dispatch(clearJobcompletionStates());
    }
    dispatch(setActiveNoteInputId('notes_back_to_office'));
    dispatch(setAddJobMediaInputId('upload-file-complete-job'));

    if (['required', 'optional'].includes(jobData?.job?.job_action?.[jobActionIndex]?.completion_photos_required as string)) {
      dispatch(pushToAddMediaMenu('job_completion_photos'));
    }
    dispatch(setShowJobCompleteBtn(true));

    const signatureCanvas = signatureRef.current;

    const noteAccordion = document.getElementById('notes_back_to_office');
    const noteInput = document.getElementById('notes_back_to_office_input');

    let waitingTimeout: NodeJS.Timeout;
    const handleNoteClickEvent = () => {
      setDisplayNotesAccorion((prev) => !prev);
      waitingTimeout = setTimeout(() => {
        noteInput?.focus();
      }, 300);
    };

    if (noteAccordion) {
      noteAccordion.addEventListener('click', handleNoteClickEvent);
    }

    return () => {
      const signatureDataURL = signatureCanvas?.toDataURL();
      dispatch(removeFromAddMediaMenu('job_completion_photos'));
      dispatch(setActiveNoteInputId(''));
      dispatch(setAddJobMediaInputId(''));
      dispatch(clearJobcompletionStates());
      dispatch(setShowJobCompleteBtn(false));

      if (noteAccordion) {
        noteAccordion.removeEventListener('click', handleNoteClickEvent);
      }

      const jobCompletionData = {
        jobId: jobData?.job._id,
        signature: signatureDataURL,
        job_completion_photos: completionPhotosRef.current,
        materials_used: materialsRef.current
      };

      localStorage.setItem('job_completion_data', JSON.stringify(jobCompletionData));
      waitingTimeout && clearTimeout(waitingTimeout);
    };
  }, []);

  useEffect(() => {
    if (
      !!jobData &&
      !!jobData.job.job_action &&
      !!jobData.job?.job_action[jobActionIndex]?.record_materials_used &&
      jobData.job?.job_action[jobActionIndex]?.record_materials_used !== 'no' &&
      isMaterialsDataFetched &&
      !!materialsData
    ) {
      const dummyData = materialsData.map((singleMaterialData) => ({
        units: 0,
        ...singleMaterialData
      }));

      const localData = localStorage.getItem('job_completion_data');
      if (localData && JSON.parse(localData)?.jobId === jobData?.job._id && JSON.parse(localData)?.materials_used?.length) {
        const data = JSON.parse(localData);

        dispatch(setMaterialsUsed([...data?.materials_used]));
        materialsRef.current = data?.materials_used;
      } else {
        dispatch(setMaterialsUsed(dummyData));
        materialsRef.current = dummyData;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialsData]);

  return (
    <>
      {(viewStates.jobView || viewStates.estimateView) && <ViewEstimateOptions existingJobData={jobData} />}
      {(viewStates.jobView || viewStates.workOrderView) && (
        <Grid container component={CardContent} spacing={2} className="p-6 sm:p-12 !pb-20 min-h-[45dvh]">
          {/* -------------------Add Media---------------- */}
          <Grid item xs={12} className="space-y-2 sm:grid hidden">
            <input
              style={{ display: 'none' }}
              id="upload-file-complete-job"
              type="file"
              multiple
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileUpload(event, false)}
            />
            <label htmlFor="upload-file-complete-job">
              <Button
                component="span"
                startIcon={isAddMediaUploading ? <LoadingOutlined /> : <CameraFilled />}
                disabled={isAddMediaUploading}
                variant="contained"
                className="sm:inline hidden min-w-max"
              >
                Add Media
              </Button>
            </label>
          </Grid>
          {files.length + jobImages.length > 0 && (
            <Grid item xs={12} className="space-y-2">
              <Accordion>
                <AccordionSummary expandIcon={''} className="flex items-center w-full justify-between">
                  <Typography variant="h4" className="text-start flex sm:justify-start gap-x-4 justify-between w-full">
                    Job Media
                  </Typography>
                  <div className="min-w-max flex items-center">
                    {files.length + jobImages.length > 0 && (
                      <Chip variant="light" label={files.length + jobImages.length} color="primary" className="mx-4" />
                    )}

                    {!!isErrorInAddMediaImages.length && (
                      <CustomTooltip message={isErrorInAddMediaImages}>
                        <ExclamationCircleFilled className="text-xl text-red-500 " />
                      </CustomTooltip>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {!!files && !!files.length && (
                    <Grid item xs={12}>
                      <ImageList cols={5} rowHeight={'auto'} className="space-x-1 space-y-1">
                        {files?.map((singleFileUrl: TMedia, index: number) => (
                          <ImageListItem
                            key={index}
                            component={Card}
                            className="flex justify-center"
                            sx={{ maxHeight: '250px', minHeight: '100px', minWidth: '100px' }}
                          >
                            {universalRenerMedia(singleFileUrl.url, index)}

                            <ImageListItemBar
                              className="p-1 bg-transparent"
                              position="top"
                              actionIcon={
                                <DeleteOutlined
                                  className="font-lg text-red-500"
                                  onClick={() => handleRemoveSelectedfile(index, false, singleFileUrl._id)}
                                />
                              }
                              actionPosition="right"
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                    </Grid>
                  )}
                  {!!jobImages && !!jobImages.length && (
                    <Grid item xs={12}>
                      <ImageList cols={5} rowHeight={'auto'} className="space-x-1 space-y-1">
                        {jobImages?.map((singleFileUrl: string, index: number) => (
                          <ImageListItem
                            key={index}
                            component={Card}
                            className="flex justify-center"
                            sx={{ maxHeight: '250px', minHeight: '100px', minWidth: '100px' }}
                          >
                            {universalRenerMedia(singleFileUrl, index)}
                          </ImageListItem>
                        ))}
                      </ImageList>
                    </Grid>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}

          {/* -------------------COMPLETION PHOTOS----------------- */}
          {!!jobData &&
            !!jobData.job.job_action &&
            !!jobData.job?.job_action[jobActionIndex]?.completion_photos_required &&
            jobData.job?.job_action[jobActionIndex].completion_photos_required !== 'no' && (
              <Grid item xs={12}>
                <Accordion expanded={!!jobOptions.job_completion_photos?.length}>
                  <AccordionSummary expandIcon={<></>} className="w-full flex justify-between items-center">
                    <label className="w-full" htmlFor="upload-job-completion">
                      <Typography component={'span'} variant="h4">{`Completion Photos ${
                        jobData?.job?.job_action?.[jobActionIndex]?.completion_photos_required === 'required' ? '*' : ''
                      }`}</Typography>
                    </label>

                    <input
                      style={{ display: 'none' }}
                      id="upload-job-completion"
                      type="file"
                      accept="image/*,video/*,audio/*"
                      multiple
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileUpload(event, true)}
                    />

                    {isCompletionMediaUploading && (
                      <div className="px-2">
                        <LoadingOutlined size={1} />
                      </div>
                    )}

                    {!!isErrorInJobCompletionImages.length && (
                      <CustomTooltip message={isErrorInJobCompletionImages}>
                        <ExclamationCircleFilled className="text-xl text-red-500 mt-1" />
                      </CustomTooltip>
                    )}
                  </AccordionSummary>
                  <AccordionDetails>
                    {!!jobOptions.job_completion_photos && (
                      <ImageList cols={5} rowHeight={'auto'} className="space-x-1 space-y-1">
                        {jobOptions.job_completion_photos?.map((singleFileUrl: string, index: number) => (
                          <ImageListItem key={index} component={Card} className="border border-1-black w-max max-w-[200px]">
                            {universalRenerMedia(singleFileUrl, index)}

                            <ImageListItemBar
                              className="p-1 bg-transparent"
                              position="top"
                              actionIcon={
                                <DeleteOutlined className="font-lg text-red-500" onClick={() => handleRemoveSelectedfile(index, true)} />
                              }
                              actionPosition="right"
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}

          {/* -------------------MATERIALS USED----------------- */}
          {!!jobData &&
            !!jobData.job.job_action &&
            !!jobData.job?.job_action[jobActionIndex]?.record_materials_used &&
            jobData.job?.job_action[jobActionIndex].record_materials_used !== 'no' &&
            isMaterialsDataFetched &&
            !!materialsData &&
            !!materialsData.length && (
              <Grid item xs={12} className="space-y-2">
                <Accordion {...(jobData.job?.job_action[jobActionIndex]?.record_materials_used === 'required' && { expanded: true })}>
                  <AccordionSummary expandIcon={''}>
                    <Typography variant="h4">
                      Material Used {jobData?.job?.job_action?.[jobActionIndex]?.record_materials_used === 'required' && '*'}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="flex flex-wrap gap-2">
                      {jobOptions?.materials_used?.map((singleMaterial: TMaterialUsed, index: number) => (
                        <div>
                          <Typography>{singleMaterial.description}</Typography>
                          <TextField
                            value={singleMaterial.units}
                            type="number"
                            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                              handleMaterialUsedUnit(event, index)
                            }
                            InputProps={{
                              endAdornment: <InputAdornment position="end">{singleMaterial.unit_of_measure}</InputAdornment>
                            }}
                            error={
                              jobData?.job?.job_action?.[jobActionIndex]?.record_materials_used === 'required' &&
                              singleMaterial.units === null
                            }
                            helperText={
                              jobData?.job?.job_action?.[jobActionIndex]?.record_materials_used === 'required' &&
                              singleMaterial.units === null &&
                              'This field is required'
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}

          {/* -------------------CUSTOMER SIGN OFF----------------- */}
          {!!jobData &&
            !!jobData.job?.job_action &&
            !!jobData.job.job_action[jobActionIndex]?.customer_sign_on_job &&
            jobData.job.job_action[jobActionIndex].customer_sign_on_job !== 'no' && (
              <Grid item xs={12} className="space-y-2">
                <Accordion {...(jobData.job?.job_action[jobActionIndex]?.customer_sign_on_job === 'required' && { expanded: true })}>
                  <AccordionSummary expandIcon={''}>
                    <Typography variant="h4" className="text-start flex sm:justify-start gap-x-4 justify-between w-full">
                      <span>
                        {jobData.job.job_action[jobActionIndex].customer_sign_on_job === 'optional'
                          ? 'Customer Sign Off'
                          : 'Customer Sign Off *'}
                      </span>

                      {!!jobOptions.customer_sign_for_job_completed && !!jobOptions.customer_sign_for_job_completed?.length && (
                        <CheckCircleFilled style={{ color: theme.palette.success.main }} />
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl component={Stack} direction={{ md: 'row' }} alignItems={{ md: 'center' }} spacing={2}>
                      <Typography sx={{ color: 'gray' }}>Is customer present? *</Typography>
                      <RadioGroup defaultValue={true} row value={jobOptions.is_customer_present} onChange={handleIsCustomerPresentChange}>
                        <FormControlLabel value={true} control={<Radio />} label="Yes" />
                        <FormControlLabel value={false} control={<Radio />} label="No" />
                      </RadioGroup>
                    </FormControl>
                    {jobOptions.is_customer_present && (
                      <div className="flex items-start relative sm:w-max w-full mt-2">
                        <ReactSignatureCanvas
                          penColor="black"
                          canvasProps={{
                            className: 'signateureCanvas',
                            style: { width: '100%', maxWidth: '600px', border: '1px solid black', minHeight: '200px', maxHeight: 250 }
                          }}
                          ref={signatureRef}
                          onEnd={() => {
                            dispatch(setCustomerSign(signatureRef?.current?.toDataURL()));
                          }}
                        />
                        <CustomTooltip message="Clear" props={{ placement: 'top' }}>
                          <IconButton onClick={clearSignature} color="error" className="absolute top-0 right-0">
                            <ClearOutlined />
                          </IconButton>
                        </CustomTooltip>
                      </div>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}

          {/* -------------------NOTES FOR OFFICE----------------- */}

          {jobData?.job.state === jobStates.WORKER_ASSIGNED && (
            <Grid item xs={12} className={displayNotesAccorion ? 'visible' : 'sm:visible invisible'}>
              <Accordion>
                <AccordionSummary id="notes_back_to_office" expandIcon={''}>
                  <Typography variant="h4">Note Back To The Office</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    placeholder="Type your notes here..."
                    classes={{ root: '!rounded-none' }}
                    id={'notes_back_to_office_input'}
                    multiline
                    minRows={2}
                    maxRows={6}
                    value={notesForOffice.note}
                    onChange={handleNoteChange}
                    className="text-[1.1rem] sm:w-1/2 w-full"
                    variant="standard"
                  />
                  {notesForOffice.note.length > 0 && (
                    <div className="flex flex-col sm:w-1/2 w-full items-start justify-between mt-4">
                      <ButtonGroup className="!shadow-none" variant="outlined">
                        <Button
                          className="rounded-none min-w-max !shadow-none sm:text-base text-xs text-balance"
                          startIcon={<SendOutlined className="-rotate-45 mb-1" />}
                          variant="contained"
                          onClick={handleSendNoteToOffice}
                        >
                          Send Note
                        </Button>
                        <Button
                          className="rounded-none min-w-max !shadow-none sm:text-base text-xs text-balance"
                          startIcon={<CloseOutlined />}
                          variant="contained"
                          color="error"
                          onClick={() => handleActionButtonClick('job_not_completed')}
                        >
                          Cancel Job
                        </Button>
                        <Button
                          className="rounded-none min-w-max !shadow-none sm:text-base text-xs text-balance"
                          startIcon={<CheckOutlined />}
                          variant="contained"
                          color="success"
                          onClick={() => handleActionButtonClick('job_completed')}
                        >
                          Complete Job
                        </Button>
                      </ButtonGroup>
                      {notesForOffice.already_sent && (
                        <Typography variant="caption" color={'GrayText'} className="pl-1 mt-1">
                          <CheckCircleOutlined /> A note has been sent already
                        </Typography>
                      )}
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default CompleteJob;
