import { Button, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { APP_DEFAULT_PATH } from 'config';
import { useNavigate } from 'react-router';
import loactionGif from 'assets/images/enable_location.gif';

const LocationPermissionGuard = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div className="h-screen max-w-full flex items-center justify-center">
      <Paper className="md:p-6 p-4 xl:w-1/2 lg:w-2/3 w-full m-4 flex items-start justify-center" sx={{ minHeight: 256 }} elevation={3}>
        <div className="w-full flex flex-col justify-between items-center gap-y-6">
          <Typography variant={!matchDownSm ? 'h2' : 'h4'} color="primary">
            Enable Your Location
          </Typography>
          <img className="sm:w-1/2 w-full" alt="Enable Location" src={loactionGif} />
          <Button
            onClick={() => {
              navigate(APP_DEFAULT_PATH);
              window.location.reload();
            }}
          >
            Go to Homepage
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default LocationPermissionGuard;
