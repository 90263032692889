import { createSlice } from '@reduxjs/toolkit';
import { TMeasurementUnits } from 'components/forms/JobOptionItem/types.jobItems';
import { TOptionTabs } from 'pages/jobs/types/types.estimateOptions';

// types
type TEstimateOptionActions = {
  optionsData: {
    id: string;
    approved?: boolean;
    option_name: string;
    proposal_note: string;
    estimate_note: string;
    files: string[];
    sub_total: number;
    tax: number;
    grand_total: number;
    items: {
      id: string;
      job_category: { id: string; name: string };
      image: string;
      service_and_product: { id: string; name: string };
      description: string;
      units: string;
      taxable: number;
      price: number;
      measure_type?: string;
      item_details?: string;

      measurements?: TMeasurementUnits[];
      show_units_on_proposal: boolean;
    }[];
  }[];
  editModeOptionsData: TOptionTabs[];
  currentSelectedOption: string | null;
  activeTabTitle: string;
};

// initial state
const initialState: TEstimateOptionActions = {
  optionsData: [],
  editModeOptionsData: [],
  currentSelectedOption: null,
  activeTabTitle: ''
};

// ==============================|| SLICE ||============================== //

const estimateOptionActions = createSlice({
  name: 'estimate_options',
  initialState,
  reducers: {
    setOptionsData(state, action) {
      state.optionsData = action.payload;
    },
    setOptionsDataEditMode(state, action) {
      state.editModeOptionsData = action.payload;
    },
    setCurrentSelectedOption(state, action) {
      state.currentSelectedOption = action.payload;
    },
    setActiveTabTitle(state, action) {
      state.activeTabTitle = action.payload;
    }
  }
});

export default estimateOptionActions.reducer;

export const { setOptionsData, setOptionsDataEditMode, setCurrentSelectedOption, setActiveTabTitle } = estimateOptionActions.actions;
