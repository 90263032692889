import { CameraFilled, DeleteFilled, LoadingOutlined } from '@ant-design/icons';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Button,
  CardMedia,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useQuery } from '@tanstack/react-query';
import UniversalDialog from 'components/popups/UniversalDialog';
import TMaterialData from 'components/tables/interfaces/materialTableInterface';
import TUserData from 'components/tables/interfaces/userTableInterface';
import dayjs, { Dayjs } from 'dayjs';
import { getIn, useFormik } from 'formik';
import useAuth from 'hooks/useAuth';
import { TExpenseData, TReimbursedAmountPopup } from 'pages/expenses/types/types.expense';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import ExpenseServicesInstance from 'services/services.expense';
import FileUploadServiceInstance from 'services/services.fileUpload';
import JobServicesInstance from 'services/services.jobs';
import MaterialServicesInstance from 'services/services.materials';
import UserServicesInstance from 'services/services.users';
import { formatCostInDecimal } from 'utils/common';
import trimFc from 'utils/trimFc';
import * as Yup from 'yup';

const AddExpenseForm = ({
  onSuccess,
  jobData,
  isGlobalExpense,
  selectedExpenseType
}: {
  onSuccess: () => void;
  jobData: { id: string; name: string };
  isGlobalExpense: boolean;
  selectedExpenseType?: string;
}) => {
  //--------------------------------------constants----------------------------------------
  const { user } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const [reimbursedAmountPopup, setReimbursedAmountPopup] = useState<TReimbursedAmountPopup>({ action: { open: false } });
  const [expenseTypeOption, setExpenseTypeOptions] = useState<
    {
      label: string;
      value: string;
      permission: string;
    }[]
  >([]);

  const searchData = {
    search: [
      [
        {
          field_name: 'status',
          field_value: 1,
          operator: 'exactmatch'
        }
      ]
    ]
  };
  // ----------------------------------------useQuery----------------------------------------
  const { data: availableJobs, isFetched: isJobDataFetched } = useQuery({
    queryKey: ['fetchAvaliableJobs'],
    queryFn: () => JobServicesInstance.getAllJobs(undefined, searchData)
  });
  const { data: allExpenseTypeData, isFetched: isExpenseTypeFetched } = useQuery({
    queryKey: ['expense_type_payroll'],
    queryFn: () => ExpenseServicesInstance.getAllExpenseType()
  });
  const { data: allMaterialsData, isFetched: isMaterialDataFetched } = useQuery({
    queryKey: ['material_data'],
    queryFn: () => MaterialServicesInstance.getAllMaterials(undefined, searchData)
  });
  const { data: allUsersData } = useQuery({
    queryKey: ['user_data', searchData],
    queryFn: () => UserServicesInstance.getAllUsers(undefined, searchData),
    enabled: user?.permissions && user.permissions.includes('getUsers')
  });
  const renderExpensePermission = () => {
    return allExpenseTypeData?.filter((eachExpenseType) => user?.permissions?.includes(eachExpenseType.permission)) || [];
  };

  // --------------------------Other functions-------------------
  const formik = useFormik<TExpenseData>({
    initialValues: {
      job: jobData,
      expense_type: selectedExpenseType ?? '',
      description: '',
      date: new Date(),
      is_global_expense: isGlobalExpense,
      price: null as unknown as number,
      units: null as unknown as number,
      reimbursed_expense: !!selectedExpenseType,
      reimbursed_amount: null as unknown as number,
      media: [],
      material: {
        id: '',
        description: ''
      },
      reimbursed_user: {
        id: `${user?._id}`,
        name: `${user?.first_name} ${user?.last_name}`
      }
    },
    validationSchema: Yup.object().shape({
      expense_type: Yup.string().max(255).required('Expense Type is required'),
      material: Yup.object().when('expense_type', ([expense_type], schema) => {
        if (expense_type === 'job_supplies/materials')
          return schema.shape({
            id: Yup.string().required('Material is required')
          });
        return schema.nullable();
      }),
      date: Yup.date().required('Date is required'),
      price: Yup.number().required('Price is required'),
      reimbursed_amount: Yup.number().when(['reimbursed_expense'], {
        is: (reimbursed_expense: boolean) => {
          if (reimbursed_expense) {
            return true;
          }
        },
        then: (schema: Yup.NumberSchema) =>
          schema.max(Yup.ref('price'), 'Reimbursed Amount must be less than or equal to Price').required('Reimbursed Amount is Required'), // Apply this schema if the condition is true
        otherwise: (schema: Yup.NumberSchema) => schema.nullable()
      }),
      reimbursed_user: Yup.object().when('reimbursed_expense', ([reimbursed_expense], schema) => {
        if (reimbursed_expense) {
          return schema.shape({
            id: Yup.string().required('Reimbursed User is required')
          });
        }
        return schema.nullable();
      })
    }),

    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await handleUserFormSubmit(values);
      setSubmitting(false);
    }
  });
  const handleUserFormSubmit = async (values: TExpenseData) => {
    if (['education/training', 'payroll_compensation', 'bonuses'].includes(values?.expense_type)) values.is_global_expense = true;

    if (!values?.job?.id.length) delete values.job;
    if (!(values.expense_type === 'job_supplies/materials')) delete values.material;
    if (!values.reimbursed_expense) {
      delete values.reimbursed_amount;
      delete values.reimbursed_user;
    }
    const response = await ExpenseServicesInstance.createExpense(values);
    response && onSuccess();
  };
  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) < 0) {
      event.target.value = '';
      return null;
    }
    if (allMaterialsData?.materials.length)
      formik.setFieldValue(
        'price',
        Number(event.target.value) *
          allMaterialsData?.materials[
            allMaterialsData?.materials.findIndex((eachMaterial) => eachMaterial._id === formik.values.material?.id)
          ]?.price_per_unit
      );
    formik.handleChange(event);
  };
  const handleExpenseTypeChange = (event: SyntheticEvent<Element, Event>, newValue: { label: string; value: string }) => {
    if (['education/training', 'payroll_compensation', 'bonuses'].includes(newValue.value)) {
      formik.setFieldValue('reimbursed_expense', true);
      formik.setFieldValue('reimbursed_amount', formik.values.price);
    }
    formik.setFieldValue('expense_type', newValue.value);
  };

  const handleChangeIsReimbursed = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'false') {
      setReimbursedAmountPopup({ action: { open: true } });
      return;
    }
    formik.setFieldValue('reimbursed_amount', formik.values.price);
    formik.setFieldValue('reimbursed_expense', event.target.value === 'true');
  };
  //-------------------------------useEffect----------------------------
  useEffect(() => {
    setExpenseTypeOptions(renderExpensePermission);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allExpenseTypeData]);

  const [isFileUploading, setIsFileUploading] = useState(false);

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setIsFileUploading(true);

      const FilesData = Object.values(event.target.files);
      const newImagesData = !!formik.values.media?.length ? [...formik.values.media] : [];
      const uploadPromises = FilesData.map((eachFile, index) => {
        return FileUploadServiceInstance.uploadFile(eachFile);
      });

      Promise.all(uploadPromises)
        .then((response) => {
          setIsFileUploading(false);
          response.forEach((eachImage) => {
            newImagesData.push(eachImage?.data);
          });
        })
        .catch((err) => {});

      formik.setFieldValue('media', newImagesData);
    }
  };

  return (
    <Grid container spacing={2.5} onSubmit={formik.handleSubmit} component={'form'}>
      {/* -----------------------------------------JOB------------------------------ */}
      <Grid container item xs={12} sm={formik.values.expense_type === 'job_supplies/materials' ? 12 : 6} alignItems={'end'}>
        {isJobDataFetched && availableJobs && !!availableJobs.jobs.length ? (
          <div className="flex flex-col w-full">
            <InputLabel htmlFor="job">Job</InputLabel>
            {!!jobData.id.length ? (
              <OutlinedInput
                id="job_name"
                value={formik.values.job?.name}
                name="job_name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                fullWidth
              />
            ) : (
              <Autocomplete
                id="job"
                disableClearable
                value={availableJobs.jobs.find((job) => job.job_name === formik.values?.job?.name)}
                getOptionLabel={(option) => option?.job_name ?? ''}
                onChange={(event, newValue) => {
                  formik.setFieldValue('job.name', newValue.job_name);
                  formik.setFieldValue('job.id', newValue._id);
                }}
                options={availableJobs.jobs}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Job"
                    sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                  />
                )}
              />
            )}
            {getIn(formik.touched, 'job.name') && getIn(formik.errors, 'job.name') && (
              <FormHelperText error id="helper-text-job-name">
                {getIn(formik.errors, 'job.name')}
              </FormHelperText>
            )}
          </div>
        ) : (
          <Alert severity="info" action={<Button onClick={() => navigate('/jobs')}>Add Jobs</Button>} className="w-full">
            No jobs available.
          </Alert>
        )}
      </Grid>

      {/* -----------------------------------------EXPENSE TYPE------------------------------ */}
      {isExpenseTypeFetched && allExpenseTypeData && !!allExpenseTypeData.length && (
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="expense_type">Expense Type*</InputLabel>
          <Autocomplete
            id="expense_type"
            disableClearable
            value={allExpenseTypeData.find((expense: { label: string; value: string }) => expense.value === formik.values.expense_type)}
            getOptionLabel={(option) => option.label}
            onChange={handleExpenseTypeChange}
            options={expenseTypeOption}
            renderInput={(params) => (
              <TextField
                {...params}
                error={formik.touched.expense_type && !!formik.errors.expense_type}
                helperText={formik.touched.expense_type && formik.errors.expense_type}
                placeholder="Expense Type"
                sx={{
                  '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary }
                }}
              />
            )}
          />
        </Grid>
      )}
      {/* -----------------------------Materials------------------------------ */}
      {formik.values.expense_type === 'job_supplies/materials' && (
        <Grid container item xs={12} sm={6} alignItems={'end'}>
          {isMaterialDataFetched && allMaterialsData?.materials.length ? (
            <div className="flex flex-col w-full">
              <InputLabel htmlFor="material">Material*</InputLabel>
              <Autocomplete
                id="material"
                disableClearable
                value={allMaterialsData.materials.find((eachMaterial) => eachMaterial._id === formik.values?.material?.id)}
                onChange={(event: SyntheticEvent<Element, Event>, newValue: TMaterialData) => {
                  formik.setFieldValue('material', { id: newValue._id, description: newValue.description });
                }}
                getOptionLabel={(eachOption) => eachOption.description}
                options={allMaterialsData.materials}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Material"
                    sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                  />
                )}
              />
              {getIn(formik.touched, 'material.id') && getIn(formik.errors, 'material.id') && (
                <FormHelperText error id="helper-text-company-name">
                  {getIn(formik.errors, 'material.id')}
                </FormHelperText>
              )}
            </div>
          ) : (
            <Alert
              severity="info"
              action={<Button onClick={() => navigate('/settings/materials-supplies')}>Add Material</Button>}
              className="w-full"
            >
              No material added.
            </Alert>
          )}
        </Grid>
      )}

      {/* -----------------------------DESCRIPTION------------------------------ */}
      <Grid item xs={12}>
        <InputLabel htmlFor="description">Description</InputLabel>
        <TextField
          id="description"
          fullWidth
          placeholder="Description"
          name="description"
          multiline
          rows={3}
          onChange={trimFc(formik)}
          value={formik.values?.description}
        />
      </Grid>
      {/* -----------------------------DATE------------------------------ */}
      <Grid item xs={12} sm={6}>
        <InputLabel htmlFor="date">Date*</InputLabel>
        <DatePicker
          maxDate={dayjs(new Date())}
          className="w-full"
          value={formik.values.date ? dayjs(formik.values.date) : null}
          onChange={(newValue: Dayjs | null) => {
            if (newValue?.isValid()) formik.setFieldValue('date', newValue !== null ? newValue.toDate() : null);
          }}
        />
      </Grid>
      {/* -----------------------------PRICE------------------------------ */}
      <Grid item xs={12} sm={6}>
        <InputLabel htmlFor="price" required>
          Price
        </InputLabel>

        <TextField
          id="price"
          value={formik.values.price}
          name="price"
          onBlur={(event) => {
            const numericValue = parseFloat(event.target.value);
            if (!isNaN(numericValue)) {
              formik.setFieldValue('price', numericValue.toFixed(2));
            }
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            formik.setFieldValue('price', formatCostInDecimal(event.target.value, 2));
            if (formik.values.reimbursed_expense) formik.setFieldValue('reimbursed_amount', formatCostInDecimal(event.target.value, 2));
          }}
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
          error={Boolean(formik.touched.price && formik.errors.price)}
          helperText={formik.touched.price && formik.errors.price}
        />
      </Grid>
      {/* -----------------------------Number of units------------------------------ */}
      {formik.values.expense_type === 'job_supplies/materials' && (
        <Grid container item xs={12} sm={6}>
          <InputLabel htmlFor="units">
            Number of units&nbsp;
            {!!formik.values.material?.id.length &&
              '- ' + allMaterialsData?.materials.find((eachMaterial) => eachMaterial._id === formik.values.material?.id)?.unit_of_measure}
          </InputLabel>
          <TextField
            disabled={!formik?.values?.material?.id?.length}
            value={formik.values.units}
            defaultValue={0}
            type="number"
            id="units"
            name="units"
            onBlur={formik.handleBlur}
            fullWidth
            onChange={handlePriceChange}
          />
        </Grid>
      )}
      {/**------------------Does Somebody Need To Get Reimbursed for These Expenses?--------------------------- */}
      <Grid item xs={12}>
        <div className="flex flex-col md:flex-row md:space-x-2 space-y-2 md:space-y-0 md:items-center">
          <div className="break-all">Does Somebody Need To Get Reimbursed for These Expenses?</div>
          {formik.values.reimbursed_expense === false &&
            !['education/training', 'payroll_compensation', 'bonuses'].includes(formik.values.expense_type) && (
              <Alert color="warning" severity="info" variant="border" className="w-fit h-12 text-xs sm:text-sm">
                No reimbursement
              </Alert>
            )}
        </div>
        <RadioGroup
          row
          aria-label="reimbursed_expense"
          onChange={handleChangeIsReimbursed}
          name="reimbursed_expense"
          id="reimbursed_expense"
          value={`${formik.values.reimbursed_expense}`}
        >
          <FormControlLabel
            disabled={['education/training', 'payroll_compensation', 'bonuses'].includes(formik.values.expense_type)}
            value={false}
            control={<Radio />}
            label={'No'}
          />
          <FormControlLabel
            disabled={['education/training', 'payroll_compensation', 'bonuses'].includes(formik.values.expense_type)}
            value={true}
            control={<Radio />}
            label={'Yes'}
          />
        </RadioGroup>
      </Grid>
      {/* -----------------------------REIMBURSED AMOUNT------------------------------ */}
      {formik.values.reimbursed_expense && (
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="Reimbursed" required>
            Reimbursed Amount
          </InputLabel>

          <TextField
            id="reimbursed_amount"
            value={formik.values.reimbursed_amount}
            name="reimbursed_amount"
            onBlur={(event) => {
              const numericValue = parseFloat(event.target.value);
              if (!isNaN(numericValue)) {
                formik.setFieldValue('reimbursed_amount', numericValue.toFixed(2));
              }
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              formik.setFieldValue('reimbursed_amount', formatCostInDecimal(event.target.value, 2));
            }}
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>
            }}
            error={Boolean(formik.touched.reimbursed_amount && formik.errors.reimbursed_amount)}
            helperText={formik.touched.reimbursed_amount && formik.errors.reimbursed_amount}
          />
        </Grid>
      )}
      {/* -----------------------------Reimbursed User------------------------------ */}
      {formik.values.reimbursed_expense && (
        <Grid item xs={12} sm={6}>
          <div className="text-xs break-all">Who needs to be reimbursed for this expense?*</div>
          {allUsersData ? (
            <Autocomplete
              id="reimbursed_user"
              disableClearable
              disabled={!user?.permissions?.includes('getUsers')}
              value={allUsersData.users.find((eachUser) => eachUser._id === formik.values?.reimbursed_user?.id)}
              onChange={(event: SyntheticEvent<Element, Event>, newValue: TUserData) => {
                formik.setFieldValue('reimbursed_user', { id: newValue._id, name: `${newValue.first_name} ${newValue.last_name}` });
              }}
              getOptionLabel={(eachOption) => `${eachOption.first_name} ${eachOption.last_name}`}
              options={allUsersData.users}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Reimbursed User"
                  sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                />
              )}
            />
          ) : (
            <OutlinedInput
              id="reimbursed_user"
              disabled={!user?.permissions?.includes('getUsers')}
              value={formik.values.reimbursed_user?.name}
              name="reimbursed_user"
              fullWidth
              error={Boolean(formik.touched.reimbursed_user && formik.errors.reimbursed_user && formik.values.reimbursed_user)}
            />
          )}
          {getIn(formik.touched, 'reimbursed_user.id') && getIn(formik.errors, 'reimbursed_user.id') && (
            <FormHelperText error id="helper-text-company-name">
              {getIn(formik.errors, 'reimbursed_user.id')}
            </FormHelperText>
          )}
        </Grid>
      )}

      {/* -----------------------------Media------------------------------ */}
      {formik.values.media.length > 0 && (
        <Grid item xs={12}>
          <div className="flex flex-wrap items-center justify-start gap-x-3">
            {formik.values.media.map((eachImage) => (
              <div className="relative min-h-max">
                <IconButton
                  className="absolute top-0 right-0  bg-red-100/70"
                  color="error"
                  size="large"
                  onClick={() => {
                    formik.setFieldValue(
                      'media',
                      formik.values.media.filter((eachMedia) => eachMedia !== eachImage)
                    );
                  }}
                >
                  <DeleteFilled />
                </IconButton>
                <CardMedia key={eachImage} component="img" src={eachImage} alt="Image" className="h-40 w-40" />
              </div>
            ))}
          </div>
        </Grid>
      )}

      {/*---end---*/}
      <Grid item xs={12}>
        <div className="flex flex-row items-center justify-between mt-5 space-x-3">
          <input
            key={Math.random()}
            style={{ display: 'none' }}
            id="expense-attachment"
            type="file"
            accept="image/*"
            capture="environment"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileUpload(event)}
          />
          <label htmlFor="expense-attachment">
            <LoadingButton
              loading={isFileUploading}
              variant="dashed"
              color="primary"
              component="span"
              className="min-w-max"
              startIcon={<CameraFilled />}
              disabled={isFileUploading}
            >
              Add Media
            </LoadingButton>
          </label>

          {formik.submitCount > 0 && Object.keys(formik.errors).length > 0 && (
            <Alert severity="error" className=" text-md">
              {Object.keys(formik.errors).length === 1 &&
              Object.keys(formik.errors).includes('reimbursed_user') &&
              user?.permissions &&
              !user?.permissions.includes('getUsers')
                ? "You don't have permission to read users."
                : 'You have missed something'}
            </Alert>
          )}
          <Button variant="contained" disabled={formik.isSubmitting} startIcon={formik.isSubmitting && <LoadingOutlined />} type="submit">
            Submit
          </Button>
        </div>
      </Grid>

      {reimbursedAmountPopup.action.open && (
        <UniversalDialog
          title="Reimbursed Amount"
          action={{ open: reimbursedAmountPopup.action.open, maxWidth: 'xs', fullWidth: true }}
          onClose={() => {
            formik.setFieldValue('reimbursed_expense', true);
            setReimbursedAmountPopup({ action: { open: false } });
          }}
          onSave={() => {
            formik.setFieldValue('reimbursed_expense', false);
            formik.setFieldValue('reimbursed_user', { id: '', name: '' });
            formik.setFieldValue('reimbursed_amount', null as unknown as number);
            setReimbursedAmountPopup({ action: { open: false } });
          }}
          primaryButonTitle="Yes"
          secondaryButonTitle="No"
          hasSecondaryButton={true}
          handleSecondaryClick={() => {
            formik.setFieldValue('reimbursed_expense', true);
            setReimbursedAmountPopup({ action: { open: false } });
          }}
        >
          <Typography variant="body1">
            You are not getting reimbursed for this expense. Are you sure about this? <br />
          </Typography>
        </UniversalDialog>
      )}
    </Grid>
  );
};

export default AddExpenseForm;
