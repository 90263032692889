import { InboxOutlined } from '@ant-design/icons';
import {
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { TPayment } from 'components/tables/interfaces/jobTableInterface';
import { TJobInfo } from 'components/widgets/types/jobInfoType';
import useTimezone from 'hooks/useTimezone';
import { useState } from 'react';
import { useSelector } from 'store';
import { formatCurrency, getStateAbbrevation, toTitleCase } from 'utils/common';
import { invoicePdfMessage } from 'utils/constants';

const InvoiceSection = (props: { jobData?: TJobInfo }) => {
  //---------------------------Constants-----------------------
  const { jobData } = props;
  const [selectedPaymentIndex, setSelectedPaymentIndex] = useState<number | null>(null); // Initial value can be adjusted based on your requirements
  const formatDateTime = useTimezone();
  const settingsState = useSelector((state) => state.settings.generalSettings);
  //------------------------------------Handlers---------------------------
  const handleDialogClose = () => {
    setSelectedPaymentIndex(null);
  };
  //------------------------------------Component---------------------------
  const ImageDialog = () => {
    return (
      <Dialog open={selectedPaymentIndex !== null} onClose={handleDialogClose}>
        <DialogContent>
          <CardMedia component="img" src={jobData?.job?.payments?.[selectedPaymentIndex ?? 0]?.cheque_image} alt="Image" />
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Grid container spacing={2}>
      {/*-----------------------------------TRANSACTION ACTIVITY------------------------*/}
      {!!jobData?.job.payments?.length && (
        <Grid item xs={12}>
          <TableContainer>
            <Typography variant="h6" className="p-3 font-semibold text-base" sx={{ backgroundColor: '#2cc4cb', color: 'white' }}>
              Transaction Activity
            </Typography>
            <Table className="border border-grey">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Payment Type</TableCell>
                  <TableCell align="left">Amount Paid</TableCell>
                </TableRow>
              </TableHead>
              {jobData?.job?.payments ? (
                <TableBody>
                  {jobData?.job?.payments?.map((paymentData: TPayment, index: number) => {
                    return (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="left">
                          {paymentData.date &&
                            formatDateTime(paymentData.date, {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: settingsState?.time_format === '12hrs'
                            })}
                        </TableCell>
                        <TableCell align="left">
                          {paymentData.payment_type === 'cheque' ? 'Check' : toTitleCase(paymentData.payment_type as string)}
                        </TableCell>
                        <TableCell align="left" className="text-base font-mono">
                          ${formatCurrency(paymentData.amount)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableRow>
                  <TableCell colSpan={12}>
                    <CardContent className="w-full">
                      <Stack className="mt-4 flex flex-col items-center gap-2">
                        <InboxOutlined style={{ height: 20, transform: 'scale(3)', color: 'GrayText' }} />
                        <Typography color={'GrayText'}>No Data</Typography>
                      </Stack>
                    </CardContent>
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </TableContainer>
        </Grid>
      )}

      {/*-----------------------------------------------Invoice Payment Component */}
      <Grid xs={12} item>
        <div className="flex flex-row justify-end p-4">
          <div className="flex flex-col">
            {jobData?.payment_info_on_invoice ? (
              <Stack spacing={2}>
                <div>
                  <Typography align="left" sx={{ fontWeight: 'bold' }} variant="body1">
                    {'Please Make Check Payable To:'}
                  </Typography>
                  <Typography align="left" className="text-gray-600">
                    {jobData?.company.company_name}
                  </Typography>
                </div>
                <div>
                  <Typography align="left" sx={{ fontWeight: 'bold' }} variant="body1">
                    {'Send Payment To:'}
                  </Typography>
                  <Typography className="text-gray-600" align="left">
                    {jobData?.job.address?.address1},{jobData?.job.address?.address2}
                  </Typography>
                  <Typography className="text-gray-600" align="left">
                    {jobData?.job.address?.city},{!!jobData?.job.address?.state && getStateAbbrevation(jobData?.job.address?.state)},
                    {jobData?.job.address?.zip}
                  </Typography>
                </div>
              </Stack>
            ) : (
              <></>
            )}
          </div>
        </div>
        <Typography textAlign={'center'} variant="h4">
          {invoicePdfMessage}
        </Typography>
      </Grid>
      {selectedPaymentIndex !== null && <ImageDialog />}
    </Grid>
  );
};

export default InvoiceSection;
