import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import Loader from 'components/Loader';
import { IEstimationOption, TJobData, TJobItem } from 'components/tables/interfaces/jobTableInterface';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import JobServicesInstance from 'services/services.jobs';
import { dispatch, useSelector } from 'store';
import { setCurrentSelectedOption } from 'store/reducers/customReducers/slice.estimateOptions';
import { categorizeEstimationOptions, formatCurrency } from 'utils/common';
import { jobStates } from 'utils/constants';
import { TStateView } from '../JobsViewPage';
import SingleEstimateOptionView from './SingleEstimateOptionView';

type TViewEstimateOptions = {
  existingJobData?: TJobData;
  stateView?: TStateView;
};

const ViewEstimateOptions = (props: TViewEstimateOptions) => {
  //------------------------Constants----------------
  const theme = useTheme();
  const location = useLocation();
  const jobId = location.pathname.split('/').pop();
  const currentSelectedOption = useSelector((state) => state.estimateOptions.currentSelectedOption);

  const [activeTab, setActiveTab] = useState(0);
  const [dataByCategory, setDataByCategory] = useState<{ [key: string]: TJobItem[] }[]>([]); //It will be an array of objects and each keys in object will represent a separate job category, a single key will contain items related to it.
  const [categoryTotal, setCategoryTotal] = useState<{ [key: string]: number }[]>([]);

  const [jobData, setJobData] = useState<TJobData>();

  //-------------------------useQuery-----------------
  const { data: fetchedJobData, isLoading: isEstimateLoading } = useQuery({
    queryKey: ['single_job_data', jobId, props?.existingJobData],
    queryFn: () => JobServicesInstance.getSingleJobs(jobId),
    enabled: !!jobId && !props?.existingJobData
  });

  useEffect(() => {
    if (fetchedJobData && !props?.existingJobData) {
      setJobData(fetchedJobData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedJobData]);

  useEffect(() => {
    if (props?.existingJobData) {
      const dummyData: any = props?.existingJobData;
      if (!!dummyData.job.estimations && dummyData.job.previous_states?.includes(jobStates.PROPOSAL_APPROVED)) {
        dummyData.job.estimations.options = props?.existingJobData.job?.estimations?.options?.filter((eachOption) => eachOption.approved);
      }
      setJobData(dummyData);
    }
  }, [props?.existingJobData]);

  //-----------------------------useEffects---------------

  useEffect(() => {
    if (jobData?.job.estimations && !!jobData?.job.estimations?.options.length) {
      const formatedData = categorizeEstimationOptions(jobData.job.estimations.options);
      setCategoryTotal(formatedData.category_total);
      setDataByCategory(formatedData.data_by_category);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobData]);

  const handleSelectedOption = (selectedOptionId: string) => {
    dispatch(setCurrentSelectedOption(selectedOptionId));
  };

  return isEstimateLoading ? (
    <Loader />
  ) : (
    <div className="p-2">
      {props?.stateView === 'worker_info' &&
        jobData?.job?.job_action?.map((singleJobAction) => (
          <Typography className=" font-semibold">
            F.W. Commission {`(${singleJobAction?.field_worker?.name}): `}
            &#36;{formatCurrency(singleJobAction.worker_commission)}
          </Typography>
        ))}
      {props?.stateView === 'estimate_info' && jobData?.job.estimator_commission !== undefined && (
        <Typography className="font-semibold">
          Est. Commission {`(${jobData.job.estimator?.name}): `}
          &#36;{formatCurrency(jobData?.job.estimator_commission)}
        </Typography>
      )}
      <Box sx={{ borderBottom: 1, borderColor: 'divider', pb: 4 }} className="space-y-4 mt-4">
        <Tabs value={activeTab} className="bg-gray-50" variant="scrollable" scrollButtons="auto">
          {jobData?.job.estimations?.options.map((singleOption, optionIndex) => (
            <div key={optionIndex} className="flex items-center" style={{ position: 'relative' }}>
              <Tab
                label={singleOption.option_name}
                value={optionIndex}
                sx={{
                  color: singleOption.id === currentSelectedOption ? theme.palette.primary.lighter : 'primary.main',
                  padding: 0,
                  paddingX: 1,
                  backgroundColor: singleOption.id === currentSelectedOption ? theme.palette.primary.main : 'inherit',
                  borderRadius: 0
                }}
                onClick={() => {
                  if (singleOption.id) {
                    handleSelectedOption(singleOption.id);
                  }
                  setActiveTab(optionIndex);
                }}
              />
            </div>
          ))}
        </Tabs>
        <SingleEstimateOptionView
          stateView={props?.stateView}
          activeTab={activeTab}
          optionData={jobData?.job.estimations?.options[activeTab] ?? ({} as IEstimationOption)}
          currentStatus={
            jobData?.job.state === jobStates.PROPOSAL_DECLINED
              ? 'Rejected'
              : jobData?.job.previous_states?.includes('proposal_approved')
              ? 'Approved'
              : jobData?.job.previous_states?.includes('proposal_sent')
              ? 'Proposal Sent'
              : jobData?.job.previous_states?.includes('estimation')
              ? 'Estimate created'
              : ''
          }
          estimator={jobData?.job.estimator?.name ?? 'Not Assigned'}
          dataByCategory={dataByCategory}
          categoryTotal={categoryTotal}
          jobData={jobData}
          office_to_worker_notes={jobData?.job.office_to_worker_notes ?? []}
        />
      </Box>
    </div>
  );
};

export default ViewEstimateOptions;
