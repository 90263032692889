// material-ui
import { styled, Theme, useTheme } from '@mui/material/styles';

// types
import { useMediaQuery } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ThemeDirection } from 'types/config';
import { TCalendar } from '../types/types.calendar';

// ==============================|| CALENDAR - STYLED ||============================== //

const CalendarStyled = styled('div')(({ calendarAllData }: { calendarAllData: unknown[] }) => {
  //-------------constants------------------
  const theme = useTheme();
  const matchDownSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { calendar_tab: selectedTab } = useParams();

  const [dayCellContent, setDayCellContent] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    if (!!calendarAllData && !!calendarAllData.length) {
      let newDayCellContent: { [key: string]: any } = {};
      switch (selectedTab) {
        case 'job_calendar':
        case 'estimate_calendar':
        case 'archive_calendar':
        case 'town_calendar':
          (calendarAllData as unknown as TCalendar[]).forEach((eachDayData: TCalendar) => {
            newDayCellContent[`.day-${moment(eachDayData.date).format('YYYY-MM-DD')}`] = {
              outline: `2px solid ${eachDayData.bg_color}`,
              outlineOffset: '-2px'
            };
          });
          break;
        case 'blackout_days':
          (calendarAllData as unknown as string[]).forEach((eachDayData: string) => {
            newDayCellContent[`.day-${eachDayData}`] = {
              outline: `2px solid red`,
              backgroundColor: theme.palette.error.main
            };
          });
          break;
        default:
          break;
      }
      setDayCellContent(newDayCellContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarAllData, selectedTab]);

  return {
    ...dayCellContent,
    '.event_title': {
      outline: `2px solid ${theme.palette.success.light}`,
      outlineOffset: '-2px'
    },
    width: 'calc(100% + 2px)',
    overflow: 'scroll',
    marginLeft: -1,
    marginBottom: '-50px',
    '& .fc .fc-scrollgrid': { ...(matchDownSM && { width: '1100px', overflow: 'scroll' }) },
    // hide license message
    '& .fc-license-message': {
      display: 'none'
    },
    '& .fc .fc-daygrid .fc-scroller-liquid-absolute': {
      overflow: 'hidden !important'
    },
    // basic style
    '& .fc': {
      '--fc-bg-event-opacity': 1,
      '--fc-border-color': theme.palette.divider,
      '--fc-daygrid-event-dot-width': '10px',
      '--fc-today-bg-color': theme.palette.primary.lighter,
      '--fc-list-event-dot-width': '10px',
      '--fc-event-border-color': theme.palette.primary.dark,
      '--fc-now-indicator-color': theme.palette.error.main,
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.paper,
      fontFamily: theme.typography.fontFamily
    },
    // date text
    '& .fc .fc-daygrid-day-top': {
      display: 'grid',
      '& .fc-daygrid-day-number': {
        textAlign: 'center',
        marginTop: 12,
        marginBottom: 12
      }
    },

    // weekday
    '& .fc .fc-col-header-cell': {
      backgroundColor: theme.palette.primary.main
    },

    '& .fc .fc-col-header-cell-cushion': {
      color: theme.palette.grey[900],
      padding: 16
    },

    // events
    '& .fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start': {
      marginLeft: 4,
      marginBottom: 6,
      borderRadius: 4,
      backgroundColor: theme.palette.primary.main,
      border: 'none'
    },

    '& .fc-h-event .fc-event-main': {
      padding: 4,
      paddingLeft: 8
    },

    // popover when multiple events
    '& .fc .fc-more-popover': {
      border: 'none',
      borderRadius: 6,
      zIndex: 1200
    },

    '& .fc .fc-more-popover .fc-popover-body': {
      maxWidth: '200px',
      backgroundColor: theme.palette.grey[200],
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4
    },

    '& .fc .fc-popover-header': {
      padding: 12,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.text.primary
    },

    // agenda view
    '& .fc-theme-standard .fc-list-day-cushion': {
      backgroundColor: theme.palette.grey[100]
    },

    '& .fc .fc-day': {
      cursor: selectedTab === 'blackout_days' ? 'pointer' : 'auto',
      ...(matchDownSM && { width: '150px' })
    },

    '& .fc .fc-timeGridDay-view .fc-timegrid-slot': {
      backgroundColor: theme.palette.background.paper
    },

    '& .fc .fc-timegrid-slot': {
      cursor: 'pointer'
    },

    '& .fc .fc-list-event:hover td': {
      // cursor: 'pointer',
      backgroundColor: theme.palette.grey[100]
    },

    '& .fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link': {
      padding: 8,
      margin: 2
    },
    ...(theme.direction === ThemeDirection.RTL && { overflow: 'hidden' })
  };
});

export default CalendarStyled;
