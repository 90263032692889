import { Box, Grid, Skeleton } from '@mui/material';

const CalendarSkeleton = () => (
  <Box p={3}>
    <Box display="flex" justifyContent="space-between" mb={2}>
      <Skeleton variant="rectangular" width={100} height={30} />
      <Skeleton variant="rectangular" width={200} height={30} />
      <Skeleton variant="rectangular" width={100} height={30} />
    </Box>
    <Grid container spacing={1} mb={1}>
      {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, index) => (
        <Grid item xs={1.7} key={index}>
          <Skeleton variant="rectangular" width="100%" height={40} />
        </Grid>
      ))}
    </Grid>
    <Box>
      {[...Array(5)].map((_, weekIndex) => (
        <Grid container spacing={1} key={weekIndex} mb={1}>
          {[...Array(7)].map((_, dayIndex) => (
            <Grid item xs={1.7} key={dayIndex}>
              <Skeleton variant="rectangular" width="100%" height={120} />
            </Grid>
          ))}
        </Grid>
      ))}
    </Box>
  </Box>
);

export default CalendarSkeleton;
