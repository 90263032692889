import { DialogProps, Grid, InputLabel, Stack } from '@mui/material';
import { DatePicker, LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { dispatch, useSelector } from 'store';
import { getPunchDetails } from 'store/reducers/punching';
import { TUniversalDialogPropsWActions } from 'types/globalTypes/type.universalDialog';
import UniversalDialog from './UniversalDialog';

type TPopupActionProps = { open: boolean; fullWidth: boolean; maxWidth: DialogProps['maxWidth'] };

const SpecifyPunchTimePopup = (
  props: TUniversalDialogPropsWActions & {
    isClockedIn: boolean;
    specifyTimePopup: TPopupActionProps;
    handlePunchClick: (date: string, time: string, is_specified_time: boolean) => Promise<void>;
  }
) => {
  const punching = useSelector((state) => state.punching);
  const [specifiedTime, setSpecifiedTime] = useState<{ date: Dayjs | null; time: Dayjs | null }>({ date: dayjs(), time: dayjs() });

  // -------------handlers--------------

  const handleSpecifyTimeSubmit = async () => {
    if (specifiedTime.date && specifiedTime.time) {
      const { date, time } = specifiedTime;
      const updatedTime = time.set('year', date.year()).set('month', date.month()).set('date', date.date());
      await props.handlePunchClick(specifiedTime.date.toISOString(), updatedTime.toISOString(), true);
      dispatch(getPunchDetails());
    }
    props.onClose();
  };

  useEffect(() => {
    if (!props.isClockedIn) {
      setSpecifiedTime((prev) => ({ ...prev, date: dayjs() }));
    } else {
      const time = dayjs(punching.punchingDetails?.todaysPunch?.date).set('hour', dayjs().hour()).set('minute', dayjs().minute());
      setSpecifiedTime((prev) => ({ ...prev, date: dayjs(punching.punchingDetails?.todaysPunch?.date), time }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isClockedIn, props.specifyTimePopup.open]);

  return (
    <UniversalDialog {...props} primaryButonTitle="Submit" onClose={() => props.onClose()} onSave={handleSpecifyTimeSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Stack>
            <InputLabel>{props.isClockedIn ? 'Clock Out' : 'Clock In'} Date</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={specifiedTime.date}
                maxDate={dayjs(new Date())}
                onChange={(newValue: Dayjs | null) => {
                  if (newValue?.isValid() && newValue) setSpecifiedTime((prev) => ({ ...prev, date: newValue }));
                }}
                disabled={props.isClockedIn}
              />
            </LocalizationProvider>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack>
            <InputLabel>{props.isClockedIn ? 'Clock Out' : 'Clock In'} Time</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileTimePicker
                value={specifiedTime.time}
                openTo="hours"
                onChange={(newValue: dayjs.Dayjs | null) => {
                  if (newValue?.isValid() && newValue) {
                    setSpecifiedTime((prev) => ({ ...prev, time: newValue }));
                  }
                }}
              />
            </LocalizationProvider>
          </Stack>
        </Grid>
      </Grid>
    </UniversalDialog>
  );
};

export default SpecifyPunchTimePopup;
