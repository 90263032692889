// import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import { ChangeEvent, useRef, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
// material-ui
import { Button, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
// project import
import IconButton from 'components/@extended/IconButton';

// assets
import {
  AlignCenterOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
  CloseOutlined,
  CloudUploadOutlined,
  ExclamationCircleFilled,
  FileImageOutlined,
  ScissorOutlined
} from '@ant-design/icons';
import CustomTooltip from 'components/CustomTooltip';
import FileUploadServiceInstance from 'services/services.fileUpload';
import { dataURLtoBlob } from 'utils/common';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1.25),
    paddingRight: theme.spacing(2)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = ({ children, onClose, ...other }: DialogTitleProps) => (
  <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
    {children}
    {onClose ? (
      <IconButton
        aria-label="close"
        onClick={onClose}
        color="secondary"
        sx={{
          position: 'absolute',
          right: 10,
          top: 10
        }}
      >
        <CloseOutlined />
      </IconButton>
    ) : null}
  </DialogTitle>
);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

type ImageCropProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (arg0: any) => void;
  dialogTitle: string;
  aspectRatio?: number;
  Image?: string;
};

const ImageCrop = (props: ImageCropProps) => {
  const { open, onClose, onSubmit, dialogTitle = null, aspectRatio = 1 } = props;
  /** selected image */
  const [selectedImage, setSelectedImage] = useState<string | null | undefined>(null);
  const [selectedImageName, setSelectedImageName] = useState<string>('');
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [isErrorInUploadImages, setIsErrorInUploadImages] = useState<boolean>(false);

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedImage(null);
    let selectedFile;
    if (event.target.files) {
      selectedFile = event?.target.files[0];
    }

    if (selectedFile) {
      if (!selectedFile.type.startsWith('image/')) {
        setIsErrorInUploadImages(true);
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target) {
          setSelectedImage(e.target.result ? String(e.target.result) : null);
        }
      };
      setSelectedImageName(selectedFile.name.replace(/ /g, '_'));
      reader.readAsDataURL(selectedFile);
    }
  };

  const cropperRef = useRef<ReactCropperElement>(null);

  const handleCrop = () => {
    const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas();
    if (croppedCanvas) {
      setSelectedImage(croppedCanvas.toDataURL());
    }
  };

  const handleFlipHorizontal = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      cropper.scaleX(cropper.getData().scaleX === 1 ? -1 : 1);
    }
  };

  const handleFlipVertical = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      cropper.scaleY(cropper.getData().scaleY === 1 ? -1 : 1);
    }
  };

  const handleRemove = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.destroy();
      setSelectedImage(null);
    }
  };

  const centerImage = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;

      const containerData = cropper.getContainerData();
      const cropBoxData = cropper.getCropBoxData();

      const left = (containerData.width - cropBoxData.width) / 2;
      const top = (containerData.height - cropBoxData.height) / 2;

      cropper.setCropBoxData({ left, top });
    }
  };

  const handleFileUploadSubmit = async () => {
    setIsFileUploading(true);
    if (selectedImage) {
      const blob = dataURLtoBlob(selectedImage);
      const response: { success: boolean; data?: any } | undefined = await FileUploadServiceInstance.uploadFile(blob, selectedImageName);
      if (response && response.success) {
        setIsFileUploading(false);
        onSubmit(response?.data);
      }
    }
  };

  const handleClose = () => {
    setSelectedImage(null);
    onClose();
  };

  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={'sm'} fullWidth>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {dialogTitle ? dialogTitle : 'Upload File'}
      </BootstrapDialogTitle>
      <DialogContent dividers sx={{ p: 3 }}>
        {!selectedImage ? (
          <div className="w-full flex flex-col space-y-2">
            {props?.Image && <CardMedia src={props?.Image} component={'img'} />}
            <div className="flex justify-center space-x-3">
              <Button component="label" startIcon={<FileImageOutlined />} variant="dashed">
                Upload New File <VisuallyHiddenInput type="file" accept="image/*" onChange={handleImageChange} />
              </Button>
              {isErrorInUploadImages && (
                <CustomTooltip message={`Please upload an image.`}>
                  <ExclamationCircleFilled className="text-xl text-red-500 " />
                </CustomTooltip>
              )}
            </div>
          </div>
        ) : (
          <>
            <Grid container spacing={2} className="mb-5">
              <Grid item>
                <Button variant="outlined" color="secondary" size="small" startIcon={<ScissorOutlined />} onClick={handleCrop}>
                  Crop
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" color="secondary" size="small" startIcon={<AlignCenterOutlined />} onClick={centerImage}>
                  Center
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" color="secondary" size="small" startIcon={<ArrowRightOutlined />} onClick={handleFlipHorizontal}>
                  Flip X
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" color="secondary" size="small" startIcon={<ArrowUpOutlined />} onClick={handleFlipVertical}>
                  Flip Y
                </Button>
              </Grid>
              {/* <Grid item>
                <Button variant="outlined" color="secondary" size="small" startIcon={<RetweetOutlined />} onClick={handleInvert}>
                  Invert
                </Button>
              </Grid> */}
              <Grid item>
                <Button variant="outlined" color="secondary" size="small" startIcon={<CloseOutlined />} onClick={handleRemove}>
                  Remove
                </Button>
              </Grid>
            </Grid>

            <Cropper
              viewMode={2}
              controls
              aspectRatio={aspectRatio}
              src={selectedImage}
              style={{ height: 'auto', width: '100%' }}
              initialAspectRatio={1}
              guides={false}
              ref={cropperRef}
            />
          </>
        )}
      </DialogContent>
      {selectedImage && (
        <DialogActions>
          <Button
            disabled={!selectedImage || isFileUploading}
            variant="contained"
            onClick={handleFileUploadSubmit}
            startIcon={<CloudUploadOutlined />}
            component="label"
          >
            Upload image
          </Button>
        </DialogActions>
      )}
    </BootstrapDialog>
  );
};

export default ImageCrop;
