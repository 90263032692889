import { createSlice } from '@reduxjs/toolkit';

type TLogs = {
  description: string;
  action: string;
  attachments: string[];
};
const initialState: TLogs[] = [] as TLogs[];

const logsAction = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    addLogs(state, action) {
      state.push(action.payload);
    },
    clearLogs(state) {
      state.splice(0, state.length);
    }
  }
});
export default logsAction.reducer;
export const { addLogs, clearLogs } = logsAction.actions;
