import { AxiosRequestConfig } from 'axios';
import { Chance } from 'chance';
import Loader from 'components/Loader';
import { APP_DEFAULT_PATH } from 'config';
import dayjs from 'dayjsConfig';
import useConfig from 'hooks/useConfig';
import jwtDecode from 'jwt-decode';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useLocation, useNavigate } from 'react-router';
import profileServicesInstance from 'services/service.profile';
import AuthServicesInstance from 'services/services.auth';
import PunchingServicesInstance, { TClockInData, TClockOutData } from 'services/services.punching';
import UserServicesInstance from 'services/services.users';
import { dispatch as dispatchStore, useSelector } from 'store';
import { LOGIN, LOGOUT, SET_OLD_USER, UPDATE_USER } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';
import { getGeneralSettings, setIsTodaysJob } from 'store/reducers/customReducers/slice.settings';
import { clockIn, clockOut, getPunchDetails } from 'store/reducers/punching';
import { AuthProps, JWTContextType, UserProfile } from 'types/auth';
import { ThemeMode } from 'types/config';
import { KeyedObject } from 'types/root';
import { default as axios } from 'utils/axios';
import { checkIsFollowupsAssigned, getAddressFromCoordinates, getCurrentLocation } from 'utils/common';

const chance = new Chance();

// constant
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  permissions: null
};

const verifyToken: (st: string) => boolean = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded: KeyedObject = jwtDecode(serviceToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */

  return decoded.exp > Date.now() / 1000;
};

export const setSession = (serviceToken?: string | null, location?: string) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    if (location !== '/register') {
      localStorage.clear();
    }
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate();
  const location = useLocation();
  const { onChangeMode, onChangeFontFamily } = useConfig();

  const punching = useSelector((state) => state.punching);

  useEffect(() => {
    const init = async () => {
      onChangeFontFamily("'Poppins', sans-serif");
      const serviceToken = window.localStorage.getItem('serviceToken');

      if (serviceToken && verifyToken(serviceToken)) {
        setSession(serviceToken);

        const response = await AuthServicesInstance.getMe();
        if (response.success) {
          const user = {
            ...response.data,
            subscription: response?.company?.subscription,
            company_name: response?.company?.company_name ?? '',
            company_url: response?.company?.company_url,
            setting: response.setting
          } as UserProfile;

          onChangeMode((user?.theme_mode as ThemeMode) ?? ThemeMode.LIGHT);

          const punchPermission: boolean = !!user.permissions?.some((eachPermission) =>
            ['upsertPunch', 'getPunch'].includes(eachPermission)
          );

          dispatchStore(getGeneralSettings());
          punchPermission && dispatchStore(getPunchDetails());
          const permissionsResponse = await AuthServicesInstance.getPermissions();
          if (permissionsResponse) {
            const permissions = permissionsResponse.data;
            dispatch({
              type: LOGIN,
              payload: {
                isLoggedIn: true,
                user,
                permissions: permissions
              }
            });
          }
        }
      } else {
        console.log('USER LOGGED OUT');
        dispatch({
          type: LOGOUT
        });
        setSession(null, location.pathname);
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async (email: string, password: string) => {
    const response = await axios.post('/api/auth/login', { email, password, login_as_another_user: false }, {
      locationNeeded: false
    } as AxiosRequestConfig);

    if (response.data.success) {
      const { token: serviceToken } = response.data.data;
      setSession(serviceToken);

      const permissionsResponse = await AuthServicesInstance.getPermissions();

      const meData = await AuthServicesInstance.getMe();

      if (meData.success && permissionsResponse) {
        const user = {
          ...meData.data,
          subscription: meData.company?.subscription ?? {},
          company_name: meData?.company?.company_name ?? '',
          company_url: meData?.company?.company_url ?? '',
          setting: meData.setting
        } as UserProfile;

        onChangeMode((user?.theme_mode as ThemeMode) ?? ThemeMode.LIGHT);

        if (user.rate_info?.punch_type === 'auto/system_calculator') {
          const location = await getCurrentLocation();
          const address = await getAddressFromCoordinates(Number(location.lat), Number(location.long));
          const punchData: TClockInData = {
            date: new Date().toISOString(),
            clock_in: {
              time: new Date().toISOString(),
              location: location,
              address: address
            },
            is_specified_time: false
          };

          const response = await PunchingServicesInstance.clockIn(punchData);
          if (response && response.success) {
            dispatchStore(clockIn(new Date().toISOString()));
          }
        }

        const punchPermission: boolean = !!user.permissions?.some((eachPermission) => ['upsertPunch', 'getPunch'].includes(eachPermission));

        punchPermission && dispatchStore(getPunchDetails());
        dispatchStore(getGeneralSettings());
        const permissions = permissionsResponse.data;
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user: user,
            permissions: permissions
          }
        });

        if (user.rate_info?.pay_type === 'hourly' && user.rate_info?.punch_type === 'clock_in/clock_out') {
          navigate('/punch');
          return;
        }

        let isFollowUpsAssigned;
        if (user.role && ['estimator', 'field_worker'].includes(user.role)) {
          isFollowUpsAssigned = await checkIsFollowupsAssigned(user.role);
        }
        if (isFollowUpsAssigned === false) {
          navigate('/jobs');
          dispatchStore(setIsTodaysJob(true));
        }
      }
    }
  };

  const loginAsAnotherUser = async (email: string) => {
    const oldToken: string | null = localStorage.getItem('serviceToken');
    if (oldToken) {
      localStorage.setItem('oldServiceToken', oldToken);
      localStorage.setItem('oldUser', JSON.stringify(state.user));
      localStorage.setItem('oldPermissions', JSON.stringify(state.permissions));
      dispatch({
        type: SET_OLD_USER,
        payload: {
          isLoggedIn: true,
          oldUser: state.user,
          oldPermissions: state.permissions
        }
      });
      const response = await UserServicesInstance.loginAsAnotherUser(email);

      if (response) {
        const { token: serviceToken } = response;
        setSession(serviceToken);

        const permissionsResponse = await AuthServicesInstance.getPermissions();

        const meData = await AuthServicesInstance.getMe();

        if (meData.success && permissionsResponse) {
          const user = {
            ...meData.data,
            subscription: meData.company?.subscription ?? {},
            company_name: meData?.company?.company_name ?? '',
            company_url: meData?.company?.company_url ?? '',
            setting: meData.setting
          } as UserProfile;

          onChangeMode((user?.theme_mode as ThemeMode) ?? ThemeMode.LIGHT);

          if (user.rate_info?.punch_type === 'auto/system_calculator') {
            const location = await getCurrentLocation();
            const address = await getAddressFromCoordinates(Number(location.lat), Number(location.long));
            const punchData: TClockInData = {
              date: new Date().toISOString(),
              clock_in: {
                time: new Date().toISOString(),
                location: location,
                address: address
              },
              is_specified_time: false
            };

            const response = await PunchingServicesInstance.clockIn(punchData);
            if (response && response.success) {
              dispatchStore(clockIn(dayjs().toISOString()));
            }
          }

          const punchPermission: boolean = !!user.permissions?.some((eachPermission) =>
            ['upsertPunch', 'getPunch'].includes(eachPermission)
          );

          punchPermission && dispatchStore(getPunchDetails());
          dispatchStore(getGeneralSettings());
          const permissions = permissionsResponse.data;
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: user,
              permissions: permissions
            }
          });

          if (user.rate_info?.punch_type === 'clock_in/clock_out') {
            navigate('/punch');
            return;
          }

          let isFollowUpsAssigned;
          if (user.role && ['estimator', 'field_worker'].includes(user.role)) {
            isFollowUpsAssigned = await checkIsFollowupsAssigned(user.role);
          }
          if (isFollowUpsAssigned === false) {
            navigate('/jobs');
            dispatchStore(setIsTodaysJob(true));
          } else navigate(APP_DEFAULT_PATH);
        }
      }
    }
  };

  const resetLogin = async () => {
    const oldToken = window.localStorage.getItem('oldServiceToken');
    const oldUser = localStorage.getItem('oldUser');
    const oldPermissions = localStorage.getItem('oldPermissions');
    if (oldToken) {
      if (oldUser && oldPermissions) {
        await AuthServicesInstance.logout();
        setSession(oldToken);
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user: JSON.parse(oldUser),
            permissions: JSON.parse(oldPermissions)
          }
        });
        navigate('settings/users');
        window.localStorage.removeItem('oldServiceToken');
        window.localStorage.removeItem('oldUser');
        window.localStorage.removeItem('oldPermissions');
        window.location.reload();
      }
    }
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    // todo: this flow need to be recode as it not verified
    const id = chance.bb_pin();
    const response = await axios.post('/api/auth/register', {
      id,
      email,
      password,
      firstName,
      lastName
    });
    let users = response.data;

    if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
      const localUsers = window.localStorage.getItem('users');
      users = [
        ...JSON.parse(localUsers!),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`
        }
      ];
    }

    window.localStorage.setItem('users', JSON.stringify(users));
  };

  const logout = async (is_auto_logout: boolean, punchType: string | undefined): Promise<boolean> => {
    try {
      if (punchType && ['auto/system_calculator', 'clock_in/clock_out'].includes(punchType)) {
        const location = await getCurrentLocation();
        const address = await getAddressFromCoordinates(Number(location.lat), Number(location.long));
        const punchData: TClockOutData = {
          clock_out: {
            time: new Date().toISOString(),
            location: location,
            address: address
          },
          is_auto_logout
        };
        const punchId = punching.punchingDetails?.todaysPunch?._id;
        if (!!punchId) {
          const response = await PunchingServicesInstance.clockOut(punchId as string, punchData);
          if (response) {
            dispatchStore(clockOut(new Date().toISOString()));
          }
        }
      }
      const res = await AuthServicesInstance.logout();
      if (res !== null || res !== undefined) {
        setSession(null);
        dispatch({ type: LOGOUT });
      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const resetPassword = async (email: string) => {
    return await AuthServicesInstance.forgotPassword(email);
  };

  const updateProfile = async (values: UserProfile) => {
    const response = await profileServicesInstance.updateUserProfile(values);
    dispatch({
      type: UPDATE_USER,
      payload: { isLoggedIn: true, user: response }
    });
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile, loginAsAnotherUser, resetLogin }}>
      {children}
    </JWTContext.Provider>
  );
};

export const useAuthState = () => {
  const contextValue = useContext(JWTContext);

  if (!contextValue) {
    throw new Error('AuthProvider not found.');
  }

  return contextValue;
};
export default JWTContext;
