import { DeleteOutlined, EditOutlined, PlusOutlined, QuestionCircleFilled } from '@ant-design/icons';
import {
  Autocomplete,
  Button,
  ClickAwayListener,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Stack, useMediaQuery } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import CustomTooltip from 'components/CustomTooltip';
import { TjobItem } from 'components/tables/interfaces/jobTableInterface';
import { TSelecetdJobCategory } from 'pages/jobs/types/types.estimateOptions';
import { ChangeEvent, useEffect, useState } from 'react';
import CategoriesProductAndServicesInstance from 'services/services.categoriesProductAndServices';
import { formateData } from 'utils/common';
import * as v4 from 'uuidv4';
import ItemsMeasureUnitFields from './ItemsMeasureUnitFields';
import { TItemsMeasurementFieldsOnChangeArg, TMeasurementUnits } from './types.jobItems';

type TAllJobItem = {
  id: string;
  value: string;
  label: string;
  worker_commission: number;
  description: string;
  description_to_field_worker: string;
  image: string;
  measure_type: string;
  item_details: string;
  isTaxable: boolean;
  customer_cost_per_unit: number;
  minimum_customer_cost: number;
};

export const AddJobItemForm = (props: {
  handleJobItems: (values: any) => void;
  onAddJobItem: () => void;
  categoryInfo: TSelecetdJobCategory;
  taxRate: number;
}) => {
  //--------------------Constants---------------------
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    handleJobItems,
    onAddJobItem,
    categoryInfo: { categoryId, categoryTitle }
  } = props;

  const [allJobItems, setAllJobItems] = useState<TAllJobItem[]>([]);

  const [showPriceInput, setShowPriceInput] = useState(false);
  const initialJobItems = {
    job_category: { name: categoryTitle, id: categoryId },
    image: '',
    service_and_product: { name: '', id: '' },
    worker_commission: 0,
    description: '',
    description_to_field_worker: '',
    units: '',
    taxable: 0,
    price: null as unknown as number,
    measure_type: '',
    measurements: null as unknown as TMeasurementUnits[],
    customer_cost_per_unit: 0,
    minimum_customer_cost: 0,
    id: v4.uuid(),
    show_units_on_proposal: true,
    item_details: ''
  };
  const [jobItems, setJobItems] = useState<TjobItem[]>([{ ...initialJobItems }]);

  //------------------------------useQuery----------------------------------
  const { data: jobProductData, isFetching: isJobProductDataFetching } = useQuery({
    queryKey: ['job_products', categoryId],
    queryFn: () => CategoriesProductAndServicesInstance.getServiceAndProductByJobCategory(categoryId),
    networkMode: 'always'
  });

  //-------------------------useEffects------------------------------

  useEffect(() => {
    if (jobProductData) {
      const dummyJobProductData: TAllJobItem[] = jobProductData.map((jobCategoryData) => ({
        id: jobCategoryData._id ? jobCategoryData._id : '',
        value: jobCategoryData.item_name,
        label: jobCategoryData.item_name,
        worker_commission: jobCategoryData.worker_commission_per_unit ?? 0,
        description: jobCategoryData.proposal_description,
        description_to_field_worker: jobCategoryData.description_to_field_worker,
        image: jobCategoryData.job_image,
        measure_type: jobCategoryData.measure,
        customer_cost_per_unit: jobCategoryData.customer_cost_per_unit,
        minimum_customer_cost: jobCategoryData.minimum_customer_cost,
        item_details: jobCategoryData.item_details,
        isTaxable: jobCategoryData.tax
      }));

      setAllJobItems(dummyJobProductData);
    }
  }, [jobProductData]);

  const handleJobFormSubmit = () => {
    const dummyJobItems = jobItems.map((item) => {
      return {
        ...item,
        price: item.price ? item.price : 0
      };
    });

    handleJobItems(dummyJobItems);
    onAddJobItem();
  };

  //--------------------------Handlers------------------------------
  const handleAddOrDelete = (value: string, index?: number) => {
    value === 'delete' && handleDeleteJobItem(index as number);
    value === 'add' && handleAddJobItem();
  };

  const handleDeleteJobItem = (itemIndex: number) => {
    const dummyItemData = jobItems.filter((item, index) => index !== itemIndex);
    setJobItems(dummyItemData);
  };

  const handleAddJobItem = () => {
    const dummyArr = [...jobItems];
    dummyArr.push({
      ...initialJobItems,
      id: v4.uuid(),
      show_units_on_proposal: true
    });
    setJobItems(dummyArr);
  };

  const handlePriceEdit = () => {
    setShowPriceInput(true);
  };

  const handlePriceBlur = () => {
    setShowPriceInput(false);
  };

  const handlePriceChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    if (!/^\d*\.?\d{0,2}$/.test(event.target.value)) {
      return;
    }
    const newJobItems = [...jobItems];
    newJobItems[index].price = event.target.value ? Number(event.target.value) : ('' as unknown as number);
    newJobItems[index].taxable = newJobItems[index].isTaxable ? calculateTax(Number(event.target.value)) : 0;
    setJobItems(newJobItems);
  };

  const handleDisplayOnProposal = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const newJobItems = [...jobItems];
    newJobItems[index].show_units_on_proposal = event.target.checked;
    setJobItems(newJobItems);
  };

  const calculateTax = (price: number) => {
    if (props?.taxRate !== undefined) {
      const taxableAmount = (price * props?.taxRate) / 100;
      return Number(taxableAmount.toFixed(2));
    }
    return 0;
  };

  const handleCalculatedPrice = (data: TItemsMeasurementFieldsOnChangeArg, index: number) => {
    const { price: totalItemsPrice, units: totalUnits, measurement_data } = data;
    const newJobItems = [...jobItems];
    newJobItems[index].price = totalItemsPrice;
    newJobItems[index].units = `${totalUnits}`;
    newJobItems[index].measurements = measurement_data;
    newJobItems[index].taxable = newJobItems[index].isTaxable ? calculateTax(totalItemsPrice) : 0;
    const selectedItem = allJobItems.find((singleItem) => singleItem.id === newJobItems[index].service_and_product.id);

    if (selectedItem)
      newJobItems[index].worker_commission = selectedItem?.worker_commission * (totalItemsPrice / selectedItem?.customer_cost_per_unit);
    setJobItems(newJobItems);
  };

  return (
    <Grid container spacing={6}>
      <Grid item container xs={12} spacing={4}>
        {jobItems.length > 0 &&
          jobItems.map((singleJobItem, itemIndex, jobItemsArray) => {
            return (
              <Grid item container spacing={2} key={singleJobItem.id}>
                <Grid item xs={10} sm={4}>
                  <Stack direction={isMobile ? 'row-reverse' : 'row'} alignItems={'end'}>
                    <IconButton onClick={() => handleAddOrDelete('delete', itemIndex)} color="error" disabled={jobItemsArray.length === 1}>
                      <DeleteOutlined />
                    </IconButton>

                    <Stack className="space-y-1 w-full">
                      <InputLabel htmlFor={`items[${itemIndex}].item_name`}>Job Item*</InputLabel>
                      <Autocomplete
                        fullWidth
                        id={`items[${itemIndex}].item_name`}
                        value={allJobItems.find((singleData) => singleData.value === singleJobItem.service_and_product.name)}
                        disableClearable
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        loading={isJobProductDataFetching}
                        onChange={(
                          event: React.SyntheticEvent<Element, Event>,
                          newValue: {
                            value: string;
                            label: string;
                            worker_commission: number;
                            description: string;
                            description_to_field_worker: string;
                            image: string;
                            measure_type: string;
                            customer_cost_per_unit?: number;
                            minimum_customer_cost?: number;
                            item_details: string;
                            id: string;
                            isTaxable: boolean;
                          }
                        ) => {
                          setJobItems((prev) =>
                            prev.map((item, index) =>
                              index === itemIndex
                                ? {
                                    ...item,
                                    item_details: newValue.item_details,
                                    service_and_product: { name: newValue.value, id: newValue.id },
                                    image: newValue.image,
                                    description: newValue.description,
                                    worker_commission: 0,

                                    description_to_field_worker: newValue.description_to_field_worker,
                                    measure_type: newValue.measure_type,
                                    customer_cost_per_unit: newValue.customer_cost_per_unit,
                                    minimum_customer_cost: newValue.minimum_customer_cost,
                                    units: '',
                                    price: 0,
                                    isTaxable: newValue.isTaxable
                                  }
                                : item
                            )
                          );
                        }}
                        options={allJobItems}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            placeholder="Item"
                            sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
                          />
                        )}
                      />
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {singleJobItem.measure_type && (
                    <Stack className="space-y-1">
                      <Stack direction="row" alignItems="center">
                        <InputLabel htmlFor={`items[${itemIndex}].units`}>
                          {singleJobItem.measure_type ? singleJobItem.measure_type.replace(/_/g, ' ') : 'Units'}*
                        </InputLabel>
                        <CustomTooltip
                          message={`If you want to enter the total ${
                            singleJobItem.measure_type ? singleJobItem.measure_type.replace(/_/g, ' ') : 'Units'
                          }, enter it in the first input field (L).`}
                          props={{ placement: 'top-start' }}
                        >
                          <QuestionCircleFilled className="ml-1" />
                        </CustomTooltip>
                      </Stack>
                      <ItemsMeasureUnitFields
                        key={singleJobItem.measure_type}
                        measuringType={singleJobItem.measure_type}
                        itemPrice={singleJobItem.customer_cost_per_unit ?? 0}
                        onChange={(data) => handleCalculatedPrice(data, itemIndex)}
                        isMultipleItemsAllowed={true}
                      />
                      <FormControlLabel
                        control={
                          <Switch checked={singleJobItem.show_units_on_proposal} onChange={(e) => handleDisplayOnProposal(e, itemIndex)} />
                        }
                        label="Display Units On Proposal"
                      />
                    </Stack>
                  )}
                </Grid>

                <Grid item xs={12} sm={2}>
                  {singleJobItem.price !== null && (
                    <>
                      <Stack className="flex flex-row justify-start w-full items-center">
                        <InputLabel htmlFor={`items[${itemIndex}].price`} className="text-gray-500">
                          Price
                        </InputLabel>
                        <IconButton color="primary" onClick={handlePriceEdit}>
                          <EditOutlined />
                        </IconButton>
                      </Stack>
                      {!showPriceInput ? (
                        <Typography variant="body1" className="break-all">
                          {formateData(singleJobItem.price ?? 0, '$')}
                        </Typography>
                      ) : (
                        <ClickAwayListener disableReactTree onClickAway={handlePriceBlur}>
                          <TextField
                            InputProps={{ startAdornment: <Typography>$</Typography> }}
                            inputMode="decimal"
                            type="number"
                            variant="outlined"
                            sx={{ padding: 0 }}
                            onBlur={handlePriceBlur}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => handlePriceChange(event, itemIndex)}
                            value={singleJobItem.price}
                          />
                        </ClickAwayListener>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Divider orientation="horizontal" />
                </Grid>
              </Grid>
            );
          })}
      </Grid>
      <Grid item xs={12} className="flex flex-row w-full items-end justify-between">
        <Button startIcon={<PlusOutlined />} color="primary" aria-label="close" onClick={() => handleAddOrDelete('add')} variant="text">
          Add More
        </Button>
        <Button
          type="submit"
          variant="contained"
          onClick={handleJobFormSubmit}
          disabled={jobItems.some(
            (eachItem) =>
              eachItem.service_and_product.id.length === 0 ||
              eachItem.measurements === null ||
              (eachItem.measurements?.length ? !eachItem.measurements[0].length : true)
          )}
        >
          Add Job Item
        </Button>
      </Grid>
    </Grid>
  );
};
