import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useQuery } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import { getIn, useFormik } from 'formik';
import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import AutoAppointmentServiceInstance from 'services/services.autoAppointment';
import * as yup from 'yup';
import ThankYouPage from '../ExternalFormWidgets/ThankYouPage';

const ScheduleAppointmentFromMail = () => {
  //-----------------constants---------------
  const [date, setDate] = useState(moment().format('YYYY-MM'));
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [isThankYouPage, setIsThankYouPage] = useState(false);
  const location = useLocation();
  const companyId = new URLSearchParams(location.search).get('company_id');
  const jobId = new URLSearchParams(location.search).get('job_id');

  const formik = useFormik({
    initialValues: {
      area: { id: '', name: '' },
      schedule_date: '',
      appointment_timeblock: {
        start_time: '',
        end_time: ''
      }
    },
    validationSchema: yup.object().shape({
      schedule_date: yup.string().required('This field is required'),
      appointment_timeblock: yup
        .object()
        .when([], () =>
          appointmentConfigData?.auto_appointment_schedule?.schedule.type === 'hour'
            ? yup.object().shape({ start_time: yup.string().required('Select a preferred time slot') })
            : yup.object().shape({ start_time: yup.string() })
        ),
      area: yup.object().shape({
        id: yup.string().required('This field is required')
      })
    }),
    onSubmit: async (values) => {
      const response =
        !!companyId && !!jobId && (await AutoAppointmentServiceInstance.scheduleWorker(jobId, { company_id: companyId, ...values }));
      if (response) {
        setIsThankYouPage(true);
      }
    }
  });
  //-----------------useQuery---------------

  const { data: appointmentConfigData, isFetched: isAutoAppointmentFetch } = useQuery({
    queryKey: ['appointment_config'],
    queryFn: () => {
      if (!!companyId) return AutoAppointmentServiceInstance.getAppointmentDetailsFromMail(companyId);
    },
    enabled: !!companyId
  });
  const { data: serviceAndAeraAvailableDates } = useQuery({
    queryKey: ['service_and_area_available_date', date, formik.values.area.id],
    queryFn: () => {
      if (!!companyId && !!formik.values.area.id)
        return AutoAppointmentServiceInstance.getServiceAndAreaAvailableDates(
          companyId,
          formik.values.area.id,
          '',
          moment(date).month() + 1,
          moment(date).year(),
          false
        );
    }
  });
  //-----------------Handlers---------------
  const handleScheduleTimeChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    const dates = value.split('&');
    formik.setFieldValue('appointment_timeblock', { start_time: dates[0], end_time: dates[1] });
  };
  const disableNotAvailableDates = (day: Dayjs) => {
    const formatedDate = day.format('YYYY-MM-DD');
    if ((!!availableDates && !availableDates.includes(formatedDate)) || day < dayjs()) {
      return true;
    }
    const currentDateData = serviceAndAeraAvailableDates?.find(
      (singleDate) => moment(singleDate.date).format('YYYY-MM-DD') === formatedDate
    );
    if (!!currentDateData) {
      if (appointmentConfigData?.auto_appointment_schedule.schedule.type !== 'hour')
        return appointmentConfigData?.auto_appointment_schedule.scheduling_limit.by_job_count === true
          ? currentDateData.count >= appointmentConfigData?.auto_appointment_schedule?.scheduling_limit.limit
          : currentDateData.price >= (appointmentConfigData?.auto_appointment_schedule?.scheduling_limit?.limit as number);
      return appointmentConfigData?.auto_appointment_schedule?.scheduling_limit.by_job_count
        ? !currentDateData.time_blocks.find(
            (singleTimeBlock) => singleTimeBlock.count < appointmentConfigData?.auto_appointment_schedule?.scheduling_limit.limit
          )
        : !currentDateData.time_blocks.find(
            (singleTimeBlock) => singleTimeBlock.price < appointmentConfigData?.auto_appointment_schedule?.scheduling_limit.limit
          );
    }
    return false;
  };
  //----------------useEffect--------------
  useEffect(() => {
    if (!!serviceAndAeraAvailableDates) {
      const dummyAvailableDates = serviceAndAeraAvailableDates.map((singleAvavilableDate) => singleAvavilableDate.date);

      setAvailableDates(dummyAvailableDates);
    }
  }, [serviceAndAeraAvailableDates]);

  return isThankYouPage ? (
    <ThankYouPage jobId={jobId ?? ''} />
  ) : (
    <div className="flex justify-center mt-24">
      <Card component={CardContent} sx={{ width: { xs: '500px', md: '700px' } }}>
        <CardHeader title={<Typography className="text-2xl font-semibold">Schedule Your Appointment</Typography>} className="border-b" />
        <Grid container spacing={2} component={'form'} onSubmit={formik.handleSubmit} className="py-2">
          {/*----------------------------------------choose your area---------------------------------*/}
          <Grid className="space-y-2" item xs={12}>
            <InputLabel>
              Choose Your&nbsp;{isAutoAppointmentFetch && !!appointmentConfigData && appointmentConfigData?.auto_appointment_schedule.area}*
            </InputLabel>
            <Autocomplete
              fullWidth
              disableClearable
              value={appointmentConfigData?.auto_appointment_areas?.find((singleArea) => singleArea._id === formik.values.area.id)}
              getOptionLabel={(option: { _id: string; area_name: string }) => option.area_name}
              onChange={(event, newValue) => formik.setFieldValue('area', { id: newValue?._id, name: newValue?.area_name })}
              options={appointmentConfigData?.auto_appointment_areas ?? []}
              renderInput={(params) => <TextField {...params} />}
            />
            {getIn(formik.touched, 'area.id') && getIn(formik.errors, 'area.id') && (
              <FormHelperText error id="area.id">
                {getIn(formik.errors, 'area.id')}
              </FormHelperText>
            )}
          </Grid>
          {/*----------------------------------------Schedule Date-----------------------*/}
          {!!formik.values.area.id.length && (
            <Grid item xs={12} md={6}>
              <InputLabel>Schedule Date*</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  shouldDisableDate={disableNotAvailableDates}
                  onMonthChange={(month: dayjs.Dayjs) => {
                    setDate(month.format('YYYY-MM'));
                  }}
                  className="w-full"
                  value={formik.values.schedule_date.length ? dayjs(formik.values.schedule_date) : null}
                  onChange={(newValue: Dayjs | null) => {
                    if (newValue?.isValid()) formik.setFieldValue('schedule_date', newValue.toISOString());
                  }}
                />
                {getIn(formik.touched, 'schedule_date') && getIn(formik.errors, 'schedule_date') && (
                  <FormHelperText error id="schedule_date">
                    {getIn(formik.errors, 'schedule_date')}
                  </FormHelperText>
                )}
              </LocalizationProvider>
            </Grid>
          )}
          {/*----------------------------------------Appointment Timeblocks-----------------------*/}

          {appointmentConfigData?.auto_appointment_schedule?.schedule.type === 'hour' && !!formik.values.schedule_date.length && (
            <Grid item xs={12} md={6} className="justify-start">
              <InputLabel>Schedule Time*</InputLabel>
              <RadioGroup
                value={`${formik.values?.appointment_timeblock?.start_time}&${formik.values?.appointment_timeblock?.end_time}`}
                name="appointment_timeblock"
                onChange={handleScheduleTimeChange}
              >
                {appointmentConfigData?.auto_appointment_schedule.schedule.type === 'hour' &&
                  appointmentConfigData?.auto_appointment_schedule?.schedule?.time_blocks?.map((singleTimeBlock) => (
                    <FormControlLabel
                      value={`${singleTimeBlock.start_time}&${singleTimeBlock.end_time}`}
                      control={
                        <Radio
                          disabled={serviceAndAeraAvailableDates?.some(
                            (singleAvailableDate) =>
                              singleAvailableDate.date === moment(formik.values.schedule_date).format('YYYY-MM-DD') &&
                              singleAvailableDate.time_blocks.some((timeBlock) => {
                                if (
                                  `${timeBlock.start_time}&${timeBlock.end_time}` ===
                                  `${singleTimeBlock.start_time}&${singleTimeBlock.end_time}`
                                )
                                  return appointmentConfigData?.auto_appointment_schedule.scheduling_limit.by_job_count
                                    ? timeBlock.count >= appointmentConfigData?.auto_appointment_schedule.scheduling_limit.limit
                                    : timeBlock.price >= appointmentConfigData?.auto_appointment_schedule.scheduling_limit.limit;
                              })
                          )}
                        />
                      }
                      label={`${moment(singleTimeBlock.start_time).format('hh:mm A')} - ${moment(singleTimeBlock.end_time).format(
                        'hh:mm A'
                      )}`}
                    />
                  ))}
                {getIn(formik.touched, 'appointment_timeblock.start_time') && getIn(formik.errors, 'appointment_timeblock.start_time') && (
                  <FormHelperText error id="appointment_timeblock.start_time">
                    {getIn(formik.errors, 'appointment_timeblock.start_time')}
                  </FormHelperText>
                )}
              </RadioGroup>
            </Grid>
          )}
          <Grid item xs={12} className="flex justify-end">
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default ScheduleAppointmentFromMail;
