import axios from 'axios';
import { IEstimationOption, TCustomerPhones, TJobAction, TJobCompleteData, TJobItem } from 'components/tables/interfaces/jobTableInterface';
import dayjs, { Dayjs } from 'dayjsConfig';
import moment from 'moment';
import numeral from 'numeral';
import { TCustomerData } from 'pages/customers/types/type.customersPage';
import { TSettingData } from 'pages/Settings/types/types.generalSettings';
import { CountryData } from 'react-phone-input-2';
import FollowupServicesInstance from 'services/services.followups';
import IndexedDBServices from 'services/services.localDb';
import LogsServicesInstance from 'services/services.logs';
import { TIndexedDBResponse, TLogs, TStoredAPICalls } from 'services/types/types.services';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { abbr } from 'us-state-converter';
import { characters } from 'utils/constants';
import { constants, defaultPermissionsForRoles, googleMapsApiKey, indexedDbStoreNames, jobNameFormats, jobStates } from './constants';

export const getAccessToken = () => {
  const accessToken = window.localStorage.getItem('serviceToken');
  if (accessToken !== null) {
    return accessToken;
  }
  return window.localStorage.getItem('serviceToken');
};

export function getLocationInfo() {
  return window.localStorage.getItem('locationNeeded') || 'false';
}

/*
export function flattenObject(obj: any) {
  const result: any = {};

  function recurse(obj: any, result: any) {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
        recurse(obj[key], result);
      } else {
        result[key] = obj[key];
      }
    }
  }

  recurse(obj, result);

  return result;
}
*/

export function flattenObject1(obj: any) {
  const result: any = {};
  function recurse(obj: any, result: any) {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        recurse(obj[key], result);
      } else {
        result[key] = obj[key];
      }
    }
  }
  recurse(obj, result);
  return result;
}

export function flattenObject(obj: any) {
  const result: any = {};
  function recurse(inputArr: any, result: { [key: string]: any }, appendKey: string = ''): void {
    for (const key in inputArr) {
      if (typeof inputArr[key] === 'object' && !Array.isArray(inputArr[key])) {
        if (key === 'address') {
          result[key] = inputArr[key];

          continue;
        }
        if (key === 'billing_contact') {
          recurse(inputArr[key], result, key);
        } else {
          recurse(inputArr[key], result, appendKey);
        }
      } else {
        result[appendKey ? `${appendKey}_${key}` : key] = inputArr[key];
      }
    }
  }

  recurse(obj, result);
  return result;
}

export enum rolesEnum {
  office_worker = 'office_worker',
  field_worker = 'field_worker',
  estimator = 'estimator',
  company_admin = 'company_admin'
}

export function formatRolePermissionData(currentRole: rolesEnum) {
  const selectedRolePermissions = defaultPermissionsForRoles[currentRole];
  const formattedRolePermissions: any = {};

  Object.entries(selectedRolePermissions).forEach(([rolePermisionKey, rolePermisionValue]: any) => {
    if (rolePermisionValue?.length) {
      rolePermisionValue.forEach((permisionValue: string) => {
        formattedRolePermissions[permisionValue] = rolePermisionKey;
      });
    }
  });
  return formattedRolePermissions;
}
// export const formateReportAmountData = (value: number) => {
//   return value < 0
//     ? `- $${decimalComma(Math.trunc(-value).toString()) + '.' + value.toFixed(2).toString().split('.')[1]}`
//     : `$${decimalComma(Math.trunc(value).toString()) + '.' + value.toFixed(2).toString().split('.')[1]}`;
// };

export const randomHexColorCode = () => {
  const maxColorValue = 0xeeeeee; // Exclude pure white
  const colorCode =
    '#' +
    Math.floor(Math.random() * maxColorValue)
      .toString(16)
      .padStart(6, '0');

  return colorCode;
};

export const validateNotesData = (dataToBeValidated: object) => {
  //------ "any" object can be validated-------
  const hadError: boolean[] = [];
  Object.values(dataToBeValidated).forEach((value: any) => hadError.push(!value.length));
  return hadError.some((err) => err);
};

export const getWidgetScript = (companyId: string) => `<script>
(function () {
  options = {
    company_id: "${companyId}",
    base_url: "${constants.REACT_APP_BASE_URL}",
  };
  s = document.createElement("script");
  s.type = "text/javascript";
  s.async = true;
  s.src = "${constants.REACT_APP_BASE_URL}/estimate-widget.js";
  document.body.appendChild(s);
  s.onload = function () {
    ZippfleetWidget.init(options);
  };
})();
</script>
<div id="zippfleet-form-container"></div>`;

export const getAppointmentWidgetScript = (companyId: string, serviceId?: string) => `<script>
(function () {
  options = {
    company_id: "${companyId}",
    service_id: "${serviceId ?? ''}",
    base_url: "${constants.REACT_APP_BASE_URL}",
  };
  s = document.createElement("script");
  s.type = "text/javascript";
  s.async = true;
  s.src = "${constants.REACT_APP_BASE_URL}/appointment-widget.js";
  document.body.appendChild(s);
  s.onload = function () {
    ZippfleetWidget.init(options);
  };
})();
</script>
<div id="zippfleet-form-container"></div>`;

export const toTitleCase = (inputString: string): string => {
  if (typeof inputString !== 'string') {
    return '';
  }
  return inputString.toLowerCase().replace(/(?:^|\s)\w/g, (match) => match.toUpperCase());
};

export function snakeCaseToTitleCase(input: string): string {
  if (!input) return '';
  return input
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

//todo: create a func formatJobName, use switch case
export const formatJobName = (formateType: string, firstname: string, lastname: string, jobNumber?: number) => {
  const currentDate = moment(new Date()).format('MM/DD/YYYY');

  switch (formateType) {
    case jobNameFormats.TODAY_DATE:
      return currentDate;

    case jobNameFormats.CUSTOMER_NAME_HYPHRN_DATE:
      return `${firstname} ${lastname} - ${currentDate}`;

    case jobNameFormats.REVERSE:
      return `${currentDate} - ${firstname} ${lastname}`;
    case jobNameFormats.JOB_NUMBER:
      return ((jobNumber ?? 0) + 1).toString();
    case jobNameFormats.CUSTOM_NAME:
    default:
      return `${firstname}_${lastname}_${currentDate}`;
  }
};
export const getUniqueId = (len = 10) => {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let objectId = '';
  let i = 0;
  while (i < len) {
    objectId += possible.charAt(Math.floor(Math.random() * possible.length));
    i++;
  }
  return objectId;
};
export const calculateTimeDifference = (date1: Dayjs, date2: Dayjs) => {
  return date1.diff(date2, 'hours');
};
export async function getCurrentLocation(): Promise<{ lat: string; long: string }> {
  try {
    const position = await new Promise<GeolocationPosition>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
    const lat = position.coords.latitude.toString();
    const long = position.coords.longitude.toString();
    return { lat, long };
  } catch (error) {
    return { lat: '', long: '' };
  }
}
export const getAddressFromCoordinates = async (lat: number, long: number): Promise<string> => {
  const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${googleMapsApiKey}`);
  const results = response.data.results;
  if (results && results.length > 0) {
    return results[0].formatted_address;
  } else {
    return '';
  }
};

export const storedAPIInIndexedDB = async (request: TStoredAPICalls) => {
  const indexedDBInstance = new IndexedDBServices(indexedDbStoreNames.api);
  const apiData = (await indexedDBInstance.getAllLocalData()) as TIndexedDBResponse;

  if (!apiData) {
    await indexedDBInstance.insertDataInIndexedDB({
      date: moment().format('MM-DD-YYYY'),
      data: [request]
    });
  } else {
    await indexedDBInstance.insertDataInIndexedDB({
      date: moment().format('MM-DD-YYYY'),
      data: [...apiData.data, request]
    });
  }
};

export const storedDataInIndexedDB = async (storeName: string, data: any) => {
  const indexedDBInstance = new IndexedDBServices(storeName);
  await indexedDBInstance.insertDataInIndexedDB({ date: moment().format('MM-DD-YYYY'), data });
  const response = (await indexedDBInstance.getAllLocalData()) as TIndexedDBResponse;
  return response;
};

export const formatPhoneNumber = (phoneNumber: string) => {
  const digits = phoneNumber.replace(/\D/g, '');

  if (!(digits.length < 10)) {
    const areaCode = digits.substring(0, 3);
    const exchangeCode = digits.substring(3, 6);
    const subscriberNumber = digits.substring(6, 10);
    return `(${areaCode}) ${exchangeCode}-${subscriberNumber}`;
  }
  return phoneNumber;
};

export const handleEstimateReminder = (formik: any, reminder: string) => {
  switch (reminder) {
    case '1daybefore':
      return dayjs(formik.values.estimate_reminder?.scheduled_date_and_time).subtract(1, 'day').toISOString();
    case '2daybefore':
      return dayjs(formik.values.estimate_reminder?.scheduled_date_and_time).subtract(2, 'day').toISOString();
    default:
      return formik.values.estimate_reminder?.reminder_date;
  }
};
export const handleJobReminder = (formik: any, reminder: string) => {
  switch (reminder) {
    case '1daybefore':
      return dayjs(formik.values.job_reminder?.scheduled_date_and_time).subtract(1, 'day').toISOString();
    case '2daybefore':
      return dayjs(formik.values.job_reminder?.scheduled_date_and_time).subtract(2, 'day').toISOString();
    default:
      return formik.values.job_reminder?.reminder_date;
  }
};
export const checkApprovedOption = (estimateOption: IEstimationOption[]) => {
  return estimateOption?.findIndex((singleEstimateOption: IEstimationOption) => singleEstimateOption.approved === true);
};
export const categorizeEstimationOptions = (estimateOption: IEstimationOption[]) => {
  let updatedCategoryTotal: { [key: string]: number }[] = [];
  let updatedDataByCategory: { [key: string]: TJobItem[] }[] = [];
  estimateOption.forEach((singleOption: IEstimationOption, optionIndex: number) => {
    if (!!singleOption.items.length) {
      singleOption.items.forEach((singleItem) => {
        if (!updatedDataByCategory[optionIndex]) {
          updatedDataByCategory[optionIndex] = {};
        }
        if (!updatedCategoryTotal[optionIndex]) {
          updatedCategoryTotal[optionIndex] = {};
        }
        if (!updatedDataByCategory[optionIndex][singleItem.job_category.name]) {
          updatedDataByCategory[optionIndex][singleItem.job_category.name] = [];
        }
        if (!updatedCategoryTotal[optionIndex][singleItem.job_category.name]) {
          updatedCategoryTotal[optionIndex][singleItem.job_category.name] = 0;
        }
        updatedDataByCategory[optionIndex][singleItem.job_category.name].push(singleItem);
        updatedCategoryTotal[optionIndex][singleItem.job_category.name] =
          updatedCategoryTotal[optionIndex][singleItem.job_category.name] + singleItem.price;
      });
    }
  });
  return { category_total: updatedCategoryTotal, data_by_category: updatedDataByCategory };
};

export const formatePhonesData = (customerData: TCustomerData): TCustomerPhones => {
  const dummydata = [
    {
      phone: customerData?.phone1 ?? '',
      phone_country_code: customerData?.phone1_country_code ?? '',
      ...(customerData?.phone1_extension && { phone_extension: String(customerData?.phone1_extension) })
    }
  ];

  if (!!customerData?.phone2 && !!customerData?.phone2_country_code)
    dummydata.push({
      phone: customerData?.phone2,
      phone_country_code: customerData?.phone2_country_code,
      ...(customerData?.phone2_extension && { phone_extension: String(customerData?.phone2_extension) })
    });
  if (!!customerData?.phone3 && !!customerData?.phone3_country_code)
    dummydata.push({
      phone: customerData?.phone3,
      phone_country_code: customerData?.phone3_country_code,
      ...(customerData?.phone3_extension && { phone_extension: String(customerData?.phone3_extension) })
    });
  return dummydata;
};

export const createMultipleLogs = async (logsData: TLogs) => {
  const response = await LogsServicesInstance.createBulkLog(logsData);
  if (response) return response;
};
export const handlePhoneNumberInputChange = (
  phone: string,
  country: CountryData,
  e: React.ChangeEvent<HTMLInputElement>,
  formik: any,
  field_name: string
) => {
  formik.setFieldValue(`${field_name}_country_code`, country.dialCode);
  formik.setFieldValue(`${field_name}`, phone.slice(country.dialCode.length));
};
export const getAllWeekendDates = (weekend: number): string[] => {
  let currentDate = moment().startOf('year').weekday(weekend);
  const endDate = moment().endOf('year');
  const getAllWeekendDates = [];
  while (currentDate <= endDate) {
    getAllWeekendDates.push(moment(currentDate).format('YYYY-MM-DD'));
    currentDate = moment(currentDate).add(7, 'day');
  }

  return getAllWeekendDates;
};
// export const decimalComma = (value: string): string => {
//   if (value.length <= 3) return value;
//   const pos = value.length % 3;
//   const initial = pos ? value.slice(0, pos) + ',' : '';
//   const remainder = value.slice(pos);
//   const grouped = remainder.match(/.{1,3}/g)?.join(',') ?? '';
//   return initial + grouped;
// };

export const formateData = (value: number, currency?: string) => {
  return numeral(value).format(`${currency ?? ''}0,0.00`);
};
export const removeExtension = (fileName: string) => {
  return fileName.replace(/\.[^.]+$/, '');
};
export const getFileNameFromURL = (url: string) => {
  const startIndex = url.indexOf('zippfleet-file-');
  const endIndex = url.indexOf('-&-', startIndex + 14);
  const fileName = url.substring(startIndex + 15, endIndex);
  return fileName;
};

export const dataURLtoBlob = (dataURL: string) => {
  const byteString = atob(dataURL.split(',')[1]);
  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

export const handleDownload = async (url: string) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors'
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = blobUrl;
    a.download = getFileNameFromURL(url);
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(blobUrl);
    document.body.removeChild(a);
  } catch (error: unknown) {
    const knownError = error as { message: string };
    dispatch(
      openSnackbar({
        open: true,
        message: knownError.message,
        variant: 'alert',
        alert: {
          color: 'error'
        },
        severity: 'error',
        close: true
      })
    );
  }
};
export const isAssignWorkerFieldHidden = (
  assign_worker_config: TSettingData['assign_worker_page'],
  field_name: string,
  isShowMoreOptionSelected: boolean,
  isEditMode: boolean
) => {
  return !isEditMode && assign_worker_config?.[field_name as keyof TSettingData['assign_worker_page']]['is_hidden']
    ? isShowMoreOptionSelected
    : true;
};
export const handleRedirectToGoogleMap = (address: any) => {
  if (!address) return;
  const encodedAddress = encodeURIComponent(address);
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
  window.open(googleMapsUrl, '_blank');
};
export const getListOfItemDetails = (options: IEstimationOption[]) => {
  const itemDetails = new Map();
  options.forEach((singleOption: IEstimationOption) => {
    singleOption.items.forEach((singleItem: TJobItem) => {
      if (!!singleItem.item_details && singleItem.item_details?.length > 0) {
        itemDetails.set(singleItem.service_and_product.id, singleItem.item_details);
      }
    });
  });
  const iterator = itemDetails.values(),
    listOfItemDetails: string[] = [];
  let item_detail = iterator.next().value;
  while (!!item_detail) {
    listOfItemDetails.push(item_detail);
    item_detail = iterator.next().value;
  }
  return listOfItemDetails;
};
export const checkIsFollowupsAssigned = async (role: string) => {
  const isFollowupsAssigned = await FollowupServicesInstance.getIsAssignedFollwup();
  return isFollowupsAssigned?.is_assigned_followups;
};
export const getStateAbbrevation = (name: string) => {
  const stateName = abbr(name);

  return stateName === 'No abbreviation found with that state name' ? name : stateName;
};
export const getResponseStatus = (sentTime: string | Date, type: string) => {
  const currentTime = new Date();
  const sentDate = new Date(sentTime);
  const timeDifference = currentTime.getTime() - sentDate.getTime();

  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);

  if (hours < 24) {
    return type === 'proposal' ? 'Opened' : 'Sent';
  } else if (hours >= 24 && hours < 48) {
    return `${hours} hours, no response`;
  } else if (days >= 2 && days < 7) {
    return `${days} days, no response`;
  } else if (weeks >= 1 && weeks < 4) {
    return `${weeks} weeks, no response`;
  } else {
    return `Last sent ${sentDate.toLocaleString()}, no response`;
  }
};
export const isFinalWorkerCompletingJob = (job: TJobCompleteData, jobAcitonId: string) => {
  // This function checks if the current worker is the final worker completing the job.
  // First, it checks if the job does not require multiple actions. If the job is not a multi-action job,
  // then the current worker is the final worker marking the job as complete.
  // If the job is a multi-action job, it checks if there are any other workers with incomplete sequential actions
  // (i.e., sequential job type where the next job in the sequence hasn't been created yet)
  // or if there are workers who haven't completed their assigned tasks (`is_job_complete: false`).
  // If no such worker is found, the current worker is considered the final worker completing the job.
  return (
    job?.job_action?.[0].is_multi_action_job_required_additional_field_workers === false ||
    !job?.job_action?.some((singleFieldWorker) => {
      return (
        singleFieldWorker.id !== jobAcitonId &&
        ((singleFieldWorker.multi_action_job_type === 'sequential' && !singleFieldWorker.is_sequential_job_created) ||
          !singleFieldWorker.is_job_complete)
      );
    })
  );
};
export const containsIncompleteJob = (jobAction: TJobAction[]) => {
  return jobAction.some((eachJobAction) => eachJobAction.is_job_complete === false);
};
export const isWorkerReassigned = (worker_type: string, previousStates: string[]) => {
  switch (worker_type) {
    case 'field_worker':
      return previousStates?.includes(jobStates.REASSIGNED_WORKER) && !previousStates?.includes(jobStates.JOB_COMPLETED);
    case 'estimator':
      return previousStates?.includes(jobStates.REASSIGNED_ESTIMATOR) && !previousStates?.includes(jobStates.ESTIMATION_COMPLETED);
    default:
      return false;
  }
};

export const generateRandomKeyword = (length: number): string => {
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
};

export function getGoogleMapsDirectionsURL(originLat: string | number, originLng: string | number, destinationAddress: string) {
  const baseURL = 'https://www.google.com/maps/search/?api=1';

  const destinationParam = `query=${encodeURIComponent(destinationAddress)}`;

  return `${baseURL}&${destinationParam}`;
}

export function getGoogleEarthUrl(address: string) {
  const encodedAddress = encodeURIComponent(address);

  return `https://earth.google.com/web/search/${encodedAddress}`;
}

export function formatCostInDecimal(value: string, decimalPlace: number = 2) {
  // Allow only numbers and one decimal point, simplify leading zeros to just one zero
  value = value
    .replace(/[^0-9.]/g, '')
    .replace(/(\..*)\./g, '$1')
    .replace(/^0+/, '0');

  // Prepend '0' if the value starts with a '.'
  if (value.startsWith('.')) {
    value = '0' + value;
  }

  // Limit to specified decimal places
  if (value.includes('.')) {
    const parts = value.split('.');
    parts[1] = parts[1].slice(0, decimalPlace); // Slice the decimal part to the maximum allowed length
    value = parts[0] + '.' + parts[1];
  }

  return value;
}

export function getNextSalaryDate(initialPayDay: string | Date, payFrequencyOption: string) {
  // 1) Parse initialPayDay into a Date object
  const initialDate = new Date(initialPayDay);

  // 2) Determine “today”
  const today = new Date();

  // 3) Determine frequency in days
  let frequencyInDays = 7; // default to weekly
  if (payFrequencyOption === 'by_weekly') {
    frequencyInDays = 14;
  }

  // 4) If initialDate is still in the future, that’s the next payday
  if (initialDate > today) {
    return initialDate;
  }

  // 5) Otherwise, start at initialDate and keep moving forward
  let nextPayDate = new Date(initialDate);
  while (nextPayDate <= today) {
    nextPayDate.setDate(nextPayDate.getDate() + frequencyInDays);
  }

  return nextPayDate;
}

export function formatCurrency(number: any, minimumFractionDigits: number = 2) {
  const original = new Intl.NumberFormat('en-US', {
    minimumFractionDigits,
    maximumFractionDigits: 2
  }).format(number);
  return original;
}

export function toUrlSafeBase64(input: string): string {
  const base64 = btoa(input);
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

export function fromUrlSafeBase64(input: string | undefined): string {
  if (!input) {
    return '';
  }
  let base64 = input.replace(/-/g, '+').replace(/_/g, '/');
  while (base64.length % 4 !== 0) {
    base64 += '=';
  }
  return atob(base64);
}
